import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabPanel } from 'react-tabs';
import Draggable from 'react-draggable';
import { CustomTabList, CustomTab } from '../CustomTabs';
import { useWindowSize } from '../../hooks/shared/use-window-size';

import styles from './MultipleTabsPopup.module.scss';

// Need update style if change values here
const defaultPopupSize = {
    width: 892,
    height: 600,
};

const defaultInitPosition = {
    x: 0,
    y: 0,
};

/**
 * Method for getting correct position
 * for place popup by center window
 *
 * @param {object} windowSize window size values
 * @param {number} windowSize.width window width
 * @param {number} windowSize.height window height
 * @returns {object} calculated position
 */
const getInitPopupPosition = (windowSize) => {
    if (!windowSize || !windowSize.width || !windowSize.height) {
        return defaultInitPosition;
    }
    // Minimal position from top window
    const minYPosition = 0;
    const calcX = Math.round((windowSize.width / 2) - (defaultPopupSize.width / 2));
    const calcY = Math.round((windowSize.height / 2) - (defaultPopupSize.height / 2));
    return {
        x: calcX,
        y: calcY < 0 ? minYPosition : calcY,
    };
};

const MultipleTabsPopup = ({
    tabs,
    selectedIndex,
    onTabClick,
}) => {
    const size = useWindowSize();
    const initPosition = getInitPopupPosition(size);
    const handleTabClick = (index) => () => onTabClick(index);
    return (
        <>
            {
                tabs && tabs.length
                    ? (
                        <div className={styles['popup-container']}>
                            <Draggable
                                handle=".handle"
                                defaultPosition={initPosition}
                                position={null}
                            >
                                <div
                                    className={styles['popup-draggable']}
                                    style={{
                                        width: `${defaultPopupSize.width}px`,
                                    }}
                                >
                                    <Tabs selectedIndex={selectedIndex}>
                                        <CustomTabList>
                                            {
                                                tabs && tabs.length
                                                    ? (
                                                        tabs.map((tab, index) => (
                                                            <CustomTab
                                                                onClick={handleTabClick(index)}
                                                                key={tab.id}
                                                                style={{
                                                                    maxWidth: `${(100 / tabs.length).toFixed(2)}%`,
                                                                }}
                                                                className="handle"
                                                            >
                                                                {tab.title}
                                                            </CustomTab>
                                                        ))
                                                    )
                                                    : null
                                            }
                                        </CustomTabList>
                                        {
                                            tabs && tabs.length
                                                ? (
                                                    tabs.map((tab) => (
                                                        <TabPanel
                                                            key={tab.id}
                                                            forceRender={tab.forceRender}
                                                        >
                                                            <div
                                                                className={styles['popup-content']}
                                                                style={{
                                                                    width: `${defaultPopupSize.width}px`,
                                                                    maxHeight: `${defaultPopupSize.height}px`,
                                                                }}
                                                            >
                                                                {tab.content}
                                                            </div>
                                                        </TabPanel>
                                                    ))
                                                )
                                                : null
                                        }
                                    </Tabs>
                                </div>
                            </Draggable>
                        </div>
                    )
                    : null
            }
        </>
    );
};

MultipleTabsPopup.propTypes = {
    selectedIndex: PropTypes.number,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.node,
            content: PropTypes.node,
        }),
    ).isRequired,
    onTabClick: PropTypes.func,
};

MultipleTabsPopup.defaultProps = {
    selectedIndex: 0,
    onTabClick: () => null,
};

export default MultipleTabsPopup;
