import React from 'react';
import PropTypes from 'prop-types';
import { SIZE } from '../../constants/operator';

import styles from './InputList.module.scss';

const InputList = ({
    className,
    size,
    prefix,
    postfix,
    children,
}) => (
    <div
        className={`
            ${styles['input-list']}
            ${styles[`input-list--${size}`]}
            ${prefix ? styles['input-list--prefix'] : ''}
            ${postfix ? styles['input-list--postfix'] : ''}
            ${className}
        `}
    >
        {
            prefix
                ? (
                    <div className={styles['input-list__prefix']}>
                        {prefix}
                    </div>
                )
                : null
        }
        {
            postfix
                ? (
                    <div className={styles['input-list__postfix']}>
                        {postfix}
                    </div>
                )
                : null
        }
        <div className={styles['input-list__list']}>
            <div className={styles['input-list__row']}>
                {
                    children.map((child) => (
                        <div
                            className={styles['input-list__item']}
                            style={{ width: `${100 / children.length}%` }}
                            key={`${child.key}`}
                        >
                            {
                                React.cloneElement(
                                    child,
                                    {
                                        size,
                                        prefix: '',
                                        postfix: '',
                                    },
                                )
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
);

InputList.SIZE = { ...SIZE };

InputList.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(InputList.SIZE)),
    prefix: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    postfix: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    children: PropTypes.arrayOf(PropTypes.shape({})),
};

InputList.defaultProps = {
    className: '',
    size: InputList.SIZE.MEDUIM,
    prefix: '',
    postfix: '',
    children: [],
};

export default InputList;
