/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import Dropdown from '../../../components/Dropdown';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { FilterIcon } from '../../../components/Icons/icons';
import { renderField } from '../Form/FormElements';
import { SORT_ORDER } from '../../../constants/shared';

import styles from './Table.module.scss';

const DropdownContent = ({
    useStores,
    fields,
    form,
}) => {
    const timeoutRef = useRef(0);
    const createHandler = useCallback((onChange) => (...args) => {
        const { current: timeoutId } = timeoutRef;
        clearTimeout(timeoutId);
        onChange(...args);
        timeoutRef.current = setTimeout(() => {
            form.submit();
        }, 750);
    }, [form]);

    return (
        <div
            className={styles['table-header__field']}
        >
            {fields.map((fieldForm) => {
                if (!form.has(fieldForm.name)) {
                    return null;
                }
                const field = form.$(fieldForm.name);
                const FieldComponent = renderField(field);
                const fieldSpecificProps = (type) => {
                    switch (type) {
                        case 'select':
                            return {
                                menuIsOpen: true,
                                disable: false,
                                id: `TableHeaderTitle-${field.name}`,
                            };
                        case 'directorySelect':
                        case 'directorySearch':
                            return {
                                headless: true,
                                menuIsOpen: true,
                                disable: false,
                                className: styles['table-header__select'],
                                id: `TableHeaderTitle-${field.name}`,
                            };
                        case 'datepicker':
                            return {
                                className: styles['table-header__datepicker'],
                                id: `TableHeaderTitle-${field.name}`,
                                withPortalId: false,
                                shouldCloseOnSelect: false,
                                onSelect: (date, event, pickerRef) => {
                                    // FIXME: ugly hack to close datepicker
                                    // but not close dropdown. Not work without
                                    // setTimeout
                                    setTimeout(() => {
                                        pickerRef.current.setOpen(false);
                                    });
                                },
                            };
                        default:
                            return {
                                className: styles['table-header__input'],
                                id: `TableHeaderTitle-${field.name}`,
                            };
                    }
                };
                return (
                    <Observer key={`${field.id}`}>
                        {() => {
                            const { onChange, ...fieldProps } = field.bind();
                            const handleChange = createHandler(onChange);

                            return (
                                <FieldComponent
                                    {...fieldProps}
                                    {...fieldSpecificProps(field.type)}
                                    useStores={useStores}
                                    error={field.error}
                                    form={form}
                                    onChange={handleChange}
                                />
                            );
                        }}
                    </Observer>
                );
            })}
        </div>
    );
};

DropdownContent.propTypes = {
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({
        submit: PropTypes.func.isRequired,
        $: PropTypes.func.isRequired,
        has: PropTypes.func.isRequired,
    }).isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        }),
    ),
};

DropdownContent.defaultProps = {
    fields: [],
};

const DropdownWrapper = ({ dropdownOpened, children }) => (
    dropdownOpened
        ? (
            <div className={styles.menu__content}>
                {children}
            </div>
        )
        : null
);

DropdownWrapper.propTypes = {
    dropdownOpened: PropTypes.bool,
    children: PropTypes.element,
};

DropdownWrapper.defaultProps = {
    dropdownOpened: false,
    children: '',
};

const Text = ({
    title,
    sortDirection,
    isSortable,
    onClick,
}) => {
    const withDirectionIcon = Boolean(sortDirection);
    return (
        <span
            className={`
                ${styles['table-header__text']}
                ${isSortable ? styles['table-header__text--sortable'] : ''}
            `}
            onClick={onClick}
            aria-hidden="true"
        >
            {
                withDirectionIcon
                    ? (
                        <span className={styles['table-header__direction']}>
                            {
                                sortDirection === SORT_ORDER.ASC
                                    ? '\u2191 '
                                    : '\u2193 '

                            }
                        </span>
                    )
                    : null
            }
            {title}
        </span>
    );
};

Text.propTypes = {
    title: PropTypes.string,
    sortDirection: PropTypes.string,
    isSortable: PropTypes.bool,
    onClick: PropTypes.func,
};

Text.defaultProps = {
    title: '',
    sortDirection: undefined,
    isSortable: false,
    onClick: () => null,
};

const HeaderTitle = ({
    useStores,
    form,
    title,
    fields,
    sortName,
    sortDirection,
    onTableHeaderClick,
}) => {
    const withFields = fields && fields.length;
    const isSortable = Boolean(sortName);
    const handleTableHeaderClick = (e) => {
        if (e) e.preventDefault();
        if (sortName && onTableHeaderClick && typeof onTableHeaderClick === 'function') {
            onTableHeaderClick({ sortName, sortDirection });
        }
    };

    return (
        <div className={styles['table-header']}>
            <Text
                title={title}
                isSortable={isSortable}
                sortDirection={sortDirection}
                onClick={handleTableHeaderClick}
            />
            {withFields
                ? (
                    <div className={styles['table-header__filter']}>
                        <Dropdown
                            name={(
                                <span className={styles['table-header__icon']}>
                                    <SVGIcon SVGElement={FilterIcon} height={10} />
                                </span>
                            )}
                            className={styles['table-header__dropdown']}
                            openClassName={styles['table-header__dropdown--open']}
                            contentClassName={styles['table-header__dropdown-content']}
                        >
                            <DropdownWrapper>
                                <DropdownContent
                                    useStores={useStores}
                                    fields={fields}
                                    form={form}
                                />
                            </DropdownWrapper>
                        </Dropdown>
                    </div>
                )
                : null}
        </div>
    );
};

HeaderTitle.propTypes = {
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({
        submit: PropTypes.func.isRequired,
        $: PropTypes.func.isRequired,
        has: PropTypes.func.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        }),
    ),
    sortName: PropTypes.string,
    sortDirection: PropTypes.string,
    onTableHeaderClick: PropTypes.func,
};

HeaderTitle.defaultProps = {
    fields: [],
    sortName: undefined,
    sortDirection: undefined,
    onTableHeaderClick: undefined,
};

export default HeaderTitle;
