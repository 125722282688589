const findClosestRouteWithComponent = (routes = []) => {
    for (let i = 0; i < routes.length; i += 1) {
        if (routes[i].routes) {
            return findClosestRouteWithComponent(routes[i].routes);
        }
        return routes[i];
    }
    return null;
};

export default findClosestRouteWithComponent;
