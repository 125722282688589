export default {
    type: 'fieldset', // comment
    name: 'comment',
    fields: [{
        name: 'note',
        type: 'textarea',
        label: 'Примечания',
        rows: 3,
    }],
};
