import {
    observable,
    computed,
    action,
} from 'mobx';

const STATES = {
    DEFAULT: 0,
    INITIALLY_LOADING: 1,
    LOADING: 2,
    ERROR: 3,
};
class DataSource {
    @observable $state = STATES.DEFAULT;

    @observable $lastMethod = undefined;

    @observable $error;

    $service;

    constructor(service, initialState = STATES.DEFAULT) {
        this.$initialize(service, initialState);
    }

    @computed
    get state() {
        return this.$state;
    }

    @computed
    get loading() {
        const { LOADING, INITIALLY_LOADING } = STATES;
        return this.$state === LOADING || this.$state === INITIALLY_LOADING;
    }

    @computed
    get lastMethod() {
        return this.$lastMethod;
    }

    @computed
    get error() {
        const { $error } = this;
        if (!$error) {
            return null;
        }
        const responseErrorMessage = $error.response
            && $error.response.data
            && $error.response.error
            && $error.response.error.message;
        const errorMessage = $error.message;
        return responseErrorMessage || errorMessage || 'Unexpected error';
    }

    $initialize(service, initialState = STATES.DEFAULT) {
        if (!service || !(service instanceof Object)) {
            throw new Error('No valid service provided');
        }
        this.$service = service;
        this.$setState(initialState, 'initial');
        Object.keys(service).forEach((method) => {
            if (
                !Object.prototype.hasOwnProperty.call(this, method)
                && typeof service[method] === 'function'
            ) {
                this[method] = (...rest) => (
                    this.$makeRequest(method, ...rest)
                );
            }
        });
    }

    $makeRequest(method, ...params) {
        const result = this.$service[method](...params);
        if (result instanceof Promise) {
            this.$setState(STATES.LOADING, method);
            return result
                .catch((e) => {
                    this.$setState(STATES.ERROR);
                    return Promise.reject(e);
                })
                .finally(() => {
                    if (this.$state !== STATES.ERROR) {
                        this.$setState(STATES.DEFAULT);
                    }
                });
        }
        return result;
    }

    @action
    $setState(state, method) {
        if (!Object.values(STATES).includes(state)) {
            throw new Error('Undefined data source state');
        }
        this.$state = state;
        this.$lastMethod = method;
    }

    @action
    setError(error) {
        this.$setState(STATES.ERROR);
        this.$error = error;
    }

    @action
    setPreload() {
        this.$setState(STATES.LOADING);
    }
}

export default DataSource;
export {
    STATES,
};
