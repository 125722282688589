import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'location',
    fields: [{
        name: 'cityIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        multiple: true,
        clearable: true,
        searchable: true,
        withDefault: true,
        isDropdown: true,
    }, {
        name: 'cityDistrictIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'streetIds',
        type: 'directorySearch',
        directory: DIRECTORIES.STREETS,
        label: 'Улица',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
        optionValuesIsArray: true,
    }, {
        name: 'cityDistrictLandmarkIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT_LANDMARK,
        label: 'Ориентир',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset',
    name: 'steadType',
    fields: [{
        name: 'objectSteadTypeIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_STEAD_TYPE,
        label: 'Тип участка (назначение)',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }, {
        name: 'square',
        type: 'fieldgroup',
        label: 'Площадь, м²',
        fields: [{
            name: 'totalAreaFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'totalAreaTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }, {
        name: 'passageIds',
        type: 'directorySelect',
        directory: DIRECTORIES.PASSAGE,
        label: 'Подъезд',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}];
