import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import ObjectList from '../../../stores/shared/RefactoredObjectList/ObjectList';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import { ButtonClose } from '../../../components/Button';
import Phone from '../../../components/Phone';
import { RelatedTypeTab } from '../ObjectPageView';
import RelatedContent from './RelatedContent';
import Preloader from '../../../components/Preloader';
import { OBJECT_TYPE_NAME, TAB_TYPE } from '../../../constants/shared';

import styles from './RelatedObjects.module.scss';
import { CopyToClipboardButton } from '../../../components/CopyToClipboard/CopyToClipboard';

const RelatedObjects = ({
    useStores,
    id,
    onClose,
    store: {
        related,
        tabs,
        phone,
    },
}) => {
    const handleRowClick = (row) => {
        const { id: itemId } = row.original;
        const { list } = related;
        const item = list.getItem(itemId);
        const instance = related.createDetailed(item.dealType);
        instance.setData({ exclusive: item.isExclusive });
        list.setCursor(itemId);
        instance.setData({ id: itemId });
        if (handleRowClick.callback) {
            instance.load(itemId, false);
            handleRowClick.callback(instance);
        }
    };
    const handleDoubleRowClick = (data) => {
        const {
            original: { id: itemId },
        } = data;
        const { list } = related;
        const item = list.getItem(itemId);
        if (!tabs.getTab(item.id)) {
            const instance = related.createDetailed(item.dealType);
            instance.setData({ id: item.id, exclusive: item.isExclusive });
            const form = instance.createForm();
            form.on('done', (response) => {
                if (!response.filter((res) => Boolean(res)).length) {
                    return;
                }
                tabs.removeTab(item.id);
                item.setData(instance.toItem());
            });
            list.setCursor(item.id);
            instance.load(item.id);
            tabs.addTab(
                instance,
                item.id,
                item.objectName,
                TAB_TYPE.EDIT_OBJECT,
            );
        }
    };
    const handleTabChange = (e, type) => {
        related.setActiveTab(type);
        handleRowClick.callback(null);
    };
    return (
        <div className={`${styles.container} ${styles.related}`}>
            <h2 className={`handle ${styles.title}`}>
                Объекты связанные с
                &nbsp;
                <Phone className={styles.title__phone}>
                    {phone}
                </Phone>
                <CopyToClipboardButton text={phone} iconHeight={15} />
            </h2>
            <div className={styles.close}>
                <ButtonClose
                    onClick={() => onClose(id)}
                />
            </div>
            <div className={styles.related__content}>
                <Observer>
                    {() => (
                        <div
                            className={`
                                ${styles.related__preloader}
                                ${related.loading ? styles['related__preloader--loading'] : ''}
                            `}
                        >
                            <Preloader className={styles.related__spinner} />
                        </div>
                    )}
                </Observer>
                <Observer>
                    {() => (
                        <div
                            className={`
                                ${styles['related__tab-list']}
                                ${related.availableObjectTypes.length ? styles['related__tab-list--ready'] : ''}
                            `}
                        >
                            {
                                related.availableObjectTypes.length
                                    ? related.availableObjectTypes.map((type) => (
                                        <RelatedTypeTab
                                            key={`related-tab-${type.toLowerCase()}`}
                                            type={type}
                                            active={type === related.activeType}
                                            onClick={handleTabChange}
                                        >
                                            {OBJECT_TYPE_NAME[type]}
                                        </RelatedTypeTab>
                                    ))
                                    : (
                                        <div className={`${styles.related__message} ${styles.message}`}>
                                            <div className={styles.message__title}>
                                                Сообщение
                                            </div>
                                            <div className={styles.message__text}>
                                                Нет ни одного связанного объекта
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    )}
                </Observer>
                <div className={styles.content}>
                    <Observer>
                        {() => (
                            <RelatedContent
                                useStores={useStores}
                                list={related.list}
                                onDoubleRowClick={handleDoubleRowClick}
                                onRowClick={handleRowClick}
                            />
                        )}
                    </Observer>
                </div>
            </div>
        </div>
    );
};

RelatedObjects.propTypes = {
    useStores: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    store: PropTypes.shape({
        phone: PropTypes.string,
        related: PropTypes.shape({
            availableObjectTypes: PropTypes.arrayOf(PropTypes.string),
            activeType: PropTypes.string,
            list: PropTypes.instanceOf(ObjectList),
            setActiveTab: PropTypes.func,
            createDetailed: PropTypes.func,
            loading: PropTypes.bool,
        }),
        tabs: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(PopupTabsWithApproveStore),
        ]),
        Detailed: PropTypes.shape({}),
    }).isRequired,
};

export default RelatedObjects;
