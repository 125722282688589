import BaseStrategy from './BaseStrategy';
import SteadItem from '../item/SteadItem';
import { TABLE_CELL } from '../../../../constants/shared';

class SteadStrategy extends BaseStrategy {
    constructor() {
        super();
        this.objectType = 'steads';
        this.Item = SteadItem;
    }

    // eslint-disable-next-line class-methods-use-this
    get sortOrder() {
        return [
            TABLE_CELL.DISTRICT_NAME.sortName,
            TABLE_CELL.STEAD_TYPE_NAME.sortName,
            TABLE_CELL.PRICE_DELIMITED.sortName,
            TABLE_CELL.SQUARE.sortName,
            TABLE_CELL.STREET_NAME.sortName,
        ];
    }
}

export default SteadStrategy;
