import React from 'react';
import PropTypes from 'prop-types';
import FormElements from '../Form';
import { Button } from '../../../components/Button';
import MortgageFieldset from './MortgageFieldset';
import MortgageField from './MortgageField';
import numberWithSpaces from '../../../utils/number-with-spaces';

import styles from './MortgageCalculator.module.scss';

const MortgageCalculator = ({
    monthlyPayment,
    totalOverPayment,
    form,
    useStores,
}) => (
    <div className={styles.mortgage}>
        <div className={styles.mortgage__hint}>
            Для аннуитетных платежей
        </div>
        <FormElements
            form={form}
            elements={form.render}
            useStores={useStores}
            components={{
                FieldsetWrapper: MortgageFieldset,
                FieldWrapper: MortgageField,
            }}
        />
        <div className={styles.mortgage__submit}>
            <Button
                type={Button.TYPE.BUTTON}
                color={Button.COLOR.BLUE}
                size={Button.SIZE.LARGE}
                onClick={form.onSubmit}
                block
            >
                Рассчитать
            </Button>
        </div>
        <div className={styles.mortgage__footer}>
            <div className={styles.mortgage__total}>
                Ежемесячный платеж
                <div className={`${styles.mortgage__value} ${styles['mortgage__value--month']}`}>
                    {numberWithSpaces(monthlyPayment)}
                    {' '}
                    руб.
                </div>
            </div>
            <div className={styles.mortgage__total}>
                Переплата за кредит
                <div className={`${styles.mortgage__value} ${styles['mortgage__value--total']}`}>
                    {numberWithSpaces(totalOverPayment)}
                    {' '}
                    руб.
                </div>
            </div>
        </div>
        <br />
    </div>
);

MortgageCalculator.propTypes = {
    monthlyPayment: PropTypes.string,
    totalOverPayment: PropTypes.string,
    form: PropTypes.shape({
        render: PropTypes.arrayOf(PropTypes.shape({})),
        onSubmit: PropTypes.func,
    }),
    useStores: PropTypes.func,
};

MortgageCalculator.defaultProps = {
    monthlyPayment: '0',
    totalOverPayment: '0',
    form: {
        onSubmit: () => null,
    },
    useStores: () => null,
};

export default MortgageCalculator;
