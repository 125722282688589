/* global window, document */
import { useEffect, useRef } from 'react';

let keyPressed = false;
['keyup', 'keydown'].forEach((event) => {
    window.addEventListener(event, () => {
        document.onselectstart = () => !keyPressed;
    });
});

const useKeyPress = (targetKey) => {
    const latestValue = useRef(false);
    useEffect(() => {
        const downHandler = (e) => {
            const { key } = e;
            if (key === targetKey) {
                keyPressed = true;
                latestValue.current = true;
            }
        };
        const upHandler = (e) => {
            const { key } = e;
            if (key === targetKey) {
                keyPressed = false;
                latestValue.current = false;
            }
        };
        const blurHandler = () => {
            keyPressed = false;
            latestValue.current = false;
        };
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        window.addEventListener('blur', blurHandler);
        return () => {
            keyPressed = false;
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
            window.removeEventListener('blur', blurHandler);
        };
    }, []);
    return () => latestValue.current;
};

export default useKeyPress;
