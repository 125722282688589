import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Observer } from 'mobx-react';
import useStores from '../../../hooks/client/use-stores';
import { ACTIVATE_DEVICE_STATE } from '../../../constants/client';

const App = ({
    children,
}) => {
    const history = useHistory();
    const { deviceStore, sessionStore, directoriesStore } = useStores();
    useEffect(() => {
        const subscriptions = [];
        if (sessionStore.isAuthorized) {
            directoriesStore.loadDefaults();
        } else {
            subscriptions.push(sessionStore.on('user:authorized', () => {
                directoriesStore.loadDefaults();
            }));
        }
        subscriptions.push(deviceStore.on('device:ready', () => {
            sessionStore.tryAuth();
        }));
        subscriptions.push(deviceStore.on('device:error', ({ code }) => {
            history.push(`/activate/${code === ACTIVATE_DEVICE_STATE.NO_REACTIVATION_AVAILABLE ? 'block' : 're-activate'}`);
        }));
        return () => {
            subscriptions.forEach((unsubscribe) => {
                unsubscribe();
            });
        };
    }, []);
    return (
        <Observer>
            {() => (
                deviceStore.fingerprint
                    ? children
                    : 'loading...'
            )}
        </Observer>
    );
};

App.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
};

App.defaultProps = {
    children: null,
};

export default App;
