import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

import styles from './CheckboxList.module.scss';

const CheckboxList = ({
    id,
    className,
    name,
    columns,
    options,
    value,
    disabled,
    size,
    onChange,
    readOnly,
    error,
    colored,
}) => {
    const style = {};
    if (columns) {
        style.display = 'grid';
        style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
    }
    const handleChange = (val) => (e) => {
        const { checked } = e.target;
        let output = value instanceof Array ? value : [];
        if (checked) {
            output.push(val);
        } else {
            output = output.filter((checkedValue) => checkedValue !== val);
        }
        if (onChange && typeof onChange === 'function') {
            return onChange(output);
        }
        return output;
    };
    return (
        <div
            id={id}
            style={style}
            className={`
                ${styles['checkbox-list']}
                ${styles[`checkbox-list--${size}`]}
                ${error ? styles['checkbox-list--error'] : ''}
                ${colored ? styles['checkbox-list--colored'] : ''}
                ${className}
            `}
        >
            {
                options.map((item) => {
                    const {
                        value: itemValue,
                        label,
                    } = item;
                    return (
                        <div
                            key={`checkbox-${itemValue}`}
                            className={styles['checkbox-list__item']}
                        >
                            <Checkbox
                                id={`checkbox-${itemValue}`}
                                name={name}
                                value={itemValue}
                                size={size}
                                checked={value ? value.includes(itemValue) : false}
                                disabled={disabled || item.disabled}
                                onChange={handleChange(itemValue, label)}
                                readOnly={readOnly}
                                className={`${styles['checkbox-list__checkbox']} ${styles[`checkbox-list__checkbox--${size}`]}`}
                            >
                                {label}
                            </Checkbox>
                        </div>
                    );
                })
            }
            {
                error
                    ? (
                        <div className={styles['checkbox-list__error']}>
                            {error}
                        </div>
                    )
                    : null
            }
        </div>
    );
};

CheckboxList.SIZE = { ...Checkbox.SIZE };

CheckboxList.TYPE = { ...Checkbox.TYPE };

CheckboxList.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    columns: PropTypes.number,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
    ]),
    size: PropTypes.oneOf(Object.values(CheckboxList.SIZE)),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        label: PropTypes.string,
    })).isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    colored: PropTypes.bool,
    error: PropTypes.string,
};

CheckboxList.defaultProps = {
    id: '',
    className: '',
    columns: 0,
    size: CheckboxList.SIZE.MEDUIM,
    value: undefined,
    disabled: false,
    onChange: null,
    readOnly: false,
    colored: true,
    error: '',
};

export default CheckboxList;
