import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { Button } from '../Button';
import { SIZE } from '../../constants/shared';

import styles from './Companies.module.scss';

const Companies = ({
    id: fieldId,
    value,
    options,
    onChange,
}) => {
    const handleChange = (e) => {
        const { target: { checked, value: current } } = e;
        let output = value || [];
        if (checked) {
            output.push(Number(current));
        } else {
            output = output.filter((checkedId) => checkedId !== Number(current));
        }
        onChange(output);
    };
    const selectAll = () => {
        onChange(options.map(({ value: optionValue }) => optionValue));
    };
    const reset = () => {
        onChange([]);
    };
    return (
        <div className={styles.companies}>
            <div className={styles.companies__list}>
                {
                    value && value
                        .sort((a, b) => {
                            const { label: aLabel } = options.find(({ value: optionValue }) => (
                                optionValue === a
                            ));
                            const { label: bLabel } = options.find(({ value: optionValue }) => (
                                optionValue === b
                            ));
                            if (aLabel > bLabel) {
                                return 1;
                            }
                            if (aLabel < bLabel) {
                                return -1;
                            }
                            return 0;
                        })
                        .map((checkedValue) => {
                            const { label } = options.find(({ value: optionValue }) => (
                                optionValue === checkedValue
                            ));
                            return (
                                <div className={styles.companies__item}>
                                    <Checkbox
                                        key={`company-${fieldId}-${checkedValue}`}
                                        id={`company-${fieldId}-${checkedValue}`}
                                        name={`company-${fieldId}-${checkedValue}`}
                                        className={styles.companies__checkbox}
                                        checked
                                        value={checkedValue}
                                        onChange={handleChange}
                                        size={SIZE.MEDIUM}
                                    >
                                        {label}
                                    </Checkbox>
                                </div>
                            );
                        })
                }
                {
                    value && value.length && value.length !== options.length
                        ? <hr />
                        : null
                }
                {
                    options && options
                        .filter(({ value: optionValue }) => !value.includes(optionValue))
                        .map(({ value: optionValue, label }) => (
                            <div className={styles.companies__item}>
                                <Checkbox
                                    key={`company-${fieldId}-${optionValue}`}
                                    id={`company-${fieldId}-${optionValue}`}
                                    name={`company-${fieldId}-${optionValue}`}
                                    className={styles.companies__checkbox}
                                    value={optionValue}
                                    onChange={handleChange}
                                    size={SIZE.MEDIUM}
                                >
                                    {label}
                                </Checkbox>
                            </div>
                        ))
                }
            </div>
            <div className={styles['companies__action-list']}>
                <Button
                    color={Button.COLOR.WHITE_BLACK}
                    className={styles.companies__action}
                    size={Button.SIZE.LARGE}
                    disabled={options.length === value.length}
                    block
                    onClick={selectAll}
                >
                    Выбрать все
                </Button>
                <Button
                    color={Button.COLOR.WHITE_BLACK}
                    className={styles.companies__action}
                    size={Button.SIZE.LARGE}
                    disabled={value.length === 0}
                    block
                    onClick={reset}
                >
                    Отменить все
                </Button>
                <div className={styles.companies__warning}>
                    В этом списке показываются только компании,
                    которые не привязаны ни к одному оператору
                    или привязаны только к текущему
                </div>
            </div>
        </div>
    );
};

Companies.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                    ]),
                }),
            ]),
        ),
    ]),
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ]),
    ),
    onChange: PropTypes.func,
};

Companies.defaultProps = {
    id: '',
    value: [],
    options: [],
    onChange: () => null,
};

export default Companies;
