import React from 'react';
import PropTypes from 'prop-types';
import { EMPTY_DATA_STRING } from '../../../constants/shared';

import styles from './ObjectComponent.module.scss';

const ObjectComponentHistory = ({
    history,
}) => (
    <div className={`${styles.history}`}>
        <h2 className={styles['block-title']}>
            <span>История</span>
        </h2>
        <div className={styles.history__container}>
            {
                history && history.length
                    ? history.map((historyItem) => (
                        <div className={styles.history__item} key={historyItem.id}>
                            <div className={styles.history__changes}>
                                <div>
                                    {historyItem.operator || historyItem.user || ''}
                                </div>
                                {
                                    historyItem.changed && historyItem.changed.length
                                        ? (historyItem.changed.map((changed, index) => (
                                            <div key={`${historyItem.id}-${index + 1}`}>
                                                {`${changed.name}: ` || ''}
                                                {
                                                    changed.action !== 'ADD'
                                                        ? changed.prevValue ?? EMPTY_DATA_STRING
                                                        : 'добавлено'
                                                }
                                                &nbsp;
                                                →
                                                &nbsp;
                                                {
                                                    changed.action !== 'DELETE'
                                                        ? changed.newValue ?? EMPTY_DATA_STRING
                                                        : 'удалено'
                                                }
                                            </div>
                                        )))
                                        : null
                                }
                                {
                                    historyItem.reason
                                        ? (
                                            <div>
                                                {`Причина: ${historyItem.reason || EMPTY_DATA_STRING}`}
                                            </div>
                                        )
                                        : null
                                }
                                {
                                    historyItem.comment
                                        ? (
                                            <div>
                                                {`Комментарий: ${historyItem.comment || EMPTY_DATA_STRING}`}
                                            </div>
                                        )
                                        : null
                                }
                            </div>
                            <div className={styles.history__datetime}>
                                {historyItem.datetime}
                            </div>
                        </div>
                    ))
                    : null
            }
        </div>
    </div>
);

ObjectComponentHistory.propTypes = {
    history: PropTypes.arrayOf(
        PropTypes.shape({
            operator: PropTypes.string,
            changed: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    prevValue: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                    ]),
                    newValue: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                    ]),
                }),
            ),
            prevValue: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    ),
};

ObjectComponentHistory.defaultProps = {
    history: [],
};

export default ObjectComponentHistory;
