import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';
import { Button } from '../../../components/Button';

import styles from './ExportObjects.module.scss';

const ExportObjectsField = ({
    label,
    id,
    hidden,
    name,
    type,
    children,
    value,
    disabled,
    form,
}) => {
    if (hidden) return null;
    const handleClearClick = () => {
        form.$(name).set('value', null);
    };
    return (
        <>
            <div
                className={`
                    ${styles['export-objects__field']}
                    ${(styles[`export-objects__field--${name}`] ? styles[`export-objects__field--${name}`] : '')}
                    ${disabled ? styles['export-objects__field--disabled'] : ''}
                    ${hidden ? 'hidden' : ''}
                `}
            >
                {
                    label
                        ? (
                            <Label
                                className={styles['export-objects__label']}
                                htmlFor={id}
                            >
                                {label}
                            </Label>
                        )
                        : null
                }
                {children}
            </div>
            {
                type === 'checkboxList'
                    ? (
                        <div className={styles['export-objects__clear']}>
                            <Button
                                type={Button.TYPE.BUTTON}
                                size={Button.SIZE.SMALL}
                                color={Button.COLOR.WHITE_BLACK}
                                disabled={!value || !value.length || disabled}
                                onClick={handleClearClick}
                            >
                                Очистить
                            </Button>
                        </div>
                    )
                    : null
            }
        </>
    );
};

ExportObjectsField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    hidden: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.element.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({}),
        PropTypes.bool,
    ]),
    disabled: PropTypes.bool,
    form: PropTypes.shape({
        $: PropTypes.func,
    }).isRequired,
};

ExportObjectsField.defaultProps = {
    label: '',
    hidden: false,
    name: '',
    type: '',
    value: [],
    disabled: false,
};

export default ExportObjectsField;
