const {
    REACT_APP_API_PROTOCOL,
    REACT_APP_API_HOST,
    REACT_APP_API_PREFIX,
    REACT_APP_ROLLBAR_TOKEN,
    REACT_APP_ROLLBAR_ENVIRONMENT,
    REACT_APP_ROLLBAR_CODE_VERSION,
    REACT_APP_VK_CLIENT_ID,
    REACT_APP_OK_CLIENT_ID,
    REACT_APP_OK_REDIRECT_URL,
    REACT_APP_OBJECT_CREATED_AT_IS_FOR_ADDED_AT,
    REACT_APP_OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT,
    REACT_APP_OBJECT_SHOW_ALL_GROUP,
    REACT_APP_CHAT_BOT_NAME,
} = process.env;

const APP_NAME = 'client';
const API_PROTOCOL = REACT_APP_API_PROTOCOL || 'http';
const API_HOST = REACT_APP_API_HOST || 'localhost:3000';
const API_PREFIX = REACT_APP_API_PREFIX ? `/${REACT_APP_API_PREFIX}` : '';
const ROLLBAR_TOKEN = REACT_APP_ROLLBAR_TOKEN;
const ROLLBAR_ENVIRONMENT = REACT_APP_ROLLBAR_ENVIRONMENT || 'production';
const ROLLBAR_CODE_VERSION = REACT_APP_ROLLBAR_CODE_VERSION || '1.0.0';
const VK_CLIENT_ID = REACT_APP_VK_CLIENT_ID;
const OK_CLIENT_ID = REACT_APP_OK_CLIENT_ID;
const OK_REDIRECT_URL = REACT_APP_OK_REDIRECT_URL;
const OBJECT_SHOW_ALL_GROUP = REACT_APP_OBJECT_SHOW_ALL_GROUP
    === true.toString();
const OBJECT_CREATED_AT_IS_FOR_ADDED_AT = REACT_APP_OBJECT_CREATED_AT_IS_FOR_ADDED_AT
    === true.toString();
// eslint-disable-next-line max-len
const OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT = REACT_APP_OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT
    === true.toString();
const CHAT_BOT_NAME = REACT_APP_CHAT_BOT_NAME || 'kric_chat_bot';

export {
    APP_NAME,
    API_PROTOCOL,
    API_HOST,
    API_PREFIX,
    ROLLBAR_TOKEN,
    ROLLBAR_ENVIRONMENT,
    ROLLBAR_CODE_VERSION,
    VK_CLIENT_ID,
    OK_CLIENT_ID,
    OK_REDIRECT_URL,
    OBJECT_CREATED_AT_IS_FOR_ADDED_AT,
    OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT,
    OBJECT_SHOW_ALL_GROUP,
    CHAT_BOT_NAME,
};
