import { computed } from 'mobx';
import BaseItem from './BaseItem';
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter';

class HouseItem extends BaseItem {
    @computed
    get objectName() {
        const {
            streetName,
            houseNumber,
            phone,
            id,
        } = this;
        const fullName = [streetName, houseNumber]
            .filter((address) => Boolean(address))
            .join(', ');
        return fullName || phone || id;
    }

    @computed
    get houseTypeName() {
        const { houseType } = this;
        if (!houseType) {
            return null;
        }
        return houseType.name;
    }

    @computed
    get wallMaterialName() {
        const { wallMaterial } = this;
        if (!wallMaterial) {
            return null;
        }
        return wallMaterial.shortname
            ? capitalizeFirstLetter(wallMaterial.shortname)
            : wallMaterial.name;
    }

    @computed
    get dateOfConstruction() {
        const { yearOfConstruction } = this;
        return yearOfConstruction;
    }

    @computed
    get floorCountWithWallMaterial() {
        const { wallMaterialName } = this;
        return `${this.floorCount || '-/-'} ${wallMaterialName?.slice(0, 3).toLowerCase() || ''}`.trim();
    }

    @computed
    get roomCount() {
        const { $roomCount } = this;
        if ($roomCount === null || $roomCount === undefined) {
            return null;
        }
        return `${$roomCount}`;
    }
}

export default HouseItem;
