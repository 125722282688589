import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonClose } from '../../../components/Button';

import styles from './NotepadObjects.module.scss';

const NotepadApprove = ({
    data,
    onClose,
    onApprove,
    onCancel,
    toggleApprovingState,
}) => {
    // Метод при подтверждение сохранения
    const handleApproveClick = () => {
        onApprove(data);
        toggleApprovingState();
    };
    // Метод закрывает стейт подтверждения
    // eslint-disable-next-line no-unused-vars
    const handleCloseClick = () => {
        onClose(data);
        toggleApprovingState();
    };
    const handleCancelClick = () => {
        onCancel(data);
        toggleApprovingState();
    };
    return (
        <div className={styles.approving}>
            <div className={styles.approving__inner}>
                <ButtonClose className={styles.approving__close} onClick={handleCancelClick} />
                <div className={styles.approving__box}>
                    <div className={styles.approving__message}>
                        {data.message}
                    </div>
                    {
                        data.description
                            ? (
                                <div className={styles.approving__description}>
                                    {data.description}
                                </div>
                            )
                            : null
                    }
                    <div className={styles.approving__buttons}>
                        <Button
                            type={Button.TYPE.BUTTON}
                            size="md"
                            color="orange"
                            className={styles.approving__button}
                            onClick={handleApproveClick}
                            block
                        >
                            Да, удалить
                        </Button>
                        <Button
                            type={Button.TYPE.BUTTON}
                            size="md"
                            color="gray"
                            className={styles.approving__button}
                            onClick={handleCancelClick}
                            block
                        >
                            Отмена
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

NotepadApprove.propTypes = {
    data: PropTypes.shape({
        message: PropTypes.string,
        description: PropTypes.string,
    }),
    onClose: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    toggleApprovingState: PropTypes.func.isRequired,
};

NotepadApprove.defaultProps = {
    data: {},
    onCancel: () => null,
};

export default NotepadApprove;
