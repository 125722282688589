import requestFactory from '../../utils/request';
import createPaymentService from './PaymentService';
import createSessionService from './SessionService';
import createAppService from './AppService';
import createObjectService from './ObjectService';
import createDirectoryService from '../shared/DirectoryService';
import createNotepadService from '../shared/NotepadService';
import createStorageService from './StorageService';
import createUserService from './UserService';
import createDaDataService from '../shared/DaDataService';
import createPresetService from '../shared/PresetService';
import createPriceStatsService from './PriceStatsService';
import createSocialNetworkService from './SocialNetworkService';

import {
    API_PROTOCOL,
    API_HOST,
    API_PREFIX,
} from '../../config/client';

const apiRequest = requestFactory(API_PROTOCOL, API_HOST, API_PREFIX);

const PaymentService = createPaymentService(apiRequest);
const SessionService = createSessionService(apiRequest);
const AppService = createAppService(apiRequest);
const ObjectService = createObjectService(apiRequest);
const DirectoryService = createDirectoryService(apiRequest);
const NotepadService = createNotepadService(apiRequest);
const StorageService = createStorageService();
const UserService = createUserService(apiRequest);
const DaDataService = createDaDataService(apiRequest);
const PresetService = createPresetService(apiRequest);
const PriceStatsService = createPriceStatsService(apiRequest);
const SocialNetworkService = createSocialNetworkService(apiRequest);

export default apiRequest;
export {
    SessionService,
    AppService,
    ObjectService,
    DirectoryService,
    NotepadService,
    StorageService,
    UserService,
    DaDataService,
    PresetService,
    PaymentService,
    PriceStatsService,
    SocialNetworkService,
};
