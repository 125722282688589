import videoField from './video';
import { DIRECTORIES } from '../../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'basicInfo',
    label: 'Основная информация',
    fields: [{
        name: 'basicInfoLeft',
        type: 'fieldgroup',
        fields: [{
            name: 'roomCount',
            type: 'integer',
            label: 'Комнат',
        }, {
            name: 'effluent',
            type: 'directorySelect',
            directory: DIRECTORIES.EFFLUENT,
            label: 'Сток',
            searchable: true,
            clearable: true,
        }, {
            name: 'houseType',
            type: 'directorySelect',
            directory: DIRECTORIES.OBJECT_HOUSE_TYPE,
            label: 'Тип дома',
            rules: 'required',
            searchable: true,
            clearable: true,
        }, {
            name: 'wallMaterial',
            type: 'directorySelect',
            directory: DIRECTORIES.WALL_MATERIAL,
            label: 'Материал',
            searchable: true,
            clearable: true,
        }, {
            name: 'objectCondition',
            type: 'directorySelect',
            directory: DIRECTORIES.OBJECT_CONDITION,
            label: 'Состояние',
            searchable: true,
            clearable: true,
        }, {
            name: 'waterSupply',
            type: 'directorySelect',
            directory: DIRECTORIES.WATER_SUPPLY,
            label: 'Вода',
            searchable: true,
            clearable: true,
        }, {
            name: 'yearOfConstruction',
            type: 'integer',
            size: 'sm',
            label: 'Год сдачи',
        }],
    }, {
        name: 'basicInfoRight',
        type: 'fieldgroup',
        fields: [{
            name: 'floorCount',
            type: 'integer',
            size: 'sm',
            label: 'Этажей',
        }, {
            name: 'totalArea',
            type: 'numeric',
            size: 'sm',
            label: 'Площадь',
            postfix: 'м²',
        }, {
            name: 'landArea',
            type: 'numeric',
            size: 'sm',
            label: 'Площадь участка',
            postfix: 'сот.',
        }, {
            name: 'price',
            type: 'integer',
            size: 'sm',
            label: 'Цена',
            postfix: 'т.р.',
        }, {
            name: 'unitPrice',
            type: 'numeric',
            size: 'sm',
            label: 'Цена за м²',
            postfix: 'т.р.',
        }, {
            name: 'hasDeposit',
            type: 'checkbox',
            size: 'md',
            label: 'Депозит',
        }, {
            name: 'isLandOwned',
            type: 'checkbox',
            size: 'md',
            label: 'В собственности',
        }, {
            name: 'exchange',
            type: 'checkbox',
            label: 'Обмен',
            size: 'md',
            rows: 1,
        }, {
            name: 'ownerType',
            type: 'directoryRadioList',
            directory: DIRECTORIES.OWNER_TYPE,
            rules: 'required',
            size: 'md',
            rows: 1,
        }],
    }],
}, {
    type: 'fieldset',
    name: 'additionalInfo',
    label: 'Дополнительная информация',
    fields: [{
        name: 'additionalInfoLeft',
        type: 'fieldgroup',
        fields: [{
            name: 'heating',
            type: 'directorySelect',
            directory: DIRECTORIES.HEATING,
            label: 'Отопление',
            clearable: true,
            searchable: true,
        }, {
            name: 'hasCellar',
            type: 'checkbox',
            label: 'Есть подвал',
            size: 'md',
        }, {
            name: 'hasGarage',
            type: 'checkbox',
            label: 'Есть гараж',
            size: 'md',
        }, {
            name: 'hasGas',
            type: 'checkbox',
            label: 'Проведен газ',
            size: 'md',
        }, {
            name: 'hasSauna',
            type: 'checkbox',
            label: 'Есть сауна',
            size: 'md',
        }, {
            name: 'hasSummerKitchen',
            type: 'checkbox',
            label: 'Есть летняя кухня',
            size: 'md',
        }],
    }, {
        name: 'additionalInfoRight',
        type: 'fieldgroup',
        fields: [{
            name: 'furnishing',
            type: 'directorySelect',
            directory: DIRECTORIES.FURNISHING,
            clearable: true,
            searchable: true,
            label: 'Мебель',
        }],
    }, {
        name: 'operatorNoteWrapper',
        type: 'fieldgroup',
        fields: [{
            name: 'publicNote',
            type: 'textarea',
            rows: 3,
            label: 'Внешнее примечание',
        }, {
            name: 'privateNote',
            type: 'textarea',
            rows: 3,
            label: 'Внутреннее примечание',
        }],
    }],
}, videoField, {
    type: 'fieldset',
    name: 'note',
    label: 'Текст объявления',
    fields: [{
        name: 'note',
        type: 'textarea',
        rows: 14,
    }],
}, {
    type: 'fieldset',
    name: 'location',
    label: 'Расположение',
    fields: [{
        name: 'area',
        type: 'directorySearch',
        directory: DIRECTORIES.AREA,
        label: 'Район субъекта',
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'city',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        rules: 'required',
        searchable: true,
        clearable: true,
        withDefault: true,
    }, {
        name: 'cityDistrict',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        searchable: true,
        clearable: true,
        defaultCtx: { fromCard: true },
    }, {
        name: 'street',
        type: 'directorySearch',
        directory: DIRECTORIES.STREET,
        label: 'Улица',
        searchable: true,
        clearable: true,
    }, {
        name: 'house',
        type: 'directorySearch',
        label: '№ дома',
        directory: DIRECTORIES.HOUSE,
        searchable: true,
        clearable: true,
    }, {
        label: 'Ориентир',
        name: 'cityDistrictLandmark',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT_LANDMARK,
        searchable: true,
        clearable: true,
    }, {
        name: 'longitude',
        hidden: true,
    }, {
        name: 'latitude',
        hidden: true,
    }],
}, {
    type: 'fieldset',
    name: 'contacts',
    label: 'Контакты',
    fields: [{
        type: 'fieldgroup',
        name: 'contacts',
        fields: [{
            label: 'Контакты',
            name: 'phones',
            type: 'contact',
            rules: 'contact|required',
            size: 'sm',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'user-note',
    label: '',
    fields: [{
        name: 'userNote',
        rows: 3,
        type: 'textarea',
        label: 'Личные заметки',
        tooltip: 'Эти заметки видны только вам (пользователю)',
        readonly: false,
    }],
}, {
    type: 'fieldset',
    name: 'incorrect',
    label: 'Некорректность',
    fields: [{
        name: 'incorrect',
        type: 'directoryCheckboxList',
        size: 'md',
        columns: 2,
        colored: false,
        directory: DIRECTORIES.INCORRECT_OPTIONS,
        alwaysEditable: true,
    }],
}];
