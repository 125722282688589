import { computed } from 'mobx';
import BaseItem from './BaseItem';

class SteadItem extends BaseItem {
    @computed
    get objectName() {
        const {
            streetName,
            houseNumber,
            phone,
            id,
        } = this;
        const fullName = [streetName, houseNumber]
            .filter((address) => Boolean(address))
            .join(', ');
        return fullName || phone || id;
    }

    @computed
    get steadTypeName() {
        const { steadType } = this;
        if (!steadType) {
            return null;
        }
        return steadType.name;
    }

    @computed
    get passageName() {
        const { passage } = this;
        if (!passage) {
            return null;
        }
        return passage.name;
    }

    @computed
    get buildingsName() {
        const { buildings } = this;
        if (!buildings) {
            return null;
        }
        return buildings.name;
    }

    @computed
    get squareTotal() {
        const { totalArea } = this;
        return totalArea || null;
    }
}

export default SteadItem;
