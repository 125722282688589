import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const CustomValueContainer = ({ children, ...valueContainerProps }) => {
    const { maxMultiValues } = valueContainerProps.selectProps;
    const currentValue = valueContainerProps.getValue();
    const currentValuesCount = currentValue.length || 0;
    if (currentValuesCount <= maxMultiValues) {
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <components.ValueContainer {...valueContainerProps}>
                {children}
            </components.ValueContainer>
        );
    }
    const updatedChildren = [
        `${currentValuesCount} выбрано`,
        // eslint-disable-next-line react/prop-types
        ...children.slice(-1),
    ];
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.ValueContainer {...valueContainerProps}>
            {updatedChildren}
        </components.ValueContainer>
    );
};

CustomValueContainer.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
};

CustomValueContainer.defaultProps = {
    children: [],
};

export default CustomValueContainer;
