import {
    observable,
    computed,
    action,
} from 'mobx';
import ListItem from './entities/ListItem';

class List {
    @observable $list = [];

    Item;

    constructor(Item = ListItem) {
        this.Item = Item;
    }

    @computed
    get list() {
        return this.$list.slice();
    }

    @computed
    get memoKey() {
        return this.$list.map(({ memoKey }) => memoKey).join('');
    }

    getItem(id) {
        return this.$list.find((item) => item.id === id);
    }

    toItem(data) {
        const { Item } = this;
        return new Item(data, this);
    }

    @action
    setList(list = []) {
        const providedList = list.map((item) => this.toItem(item));
        this.$list = providedList;
    }

    @action
    addList(list = []) {
        const providedList = list.map((item) => this.toItem(item));
        this.$list = this.$list.concat(providedList);
    }

    @action
    addItem(item) {
        this.$list.push(this.toItem(item));
    }

    @action
    unshiftItem(item) {
        this.$list.unshift(this.toItem(item));
    }
}

export default List;
