import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_DATA_STRING } from '../../../constants/shared';

const CellWithNewLine = ({ value }) => {
    if (typeof value === 'string' && value.includes('\n')) {
        const lines = value.split('\n');
        return lines.map((line) => <div key={line}>{line}</div>);
    }
    return value || EMPTY_DATA_STRING;
};

CellWithNewLine.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
        PropTypes.string,
    ]),
};

CellWithNewLine.defaultProps = {
    value: '',
};

export default CellWithNewLine;
