/* global alert, window */
import { observable, action } from 'mobx';
import Token from './entities/Token';
import createForm from '../../../helpers/Form';
import { EventEmitter } from '../../../helpers/EventEmitter';
import { MAX_DELAY } from '../../../constants/shared';

class Session {
    #token = new Token();

    #tokenUpdateTimeout = 0;

    #eventEmitter = new EventEmitter();

    loginForm;

    @observable
    isAuthorized = false;

    constructor(sessionService, storageService, { loginFields = [], tokenUpdateLag = 0, tokenName = 'accessToken' }) {
        this.sessionService = sessionService;
        this.storageService = storageService;
        this.loginForm = createForm(loginFields);
        this.tokenUpdateLag = tokenUpdateLag;
        this.tokenName = tokenName;
        const { loginForm } = this;
        loginForm.on('success', this.login.bind(this));
    }

    get token() {
        return this.#token.value;
    }

    set token(newToken) {
        const token = this.#token;
        const { storageService, tokenUpdateLag, tokenName } = this;
        const tokenUpdateTimeout = this.#tokenUpdateTimeout;
        if (tokenUpdateTimeout) {
            clearTimeout(tokenUpdateTimeout);
        }
        token.value = newToken;
        storageService(tokenName, {
            accessToken: token.value,
            expiresAt: token.expiresAt,
        });
        const timeout = Math.max(token.expiresAt - Date.now() - tokenUpdateLag, 0);
        const setTimeoutWithLargeDelay = (fn, delay) => {
            if (delay > MAX_DELAY) {
                this.#tokenUpdateTimeout = setTimeout(() => {
                    setTimeoutWithLargeDelay(fn, delay - MAX_DELAY);
                }, MAX_DELAY);
                return;
            }
            this.#tokenUpdateTimeout = setTimeout(fn, delay);
        };
        setTimeoutWithLargeDelay(() => {
            this.updateToken();
        }, timeout);
    }

    tryAuth() {
        const { storageService, tokenName, tokenUpdateLag } = this;
        const { accessToken, expiresAt } = storageService(tokenName) || {};
        const now = Date.now();
        if (accessToken && expiresAt > now - tokenUpdateLag) {
            this.token = {
                value: accessToken,
                expiresIn: (expiresAt - now) / 10 ** 3,
            };
            this.authorize();
        }
    }

    updateToken() {
        const { sessionService } = this;
        sessionService.refresh()
            .then((response) => {
                this.token = {
                    ...response,
                    value: response.accessToken,
                };
            })
            .catch((e) => alert(e));
    }

    addTokenToRequest(config) {
        const { token } = this;
        const modifiedConfig = config;
        if (!token) {
            return config;
        }
        if (!modifiedConfig.headers.Authorization) {
            modifiedConfig.headers.Authorization = `bearer ${token}`;
        }
        return modifiedConfig;
    }

    checkResponseStatus(error) {
        this.login = '';
        const { response } = error;
        if (response?.status !== 401) {
            window.rollbar.error(error);
            return Promise.reject(error);
        }
        this.logout();
        return Promise.reject(error);
    }

    login({ login, password }) {
        const { sessionService } = this;
        return sessionService.login({ login, password })
            .then((response) => {
                this.token = response;
                this.authorize();
            })
            .catch((e) => { alert(e); console.error(e); });
    }

    on(...rest) {
        return this.#eventEmitter.subscribe(...rest);
    }

    @action
    logout() {
        const { storageService, tokenName } = this;
        storageService.remove(tokenName);
        this.isAuthorized = false;
    }

    @action
    authorize() {
        this.authorizeEvent();
    }

    @action
    authorizeEvent() {
        this.isAuthorized = true;
        this.#eventEmitter.emit('user:authorized');
    }
}

const createSessionStore = () => new Session();

export default Session;
export {
    createSessionStore,
};
