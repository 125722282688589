import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import { Button } from '../../../components/Button';

import styles from './Dialog.module.scss';

const ApproveDialog = ({
    children,
    onApprove,
    onCancel,
    onClose,
    useStores,
    name,
    approveDisabled,
    ...props
}) => {
    const { dialogStore } = useStores();
    const executeAsPromise = (f) => () => {
        const result = f();
        if (result instanceof Promise) {
            return result;
        }
        return Promise.resolve(f);
    };
    const handleApproveClick = () => (
        executeAsPromise(onApprove)()
            .then(() => dialogStore.hide())
    );
    const handleCancelClick = () => (
        executeAsPromise(onCancel)()
            .then(() => dialogStore.hide())
    );
    return (
        <Dialog
            useStores={useStores}
            name={name}
            size="sm"
            className={styles.approve}
            onCancel={handleCancelClick}
            onClose={onClose}
            footer={(
                <div className={styles.approve__footer}>
                    <div className={styles.approve__button}>
                        <Button
                            size={Button.SIZE.LARGE}
                            color={Button.COLOR.GRAY}
                            onClick={handleCancelClick}
                            block
                        >
                            Отмена
                        </Button>
                    </div>
                    <div className={styles.approve__button}>
                        <Button
                            size={Button.SIZE.LARGE}
                            color={Button.COLOR.ORANGE}
                            onClick={handleApproveClick}
                            block
                            disabled={approveDisabled}
                        >
                            Да
                        </Button>
                    </div>
                </div>
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <div className={styles.approve__message}>
                {children}
            </div>
        </Dialog>
    );
};

ApproveDialog.propTypes = {
    onApprove: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    useStores: PropTypes.func,
    approveDisabled: PropTypes.bool,
    ...ApproveDialog.propTypes,
};

ApproveDialog.defaultProps = {
    onApprove: () => null,
    onCancel: () => null,
    onClose: () => null,
    useStores: () => null,
    approveDisabled: false,
    ...ApproveDialog.defaultProps,
};

export default ApproveDialog;
