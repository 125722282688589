/* global document */
import {
    extendObservable,
    runInAction,
    computed,
    action,
} from 'mobx';
import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import { bindings, defaultBindings } from './_bindings';
import { EventEmitter } from '../EventEmitter';
import DefaultField from './fields/DefaultField';
import Select from './fields/Select';
import DatePicker from './fields/DatePicker';
import Numeric from './fields/Numeric';
import CheckBox from './fields/CheckBox';
import TagList from './fields/TagList';
import CheckboxList from './fields/CheckboxList';
import Contact from './fields/Contact';
import Phone from './fields/Phone';
import plugins from './utils/validatorjs';
import createFields from './utils/create-fields';
import nullObject from '../../utils/null-object';
import clearObject from '../../utils/clear-object';
import clearObjectExceptSuburb from '../../utils/clear-object-except-suburb';

class ExtendedForm extends Form {
    #eventEmitter = new EventEmitter();

    render = [];

    constructor(fields = []) {
        super({
            fields: createFields(
                fields,
                bindings,
                {
                    ...defaultBindings,
                    rules: undefined,
                    related: undefined,
                },
            ),
        }, {
            options: {
                retrieveOnlyEnabledFields: true,
                validateDeletedFields: false,
                validateDisabledFields: false,
            },
        });
        this.render = fields;
        extendObservable(
            this,
            {
                loading: false,
                snapshot: null,
                $editable: true,
            },
        );
        this.makeSnapshot();
    }

    // eslint-disable-next-line class-methods-use-this
    clearValues(values) {
        return clearObject(values);
    }

    // eslint-disable-next-line class-methods-use-this
    nullValues(values) {
        return nullObject(values);
    }

    // eslint-disable-next-line class-methods-use-this
    clearValuesExceptSuburb(values) {
        return clearObjectExceptSuburb(values);
    }

    set editable(value) {
        const { $editable: editable } = this;
        if (editable === value) {
            return;
        }
        this.$editable = value;
        this.fields.forEach((field) => {
            if (!field?.extra?.alwaysEditable) {
                field.resetValidation();
                field.set('extra', { ...field.get('extra'), readOnly: value === false });
                field.set('disabled', true);
                field.set('options', {
                    validateDisabledFields: false,
                });
            }
        });
    }

    @computed
    get editable() {
        return this.$editable;
    }

    @computed
    get snapshotIsDiffer() {
        return JSON.stringify(this.values()) !== this.snapshot;
    }

    // eslint-disable-next-line class-methods-use-this
    plugins() {
        const validatorjs = dvr(plugins.dvr);
        validatorjs.class.prototype.makeLabels = function makeLabels(validation, field) {
            const labels = { [field.path]: field.extra.attributeName || field.label };
            Object.keys(validation.rules[field.path]).forEach((key) => {
                const rule = validation.rules[field.path][key];
                if (typeof rule.value === 'string' && rule.name.match(/^(required_|same|different|lte|lt|gte|gt)/)) {
                    rule.value.split(',').forEach((p, i) => {
                        if (!rule.name.match(/^required_(if|unless)/) || (i % 2 === 0)) {
                            const f = this.state.form.$(p);
                            if (f && f.path && (f.extra.attributeName || f.label)) {
                                labels[rule.name] = f.extra.attributeName || f.label;
                            }
                        }
                    });
                }
            });
            validation.setAttributeNames(labels);
        };
        return { dvr: validatorjs };
    }

    // eslint-disable-next-line class-methods-use-this
    bindings() {
        return bindings;
    }

    // eslint-disable-next-line class-methods-use-this
    makeField(props) {
        switch (props.data.type) {
            case 'select':
            case 'directorySelect':
            case 'directorySearch':
            case 'directoryClientSearch':
            case 'dropdownSelect':
                return new Select(props);
            case 'datepicker':
                return new DatePicker(props);
            case 'numeric':
            case 'integer':
                return new Numeric(props);
            case 'checkbox':
                return new CheckBox(props);
            case 'directoryTagList':
            case 'tagList':
                return new TagList(props);
            case 'contact':
                return new Contact(props);
            case 'phone':
                return new Phone(props);
            case 'checkboxList':
                return new CheckboxList(props);
            default:
                return new DefaultField(props);
        }
    }

    @action
    makeSnapshot() {
        this.snapshot = JSON.stringify(this.values());
    }

    hooks() {
        return {
            onSubmit: (form) => {
                runInAction(() => {
                    this.loading = true;
                });
                const result = this.emit('submit', form);
                Promise.all(result)
                    .finally(() => {
                        runInAction(() => {
                            this.loading = true;
                        });
                    });
                // TODO: find a better place for this
                // Trigger hide mobile keyboard
                document.activeElement.blur();
            },
            onSuccess: (form) => {
                const values = form.values();
                // TODO: удалить поля у которых omit = true
                // Object.keys(values).forEach((fieldName) => {
                //     if (form.$(fieldName).extra.omit) {
                //         delete values[fieldName];
                //     }
                // });
                const result = this.emit('success', values);
                Promise.all(result)
                    .then((response) => {
                        this.emit('done', response);
                    })
                    .catch(() => null)
                    .finally(() => {
                        setTimeout(() => {
                            runInAction(() => {
                                this.loading = false;
                            });
                        }, 300);
                    });
            },
            onError: (form) => {
                const result = this.emit('error', form.errors());
                Promise.all(result)
                    .finally(() => {
                        setTimeout(() => {
                            runInAction(() => {
                                this.loading = false;
                            });
                        }, 300);
                    });
            },
            onReset: (form) => {
                runInAction(() => {
                    this.loading = true;
                });
                // TODO: удалить поля у которых omit = true
                const result = this.emit('reset', form.values());
                Promise.all(result)
                    .finally(() => {
                        setTimeout(() => {
                            runInAction(() => {
                                this.loading = false;
                            });
                        }, 300);
                    });
            },
        };
    }

    on(eventName, fn) {
        return this.#eventEmitter.subscribe(
            eventName,
            fn,
        );
    }

    emit(eventName, data) {
        return this.#eventEmitter.emit(eventName, data);
    }
}

const applyMixins = (FormClass = ExtendedForm, mixins = []) => {
    let Mixed = FormClass;
    mixins.forEach((applyMixin) => {
        Mixed = applyMixin(Mixed);
    });
    return Mixed;
};

const createForm = (fields, mixins = []) => {
    const FormClass = applyMixins(ExtendedForm, mixins);
    return new FormClass(fields);
};

export default createForm;
export {
    ExtendedForm,
    applyMixins,
};
