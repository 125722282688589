import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../Icons/SVGIcon';

import styles from './PageTitle.module.scss';

const PageTitle = ({
    children,
    icon,
}) => (
    <h1 className={styles['page-title']}>
        <span className={styles['page-title__title']}>
            {children}
        </span>
        {
            icon
                ? (
                    <SVGIcon
                        className={styles['page-title__icon']}
                        SVGElement={icon}
                        height={24}
                    />
                )
                : null
        }
    </h1>
);

PageTitle.propTypes = {
    children: PropTypes.string.isRequired,
    icon: PropTypes.func,
};

PageTitle.defaultProps = {
    icon: null,
};

export default PageTitle;
