import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'subscribe',
    fields: [{
        type: 'directorySelect',
        directory: DIRECTORIES.BILLING_PAYMENT_TYPES,
        name: 'paymentTypeId',
        label: 'Способ оплаты',
        size: 'md',
        value: { value: 1, label: 'Расчетный счет' },
    }, {
        name: 'description',
        type: 'text',
        label: 'Платежная информация',
        size: 'md',
    }, {
        name: 'amount',
        type: 'integer',
        label: 'Сумма',
        size: 'md',
        rules: 'required',
    }],
}];
