import { HouseStrategy } from '../../../shared/RefactoredObjectList/strategy';
import { TABLE_CELL, SORT_ORDER, DEAL_TYPE } from '../../../../constants/shared';

class ClientHouseStrategy extends HouseStrategy {
    get defaultSortData() {
        const { dealType } = this;
        if (dealType === DEAL_TYPE.RENT) {
            return [{
                field: TABLE_CELL.DISTRICT_NAME.sortName,
                direction: SORT_ORDER.DESC,
            }, {
                field: TABLE_CELL.ROOMS_COUNT.sortName,
                direction: SORT_ORDER.ASC,
            }, {
                field: TABLE_CELL.PRICE_DELIMITED.sortName,
                direction: SORT_ORDER.ASC,
            }];
        }
        return [{
            field: TABLE_CELL.ROOMS_COUNT.sortName,
            direction: SORT_ORDER.ASC,
        }, {
            field: TABLE_CELL.DISTRICT_NAME.sortName,
            direction: SORT_ORDER.DESC,
        }, {
            field: TABLE_CELL.STREET_NAME.sortName,
            direction: SORT_ORDER.ASC,
        }, {
            field: TABLE_CELL.PRICE_DELIMITED.sortName,
            direction: SORT_ORDER.ASC,
        }];
    }
}

export default ClientHouseStrategy;
