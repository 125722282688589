import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonClose } from '../../../components/Button';

import styles from './ObjectComponent.module.scss';

const ObjectComponentApprove = ({
    onCancel,
    onApprove,
    disabled,
    toggleApprovingState,
    action,
}) => {
    // Метод закрывает стейт подтверждения
    const onCloseState = () => {
        toggleApprovingState();
    };
    return (
        <div className={styles.approving}>
            <div className={styles.approving__inner}>
                <ButtonClose className={styles.approving__close} onClick={onCloseState} />
                <div className={styles.approving__box}>
                    {
                        action
                            ? 'Удалить?'
                            : 'Сохранить изменения?'
                    }
                    <div className={styles.approving__buttons}>
                        <Button
                            type={Button.TYPE.BUTTON}
                            size="md"
                            color="green"
                            className={styles.approving__button}
                            onClick={onApprove}
                            disabled={disabled}
                            block
                        >
                            {
                                action
                                    ? 'Удалить и выйти'
                                    : 'Сохранить и выйти'
                            }
                        </Button>
                        <Button
                            type={Button.TYPE.BUTTON}
                            size="md"
                            color="gray"
                            className={styles.approving__button}
                            onClick={onCancel}
                            disabled={disabled}
                            block
                        >
                            {
                                action
                                    ? 'Отменить'
                                    : 'Выйти без сохранения'
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ObjectComponentApprove.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
    toggleApprovingState: PropTypes.func.isRequired,
    action: PropTypes.string,
};

ObjectComponentApprove.defaultProps = {
    action: undefined,
};

export default ObjectComponentApprove;
