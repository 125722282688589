import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Error from '../Error';
import SVGIcon from '../Icons/SVGIcon';
import { CopyIcon } from '../Icons/icons';
import { SIZE, POSITION } from '../../constants/shared';

import styles from './Input.module.scss';

const size2height = {
    [SIZE.SMALL]: 16,
    [SIZE.MEDIUM]: 16,
    [SIZE.LARGE]: 22,
};

const Input = forwardRef((props, ref) => {
    const {
        className,
        id,
        name,
        value,
        type,
        placeholder,
        disabled,
        readOnly,
        copy,
        error,
        size,
        prefix,
        postfix,
        autoComplete,
        children,
        onChange,
        onInput,
        onClick,
        onBlur,
        onFocus,
        onCopy,
    } = props;

    return (
        <div
            className={`
                ${styles.input}
                ${styles[`input--${size}`]}
                ${disabled ? styles['input--disabled'] : ''}
                ${readOnly ? styles['input--readonly'] : ''}
                ${error ? styles['input--error'] : ''}
                ${copy ? styles['input--copy'] : ''}
                ${prefix ? styles['input--prefix'] : ''}
                ${postfix ? styles['input--postfix'] : ''}
                ${className}
            `}
        >
            {
                prefix
                    ? (
                        <div className={styles.input__prefix}>
                            {prefix}
                        </div>
                    )
                    : null
            }
            {
                postfix
                    ? (
                        <div className={styles.input__postfix}>
                            {typeof postfix === 'function' ? postfix(props) : postfix}
                        </div>
                    )
                    : null
            }
            <div className={styles.input__field}>
                <input
                    ref={ref}
                    id={id}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={onChange}
                    onInput={onInput}
                    onClick={onClick}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete={autoComplete}
                />
                {
                    copy
                        ? (
                            <CopyToClipboard
                                text={value}
                                onCopy={onCopy}
                            >
                                <button
                                    type="button"
                                    className={`${styles.input__copy} ${styles.copy}`}
                                    data-tip="Скопировать"
                                    data-place={POSITION.RIGHT}
                                    data-class={styles.copy__hint}
                                >
                                    <SVGIcon
                                        SVGElement={CopyIcon}
                                        className={styles.copy__icon}
                                        height={size2height[size]}
                                    />
                                </button>
                            </CopyToClipboard>
                        )
                        : null
                }
                {
                    error
                        ? (
                            <Error
                                className={styles.input__error}
                                error={error}
                                id={`tooltip-${id}`}
                                size={size}
                            />
                        )
                        : null
                }
                {children}
            </div>
        </div>
    );
});

Input.SIZE = { ...SIZE };

Input.TYPE = {
    TEXT: 'text',
    PASSWORD: 'password',
};

Input.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    type: PropTypes.oneOf(Object.values(Input.TYPE)),
    size: PropTypes.oneOf(Object.values(Input.SIZE)),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    copy: PropTypes.bool,
    error: PropTypes.string,
    prefix: PropTypes.string,
    postfix: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    autoComplete: PropTypes.oneOf(['off', 'none', null]),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onCopy: PropTypes.func,
};

Input.defaultProps = {
    className: '',
    id: undefined,
    type: Input.TYPE.TEXT,
    placeholder: '',
    value: undefined,
    size: Input.SIZE.MEDIUM,
    disabled: false,
    readOnly: false,
    copy: false,
    error: '',
    prefix: '',
    postfix: '',
    autoComplete: null,
    children: null,
    onChange: null,
    onInput: null,
    onClick: null,
    onBlur: null,
    onFocus: null,
    onCopy: null,
};

export default Input;
