import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import BaseSelect from './BaseSelect';
import Dropdown from '../Dropdown';
import SVGIcon from '../Icons/SVGIcon';
import { SearchIcon } from '../Icons/icons';

import styles from './Select.module.scss';

const size2height = {
    sm: 12,
    md: 14,
    lg: 16,
};

const DropdownWrapper = ({ dropdownOpened, children }) => (
    dropdownOpened
        ? (
            <div className={styles.dropdown__content}>
                {children}
            </div>
        )
        : null
);

DropdownWrapper.propTypes = {
    dropdownOpened: PropTypes.bool,
    children: PropTypes.element,
};

DropdownWrapper.defaultProps = {
    dropdownOpened: false,
    children: '',
};

const DropdownSelectValue = ({ value, placeholder }) => {
    if (!value || (!value.label && !value.length) || value === 'value') {
        return (
            <div className={`${styles.dropdown__value} ${styles['dropdown__value--placeholder']}`}>
                {placeholder || '\u0000'}
            </div>
        );
    }
    if (Array.isArray(value)) {
        return value.map(({ value: val, label }) => (
            <div key={`value-${val}`} className={styles.dropdown__value}>{label || '\u0000'}</div>
        ));
    }
    return <div className={styles.dropdown__value}>{value.label}</div>;
};

DropdownSelectValue.propTypes = {
    value: BaseSelect.propTypes.value,
    placeholder: PropTypes.string,
};

DropdownSelectValue.defaultProps = {
    value: BaseSelect.defaultProps.value,
    placeholder: '\u0000',
};

const CustomValueContainer = ({ children, ...valueContainerProps }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ValueContainer {...valueContainerProps}>
        {children.slice(-1)}
    </components.ValueContainer>
);

CustomValueContainer.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
};

CustomValueContainer.defaultProps = {
    children: [],
};

const CustomMenuListItem = ({ handleClick, tooltipFieldName = 'label', ...rest }) => {
    const { value, label } = { ...rest };
    const ref = useRef();

    const title = useMemo(() => (
        ref.current && ref.current.scrollWidth > ref.current.clientWidth
            ? rest[tooltipFieldName] ?? ''
            : ''),
    [
        rest[tooltipFieldName],
        ref.current?.scrollWidth,
        ref.current?.clientWidth,
    ]);

    return (
        <div
            ref={ref}
            key={`selected-${value}`}
            title={title}
            className={styles.dropdown__selected}
            onClick={handleClick}
            aria-hidden
        >
            {label}
        </div>
    );
};

CustomMenuListItem.propTypes = {
    handleClick: PropTypes.func.isRequired,
    tooltipFieldName: PropTypes.string,
};

CustomMenuListItem.defaultProps = {
    tooltipFieldName: 'label',
};

const CustomMenuList = ({ children, ...props }) => {
    const { hasValue, getValue, setValue } = props;
    const selected = getValue();
    const handleClick = ({ value }) => () => {
        setTimeout(() => {
            setValue(selected.filter(({ value: val }) => val !== value), 'remove-value');
        }, 0);
    };
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.MenuList {...props}>
            {
                hasValue
                    ? (
                        <div className={styles['dropdown__selected-list']}>
                            {
                                selected.map(({ value, label }) => (
                                    <CustomMenuListItem
                                        value={value}
                                        label={label}
                                        handleClick={handleClick({ value, label })}
                                    />
                                ))
                            }
                        </div>
                    )
                    : null
            }
            {children}
        </components.MenuList>
    );
};

CustomMenuList.propTypes = {
    hasValue: PropTypes.bool.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
};

CustomMenuList.defaultProps = {
    children: [],
};

const DropdownSelect = ({
    disabled,
    value,
    size,
    placeholder,
    onMenuOpen,
    className,
    footer,
    menuIsOpen,
    headless,
    ...props
}) => {
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        if (menuIsOpen) {
            onMenuOpen();
        }
    }, []);
    return (
        <div className={styles['dropdown-wrapper']}>
            <Dropdown
                className={`
                    ${styles.dropdown}
                    ${styles[`dropdown--${size}`]}
                `}
                openClassName={styles['dropdown--open']}
                contentClassName={styles.dropdown__inner}
                name={(
                    <DropdownSelectValue
                        value={value}
                        placeholder={placeholder}
                    />
                )}
                onDropdownOpen={onMenuOpen}
                disabled={disabled}
                headless={headless}
                isInitiallyOpen={menuIsOpen}
            >
                <DropdownWrapper dropdownOpened={menuIsOpen}>
                    <BaseSelect
                        disabled={disabled}
                        value={value}
                        size={size}
                        placeholder={placeholder}
                        className={`${styles.dropdown__search} ${styles.search} ${className}`}
                        wrappers={{
                            ValueContainer: CustomValueContainer,
                            MenuList: CustomMenuList,
                            DropdownIndicatorWrapper: () => (
                                <div className={styles.search__icon}>
                                    <SVGIcon
                                        SVGElement={SearchIcon}
                                        height={size2height[size]}
                                    />
                                </div>
                            ),
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        menuIsOpen
                        searchable
                        clearable={false}
                        autoFocus
                        backspaceRemovesValue={false}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        inputValue={inputValue}
                        onInputChange={setInputValue}
                    />
                    {footer || null}
                </DropdownWrapper>
            </Dropdown>
        </div>
    );
};

DropdownSelect.propTypes = {
    disabled: BaseSelect.propTypes.disabled,
    value: BaseSelect.propTypes.value,
    size: BaseSelect.propTypes.size,
    placeholder: BaseSelect.propTypes.placeholder,
    className: BaseSelect.propTypes.className,
    onMenuOpen: PropTypes.func,
    footer: PropTypes.element,
    menuIsOpen: PropTypes.bool,
    headless: PropTypes.bool,
};

DropdownSelect.defaultProps = {
    disabled: BaseSelect.defaultProps.disabled,
    value: BaseSelect.defaultProps.value,
    size: BaseSelect.defaultProps.size,
    placeholder: BaseSelect.defaultProps.placeholder,
    className: BaseSelect.defaultProps.className,
    footer: null,
    onMenuOpen: () => null,
    menuIsOpen: false,
    headless: false,
};

export default DropdownSelect;
