import React from 'react';
import AsyncSelect from 'react-select/async';
import BaseSelect from './BaseSelect';

const AsyncDefaultSelect = (props) => (
    <BaseSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        Component={AsyncSelect}
        searchable
    />
);

export default AsyncDefaultSelect;
