/* global document */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './Dropdown.module.scss';

const Dropdown = ({
    children,
    align,
    className,
    openClassName,
    name,
    disabled,
    contentClassName,
    onDropdownOpen,
    isInitiallyOpen,
    headless,
}) => {
    const dropdown = useRef();
    const trigger = useRef();
    const [isOpen, setOpen] = useState(isInitiallyOpen);
    const handleClick = (e) => {
        const exclude = ['A', 'BUTTON'];
        if (
            headless || (
                dropdown.current.contains(e.target)
                && !exclude.find((tag) => tag === e.target.tagName)
            )
        ) {
            return;
        }
        if (trigger?.current?.contains(e.target)) {
            const opened = !isOpen;
            setOpen(opened);
            if (opened) {
                onDropdownOpen();
            }
            return;
        }
        setOpen(false);
    };
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [isOpen]);
    const modifiedChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { dropdownOpened: isOpen });
        }
        return child;
    });
    return (
        <div
            className={`
                ${styles.dropdown}
                ${align === Dropdown.ALIGN.RIGHT ? styles['dropdown--right'] : ''}
                ${isOpen ? styles['dropdown--open'] : ''}
            `}
        >
            {!headless && (
                <button
                    ref={trigger}
                    className={`
                        ${styles.dropdown__trigger}
                        ${isOpen ? openClassName : ''}
                        ${className}
                    `}
                    type="button"
                    disabled={disabled}
                >
                    {name}
                </button>
            )}
            <div
                className={`${styles.dropdown__content} ${headless ? styles['dropdown__content--headless'] : ''} ${contentClassName}`}
                ref={dropdown}
            >
                {modifiedChildren}
            </div>
        </div>
    );
};

Dropdown.ALIGN = {
    RIGHT: 'right',
    LEFT: 'left',
};

Dropdown.propTypes = {
    align: PropTypes.oneOf(Object.values(Dropdown.ALIGN)),
    className: PropTypes.string,
    openClassName: PropTypes.string,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    disabled: PropTypes.bool,
    isInitiallyOpen: PropTypes.bool,
    headless: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
    contentClassName: PropTypes.string,
    onDropdownOpen: PropTypes.func,
};

Dropdown.defaultProps = {
    align: Dropdown.ALIGN.LEFT,
    className: '',
    openClassName: '',
    disabled: false,
    isInitiallyOpen: false,
    headless: false,
    children: null,
    contentClassName: '',
    onDropdownOpen: () => null,
};

export default Dropdown;
