import Field from './DefaultField';
import isEmpty from '../../../utils/is-empty';

class Numeric extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw) => {
        const { providedOutput } = this;
        let value;
        if (!isEmpty(raw) || raw === '0') {
            value = Number(String(raw).replace(/,/g, '.'));
        } else {
            value = '';
        }
        if (typeof providedOutput === 'function') {
            return providedOutput(value);
        }
        return value;
    }
}

export default Numeric;
