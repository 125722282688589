import handleSelectChange from './utils/hande-react-select';
import handleRangeChange from './utils/handle-range-change';
import handleOnlyNumber from './utils/handle-only-number';
import handleOnlyInteger from './utils/handle-only-integer';
import { SIZE } from '../../constants/operator';

const defaultBindings = {
    id: 'id',
    name: 'name',
    type: 'type',
    value: 'value',
    label: 'label',
    placeholder: 'placeholder',
    disabled: 'disabled',
    readOnly: 'readOnly',
    onChange: 'onChange',
    onBlur: 'onBlur',
    onFocus: 'onFocus',
    autoFocus: 'autoFocus',
};

const applyBindings = (setNewBindings) => ({ $try, field, props }) => ({
    ...Object
        .keys(defaultBindings)
        .reduce((carry, key) => ({
            ...carry,
            [key]: $try(props[key], field[key]),
        }), {}),
    copy: $try(field.copy, field.extra.copy, false),
    hidden: $try(field.hidden, field.extra.hidden, false),
    disabled: $try(props.disabled, field.disabled || field.hidden),
    readOnly: $try(props.readOnly, field.extra.readOnly, field.readOnly),
    size: $try(props.size, field.extra.size, SIZE.SMALL),
    ctx: $try(props.ctx, { ...field.extra.ctx, ...field.extra.defaultCtx }, {}),
    icon: $try(props.icon, field.extra.icon, {}),
    attributeName: $try(props.attributeName, field.extra.attributeName),
    tooltip: $try(props.tooltip, field.extra.tooltip),
    inline: $try(props.inline, field.extra.inline),
    ...setNewBindings({ $try, field, props }),
});

const bindings = {
    select: applyBindings(({ $try, field, props }) => ({
        onChange: $try(props.onChange, handleSelectChange(field)),
        options: $try(props.options, field.extra.options, []),
        multiple: $try(props.multiple, field.extra.multiple),
        searchable: $try(props.searchable, field.extra.searchable),
        menuPlacement: $try(props.menuPlacement, field.extra.menuPlacement, 'auto'),
    })),
    radioList: applyBindings(({ $try, field, props }) => ({
        options: $try(props.options, field.extra.options, []),
        rows: $try(props.rows, field.extra.rows),
    })),
    checkboxList: applyBindings(({ $try, field, props }) => ({
        options: $try(props.options, field.extra.options, []),
    })),
    tagList: applyBindings(({ $try, field, props }) => ({
        options: $try(props.options, field.extra.options, []),
        type: $try(props.listType, field.extra.listType, 'radio'),
    })),
    checkbox: applyBindings(({ $try, field, props }) => ({
        checked: $try(props.checked, field.checked),
        children: $try(props.label, field.label),
        label: $try(null, null),
    })),
    tag: applyBindings(({ $try, field, props }) => ({
        children: $try(props.label, field.label),
        label: null,
        type: $try(props.tagType, field.extra.tagType, 'radio'),
    })),
    datepicker: applyBindings(({ $try, field, props }) => ({
        popperPlacement: $try(props.placement, field.extra.placement, 'bottom-start'),
        closeOnScroll: $try(props.closeOnScroll, field.extra.closeOnScroll, true),
        endOfDay: $try(props.endOfDay, field.extra.endOfDay, false),
        startOfDay: $try(props.startOfDay, field.extra.startOfDay, false),
        clearable: $try(props.clearable, field.extra.clearable, false),
    })),
    text: applyBindings(({ $try, field, props }) => ({
        prefix: $try(props.prefix, field.extra.prefix),
        postfix: $try(props.postfix, field.extra.postfix),
    })),
    password: applyBindings(() => ({})),
    integer: applyBindings(({ $try, field, props }) => ({
        prefix: $try(props.prefix, field.extra.prefix),
        postfix: $try(props.postfix, field.extra.postfix),
        onChange: $try(props.onChange, handleOnlyInteger(field)),
        days: $try(props.postfix, field.extra.postfix),
        type: 'text',
    })),
    numeric: applyBindings(({ $try, field, props }) => ({
        prefix: $try(props.prefix, field.extra.prefix),
        postfix: $try(props.postfix, field.extra.postfix),
        onChange: $try(props.onChange, handleOnlyNumber(field)),
        type: 'text',
    })),
    textarea: applyBindings(({ $try, field, props }) => ({
        rows: $try(props.rows, field.extra.rows),
    })),
    directorySelect: applyBindings(({ $try, field, props }) => ({
        onChange: $try(props.onChange, handleSelectChange(field)),
        multiple: $try(props.multiple, field.extra.multiple),
        searchable: $try(props.searchable, field.extra.searchable),
        clearable: $try(props.clearable, field.extra.clearable),
        directory: $try(props.directory, field.extra.directory),
        isDropdown: $try(props.isDropdown, field.extra.isDropdown),
        withSelectAll: $try(props.withSelectAll, field.extra.withSelectAll),
        preload: $try(props.preload, field.extra.preload),
        menuPlacement: $try(props.menuPlacement, field.extra.menuPlacement, 'auto'),
    })),
    directorySearch: applyBindings(({ $try, field, props }) => ({
        onChange: $try(props.onChange, handleSelectChange(field)),
        multiple: $try(props.multiple, field.extra.multiple),
        clearable: $try(props.clearable, field.extra.clearable),
        directory: $try(props.directory, field.extra.directory),
        withDefault: $try(props.withDefault, field.extra.withDefault),
        isDropdown: $try(props.isDropdown, field.extra.isDropdown),
        menuPlacement: $try(props.menuPlacement, field.extra.menuPlacement, 'auto'),
    })),
    directoryClientSearch: applyBindings(({ $try, field, props }) => ({
        onChange: $try(props.onChange, handleSelectChange(field)),
        multiple: $try(props.multiple, field.extra.multiple),
        clearable: $try(props.clearable, field.extra.clearable),
        directory: $try(props.directory, field.extra.directory),
        withDefault: $try(props.withDefault, field.extra.withDefault),
        isDropdown: $try(props.isDropdown, field.extra.isDropdown),
        menuPlacement: $try(props.menuPlacement, field.extra.menuPlacement, 'auto'),
    })),
    directoryTagList: applyBindings(({ $try, field, props }) => ({
        directory: $try(props.directory, field.extra.directory),
        options: $try(props.options, field.extra.options, []),
        type: $try(props.listType, field.extra.listType, 'radio'),
    })),
    directoryRadioList: applyBindings(({ $try, field, props }) => ({
        directory: $try(props.directory, field.extra.directory),
        options: $try(props.options, field.extra.options, []),
        type: $try(props.listType, field.extra.listType, 'radio'),
    })),
    directoryCheckboxList: applyBindings(({ $try, field, props }) => ({
        directory: $try(props.directory, field.extra.directory),
        options: $try(props.options, field.extra.options, []),
        type: $try(props.listType, field.extra.listType, 'checkbox'),
    })),
    contact: applyBindings(({ $try, field, props }) => ({
        type: $try(props.listType, field.extra.listType, 'contact'),
        simple: $try(props.simple, field.extra.simple, false),
    })),
    phone: applyBindings(({ $try, field, props }) => ({
        type: $try(props.listType, field.extra.listType, 'text'),
    })),
    range: applyBindings(({ $try, field, props }) => ({
        onChange: $try(props.onChange, handleRangeChange(field)),
        min: $try(props.min, field.extra.min, 0),
        max: $try(props.max, field.extra.max),
        step: $try(props.step, field.extra.step, 1),
        measure: $try(props.measure, field.extra.measure, ''),
        measureStart: $try(props.measureStart, field.extra.measureStart, ''),
        measureEnd: $try(props.measureEnd, field.extra.measureEnd, ''),
        showLabels: $try(props.showLabels, field.extra.showLabels, true),
    })),
    dropdownSelect: applyBindings(({ $try, field, props }) => ({
        onChange: $try(props.onChange, handleSelectChange(field)),
        options: $try(props.options, field.extra.options, []),
        multiple: $try(props.multiple, field.extra.multiple),
        searchable: $try(props.searchable, field.extra.searchable),
    })),
    companies: applyBindings(({ $try, field, props }) => ({
        options: $try(props.options, field.extra.options, []),
    })),
    radius: applyBindings(() => ({})),
};

export default bindings;

export {
    bindings,
    defaultBindings,
};
