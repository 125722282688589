import { FlatStrategy } from '../../../shared/RefactoredObjectList/strategy';
import { TABLE_CELL, SORT_ORDER } from '../../../../constants/shared';

class ClientFlatStrategy extends FlatStrategy {
    // eslint-disable-next-line class-methods-use-this
    get defaultSortData() {
        return [{
            field: TABLE_CELL.ROOMS_COUNT.sortName,
            direction: SORT_ORDER.ASC,
        }, {
            field: TABLE_CELL.DISTRICT_NAME.sortName,
            direction: SORT_ORDER.DESC,
        }, {
            field: TABLE_CELL.STREET_NAME.sortName,
            direction: SORT_ORDER.ASC,
        }, {
            field: TABLE_CELL.PRICE_DELIMITED.sortName,
            direction: SORT_ORDER.ASC,
        }];
    }
}

export default ClientFlatStrategy;
