/* eslint linebreak-style: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '../../../components/ContextMenu';
import { MergeIcon } from '../../../components/Icons/icons';

/**
 * Пункт контекстного меню для мерджа выделенных записей
 * @param {Object} props
 * @param {string} props.primaryObjectId идентификатор главного объекта для мерджа
 * @param {Array<Object>} props.selected список выбранных объектов
 * @param {() => void} props.onClick обработчик клика по контекстному меню
 * @param {boolean} props.visible признак видимости пункта меню
 * @returns пункт меню для мерджа выделенных записей
 */
const MergeContextMenuItem = React.memo(({
    primaryObjectId,
    selected,
    onClick,
    visible = true,
}) => {
    if (!visible || !primaryObjectId) {
        return null;
    }
    if (!selected
        || selected.length === 0
        || (selected.length === 1
            && selected.at(0).id === primaryObjectId)) {
        return null;
    }

    return (
        <div
            onClick={onClick}
            onKeyDown={onClick}
            role="menuitem"
            tabIndex={0}
        >
            <MenuItem
                icon={MergeIcon}
            >
                Объединить с выбранными
            </MenuItem>
        </div>
    );
});

MergeContextMenuItem.propTypes = {
    primaryObjectId: PropTypes.string.isRequired,
    selected: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        }),
    ),
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

MergeContextMenuItem.defaultProps = {
    selected: [],
    visible: true,
};

export default MergeContextMenuItem;
