import { runInAction } from 'mobx';
import Directory from '../../../shared/Directories/entities/Directory';

class BillingCompanies extends Directory {
    loadData() {
        if (this.loaded || this.loading) {
            return Promise.resolve(this.options);
        }
        const { directory } = this;
        return this.dataSource.getBillingCompanies(directory)
            .then((list = []) => {
                runInAction(() => {
                    this.loaded = true;
                });
                this.items.addList(list.map(({ name, id }) => ({ value: id, label: name })));
                return Promise.resolve(this.options);
            })
            .catch(() => {});
    }
}

export default BillingCompanies;
