import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

import styles from './TagList.module.scss';

const TagList = ({
    className,
    name,
    options,
    disabled,
    size,
    type,
    value,
    onChange,
}) => {
    const handleChange = (val, label, rest) => () => {
        if (onChange && typeof onChange === 'function') {
            onChange({ value: val, label, ...rest });
        }
    };
    let simpleValue = value;
    if (simpleValue instanceof Object) {
        simpleValue = simpleValue.value;
    }
    return (
        <div
            className={`
                ${styles['tag-list']}
                ${styles[`tag-list--${size}`]}
                ${className}
            `}
        >
            {
                options.map((item) => {
                    const {
                        id,
                        value: itemValue,
                        label,
                        disabled: tagDisabled,
                        ...rest
                    } = item;
                    return (
                        <Tag
                            className={styles['tag-list__item']}
                            key={id || `tag-${name}-${itemValue}`}
                            id={id || `tag-${name}-${itemValue}`}
                            name={name}
                            type={type}
                            value={itemValue}
                            size={size}
                            checked={(
                                simpleValue !== undefined
                                    ? String(itemValue) === String(simpleValue)
                                    : undefined
                            )}
                            disabled={disabled || tagDisabled}
                            onChange={handleChange(itemValue, label, rest)}
                        >
                            {label}
                        </Tag>
                    );
                })
            }
        </div>
    );
};

TagList.SIZE = { ...Tag.SIZE };

TagList.TYPE = { ...Tag.TYPE };

TagList.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(Object.values(TagList.SIZE)),
    type: PropTypes.oneOf(Object.values(TagList.TYPE)),
    value: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.string,
        PropTypes.number,
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        label: PropTypes.string,
        disabled: PropTypes.bool,
    })).isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

TagList.defaultProps = {
    className: '',
    size: TagList.SIZE.MEDUIM,
    type: TagList.TYPE.CHECKBOX,
    value: undefined,
    disabled: false,
    onChange: null,
};

export default TagList;
