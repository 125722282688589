import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './ExportObjects.module.scss';

const ExportObjectsFieldGroup = ({ fieldgroup: { label, name, description }, children }) => (
    <div className={`${styles['export-objects__fieldgroup']} ${styles[`export-objects__fieldgroup--${name}`] || ''}`}>
        {
            label
                ? (
                    <Label className={`${styles['export-objects__label']} ${styles['export-objects__label--fieldgroup']}`}>
                        {label}
                    </Label>
                )
                : null
        }
        {
            description
                ? (
                    <div className={styles['export-objects__description']}>
                        {description}
                    </div>
                )
                : null
        }
        <div className={styles['export-objects__fieldgroup-list']}>
            {children}
        </div>
    </div>
);

ExportObjectsFieldGroup.propTypes = {
    fieldgroup: PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    children: PropTypes.element.isRequired,
};

export default ExportObjectsFieldGroup;
