import { ReactComponent as AlertIcon } from './svg/Alert.component.svg';
import { ReactComponent as CrossIcon } from './svg/Cross.component.svg';
import { ReactComponent as CrossBorderedIcon } from './svg/CrossBordered.component.svg';
import { ReactComponent as CheckedIcon } from './svg/Checked.component.svg';
import { ReactComponent as ClearIcon } from './svg/Clear.component.svg';
import { ReactComponent as ClockIcon } from './svg/Clock.component.svg';
import { ReactComponent as CirclePlusIcon } from './svg/CirclePlus.component.svg';
import { ReactComponent as SaveIcon } from './svg/Save.component.svg';
import { ReactComponent as SearchIcon } from './svg/Search.component.svg';
import { ReactComponent as EmailIcon } from './svg/Email.component.svg';
import { ReactComponent as ErrorIcon } from './svg/Error.component.svg';
import { ReactComponent as ChevronRightIcon } from './svg/ChevronRight.component.svg';
import { ReactComponent as CalendarIcon } from './svg/Calendar.component.svg';
import { ReactComponent as ListBlueIcon } from './svg/ListBlue.component.svg';
import { ReactComponent as ColumnsBlueIcon } from './svg/ColumnsBlue.component.svg';
import { ReactComponent as LayoutBlueIcon } from './svg/LayoutBlue.component.svg';
import { ReactComponent as WatchOrangeIcon } from './svg/WatchOrange.component.svg';
import { ReactComponent as SettingsBlackIcon } from './svg/SettingsBlack.component.svg';
import { ReactComponent as SettingsWhiteIcon } from './svg/SettingsWhite.component.svg';
import { ReactComponent as HomeBlueIcon } from './svg/HomeBlue.component.svg';
import { ReactComponent as UserGreenIcon } from './svg/UserGreen.component.svg';
import { ReactComponent as UserBlueIcon } from './svg/UserBlue.component.svg';
import { ReactComponent as HouseIcon } from './svg/House.component.svg';
import { ReactComponent as HouseFilledIcon } from './svg/HouseFilled.component.svg';
import { ReactComponent as HouseFilledWithBorderIcon } from './svg/HouseFilledWithBorder.component.svg';
import { ReactComponent as PhotoIcon } from './svg/Photo.component.svg';
import { ReactComponent as CompanyIcon } from './svg/Company.component.svg';
import { ReactComponent as WatchRubOrangeIcon } from './svg/WatchRubOrange.component.svg';
import { ReactComponent as WatchUserOrangeIcon } from './svg/WatchUserOrange.component.svg';
import { ReactComponent as SupportIcon } from './svg/Support.component.svg';
import { ReactComponent as KeyIcon } from './svg/Key.component.svg';
import { ReactComponent as ClipboardIcon } from './svg/Clipboard.component.svg';
import { ReactComponent as RefreshIcon } from './svg/Refresh.component.svg';
import { ReactComponent as TrophyIcon } from './svg/Trophy.component.svg';
import { ReactComponent as NotifyOrangeIcon } from './svg/NotifyOrange.component.svg';
import { ReactComponent as NotifyWhiteIcon } from './svg/NotifyWhite.component.svg';
import { ReactComponent as ZapIcon } from './svg/Zap.component.svg';
import { ReactComponent as HomeBluePlusIcon } from './svg/HomeBluePlus.component.svg';
import { ReactComponent as ColumnsBluePlusIcon } from './svg/ColumnsBluePlus.component.svg';
import { ReactComponent as ExternalLinkIcon } from './svg/ExternalLink.component.svg';
import { ReactComponent as NotepadPlusIcon } from './svg/NotepadPlus.component.svg';
import { ReactComponent as UserPlusIcon } from './svg/UserPlus.component.svg';
import { ReactComponent as UserProlongIcon } from './svg/UserProlong.component.svg';
import { ReactComponent as NotepadNestIcon } from './svg/NotepadNest.component.svg';
import { ReactComponent as UserBanIcon } from './svg/UserBan.component.svg';
import { ReactComponent as CompanyPlusIcon } from './svg/CompanyPlus.component.svg';
import { ReactComponent as CompanyBanIcon } from './svg/CompanyBan.component.svg';
import { ReactComponent as AdminPlusIcon } from './svg/AdminPlus.component.svg';
import { ReactComponent as AdminBanIcon } from './svg/AdminBan.component.svg';
import { ReactComponent as KeyAddIcon } from './svg/KeyAdd.component.svg';
import { ReactComponent as ToolIcon } from './svg/Tool.component.svg';
import { ReactComponent as CalcIcon } from './svg/Calc.component.svg';
import { ReactComponent as StatsIcon } from './svg/Stats.component.svg';
import { ReactComponent as DoubleGISIcon } from './svg/DoubleGIS.component.svg';
import { ReactComponent as LogOutIcon } from './svg/LogOut.component.svg';
import { ReactComponent as FilterIcon } from './svg/Filter.component.svg';
import { ReactComponent as PhoneIcon } from './svg/Phone.component.svg';
import { ReactComponent as LockIcon } from './svg/Lock.component.svg';
import { ReactComponent as DoubleArrowGreenIcon } from './svg/DoubleArrowGreen.component.svg';
import { ReactComponent as PlusIcon } from './svg/Plus.component.svg';
import { ReactComponent as LinkIcon } from './svg/Link.component.svg';
import { ReactComponent as BanIcon } from './svg/Ban.component.svg';
import { ReactComponent as CopyIcon } from './svg/Copy.component.svg';
import { ReactComponent as DownloadIcon } from './svg/Download.component.svg';
import { ReactComponent as ExternalLinkSimpleIcon } from './svg/ExternalLinkSimple.component.svg';
import { ReactComponent as EditIcon } from './svg/Edit.component.svg';
import { ReactComponent as EyeOpenedIcon } from './svg/EyeOpened.component.svg';
import { ReactComponent as EyeClosedIcon } from './svg/EyeClosed.component.svg';
import { ReactComponent as PlusSquareIcon } from './svg/PlusSquare.component.svg';
import { ReactComponent as ErrorMessageIcon } from './svg/ErrorMessage.component.svg';
import { ReactComponent as SuccessMessageIcon } from './svg/SuccessMessage.component.svg';
import { ReactComponent as BrickIcon } from './svg/Brick.component.svg';
import { ReactComponent as FingerIcon } from './svg/Finger.component.svg';
import { ReactComponent as TelegramIcon } from './svg/Telegram.component.svg';
import { ReactComponent as VKIcon } from './svg/VK.component.svg';
import { ReactComponent as OKIcon } from './svg/OK.component.svg';
import { ReactComponent as SocialIcon } from './svg/Social.component.svg';
import { ReactComponent as MergeIcon } from './svg/Merge.component.svg';
import { ReactComponent as ArrowRightIcon } from './svg/Arrow.Right.component.svg';

const buttonIcons = {
    CrossIcon,
    CrossBorderedIcon,
    ClearIcon,
    ClockIcon,
    SaveIcon,
    PlusIcon,
    SearchIcon,
    DownloadIcon,
    ExternalLinkSimpleIcon,
    EditIcon,
    EyeOpenedIcon,
    EyeClosedIcon,
    PlusSquareIcon,
};

const inputIcons = {
    SearchIcon,
    EmailIcon,
    CalendarIcon,
    PhoneIcon,
    LockIcon,
};

const icons = {
    CrossIcon,
    CrossBorderedIcon,
    CheckedIcon,
    ClearIcon,
    ClockIcon,
    CirclePlusIcon,
    SaveIcon,
    SearchIcon,
    EmailIcon,
    ErrorIcon,
    ChevronRightIcon,
    CalendarIcon,
    AlertIcon,
    BanIcon,
    CopyIcon,
    EyeOpenedIcon,
    EyeClosedIcon,
    // colored
    ListBlueIcon,
    LayoutBlueIcon,
    ColumnsBlueIcon,
    SettingsBlackIcon,
    SettingsWhiteIcon,
    WatchOrangeIcon,
    HomeBlueIcon,
    UserGreenIcon,
    HouseIcon,
    PhotoIcon,
    DownloadIcon,
    ExternalLinkSimpleIcon,
    EditIcon,
    PlusSquareIcon,
    ErrorMessageIcon,
    SuccessMessageIcon,
};

export default icons;
export {
    buttonIcons,
    inputIcons,
    CrossIcon,
    CrossBorderedIcon,
    CheckedIcon,
    ClearIcon,
    ClockIcon,
    CirclePlusIcon,
    SaveIcon,
    SearchIcon,
    ErrorIcon,
    ChevronRightIcon,
    CalendarIcon,
    AlertIcon,
    ListBlueIcon,
    LayoutBlueIcon,
    ColumnsBlueIcon,
    SettingsBlackIcon,
    SettingsWhiteIcon,
    WatchOrangeIcon,
    HomeBlueIcon,
    UserGreenIcon,
    HouseIcon,
    PhotoIcon,
    UserBlueIcon,
    CompanyIcon,
    WatchRubOrangeIcon,
    WatchUserOrangeIcon,
    SupportIcon,
    KeyIcon,
    ClipboardIcon,
    RefreshIcon,
    TrophyIcon,
    NotifyOrangeIcon,
    NotifyWhiteIcon,
    ZapIcon,
    HomeBluePlusIcon,
    ColumnsBluePlusIcon,
    ExternalLinkIcon,
    NotepadPlusIcon,
    UserPlusIcon,
    UserProlongIcon,
    NotepadNestIcon,
    UserBanIcon,
    CompanyPlusIcon,
    CompanyBanIcon,
    AdminPlusIcon,
    AdminBanIcon,
    KeyAddIcon,
    ToolIcon,
    CalcIcon,
    StatsIcon,
    DoubleGISIcon,
    LogOutIcon,
    FilterIcon,
    LockIcon,
    PhoneIcon,
    DoubleArrowGreenIcon,
    PlusIcon,
    HouseFilledIcon,
    HouseFilledWithBorderIcon,
    LinkIcon,
    BanIcon,
    CopyIcon,
    DownloadIcon,
    ExternalLinkSimpleIcon,
    EditIcon,
    EyeOpenedIcon,
    EyeClosedIcon,
    PlusSquareIcon,
    ErrorMessageIcon,
    SuccessMessageIcon,
    BrickIcon,
    FingerIcon,
    TelegramIcon,
    VKIcon,
    OKIcon,
    SocialIcon,
    MergeIcon,
    ArrowRightIcon,
};
