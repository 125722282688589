import React from 'react';
import PropTypes from 'prop-types';
import { TabList } from 'react-tabs';

import styles from './CustomTabs.module.scss';

const CustomTabList = ({ children, className, ...props }) => (
    <TabList
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={`${className} ${styles['tab-list']}`}
    >
        {children}
    </TabList>
);

CustomTabList.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

CustomTabList.defaultProps = {
    className: '',
};

CustomTabList.tabsRole = 'TabList';

export default CustomTabList;
