import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset', // ad
    name: 'filter',
    fields: [{
        name: 'cityIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Нас. пункт',
        multiple: true,
        clearable: true,
        searchable: true,
    }, {
        name: 'query',
        type: 'text',
        label: 'Улица',
    }],
}];
