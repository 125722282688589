import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.ROW_SELECT,
    width: 3,
    sortName: null,
}, {
    ...TABLE_CELL.OBJECT_TYPE,
    width: 3,
    sortName: null,
    fields: [{
        name: 'ownerTypeIds',
    }],
}, {
    ...TABLE_CELL.STEAD_TYPE_NAME,
    width: 9,
    fields: [{
        name: 'objectSteadTypeIds',
    }],
}, {
    ...TABLE_CELL.EXCHANGE,
    width: 4,
}, {
    ...TABLE_CELL.SHORT_DISTRICT_NAME,
    width: 5,
    fields: [{
        name: 'cityDistrictIds',
    }],
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 18,
    textAlign: 'left',
    fields: [{
        name: 'streetIds',
    }],
}, {
    ...TABLE_CELL.DISTRICT_LANDMARK_NAME,
    textAlign: 'left',
    width: 15,
    fields: [{
        name: 'cityDistrictLandmarkIds',
    }],
}, {
    ...TABLE_CELL.SQUARE_TOTAL,
    width: 5,
    fields: [{
        name: 'totalAreaFrom',
    }, {
        name: 'totalAreaTo',
    }],
    sortName: null,
}, {
    ...TABLE_CELL.PRICE_DELIMITED,
    width: 6,
}, {
    ...TABLE_CELL.UNIT_PRICE_DELIMITED,
    title: 'Цена, сот',
    width: 5,
}, {
    ...TABLE_CELL.PASSAGE_NAME,
    width: 9,
    fields: [{
        name: 'passageIds',
    }],
}, {
    ...TABLE_CELL.BUILDINGS_NAME,
    width: 10,
}, {
    ...TABLE_CELL.IMAGES,
    sortName: null,
    width: 6.7,
}, {
    ...TABLE_CELL.INCORRECT_OBJECT,
}];
