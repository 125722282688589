import React from 'react';
import { createDialogStore } from '../../stores/shared/DialogStore';
import { createSessionStore } from '../../stores/operator/SessionStore';
import { createDirectoriesStore } from '../../stores/operator/Directories';
import { createHeaderActionsStore } from '../../stores/shared/HeaderActions';
import { createHeaderToolsStore } from '../../stores/operator/HeaderToolsStore';
import { ObjectService, StorageService } from '../../services/operator';
import { createObjectsMarkersStore } from '../../stores/shared/ObjectsMarkers';

const context = {
    sessionStore: createSessionStore(),
    directoriesStore: createDirectoriesStore(),
    dialogStore: createDialogStore(),
    headerActionsStore: createHeaderActionsStore(),
    headerToolsStore: createHeaderToolsStore(),
    objectMarkers: createObjectsMarkersStore(ObjectService),
};

// TODO: find a better place for this
if (context.sessionStore.isAuthorized) {
    context.directoriesStore.loadDefaults();
    context.sessionStore.loadOperatorInfo();
} else {
    context.sessionStore.on('user:authorized', () => {
        context.directoriesStore.loadDefaults();
        StorageService.remove('sortData');
    });
}

const storesContext = React.createContext(context);

export default storesContext;
