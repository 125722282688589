import { DIRECTORIES } from '../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'status',
    hidden: true,
    fields: [{
        type: 'checkbox',
        name: 'active',
        size: 'md',
        value: true,
        disabled: true,
        label: 'Активен',
    }, {
        type: 'checkbox',
        name: 'soonExpires',
        size: 'md',
        label: 'Скоро истечет',
    }, {
        type: 'checkbox',
        name: 'notPaid',
        size: 'md',
        label: 'Не оплачен',
    }, {
        type: 'checkbox',
        name: 'banned',
        size: 'md',
        label: 'Забанен',
    }],
}, {
    type: 'fieldset', // group by
    name: 'userSearch',
    fields: [{
        name: 'company',
        type: 'directorySearch',
        directory: DIRECTORIES.ORGANIZATION,
        label: 'Компания',
        multiple: true,
        searchable: true,
        clearable: true,
    }, {
        name: 'search',
        label: 'Поиск',
    }],
}];
