import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import useKeyPress from '../../../hooks/shared/use-key-press';
import ObjectList from '../../../stores/shared/RefactoredObjectList/ObjectList';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import NotepadList from '../../../stores/shared/NotepadList/NotepadList';
import { ButtonClose, IconButton } from '../../../components/Button';
import { RelatedTypeTab } from '../ObjectPageView';
import NotepadContent from './NotepadContent';
import NotepadApprove from './NotepadApprove';
import { CrossIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';
import NotepadStats from './NotepadStats';
import {
    DEAL_TYPE,
    DEAL_TYPE_NAME,
    OBJECT_TYPE,
    OBJECT_TYPE_NAME,
    TAB_TYPE,
} from '../../../constants/shared';

import styles from './NotepadObjects.module.scss';

const NotepadObjects = ({
    useStores,
    id,
    onClose,
    createRelated,
    store: {
        notepads,
        notepad,
        tabs,
        name,
    },
}) => {
    const [approveData, setApproveData] = useState({});
    const isCtrlPressed = useKeyPress('Control');
    const isShiftPressed = useKeyPress('Shift');
    const toggleApprovingState = () => {
        tabs.toggleApproveTab(id);
    };

    const handleRowClick = (row) => {
        const { id: itemId, isExclusive } = row.original;
        const { list } = notepad;
        const item = list.getItem(itemId);
        if (isShiftPressed()) {
            list.selectRange(list.cursor, itemId);
            return;
        }
        list.setCursor(itemId);
        if (isCtrlPressed()) {
            item.toggle();
            return;
        }
        const instance = notepad.createDetailed(item.dealType);
        instance.setData({ id: itemId, exclusive: isExclusive });
        if (handleRowClick.callback) {
            instance.load(itemId, false);
            handleRowClick.callback(instance);
        }
    };
    const handleDoubleRowClick = (data) => {
        const {
            original: { id: itemId, isExclusive },
        } = data;
        const { list } = notepad;
        const item = list.getItem(itemId);
        if (!tabs.getTab(item.id)) {
            const instance = notepad.createDetailed(item.dealType);
            instance.setData({ id: itemId, exclusive: isExclusive });
            const form = instance.createForm();
            form.on('done', (response) => {
                if (!response.filter((res) => Boolean(res)).length) {
                    return;
                }
                tabs.removeTab(item.id);
                item.setData(instance.toItem());
            });
            list.setCursor(item.id);
            instance.load(item.id);
            tabs.addTab(
                instance,
                item.id,
                item.objectName,
                TAB_TYPE.EDIT_OBJECT,
            );
        }
    };
    const handleObjectTypeTabChange = (e, type) => {
        notepad.setActiveObjectTypeTab(type);
        handleRowClick.callback(null);
    };
    const handleDealTypeTabChange = (e, type) => {
        notepad.setActiveDealTypeTab(type);
        handleRowClick.callback(null);
    };
    const handleDeleteNotepadClick = () => {
        setApproveData({
            message: 'Вы точно хотите удалить этот блокнот?',
            description: 'Восстановить его будет невозможно',
            action: 'deleteNotepad',
        });
        tabs.toggleApproveTab(id);
    };
    const handleDeleteObjectsClick = () => {
        setApproveData({
            message: 'Вы точно хотите удалить эти объекты?',
            action: 'deleteObjects',
        });
        tabs.toggleApproveTab(id);
    };
    const handleDeleteNotepadApprove = () => {
        notepads.remove(notepad.id);
        onClose();
    };
    const handleDeleteObjectsApprove = () => {
        const itemsToDelete = notepad.list.selected;
        notepads.removeObjectsFromNotepad(
            notepad.id,
            itemsToDelete.map(({ id: objectId }) => objectId),
            notepad.list.objectType,
        ).then(() => {
            notepad.list.reload();
        });
    };
    const handleApprove = ({ action }) => {
        switch (action) {
            case 'deleteNotepad':
                return handleDeleteNotepadApprove();
            case 'deleteObjects':
                return handleDeleteObjectsApprove();
            default:
                return toggleApprovingState();
        }
    };
    const handleCreateRelated = (objectId, phone) => {
        const item = notepad.list.getItem(objectId);
        const { objectName } = item;
        const relatedTabId = `${objectId}-related`;
        if (!tabs.getTab(relatedTabId)) {
            const related = createRelated(objectId, phone);
            related.load();
            tabs.addTab(
                {
                    related,
                    phone,
                    tabs,
                },
                relatedTabId,
                objectName || objectId,
                TAB_TYPE.RELATED_OBJECTS,
            );
        }
    };
    return (
        <Observer>
            {() => {
                const linkedTab = tabs.getTab(id);
                const { approveIsActive } = linkedTab;
                return (
                    <>
                        {
                            approveIsActive
                                ? (
                                    <NotepadApprove
                                        data={approveData}
                                        onClose={onClose}
                                        onApprove={handleApprove}
                                        toggleApprovingState={toggleApprovingState}
                                    />
                                )
                                : null
                        }
                        <div className={`${styles.container} ${styles.notepad}`}>
                            <h2 className={`handle ${styles.title}`}>
                                Блокнот
                                &nbsp;
                                {name}
                            </h2>
                            <div className={styles.close}>
                                <ButtonClose
                                    onClick={() => onClose(id)}
                                />
                            </div>
                            <div className={styles.notepad__content}>
                                <div className={styles.notepad__menu}>
                                    <div className={styles['notepad__tab-list']}>
                                        {
                                            Object.values(OBJECT_TYPE).map((type) => (
                                                <RelatedTypeTab
                                                    key={`notepad-tab-${type.toLowerCase()}`}
                                                    type={type}
                                                    active={type === notepad.activeObjectType}
                                                    onClick={handleObjectTypeTabChange}
                                                >
                                                    {OBJECT_TYPE_NAME[type]}
                                                </RelatedTypeTab>
                                            ))
                                        }
                                    </div>
                                    <div className={`${styles['notepad__tab-list']} ${styles['notepad__tab-list--parents']}`}>
                                        {
                                            Object.values(DEAL_TYPE).map((type) => (
                                                <RelatedTypeTab
                                                    key={`notepad-tab-${type.toLowerCase()}`}
                                                    type={type}
                                                    active={type === notepad.activeDealType}
                                                    onClick={handleDealTypeTabChange}
                                                    className={`${styles.notepad__tab} ${styles['notepad__tab--parent']}`}
                                                    activeClassName={styles['notepad__tab--active']}
                                                >
                                                    {DEAL_TYPE_NAME[type]}
                                                </RelatedTypeTab>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className={styles.content}>
                                    <NotepadStats notepad={notepad} />
                                    <Observer>
                                        {() => (
                                            <>
                                                {
                                                    notepad.list.tableData.length === 0
                                                    && !notepad.list.loading
                                                        ? (
                                                            <div className={`${styles.notepad__message} ${styles.message}`}>
                                                                <div
                                                                    className={`${styles.message__title}`}
                                                                >
                                                                    Сообщение
                                                                </div>
                                                                <div
                                                                    className={styles.message__text}
                                                                >
                                                                    В блокнот не добавлено
                                                                    &nbsp;
                                                                    ни одного объекта заданного типа
                                                                </div>
                                                            </div>
                                                        ) : null
                                                }
                                                <NotepadContent
                                                    useStores={useStores}
                                                    list={notepad.list}
                                                    tabs={tabs}
                                                    onDoubleRowClick={handleDoubleRowClick}
                                                    onRowClick={handleRowClick}
                                                    onPhoneClick={
                                                        createRelated
                                                            ? handleCreateRelated
                                                            : undefined
                                                    }
                                                />
                                            </>
                                        )}
                                    </Observer>
                                </div>
                                <Observer>
                                    {() => (
                                        <IconButton
                                            className={styles['notepad__delete-objects']}
                                            type="button"
                                            size={IconButton.SIZE.MEDIUM}
                                            color={IconButton.COLOR.WHITE_RED}
                                            onClick={handleDeleteObjectsClick}
                                            icon={<SVGIcon SVGElement={CrossIcon} />}
                                            disabled={!notepad.list.selected.length}
                                        >
                                            <>
                                                Удалить объекты из блокнота
                                                &nbsp;
                                                {
                                                    notepad.list.selected.length
                                                        ? `(${notepad.list.selected.length})`
                                                        : ''
                                                }
                                            </>
                                        </IconButton>
                                    )}
                                </Observer>
                                <button
                                    type="button"
                                    className={styles.notepad__delete}
                                    onClick={handleDeleteNotepadClick}
                                >
                                    Удалить блокнот
                                </button>
                            </div>
                        </div>
                    </>
                );
            }}
        </Observer>
    );
};

NotepadObjects.propTypes = {
    useStores: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    createRelated: PropTypes.func,
    store: PropTypes.shape({
        name: PropTypes.string,
        notepads: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(NotepadList),
        ]),
        notepad: PropTypes.shape({
            id: PropTypes.number,
            activeObjectType: PropTypes.string,
            activeDealType: PropTypes.string,
            list: PropTypes.instanceOf(ObjectList),
            setActiveObjectTypeTab: PropTypes.func,
            setActiveDealTypeTab: PropTypes.func,
            createDetailed: PropTypes.func,
            loading: PropTypes.bool,
        }),
        tabs: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(PopupTabsWithApproveStore),
        ]),
        Detailed: PropTypes.shape({}),
    }).isRequired,
};

NotepadObjects.defaultProps = {
    createRelated: () => null,
};

export default NotepadObjects;
