import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './Filter.module.scss';

const FilterFieldGroup = ({ fieldgroup: { label, name }, children }) => (
    <div className={`${styles.filter__fieldgroup} ${styles[`filter__fieldgroup--${name}`] || ''}`}>
        {
            label
                ? (
                    <Label className={styles.filter__label}>
                        {label}
                    </Label>
                )
                : null
        }
        <div className={styles['filter__fieldgroup-list']}>
            {children}
        </div>
    </div>
);

FilterFieldGroup.propTypes = {
    fieldgroup: PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    children: PropTypes.element.isRequired,
};

export default FilterFieldGroup;
