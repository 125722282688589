import React from 'react';
import PropTypes from 'prop-types';

import styles from './ObjectPageView.module.scss';

const LoadMoreBadge = ({ isShown, onClick, children }) => (
    <button
        type="button"
        onClick={onClick}
        className={`
            ${styles['page-view__load-more']}
            ${isShown ? styles['page-view__load-more--active'] : ''}
        `}
    >
        ↑
        &nbsp;
        &nbsp;
        {children}
    </button>
);

LoadMoreBadge.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        ),
    ]),
    isShown: PropTypes.bool,
    onClick: PropTypes.func,
};

LoadMoreBadge.defaultProps = {
    children: 0,
    isShown: false,
    onClick: () => null,
};

export default LoadMoreBadge;
