import React from 'react';
import PropTypes from 'prop-types';
import { MessageDialog } from '../../../components/MessageDialog';

const ObjectListState = ({ isEmpty, error }) => {
    if (!isEmpty && !error) {
        return null;
    }
    return (
        <MessageDialog title={error ? 'Ошибка!' : 'Сообщение'}>
            {error || 'По вашему запросу объектов не найдено'}
        </MessageDialog>
    );
};

ObjectListState.propTypes = {
    isEmpty: PropTypes.bool,
    error: PropTypes.string,
};

ObjectListState.defaultProps = {
    isEmpty: false,
    error: undefined,
};

export default ObjectListState;
