import { DIRECTORIES } from '../../../constants/operator';
import { CITY_NAME_CASES } from '../../../config/operator';

export default [{
    type: 'fieldset',
    name: 'city',
    fields: [{
        name: 'regionId',
        type: 'directorySearch',
        directory: DIRECTORIES.REGION,
        label: 'Субъект',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required_without:areaId',
    }, {
        name: 'areaId',
        type: 'directorySearch',
        directory: DIRECTORIES.AREA,
        label: 'Район субъекта',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required_without:regionId',
    }, {
        name: 'cityTypeId',
        type: 'directorySelect',
        directory: DIRECTORIES.CITY_TYPE,
        label: 'Тип населенного пункта',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required',
    }, {
        name: 'name',
        type: 'text',
        label: 'Название населенного пункта',
        size: 'md',
        rules: 'required|max:128',
    }, {
        name: 'suburbOfCityId',
        type: 'hidden',
    }, {
        name: 'suburbOfCity',
        type: 'hidden',
    }, {
        name: 'suburbOfCityCheckbox',
        type: 'checkbox',
        size: 'md',
        label: `Пригород ${CITY_NAME_CASES.genitive}`,
    }],
}];
