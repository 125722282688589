import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './ItemStatus.module.scss';

const ItemStatusField = ({
    type,
    label,
    id,
    children,
}) => {
    const { props: { name } } = children;

    return (
        <div className={styles['item-status__field']}>
            {
                label
                    ? (
                        <Label
                            className={`
                                ${styles['item-status__label']}
                                ${styles[`item-status__label--${type}`] || ''}
                            `}
                            htmlFor={id}
                        >
                            {label}
                        </Label>
                    )
                    : null
            }
            {
                React.cloneElement(
                    children,
                    { className: name === 'reasonId' ? styles['item-status__tag-list'] : undefined },
                )
            }
        </div>
    );
};

ItemStatusField.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    children: PropTypes.element.isRequired,
};

ItemStatusField.defaultProps = {
    label: '',
};

export default ItemStatusField;
