import { observable } from 'mobx';

class ObjectsMarkers {
    @observable
    markers = [];

    loading = false;

    firstLoad = true;

    constructor(dataSource) {
        this.dataSource = dataSource;
    }

    load(dealType, objectType) {
        const {
            dataSource,
            firstLoad,
            loading,
        } = this;

        if (loading && !firstLoad) {
            return Promise.resolve([]);
        }

        this.loading = true;

        return dataSource.getObjectMarkers({ dealType, objectType })
            .then((response) => {
                const parsedList = {};
                response.forEach((item) => {
                    const coords = `${item.latitude}-${item.longitude}`;
                    if (parsedList[coords]) {
                        parsedList[coords].objectIds.push(item.id);
                        parsedList[coords].numberOfObjects += 1;
                    } else {
                        parsedList[coords] = {
                            coords: [item.latitude, item.longitude],
                            objectIds: [item.id],
                            numberOfObjects: 1,
                        };
                    }
                });
                this.markers = Object.values(parsedList);
                this.firstLoad = false;
            }).catch(() => Promise.resolve([])).finally(() => {
                this.loading = false;
            });
    }
}

const createObjectsMarkersStore = (dataSource) => new ObjectsMarkers(dataSource);

export default ObjectsMarkers;
export {
    createObjectsMarkersStore,
};
