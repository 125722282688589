import React from 'react';
import PropTypes from 'prop-types';

import styles from './ObjectPageView.module.scss';

const ObjectPageStats = ({ children }) => (
    <div className={styles['page-view__stats']}>
        {children}
    </div>
);

ObjectPageStats.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any,
};

ObjectPageStats.defaultProps = {
    children: 0,
};

export default ObjectPageStats;
