import {
    toJS,
    extendObservable,
    action,
    observable,
} from 'mobx';

class DynamicStructure {
    keys = [];

    @observable memoKey = 0;

    @action
    setData(data) {
        if (!(data instanceof Object)) {
            throw new Error('Provided data must be an instance of Object');
        }
        const keys = Object.keys(data);
        keys.forEach((key) => { this.set(key, data[key]); });
        this.memoKey += 1;
    }

    @action
    set(key, value) {
        if (!this.keys.includes(key)) {
            this.keys.push(key);
        }
        if (Object.getOwnPropertyDescriptor(this, key)) {
            if (!Object.prototype.hasOwnProperty.call(this, `$${key}`)) {
                extendObservable(this, { [`$${key}`]: value });
                return;
            }
            this[`$${key}`] = value;
            return;
        }
        if (Object.prototype.hasOwnProperty.call(this, `$${key}`)) {
            this[`$${key}`] = value;
            return;
        }
        extendObservable(this, {
            [`$${key}`]: value,
            get [key]() {
                return toJS(this[`$${key}`]);
            },
        });
    }
}

export default DynamicStructure;
