import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import PresetListStore from '../../../stores/shared/PresetList';
import List from './List';
import Dialog from '../Dialog';
import PresetForm from './Form';
import { Button } from '../../../components/Button';

import filterStyles from '../Filter/Filter.module.scss';
import styles from './PresetList.module.scss';

const PresetList = ({
    useStores,
    presets,
    baseLink,
    onPresetDelete,
}) => {
    const { dialogStore, objectListPageStore } = useStores();

    const handleEditItemClick = (item) => {
        const itemForm = item.createItemForm();
        const unsubscribeForm = itemForm.on('done', () => {
            dialogStore.hide('edit-preset-item');
        });
        const unsubscribeDialog = dialogStore.on('edit-preset-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });
        presets.setEditItem(item);
        dialogStore.show('edit-preset-item');
    };
    const handleOpenPresetClick = (item, onlyNew) => {
        const date = item.checkedAtAsIs ? item.checkedAtAsIs : new Date(0);
        objectListPageStore.setTab(0);
        objectListPageStore.setSelectedPreset({
            item,
            filter: {
                ...item.filter,
                objectSelectionId: item.id,
                updatedAtTo: !onlyNew ? date : undefined,
                updatedAtFrom: onlyNew ? date : undefined,
            },
        });
    };
    const handleDeletePresetClick = (item) => {
        presets.setEditItem(item);
        dialogStore.show('delete-preset-item');
    };
    const handleDeletePresetApprove = (e) => {
        presets.editItem.remove()
            .then(() => {
                dialogStore.hide('delete-preset-item');
                onPresetDelete(presets.editItem.id, e);
            });
    };
    const handleCancelPresetRemove = () => {
        dialogStore.hide('delete-preset-item');
    };
    return (
        <>
            <div className={`${filterStyles.filter__content} ${styles.list}`}>
                <Observer>
                    {() => (
                        <List
                            presets={presets && presets.list}
                            loading={presets.loading}
                            loaded={presets.loaded}
                            active={objectListPageStore.activePresetId}
                            baseLink={`${objectListPageStore.baseHref}${baseLink}`}
                            onEditPresetClick={handleEditItemClick}
                            onOpenPresetClick={handleOpenPresetClick}
                            onDeletePresetClick={handleDeletePresetClick}
                        />
                    )}
                </Observer>
                <Dialog
                    useStores={useStores}
                    name="edit-preset-item"
                    size="sm"
                    footer={null}
                    header={<>Редактировать название</>}
                >
                    <Observer>
                        {() => (
                            <PresetForm
                                useStores={useStores}
                                form={presets.editItem && presets.editItem.itemForm}
                            />
                        )}
                    </Observer>
                </Dialog>
                <Dialog
                    useStores={useStores}
                    name="delete-preset-item"
                    size="sm"
                    footer={null}
                    header={<>Удалить подборку</>}
                >
                    <div className={styles['preset-remove']}>
                        <div className={styles['preset-remove__warning']}>
                            Вы уверены, что хотите удалить подборку?
                            <br />
                            Отменить это действие невозможно
                        </div>
                        <Observer>
                            {() => (
                                <div className={styles['preset-remove__actions']}>
                                    <Button
                                        type={Button.TYPE.BUTTON}
                                        color={Button.COLOR.GRAY}
                                        size={Button.SIZE.LARGE}
                                        disabled={presets.loading}
                                        block
                                        onClick={handleCancelPresetRemove}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        type={Button.TYPE.BUTTON}
                                        color={Button.COLOR.ORANGE}
                                        size={Button.SIZE.LARGE}
                                        disabled={presets.loading}
                                        block
                                        onClick={handleDeletePresetApprove}
                                    >
                                        Да
                                    </Button>
                                </div>
                            )}
                        </Observer>
                    </div>
                </Dialog>
            </div>
        </>
    );
};

PresetList.propTypes = {
    useStores: PropTypes.func.isRequired,
    onPresetDelete: PropTypes.func.isRequired,
    presets: PropTypes.instanceOf(PresetListStore),
    baseLink: PropTypes.string,
};

PresetList.defaultProps = {
    presets: null,
    baseLink: '/list',
};

export default PresetList;
