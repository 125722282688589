import createBaseDirectoryService from '../../shared/DirectoryService';
import createQuery from '../../utils/create-query';
import { DIRECTORIES } from '../../../constants/operator';

const createDirectoryService = (request) => {
    const baseService = createBaseDirectoryService(request);
    return {
        ...baseService,
        getList: (directory, ctx) => {
            if (directory === DIRECTORIES.BILLING_PAYMENT_TYPES) {
                return Promise.resolve([
                    { value: 1, label: 'Расчетный счет' },
                    { value: 2, label: 'Что-то еще' },
                ]);
            }
            return baseService.getList(directory, ctx);
        },
        getOperatorCompanies: () => (
            request.get('/auth/me')
                .then(({ attachedCompanies }) => (
                    Promise.resolve(attachedCompanies.map(({ id, name }) => ({
                        value: id,
                        label: name,
                    })))
                ))
        ),
        getBillingCompanies: () => (
            request.get('/billing/companies')
        ),
        getUsersActionTypes: () => (
            request.get('/billing/users/actions/types')
        ),
        getUsers: (query) => (
            request.get(`/users/search?query=${query}`)
                .then((users) => (
                    Promise.resolve(users.map(({
                        id,
                        login,
                        companyName,
                        ...rest
                    }) => ({
                        value: id,
                        label: `${companyName}: ${login}`,
                        login,
                        companyName,
                        ...rest,
                    })))
                ))
        ),
        getAll: (directory, page, ctx = {}) => {
            const ctxQuery = createQuery({ page, ...ctx });
            return request.get(`/directory/${directory}/all?${ctxQuery}`);
        },
        create: (directory, data) => request.post(`/directory/${directory}`, data),
        remove: (directory, id) => request.delete(`/directory/${directory}/${id}`),
        update: (directory, id, data) => request.put(`/directory/${directory}/${id}`, data),
    };
};

export default createDirectoryService;
