import createQuery from '../../utils/create-query';
import createBaseObjectService, { getCursor } from '../../shared/ObjectService';

const createObjectService = (request) => {
    const baseService = createBaseObjectService(request);
    return {
        ...baseService,
        deleteObject(id, { objectType }) {
            return request.delete(`/objects/${objectType}/${id}`);
        },
        checkObject(id, { objectType }) {
            return request.patch(`/objects/${objectType}/${id}`);
        },
        updateUserNote(id, data, { objectType }) {
            return request.put(`/objects/${objectType}/${id}/user-note`, data);
        },
        updateIncorrectOptions(id, data, { objectType }) {
            return request.put(`/objects/${objectType}/${id}/incorrect`, data);
        },
        getRelatedGroups: (id, phone) => {
            const ctx = createQuery({ excludeId: id, phone });
            return request.get(`objects/related?${ctx}`);
        },
        getRelatedObjects: (id, phone, clientCursor, { objectType, requestType }) => {
            const ctx = createQuery({ excludeId: id, phone, requestType });
            const cursor = getCursor(clientCursor);
            return request.get(`objects/${objectType}/related?${ctx}${cursor ? `&${cursor}` : ''}`);
        },
        getPriceStats: (payload) => request.post('price-analysis/dynamics', payload),
    };
};

export default createObjectService;
