import React, { useRef } from 'react';
import { Observer } from 'mobx-react';
import { format } from 'date-fns';
import {
    SelectedHighlight,
    SelectedRow,
    SelectedRowWithHint,
    SelectedRowWithContent,
    SelectedTitle,
    SelectedWrapper,
} from '../../../components/TableSelected';
import ReactTooltip from '../../../lib/react-tooltip';
import SVGIcon from '../../../components/Icons/SVGIcon';
import Phone from '../../../components/Phone';
import { LinkIcon } from '../../../components/Icons/icons';
import SelectedSaleFlat from './SelectedSaleFlat';
import SelectedRentFlat from './SelectedRentFlat';
import SelectedSaleHouse from './SelectedSaleHouse';
import SelectedRentHouse from './SelectedRentHouse';
import SelectedStead from './SelectedStead';
import SelectedRentCommercial from './SelectedRentCommercial';
import SelectedSaleCommercial from './SelectedSaleCommercial';
import { CopyToClipboardButton } from '../../../components/CopyToClipboard/CopyToClipboard';
import { defaultProps, propTypes } from './propTypes';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT, EMPTY_DATA_STRING } from '../../../constants/shared';
import declOfNum from '../../../utils/decl-of-num';
import styles from './SelectedObject.module.scss';

const SHOW_PHONES = 3;
const SPACER_FOR_DATE_AND_TIME = '\xa0\xa0\xa0\xa0';
const words = ['контакт', 'контакта', 'контактов'];
const SelectedObject = ({
    store,
    style,
    setSelectedHeight,
    onPhoneClick,
}) => {
    const tooltipRef = useRef();
    const wrapperRef = useRef();
    const handlePhoneClick = (phone) => () => {
        if (onPhoneClick && typeof onPhoneClick === 'function') {
            onPhoneClick(store.id, phone);
        }
    };
    return (
        <Observer>
            {() => {
                let Component = () => (
                    <SelectedRow
                        name={EMPTY_DATA_STRING}
                        classNames={{ row: styles.selected__row }}
                    >
                        {EMPTY_DATA_STRING}
                    </SelectedRow>
                );
                tooltipRef.current?.hideTooltip();
                /*
                    DESC: динамически изменяет высоту миникарточки
                    (зависит от кол-ва тел-ов в контакте)
                    FIXME: find better solution,
                    wrapperRef не обновляет offsetHeight
                */
                let baseOffset = 4;
                if (store.phones.length > 2) {
                    baseOffset = 3;
                }
                setSelectedHeight(() => `${wrapperRef.current?.offsetHeight + baseOffset}px`);
                switch (store.objectType) {
                    case 'flats':
                        Component = SelectedSaleFlat;
                        if (store.dealType === 'rent') {
                            Component = SelectedRentFlat;
                        }
                        break;
                    case 'houses':
                        Component = SelectedSaleHouse;
                        if (store.dealType === 'rent') {
                            Component = SelectedRentHouse;
                        }
                        break;
                    case 'steads':
                        Component = SelectedStead;
                        break;
                    case 'commercial':
                        Component = SelectedSaleCommercial;
                        if (store.dealType === 'rent') {
                            Component = SelectedRentCommercial;
                        }
                        break;
                    default:
                        break;
                }
                return (
                    <SelectedWrapper
                        ref={wrapperRef}
                        style={style}
                        loading={store.loading}
                        instantLoading
                        footer={(
                            <Observer>
                                {() => (
                                    <div className={styles.meta}>
                                        Просмотров:
                                        {' '}
                                        {store.views || 0}
                                    </div>
                                )}
                            </Observer>
                        )}
                    >
                        <Observer>
                            {() => (
                                <div key={store.memoKey}>
                                    <div className={styles.selected}>
                                        <div>
                                            <SelectedTitle>
                                                <div>
                                                    Информация по объекту
                                                    &nbsp;
                                                    <SelectedHighlight
                                                        color={SelectedHighlight.COLOR.GREEN}
                                                    >
                                                        {store.objectName}
                                                    </SelectedHighlight>
                                                </div>
                                            </SelectedTitle>
                                        </div>
                                        <div className={styles.selected__body}>
                                            <div className={`${styles.selected__col} ${styles.selected__orange}`}>
                                                <Component store={store} />
                                            </div>
                                            <div className={`${styles.selected__col} ${styles.selected__green}`}>
                                                <SelectedRowWithHint name="Статус" classNames={{ row: styles.selected__row, name: styles.selected__bold }}>
                                                    {store.status?.label}
                                                </SelectedRowWithHint>
                                                <SelectedRowWithHint name="Причина" classNames={{ row: styles.selected__row, name: styles.selected__bold }}>
                                                    {store.reason?.label}
                                                </SelectedRowWithHint>
                                                <SelectedRowWithHint name="Прим. внеш." classNames={{ row: styles.selected__row }}>
                                                    {store.publicNote}
                                                </SelectedRowWithHint>
                                                <SelectedRowWithHint name="Прим. внутр." classNames={{ row: styles.selected__row }}>
                                                    {store.privateNote}
                                                </SelectedRowWithHint>
                                                <SelectedRowWithHint name="Заметки" classNames={{ row: styles.selected__row }}>
                                                    {/*
                                                    В операторском кабинете показываем
                                                    комментарий к смене статуса,
                                                    а в клиентском кабинете -
                                                    личные заметки пользователя */}
                                                    {store.comment || store.userNote}
                                                </SelectedRowWithHint>
                                            </div>
                                            <div className={`${styles.selected__col} ${styles.selected__purple}`}>
                                                <SelectedRowWithContent
                                                    name="Компания"
                                                    value={store.company}
                                                    classNames={{
                                                        row: styles.selected__row,
                                                        name: styles.selected__bold,
                                                        value: styles.selected__semibold,
                                                    }}
                                                >
                                                    <span className={styles.selected__contacts}>
                                                        всего
                                                        <span>{store.phones.length}</span>
                                                        {declOfNum(store.phones.length, words)}
                                                    </span>
                                                </SelectedRowWithContent>
                                                {store.phones
                                                    .slice(0, SHOW_PHONES)
                                                    .map((phoneItem) => (
                                                        <SelectedRowWithContent
                                                            key={phoneItem.phone}
                                                            name={
                                                                phoneItem.contact
                                                                    ? phoneItem.contact
                                                                    : EMPTY_DATA_STRING
                                                            }
                                                            classNames={{
                                                                row: `${styles.selected__row} ${styles['selected__row--contacts']}`,
                                                                name: styles.selected__bold,
                                                            }}
                                                            value={(
                                                                <Phone
                                                                    className={`${styles.selected__phone} ${styles.selected__bold}`}
                                                                >
                                                                    {phoneItem.phone}
                                                                </Phone>
                                                            )}
                                                        >
                                                            <>
                                                                {onPhoneClick && (
                                                                    <button
                                                                        className={
                                                                            styles.selected__call
                                                                        }
                                                                        type="button"
                                                                        onClick={
                                                                            handlePhoneClick(
                                                                                phoneItem.phone,
                                                                            )
                                                                        }
                                                                    >
                                                                        <SVGIcon
                                                                            SVGElement={LinkIcon}
                                                                            width={11}
                                                                            height={12}
                                                                        />
                                                                    </button>
                                                                )}
                                                                <CopyToClipboardButton
                                                                    text={phoneItem.phone}
                                                                />
                                                            </>
                                                        </SelectedRowWithContent>
                                                    ), [])}
                                                <SelectedRowWithContent
                                                    name="Добавлено"
                                                    classNames={{
                                                        row: styles.selected__row,
                                                        name: styles.selected__bold,
                                                        value: styles.selected__semibold,
                                                    }}
                                                    value={
                                                        store.createdAt
                                                            ? format(
                                                                new Date(store.createdAt),
                                                                `${DEFAULT_DATE_FORMAT}${SPACER_FOR_DATE_AND_TIME}${DEFAULT_TIME_FORMAT}`,
                                                            )
                                                            : EMPTY_DATA_STRING
                                                    }
                                                />
                                                <SelectedRowWithContent
                                                    name="Проверено"
                                                    classNames={{
                                                        row: styles.selected__row,
                                                        name: styles.selected__bold,
                                                        value: styles.selected__semibold,
                                                    }}
                                                    value={
                                                        store.checkedAt
                                                            ? format(
                                                                new Date(store.checkedAt),
                                                                `${DEFAULT_DATE_FORMAT}${SPACER_FOR_DATE_AND_TIME}${DEFAULT_TIME_FORMAT}`,
                                                            )
                                                            : EMPTY_DATA_STRING
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Observer>
                        <ReactTooltip
                            ref={tooltipRef}
                            id="selected-object-tooltip"
                            bodyMode
                        />
                    </SelectedWrapper>
                );
            }}
        </Observer>
    );
};

SelectedObject.propTypes = propTypes;
SelectedObject.defaultProps = defaultProps;

export default SelectedObject;
