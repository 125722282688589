import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import {
    Line,
    XAxis,
    YAxis,
    Tooltip,
    LineChart,
    CartesianGrid,
    ResponsiveContainer,
    // FIXME: ошибка линтера
    // eslint-disable-next-line import/no-unresolved
} from 'recharts';

import Preloader from '../../../components/Preloader';
import parsePrice from '../../../utils/parse-price';
import FormElements from '../Form';
import FilterField from '../../shared/Filter/FilterField';
import FilterFieldset from '../../shared/Filter/FilterFieldset';
import FilterFieldGroup from '../../shared/Filter/FilterFieldGroup';
import { Button, IconButton } from '../../../components/Button';

import styles from './PriceStats.module.scss';

const PriceStats = (props) => {
    const { useStores, priceStats } = props;

    return (
        <Observer>
            {() => (
                <div className={styles.stats}>
                    <div className={styles.stats__chart}>
                        {priceStats.loading && (
                            <div className={styles.stats__loader}>
                                <Preloader />
                            </div>
                        )}
                        {(!priceStats.stats.length && !priceStats.loading) && (
                            <div className={styles.stats__empty}>Нет данных</div>
                        )}
                        {(!!priceStats.stats.length && !priceStats.loading) && (
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    data={priceStats.stats}
                                    margin={{
                                        top: 16,
                                        left: 16,
                                        right: 16,
                                        bottom: 16,
                                    }}
                                >
                                    <CartesianGrid stroke="#d3e0ec" />
                                    <XAxis
                                        tick={{ fontSize: 12, fill: '#a2b0bd' }}
                                        stroke="#d3e0ec"
                                        dataKey="date"
                                        tickMargin={8}
                                    />
                                    <YAxis
                                        tick={{ fontSize: 12, fill: '#a2b0bd' }}
                                        stroke="#d3e0ec"
                                        tickMargin={8}
                                        tickFormatter={(value) => parsePrice(value)}
                                    />
                                    <Line
                                        dot={{ r: 8 }}
                                        type="monotone"
                                        dataKey="price"
                                        stroke="#15a1ef"
                                        activeDot={{ r: 8, fill: '#15a1ef' }}
                                        strokeWidth={2}
                                        isAnimationActive={false}
                                    />
                                    <Tooltip
                                        content={({ active, payload }) => (!active ? null : (
                                            <div className={styles.stats__tooltip}>
                                                {parsePrice(payload[0]?.value, true)}
                                            </div>
                                        ))}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                    <form onSubmit={priceStats.form.onSubmit} className={styles.stats__filters}>
                        <div>
                            <FormElements
                                form={priceStats.form}
                                elements={priceStats.form.render}
                                useStores={useStores}
                                components={{
                                    FieldWrapper: FilterField,
                                    FieldsetWrapper: FilterFieldset,
                                    FieldGroupWrapper: FilterFieldGroup,
                                }}
                            />
                            {!!priceStats.formToShow && (
                                <FormElements
                                    form={priceStats.formToShow}
                                    elements={priceStats.formToShow.render}
                                    useStores={useStores}
                                    components={{
                                        FieldWrapper: FilterField,
                                        FieldsetWrapper: FilterFieldset,
                                        FieldGroupWrapper: FilterFieldGroup,
                                    }}
                                />
                            )}
                        </div>

                        <div className={styles.stats__footer}>
                            <Button
                                type="submit"
                                size={IconButton.SIZE.LARGE}
                                color={IconButton.COLOR.BLUE}
                                block
                            >
                                Искать
                            </Button>
                            <Button
                                type="reset"
                                size={IconButton.SIZE.MEDIUM}
                                color={IconButton.COLOR.WHITE}
                                block
                                onClick={priceStats.resetFilters}
                            >
                                Очистить
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </Observer>
    );
};

PriceStats.propTypes = {
    useStores: PropTypes.func,
    priceStats: PropTypes.shape({
        stats: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
        })).isRequired,
        form: PropTypes.shape({
            render: PropTypes.func.isRequired,
            onSubmit: PropTypes.func.isRequired,
        }),
        formToShow: PropTypes.shape({
            render: PropTypes.func.isRequired,
            onSubmit: PropTypes.func.isRequired,
        }),
        loading: PropTypes.bool.isRequired,
        resetFilters: PropTypes.func.isRequired,
    }).isRequired,
};

PriceStats.defaultProps = {
    useStores: () => null,
};

export default PriceStats;
