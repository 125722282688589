import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import NotepadListStore from '../../../stores/shared/NotepadList/NotepadList';
import List from './List';
import Dialog from '../Dialog';
import NotepadForm from './Form';

import filterStyles from '../Filter/Filter.module.scss';
import styles from './NotepadList.module.scss';

const NotepadList = ({
    useStores,
    notepads,
    onNotepadOpen,
}) => {
    const { dialogStore } = useStores();
    const handleNewItemClick = () => {
        const itemForm = notepads.createItemForm();
        const unsubscribeForm = itemForm.on('done', () => {
            dialogStore.hide('new-notepad-item');
        });
        const unsubscribeDialog = dialogStore.on('new-notepad-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });
        dialogStore.show('new-notepad-item');
    };
    const handleEditItemClick = (item) => {
        const itemForm = item.createItemForm();
        const unsubscribeForm = itemForm.on('done', () => {
            dialogStore.hide('edit-notepad-item');
        });
        const unsubscribeDialog = dialogStore.on('edit-notepad-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });
        notepads.setEditItem(item);
        dialogStore.show('edit-notepad-item');
    };
    return (
        <div className={`${filterStyles.filter__content} ${styles.list}`}>
            <Observer>
                {() => (
                    <List
                        notepads={notepads && notepads.list}
                        loading={notepads.loading}
                        loaded={notepads.loaded}
                        onEditNotepadClick={handleEditItemClick}
                        onNotepadOpen={onNotepadOpen}
                    />
                )}
            </Observer>
            <button
                className={styles.list__create}
                type="button"
                onClick={handleNewItemClick}
            >
                + Создать блокнот
            </button>
            <Dialog
                useStores={useStores}
                name="edit-notepad-item"
                size="sm"
                footer={null}
                header={<>Переименовать блокнот</>}
            >
                <Observer>
                    {() => (
                        <NotepadForm
                            useStores={useStores}
                            form={notepads.editItem && notepads.editItem.itemForm}
                        />
                    )}
                </Observer>
            </Dialog>
        </div>
    );
};

NotepadList.propTypes = {
    useStores: PropTypes.func.isRequired,
    notepads: PropTypes.instanceOf(NotepadListStore),
    onNotepadOpen: PropTypes.func,
};

NotepadList.defaultProps = {
    notepads: null,
    onNotepadOpen: () => null,
};

export default NotepadList;
