export default [{
    name: 'login',
    type: 'text',
    label: 'Логин',
    rules: 'required|numeric|min:1',
    size: 'lg',
}, {
    name: 'password',
    type: 'password',
    label: 'Пароль',
    rules: 'required|string',
    size: 'lg',
}];
