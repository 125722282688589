import Field from './DefaultField';

class CheckboxList extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw) => {
        const { providedOutput } = this;
        let value = raw instanceof Array ? raw : [];
        if (value === 'value') {
            value = undefined;
        }
        if (typeof providedOutput === 'function') {
            return providedOutput(value || []);
        }
        return value || [];
    }
}

export default CheckboxList;
