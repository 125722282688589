import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { KEYS } from './constants';

import styles from './KeyboardHandler.module.scss';

const KeyboardHandler = forwardRef(({
    className,
    children,
    onDown,
    onUp,
    onEnter,
    onSpace,
    preventHandler,
}, ref) => {
    // state
    const [focused, setFocus] = useState(false);
    // interactions
    const handleFocus = () => {
        setFocus(true);
    };
    const handleBlur = () => {
        setFocus(false);
    };
    const handleKeyDown = (e) => {
        if (preventHandler()) {
            return;
        }

        const { which: key } = e;
        let runAction;
        if (!focused || !Object.keys(KEYS).indexOf(key) === -1) {
            return;
        }
        switch (key) {
            case KEYS.DOWN:
                runAction = onDown;
                break;
            case KEYS.UP:
                runAction = onUp;
                break;
            case KEYS.ENTER:
                runAction = onEnter;
                break;
            case KEYS.SPACE:
                runAction = onSpace;
                break;
            default:
                return;
        }
        if (runAction && typeof runAction === 'function') {
            if (key !== KEYS.SPACE) {
                e.preventDefault();
                e.stopPropagation();
            }
            runAction();
        }
    };
    return (
        <div
            ref={ref}
            tabIndex="-1"
            className={`
                ${styles['keyboard-handler']}
                ${focused ? styles['keyboard-handler--focused'] : ''}
                ${className}
            `}
            role="presentation"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
        >
            {children}
        </div>
    );
});

KeyboardHandler.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
    onDown: PropTypes.func,
    onUp: PropTypes.func,
    onEnter: PropTypes.func,
    onSpace: PropTypes.func,
    preventHandler: PropTypes.func,
};

KeyboardHandler.defaultProps = {
    className: '',
    children: null,
    onDown: undefined,
    onUp: undefined,
    onEnter: undefined,
    onSpace: undefined,
    preventHandler: () => false,
};

export default KeyboardHandler;
