/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { AlertIcon } from '../../../components/Icons/icons';
import { POSITION, EMPTY_DATA_STRING } from '../../../constants/shared';

import styles from './Table.module.scss';

const CellStreet = ({ value: { value: street, fiasCompatible }, rowHint }) => (
    <>
        {
            !fiasCompatible
                ? (
                    <div
                        className={styles['table__fias-warning']}
                        data-tip="Не сходится с ФИАС"
                        data-place={POSITION.RIGHT}
                        data-class={styles.table__hint}
                    >
                        <SVGIcon
                            SVGElement={AlertIcon}
                            height={12}
                        />
                    </div>
                )
                : null
        }
        <div
            className={`${styles.table__street}`}
            data-tip={`
                ${street || ''}
                ${(
                    rowHint
                        ? `<div class="${styles['table__row-hint']}">${rowHint}</div>`
                        : ''
                )}
            `}
            data-multiline
            data-html
            data-place={POSITION.RIGHT}
            data-class={`
                ${styles.table__hint}
                ${rowHint ? styles['table__hint--multiline'] : ''}
            `}
        >
            {street || EMPTY_DATA_STRING}
        </div>
    </>
);

CellStreet.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.string,
        fiasCompatible: PropTypes.bool,
    }).isRequired,
    rowHint: PropTypes.string,
};

CellStreet.defaultProps = {
    rowHint: null,
};

export default CellStreet;
