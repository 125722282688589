/* eslint-disable import/prefer-default-export */
import flats from './flats';
import houses from './houses';
import rentFlats from './rent-flats';
import rentHouses from './rent-houses';
import steads from './steads';
import commercial from './commercial';
import notepadFlats from './notepad-flats';
import notepadCommercial from './notepad-commercial';
import notepadHouses from './notepad-houses';
import notepadSteads from './notepad-steads';
import relatedFlats from './related-flats';
import relatedHouses from './related-houses';
import relatedSteads from './related-steads';
import relatedCommercial from './related-commercial';

export default {
    flats,
    rentFlats,
    houses,
    rentHouses,
    steads,
    commercial,
    notepadFlats,
    notepadCommercial,
    notepadHouses,
    notepadSteads,
    relatedFlats,
    relatedHouses,
    relatedSteads,
    relatedCommercial,
};
