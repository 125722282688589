import React from 'react';
import PropTypes from 'prop-types';

import styles from './SVGIcon.module.scss';

const SVGIcon = ({
    SVGElement,
    height,
    className,
    style,
}) => {
    const Icon = SVGElement();
    const {
        props: {
            height: defaultHeight,
            width: defaultWidth,
        },
    } = Icon;
    const scale = height / defaultHeight;
    const width = defaultWidth * scale;
    return (
        <span
            className={`${styles['svg-icon']} ${className}`}
            style={{
                width: `${width}px`,
                height: `${height}px`,
            }}
        >
            <SVGElement
                style={{
                    transform: `
                        translate(${Math.min((width - defaultWidth) / 2, 0)}px, ${(height - defaultHeight) / 2}px)
                        scale(${scale}, ${scale})
                    `,
                    ...style,
                }}
            />
        </span>
    );
};

SVGIcon.propTypes = {
    SVGElement: PropTypes.func.isRequired,
    height: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
    className: PropTypes.string,
};

SVGIcon.defaultProps = {
    style: {},
    height: 16,
    className: '',
};

export default SVGIcon;
