export default [{
    name: 'email',
    type: 'text',
    label: 'Электронная почта',
    rules: 'required|email',
    size: 'lg',
    icon: 'email',
}, {
    name: 'phone',
    type: 'phone',
    label: 'Телефон',
    rules: 'required|phone',
    size: 'lg',
    icon: 'phone',
}];
