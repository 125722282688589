import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './ObjectPageView.module.scss';

const ObjectTypeTab = ({ link, children, onClick }) => (
    <NavLink
        className={styles['page-view__menu-item']}
        activeClassName={styles['page-view__menu-item--selected']}
        to={link}
        onClick={onClick}
    >
        <div className={styles['page-view__menu-title']}>
            {children}
        </div>
    </NavLink>
);

ObjectTypeTab.propTypes = {
    link: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

ObjectTypeTab.defaultProps = {
    onClick: () => null,
};

export default ObjectTypeTab;
