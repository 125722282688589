import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import buttonStyles from './Button.module.scss';
import iconStyles from './IconButton.module.scss';

const size2height = {
    [Button.SIZE.SMALL]: 10,
    [Button.SIZE.MEDIUM]: 10,
    [Button.SIZE.LARGE]: 12,
};

const IconButton = ({
    icon,
    children,
    size,
    className,
    as,
    ...props
}) => {
    let Component = Button;
    if (as) {
        Component = as;
    }
    return (
        <Component
            className={`${buttonStyles['button--with-icon']} ${className}`}
            size={size}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <span className={`${iconStyles.button__icon} ${buttonStyles.button__icon}`}>
                {
                    React.cloneElement(
                        icon,
                        { height: size2height[size] },
                    )
                }
            </span>
            {children}
        </Component>
    );
};

IconButton.SIZE = {
    ...Button.SIZE,
};

IconButton.TYPE = {
    ...Button.TYPE,
};

IconButton.COLOR = {
    ...Button.COLOR,
};

IconButton.propTypes = {
    icon: PropTypes.element.isRequired,
    as: PropTypes.element,
    ...Button.propTypes,
};

IconButton.defaultProps = {
    as: undefined,
    ...Button.defaultProps,
};

export default IconButton;
