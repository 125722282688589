import { DIRECTORIES } from '../../constants/operator';
import { DEAL_TYPE_NAME } from '../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'filter',
    fields: [{
        name: 'query',
        type: 'text',
        label: 'Поиск',
        placeholder: 'Имя, почта',
        size: 'sm',
        icon: 'search',
        rules: 'min:3',
    }, {
        name: 'ownerTypeIds',
        type: 'directorySelect',
        label: 'Вид',
        directory: DIRECTORIES.OWNER_TYPE,
        multiple: true,
    }, {
        name: 'status',
        type: 'directorySelect',
        label: 'Статус объекта',
        directory: DIRECTORIES.OBJECT_STATUS,
        multiple: true,
    }, {
        name: 'objectType',
        type: 'dropdownSelect',
        label: 'Тип недвижимости',
        multiple: true,
        options: [{
            id: 1,
            value: 'FLAT',
            label: 'Квартиры',
        }, {
            id: 2,
            value: 'HOUSE',
            label: 'Дома',
        }, {
            id: 3,
            value: 'STEAD',
            label: 'Земля',
        }, {
            id: 4,
            value: 'COMMERCIAL',
            label: 'Коммерческая недвижмость',
        }],
    }, {
        name: 'dealType',
        type: 'dropdownSelect',
        label: 'Тип сделки',
        multiple: true,
        options: [{
            id: 1,
            value: 'RENT',
            label: DEAL_TYPE_NAME.RENT,
        }, {
            id: 2,
            value: 'SALE',
            label: DEAL_TYPE_NAME.SALE,
        }],
    }, {
        name: 'actionTypeIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OPERATOR_ACTION_TYPE,
        label: 'Действие',
        size: 'sm',
        multiple: true,
        asDropdown: true,
    }, {
        name: 'createdAtFrom',
        type: 'datepicker',
        label: 'Дата от',
        startOfDay: true,
        placement: 'bottom-end',
        clearable: true,
        value: new Date(),
    }, {
        name: 'createdAtTo',
        type: 'datepicker',
        label: 'Дата до',
        placement: 'bottom-end',
        endOfDay: true,
        clearable: true,
        value: new Date(),
    }],
}];
