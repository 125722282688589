import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { SIZE } from '../../constants/shared';

import styles from './Rights.module.scss';

const crud = ['create', 'read', 'update', 'delete'];

const Rights = ({
    id,
    value,
    onChange,
}) => {
    const createChangeHandler = (key, name, checked) => () => {
        // eslint-disable-next-line no-param-reassign
        value[key][name] = checked;
        onChange({
            ...value,
            [key]: {
                ...value[key],
                [name]: Number(checked),
            },
        });
    };
    return (
        <div className={styles.rights}>
            <table>
                <thead>
                    <tr>
                        <th className={`${styles.rights__head} ${styles['rights__head--title']}`}>
                            &nbsp;
                        </th>
                        <th className={`${styles.rights__head} ${styles['rights__head--create']}`}>
                            Добавление
                        </th>
                        <th className={`${styles.rights__head} ${styles['rights__head--read']}`}>
                            Просмотр
                        </th>
                        <th className={`${styles.rights__head} ${styles['rights__head--update']}`}>
                            Редактирование
                        </th>
                        <th className={`${styles.rights__head} ${styles['rights__head--delete']}`}>
                            Удаление
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(value).map((key, index) => {
                            const {
                                label,
                                ...values
                            } = value[key];
                            return (
                                <tr key={`${key}-${index + 1}`}>
                                    <td className={`${styles.rights__td} ${styles['rights__td--title']}`}>
                                        {label}
                                    </td>
                                    {
                                        crud.map((name) => (
                                            <td className={styles.rights__td} key={`${key}-${name}`}>
                                                <Checkbox
                                                    id={`${key}-${id}-${name}`}
                                                    name={`${key}-${name}`}
                                                    className={styles.rights__checkbox}
                                                    checked={Boolean(values[name])}
                                                    value={1}
                                                    onChange={createChangeHandler(
                                                        key,
                                                        name,
                                                        !values[name],
                                                    )}
                                                    size={SIZE.MEDIUM}
                                                />
                                            </td>
                                        ))
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
};

Rights.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.shape({
            label: PropTypes.string,
            create: PropTypes.number,
            read: PropTypes.number,
            update: PropTypes.number,
            delete: PropTypes.number,
        }),
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
};

Rights.defaultProps = {
    id: '',
    value: {},
    onChange: () => null,
};

export default Rights;
