// NOTE: these are backend directories! Must not be used for store naming or etc.
const REGION = 'region';
const AREA = 'area';
const CITY_TYPE = 'citytype';
const CITY = 'city';
const CITY_DISTRICT = 'citydistrict';
const CITY_DISTRICT_LANDMARK = 'citydistrictlandmark';
const STREET_TYPE = 'streettype';
const STREET = 'street';
const STREETS = 'streets';
const HOUSE = 'house';
const AGENCY = 'agency';
const COMPLEX = 'complex';
const DEVELOPER = 'developer';
const SERIES = 'series';
const PARSER_SOURCE_BOARD = 'parsersourceboard';
const WALL_MATERIAL = 'wallmaterial';
const OBJECT_CONDITION = 'objectcondition';
const OBJECT_FLAT_TYPE = 'objectflattype'; // класс жилья
const OBJECT_STATUS = 'objectstatus';
const OBJECT_STATUS_REASON = 'objectstatusreason';
const EFFLUENT = 'effluent';
const FURNISHING = 'furnishing';
const FURNITURE = 'furniture';
const BALCONY = 'balcony';
const BUILDING = 'building';
const HEATING = 'heating';
const LAVATORY = 'lavatory';
const OBJECT_COMMERCIAL_TYPE = 'objectcommercialtype';
const OBJECT_HOUSE_TYPE = 'objecthousetype';
const OBJECT_ROOM_TYPE = 'objectroomtype';
const OBJECT_STEAD_TYPE = 'objectsteadtype';
const HOUSE_TYPE = 'housetype';
const OWNER_TYPE = 'ownertype';
const PASSAGE = 'passage';
const PHONE_NUMBER = 'phone';
const RENT_CONDITION = 'rentcondition';
const RENT_FLAT_TYPE = 'rentflattype';
const RENT_PERIOD = 'rentperiod';
const RENT_SERVICE = 'rentservice';
const WATER_SUPPLY = 'watersupply';
const WINDOW_TYPE = 'windowtype';
const PHONE_TYPE = 'phonetype';
const STOVE_TYPE = 'stovetype';
// FIXME: endpoint not exist now
const ORGANIZATION = 'organization';
const INCORRECT_OPTIONS = 'incorrect/options';
const ROOMS_COUNT = 'roomscount';
const USER_ACTION_TYPE = 'actiontypes';

const ADDRESS_DIRECTORIES = {
    REGION,
    AREA,
    CITY_TYPE,
    CITY,
    CITY_DISTRICT,
    CITY_DISTRICT_LANDMARK,
    STREET_TYPE,
    STREET,
    STREETS,
    HOUSE,
    AGENCY,
};

const SIMPLE_DIRECTORIES = {
    PHONE_TYPE,
    COMPLEX,
    DEVELOPER,
    SERIES,
    PARSER_SOURCE_BOARD,
    WALL_MATERIAL,
    OBJECT_CONDITION,
    OBJECT_FLAT_TYPE,
    OBJECT_STATUS,
    OBJECT_STATUS_REASON,
    HOUSE_TYPE,
    EFFLUENT,
    FURNISHING,
    FURNITURE,
    BALCONY,
    BUILDING,
    HEATING,
    LAVATORY,
    PHONE_NUMBER,
    OBJECT_COMMERCIAL_TYPE,
    OBJECT_HOUSE_TYPE,
    OBJECT_ROOM_TYPE,
    OBJECT_STEAD_TYPE,
    OWNER_TYPE,
    PASSAGE,
    RENT_CONDITION,
    RENT_FLAT_TYPE,
    RENT_PERIOD,
    RENT_SERVICE,
    WATER_SUPPLY,
    WINDOW_TYPE,
    ORGANIZATION,
    STOVE_TYPE,
    INCORRECT_OPTIONS,
    ROOMS_COUNT,
    USER_ACTION_TYPE,
};

const LOCAL_DIRECTORIES = {
    ROOMS_COUNT,
};

export default {
    ADDRESS_DIRECTORIES,
    SIMPLE_DIRECTORIES,
    LOCAL_DIRECTORIES,
    ...LOCAL_DIRECTORIES,
    ...ADDRESS_DIRECTORIES,
    ...SIMPLE_DIRECTORIES,
};
