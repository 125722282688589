import { PresetService } from '../../../services/client';
import PresetList from '../../shared/PresetList';

class ClientPresetList extends PresetList {
    constructor() {
        super(PresetService);
    }
}

export default ClientPresetList;
