import {
    DEAL_TYPE,
    DEAL_TYPE_NAME,
    OBJECT_TYPE,
    OBJECT_TYPE_NAME,
} from '../../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'general',
    fields: [{
        name: 'dealType',
        type: 'dropdownSelect',
        label: 'Тип сделки',
        rules: 'required',
        options: [{
            id: 1,
            value: DEAL_TYPE.RENT,
            label: DEAL_TYPE_NAME.RENT,
        }, {
            id: 2,
            value: DEAL_TYPE.SALE,
            label: DEAL_TYPE_NAME.SALE,
        }],
    }, {
        name: 'objectType',
        type: 'dropdownSelect',
        label: 'Тип недвижимости',
        rules: 'required',
        options: [{
            id: 1,
            value: OBJECT_TYPE.FLAT,
            label: OBJECT_TYPE_NAME.FLAT,
        }, {
            id: 2,
            value: OBJECT_TYPE.HOUSE,
            label: OBJECT_TYPE_NAME.HOUSE,
        }, {
            id: 3,
            value: OBJECT_TYPE.STEAD,
            label: OBJECT_TYPE_NAME.STEAD,
        }, {
            id: 4,
            value: OBJECT_TYPE.COMMERCIAL,
            label: OBJECT_TYPE_NAME.COMMERCIAL,
        }],
    }, {
        name: 'dateFrom',
        type: 'datepicker',
        label: 'Добавлен от',
        placement: 'bottom-end',
        clearable: true,
    }, {
        name: 'dateTo',
        type: 'datepicker',
        label: 'Добавлен до',
        placement: 'bottom-end',
        endOfDay: true,
        clearable: true,
    }],
}];
