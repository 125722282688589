import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { ObjectTypeTab } from '../ObjectPageView';
import splitParams from '../../../routes/utils/split-params';

import styles from '../ObjectPageView/ObjectPageView.module.scss';

const ObjectListTabs = ({ names, useStores }) => {
    const { path, url } = useRouteMatch();
    const segments = url.split('/');
    const [parents, sisters] = splitParams(path);
    const { objectListPageStore } = useStores();
    const lastSegment = segments[segments.length - 1];
    const parentRoutes = parents.map((parent) => {
        const firstPart = segments.slice(0, -2);
        return {
            link: [...firstPart, parent].join('/'),
            title: names[parent],
        };
    });
    const sisterRoutes = sisters.map((sister) => {
        const firstPart = segments.slice(0, -1);
        return {
            link: [...firstPart, sister].join('/'),
            title: names[sister],
        };
    });

    const handleClick = () => {
        objectListPageStore?.reset();
    };
    return (
        <div className={styles['page-view__menu']}>
            <div className={styles['page-view__sisters']}>
                {
                    sisterRoutes.map(({ link, title }) => (
                        <ObjectTypeTab key={link} link={link} onClick={handleClick}>
                            {title}
                        </ObjectTypeTab>
                    ))
                }
            </div>
            <div className={styles['page-view__parents']}>
                {
                    parentRoutes.map(({ link, title }) => (
                        <ObjectTypeTab key={link} link={`${link}/${lastSegment}`} onClick={handleClick}>
                            {title}
                        </ObjectTypeTab>
                    ))
                }
            </div>
        </div>
    );
};

ObjectListTabs.propTypes = {
    names: PropTypes.shape({}),
    useStores: PropTypes.func,
};

ObjectListTabs.defaultProps = {
    names: {
        sale: 'Продажа',
        rent: 'Аренда',
        flats: 'Квартиры',
        houses: 'Дома',
        steads: 'Земля',
        commercial: 'Нежилое',
    },
    useStores: () => null,
};

export default ObjectListTabs;
