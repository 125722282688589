import { computed } from 'mobx';
import ListItem from '../entities/ListItem';

const listItemWithCursor = (Store) => {
    if (ListItem !== Store && !Object.prototype.isPrototypeOf.call(ListItem, Store)) {
        throw new Error('A ListItem class must be provided');
    }
    return class extends Store {
        @computed
        get active() {
            const { parent, id } = this;
            return parent.cursor === id;
        }
    };
};

export default listItemWithCursor;
