/* eslint-disable no-return-assign */
/* eslint-disable prefer-promise-reject-errors */
import { useRef } from 'react';
import { DOUBLE_CLICK_DELAY } from '../../constants/shared';

export const cancellablePromise = (promise) => {
    let isCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
            (error) => {
                console.error(error);
                reject({ isCanceled, error });
            },
        );
    });
    return {
        promise: wrappedPromise,
        cancel: () => (isCanceled = true),
    };
};

const useCancellablePromises = () => {
    const pendingPromises = useRef([]);
    const appendPendingPromise = (promise) => (pendingPromises.current) = (
        [...pendingPromises.current, promise]
    );
    const removePendingPromise = (promise) => pendingPromises.current = (
        pendingPromises.current.filter((p) => p !== promise)
    );
    const clearPendingPromises = () => pendingPromises.current.map(
        (p) => p.cancel(),
    );
    const api = {
        appendPendingPromise,
        removePendingPromise,
        clearPendingPromises,
    };
    return api;
};

export const delay = (n) => new Promise((resolve) => setTimeout(resolve, n));

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
    const api = useCancellablePromises();
    const handleClick = () => {
        api.clearPendingPromises();
        const waitForClick = cancellablePromise(delay(DOUBLE_CLICK_DELAY));
        api.appendPendingPromise(waitForClick);

        return waitForClick.promise
            .then(() => {
                api.removePendingPromise(waitForClick);
                onClick();
            })
            .catch((errorInfo) => {
                api.removePendingPromise(waitForClick);
                console.error(errorInfo);
                if (!errorInfo.isCanceled) {
                    throw errorInfo.error;
                }
            });
    };

    const handleDoubleClick = () => {
        api.clearPendingPromises();
        onDoubleClick();
    };

    return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;
