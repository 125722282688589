/* eslint-disable no-unused-vars */
import { computed, observable } from 'mobx';
import DataSource from '../DataSource';
import Cursor from '../DataSource/entities/Cursor';
import defaultForms from '../../../forms/shared/objects-filter';
import { ListWithDataSource } from '../List';
import listWithSelect from '../List/mixins/listWithSelect';
import listWithCursor from '../List/mixins/listWithCursor';
import clearObject from '../../../utils/clear-object';
import downloadFile from '../../../utils/download-file';
import capitalizeFirstLetter from '../../../utils/capitalize-first-letter';

let listId = 1;

/**
 * Получить подготовленные значения формы группировки
 * @param {Array} groupingFormValues значения формы
 * @returns подготовленные значения формы группировки
 */
const getPreparedGroupingFormValues = (groupingFormValues) => {
    let dataToAggregateBy = { ...groupingFormValues };
    if (Object.keys(dataToAggregateBy).every((key) => !dataToAggregateBy[key])) {
        return {
            dataToAggregateBy: null,
            fieldsToGroupBy: [],
        };
    }

    // Если проставили галочки, но не указали дельты, считаем дельты за 0
    if (dataToAggregateBy.totalArea && !dataToAggregateBy.totalAreaDelta) {
        dataToAggregateBy.totalAreaDelta = 0;
    } else if (!dataToAggregateBy.totalArea && dataToAggregateBy.totalAreaDelta) {
        dataToAggregateBy.totalAreaDelta = 0;
    }

    if (dataToAggregateBy.price && !dataToAggregateBy.priceDelta) {
        dataToAggregateBy.priceDelta = 0;
    } else if (!dataToAggregateBy.price && dataToAggregateBy.priceDelta) {
        dataToAggregateBy.priceDelta = 0;
    }

    if (dataToAggregateBy.priceDelta) {
        dataToAggregateBy.priceDelta *= 1000;
    }

    Object.keys(dataToAggregateBy).forEach((key) => {
        if (!dataToAggregateBy[key]) {
            delete dataToAggregateBy[key];
        }
    });

    let fieldsToGroupBy = ['groupKey'];
    if (!Object.keys(dataToAggregateBy).length) {
        dataToAggregateBy = null;
        fieldsToGroupBy = [];
    }

    return {
        dataToAggregateBy,
        fieldsToGroupBy,
    };
};

class ObjectList extends ListWithDataSource {
    constructor(service, strategy, forms = defaultForms) {
        super(
            undefined,
            new DataSource(service),
            new Cursor(),
        );
        this.strategy = strategy;
        this.strategy.setParent(this);
        this.Item = strategy.Item;
        this.forms = forms;
        this.listId = listId;
        this.viewedAt = new Date();
        listId += 1;
        this.createFilterForm();
        this.createGroupingForm();
    }

    @computed
    get tableHeaders() {
        const { strategy } = this;
        return strategy.tableHeaders;
    }

    @computed
    get tableData() {
        return this.list;
    }

    @observable.ref grouping = [];

    @computed
    get objectType() {
        const { strategy } = this;
        return strategy.objectType;
    }

    get filterForm() {
        const { strategy } = this;
        return strategy.filterForm;
    }

    get groupingForm() {
        const { strategy } = this;
        return strategy.groupingForm;
    }

    get storageName() {
        const { strategy: { requestType, dealType, objectType } } = this;
        return [requestType, dealType, objectType]
            .filter(Boolean)
            .map((name, index) => (
                index === 0
                    ? name
                    : capitalizeFirstLetter(name)
            ))
            .join('');
    }

    getList(cursor, ctx, groupsToAggregateBy) {
        const { strategy: { requestType, dealType, objectType } } = this;
        return this.dataSource.getObjects(
            ctx,
            cursor,
            {
                requestType,
                dealType,
                objectType,
            },
            groupsToAggregateBy,
        );
    }

    preload() {
        this.dataSource.setPreload();
    }

    load() {
        const { strategy } = this;
        return strategy.prepareFilterData({ includeAggregationData: true })
            .then((data) => super.load(data))
            .then((list) => {
                this.addList(list);
                this.onLoad(list);
                return list;
            })
            .catch((e) => {
                console.error(e);
                this.dataSource.setError(e);
            });
    }

    reload(options) {
        const { paginationHandler, strategy } = this;
        paginationHandler.setPaginationData(undefined);
        this.setList([]);
        return strategy.prepareFilterData(options)
            .then((data) => super.load(data))
            .then((list) => {
                this.setList(list);
                this.onLoad(list);
                return list;
            })
            .catch((e) => {
                console.error(e);
                this.dataSource.setError(e);
            });
    }

    loadMore() {
        const { paginationHandler } = this;
        if (paginationHandler.isEnd) {
            return Promise.resolve();
        }
        return this.load();
    }

    createFilterForm() {
        const { strategy } = this;
        const filterForm = strategy.createFilterForm();
        this.initFilterFormListeners();
        return filterForm;
    }

    createGroupingForm() {
        const { strategy } = this;
        if (typeof strategy.createGroupingForm !== 'function') {
            console.warn('В стратегии нет функции createGroupingForm');
            return null;
        }

        const groupingForm = strategy.createGroupingForm();
        this.initGroupingFormListeners();
        return groupingForm;
    }

    initFilterFormListeners() {
        const { filterForm } = this;
        if (!filterForm) {
            return;
        }
        filterForm.on('success', (values) => {
            this.viewedAt = new Date();
            this.setFilterData(values);

            const groupingFormValues = this.groupingForm.values();
            const {
                dataToAggregateBy,
                fieldsToGroupBy,
            } = getPreparedGroupingFormValues(groupingFormValues);
            this.setAggregationData(dataToAggregateBy);

            return this.reload({ includeAggregationData: true })
                .then((result) => {
                    this.setGroupingData(fieldsToGroupBy);
                    return result;
                });
        });
        filterForm.on('reset', (values) => {
            this.viewedAt = new Date();
            this.setFilterData(values);
            return this.reload();
        });
    }

    initGroupingFormListeners() {
        const { groupingForm } = this;
        if (!groupingForm) {
            return;
        }

        const totalArea = groupingForm.$('totalArea');
        const totalAreaDelta = groupingForm.$('totalAreaDelta');
        const price = groupingForm.$('price');
        const priceDelta = groupingForm.$('priceDelta');

        totalAreaDelta.set('disabled', true);
        priceDelta.set('disabled', true);

        const onTotalAreaCheckboxChanged = ({ change: { newValue } }) => {
            if (!newValue) {
                totalAreaDelta.set('value', '');
                totalAreaDelta.set('disabled', true);
                return;
            }

            totalAreaDelta.set('disabled', false);
        };

        const onPriceCheckboxChanged = ({ change: { newValue } }) => {
            if (!newValue) {
                priceDelta.set('value', '');
                priceDelta.set('disabled', true);
                return;
            }

            priceDelta.set('disabled', false);
        };

        totalArea.observe(onTotalAreaCheckboxChanged);
        price.observe(onPriceCheckboxChanged);

        groupingForm.on('success', (values) => {
            const { dataToAggregateBy, fieldsToGroupBy } = getPreparedGroupingFormValues(values);

            const filterFormValues = this.filterForm.values();
            this.setFilterData(filterFormValues);
            this.setAggregationData(dataToAggregateBy);
            return this.reload({ includeAggregationData: true })
                .then((result) => {
                    this.setGroupingData(fieldsToGroupBy);
                    return result;
                });
        });
        groupingForm.on('reset', (values) => this.reload());
    }

    setFilterData(data) {
        const { strategy } = this;
        strategy.setFilterData(clearObject(data));
    }

    setSort(field, direction) {
        const { strategy } = this;
        strategy.setSortData({ field, direction });
    }

    setAggregationData(dataToAggregateBy) {
        const { strategy } = this;
        strategy.setAggregationData(dataToAggregateBy);
    }

    setGroupingData(fieldsToGroupBy) {
        this.grouping = fieldsToGroupBy;
    }

    getPresetFilterData() {
        const { strategy, strategy: { filterForm } } = this;
        return strategy.prepareFilterData.call({
            ...strategy,
            // NOTE: getters are not iterated by spread operator
            defaultSortData: strategy.defaultSortData,
            filterData: clearObject(filterForm.values()),
        })
            .then((currentFilter) => (
                new Promise((resolve) => {
                    strategy.prepareFilterData()
                        .then((filter) => {
                            resolve({ filter, currentFilter });
                        });
                })
            ));
    }

    downloadImage(id, index) {
        const { images, objectName } = this.getItem(id);
        downloadFile(images[index]?.src, objectName);
    }

    // конвертирует сохраненный фильтр в подходящий
    convertPresetFilterData(filterData) {
        const { strategy } = this;
        return strategy.convertPresetFilterData(filterData);
    }

    applyFilter(filter) {
        const { strategy: { filterForm } } = this;
        filterForm.reset();
        if (!filter.cityIds) {
            filterForm.$('cityIds').set('value', '');
        }
        filterForm.disableObservers = true;
        Object.keys(filter).forEach((fieldName) => {
            if (!filterForm.has(fieldName) || filter[fieldName] === undefined) {
                return;
            }
            let value = filter[fieldName];
            // объединение улиц
            if (fieldName === 'streetIds') {
                const stack = {};
                value = filter[fieldName].reduce((carry, street) => {
                    const { label, value: id } = street;
                    if (stack[label] === undefined) {
                        stack[label] = carry.length;
                    }
                    if (!carry[stack[label]]) {
                        carry.push(street);
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        carry[stack[label]] = Array.isArray(carry[stack[label]].value)
                            ? { label, value: [...carry[stack[label]].value, id] }
                            : { label, value: [carry[stack[label]].value, id] };
                    }
                    return carry;
                }, []);
            }
            filterForm.$(fieldName).set('value', value);
        });
        filterForm.disableObservers = false;
    }
}

export default listWithCursor(listWithSelect(ObjectList));
