import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import PageView from '../PageView';
import Filter from '../Filter/Filter';
import ObjectTypeTab from './ObjectTypeTab';

import styles from './ObjectPageView.module.scss';

const List = ({
    filterForm,
    useStores,
    children,
    meta,
    disabled,
}) => {
    const { pathname } = useLocation();
    const segments = pathname.split('/');
    const lastSegment = segments[segments.length - 1];
    return (
        <PageView
            meta={meta}
            disabled={disabled}
        >
            {
                filterForm
                    ? (
                        <Filter
                            form={filterForm}
                            title="Поиск"
                            useStores={useStores}
                        />
                    )
                    : null
            }
            <div className={styles['page-view__menu']}>
                <div className={styles['page-view__sisters']}>
                    {
                        meta.sisterRoutes.map(({ link, title }) => (
                            <ObjectTypeTab key={link} link={link}>
                                {title}
                            </ObjectTypeTab>
                        ))
                    }
                </div>
                <div className={styles['page-view__parents']}>
                    {
                        meta.parentRoutes.map(({ link, title }) => (
                            <ObjectTypeTab key={link} link={`${link}/${lastSegment}`}>
                                {title}
                            </ObjectTypeTab>
                        ))
                    }
                </div>
            </div>
            <div className={styles['page-view__wrapper']}>
                {children}
            </div>
        </PageView>
    );
};

List.propTypes = {
    filterForm: PropTypes.shape({}),
    useStores: PropTypes.func.isRequired,
    meta: PropTypes.shape({
        pageTitle: PropTypes.string,
        icon: PropTypes.func,
        sisterRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        parentRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
    }),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]).isRequired,
    disabled: PropTypes.bool,
};

List.defaultProps = {
    filterForm: null,
    meta: {
        title: null,
        icon: null,
        sisterRoutes: [],
        parentRoutes: [],
    },
    disabled: false,
};

export default List;
