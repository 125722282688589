import createQuery from '../../utils/create-query';
import createBaseObjectService, { getCursor } from '../../shared/ObjectService';

/**
 * Получить фильтр для подзапроса агрегации данных
 * @param {Object} objectToAggregateBy
 * @param {Object} filterForOriginalRequest
 * @param {Object} aggregationData
 * @returns
 */
const getSubFilterByAggregationData = (
    objectToAggregateBy,
    filterForOriginalRequest,
    aggregationData,
) => {
    const groupDisplayValue = [];

    const subFilter = {
        ...filterForOriginalRequest,
    };

    if (aggregationData.cityId || filterForOriginalRequest.cityIds?.length) {
        subFilter.cityIds = objectToAggregateBy.cityId
            ? [objectToAggregateBy.cityId]
            : null;

        if (aggregationData.cityId) {
            groupDisplayValue.push(objectToAggregateBy.city);
        }
    }

    if (aggregationData.cityDistrictId || filterForOriginalRequest.cityDistrictIds?.length) {
        subFilter.cityDistrictIds = objectToAggregateBy.cityDistrictId
            ? [objectToAggregateBy.cityDistrictId]
            : null;

        if (aggregationData.cityDistrictId) {
            groupDisplayValue.push(objectToAggregateBy.cityDistrict);
        }
    }

    if (aggregationData.streetId || filterForOriginalRequest.streetIds?.length) {
        subFilter.streetIds = objectToAggregateBy.streetId
            ? [objectToAggregateBy.streetId]
            : null;

        if (aggregationData.streetId) {
            groupDisplayValue.push(objectToAggregateBy.street);
        }
    }

    if (aggregationData.houseId || filterForOriginalRequest.houseIds?.length) {
        subFilter.houseIds = objectToAggregateBy.houseId
            ? [objectToAggregateBy.houseId]
            : null;

        if (aggregationData.houseId) {
            groupDisplayValue.push(objectToAggregateBy.house);
        }
    }

    if (aggregationData.floor) {
        subFilter.floors = objectToAggregateBy.floor
            ? [objectToAggregateBy.floor]
            : null;

        const displayValue = aggregationData.floorCount
            ? `${objectToAggregateBy.floor || '-'}/${objectToAggregateBy.floorCount || '-'}`
            : `${objectToAggregateBy.floor || '-'}/-`;
        groupDisplayValue.push(displayValue);
    }

    if (aggregationData.floorCount) {
        subFilter.floorCounts = objectToAggregateBy.floorCount
            ? [objectToAggregateBy.floorCount]
            : null;

        if (!aggregationData.floor) {
            groupDisplayValue.push(`-/${objectToAggregateBy.floorCount || '-'}`);
        }
    }

    if (aggregationData.roomCount) {
        subFilter.rooms = objectToAggregateBy.roomCount
            ? [objectToAggregateBy.roomCount]
            : null;

        if (objectToAggregateBy.roomCount) {
            groupDisplayValue.push(`${objectToAggregateBy.roomCount} к`);
        } else {
            groupDisplayValue.push(null);
        }
    }

    if (aggregationData.totalArea) {
        if (objectToAggregateBy.totalArea) {
            groupDisplayValue.push(`${objectToAggregateBy.totalArea} м²`);
        } else {
            groupDisplayValue.push(null);
        }

        if (objectToAggregateBy.totalArea === null) {
            subFilter.totalArea = null;
        } else {
            const totalAreaDelta = (aggregationData.totalAreaDelta ?? 0) / 2;
            subFilter.totalAreaFrom = Math.max(objectToAggregateBy.totalArea - totalAreaDelta, 0);
            subFilter.totalAreaTo = objectToAggregateBy.totalArea + totalAreaDelta;
        }
    }

    if (aggregationData.price) {
        const priceDisplayValue = objectToAggregateBy.price / 1000;
        if (priceDisplayValue) {
            groupDisplayValue.push(`${priceDisplayValue} т.р.`);
        } else {
            groupDisplayValue.push(null);
        }

        if (!objectToAggregateBy.price) {
            subFilter.price = null;
        } else {
            const priceDelta = (aggregationData.priceDelta ?? 0) / 2;
            subFilter.priceFrom = Math.max(objectToAggregateBy.price - priceDelta, 0);
            subFilter.priceTo = objectToAggregateBy.price + priceDelta;
        }
    }

    return [subFilter, groupDisplayValue];
};

/**
 * Получить список с применением группировки и аггрегации
 */
const getListWithGroupingAggregation = async (
    request,
    requestType,
    dealType,
    objectType,
    clientCursor,
    filterForRequest,
    aggregationData,
    loadedDataCount = 0,
    lastSubResultMeta = {},
    keysRequested = [],
) => {
    const lastLoadedDataCount = loadedDataCount;
    const cursor = getCursor(clientCursor);
    const query = `/objects/${requestType}/${dealType}/${objectType}${cursor ? `?${cursor}` : ''}`;
    const serverDefaultPaginationSize = 60;
    /* eslint-disable no-param-reassign */
    const result = await request.post(query, filterForRequest);
    /* eslint-disable no-param-reassign */
    lastSubResultMeta.prev = result.meta.prev;
    /* eslint-disable no-param-reassign */
    lastSubResultMeta.next = result.meta.next;
    const primaryLoadNextCursor = result.meta.next;
    const subResults = [];

    /* eslint-disable no-plusplus */
    for (let subRequestId = 0; subRequestId < result.data.length; subRequestId++) {
        const objectFromPrimaryRequest = result.data[subRequestId];

        objectFromPrimaryRequest.groupKey = Object
            .keys(aggregationData)
            .map((field) => `${field}=${objectFromPrimaryRequest[field]}`).join('&');
        if (keysRequested.includes(objectFromPrimaryRequest.groupKey)) {
            // eslint-disable-next-line no-continue
            continue;
        }
        keysRequested.push(objectFromPrimaryRequest.groupKey);

        const [subFilter, groupDisplayValue] = getSubFilterByAggregationData(
            objectFromPrimaryRequest,
            filterForRequest,
            aggregationData,
        );

        subFilter.itemsPerPage = serverDefaultPaginationSize - loadedDataCount;
        /* eslint-disable no-await-in-loop */
        const subResult = await request.post(query, subFilter);
        if (subResult.data.length === 1 && !aggregationData.showSingleGroups) {
            // eslint-disable-next-line no-continue
            continue;
        }
        /* eslint-disable no-param-reassign */
        lastSubResultMeta.prev = subResult.meta.prev ?? lastSubResultMeta.prev;
        /* eslint-disable no-param-reassign */
        lastSubResultMeta.next = subResult.meta.next ?? lastSubResultMeta.next;

        subResult.data = subResult.data.map((sub) => {
            // eslint-disable-next-line no-param-reassign
            sub.groupKey = objectFromPrimaryRequest.groupKey;
            // eslint-disable-next-line no-param-reassign
            sub.groupDisplayValue = groupDisplayValue.join(', ');
            return sub;
        });

        if ((loadedDataCount + subResult.data.length) < serverDefaultPaginationSize) {
            subResults.push(subResult.data);
            loadedDataCount += subResult.data.length;
            // eslint-disable-next-line no-continue
            continue;
        }

        const subResultsToFill = subResult.data
            .slice(0, serverDefaultPaginationSize - loadedDataCount);
        subResults.push(subResultsToFill);
        loadedDataCount += subResultsToFill.length;
        if (loadedDataCount === serverDefaultPaginationSize) {
            break;
        }
    }

    let flatSubResults = subResults.flat(1);
    if (loadedDataCount < serverDefaultPaginationSize && loadedDataCount !== lastLoadedDataCount) {
        lastSubResultMeta.next = primaryLoadNextCursor;
        const moreSubResults = await getListWithGroupingAggregation(
            request,
            requestType,
            dealType,
            objectType,
            primaryLoadNextCursor,
            filterForRequest,
            aggregationData,
            loadedDataCount,
            lastSubResultMeta,
            keysRequested,
        );

        flatSubResults = flatSubResults.concat(moreSubResults.data);
    }

    // в flatSubResults также будут входить оригинальные объекты
    // eslint-disable-next-line no-param-reassign
    result.data = flatSubResults;
    result.meta = {
        ...result.meta,
        prev: lastSubResultMeta.prev,
        next: lastSubResultMeta.next,
    };

    return result;
};

const createObjectService = (request) => {
    const baseService = createBaseObjectService(request);
    return {
        ...baseService,
        get: (id, { objectType }) => request.get(`objects/${objectType}/${id}`),
        getObjects: (
            filter = {},
            clientCursor,
            { requestType, dealType, objectType },
        ) => {
            const filterForRequest = { ...filter };
            const aggregationData = { ...(filterForRequest.aggregationData || {}) };
            delete filterForRequest.aggregationData;

            const fieldsForSubfilter = Object.keys(aggregationData)
                .filter((field) => !['priceDelta', 'totalAreaDelta'].includes(field));
            if (fieldsForSubfilter.length) {
                return getListWithGroupingAggregation(
                    request,
                    requestType,
                    dealType,
                    objectType,
                    clientCursor,
                    filterForRequest,
                    aggregationData,
                );
            }

            const cursor = getCursor(clientCursor);
            return request.post(`/objects/${requestType}/${dealType}/${objectType}${cursor ? `?${cursor}` : ''}`, filterForRequest);
        },
        setPhones: (id, data, { objectType, requestType }) => (
            request.put(`objects/${objectType}/${id}/quick-update?${createQuery({ requestType })}`, data)
        ),
        setStatus: (id, data, { objectType, requestType }) => (
            request.post(`objects/${objectType}/${id}/status?${createQuery({ requestType })}`, data)
        ),
        /**
         * Объединить несколько записей в одну
         * @param {string} id Идентификатор искомой записи
         * @param {Object} data Данные для запроса
         * @param {Array<string>} data.sources Список идентификаторов для объединения с искомым
         * @param {boolean} data.trashSources
         * @param {Object} data.phone Информация о телефоне
         * @param {Object} data.status Информация о статусе
         */
        merge: (id, data, { objectType }) => (
            request.post(`objects/${objectType}/merge/${id}`, data)
        ),
        getRelatedGroups: (id, phone) => {
            const ctx = createQuery({ excludeId: id, phone });
            return request.get(`objects/related?${ctx}`);
        },
        getRelatedObjects: (id, phone, clientCursor, { objectType, requestType }) => {
            const ctx = createQuery({ excludeId: id, phone, requestType });
            const cursor = getCursor(clientCursor);
            return request.get(`objects/${objectType}/related?${ctx}${cursor ? `&${cursor}` : ''}`);
        },
        getSimilarObjects: (id, page, { objectType, sortFilter, requestType }) => {
            const ctx = createQuery({ page, requestType, ...sortFilter });
            return request.get(`objects/${objectType}/${id}/similar?${ctx}`);
        },
        deleteAd: (id, adId, { objectType }) => (
            request.delete(`objects/${objectType}/${id}/ads/${adId}`)
        ),
    };
};

export default createObjectService;
