/* global document, window */
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import 'mobx-react/batchingForReactDom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Rollbar from 'rollbar';
import ReactTooltip from './lib/react-tooltip';
import { combineRoutes, renderRoutes } from './routes/utils';
import routes from './routes/client';
import * as serviceWorker from './serviceWorker';
import App from './containers/client/App';
import { PAGE_404 } from './constants/client';
import { ROLLBAR_ENVIRONMENT, ROLLBAR_TOKEN, ROLLBAR_CODE_VERSION } from './config/client';

import './styles/index.scss';

const combinedRoutes = combineRoutes(routes);

const content = renderRoutes(combinedRoutes, '', [], PAGE_404);

const rollbarConfig = ROLLBAR_TOKEN && {
    accessToken: ROLLBAR_TOKEN,
    environment: ROLLBAR_ENVIRONMENT,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: ROLLBAR_ENVIRONMENT,
        client: {
            javascript: {
                code_version: ROLLBAR_CODE_VERSION,
            },
        },
    },
    server: {
        root: window.location.origin,
        branch: 'main',
    },
    code_version: ROLLBAR_CODE_VERSION,
};

const rollbar = new Rollbar(rollbarConfig);
window.rollbar = rollbar;

ReactDOM.render(
    <React.StrictMode>
        <Provider instance={rollbar}>
            <ErrorBoundary>
                <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <App>
                            {content}
                        </App>
                    </QueryParamProvider>
                </Router>
                <ReactTooltip bodyMode isCapture={false} />
            </ErrorBoundary>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
