import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStores from '../../../hooks/client/use-stores';
import ObjectListPage from '../../../stores/shared/ObjectListPage';
import PresetList from '../../../stores/client/PresetList';
import storesContext from '../../../contexts/client';

const ObjectListWrapper = ({ children, ...props }) => {
    const { routes: [{ extra: { requestType } }] } = props;
    const [stores, setStores] = useState(useStores());
    useEffect(() => {
        const presetListStore = requestType === 'base' ? new PresetList() : null;
        const objectListPageStore = new ObjectListPage();

        setStores({
            ...stores,
            presetListStore,
            objectListPageStore,
        });
    }, []);
    return (
        <storesContext.Provider value={stores}>
            {children}
        </storesContext.Provider>
    );
};

ObjectListWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]).isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape),
};

ObjectListWrapper.defaultProps = {
    routes: [],
};

export default ObjectListWrapper;
