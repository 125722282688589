import React from 'react';
import AsyncSelect from 'react-select/async';
import DropdownSelect from './DropdownSelect';

const DropdownAsyncSelect = (props) => (
    <DropdownSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        Component={AsyncSelect}
    />
);

export default DropdownAsyncSelect;
