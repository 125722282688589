/* eslint linebreak-style: 0 */

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import ReactModal from 'react-modal';
import { ItemStatusWithContacts } from '../ItemStatus';
import './itemStatusWithContactsModal.scss';

const styleOverrides = {
    overlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        bottom: 'unset',
        overflow: 'visible',
        padding: 0,
        border: 'none',
        borderRadius: 0,
        position: 'static',
        background: 'none',
        pointerEvents: 'none',
    },
};

/**
 * Модальное окно для редактирования статуса и номеров телефона объекта
 * @param {Object} props
 * @param {Object} props.item объект недвижимости
 * @param {number} props.openKey ключ требования открытия попапа
 * @param {Object} props.useStores
 * @param {(isOpen: boolean) => void} props.onOpenChange обработчик смены состояния открытия окна
 */
const ItemStatusWithContactsModal = ({
    item,
    mode,
    openKey,
    useStores,
    onOpenChange,
}) => {
    const [isClosed, setIsClosed] = useState(false);

    const isOpen = useMemo(() => !!item && !isClosed, [item, isClosed]);

    useEffect(() => {
        setIsClosed(false);
    }, [item, openKey]);

    const close = () => {
        setIsClosed(true);
    };

    const onOpen = () => {
        onOpenChange(true);
    };

    const onClose = () => {
        onOpenChange(false);
    };

    return (
        <ReactModal
            style={styleOverrides}
            isOpen={isOpen}
            onAfterOpen={onOpen}
            onAfterClose={onClose}
            onRequestClose={close}
        >
            <Draggable handle=".modal-drag-handler">
                <div className="modal-content">
                    <div className="modal-drag-handler" />
                    <ItemStatusWithContacts
                        item={item}
                        mode={mode}
                        useStores={useStores}
                        onClose={close}
                        onSave={close}
                        showCloseButton
                    />
                </div>
            </Draggable>
        </ReactModal>
    );
};

ItemStatusWithContactsModal.propTypes = {
    item: PropTypes.shape({}),
    mode: PropTypes.string.isRequired,
    openKey: PropTypes.number,
    useStores: PropTypes.func.isRequired,
    onOpenChange: PropTypes.func,
};

ItemStatusWithContactsModal.defaultProps = {
    item: null,
    openKey: null,
    onOpenChange: () => {},
};

export default React.memo(ItemStatusWithContactsModal);
