/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../../components/Input';

import styles from './Table.module.scss';

const CellSelect = ({
    cell: { row: { original, original: { id, selected, idModifier } } },
}) => {
    const toggleRow = () => {
        if (typeof original.parent.setCursor === 'function') {
            original.parent.setCursor(original.id);
        }

        original.toggle();
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onClick={(e) => { e.stopPropagation(); }}
        >
            <Checkbox
                className={styles.table__highlight}
                id={`select-${id}-${idModifier}`}
                name={`select-${id}`}
                value
                checked={selected}
                onChange={toggleRow}
                size={Checkbox.SIZE.SMALL}
            />
        </div>
    );
};

CellSelect.propTypes = {
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                parent: PropTypes.shape({
                    setCursor: PropTypes.func,
                }),
                toggle: PropTypes.func,
                selected: PropTypes.bool,
                idModifier: PropTypes.string,
            }),
        }),
    }).isRequired,
};

export default CellSelect;
