import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, TabPanel } from 'react-tabs';
import { CustomTabList, CustomTab } from '../../../components/CustomTabs';
import { Form } from '../../../helpers/Form';
import Filter from '../Filter/Filter';
import { CollapseButton } from '../../../components/Button';
import NotepadList from '../NotepadList';
import PresetList from '../PresetList';
import NotepadListStore from '../../../stores/shared/NotepadList/NotepadList';
import PresetListStore from '../../../stores/shared/PresetList/PresetList';
import ObjectListFilterFooterWithPresets from './ObjectListFilterFooterWithPresets';
import ObjectListFilterHeader from './ObjectListFilterHeader';

import filterStyles from '../Filter/Filter.module.scss';
import styles from './ObjectList.module.scss';
import PopupTabsStore from '../../../stores/shared/PopupTabsStore';

const ObjectListFilter = ({
    tabsStore,
    showGroupingTab,
    filterForm,
    groupingForm,
    useStores,
    onCollapse,
    onNotepadOpen,
    onPresetAdd,
    onPresetSave,
    onPresetCancel,
    notepads,
    presets,
}) => {
    const [opened, setOpened] = useState(true);
    /** Признак необходимости рендера вкладок */
    const isTabs = Boolean(notepads || presets || showGroupingTab);
    const tabs = ['search'];
    const { objectListPageStore } = useStores();
    if (presets) {
        tabs.push('presets');
    }
    if (notepads) {
        tabs.push('notepads');
    }
    if (showGroupingTab && groupingForm) {
        tabs.push('groupings');
    }

    const handleCollapse = () => {
        const next = !opened;
        setOpened(next);
        onCollapse(!next);
    };
    const handleTabListSelect = (index) => {
        const tab = tabs[index];
        presets?.preload();
        objectListPageStore.setTab(index);
        switch (tab) {
            case 'presets':
                presets.reload();
                break;
            case 'notepads':
                notepads.load();
                break;
            default:
                break;
        }
    };
    const onPresetDelete = (id, deleteEvent) => {
        if (objectListPageStore.activePresetId === id) {
            filterForm.onReset(deleteEvent);
            if (filterForm.hasSnapshot('preset')) {
                filterForm.removeSnapshot('preset');
            }
            objectListPageStore.reset();
        }
    };
    return isTabs
        ? (
            <>
                <div className={filterStyles.filter__collapse}>
                    <CollapseButton
                        isOpen={opened}
                        size="sm"
                        color="white-gray"
                        onClick={handleCollapse}
                    />
                </div>
                <div className={`${styles.filter} ${opened ? styles['filter--opened'] : ''}`}>
                    <Observer>
                        {() => (
                            <Tabs
                                className={styles['filter-tabs']}
                                onSelect={handleTabListSelect}
                                defaultIndex={0}
                                selectedIndex={objectListPageStore.selectedFilterTab}
                            >
                                <CustomTabList className={styles['filter-tabs__list']}>
                                    <CustomTab
                                        activeClassName={styles['filter-tabs__tab--active']}
                                        className={styles['filter-tabs__tab']}
                                    >
                                        Поиск
                                    </CustomTab>
                                    {
                                        presets && (
                                            <CustomTab
                                                activeClassName={styles['filter-tabs__tab--active']}
                                                className={styles['filter-tabs__tab']}
                                            >
                                                Подборки
                                            </CustomTab>
                                        )
                                    }
                                    {
                                        notepads && (
                                            <CustomTab
                                                activeClassName={styles['filter-tabs__tab--active']}
                                                className={styles['filter-tabs__tab']}
                                            >
                                                Блокнот
                                            </CustomTab>
                                        )
                                    }
                                    {
                                        showGroupingTab && (
                                            <CustomTab
                                                activeClassName={styles['filter-tabs__tab--active']}
                                                className={styles['filter-tabs__tab']}
                                            >
                                                Группировка
                                            </CustomTab>
                                        )
                                    }
                                </CustomTabList>
                                <TabPanel
                                    className={styles['filter-tabs__panel']}
                                    selectedClassName={styles['filter-tabs__panel--active']}
                                >
                                    <Filter
                                        tabsStore={tabsStore}
                                        form={filterForm}
                                        useStores={useStores}
                                        withCollapse={false}
                                        components={{
                                            FilterHeader: () => (
                                                <ObjectListFilterHeader useStores={useStores} />
                                            ),
                                            FilterFooter: ({ ...props }) => (
                                                <ObjectListFilterFooterWithPresets
                                                    useStores={useStores}
                                                    onPresetAdd={onPresetAdd}
                                                    onPresetSave={onPresetSave}
                                                    onPresetCancel={onPresetCancel}
                                                    // eslint-disable-next-line max-len
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...props}
                                                />
                                            ),
                                        }}
                                    />
                                </TabPanel>
                                {
                                    presets && (
                                        <TabPanel
                                            className={styles['filter-tabs__panel']}
                                            selectedClassName={styles['filter-tabs__panel--active']}
                                        >
                                            <PresetList
                                                onPresetDelete={onPresetDelete}
                                                useStores={useStores}
                                                presets={presets}
                                            />
                                        </TabPanel>
                                    )
                                }
                                {
                                    notepads && (
                                        <TabPanel
                                            className={styles['filter-tabs__panel']}
                                            selectedClassName={styles['filter-tabs__panel--active']}
                                        >
                                            <NotepadList
                                                useStores={useStores}
                                                notepads={notepads}
                                                onNotepadOpen={onNotepadOpen}
                                            />
                                        </TabPanel>
                                    )
                                }
                                {
                                    showGroupingTab && (
                                        <TabPanel
                                            className={styles['filter-tabs__panel']}
                                            selectedClassName={styles['filter-tabs__panel--active']}
                                        >
                                            <Filter
                                                tabsStore={tabsStore}
                                                form={groupingForm}
                                                useStores={useStores}
                                                withCollapse={false}
                                                components={{
                                                    FilterHeader: () => (
                                                        <ObjectListFilterHeader
                                                            useStores={useStores}
                                                        />
                                                    ),
                                                    FilterFooter: ({ ...props }) => (
                                                        <ObjectListFilterFooterWithPresets
                                                            useStores={useStores}
                                                            onPresetAdd={onPresetAdd}
                                                            onPresetSave={onPresetSave}
                                                            onPresetCancel={onPresetCancel}
                                                            // eslint-disable-next-line max-len
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...props}
                                                        />
                                                    ),
                                                }}
                                            />
                                        </TabPanel>
                                    )
                                }
                            </Tabs>
                        )}
                    </Observer>
                </div>
            </>
        ) : (
            <Filter
                form={filterForm}
                useStores={useStores}
                title="Поиск"
                tabsStore={tabsStore}
                onCollapse={onCollapse}
            />
        );
};

ObjectListFilter.propTypes = {
    useStores: PropTypes.func.isRequired,
    onCollapse: PropTypes.func,
    filterForm: PropTypes.instanceOf(Form),
    groupingForm: PropTypes.instanceOf(Form),
    showGroupingTab: PropTypes.bool,
    notepads: PropTypes.instanceOf(NotepadListStore),
    presets: PropTypes.instanceOf(PresetListStore),
    onNotepadOpen: PropTypes.func,
    onPresetAdd: PropTypes.func,
    onPresetSave: PropTypes.func,
    onPresetCancel: PropTypes.func,
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
};

ObjectListFilter.defaultProps = {
    onCollapse: () => null,
    filterForm: {},
    groupingForm: {},
    showGroupingTab: false,
    notepads: null,
    presets: null,
    onNotepadOpen: () => null,
    onPresetAdd: () => null,
    onPresetSave: () => null,
    onPresetCancel: () => null,
};

export default ObjectListFilter;
