import Field from './DefaultField';
import isEmpty from '../../../utils/is-empty';

class Phone extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw) => {
        const { providedOutput } = this;
        let value;
        if (!isEmpty(raw)) {
            return `+${raw.replace(/[^0-9]*/g, '').replace(/^8/, '7')}`;
        }
        if (typeof providedOutput === 'function') {
            return providedOutput(value || '');
        }
        return value || '';
    }
}

export default Phone;
