import React from 'react';
import PropTypes from 'prop-types';
import { SIZE } from '../../constants/operator';

import styles from './Checkbox.module.scss';

const Checkbox = ({
    className,
    id,
    name,
    value,
    checked,
    disabled,
    size,
    children,
    onChange,
    readOnly,
}) => (
    <div
        className={`
            ${styles.checkbox}
            ${styles[`checkbox--${size}`]}
            ${children ? styles['checkbox--children'] : ''}
            ${className}
        `}
    >
        <input
            className={`
                ${styles.checkbox__input}
                ${readOnly ? styles['checkbox__input--readonly'] : ''}
                ${checked ? styles['checkbox__input--checked'] : ''}
            `}
            id={id}
            type="checkbox"
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={readOnly ? () => false : onChange}
        />
        <label htmlFor={id} className={styles.checkbox__label}>
            {children || '\u0000'}
        </label>
    </div>
);

Checkbox.SIZE = { ...SIZE };

Checkbox.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
    ]).isRequired,
    checked: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(Checkbox.SIZE)),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
    readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
    id: undefined,
    className: '',
    size: Checkbox.SIZE.MEDUIM,
    checked: undefined,
    disabled: false,
    onChange: null,
    children: null,
    readOnly: false,
};

export default Checkbox;
