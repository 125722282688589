import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import styles from './ItemStatus.module.scss';
import FormElements from '../Form';
import ItemStatusField from './ItemStatusField';
import { Button } from '../../../components/Button';

const ItemStatus = ({ form, useStores }) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div role="tooltip" onClick={(e) => e.stopPropagation()} onKeyPress={() => {}}>
        <form onSubmit={form.onSubmit} className={styles['item-status']}>
            <div className={styles['item-status__field-list']}>
                <FormElements
                    useStores={useStores}
                    form={form}
                    elements={form.render}
                    components={{
                        FieldWrapper: ItemStatusField,
                    }}
                />
            </div>
            <div className={styles['item-status__submit']}>
                <Observer>
                    {() => (
                        <Button
                            type={Button.TYPE.SUBMIT}
                            size={Button.SIZE.SMALL}
                            color={Button.COLOR.BLUE}
                            disabled={form.loading}
                        >
                            Сменить статус
                        </Button>
                    )}
                </Observer>
            </div>
        </form>
    </div>
);

ItemStatus.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func,
        render: PropTypes.arrayOf(PropTypes.shape({})),
        loading: PropTypes.bool,
    }).isRequired,
    useStores: PropTypes.func.isRequired,
};

export default ItemStatus;
