/* global alert */
import {
    action,
    computed,
    observable,
    runInAction,
} from 'mobx';
import DataSource from '../DataSource';
import { ListWithDataSource } from '../List';
import NotepadItem from './item';
import { notepad as form } from '../../../forms/shared';
import createForm from '../../../helpers/Form';
import { NOTEPAD_MESSAGES } from '../../../constants/shared';

class NotepadList extends ListWithDataSource {
    @observable loaded = false;

    @observable itemForm;

    @observable editItem;

    @observable $message;

    constructor(service) {
        super(
            NotepadItem,
            new DataSource(service),
        );
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get message() {
        const { $message } = this;
        return NOTEPAD_MESSAGES[$message] || $message;
    }

    getList() {
        return this.dataSource.getNotepads();
    }

    load(force = false) {
        if (this.loaded && !force) {
            return Promise.resolve();
        }
        return super.load()
            .then((list) => {
                this.setList(list);
                runInAction(() => {
                    this.loaded = true;
                });
                return Promise.resolve();
            })
            .catch((e) => alert(e.message));
    }

    reload() {
        return this.load(true);
    }

    remove(id) {
        const {
            dataSource,
        } = this;
        return dataSource.remove(id)
            .then(() => this.reload())
            .catch((e) => alert(e.message));
    }

    create(name) {
        const {
            dataSource,
        } = this;
        return dataSource.create(name)
            .then(() => this.reload())
            .catch((e) => alert(e.message));
    }

    update(id, name) {
        const {
            dataSource,
        } = this;
        return dataSource.update(id, name)
            .catch((e) => alert(e.message));
    }

    @action
    createItemForm() {
        const {
            itemForm,
        } = this;
        if (itemForm) {
            return itemForm;
        }
        this.itemForm = createForm(form);
        this.initItemFormListeners();
        return this.itemForm;
    }

    initItemFormListeners() {
        const { itemForm } = this;
        if (!itemForm) {
            return;
        }
        itemForm.on('success', ({ name }) => (
            this.create(name)
        ));
    }

    @action
    setEditItem({ id }) {
        this.editItem = this.getItem(id);
    }

    @action
    addObjectsToNotepad(id, objects, objectType) {
        const {
            dataSource,
        } = this;
        return dataSource.addObjects(id, objects, { objectType })
            .then(({ message }) => {
                this.$message = message;
                return this.reload();
            })
            .catch((e) => alert(e.message));
    }

    @action
    removeObjectsFromNotepad(id, objects, objectType) {
        const {
            dataSource,
        } = this;
        return dataSource.removeObjects(id, objects, { objectType })
            .then(({ message }) => {
                this.$message = message;
                return this.reload();
            })
            .catch((e) => alert(e.message));
    }
}

export default NotepadList;
