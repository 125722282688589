import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Select, DropdownSelect } from '../../../components/Select';
import { Button } from '../../../components/Button';

import styles from './Directory.module.scss';

const DirectorySelect = ({
    useStores,
    directory,
    className,
    menuIsOpen,
    isDropdown,
    withSelectAll,
    preload,
    form,
    onChange,
    ...props
}) => {
    const { directoriesStore } = useStores();
    const store = directoriesStore[directory];
    // eslint-disable-next-line react/prop-types
    const { value, ctx } = props;
    // interactions
    const handleOpen = () => {
        store.loadData(ctx);
    };
    // effects
    useEffect(() => {
        if (menuIsOpen || preload) {
            store.loadData(ctx)
                .then((options) => {
                    if (preload) {
                        form.emit(`${directory}:preloaded`, options);
                    }
                });
        }
    }, []);
    const Component = isDropdown
        ? DropdownSelect
        : Select;

    return (
        <Observer>
            {() => {
                const options = store.getOptions
                    ? store.getOptions(ctx)
                    : store.options;
                const selectAll = () => {
                    onChange(options, { action: 'change' });
                };
                const reset = () => {
                    onChange([], { action: 'change' });
                };
                return (
                    <Component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        className={`
                            ${styles['directory-select']}
                            ${store.options.length || value ? '' : styles['directory-select__empty']}
                            ${className}
                        `}
                        options={options}
                        loading={store.loading}
                        onMenuOpen={handleOpen}
                        loadingMessage={() => 'Загрузка...'}
                        noOptionsMessage={() => 'Справочник пуст'}
                        menuIsOpen={menuIsOpen}
                        value={value}
                        onChange={onChange}
                        footer={
                            withSelectAll
                                ? (
                                    <div className={styles['directory-select__footer']}>
                                        <Button
                                            color={Button.COLOR.WHITE_BLACK}
                                            className={styles['directory-select__action']}
                                            size={Button.SIZE.MEDIUM}
                                            disabled={options.length === value.length}
                                            block
                                            onClick={selectAll}
                                        >
                                            Выбрать все
                                        </Button>
                                        <Button
                                            color={Button.COLOR.WHITE_BLACK}
                                            className={styles['directory-select__action']}
                                            size={Button.SIZE.MEDIUM}
                                            disabled={value.length === 0}
                                            block
                                            onClick={reset}
                                        >
                                            Отменить все
                                        </Button>
                                    </div>
                                )
                                : null
                        }
                    />
                );
            }}
        </Observer>
    );
};

DirectorySelect.propTypes = {
    useStores: PropTypes.func.isRequired,
    directory: PropTypes.string.isRequired,
    className: PropTypes.string,
    menuIsOpen: PropTypes.bool,
    isDropdown: PropTypes.bool,
    preload: PropTypes.bool,
    withSelectAll: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    form: PropTypes.shape({
        emit: PropTypes.func,
    }),
};

DirectorySelect.defaultProps = {
    className: '',
    menuIsOpen: undefined,
    isDropdown: false,
    preload: false,
    withSelectAll: false,
    value: [],
    onChange: () => null,
    form: {
        emit: () => null,
    },
};

export default DirectorySelect;
