/* global window, document */

const MIME_TYPES = {
    'image/bmp': '.bmp',
    'image/gif': '.gif',
    'image/jpeg': '.jpeg',
    'image/svg+xml': '.svg',
    'image/tiff': '.tiff',
    'image/vnd.microsoft.icon': '.ico',
    'image/x-icon': '.ico',
    'image/png': '.png',
    'image/webp': '.webp',
};

export default (location, name) => {
    if (!location) {
        return;
    }
    // eslint-disable-next-line no-undef
    fetch(location, {
        headers: new Headers({
            Origin: window.location.origin,
        }),
        mode: 'cors',
    })
        .then((response) => response.blob())
        .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const temporaryDownloadLink = document.createElement('a');
            temporaryDownloadLink.style.display = 'none';
            temporaryDownloadLink.setAttribute('target', '_blank');
            temporaryDownloadLink.setAttribute('href', blobUrl);
            temporaryDownloadLink.setAttribute('download', name + (MIME_TYPES[blob.type] || ''));
            document.body.appendChild(temporaryDownloadLink);
            temporaryDownloadLink.click();
            document.body.removeChild(temporaryDownloadLink);
        });
};
