import React from 'react';
import PropTypes from 'prop-types';
import { SubMenu } from 'react-contextmenu';
import SVGIcon from '../Icons/SVGIcon';

import styles from './ContextMenu.module.scss';

const ModifiedSubMenu = ({
    className,
    icon,
    title,
    disabled,
    itemClassName,
    ...props
}) => (
    <SubMenu
        className={`${styles['context-sub-menu']} ${className}`}
        disabled={disabled}
        title={(
            <div
                className={`
                    ${styles['context-menu-item']}
                    ${styles['context-menu-item--parent']}
                    ${disabled ? styles['context-menu-item--disabled'] : ''}
                    ${itemClassName}
                `}
            >
                {
                    icon
                        ? (
                            <span className={styles['context-menu-item__icon']}>
                                <SVGIcon
                                    SVGElement={icon}
                                    height={16}
                                />
                            </span>
                        ) : null
                }
                <span className={styles['context-menu-item__value']}>
                    {title}
                </span>
            </div>
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    />
);

ModifiedSubMenu.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.func,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    disabled: PropTypes.bool,
};

ModifiedSubMenu.defaultProps = {
    title: '',
    icon: null,
    className: '',
    itemClassName: '',
    disabled: false,
};

export default ModifiedSubMenu;
