import React from 'react';
import { SelectedRow, SelectedRowWithHint } from '../../../components/TableSelected';
import { defaultProps, propTypes } from './propTypes';

import styles from './SelectedObject.module.scss';

const SelectedCommercial = ({
    store,
}) => (
    <>
        <SelectedRowWithHint name="Ком. услуги" classNames={{ row: styles.selected__row }}>
            {store.rentService?.label}
        </SelectedRowWithHint>
        <SelectedRowWithHint name="Отопление" classNames={{ row: styles.selected__row }}>
            {store.heating?.label}
        </SelectedRowWithHint>
        <SelectedRow name="Охрана" classNames={{ row: styles.selected__row }}>
            {store.hasSecurity ? 'Да' : 'Нет'}
        </SelectedRow>
        <SelectedRowWithHint name="Мебель" classNames={{ row: styles.selected__row }}>
            {store.rent?.furnishing?.name}
        </SelectedRowWithHint>
    </>
);

SelectedCommercial.propTypes = { store: propTypes.store };
SelectedCommercial.defaultProps = { store: defaultProps.store };

export default SelectedCommercial;
