const createActivationKeyService = (request) => ({
    getAll: (filter = {}, page) => (
        request.post(`/activation-keys/all${page ? `?page=${page}` : ''}`, filter)
    ),
    setStatus: (id, status) => (
        request.put(`/activation-keys/${id}/status`, { status: status.toLowerCase() })
    ),
    createKey: (key) => (
        request.post('/activation-keys', key)
    ),
});

export default createActivationKeyService;
