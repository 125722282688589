class Token {
    #expiresAt = 0;

    #value = '';

    set value(token) {
        const { expiresIn, value } = token;
        if (!(token instanceof Object)) throw new Error('Token must be an object');
        if (!expiresIn) throw new Error('Expires in must be specified');
        if (!value || typeof value !== 'string') throw new Error(`Invalid token value ${value}`);
        this.#expiresAt = Date.now() + expiresIn * 10 ** 3;
        this.#value = value;
    }

    get value() {
        const expiresAt = this.#expiresAt;
        if (!expiresAt || Date.now() >= expiresAt) return '';
        return this.#value;
    }

    get expiresAt() {
        return this.#expiresAt;
    }

    reset() {
        this.#expiresAt = 0;
        this.#value = '';
    }
}

export default Token;
