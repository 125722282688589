import {
    action,
    computed,
    observable,
    runInAction,
} from 'mobx';
import { EventEmitter } from '../../../../helpers/EventEmitter';
import DataSource, { STATES } from '../../DataSource';
import List from '../../List';
import { arrayNaturalSort } from '../../../../utils/sortUtils/naturalSorting';

class Directory extends EventEmitter {
    @observable items = new List();

    @observable default;

    @observable loaded = false;

    sortOptions;

    /**
     *
     * @param {string} directory наименование директории
     * @param {any} service сервис поиска
     * @param {*} state
     * @param {object} sortOptions опции сортировки справочника
     * @param {boolean} sortOptions.applySort признак применения сортировки
        натуральной сортировки к результату. `true` - натуральная сортировка применяется,
        иначе - обычная сортировка
     * @param {boolean} sortOptions.sortFieldName - наименование поля в объекте для сортировки
     */
    constructor(directory, service, state = STATES.DEFAULT, sortOptions = { applySort: true, sortFieldName: 'label' }) {
        super();
        this.directory = directory;
        this.dataSource = new DataSource(service, state);
        this.sortOptions = sortOptions;
        if (state === STATES.INITIALLY_LOADING) {
            this.loadData();
        }
    }

    @computed
    get loading() {
        return this.dataSource.loading;
    }

    @computed
    get options() {
        return this.items.$list && this.items.$list.length
            ? this.items.$list.map((item) => item.data)
            : [];
    }

    set options(values) {
        this.items.setList(values);
    }

    @action
    setDefault(defaultValue) {
        this.default = defaultValue;
    }

    loadData() {
        if (this.loaded || this.loading) {
            return Promise.resolve(this.options);
        }
        const { directory } = this;
        return this.dataSource.getList(directory)
            .then((list = []) => {
                runInAction(() => {
                    this.loaded = true;
                });

                let listToAdd = [...list];
                if (this.sortOptions?.applySort) {
                    listToAdd = arrayNaturalSort(
                        listToAdd,
                        this.sortOptions.sortFieldName,
                    );
                }

                this.items.addList(listToAdd);
                return Promise.resolve(this.options);
            })
            .catch(() => {});
    }
}

export default Directory;
