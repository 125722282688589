import React from 'react';
import PropTypes from 'prop-types';
import { SIZE } from '../../constants/operator';

import styles from './Tag.module.scss';

const Tag = ({
    className,
    id,
    name,
    value,
    checked,
    disabled,
    size,
    type,
    children,
    onChange,
}) => (
    <div
        className={`
            ${styles.tag}
            ${styles[`tag--${size}`]}
            ${className}
        `}
    >
        <input
            className={`${styles.tag__input} ${checked ? styles['tag__input--checked'] : ''}`}
            id={id}
            type={type}
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
        />
        <label htmlFor={id} className={styles.tag__label}>
            {children}
        </label>
    </div>
);

Tag.SIZE = { ...SIZE };

Tag.TYPE = {
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
};

Tag.propTypes = {
    className: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    checked: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(Tag.SIZE)),
    type: PropTypes.oneOf(Object.values(Tag.TYPE)),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.string.isRequired,
};

Tag.defaultProps = {
    id: undefined,
    className: '',
    size: Tag.SIZE.MEDUIM,
    type: Tag.TYPE.CHECKBOX,
    checked: undefined,
    disabled: false,
    onChange: null,
};

export default Tag;
