import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import ObjectComponentContent from './ObjectComponentContent';
import ObjectComponentPhotos from './ObjectComponentPhotos';
import ObjectComponentHistory from './ObjectComponentHistory';
import ObjectComponentMap from './ObjectComponentMap';
import ObjectComponentSources from './ObjectComponentSources';
import ObjectComponentCompany from './ObjectComponentCompany';
import ObjectDetailed from '../../../stores/shared/RefactoredObjectDetailed/ObjectDetailed';
import ObjectImagesDialog from '../ObjectImagesDialog/ObjectImagesDialog';
import { Button } from '../../../components/Button';
import ObjectComponentApprove from './ObjectComponentApprove';
import { ExternalLinkSimpleIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { TAB_TYPE } from '../../../constants/shared';

import styles from './ObjectComponent.module.scss';
import DetailedView from '../DetailedView';
import PopupTabsStore from '../../../stores/shared/PopupTabsStore';

const LOCATION_CONTENT_INDEX = 0;

const ObjectComponent = ({
    useStores,
    id,
    onObjectClose,
    onClose,
    store,
    tabsStore,
    title,
    view,
}) => {
    const { form } = store;
    const { dialogStore } = useStores();
    const toggleApprovingState = (action) => {
        tabsStore.toggleApproveTab(id, action);
    };
    const refSaveAllPhotos = useRef(null);
    // FIXME make it better
    const handleChangeActive = () => {};
    const handlePhotoAdd = (file) => {
        if (store.id) {
            store.uploadNewPhoto(file);
        } else {
            store.addNewPhoto(file);
        }
    };
    const handlePhotoDelete = (imageId) => {
        store.removePhoto(imageId);
    };
    const handlePhotoClick = (index) => {
        dialogStore.show(
            'editObjectImageSlider',
            { initSlide: index },
        );
    };
    const handleSimilarClick = () => {
        tabsStore.addTab(
            {
                similar: store.similar,
                tabs: tabsStore,
            },
            `similar-${id}`,
            store.objectName,
            TAB_TYPE.SIMILAR_OBJECTS,
        );
    };
    const handleCheckClick = () => {
        store.checkObject()
            .then(() => {
                onClose();
            });
    };
    const handleSourceDelete = (adId) => {
        if (typeof store.deleteAd === 'function') {
            return store.deleteAd(adId);
        }
        return Promise.resolve(null);
    };
    const handleDeleteClick = () => {
        tabsStore.toggleApproveTab(id, 'delete');
    };
    const handleUserNoteUpdateClick = (e) => {
        e.preventDefault();
        store.updateIcorrectOptions().then(() => {
            store.updateUserNote()
                .then(() => onClose());
        });
    };
    const handleSaveApprove = (e) => {
        form.onSubmit(e);
        form.on('error', () => toggleApprovingState());
    };
    const handleDeleteApprove = () => {
        store.deleteObject()
            .then(() => {
                onClose();
            });
    };
    const handleApprove = (e) => {
        const tab = tabsStore.getTab(id);
        if (tab.action) {
            return handleDeleteApprove(e);
        }
        return handleSaveApprove(e);
    };

    const handleCancel = () => {
        const tab = tabsStore.getTab(id);
        if (tab.action) {
            return tabsStore.toggleApproveTab(id);
        }
        return onClose();
    };

    const createContentComponent = (name) => {
        if (!['photos', 'map', 'history', 'sources', 'similar', 'company'].includes(name)) {
            return null;
        }
        // eslint-disable-next-line react/prop-types
        return ({ setActiveIndex }) => (
            <Observer key={`${name}-${id}`}>
                {() => {
                    switch (name) {
                        case 'photos':
                            return (
                                <ObjectComponentPhotos
                                    photos={store.images}
                                    onImageUpload={view ? null : handlePhotoAdd}
                                    onImageDelete={view ? null : handlePhotoDelete}
                                    onImageClick={handlePhotoClick}
                                    navigateToLocationTab={() => (
                                        setActiveIndex(LOCATION_CONTENT_INDEX)
                                    )}
                                />
                            );
                        case 'map':
                            return (
                                <ObjectComponentMap
                                    useStores={useStores}
                                    form={form}
                                    view={store.loaded ? view : true}
                                />
                            );
                        case 'history':
                            return (
                                <ObjectComponentHistory history={store.history} />
                            );
                        case 'sources':
                            return (
                                <ObjectComponentSources
                                    sources={store.sources}
                                    view={view}
                                    extraAdsForm={!view && store.extraAdsForm}
                                    withActions={!view}
                                    onSourceDelete={handleSourceDelete}
                                />
                            );
                        case 'similar':
                            return <div />;
                        case 'company':
                            return (
                                <ObjectComponentCompany company={store.company} />
                            );
                        default:
                            return null;
                    }
                }}
            </Observer>
        );
    };
    const createTitleComponent = (name, { label, key }) => {
        switch (name) {
            case 'similar':
                return (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div onClick={handleSimilarClick} key={key}>
                        <Observer>
                            {() => (
                                <div
                                    className={`
                                        ${styles.similar}
                                        ${store.hasSimilarObjects ? styles['similar--visible'] : ''}
                                    `}
                                >
                                    {label}
                                    <SVGIcon
                                        height={8}
                                        className={styles.similar__icon}
                                        SVGElement={ExternalLinkSimpleIcon}
                                    />
                                    <div
                                        className={`
                                            ${styles.similar__marker}
                                            ${store.hasSimilarObjects ? styles['similar__marker--visible'] : ''}
                                        `}
                                    />
                                </div>
                            )}
                        </Observer>
                    </div>
                );
            case 'photos':
            case 'map':
            case 'history':
            case 'sources':
            case 'company':
                return <span key={key}>{label}</span>;
            default:
                return null;
        }
    };
    const ObjectComponentContentWithCancel = ({ children }) => (
        ObjectComponentContent(
            { children, onObjectClose, title },
        )
    );
    return (
        <Observer>
            {() => {
                const linkedTab = tabsStore.getTab(id);
                const { approveIsActive } = linkedTab;
                return (
                    <>
                        {
                            approveIsActive
                                ? (
                                    <ObjectComponentApprove
                                        onClose={onClose}
                                        toggleApprovingState={toggleApprovingState}
                                        onApprove={handleApprove}
                                        onCancel={handleCancel}
                                        disabled={store.loading || form.loading}
                                        action={tabsStore.getTab(id).action}
                                    />
                                )
                                : null
                        }
                        <DetailedView
                            id={id}
                            useStores={useStores}
                            store={store}
                            wrapper={ObjectComponentContentWithCancel}
                            handleChangeActive={handleChangeActive}
                            createContentComponent={createContentComponent}
                            createTitleComponent={createTitleComponent}
                        >
                            <div className={styles.footer}>
                                <div
                                    className={`
                                        ${styles.footer__col}
                                        ${styles['footer__col--text']}
                                        ${styles.footer__text}
                                    `}
                                >
                                    <div>id объекта</div>
                                    <div>{id}</div>
                                </div>
                                <div
                                    className={`${styles.footer__col} ${styles['footer__col--images']}`}
                                >
                                    {
                                        store.images && store.images.length
                                        && !store.isNew
                                            ? (
                                                <div
                                                    // FIXME make it better
                                                    ref={refSaveAllPhotos}
                                                    className={`
                                                        ${styles.active}
                                                        ${styles.footer__button}
                                                        ${styles['footer__button--save-photos']}
                                                    `}
                                                >
                                                    <Button
                                                        type={Button.TYPE.BUTTON}
                                                        size="md"
                                                        color={Button.COLOR.WHITE_BLUE}
                                                        onClick={
                                                            () => store.downloadAllImages()
                                                        }
                                                    >
                                                        Скачать все фото
                                                    </Button>
                                                </div>
                                            )
                                            : null
                                    }
                                </div>
                                <div className={`${styles.footer__col} ${styles['footer__col--actions']}`}>
                                    <div
                                        className={styles.footer__buttons}
                                    >
                                        {
                                            !store.isNew && store.canDelete
                                                ? (
                                                    <>
                                                        <Button
                                                            type={Button.TYPE.BUTTON}
                                                            size="md"
                                                            color={Button.COLOR.WHITE_RED}
                                                            className={styles.footer__button}
                                                            disabled={store.loading}
                                                            onClick={handleDeleteClick}
                                                        >
                                                            Удалить
                                                        </Button>
                                                        <Button
                                                            type={Button.TYPE.BUTTON}
                                                            size="md"
                                                            color={Button.COLOR.WHITE_BLACK}
                                                            className={styles.footer__button}
                                                            disabled={store.loading}
                                                            onClick={handleCheckClick}
                                                        >
                                                            Продлить
                                                        </Button>
                                                    </>
                                                )
                                                : (
                                                    <Button
                                                        type={Button.TYPE.BUTTON}
                                                        size="md"
                                                        color={Button.COLOR.WHITE_BLACK}
                                                        className={`${styles.footer__button} ${!form.snapshotIsDiffer && !store.extraAds?.length ? styles['footer__button--hidden'] : ''}`}
                                                        onClick={onClose}
                                                        disabled={form.loading}
                                                    >
                                                        {
                                                            store.isNew
                                                                ? 'Отменить'
                                                                : 'Отменить изменения'
                                                        }
                                                    </Button>
                                                )
                                        }
                                        <Button
                                            type={Button.TYPE.SUBMIT}
                                            size="md"
                                            color={Button.COLOR.WHITE_GREEN}
                                            className={`${styles.footer__button} ${!form.snapshotIsDiffer && !store.extraAds?.length ? styles['footer__button--hidden'] : ''}`}
                                            disabled={form.loading}
                                            onClick={
                                                !store.canEdit
                                                    ? handleUserNoteUpdateClick
                                                    : null
                                            }
                                        >
                                            {
                                                store.isNew
                                                    ? 'Добавить'
                                                    : 'Сохранить изменения'
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </DetailedView>
                        {
                            store.images && store.images.length
                                ? (
                                    <ObjectImagesDialog
                                        name="editObjectImageSlider"
                                        header={`Фото объекта ${store.objectName}`}
                                        sources={store.images}
                                        useStores={useStores}
                                        onImageDownload={(index) => store.downloadImage(index)}
                                    />
                                )
                                : null
                        }
                    </>
                );
            }}
        </Observer>
    );
};

ObjectComponent.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onObjectClose: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(ObjectDetailed).isRequired,
    tabsStore: PropTypes.instanceOf(PopupTabsStore).isRequired,
    title: PropTypes.string,
    useStores: PropTypes.func,
    view: PropTypes.bool,
};

ObjectComponent.defaultProps = {
    title: 'Редактирование объекта',
    useStores: () => ({ dialogStore: {} }),
    view: false,
};

export default ObjectComponent;
