export default [{
    name: 'name',
    type: 'text',
    label: 'Наименование',
    rules: 'required|string',
    size: 'lg',
}, {
    name: 'address',
    type: 'text',
    label: 'Адрес',
    rules: 'required|string',
    size: 'lg',
}, {
    name: 'tin',
    type: 'text',
    label: 'ИНН',
    rules: 'required|string|min:10|max:12',
    size: 'lg',
}, {
    name: 'comment',
    type: 'textarea',
    label: 'Комментарий',
    rules: 'required|string|min:10',
    size: 'lg',
    rows: 3,
}];
