import SearchableDirectory from './SearchableDirectory';

class AreaDirectory extends SearchableDirectory {
    minQueryLength = 3;

    constructor(directory, service) {
        super(directory, service);
        this.message = 'AREA_QUERY_IS_EMPTY';
    }

    loadData(query = '', ctx = {}) {
        if (!query || query.length < this.minQueryLength) {
            this.message = 'AREA_QUERY_IS_EMPTY';
            return Promise.reject();
        }
        return super.loadData(query, ctx);
    }
}

export default AreaDirectory;
