import { computed } from 'mobx';
import { format } from 'date-fns';
import { ListItem } from '../../List';
import {
    EMPTY_DATA_STRING,
    DEFAULT_DATE_FORMAT,
    PRICE_DELIMITER,
    PRICE_FLOAT_CHARS,
    DEAL_TYPE_NAME,
    SIMILARITY_TEXT,
    OBJECT_HIGHLIHT_TEXT,
} from '../../../../constants/shared';

class BaseItem extends ListItem {
    @computed
    get groupKey() {
        const { $groupKey } = this;
        return $groupKey;
    }

    @computed
    get objectName() {
        const { phone } = this;
        return phone;
    }

    @computed
    get objectStatusName() {
        const { status } = this;
        if (!status) {
            return null;
        }
        return status.name;
    }

    @computed
    get objectStatusId() {
        const { status } = this;
        if (!status) {
            return null;
        }
        return status.id;
    }

    @computed
    get objectType() {
        const { ownerType, isExclusive } = this;
        return isExclusive
            ? {
                value: ownerType.id === 2 ? 100 : ownerType.id,
                text: 'Эксклюзив',
            } : {
                value: ownerType.id,
                text: ownerType.name,
            };
    }

    @computed
    get dealTypeName() {
        const { dealType } = this;
        return DEAL_TYPE_NAME[dealType];
    }

    @computed
    get shortDistrictName() {
        const { cityDistrict } = this;
        if (cityDistrict) {
            return cityDistrict.slice(0, 3).toUpperCase();
        }
        return cityDistrict;
    }

    @computed
    get districtName() {
        const { cityDistrict } = this;
        return cityDistrict;
    }

    @computed
    get districtLandmarkName() {
        const { cityDistrictLandmark } = this;
        return cityDistrictLandmark;
    }

    @computed
    get streetName() {
        let { street } = this;
        street = street || EMPTY_DATA_STRING;
        const { suburbOfCityId: $val, city } = this;
        return $val
            ? `${city}\n${street}`
            : street;
    }

    @computed
    get complexName() {
        const { complex } = this;
        return complex;
    }

    @computed
    get streetNameWithComplexName() {
        const { streetName, complex } = this;
        return [streetName || '-', complex || '-'].join('/');
    }

    @computed
    get houseNumber() {
        const { house } = this;
        return house;
    }

    @computed
    get contactPhone() {
        const { phones } = this;
        if (!phones || !phones.length) {
            return null;
        }
        return phones[0].phone;
    }

    @computed
    get created() {
        const { $createdAt } = this;
        return format(new Date($createdAt), DEFAULT_DATE_FORMAT);
    }

    @computed
    get added() {
        const { $addedAt } = this;
        return format(new Date($addedAt), DEFAULT_DATE_FORMAT);
    }

    @computed
    get priceDelimited() {
        const { $price } = this;
        const priceFloatDelimiter = 10 ** PRICE_FLOAT_CHARS;
        const priceDelimiter = PRICE_DELIMITER / priceFloatDelimiter;
        return Math.round($price / priceDelimiter) / priceFloatDelimiter;
    }

    @computed
    get unitPriceDelimited() {
        const { $unitPrice } = this;
        const priceFloatDelimiter = 10 ** PRICE_FLOAT_CHARS;
        const priceDelimiter = PRICE_DELIMITER / priceFloatDelimiter;
        return Math.round($unitPrice / priceDelimiter) / priceFloatDelimiter;
    }

    @computed
    get square() {
        const { totalArea } = this;
        return totalArea;
    }

    @computed
    get images() {
        const { $images } = this;
        if (!$images || !$images.length) {
            return [];
        }
        return $images.map((image) => ({
            src: image,
        })).filter(({ src }) => Boolean(src));
    }

    @computed
    get source() {
        const { ad } = this;
        if (!ad || !ad.length) return null;
        if (!ad[0].board) return null;
        return ad[0].board.name;
    }

    @computed
    get similarityValue() {
        const { $similarityRate } = this;
        return $similarityRate
            ? { value: $similarityRate, text: SIMILARITY_TEXT[$similarityRate] }
            : null;
    }

    @computed
    get similarityProperties() {
        const { $similarProperties } = this;
        return $similarProperties && $similarProperties.length
            ? $similarProperties
            : [];
    }

    @computed
    get highlight() {
        const { $highlight } = this;
        if (!$highlight) {
            return null;
        }
        const { bgColor, bgColorHover } = $highlight;
        return { bgColor, bgColorHover };
    }

    @computed
    get highlightValue() {
        const { $highlight } = this;
        if (!$highlight) {
            return null;
        }
        const { id } = $highlight;
        return OBJECT_HIGHLIHT_TEXT[id] !== undefined
            ? OBJECT_HIGHLIHT_TEXT[id]
            : id;
    }

    @computed
    get isExclusive() {
        const { company } = this;
        return Boolean(company);
    }

    @computed
    get company() {
        const { $company } = this;
        return $company;
    }
}

export default BaseItem;
