import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import { Button } from '../../../components/Button';

import styles from './Dialog.module.scss';

const MessageDialog = ({
    children,
    useStores,
    name,
    ...props
}) => {
    const { dialogStore } = useStores();
    const handleClose = () => dialogStore.hide();
    return (
        <Dialog
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            useStores={useStores}
            name={name}
            size="sm"
            className={styles.approve}
            footer={(
                <div className={styles.approve__footer}>
                    <Button
                        size={Button.SIZE.LARGE}
                        color={Button.COLOR.BLUE}
                        block
                        onClick={handleClose}
                    >
                        Закрыть
                    </Button>
                </div>
            )}
        >
            <div className={styles.approve__message}>
                {children}
            </div>
        </Dialog>
    );
};

MessageDialog.propTypes = {
    useStores: PropTypes.func,
    ...Dialog.propTypes,
};

MessageDialog.defaultProps = {
    useStores: () => null,
    ...Dialog.defaultProps,
};

export default MessageDialog;
