/* eslint linebreak-style: 0 */
import React, { useMemo, useRef } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

/**
 * Пункт выпадающего меню с тултипом при нехватке ширины для отображения текста
 * @param {string} tooltipFieldName наименование поля для получения значения для тултипа
 * @returns {React.Component} компонент для элемента списка выпадающего меню
 */
const OptionWithTooltip = ({ tooltipFieldName = 'label', ...props }) => {
    const ref = useRef();

    const title = useMemo(() => (ref.current && ref.current.scrollWidth > ref.current.clientWidth
        ? props[tooltipFieldName] ?? ''
        : ''),
    [
        props[tooltipFieldName],
        ref.current?.scrollWidth,
        ref.current?.clientWidth,
    ]);

    return (
        <div title={title}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <components.Option {...props} innerRef={ref} />
        </div>
    );
};

export default OptionWithTooltip;

OptionWithTooltip.propTypes = {
    tooltipFieldName: PropTypes.string,
    innerRef: PropTypes.shape({}),
};

OptionWithTooltip.defaultProps = {
    tooltipFieldName: 'label',
    innerRef: undefined,
};
