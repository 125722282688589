import React from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { EditIcon } from '../../../components/Icons/icons';
import { POSITION } from '../../../constants/shared';

import styles from './PresetList.module.scss';
import PresetListItemConfirmNew from './PresetListItemConfirmNew';

const PresetListItem = ({
    item,
    isActive,
    onOpenClick,
    onEditClick,
    onCloseNewClick,
    onNewOpenClick,
}) => (
    <Observer>
        {() => (
            <div
                className={`
                    ${styles.list__item}
                    ${styles.preset}
                    ${item.new ? styles['preset--with-new'] : ''}
                    ${isActive ? styles['preset--active'] : ''}
                `}
            >
                <div className={styles.list__row}>
                    <button
                        type="button"
                        className={`
                            ${styles.preset__trigger}
                            ${styles['preset__trigger--top']}

                        `}
                        onClick={onOpenClick}
                    >
                        <span
                            className={styles.preset__count}
                        >
                            {item.count}
                        </span>
                        <span
                            className={styles.preset__title}
                        >
                            {item.name}
                        </span>
                        <span
                            aria-hidden="true"
                            className={styles.preset__edit}
                            onClick={onEditClick}
                            data-tip="Редактировать название"
                            data-place={POSITION.RIGHT}
                            data-class={styles.preset__hint}
                        >
                            <SVGIcon
                                SVGElement={EditIcon}
                                height={14}
                            />
                        </span>
                    </button>
                </div>
                {
                    !!item.new && (
                        <PresetListItemConfirmNew
                            item={item}
                            onCloseNewClick={onCloseNewClick}
                            onNewOpenClick={onNewOpenClick}
                        />
                    )
                }
            </div>
        )}
    </Observer>
);

PresetListItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        count: PropTypes.number,
        countedAt: PropTypes.string,
        checkedAt: PropTypes.string,
        compCountedAt: PropTypes.string,
        new: PropTypes.number,
    }),
    isActive: PropTypes.bool,
    onOpenClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onCloseNewClick: PropTypes.func,
    onNewOpenClick: PropTypes.func,
};

PresetListItem.defaultProps = {
    item: {},
    isActive: false,
    onOpenClick: () => null,
    onEditClick: () => null,
    onCloseNewClick: () => null,
    onNewOpenClick: () => null,
};

export default PresetListItem;
