import React from 'react';
import PropTypes from 'prop-types';

import styles from './Table.module.scss';
import { POSITION, PRICE_STATS } from '../../../constants/shared';

const CellPrice = ({
    value,
    cell: { row: { original: { id, priceDynamic } } },
    listId,
}) => {
    const handleClick = (event) => {
        event.stopPropagation();
    };

    const direction = (() => {
        if (!priceDynamic?.length) {
            return null;
        }

        let sybmol = null;
        const lastStat = priceDynamic[0];

        if (lastStat.diff > 0) {
            sybmol = (
                <span className={styles['table__cell-price-up']}>
                    ▲
                </span>
            );
        } else if (lastStat.diff < 0) {
            sybmol = (
                <span className={styles['table__cell-price-down']}>
                    ▼
                </span>
            );
        }

        return sybmol
            ? (
                <button
                    type="button"
                    onClick={handleClick}
                    data-tip={id}
                    data-for={`${PRICE_STATS}-${listId}`}
                    data-place={POSITION.BOTTOM}
                    data-class={`${styles.table__hint} ${styles.table__slider} ${styles.table__price}`}
                    data-effect="solid"
                    data-delay-show="500"
                >
                    {sybmol}
                </button>
            )
            : null;
    })();

    return (
        <div className={styles['table__cell-price']}>
            {value}
            {direction}
        </div>
    );
};

CellPrice.propTypes = {
    listId: PropTypes.string.isRequired,
    value: PropTypes.string,
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                priceDynamic: PropTypes.arrayOf(PropTypes.shape({
                    diff: PropTypes.number,
                })),
            }),
        }),
    }),
};

CellPrice.defaultProps = {
    value: undefined,
    cell: {
        row: {
            original: {
                id: undefined,
            },
        },
    },
};

export default CellPrice;
