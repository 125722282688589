import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../Icons/SVGIcon';
import { CrossIcon } from '../Icons/icons';

const CustomClearIndicator = ({ innerProps: { ref, ...restInnerProps } }) => (
    <div
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restInnerProps}
        className="select__clear-indicator"
    >
        <SVGIcon SVGElement={CrossIcon} height={10} />
    </div>
);

CustomClearIndicator.propTypes = {
    innerProps: PropTypes.shape({
        ref: PropTypes.shape({}),
    }).isRequired,
};

export default CustomClearIndicator;
