import React from 'react';
import PropTypes from 'prop-types';
import { ButtonClose } from '../../../components/Button';

import styles from './ObjectComponent.module.scss';

const ObjectComponentContent = ({
    children,
    onObjectClose,
    title,
}) => (
    <div className={`${styles.content}`}>
        <h2 className={`handle ${styles['content-title']}`}>
            {title}
            <ButtonClose className={styles['button-close']} onClick={onObjectClose} />
        </h2>
        {children}
    </div>
);

ObjectComponentContent.propTypes = {
    children: PropTypes.element.isRequired,
    onObjectClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

ObjectComponentContent.defaultProps = {};

export default ObjectComponentContent;
