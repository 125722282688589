import React from 'react';
import FormElements from '../../shared/Form';
import useStores from '../../../hooks/operator/use-stores';

const FormElementsWithStore = (props) => (
    <FormElements
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        useStores={useStores}
    />
);

export default FormElementsWithStore;
