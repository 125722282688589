import { action, observable } from 'mobx';
import { ListItem } from '../../List';
import { notepad as form } from '../../../../forms/shared';
import createForm from '../../../../helpers/Form';
import forEachField from '../../../../forms/utils/for-each-field';

class NotepadItem extends ListItem {
    @observable itemForm;

    get name() {
        return this.$name;
    }

    get count() {
        return this.$objectCount;
    }

    @action
    createItemForm() {
        const { itemForm } = this;
        if (itemForm) {
            return itemForm;
        }
        this.itemForm = createForm(form);
        this.initItemFormListeners();
        this.setItemFormValues();
        return this.itemForm;
    }

    @action
    setItemFormValues() {
        const { itemForm } = this;
        const editFields = form;
        forEachField(editFields, (field) => {
            if (Object.getOwnPropertyDescriptor(this, field.name)) {
                // eslint-disable-next-line no-param-reassign
                if (this[field.name]) {
                    itemForm.$(field.name).set('value', this[field.name]);
                }
            }
        });
    }

    initItemFormListeners() {
        const {
            parent,
            itemForm,
            id,
        } = this;
        itemForm.on('success', ({ name }) => (
            parent.update(id, name)
                .then((response) => {
                    this.setData(response);
                    return Promise.resolve(response);
                })
        ));
    }
}

export default NotepadItem;
