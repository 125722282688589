import React from 'react';
import PropTypes from 'prop-types';
import { STATUS_FORM, POSITION } from '../../../constants/shared';

import styles from './Table.module.scss';

const CellStatus = ({
    value: { value, text },
    cell: { row: { original: { id } } },
    listId,
    useStores,
}) => {
    const { directoriesStore: store } = useStores ? useStores() : {};
    let textStatus = text;
    if (!textStatus) {
        const status = (
            store.objectstatus
                ? store.objectstatus.options
                : []
        ).find(({ value: option }) => String(option) === String(value));
        textStatus = status.label;
    }
    return (
        <div
            className={`
                ${styles['table__status-trigger']}
                ${styles[`table__status-trigger--${value}`]}
            `}
            data-tip={id}
            data-for={`${STATUS_FORM}-${listId}`}
            data-effect="solid"
            data-place={POSITION.RIGHT}
        >
            {textStatus}
        </div>
    );
};

CellStatus.propTypes = {
    useStores: PropTypes.func.isRequired,
    value: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        text: PropTypes.string,
    }).isRequired,
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        }),
    }).isRequired,
    listId: PropTypes.number,
};

CellStatus.defaultProps = {
    listId: 0,
};

export default CellStatus;
