import PropTypes from 'prop-types';

const CustomMultiValueLabel = ({ data }) => data.label;

CustomMultiValueLabel.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string,
    }).isRequired,
};

export default CustomMultiValueLabel;
