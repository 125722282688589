const createPaymentService = (request) => ({
    getAmount: ({ months }) => (
        request.get(`/billing/amount?months=${months}`)
            .then((data) => Promise.resolve(data))
    ),

    initPayment: (payload) => (
        request.post('/billing', payload)
            .then((data) => Promise.resolve(data.redirectUrl))
    ),

    getPaymentStatus: () => (
        request.get('/billing/status')
            .then((data) => Promise.resolve(data))
    ),
});

export default createPaymentService;
