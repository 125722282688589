import { DIRECTORIES } from '../../../constants/shared';

export default [{
    type: 'fieldset', // commercial type
    name: 'commercialType',
    fields: [{
        name: 'objectCommercialTypeIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_COMMERCIAL_TYPE,
        label: 'Помещение',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset', // group by
    name: 'groupBy',
    fields: [{
        type: 'radioList',
        name: 'groupBy',
        omit: true,
        size: 'md',
        rows: 1,
        value: 'cityDistrictIds',
        options: [{
            value: 'cityDistrictIds',
            label: 'по району',
        }, {
            value: 'radius',
            label: 'по радиусу',
        }],
    }],
}, {
    type: 'fieldset', // location
    name: 'location',
    fields: [{
        name: 'areaIds',
        type: 'directorySearch',
        directory: DIRECTORIES.AREA,
        label: 'Район субъекта',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'cityIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        multiple: true,
        clearable: true,
        searchable: true,
        withDefault: true,
        isDropdown: true,
    }, {
        name: 'cityDistrictIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'streetIds',
        type: 'directorySearch',
        directory: DIRECTORIES.STREETS,
        label: 'Улица',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
        optionValuesIsArray: true,
    }, {
        name: 'houseIds',
        type: 'directorySearch',
        directory: DIRECTORIES.HOUSE,
        label: 'Дом',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
        optionValuesIsArray: true,
    }, {
        name: 'cityDistrictLandmarkIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT_LANDMARK,
        label: 'Ориентир',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }, {
        name: 'radius',
        type: 'fieldset',
        label: '',
        fields: [
            { name: 'radius', type: 'radius' },
            { name: 'address', hidden: true },
            { name: 'latitude', hidden: true },
            { name: 'longitude', hidden: true },
        ],
    }],
}, {
    type: 'fieldset', // house
    name: 'house',
    fields: [{
        name: 'rooms',
        type: 'directorySelect',
        label: 'Комнат',
        directory: DIRECTORIES.LOCAL_DIRECTORIES.ROOMS_COUNT,
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'square',
        type: 'fieldgroup',
        label: 'Площадь, м²',
        fields: [{
            name: 'totalAreaFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'totalAreaTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }],
}, {
    type: 'fieldset', // price
    name: 'price',
    fields: [{
        name: 'price',
        type: 'fieldgroup',
        label: 'Цена',
        fields: [{
            name: 'priceFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'priceTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }, {
        name: 'unitPrice',
        type: 'fieldgroup',
        label: 'Цена за м²',
        fields: [{
            name: 'unitPriceFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'unitPriceTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }],
}, {
    type: 'fieldset', // floor
    name: 'floor',
    fields: [{
        name: 'floor',
        type: 'fieldgroup',
        label: 'Этаж',
        fields: [{
            name: 'floorFrom',
            type: 'integer',
            prefix: 'от',
        }, {
            name: 'floorTo',
            type: 'integer',
            prefix: 'до',
        }],
    }],
}, {
    type: 'fieldset', // chars
    name: 'chars',
    fields: [{
        name: 'wallMaterialIds',
        type: 'directorySelect',
        directory: DIRECTORIES.WALL_MATERIAL,
        label: 'Материал',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }, {
        name: 'objectConditionIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_CONDITION,
        label: 'Состояние',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset', // contacts
    name: 'contacts',
    fields: [{
        name: 'contact',
        type: 'text',
        label: 'Контакт',
    }, {
        name: 'phone',
        type: 'phone',
        label: 'Телефон',
        size: 'sm',
        rules: 'regex:/^[+]?[0-9)(-]{11,15}/',
    }],
}, {
    type: 'fieldset', // contacts
    name: 'hidden',
    hidden: true,
    fields: [{
        name: 'objectSelectionId',
        type: 'integer',
        clearable: true,
        hidden: true,
    },
    {
        name: 'updatedAtFrom',
        type: 'datepicker',
        placement: 'bottom-end',
        clearable: true,
        hidden: true,
    }, {
        name: 'updatedAtTo',
        type: 'datepicker',
        placement: 'bottom-end',
        clearable: true,
        hidden: true,
    }],
}];
