import { computed } from 'mobx';
import createForm from '../../../helpers/Form';
import { mortgageCalculator as mortgageCalculatorFields } from '../../../forms/shared';

class MortgageCalculator {
    form;

    @computed
    get monthlyPayment() {
        const { form } = this;
        const { 'payment-type': paymentType } = form.values();
        return paymentType === 'annuity'
            ? this.annuityMonthlyPayment
            : this.differentiatedMonthlyPayment;
    }

    @computed
    get totalPayment() {
        const { form } = this;
        const { 'payment-type': paymentType } = form.values();
        return paymentType === 'annuity'
            ? this.annuityTotalPayment
            : this.differentiatedTotalPayment;
    }

    @computed
    get totalOverPayment() {
        const { form } = this;
        const { 'payment-type': paymentType } = form.values();
        return paymentType === 'annuity'
            ? this.annuityTotalOverPayment
            : this.differentiatedTotalOverPayment;
    }

    @computed
    get annuityMonthlyPayment() {
        const { form } = this;
        const {
            price,
            percentage,
            period,
            'initial-payment': initialPayment,
        } = form.values();
        const debt = price - initialPayment;
        const months = period * 12;
        const r = percentage / 100 / 12;
        // eslint-disable-next-line max-len
        const total = debt * ((r * ((1 + r) ** months)) / (((1 + r) ** months) - 1));
        return total.toFixed(2);
    }

    @computed
    get annuityTotalPayment() {
        const { form, annuityMonthlyPayment } = this;
        const {
            period,
            'initial-payment': initialPayment,
        } = form.values();
        return ((period * 12) * annuityMonthlyPayment + initialPayment).toFixed(2);
    }

    @computed
    get annuityTotalOverPayment() {
        const { form, annuityMonthlyPayment } = this;
        const {
            price,
            period,
            'initial-payment': initialPayment,
        } = form.values();
        const debt = price - initialPayment;
        const overPayment = (period * 12) * annuityMonthlyPayment - debt;
        return overPayment.toFixed(2);
    }

    @computed
    get differentiatedMonthlyPayment() {
        const { form } = this;
        const {
            price,
            percentage,
            period,
            'initial-payment': initialPayment,
        } = form.values();
        const debt = price - initialPayment;
        const months = period * 12;
        const mainPayment = debt / months;
        // eslint-disable-next-line max-len
        const firstPayment = mainPayment + (debt * (percentage / 100)) / 12;
        // eslint-disable-next-line max-len
        const lastPayment = mainPayment + ((debt - mainPayment * (months - 1)) * (percentage / 100)) / 12;
        return `${firstPayment.toFixed(2)} - ${lastPayment.toFixed(2)}`;
    }

    @computed
    get differentiatedTotalPayment() {
        const { form } = this;
        const {
            price,
            percentage,
            period,
            'initial-payment': initialPayment,
        } = form.values();
        const debt = price - initialPayment;
        const months = period * 12;
        const mainPayment = debt / months;
        let totalPayment = 0;
        for (let i = 0; i < months; i += 1) {
            // eslint-disable-next-line max-len
            totalPayment += mainPayment + ((debt - mainPayment * (months - i)) * (percentage / 100)) / 12;
        }
        return (totalPayment + initialPayment).toFixed(2);
    }

    @computed
    get differentiatedTotalOverPayment() {
        const { form } = this;
        const {
            price,
            percentage,
            period,
            'initial-payment': initialPayment,
        } = form.values();
        const debt = price - initialPayment;
        const months = period * 12;
        const mainPayment = debt / months;
        let totalPayment = 0;
        for (let i = 0; i < months; i += 1) {
            // eslint-disable-next-line max-len
            totalPayment += mainPayment + ((debt - mainPayment * (months - i)) * (percentage / 100)) / 12;
        }
        const overPayment = totalPayment - debt;
        return overPayment.toFixed(2);
    }

    constructor() {
        this.form = createForm(mortgageCalculatorFields);
        this.initFormListeners();
    }

    setPrice(price) {
        const { form } = this;
        const { 'initial-payment': initialPayment } = form.values();
        const priceField = form.$('price');
        const initialPaymentField = form.$('initial-payment');
        const priceExtra = priceField.get('extra');
        const initialPaymentExtra = initialPaymentField.get('extra');
        if (priceExtra.max <= price) {
            priceField.set('extra', {
                ...priceExtra,
                max: price,
            });
        }
        initialPaymentField.set('extra', {
            ...initialPaymentExtra,
            max: price * 0.75,
        });
        priceField.set('value', price);
        if (initialPayment > price) {
            form.$('initial-payment').set('value', price * 0.75);
        }
    }

    initFormListeners() {
        const { form } = this;
        const initialPaymentField = form.$('initial-payment');
        const initialPaymentPercentField = form.$('initial-payment-percent');
        const priceField = form.$('price');
        let handleInitialPaymentChange;
        let handleInitialPaymentPercentChange;
        let handlePriceChange;
        // eslint-disable-next-line prefer-const
        handleInitialPaymentChange = ({ change: { newValue } }) => {
            const { price } = form.values();
            const percent = ((newValue / price) * 100).toFixed(2);
            initialPaymentPercentField.dispose({ type: 'observer' });
            initialPaymentPercentField.set('value', percent);
            initialPaymentPercentField.observe(handleInitialPaymentPercentChange);
        };
        // eslint-disable-next-line prefer-const
        handleInitialPaymentPercentChange = ({ change: { newValue } }) => {
            const { price } = form.values();
            const value = Math.round(((price / 100) * newValue));
            initialPaymentField.dispose({ type: 'observer' });
            initialPaymentField.set('value', value);
            initialPaymentField.observe(handleInitialPaymentChange);
        };
        // eslint-disable-next-line prefer-const
        handlePriceChange = ({ change: { newValue } }) => {
            const { 'initial-payment': initialPayment } = form.values();
            const extra = initialPaymentField.get('extra');
            const max = newValue;
            initialPaymentField.set('extra', { ...extra, max });
            if (max < initialPayment) {
                initialPaymentPercentField.dispose({ type: 'observer' });
                initialPaymentField.set('value', max);
                initialPaymentPercentField.set('value', 100);
                initialPaymentPercentField.observe(handleInitialPaymentPercentChange);
            } else {
                handleInitialPaymentChange({ change: { newValue: initialPayment } });
            }
        };

        initialPaymentField.observe(handleInitialPaymentChange);
        initialPaymentPercentField.observe(handleInitialPaymentPercentChange);
        priceField.observe(handlePriceChange);
    }
}

export default MortgageCalculator;
