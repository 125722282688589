/* global ResizeObserver */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint arrow-body-style: [0] */

import { Observer, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React,
{
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    useBlockLayout,
    useExpanded,
    useGroupBy,
    useResizeColumns,
    useTable,
} from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import ReactTooltip from '../../../lib/react-tooltip';
// import { FixedSizeList } from 'react-window';
import { ClickableBox } from '../../../components/ClickableBox';
import Preloader from '../../../components/Preloader';
import {
    POSITION,
    PRICE_STATS,
    SCROLL_WIDTH_LAG,
    STATUS_FORM,
    THUMBNAIL_SLIDER,
} from '../../../constants/shared';
import updateColor from '../../../utils/update-color';
import ItemStatus from '../ItemStatus/ItemStatus';
import ObjectImagesDialog from '../ObjectImagesDialog/ObjectImagesDialog';
import HeaderTitle from './HeaderTitle';
import TableImageSlider from './TableImageSlider';
import modifyHeaders from './utils/modify-headers';
import VariableSizeList from './VariableSizeList';

import EditObjectsTooltip from '../Tooltips/presets/editObjectsTooltip';
import GroupingRow from './GroupingRow';
import PriceTooltip from './PriceTooltip';
import SourceListTooltip from './SourceListTooltip';
import styles from './Table.module.scss';

const TableWrapper = forwardRef(({
    useStores,
    headers,
    store,
    onRowClick,
    onDoubleRowClick,
    onTableHeaderClick,
    onRightClick,
    onCellClick,
    wrapperStyles,
    textAlign,
    onStatusTooltipToggle,
}, ref) => {
    const {
        filterForm: form,
    } = store;
    const [width, setWidth] = useState(undefined);
    const parentRef = useRef();
    const { dialogStore } = useStores();
    const tooltipRef = useRef();

    const handleImageClick = (event, index) => {
        tooltipRef.current.hideTooltip();
        event.stopPropagation();
        const customProps = index !== undefined
            ? ({ initSlide: index })
            : ({});
        dialogStore.show(`imageSlider-${store.listId}`, customProps);
    };

    useEffect(() => {
        if (!parentRef.current.offsetWidth) {
            return undefined;
        }
        const parentResizeObserver = new ResizeObserver(([e]) => {
            const { target } = e;
            setWidth(target.offsetWidth);
        });
        parentResizeObserver.observe(parentRef.current);
        setWidth(parentRef.current.offsetWidth);
        return () => parentResizeObserver.unobserve(parentRef.current);
    }, [parentRef]);

    const getColumns = (headersParam, widthParam) => (
        headersParam.map(({
            title,
            textAlign: columnAlign,
            name,
            tooltip,
            sortName,
            sortDirection,
            fields,
            width: relWidth,
            ...header
        }) => ({
            ...header,
            Header: (
                <HeaderTitle
                    useStores={useStores}
                    title={title}
                    fields={fields}
                    form={form}
                    sortName={sortName}
                    sortDirection={sortDirection}
                    onTableHeaderClick={onTableHeaderClick}
                />
            ),
            title,
            tooltip,
            columnAlign,
            accessor: name,
            width: widthParam && relWidth
                ? Math.round(((widthParam - SCROLL_WIDTH_LAG) / 100) * relWidth)
                : undefined,
        }))
    );

    const memoizedColumns = useMemo(() => (
        getColumns(modifyHeaders(headers), width)
    ), [headers, width]);

    const handleScrollEnd = useCallback(() => {
        if (!store.loadMore || (store.loading && !store.firstLoad)) {
            return null;
        }
        return store.loadMore();
    }, [store]);

    const handleRightRowClick = (row) => {
        const { original: { id } } = row;
        if (typeof store.setCursor === 'function') {
            store.setCursor(id);
        }
        onRightClick(row);
    };

    return (
        <>
            <div className={`${styles['table-wrapper']}`} ref={parentRef} style={wrapperStyles}>
                <Observer>
                    {
                        () => (
                            <div
                                className={`
                                    ${styles.table__preloader}
                                    ${store.loading ? styles['table__preloader--loading'] : ''}
                                `}
                            >
                                <Preloader className={styles.table__spinner} />
                            </div>
                        )
                    }
                </Observer>
                {
                    width
                        ? (
                            <Observer>
                                {() => (
                                    <Table
                                        ref={ref}
                                        columns={memoizedColumns}
                                        data={store.tableData}
                                        grouping={store.grouping}
                                        memoKey={store.memoKey}
                                        listId={store.listId}
                                        selectedData={store.selected}
                                        onScrollEnd={handleScrollEnd}
                                        onRowClick={onRowClick}
                                        onDoubleRowClick={onDoubleRowClick}
                                        onRightRowClick={handleRightRowClick}
                                        onCellClick={onCellClick}
                                        textAlign={textAlign}
                                        useStores={useStores}
                                    />
                                )}
                            </Observer>
                        )
                        : null
                }
            </div>
            <ReactTooltip
                ref={tooltipRef}
                id={`${THUMBNAIL_SLIDER}-${store.listId}`}
                clickable
                delayHide={100}
                bodyMode
                getContent={(id) => {
                    if (!id || !store.getItem(id)) {
                        return null;
                    }
                    const { images, objectName } = store.getItem(id);
                    return images.length
                        ? (
                            <>
                                <TableImageSlider
                                    sources={images}
                                    onSliderClick={handleImageClick}
                                    relatedId={id}
                                    onImageDownload={(index) => store.downloadImage(id, index)}
                                />
                                <ObjectImagesDialog
                                    useStores={useStores}
                                    name={`imageSlider-${store.listId}`}
                                    header={`Фото объекта ${objectName}`}
                                    sources={images}
                                    relatedId={id}
                                    onImageDownload={(index) => store.downloadImage(id, index)}
                                />
                            </>
                        )
                        : null;
                }}
            />
            {store.tableHeaders.find(({ name }) => (name === 'price' || name === 'priceDelimited')) && (
                <ReactTooltip
                    id={`${PRICE_STATS}-${store.listId}`}
                    clickable
                    delayHide={100}
                    bodyMode
                    getContent={(id) => {
                        if (!id || !store.getItem(id)) {
                            return null;
                        }
                        const { priceDynamic } = store.getItem(id);
                        return <PriceTooltip priceDynamic={priceDynamic} />;
                    }}
                />
            )}
            {store.tableHeaders.find(({ name }) => name === 'source') && (
                <SourceListTooltip store={store} />
            )}
            {
                store.tableHeaders.find(({ name }) => name === 'objectStatusUpdate')
                    ? (
                        <ReactTooltip
                            id={`${STATUS_FORM}-${store.listId}`}
                            clickable
                            delayHide={100}
                            event="click"
                            globalEventOff="click"
                            bodyMode
                            getContent={(id) => {
                                if (!id || !store.getItem(id)) {
                                    return null;
                                }
                                const item = store.getItem(id);
                                const statusForm = item.createStatusUpdateForm();
                                return (
                                    <ItemStatus
                                        form={statusForm}
                                        useStores={useStores}
                                    />
                                );
                            }}
                        />
                    )
                    : null
            }
            {
                store.tableHeaders.find(({ name }) => name === 'objectStatusUpdateWithContact')
                    ? (
                        <EditObjectsTooltip
                            id={`${STATUS_FORM}-${store.listId}`}
                            useStores={useStores}
                            store={store}
                            afterShow={() => onStatusTooltipToggle(true)}
                            afterHide={() => onStatusTooltipToggle(false)}
                        />
                    )
                    : null
            }
        </>
    );
});

TableWrapper.propTypes = {
    useStores: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            width: PropTypes.number,
        }),
    ),
    store: PropTypes.shape({
        loading: PropTypes.bool,
        firstLoad: PropTypes.bool,
        tableData: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        tableHeaders: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        memoKey: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        grouping: PropTypes.arrayOf(PropTypes.string),
        loadMore: PropTypes.func,
        downloadImage: PropTypes.func,
        filterForm: PropTypes.shape({}),
        reload: PropTypes.func,
        getItem: PropTypes.func,
        listId: PropTypes.number,
        selected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        setCursor: PropTypes.func,
    }).isRequired,
    onRowClick: PropTypes.func,
    onCellClick: PropTypes.func,
    onDoubleRowClick: PropTypes.func,
    onRightClick: PropTypes.func,
    wrapperStyles: PropTypes.shape({}),
    textAlign: PropTypes.oneOf([
        'left',
        'center',
        'right',
    ]),
    onTableHeaderClick: PropTypes.func,
    onStatusTooltipToggle: PropTypes.func,
};

TableWrapper.defaultProps = {
    headers: [],
    onRowClick: () => {},
    onCellClick: () => {},
    onDoubleRowClick: () => {},
    onRightClick: () => null,
    wrapperStyles: {},
    textAlign: 'center',
    onTableHeaderClick: () => null,
    onStatusTooltipToggle: () => {},
};

const Table = forwardRef(({
    columns,
    data,
    grouping,
    memoKey,
    listId,
    selectedData,
    onScrollEnd,
    onRowClick,
    onDoubleRowClick,
    onRightRowClick,
    onCellClick,
    textAlign,
    useStores,
}, ref) => {
    const tableId = `table-${listId}`;

    const modifiedColumns = useMemo(() => {
        if (!columns || !grouping || !grouping.length) {
            return columns;
        }

        // Добавить колонку группировки, если указана группировка
        return [{
            accessor: 'groupKey',
            title: '\u0000',
            width: 2,
        }].concat(columns);
    }, [columns, grouping]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { columnResizing, groupBy, expanded },
        setGroupBy,
        toggleAllRowsExpanded,
    } = useTable(
        {
            columns: modifiedColumns,
            data,
            getRowId: useMemo(() => (row, relativeIndex, parent) => {
                const id = parent
                    ? [parent.id, relativeIndex].join('.')
                    : relativeIndex;
                return `${id}-${row.memoKey}`;
            }, [memoKey]),
        },
        useBlockLayout,
        useResizeColumns,
        useGroupBy,
        useExpanded,
    );

    useEffect(() => {
        setGroupBy(grouping);
        // По-умолчанию разворачивать все группы
        toggleAllRowsExpanded(true);
        // Замыкание на groupBy, чтобы не сбрасывалась группировка
    }, [grouping, groupBy]);

    const RenderRow = useMemo(
        () => observer(({ index, style }) => {
            const row = rows[index];
            prepareRow(row);

            // Если это группировочная строка
            if (row.isGrouped) {
                return <GroupingRow row={row} style={style} isCheckboxLeftAlign={false} />;
            }

            // Иначе просто рендерим строку
            const rowProps = row.getRowProps({ style });
            const {
                id,
                original: {
                    selected,
                    active,
                    disabled,
                    highlight,
                    highlightValue,
                },
            } = row;
            return (
                <>
                    {
                        highlight
                            ? (
                                <style>
                                    {`
                                        #${tableId} #table-row-${id} {
                                            background-color: ${highlight.bgColor};
                                        }

                                        #${tableId} #table-row-${id}:hover {
                                            background-color: ${highlight.bgColorHover};
                                        }

                                        #${tableId} #table-row-${id}.active,
                                        #${tableId} #table-row-${id}.selected {
                                            background-color: ${updateColor(-0.2, highlight.bgColor)};
                                        }
                                    `}
                                </style>
                            )
                            : null
                    }
                    <ClickableBox
                        onClick={() => onRowClick(row)}
                        onDoubleClick={() => onDoubleRowClick(row)}
                        onRightClick={() => onRightRowClick(row)}
                    >
                        <div
                            {...rowProps}
                            id={`table-row-${id}`}
                            className={`
                                ${styles.table__row}
                                ${styles[`table__row--${index % 2 === 0 ? 'even' : 'odd'}`]}
                                ${selected ? `${styles['table__row--selected']} selected` : ''}
                                ${active ? `${styles['table__row--active']} active` : ''}
                                ${disabled ? `${styles['table__row--disabled']} disabled` : ''}
                            `}
                            data-tip={highlightValue || null}
                            data-place={POSITION.RIGHT}
                            data-class={styles.table__hint}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <ClickableBox
                                        onClick={() => {
                                            if (typeof onCellClick === 'function') {
                                                return onCellClick(cell);
                                            }
                                            return undefined;
                                        }}
                                        onDoubleClick={() => {}}
                                        onRightClick={() => {}}
                                    >
                                        <div
                                            {...cell.getCellProps({ style: cell.column.style })}
                                            className={`
                                                ${styles.table__cell}
                                                ${styles[`table__cell--align-${cell.column.columnAlign || textAlign}`]}
                                                ${styles[`table__cell--${cell.column.className}`] || ''}
                                            `}
                                        >
                                            {
                                                cell.render(
                                                    'Cell',
                                                    {
                                                        listId,
                                                        useStores,
                                                        rowHint: highlightValue || null,
                                                    },
                                                )
                                            }
                                        </div>
                                    </ClickableBox>
                                );
                            })}
                        </div>
                    </ClickableBox>
                </>
            );
        }),
        [prepareRow, rows, groupBy, expanded, selectedData, columnResizing.columnWidths],
    );

    const handleScroll = useCallback((containerHeight, tableHeight) => (e) => {
        const offset = Math.ceil(e.scrollOffset);
        const diff = Math.ceil(tableHeight - containerHeight);
        if (diff > 0 && offset >= diff) {
            onScrollEnd();
        }
    }, []);

    return (
        <div
            {...getTableProps()}
            className={`${styles.table}`}
            // eslint-disable-next-line react/no-unknown-property
            memokey={memoKey}
        >
            <div className={styles.table__head}>
                {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                            return (
                                <div
                                    {...column.getHeaderProps()}
                                    className={`
                                        ${styles.table__title}
                                        ${styles[`table__title--align-${column.columnAlign || textAlign}`]}
                                    `}
                                    data-tip={column.tooltip}
                                    data-place={POSITION.RIGHT}
                                    data-class={styles.table__hint}
                                >
                                    {column.render('Header', { listId, useStores })}
                                    <div
                                        {...column.getResizerProps()}
                                        className={styles.table__resizer}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            <div
                id={tableId}
                {...getTableBodyProps()}
                className={styles.table__body}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <VariableSizeList
                            ref={ref}
                            className={styles.table__list}
                            height={height}
                            width={width}
                            overscanCount={5}
                            handleScroll={handleScroll}
                            rows={rows}
                        >
                            {RenderRow}
                        </VariableSizeList>
                    )}

                    {/* {({ width, height }) => (
                        <FixedSizeList
                            ref={ref}
                            className={styles.table__list}
                            height={height}
                            width={width}
                            itemCount={rows.length}
                            itemSize={17}
                            overscanCount={5}
                            onScroll={handleScroll(height, rows.length * 17)}
                        >
                            {RenderRow}
                        </FixedSizeList>
                    )} */}
                </AutoSizer>
            </div>
        </div>
    );
});

Table.propTypes = {
    useStores: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    data: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    grouping: PropTypes.arrayOf(PropTypes.string),
    memoKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    listId: PropTypes.number,
    selectedData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onScrollEnd: PropTypes.func,
    onRowClick: PropTypes.func,
    onCellClick: PropTypes.func,
    onDoubleRowClick: PropTypes.func,
    onRightRowClick: PropTypes.func,
    textAlign: PropTypes.oneOf([
        'left',
        'center',
        'right',
    ]),
};

Table.defaultProps = {
    columns: [],
    data: [],
    grouping: [],
    memoKey: 0,
    onScrollEnd: null,
    onRowClick: () => {},
    onCellClick: () => {},
    onDoubleRowClick: () => {},
    onRightRowClick: () => null,
    textAlign: 'center',
    listId: 0,
};

export default TableWrapper;
