import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.ROW_SELECT,
    width: 3,
    sortName: null,
}, {
    ...TABLE_CELL.OBJECT_TYPE,
    width: 3,
    sortName: null,
    fields: [{
        name: 'ownerTypeIds',
    }],
}, {
    ...TABLE_CELL.COMMERCIAL_TYPE_NAME,
    width: 11,
    textAlign: 'left',
    fields: [{
        name: 'objectCommercialTypeIds',
    }],
}, {
    ...TABLE_CELL.EXCHANGE,
    width: 4,
    sortName: null,
}, {
    ...TABLE_CELL.ROOMS_COUNT,
    width: 3,
    fields: [{
        name: 'rooms',
    }],
}, {
    ...TABLE_CELL.SHORT_DISTRICT_NAME,
    width: 5,
    fields: [{
        name: 'cityDistrictIds',
    }],
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 18,
    textAlign: 'left',
    fields: [{
        name: 'streetIds',
    }],
}, {
    ...TABLE_CELL.HOUSE_NUMBER,
    title: '№',
    width: 6,
    fields: [{
        name: 'houseIds',
    }],
}, {
    ...TABLE_CELL.DISTRICT_LANDMARK_NAME,
    textAlign: 'left',
    width: 10,
    fields: [{
        name: 'cityDistrictLandmarkIds',
    }],
}, {
    ...TABLE_CELL.FLOOR_WITH_FLOOR_COUNT_WITH_WALL_MATERIAL,
    width: 8,
    fields: [{
        name: 'floorFrom',
    }, {
        name: 'floorTo',
    }],
}, {
    ...TABLE_CELL.SQUARE_TOTAL,
    width: 6,
    fields: [{
        name: 'totalAreaFrom',
    }, {
        name: 'totalAreaTo',
    }],
    sortName: null,
}, {
    ...TABLE_CELL.PRICE_DELIMITED,
    width: 6,
}, {
    ...TABLE_CELL.UNIT_PRICE_DELIMITED,
    width: 5,
}, {
    ...TABLE_CELL.DATE_OF_CONSTRUCTION,
    width: 5,
    sortName: null,
}, {
    ...TABLE_CELL.IMAGES,
    sortName: null,
    width: 5.7,
}, {
    ...TABLE_CELL.INCORRECT_OBJECT,
}];
