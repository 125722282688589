import { computed } from 'mobx';
import FlatItem from './FlatItem';

class CommercialItem extends FlatItem {
    @computed
    get objectName() {
        const {
            streetName,
            houseNumber,
            phone,
            id,
        } = this;
        const fullName = [streetName, houseNumber]
            .filter((address) => Boolean(address))
            .join(', ');
        return fullName || phone || id;
    }

    @computed
    get commercialTypeName() {
        const { commercialType } = this;
        if (!commercialType) {
            return null;
        }
        return commercialType.name;
    }

    @computed
    get squareTotal() {
        const { totalArea } = this;
        return totalArea || null;
    }

    @computed
    get dateOfConstruction() {
        const { yearOfConstruction } = this;
        return yearOfConstruction;
    }

    @computed
    get roomCount() {
        const { $roomCount } = this;
        if ($roomCount === null || $roomCount === undefined) {
            return null;
        }
        return `${$roomCount}`;
    }
}

export default CommercialItem;
