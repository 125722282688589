import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-contextmenu';
import SVGIcon from '../Icons/SVGIcon';

import styles from './ContextMenu.module.scss';

const ModifiedMenuItem = ({
    className,
    icon,
    children,
    disabled,
    iconHeight,
    ...props
}) => (
    <MenuItem
        className={`
            ${styles['context-menu-item']}
            ${disabled ? styles['context-menu-item--disabled'] : ''}
            ${className}
        `}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        {
            icon
                ? (
                    <span className={styles['context-menu-item__icon']}>
                        <SVGIcon
                            SVGElement={icon}
                            height={iconHeight}
                        />
                    </span>
                ) : null
        }
        <span className={styles['context-menu-item__value']}>
            {children}
        </span>
    </MenuItem>
);

ModifiedMenuItem.propTypes = {
    children: PropTypes.string,
    icon: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconHeight: PropTypes.number,
};

ModifiedMenuItem.defaultProps = {
    children: '',
    icon: null,
    className: '',
    disabled: false,
    iconHeight: 16,
};

export default ModifiedMenuItem;
