import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';

import useStores from '../../../hooks/client/use-stores';
import ObjectListStore from '../../../stores/shared/RefactoredObjectList/ObjectList';
import { OBJECT_TYPE_MAP } from '../../../constants/shared';
import { MenuItem, SubMenu } from '../../../components/ContextMenu';
import { OKIcon, SocialIcon, VKIcon } from '../../../components/Icons/icons';

import styles from './SocialNetworks.module.scss';

const SocialNetworksMenu = (props) => {
    const { list } = props;
    const { dialogStore, sessionStore: { socialNetworkInfo } } = useStores();

    const handleSocialNetworkSelect = (_, { id }) => {
        const item = list.getItem(list.cursor);
        list.prepareSocialNetworkPostData(
            id, item.id, OBJECT_TYPE_MAP[list.objectType].toLowerCase(),
            () => dialogStore.show('social-network-dialog'),
        );
    };

    return (
        <Observer>
            {() => (
                <SubMenu
                    icon={SocialIcon}
                    title="Соцсети"
                    itemClassName={styles['social-network__context']}
                    disabled={(
                        !socialNetworkInfo.vkUserName
                        && !socialNetworkInfo.okUserName
                    )}
                >
                    <MenuItem
                        icon={VKIcon}
                        data={{ id: 'vk' }}
                        onClick={handleSocialNetworkSelect}
                        disabled={!socialNetworkInfo.vkUserName}
                        iconHeight={18}
                    >
                        Вконтакте
                    </MenuItem>
                    <MenuItem
                        icon={OKIcon}
                        data={{ id: 'ok' }}
                        onClick={handleSocialNetworkSelect}
                        disabled={!socialNetworkInfo.okUserName}
                        iconHeight={18}
                    >
                        Одноклассники
                    </MenuItem>
                </SubMenu>
            )}
        </Observer>
    );
};

SocialNetworksMenu.propTypes = {
    list: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(ObjectListStore),
    ]).isRequired,
};

export default SocialNetworksMenu;
