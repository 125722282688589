import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboardButton } from '../CopyToClipboard/CopyToClipboard';

/**
 * @description converts e164 phone number into local
 * @param {object} props - react component props
 * @param {copy} props.copy - can copy phone
 * @param {string} props.children - e164 phone number
 * @param {string} props.className - class
 * @param {Function} props.onClick - on click function
 * @returns {React.Component} react component
 */
const Phone = ({
    children, className,
    onClick,
    copy,
}) => {
    if (!children) return null;
    let phoneNumberFormatted = children;
    const phoneNumberNumeric = children.replace(/[^\d]/g, '');
    if (phoneNumberNumeric.length === 11) {
        phoneNumberFormatted = phoneNumberNumeric.replace(
            /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
            '+$1 ($2) $3 $4 $5',
        );
    }
    return (
        <>
            <a
                className={`
                    link
                    ${className}
                `}
                href={`tel:+${phoneNumberNumeric}`}
                onClick={onClick}
            >
                {phoneNumberFormatted}
            </a>
            {copy && <CopyToClipboardButton text={phoneNumberFormatted} />}
        </>
    );
};

Phone.propTypes = {
    copy: PropTypes.bool,
    children: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Phone.defaultProps = {
    copy: false,
    children: '',
    className: '',
    onClick: null,
};

export default Phone;
