import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset', // ad
    name: 'location',
    fields: [{
        name: 'cityId',
        size: 'md',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Нас. пункт',
        clearable: true,
        searchable: true,
        rules: 'required',
    }, {
        name: 'name',
        size: 'md',
        type: 'text',
        label: 'Район',
        rules: 'required|max:128',
    }],
}];
