/* eslint linebreak-style: 0 */

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { EMPTY_DATA_STRING, POSITION, SOURCE_LIST } from '../../../constants/shared';
import styles from './Table.module.scss';
import { ListBlueIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';
import extractBoardNameFromUrl from '../../../utils/extract-board-name-from-url';

const CellSource = ({
    cell: { row: { original } },
    listId,
}) => {
    const ads = original.ad.toSorted((a, b) => {
        const getKey = (elem) => elem.updatedAt ?? elem.createdAt ?? '';
        return getKey(b)
            .localeCompare(getKey(a));
    });

    const ad = ads[0];
    if (!ad) {
        return <div>{EMPTY_DATA_STRING}</div>;
    }

    const boardName = useMemo(() => ad.board?.name ?? extractBoardNameFromUrl(ad.url), [ad]);

    const showTooltipButton = (
        <i
            data-tip={original.id}
            data-for={`${SOURCE_LIST}-${listId}`}
            data-place={POSITION.BOTTOM}
            data-class={`${styles.table__hint} ${styles.table__slider}`}
            data-effect="solid"
        >
            <SVGIcon className={styles['table__cell-source-expand-icon']} SVGElement={ListBlueIcon} />
        </i>
    );

    return (
        <div className={styles['table__cell-source']}>
            <div className={styles['table__cell-source-link']}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={ad?.url}
                    onClick={(e) => e.stopPropagation()}
                    onContextMenu={(e) => e.stopPropagation()}
                >
                    {boardName}
                </a>
            </div>

            {ads.length > 1 && showTooltipButton}
        </div>
    );
};

CellSource.propTypes = {
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                ad: PropTypes.oneOfType([
                    PropTypes.array,
                ]),
            }),
        }),
    }).isRequired,
    listId: PropTypes.number.isRequired,
};

CellSource.defaultProps = {};

export default CellSource;
