import { computed, observable } from 'mobx';
import { withAddressField, withObjectDetailedField } from '../../../helpers/Form/mixins';
import HouseDirectory from '../Directories/entities/HouseDirectory';
import ObjectDetailed from './ObjectDetailed';

class SteadDetailed extends ObjectDetailed {
    @observable $steadType;

    @observable $isOwned;

    @observable $passage;

    @observable $hasCommunications;

    @observable $hasElectricity;

    @observable $hasForestNearby;

    constructor(service) {
        super(service, 'steads');
    }

    @computed
    get objectName() {
        const {
            $street = {},
            $house = {},
            id,
        } = this;
        if ($street) {
            return [$street.name, $house?.number]
                .filter((address) => Boolean(address))
                .join(', ');
        }
        return id;
    }

    @computed
    get house() {
        const { $house } = this;
        if (!$house) {
            return null;
        }
        return {
            value: $house.id,
            label: HouseDirectory.getFullHouseName($house),
            extra: {
                number: $house.number,
                buildingNumber: $house.buildingNumber,
                structureNumber: $house.structureNumber,
            },
        };
    }

    @computed
    get steadType() {
        const { $steadType } = this;
        if (!$steadType) {
            return null;
        }
        return {
            value: $steadType.id,
            label: $steadType.name,
        };
    }

    @computed
    get passage() {
        const { $passage } = this;
        if (!$passage) {
            return null;
        }
        return {
            value: $passage.id,
            label: $passage.name,
        };
    }

    @computed
    get buildings() {
        const { $buildings } = this;
        if (!$buildings) {
            return [];
        }
        return $buildings.map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    }

    @computed
    get hasCommunications() {
        return Boolean(this.$hasCommunications);
    }

    @computed
    get hasElectricity() {
        return Boolean(this.$hasElectricity);
    }

    @computed
    get hasForestNearby() {
        return Boolean(this.$hasForestNearby);
    }

    @computed
    get hasVegetation() {
        return Boolean(this.$hasVegetation);
    }

    @computed
    get hasWaterSupply() {
        return Boolean(this.$hasWaterSupply);
    }

    @computed
    get isOwned() {
        return Boolean(this.$isOwned);
    }

    @computed
    get fullAddress() {
        const { $city, $street, $house } = this;
        if (!$city || !$street || !$house) {
            return null;
        }
        return `${$city.name}, ${$street.name}, ${$house.number}`;
    }

    createForm(mixins = []) {
        return super.createForm([
            withObjectDetailedField({
                totalArea: 'totalArea',
                price: 'price',
                unitPrice: 'unitPrice',
            }, true),
            withAddressField({
                area: 'area',
                city: 'city',
                district: 'cityDistrict',
                districtLandmark: 'cityDistrictLandmark',
                street: 'street',
                house: 'house',
            }),
            ...mixins,
        ]);
    }

    // eslint-disable-next-line class-methods-use-this
    prepareRequest(values) {
        const prepared = super.prepareRequest(values);
        return {
            ...prepared,
            objectSteadTypeId: values.steadType,
            passageId: values.passage,
            buildingIds: values.buildings || [],
            isOwned: values.isOwned,
            areaId: values.area,
            cityId: values.city,
            cityDistrictId: values.cityDistrict,
            cityDistrictLandmarkId: values.cityDistrictLandmark,
            streetId: values.street,
            houseId: Array.isArray(values.house) ? values.house[0] : values.house,
            hasCommunications: values.hasCommunications,
            hasElectricity: values.hasCommunications,
            hasForestNearby: values.hasForestNearby,
            hasVegetation: values.hasVegetation,
            hasWaterSupply: values.hasWaterSupply,
            totalArea: values.totalArea,
        };
    }

    toItem() {
        const item = super.toItem();
        return {
            ...item,
            steadType: this.$steadType ? this.$steadType : null,
            totalArea: this.$totalArea,
            unitPrice: this.$unitPrice,
        };
    }
}

export default SteadDetailed;
