import React from 'react';
import PropTypes from 'prop-types';
import ObjectComponent from '../ObjectComponent';
import ObjectDetailed from '../../../stores/shared/RefactoredObjectDetailed/ObjectDetailed';

const EditObject = ({
    useStores,
    id,
    onObjectClose,
    onClose,
    store,
    tabsStore,
    view,
}) => (
    <ObjectComponent
        useStores={useStores}
        id={id}
        onObjectClose={onObjectClose}
        onClose={onClose}
        store={store}
        tabsStore={tabsStore}
        view={view}
    />
);

EditObject.propTypes = {
    useStores: PropTypes.func,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onObjectClose: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(ObjectDetailed).isRequired,
    tabsStore: PropTypes.shape({}).isRequired,
    view: PropTypes.bool,
};

EditObject.defaultProps = {
    useStores: () => null,
    view: false,
};

export default EditObject;
