import React from 'react';
import BaseSelect from './BaseSelect';

const DefaultSelect = (props) => (
    <BaseSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    />
);

export default DefaultSelect;
