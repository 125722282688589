import { action, computed, observable } from 'mobx';

class ObjectListPage {
    @observable selectedFilterTab = 0;

    @observable selected;

    baseHref = '';

    constructor(baseHref = '') {
        this.baseHref = baseHref;
    }

    @computed
    get isPresetsSelected() {
        return this.selectedFilterTab === 'presets';
    }

    @computed
    get activePresetId() {
        return this.selected?.item.id;
    }

    @action
    setTab(tab) {
        this.selectedFilterTab = tab;
    }

    @action
    setSelectedPreset(selected) {
        this.selected = selected;
    }

    reset() {
        this.selected = undefined;
        this.selectedFilterTab = 0;
    }
}

export default ObjectListPage;
