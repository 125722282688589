import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.ROW_SELECT,
    width: 4,
    sortName: null,
}, {
    ...TABLE_CELL.OBJECT_TYPE,
    width: 3,
    sortName: null,
}, {
    ...TABLE_CELL.STEAD_TYPE_NAME,
    width: 15,
    textAlign: 'center',
}, {
    ...TABLE_CELL.SHORT_DISTRICT_NAME,
    width: 7,
    fields: [{
        name: 'cityDistrictIds',
    }],
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 22,
    textAlign: 'left',
}, {
    ...TABLE_CELL.SQUARE_TOTAL,
    width: 12,
    sortName: null,
}, {
    ...TABLE_CELL.PRICE_DELIMITED,
    width: 7,
}, {
    ...TABLE_CELL.UNIT_PRICE_DELIMITED,
    width: 7,
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 15,
    sortName: null,
}, {
    ...TABLE_CELL.IMAGES,
    width: 5,
    sortName: null,
}];
