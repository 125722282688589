import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import styles from './Button.module.scss';

const CollapseButton = ({
    size,
    isOpen,
    className,
    ...props
}) => (
    <Button
        className={`
            ${styles['button--collapse']}
            ${isOpen ? styles['button--collapsed'] : ''}
            ${className}
        `}
        size={size}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        type="button"
    >
        {'\u0000'}
        <span className={styles.collapse}>
            <span className={styles.collapse__vertical} />
            <span className={styles.collapse__horizontal} />
        </span>
    </Button>
);

CollapseButton.SIZE = {
    ...Button.SIZE,
};

CollapseButton.COLOR = {
    ...Button.COLOR,
};

CollapseButton.propTypes = {
    isOpen: PropTypes.bool,
    ...Button.propTypes,
};

CollapseButton.defaultProps = {
    isOpen: false,
    ...Button.defaultProps,
    size: CollapseButton.SIZE.SMALL,
};

export default CollapseButton;
