import { arrayNaturalSort } from '../../../../utils/sortUtils/naturalSorting';
import SearchableDirectory from './SearchableDirectory';

class StreetDirectory extends SearchableDirectory {
    minQueryLength = 0;

    loadData(query = '', ctx = {}) {
        return super.loadData(query, ctx)
            .then((response) => {
                let list = response.map(({
                    value,
                    label,
                    extra: { streetType: { shortname }, ...extra },
                }) => ({
                    value,
                    extra,
                    label: `${shortname ? `${shortname} ` : ''}${label}`,
                }));

                if (this.sortOptions?.applySort) {
                    list = arrayNaturalSort(list, this.sortOptions.sortFieldName);
                }

                return Promise.resolve(list);
            })
            .catch(() => Promise.reject());
    }
}

export default StreetDirectory;
