import React from 'react';
import Dialog from '../../shared/Dialog';
import useStores from '../../../hooks/operator/use-stores';

const DialogWithStore = (props) => (
    <Dialog
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        useStores={useStores}
    />
);

export default DialogWithStore;
