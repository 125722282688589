import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './PresetList.module.scss';

const EditField = ({
    label,
    id,
    hidden,
    children,
}) => {
    if (hidden) return null;
    return (
        <div className={`${styles['preset-edit__field']} ${hidden ? 'hidden' : ''}`}>
            {
                label
                    ? (
                        <Label
                            className={styles['preset-edit__label']}
                            htmlFor={id}
                        >
                            {label}
                        </Label>
                    )
                    : null
            }
            {children}
        </div>
    );
};

EditField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    hidden: PropTypes.bool,
    children: PropTypes.element.isRequired,
};

EditField.defaultProps = {
    label: '',
    hidden: false,
};

export default EditField;
