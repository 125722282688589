export default [{
    type: 'fieldset',
    name: 'exportType',
    fields: [{
        type: 'fieldgroup',
        name: 'exportTypeFormat',
        label: 'Формат',
        fields: [{
            type: 'radioList',
            name: 'exportTypeId',
            size: 'lg',
            options: [],
        }],
    }, {
        type: 'fieldgroup',
        name: 'exportTypeParams',
        label: 'Что экспортировать',
        fields: [{
            type: 'radioList',
            name: 'exportTarget',
            size: 'lg',
            value: 'all',
            rows: 3,
            options: [{
                value: 'all',
                label: 'Все объекты',
            }, {
                value: 'selected',
                label: 'Выделенные объекты',
            }, {
                value: 'notebook',
                label: 'Записи из блокнта',
            }],
        }, {
            type: 'select',
            name: 'notebookId',
            label: 'Блокнот',
            size: 'md',
            options: [],
        }, {
            type: 'select',
            name: 'exportAttributes',
            label: 'Способ выгрузки',
            size: 'md',
            options: [],
        }],
    }],
}, {
    type: 'fieldset',
    name: 'exportExtraParams',
    fields: [{
        type: 'fieldgroup',
        name: 'exportExtraParams',
        label: 'Дополнительные параметры',
        description: ' ',
        fields: [{
            name: 'password',
            type: 'password',
            label: 'Пароль',
            rules: 'required|string',
            size: 'lg',
        }, {
            name: 'company',
            type: 'text',
            label: 'Название компании',
            rules: 'required|string',
            size: 'lg',
        }, {
            name: 'name',
            type: 'text',
            label: 'Имя риэлтора',
            rules: 'required|string',
            size: 'lg',
        }, {
            name: 'phone',
            type: 'phone',
            label: 'Номер телефона',
            rules: 'required|phone',
            size: 'lg',
        }, {
            type: 'checkbox',
            name: 'no-photo',
            label: 'Без фотографий',
            size: 'md',
        }, {
            type: 'checkbox',
            name: 'noContactNames',
            label: 'Убрать имя из контакта',
            size: 'md',
        }, {
            type: 'checkbox',
            name: 'addSource',
            label: 'Добавить ссылку на источник',
            size: 'md',
        }, {
            type: 'checkbox',
            name: 'addNotes',
            label: 'Добавить личные заметки',
            size: 'md',
        }, {
            type: 'checkbox',
            name: 'addText',
            label: 'Добавить текст объявления',
            size: 'md',
        }],
    }, {
        type: 'fieldgroup',
        name: 'exportExtraParams',
        fields: [{
            type: 'checkbox',
            name: 'ignoreIncorrect',
            label: 'Не экспортировать некорректные объекты',
            size: 'md',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'exportFields',
    fields: [{
        type: 'fieldgroup',
        name: 'exportFields',
        label: 'Поля',
        fields: [{
            type: 'select',
            name: 'dealType',
            size: 'md',
            label: 'Типы сделки и объекта',
            options: [],
        }, {
            type: 'fieldgroup',
            name: 'exportFieldsList',
            fields: [{
                type: 'checkboxList',
                name: 'fields',
                size: 'md',
                options: [],
            }],
        }],
    }],
}];
