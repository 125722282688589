/* global document */
import { Observer } from 'mobx-react';
import React from 'react';
import Container from '../../../components/Container';
import ChatBotQR from '../../shared/ChatBotQR';
import PriceStats from '../PriceStats';
import SVGIcon from '../../../components/Icons/SVGIcon';
import useStores from '../../../hooks/client/use-stores';
import Dialog from '../Dialog';
import PaymentFormModal from '../PaymentFormPage/PaymentFormModal';
import MortgageCalculator from '../../shared/MortgageCalculator';
import SocialNetworksDropdown from '../SocialNetworks/SocialNetworksDropdown';
import {
    CalcIcon,
    LogOutIcon,
    StatsIcon,
    TelegramIcon,
} from '../../../components/Icons/icons';

import styles from './Header.module.scss';

const Header = () => {
    const {
        appStore: { mortgageCalculator, priceStats },
        headerActions,
        sessionStore,
        dialogStore,
    } = useStores();
    const handleClick = (f) => (e) => {
        document.body.click();
        f(e);
    };
    const handleStatsClick = handleClick(() => dialogStore.show('stats'));
    const handleMortgageClick = handleClick(() => dialogStore.show('mortgage'));
    const handleTelegramBotClick = handleClick(() => dialogStore.show('telegram-bot'));
    const handlePaymentClick = handleClick(() => dialogStore.show('payment-dialog'));
    return (
        <div className={styles.header}>
            <Container>
                <div className={styles['header__action-list']}>
                    <div className={styles.header__dynamic}>
                        <Observer>
                            {() => (
                                headerActions.actions.map((action) => (
                                    <button
                                        type="button"
                                        className={`${styles.header__action} ${styles['header__action--dynamic']}`}
                                        onClick={handleClick(action.actionFunction)}
                                        key={`HeaderActions--${action.actionText}`}
                                    >
                                        <SVGIcon
                                            SVGElement={action.icon}
                                            className={`${styles.header__icon}`}
                                            height={14}
                                        />
                                        <span>
                                            {action.actionText}
                                        </span>
                                    </button>
                                ))
                            )}
                        </Observer>
                    </div>
                    <button
                        type="button"
                        className={`${styles.header__action} ${styles.logout}`}
                        onClick={() => sessionStore.logout()}
                    >
                        <SVGIcon
                            SVGElement={LogOutIcon}
                            className={`${styles.header__icon} ${styles.mortgage__icon}`}
                            height={12}
                        />
                        <span>
                            Выйти
                        </span>
                    </button>
                    <div className={`${styles.header__action} ${styles['header__action--social']}`}>
                        <SocialNetworksDropdown />
                    </div>
                    <button type="button" onClick={handleTelegramBotClick} className={`${styles.header__action} ${styles['header__action--telegram']}`}>
                        <SVGIcon
                            SVGElement={TelegramIcon}
                            className={`${styles.header__icon} ${styles.mortgage__icon}`}
                            height={15}
                        />
                        <span>
                            Чат-бот
                        </span>
                    </button>
                    {!!sessionStore.paymentInfo.paymentExpirationDate && (
                        <button
                            type="button"
                            onClick={handlePaymentClick}
                            className={`
                                ${styles.header__action}
                                ${styles['header__action--payment']}
                                ${sessionStore.paymentInfo.isTimeToPay ? styles['header__action--warning'] : ''}
                            `}
                        >
                            <span>
                                Оплачено&nbsp;до&nbsp;
                                <b>
                                    {sessionStore.paymentInfo
                                        .paymentExpirationDate.toLocaleDateString()}
                                </b>
                            </span>
                        </button>
                    )}
                    <button
                        type="button"
                        className={`${styles.header__action} ${styles.mortgage}`}
                        onClick={handleMortgageClick}
                    >
                        <SVGIcon
                            SVGElement={CalcIcon}
                            className={`${styles.header__icon} ${styles.mortgage__icon}`}
                            height={12}
                        />
                        <span>
                            Калькулятор
                        </span>
                    </button>
                    <button
                        type="button"
                        onClick={handleStatsClick}
                        className={styles.header__action}
                    >
                        <SVGIcon
                            height={12}
                            className={styles.header__icon}
                            SVGElement={StatsIcon}
                        />
                        <span>Анализ цен</span>
                    </button>
                </div>
            </Container>
            <Dialog
                name="mortgage"
                size="md"
                footer={null}
                className={styles['mortgage-dialog']}
                header={(
                    <div className={styles['mortgage-header']}>
                        <span>Калькулятор ипотеки</span>
                        <SVGIcon
                            SVGElement={CalcIcon}
                            height={24}
                            className={styles['mortgage-icon']}
                        />
                    </div>
                )}
            >
                <Observer>
                    {() => (
                        <MortgageCalculator
                            monthlyPayment={mortgageCalculator.monthlyPayment}
                            totalOverPayment={mortgageCalculator.totalOverPayment}
                            form={mortgageCalculator.form}
                            useStores={useStores}
                        />
                    )}
                </Observer>
            </Dialog>

            <ChatBotQR />

            <Dialog
                name="stats"
                size="xlg"
                footer={null}
                onOpen={() => priceStats.init()}
                className={styles['stats-dialog']}
                shouldBeClosed={(event) => (
                    event && event.target.className
                        ? (
                            typeof event.target.className === 'string'
                            && !(event.target.className.includes('DatePicker')
                             || event.target.className.includes('react-datepicker'))
                            && !(event.target.className.includes('DatePicker')
                             || event.target.className.includes('react-datepicker'))
                        )
                        : true
                )}
                header={(
                    <div className={styles['stats-header']}>
                        <span>Анализ цен</span>
                        <SVGIcon
                            SVGElement={StatsIcon}
                            height={24}
                            className={styles['stats-icon']}
                        />
                    </div>
                )}
            >
                <Observer>
                    {() => (
                        <PriceStats
                            useStores={useStores}
                            priceStats={priceStats}
                        />
                    )}
                </Observer>
            </Dialog>

            <PaymentFormModal />
        </div>
    );
};

export default Header;
