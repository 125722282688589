import isEmpty from './is-empty';

const nullRecursively = (data, reducer) => Object.keys(data).reduce(reducer, {});
const createReducer = (data) => (carry, key) => {
    const value = data[key];
    if (value instanceof Object && !isEmpty(value)) {
        if (value instanceof Array) {
            // eslint-disable-next-line no-param-reassign
            carry[key] = Object.values(nullRecursively(value, createReducer(value)));
        } else {
            // eslint-disable-next-line no-param-reassign
            carry[key] = nullRecursively(value, createReducer(value));
        }
        return carry;
    }
    if (value instanceof Array) {
        // eslint-disable-next-line no-param-reassign
        carry[key] = value;
    } else {
        // eslint-disable-next-line no-param-reassign
        carry[key] = isEmpty(value) ? null : value;
    }
    return carry;
};

const nullObject = (obj) => nullRecursively(obj, createReducer(obj));

export default nullObject;
