import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'location',
    fields: [{
        name: 'cityIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        multiple: true,
        clearable: true,
        searchable: true,
        withDefault: true,
        isDropdown: true,
    }, {
        name: 'cityDistrictIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'cityDistrictLandmarkIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT_LANDMARK,
        label: 'Ориентир',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset',
    name: 'stead',
    fields: [{
        name: 'objectCommercialTypeIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_COMMERCIAL_TYPE,
        label: 'Тип',
        rules: 'required',
        searchable: true,
        multiple: true,
        clearable: true,
    }, {
        name: 'square',
        type: 'fieldgroup',
        label: 'Площадь, сот',
        fields: [{
            name: 'totalAreaFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'totalAreaTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }, {
        name: 'floors',
        type: 'fieldgroup',
        size: 'sm',
        label: 'Этаж',
        fields: [{
            name: 'floorFrom',
            type: 'integer',
            prefix: 'от',
        }, {
            name: 'floorTo',
            type: 'integer',
            prefix: 'до',
        }],
    }],
}];
