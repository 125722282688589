import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'street',
    fields: [{
        name: 'cityId',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Нас. пункт',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required',
    }, {
        name: 'streetTypeId',
        type: 'directorySelect',
        directory: DIRECTORIES.STREET_TYPE,
        label: 'Тип улицы',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required',
    }, {
        name: 'name',
        type: 'text',
        label: 'Название улицы',
        size: 'md',
        rules: 'required|max:128',
    }, {
        name: 'aliases',
        size: 'md',
        rows: 5,
        type: 'textarea',
        label: 'Синонимы названия улицы',
        rules: 'max:512',
    }],
}];
