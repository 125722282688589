import { arrayNaturalSort } from '../../../../utils/sortUtils/naturalSorting';
import SearchableDirectory from './SearchableDirectory';

class StreetsFilterDirectory extends SearchableDirectory {
    minQueryLength = 0;

    constructor(...props) {
        super(...props, { sortOptions: { applySort: false } });
    }

    loadData(query = '', ctx = {}) {
        return super.loadData(query, ctx)
            .then((response) => {
                const uniqueStreets = [];
                let filteredStreets = [];
                response.forEach(({
                    value,
                    label,
                    extra: { streetType: { shortname }, ...extra },
                // eslint-disable-next-line array-callback-return
                }) => {
                    const streetLabel = `${shortname ? `${shortname} ` : ''}${label}`;
                    const streetIndex = uniqueStreets.indexOf(streetLabel);
                    if (streetIndex < 0) {
                        filteredStreets.push({
                            value,
                            extra,
                            streetName: label,
                            label: streetLabel,
                        });
                        uniqueStreets.push(streetLabel);
                    } else {
                        const streetIds = filteredStreets[streetIndex].value;
                        filteredStreets[streetIndex].value = streetIds instanceof Array
                            ? [...streetIds, value]
                            : [streetIds, value];
                    }
                });

                const streetsJK = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('жк ')), 'streetName');
                const streetsHouses = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('д. ')), 'streetName');
                const streetsDNP = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('днп ')), 'streetName');
                const streetsDNT = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('днт ')), 'streetName');
                const streetsPDK = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('пдк ')), 'streetName');
                const streetsSO = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('со ')), 'streetName');
                const streetsSTPoint = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('ст. ')), 'streetName');
                const streetsSTNoPoint = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('ст ')), 'streetName');
                const streetsSNT = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('снт ')), 'streetName');
                const streetsSPK = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('спк ')), 'streetName');
                const streetsTSN = arrayNaturalSort(filteredStreets.filter((street) => street.label.toLowerCase().startsWith('тсн ')), 'streetName');
                const streetsOther = arrayNaturalSort(filteredStreets.filter(
                    (street) => !streetsJK.includes(street)
                    && !streetsHouses.includes(street)
                    && !streetsDNP.includes(street)
                    && !streetsDNT.includes(street)
                    && !streetsPDK.includes(street)
                    && !streetsSO.includes(street)
                    && !streetsSTPoint.includes(street)
                    && !streetsSTNoPoint.includes(street)
                    && !streetsSNT.includes(street)
                    && !streetsSPK.includes(street)
                    && !streetsTSN.includes(street),
                ),
                'streetName');

                filteredStreets = streetsOther
                    .concat(streetsJK)
                    .concat(streetsHouses)
                    .concat(streetsDNP)
                    .concat(streetsDNT)
                    .concat(streetsPDK)
                    .concat(streetsSO)
                    .concat(streetsSTPoint)
                    .concat(streetsSTNoPoint)
                    .concat(streetsSNT)
                    .concat(streetsSPK)
                    .concat(streetsTSN);
                return Promise.resolve(filteredStreets);
            })
            .catch(() => Promise.reject());
    }
}

export default StreetsFilterDirectory;
