/* global window, Notification */
import defaultIcon from '../images/app-icon.png';

const defaults = {
    silent: true,
    icon: defaultIcon,
};

const spawnNotification = (
    message,
    options = defaults,
) => {
    if (!window.Notification) {
        return Promise.reject(new Error('Notification is not supported'));
    }
    if (Notification.permission === 'granted') {
        const notification = new Notification(message, { ...defaults, ...options });
        return Promise.resolve(notification);
    }
    if (Notification.permission !== 'denied') {
        Notification.requestPermission()
            .then(() => spawnNotification(message));
    }
    return Promise.resolve(null);
};

export default spawnNotification;
