const forEach = (fieldType, fields, fn) => {
    fields.forEach((field) => {
        const deeper = Boolean(field.fields);
        if (field.type === fieldType || (fieldType === 'field' && !deeper)) {
            fn(field);
        }
        if (deeper) {
            forEach(fieldType, field.fields, fn);
        }
    });
};

const forEachField = (fields, fn) => {
    forEach('field', fields, fn);
};

const forEachFieldset = (fields, fn) => {
    forEach('fieldset', fields, fn);
};

const forEachFieldGroup = (fields, fn) => {
    forEach('fieldgroup', fields, fn);
};

export default forEachField;
export {
    forEachFieldset,
    forEachFieldGroup,
};
