import Field from './DefaultField';
import { toE164 } from '../../../utils/phone';

class Contact extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw = []) => {
        const { providedOutput } = this;
        const value = raw && raw.map((item) => ({
            ...item,
            phone: toE164(item.phone),
        }));
        if (typeof providedOutput === 'function') {
            return providedOutput(value || '');
        }
        return value || [];
    }
}

export default Contact;
