import { action, computed, observable } from 'mobx';
import { EventEmitter } from '../../../../helpers/EventEmitter';
import DataSource, { STATES } from '../../DataSource';
import { arrayNaturalSort } from '../../../../utils/sortUtils/naturalSorting';

class SearchableDirectory extends EventEmitter {
    minQueryLength = 3;

    debounce = 300;

    requestId;

    sortOptions;

    @observable default;

    @observable message;

    /**
     * @param {string} directory наименование директории
     * @param {any} service сервис поиска
     * @param {object} sortOptions опции сортировки справочника
     * @param {boolean} sortOptions.applySort признак применения сортировки
        натуральной сортировки к результату. `true` - натуральная сортировка применяется,
        иначе - обычная сортировка
     * @param {boolean} sortOptions.sortFieldName - наименование поля в объекте для сортировки
     */
    constructor(directory, service, sortOptions = { applySort: true, sortFieldName: 'label' }) {
        super();
        this.directory = directory;
        this.dataSource = new DataSource(service, STATES.DEFAULT);
        this.sortOptions = sortOptions;
    }

    @computed
    get loading() {
        return this.dataSource.loading;
    }

    @action
    setDefault(defaultValue) {
        this.default = defaultValue;
    }

    @action
    setError(message) {
        this.message = message;
    }

    loadData(query = '', ctx = {}) {
        const {
            minQueryLength,
            debounce,
            requestId,
        } = this;
        this.setError('');
        if (minQueryLength && typeof query !== 'string') {
            throw new Error(`Search query must be a string, got: ${typeof query}`);
        }
        if (query.length < minQueryLength) {
            return Promise.reject();
        }
        if (requestId) {
            clearTimeout(requestId);
        }
        this.dataSource.setPreload();
        return new Promise((resolve, reject) => {
            this.requestId = setTimeout(() => {
                this.dataSource.search(this.directory, query, ctx)
                    .then((response) => {
                        let list = response.map((res) => {
                            const label = (this.directory === 'agency'
                                ? (res.extra?.aliases?.map((a) => a.name).join('; ') || this.directory)
                                : res.label)
                                .trim();

                            return {
                                ...res,
                                label,
                            };
                        });

                        if (this.sortOptions?.applySort) {
                            list = arrayNaturalSort(
                                list,
                                this.sortOptions.sortFieldName,
                            );
                        }

                        this.setError('');
                        this.requestId = 0;
                        resolve(list);
                    })
                    .catch((e) => {
                        this.setError(e.message);
                        reject(e);
                    });
            }, debounce);
        });
    }
}

export default SearchableDirectory;
