import { action, computed, observable } from 'mobx';

class Cursor {
    nextPage;

    @observable totalItems = 0;

    @observable newItems = 0;

    @computed
    get data() {
        return {
            totalItems: this.totalItems,
            newItems: this.newItems,
        };
    }

    @computed
    get isEnd() {
        return this.nextPage === null;
    }

    handleResponse({
        meta: {
            next,
            itemsTotal,
        },
        data,
    }) {
        this.setPaginationData(next, itemsTotal);
        return Promise.resolve(data);
    }

    // eslint-disable-next-line class-methods-use-this
    prepareRequest(...request) {
        const { nextPage } = this;
        return Promise.resolve([nextPage, ...request]);
    }

    @action
    setPaginationData(newNextPage, itemsTotal) {
        const { nextPage, totalItems: currentItemsTotal } = this;
        const isFirstLoad = nextPage !== undefined;
        this.nextPage = newNextPage;
        if (itemsTotal !== undefined) {
            this.totalItems = itemsTotal;
        }
        if (isFirstLoad) {
            this.newItems = itemsTotal - currentItemsTotal;
        } else {
            this.newItems = 0;
        }
    }
}

export default Cursor;
