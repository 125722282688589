import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Container from '../../../components/Container';
import { createBreadcrumbs } from '../../../routes/utils';
import Header from '../Header';

import styles from './Root.module.scss';

Modal.setAppElement('#root');

const Root = ({ routes, children }) => {
    const { pathname } = useLocation();
    const activeRootPage = routes.find(({ link }) => pathname.includes(link));
    const breadcrumbs = createBreadcrumbs(pathname, routes);
    if (!activeRootPage) return children;
    return (
        <div className={styles.root}>
            <Header />
            <Container>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <div className={styles.content}>
                    {children}
                </div>
            </Container>
        </div>
    );
};

Root.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
            icon: PropTypes.func,
        }),
    ),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
};

Root.defaultProps = {
    routes: [],
    children: null,
};

export default Root;
