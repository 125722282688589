import PropTypes from 'prop-types';

const selectType = PropTypes.shape({
    label: PropTypes.string,
});

export const propTypes = {
    store: PropTypes.shape({
        memoKey: PropTypes.number,
        loading: PropTypes.bool,
        id: PropTypes.string,
        objectName: PropTypes.string,
        flatType: selectType,
        roomType: selectType,
        developer: selectType,
        objectCondition: selectType,
        furnishing: selectType,
        balcony: selectType,
        windowType: selectType,
        personsPerLavatory: PropTypes.string,
        lavatory: selectType,
        status: selectType,
        reason: selectType,
        publicNote: PropTypes.string,
        privateNote: PropTypes.string,
        userNote: PropTypes.string,
        company: PropTypes.string,
        comment: PropTypes.string,
        phones: PropTypes.arrayOf(PropTypes.shape({})),
        createdAt: PropTypes.string,
        checkedAt: PropTypes.string,
    }),
    style: PropTypes.shape({}),
    setSelectedHeight: PropTypes.func,
    onPhoneClick: PropTypes.func,
};

export const defaultProps = {
    store: {},
    style: {},
    onPhoneClick: null,
    setSelectedHeight: () => null,
};
