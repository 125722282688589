import React from 'react';
import PropTypes from 'prop-types';
import { ButtonLink, Button } from '../../../components/Button';
import FormElements from '../Form';

import styles from './ObjectComponent.module.scss';
import Label from '../../../components/Label';
import { CrossIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';

const ObjectComponentSources = ({
    sources,
    view,
    extraAdsForm,
    withActions,
    // eslint-disable-next-line no-unused-vars
    onSourceDelete,
}) => {
    const createDeleteHandler = (sourceId) => (e) => {
        e.preventDefault();
        const { target: button } = e;
        button.disabled = true;
        onSourceDelete(sourceId).then((success) => {
            if (!success) {
                button.disabled = false;
            }
        });
    };

    const handleExtraAddEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
            extraAdsForm.onSubmit(e);
            return false;
        }
        return true;
    };

    return (
        <div className={`${styles.sources}`}>
            <h2 className={styles['block-title']}>
                <span>Источники</span>
            </h2>
            <div className={`${styles.sources__container} ${(withActions ? styles['sources__container--with-actions'] : null)}`}>
                {
                    sources && sources.length
                        ? sources.map((sourceItem, index) => (
                            <div
                                className={styles.source__item}
                                key={`${sourceItem.url}-${index + 1}`}
                            >
                                {
                                    sourceItem.src
                                        ? (
                                            <div className={styles['source__item-img']}>
                                                <img
                                                    src={sourceItem.src}
                                                    alt={sourceItem.src}
                                                />
                                            </div>
                                        )
                                        : (
                                            <div className={styles['source__item-posted']}>
                                                {sourceItem.name}
                                            </div>
                                        )
                                }
                                <div className={styles['source__item-link-container']}>
                                    <ButtonLink
                                        href={sourceItem.url}
                                        rel="noopener noreferrer"
                                        color="white-blue"
                                        size="sm"
                                        className={styles['source__item-link']}
                                    >
                                        Открыть страницу
                                    </ButtonLink>
                                </div>
                                <div className={styles['source__item-posted']}>
                                    <div>Выложено</div>
                                    {sourceItem.posted}
                                </div>
                                <div className={styles['source__item-updated']}>
                                    <div>Обновлено</div>
                                    {sourceItem.updated}
                                </div>
                                {
                                    withActions ? (
                                        <div className={styles['source__item-actions']}>
                                            <button
                                                type="button"
                                                className={styles['source__item-delete']}
                                                onClick={createDeleteHandler(sourceItem.id)}
                                            >
                                                <SVGIcon
                                                    SVGElement={CrossIcon}
                                                    width={11}
                                                    height={11}
                                                />
                                            </button>
                                        </div>
                                    ) : null
                                }
                            </div>
                        ))
                        : null
                }
            </div>
            { !view && extraAdsForm && (
                <div className={styles.sources__add}>
                    <Label className={styles.sources__label}>
                        Ссылка на источник
                    </Label>
                    {/* eslint-disable-next-line react/no-unknown-property */}
                    <div onKeyPressCapture={handleExtraAddEnterPress}>
                        <FormElements
                            form={extraAdsForm}
                            elements={extraAdsForm.render || []}
                            onKeyPress={handleExtraAddEnterPress}
                        />
                    </div>
                    <Button
                        type={Button.TYPE.BUTTON}
                        color={Button.COLOR.WHITE_BLUE}
                        size={Button.SIZE.SMALL}
                        onClick={extraAdsForm.onSubmit}
                    >
                        Добавить
                    </Button>

                </div>
            )}
        </div>
    );
};

ObjectComponentSources.propTypes = {
    sources: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            url: PropTypes.string,
            posted: PropTypes.string,
            updated: PropTypes.string,
        }),
    ),
    view: PropTypes.bool,
    extraAdsForm: PropTypes.shape({
        render: PropTypes.func,
        onSubmit: PropTypes.func,
    }),
    withActions: PropTypes.bool,
    onSourceDelete: PropTypes.func,
};

ObjectComponentSources.defaultProps = {
    sources: [],
    view: false,
    extraAdsForm: undefined,
    withActions: false,
    onSourceDelete: () => Promise.resolve(null),
};

export default ObjectComponentSources;
