import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useStores from '../../../hooks/client/use-stores';
import LoginPage from '../../shared/LoginPage';
import { ButtonLink } from '../../../components/Button';

import registrationStyles from './Register.module.scss';

const ResetPasswordAttention = () => {
    const history = useHistory();
    const { resetPasswordStore = {} } = useStores();
    const { requestForm = { $: () => null } } = resetPasswordStore;
    useEffect(() => {
        if (requestForm && requestForm.on) {
            return requestForm.on('done', () => {
                history.push('attention');
            });
        }
        return undefined;
    }, [requestForm]);
    return (
        <LoginPage
            title={(
                <div className={registrationStyles.reset__header}>
                    Восстановление пароля
                </div>
            )}
            className={registrationStyles.reset}
        >
            <>
                <div className={registrationStyles.reset__attention}>
                    Мы выслали код на вашу почту
                    <br />
                    <br />
                    Вы можете ввести его в следующем окне или перейти по ссылке из письма
                    <br />
                    <br />
                </div>
                <ButtonLink
                    Component={Link}
                    size={ButtonLink.SIZE.LARGE}
                    color={ButtonLink.COLOR.BLUE}
                    href="password"
                    target="_self"
                    block
                >
                    Продолжить
                </ButtonLink>
            </>
        </LoginPage>
    );
};

export default ResetPasswordAttention;
