import lastId from './last-id';

const reduceFields = (carry, field) => {
    const { type, fields = [] } = field;
    switch (type) {
        case 'fieldset':
        case 'fieldgroup':
        case 'inputList':
            carry.concat(fields.reduce(reduceFields, carry));
            break;
        default:
            carry.push(field);
    }
    return carry;
};

const createFields = (fields = [], bindings, props) => {
    const flatFields = fields.reduce(reduceFields, []);
    return flatFields.map((field) => {
        const { type = 'text', name } = field;
        const keys = Object.keys(field);
        const propsKeys = Object.keys(props);
        const defaultKeys = keys.filter((key) => propsKeys.includes(key));
        const extraKeys = keys.filter((key) => !propsKeys.includes(key));
        const defaults = defaultKeys.reduce((carry, key) => ({ ...carry, [key]: field[key] }), {});
        const extra = extraKeys.reduce((carry, key) => ({ ...carry, [key]: field[key] }), {});
        const id = lastId(name);
        return {
            ...defaults,
            extra,
            id,
            bindings: bindings[type]
                ? type
                : undefined,
        };
    });
};

export default createFields;
