import { computed } from 'mobx';
import DataSource, { STATES } from '../../../shared/DataSource';
import { ContactService } from '../../../../services/operator';

class Contact {
    minQueryLength = 3;

    constructor() {
        this.dataSource = new DataSource(ContactService, STATES.DEFAULT);
    }

    @computed
    get loading() {
        return this.dataSource.loading;
    }

    searchByPhone(query, phoneType) {
        const { minQueryLength } = this;
        if (query.length < minQueryLength) return Promise.reject();
        return this.dataSource.findContactByPhone(query, phoneType)
            .then((response) => (
                Promise.resolve(response || [])
            ));
    }
}

export default Contact;
