/* global window */
import { useState, useEffect } from 'react';

const getSize = (isClient) => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
});

// Hook for getting size window with handle resize
const useWindowSizeWithHandler = () => {
    const isClient = typeof window === 'object';
    const [windowSize, setWindowSize] = useState(getSize(isClient));
    const handleResize = () => {
        setWindowSize(getSize(isClient));
    };
    useEffect(() => {
        if (!isClient) {
            return false;
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return windowSize;
};

// Hook for getting size window without handle resize
const useWindowSize = () => {
    const isClient = typeof window === 'object';
    return getSize(isClient);
};

export default useWindowSize;

export {
    useWindowSize,
    useWindowSizeWithHandler,
};
