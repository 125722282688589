import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'street',
    fields: [{
        name: 'objectStatusId',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_STATUS,
        label: 'Статус',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required',
    }, {
        name: 'name',
        type: 'text',
        label: 'Причина',
        size: 'md',
        rules: 'required|max:128',
    }],
}];
