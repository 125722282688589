import flats from '../../shared/objects-filter/flats';
import houses from '../../shared/objects-filter/houses';
import steads from '../../shared/objects-filter/steads';
import commercial from '../../shared/objects-filter/commercial';
import { reduceFieldsets } from '../../utils/reduce-fields';
import statusGroups from './status-groups';
import exclusive from './exclusive';
import dateAdded from '../../shared/objects-filter/date-added';
import dateCreated from '../../shared/objects-filter/date-created';
import ownerType from './owner-type';
import note from './note';
import stove from './stove';
import { OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT } from '../../../config/client';
import forms from '../../shared/objects-filter';

const addFloorCountField = (fieldset) => {
    const { name } = fieldset;
    switch (name) {
        case 'floor':
            return {
                ...fieldset,
                fields: fieldset.fields.splice(1, 0, {
                    name: 'floorCount',
                    type: 'fieldgroup',
                    label: 'Этажность',
                    fields: [{
                        name: 'floorCountFrom',
                        type: 'integer',
                        prefix: 'от',
                    }, {
                        name: 'floorCountTo',
                        type: 'integer',
                        prefix: 'до',
                    }],
                }),
            };
        default:
            return fieldset;
    }
};

const addStoveField = (fieldset) => {
    const { name } = fieldset;
    switch (name) {
        case 'chars':
            return {
                ...fieldset,
                fields: fieldset.fields.splice(2, 0, stove),
            };
        default:
            return fieldset;
    }
};

const statusGroupFields = [statusGroups];

const dateField = OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT
    ? dateAdded
    : dateCreated;

export default {
    flats: reduceFieldsets(
        reduceFieldsets(
            [forms.sourceBoard,
                ...statusGroupFields, exclusive, ownerType, ...flats, dateField, note],
            addFloorCountField,
        ),
        addStoveField,
    ),
    houses: reduceFieldsets(
        [forms.sourceBoard,
            ...statusGroupFields, exclusive, ownerType, ...houses, dateField, note],
        addFloorCountField,
    ),
    steads: reduceFieldsets(
        [forms.sourceBoard,
            ...statusGroupFields, exclusive, ownerType, ...steads, dateField, note],
        addFloorCountField,
    ),
    commercial: reduceFieldsets(
        [forms.sourceBoard,
            ...statusGroupFields, exclusive, ownerType, ...commercial, dateField, note],
        addFloorCountField,
    ),
};
