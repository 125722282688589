import React from 'react';
import PropTypes from 'prop-types';
import ImageSlider from '../../../components/ImageSlider/ImageSlider';
import Dialog from '../Dialog';

import styles from './ObjectImagesDialog.module.scss';

const HeaderWrapper = ({ children }) => (
    <div className={`${styles['slider-dialog__header']}`}>
        {children}
    </div>
);

HeaderWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

const BodyWrapper = ({ children }) => (
    <div className={`${styles['slider-dialog__body']}`}>
        {children}
    </div>
);

BodyWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

const ObjectImagesDialog = ({
    name,
    header,
    sources,
    relatedId,
    useStores,
    onImageDownload,
}) => (
    <Dialog
        useStores={useStores}
        name={name}
        size="lg"
        footer={null}
        header={header}
        components={{
            HeaderWrapper,
            BodyWrapper,
        }}
    >
        {({ initSlide }) => (
            <ImageSlider
                size={ImageSlider.SIZE.LARGE}
                arrows
                dots
                slides={sources}
                maxHeight={500}
                relatedId={relatedId}
                initSlide={initSlide}
                onImageDownload={onImageDownload}
            />
        )}
    </Dialog>
);

ObjectImagesDialog.propTypes = {
    useStores: PropTypes.func,
    name: PropTypes.string,
    header: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
        }),
    ),
    relatedId: PropTypes.string,
    onImageDownload: PropTypes.func,
};

ObjectImagesDialog.defaultProps = {
    useStores: () => ({ dialogStore: {} }),
    name: 'imageSlider',
    sources: [],
    relatedId: null,
    onImageDownload: () => {},
};

export default ObjectImagesDialog;
