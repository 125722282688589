import isEmpty from '../../utils/is-empty';

const createQuery = (ctx = {}) => (
    Object.keys(ctx)
        .filter((key) => Boolean(ctx[key]))
        .map((key) => (
            ctx[key] instanceof Array
                ? `${ctx[key].map((val) => `${key}=${encodeURIComponent(val)}`).join('&')}${ctx[key].length === 1 ? `&${key}=0` : ''}`
                : `${key}=${encodeURIComponent(ctx[key])}`
        ))
        .filter((item) => !isEmpty(item))
        .join('&')
);

export default createQuery;
