import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Preloader from '../Preloader';

import styles from './Selected.module.scss';

const SelectedWrapper = forwardRef(({
    loading,
    instantLoading,
    footer,
    style,
    children,
}, ref) => (
    <>
        <div className={styles.selected} style={style} data-loading={loading}>
            <div
                className={`
                    ${styles.selected__preloader}
                    ${loading ? `${styles['selected__preloader--loading']} ${instantLoading ? styles['selected__preloader--instant'] : ''}` : ''}
                `}
            >
                <div className={styles.preloader__inner}>
                    <Preloader
                        className={`${styles.preloader}`}
                    />
                </div>
            </div>
            <div ref={ref}>
                <div className={`${styles.selected__body} ${footer && styles['selected__body--with-footer']}`}>
                    {children}
                </div>
                {footer}
            </div>
        </div>
    </>
));

SelectedWrapper.propTypes = {
    loading: PropTypes.bool,
    instantLoading: PropTypes.bool,
    style: PropTypes.shape({}),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ]),
        ),
    ]),
    footer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ]),
        ),
    ]),
};

SelectedWrapper.defaultProps = {
    loading: true,
    instantLoading: false,
    style: {},
    children: null,
    footer: null,
};

export default SelectedWrapper;
