/* eslint linebreak-style: 0 */
/* eslint-disable */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import styles from './ItemStatus.module.scss';
import Dialog from '../../operator/Dialog';
import FormElements from '../Form';
import ItemStatusField from './ItemStatusField';
import DirectoryForm from '../../operator/DirectoryPage/DirectoryForm';
import spawnNotification from '../../../utils/spawn-notification';
import { Button, ButtonClose } from '../../../components/Button';
import { propTypes } from '../SelectedObject/propTypes';
import { ItemStatusModes } from './constants';
import './itemStatusWithContacts.scss';

/**
 * Item со статусом и контактом (две формы)
 * @param {Object} props
 * @param {Object} props.item - объект с информацией о текущем объекте
 */
const ItemStatusWithContacts = ({
    item,
    mode,
    useStores,
    onClose,
    onSave,
    showCloseButton = false,
}) => {
    const { dialogStore } = useStores();

    if (!item) {
        return null;
    }

    const form = useMemo(() => {
        switch (mode) {
            case ItemStatusModes.Edit: {
                return item.createObjectsEditForm();
            }
            case ItemStatusModes.Merge: {
                return item.createObjectsMergeForm();
            }
            default: {
                throw new Error('Неизвестный режим формы')
            }
        }
    }, [item]);
   
    const agencySelectorForm = item.createAgencySelectorForm();
    const agencyForm = item.createAgencyCreateForm();

    const handleSave = () => {
        form.submit();
        onSave();
    };

    const handleNewItemClick = () => {
        const unsubscribeForm = agencyForm.on('done', () => {
            dialogStore.hide('new-agency-item');
            spawnNotification('Агенство добавлено в справочник');
        });

        const unsubscribeDialog = dialogStore.on('new-agency-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });

        dialogStore.show('new-agency-item');
    };

    const formTitle = useMemo(() => {
        switch (mode) {
            case ItemStatusModes.Edit: {
                return 'Редактирование';
            };
            case ItemStatusModes.Merge: {
                return 'Объединение';
            };
            default: {
                throw new Error('Неизвестный режим формы')
            }
        }
    }, [mode])

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div
            className="item-status-with-contact"
            role="dialog"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={() => {}}
        >
            <div className={`item-status ${styles['item-status']}`}>
                <div className='title'>
                    {formTitle ?? ''}
                    {showCloseButton && <ButtonClose onClick={onClose}/>}
                </div>
                <div className={styles['item-status__field-list']}>
                    <div id="item-status-contact-container" className={styles['item-status__contact-container']}>
                        <div className="agency-selector">
                            <form onSubmit={agencySelectorForm.onSubmit} className={styles['item-status__contact-form']}>
                                <FormElements
                                    form={agencySelectorForm}
                                    elements={agencySelectorForm.render}
                                    useStores={useStores}
                                    components={{
                                        FieldWrapper: ItemStatusField,
                                    }}
                                />
                            </form>
                            <Button
                                type={Button.TYPE.BUTTON}
                                size={Button.SIZE.MEDIUM}
                                color={Button.COLOR.BLUE}
                                onClick={handleNewItemClick}
                                disabled={form.loading}
                                className={`agency-select-button ${styles['item-status__contact-submit']}`}
                            >
                                Добавить
                            </Button>
                        </div>

                        <form onSubmit={form.onSubmit} className={styles['item-status__contact-form']}>
                            <FormElements
                                form={form}
                                elements={form.render}
                                useStores={useStores}
                                components={{
                                    FieldWrapper: ItemStatusField,
                                }}
                            />
                        </form>
                    </div>
                </div>
                <div className={styles['item-status__submit']}>
                    <Observer>
                        {() => (
                            <Button
                                type={Button.TYPE.BUTTON}
                                size={Button.SIZE.SMALL}
                                color={Button.COLOR.BLUE}
                                onClick={handleSave}
                                disabled={form.loading}
                            >
                                Сохранить изменения
                            </Button>
                        )}
                    </Observer>
                </div>
            </div>

            <Dialog
                name="new-agency-item"
                size="sm"
                footer={null}
                header={<>Создать агенство</>}
            >
                <Observer>
                    {() => (
                        <DirectoryForm
                            button="Добавить"
                            form={agencyForm}
                        />
                    )}
                </Observer>
            </Dialog>
        </div>
    );
};

ItemStatusWithContacts.propTypes = {
    item: propTypes.store.isRequired,
    form: PropTypes.shape({
        submit: PropTypes.func,
        onSubmit: PropTypes.func,
        render: PropTypes.shape([]),
        loading: PropTypes.bool,
    }).isRequired,
    useStores: PropTypes.func.isRequired,
};

ItemStatusWithContacts.defaultProps = {
};

export default ItemStatusWithContacts;
