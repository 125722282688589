export default [{
    name: 'code',
    type: 'text',
    label: 'Код',
    rules: 'required|string',
    size: 'lg',
    tooltip: 'Это код из письма, введите его тут или перейдите по ссылке из письма',
}, {
    name: 'password',
    type: 'password',
    label: 'Пароль',
    rules: 'required|string|min:8|regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d){8,}/',
    size: 'lg',
}, {
    name: 'passwordConfirm',
    type: 'password',
    label: 'Пароль еще раз',
    rules: 'required|string|same:password',
    size: 'lg',
}];
