/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint linebreak-style: 0 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { SOURCE_LIST } from '../../../constants/shared';
import ReactTooltip from '../../../lib/react-tooltip';
import { CrossIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';
import extractBoardNameFromUrl from '../../../utils/extract-board-name-from-url';
import styles from './Table.module.scss';

const SourceListTooltip = ({ store }) => {
    const tooltipEl = useRef(null);

    const getContent = (id) => {
        if (!id || !store.getItem(id)) {
            return null;
        }

        const ads = store.getItem(id)
            .ad
            .toSorted((a, b) => {
                const getKey = (elem) => elem.updatedAt ?? elem.createdAt ?? '';
                return getKey(b)
                    .localeCompare(getKey(a));
            });

        const closeTooltipButton = (
            <button
                type="button"
                className={styles['close-sources-tooltip-button']}
                onClick={() => {
                    if (tooltipEl.current?.tooltipRef) {
                        tooltipEl.current.tooltipRef = null;
                    }
                    ReactTooltip.hide();
                }}
            >
                <SVGIcon SVGElement={CrossIcon} height={12} />
            </button>
        );

        return (
            <div
                onClick={(e) => e.stopPropagation()}
                onContextMenu={(e) => e.stopPropagation()}
            >
                {closeTooltipButton}
                <div>
                    <strong>Источники:</strong>
                    <ul>
                        {
                            ads.map((ad) => (
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={ad?.url}
                                    >
                                        {ad?.board?.name ?? extractBoardNameFromUrl(ad?.url)}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <ReactTooltip
            ref={tooltipEl}
            id={`${SOURCE_LIST}-${store.listId}`}
            clickable
            bodyMode
            event="click"
            globalEventOff="click"
            getContent={getContent}
        />
    );
};

SourceListTooltip.propTypes = {
    store: PropTypes.shape({
        getItem: PropTypes.func,
        listId: PropTypes.string,
    }).isRequired,
};

SourceListTooltip.defaultProps = {};

export default SourceListTooltip;
