import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import useStores from '../../../hooks/client/use-stores';
import LoginPage, { LoginPageField } from '../../shared/LoginPage';
import { Button, ButtonLink } from '../../../components/Button';
import FormElements from '../Form';

import styles from '../../shared/LoginPage/LoginPage.module.scss';
import registrationStyles from './Register.module.scss';

const ResetPasswordRequest = () => {
    const history = useHistory();
    const { resetPasswordStore = {} } = useStores();
    const { requestForm = { $: () => null } } = resetPasswordStore;
    useEffect(() => {
        if (requestForm && requestForm.on) {
            return requestForm.on('done', ([response]) => {
                if (response) {
                    history.push('attention');
                }
            });
        }
        return undefined;
    }, [requestForm]);
    return (
        <LoginPage
            title={(
                <div className={registrationStyles.reset__header}>
                    Восстановление пароля
                </div>
            )}
            className={registrationStyles.reset}
            footer={(
                <ButtonLink
                    Component={Link}
                    size={ButtonLink.SIZE.MEDIUM}
                    color={ButtonLink.COLOR.TRANSPARENT}
                    href="/register"
                    target="_self"
                >
                    Регистрация
                </ButtonLink>
            )}
        >
            <Observer>
                {() => (
                    <form
                        onSubmit={requestForm.onSubmit}
                    >
                        <FormElements
                            form={requestForm}
                            elements={requestForm.render || []}
                            components={{
                                FieldWrapper: LoginPageField,
                            }}
                        />
                        <div className={styles.login__submit}>
                            <Button
                                type="submit"
                                color={Button.COLOR.BLUE}
                                size={Button.SIZE.LARGE}
                                block
                                disabled={requestForm.loading}
                            >
                                Восстановить пароль
                            </Button>
                        </div>
                        <div className={styles.login__forgot}>
                            <Link className="link" to="/login">Я вспомнил! Ко входу</Link>
                        </div>
                    </form>
                )}
            </Observer>
        </LoginPage>
    );
};

export default ResetPasswordRequest;
