import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import numberWithSpaces from '../../utils/number-with-spaces';
import { SIZE } from '../../constants/shared';

import styles from './RangeInput.module.scss';

const RangeInput = ({
    name,
    id,
    size,
    readOnly,
    disabled,
    label,
    min,
    max,
    value,
    measure,
    measureStart,
    measureEnd,
    showLabels,
    className,
    step,
    onChange,
}) => {
    // vars
    const [inputValue, setInputValue] = useState(value);
    const spacedInputValue = numberWithSpaces(inputValue);
    // helpers
    // eslint-disable-next-line no-bitwise
    const roundly = (num) => (num === (num ^ 0) ? num : num.toFixed(1));
    const toNum = (str) => (str ? str.replace(/[^0-9.,]*/g, '') : '');
    const formatLabel = (num, type) => {
        if (!showLabels) {
            return '';
        }
        const output = numberWithSpaces(num);
        switch (type) {
            case 'min':
                return `${output} ${measureStart || measure || ''}`;
            case 'max':
                return `${output} ${measureEnd || measure || ''}`;
            default:
                return `${output} ${measure}`;
        }
    };
    // interactions
    const handleChange = (num) => {
        let roundedValue = roundly(Number(num) || 0);
        roundedValue = Math.max(Math.min(roundedValue, max), min);
        if (onChange && typeof onChange === 'function') {
            setInputValue(roundedValue);
            onChange(roundedValue);
        }
    };
    const handleInputChange = (e) => {
        const { target: { value: val } } = e;
        setInputValue(toNum(val));
    };
    const handleInputBlur = (e) => {
        const { target: { value: val } } = e;
        handleChange(toNum(val.replace(/,/, '.')));
    };
    const handleInputSubmit = (e) => {
        const { which, target: { value: val } } = e;
        if (which === 13) {
            handleChange(toNum(val.replace(/,/, '.')));
        }
    };
    // effects
    useEffect(() => {
        handleChange(value);
    }, []);
    useEffect(() => {
        setInputValue(value);
    }, [value]);
    const classNames = {
        track: styles.range__track,
        activeTrack: styles['range__track--active'],
        disabledInputRange: `${styles.range} ${styles['range__track--disabled']}`,
        inputRange: styles.range,
        labelContainer: styles.range__label,
        maxLabel: styles['range__label--max'],
        minLabel: styles['range__label--min'],
        slider: styles.range__slider,
        sliderContainer: styles['range__slider-container'],
        valueLabel: styles['range__label--value'],
    };

    return (
        <div
            className={`
                ${styles['input-range']}
                ${styles[`input-range--${size}`]}
                ${disabled ? styles['input-range--disabled'] : ''}
                ${readOnly ? styles['input-range--readonly'] : ''}
                ${className || ''}
            `}
        >
            <label htmlFor={id} className={styles['input-range__label']}>
                <div className={styles['input-range__value']}>
                    <input
                        id={id}
                        value={spacedInputValue}
                        size={spacedInputValue.length || 1}
                        className={`
                            ${styles['input-range__input']}
                            ${measure ? styles['input-range__input--measure'] : ''}
                        `}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyDown={handleInputSubmit}
                    />
                    <div className={styles['input-range__measure']}>
                        {measure}
                    </div>
                </div>
                <div className={styles['input-range__title']}>
                    {label}
                </div>
            </label>
            <InputRange
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                maxValue={max}
                minValue={min}
                step={step}
                value={value}
                onChange={handleChange}
                classNames={classNames}
                formatLabel={formatLabel}
            />
        </div>
    );
};

RangeInput.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    size: PropTypes.oneOf(Object.values(SIZE)),
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number.isRequired,
    value: PropTypes.number,
    measure: PropTypes.string,
    measureStart: PropTypes.string,
    measureEnd: PropTypes.string,
    showLabels: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    step: PropTypes.number,
};

RangeInput.defaultProps = {
    id: '',
    size: SIZE.MEDIUM,
    readOnly: false,
    disabled: false,
    min: 0,
    value: 0,
    className: '',
    measure: '',
    measureStart: '',
    measureEnd: '',
    showLabels: true,
    onChange: () => null,
    step: 1,
};

export default RangeInput;
