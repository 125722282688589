const ROW_SELECT = {
    name: 'rowSelect',
    title: '\u0000',
    width: 4,
};
const CITY_NAME = {
    name: 'cityName',
    title: 'Нас. пункт',
    sortName: 'city',
};
const CITY_ALIASES = {
    name: 'aliases',
    title: 'Синонимы',
    sortName: 'aliases',
};
const DISTRICT_NAME = {
    name: 'districtName',
    title: 'Район',
    sortName: 'cityDistrict',
    tooltip: 'Район',
};
const DISTRICT_LANDMARK_NAME = {
    name: 'districtLandmarkName',
    title: 'Ориентир',
    sortName: 'cityDistrictLandmark',
};
const SHORT_DISTRICT_NAME = {
    ...DISTRICT_NAME,
    name: 'shortDistrictName',
};
const STREET_NAME = {
    name: 'streetName',
    title: 'Улица',
    sortName: 'street',
    tooltip: 'Улица',
};
const STREET_NAME_WITH_COMPLEX_NAME = {
    ...STREET_NAME,
    name: 'streetNameWithComplexName',
    title: 'Улица/ЖК',
};
const HOUSE_NUMBER = {
    name: 'houseNumber',
    title: 'Номер дома',
    sortName: 'house',
    tooltip: 'Номер дома',
};
const STRUCTURE_NUMBER = {
    name: 'structureNumber',
    title: 'Строение',
};
const BUILDING_NUMBER = {
    name: 'buildingNumber',
    title: 'Корпус',
};
const HOUSE_TYPE_NAME = {
    name: 'houseTypeName',
    title: 'Тип дома',
    sortName: 'objectHouseType',
    tooltip: 'Тип дома',
};
const REGION_NAME = {
    name: 'regionName',
    title: 'Субъект',
    sortName: 'region',
};
const AREA_NAME = {
    name: 'areaName',
    title: 'Район субъекта',
    sortName: 'area',
};
const AGENCY_NAME = {
    name: 'agencyName',
    title: 'Название агентства',
    sortName: 'agency',
    tooltip: 'Название агенства',
};
const OBJECT_STATUS_NAME = {
    name: 'objectStatusName',
    title: 'Статус объекта',
    sortName: 'status',
    tooltip: 'Статус объекта',
};
const OBJECT_TYPE = {
    name: 'objectType',
    title: 'Тип',
    className: 'object-type',
    tooltip: 'Тип Владельца',
};
const OBJECT_TYPE_NAME = {
    name: 'objectTypeName',
    title: 'Тип объекта',
    sortName: 'objectType',
    tooltip: 'Тип объекта',
};
const ROOMS_COUNT = {
    name: 'roomCount',
    title: 'Комнат',
    sortName: 'roomCount',
    tooltip: 'Кол-во Комнат',
};
const FLOOR_COUNT = {
    name: 'floorCount',
    title: 'Этажность',
    sortName: 'floor',
};
const FLOOR_COUNT_WITH_WALL_MATERIAL = {
    name: 'floorCountWithWallMaterial',
    title: 'Этажность/Материал стен',
    sortName: 'floor',
    tooltip: 'Этажность/Материал стен',
};
const FLOOR_WITH_FLOOR_COUNT_WITH_WALL_MATERIAL = {
    name: 'floorWithFloorCountWithWallMaterial',
    title: 'Этаж/Этажность/Материал стен',
    sortName: 'floor',
    tooltip: 'Этаж/Этажность/Материал стен',
};
const SQUARE = {
    name: 'square',
    title: 'Площадь',
    sortName: 'totalArea',
    tooltip: 'Площадь',
};
const SQUARE_TOTAL = {
    ...SQUARE,
    name: 'squareTotal',
};
const PRICE = {
    name: 'price',
    title: 'Цена',
    sortName: 'price',
};
const UNIT_PRICE = {
    name: 'unitPrice',
    title: 'Цена, м²',
    sortName: 'unitPrice',
};
const PRICE_DELIMITED = {
    name: 'priceDelimited',
    title: 'Цена',
    sortName: 'price',
    tooltip: 'Цена',
};
const UNIT_PRICE_DELIMITED = {
    name: 'unitPriceDelimited',
    title: 'Цена, м²',
    sortName: 'unitPrice',
    tooltip: 'Цена, м²',
};
const SERIES_NAME = {
    name: 'seriesName',
    title: 'Серия',
    sortName: 'series',
    tooltip: 'Серия',
};
const DATE_OF_COMPLETION = {
    name: 'dateOfCompletion',
    title: 'Год',
    sortName: 'yearOfCompletion',
    tooltip: 'Год',
};
const DATE_OF_CONSTRUCTION = {
    name: 'dateOfConstruction',
    title: 'Год',
    sortName: 'yearOfConstruction',
};
const IMAGES = {
    name: 'images',
    title: 'Фото',
    tooltip: 'Фото',
};
const CREATED = {
    name: 'created',
    title: 'Размещено',
    sortName: 'createdAt',
    tooltip: 'Размещено',
};
const CREATED_TIME = {
    name: 'createdTime',
    title: 'Время',
};
const ADDED = {
    name: 'added',
    title: 'Размещено',
    sortName: 'addedAt',
};
const CONTACT_PHONE = {
    name: 'contactPhone',
    title: 'Телефон',
    tooltip: 'Телефон',
};
const SOURCE = {
    name: 'source',
    title: 'Источник',
    tooltip: 'Источник',
};
const EXCHANGE = {
    name: 'exchange',
    title: 'Обмен',
    tooltip: 'Обмен',
};
const STEAD_TYPE_NAME = {
    name: 'steadTypeName',
    title: 'Тип участка',
    sortName: 'objectSteadType',
    tooltip: 'Тип участка',
};
const COMMERCIAL_TYPE_NAME = {
    name: 'commercialTypeName',
    title: 'Тип помещения',
    sortName: 'objectCommercialType',
    tooltip: 'Тип помещения',
};
const PASSAGE_NAME = {
    name: 'passageName',
    title: 'Проезд',
    sortName: 'passage',
};
const BUILDINGS_NAME = {
    name: 'buildingsName',
    title: 'Постройки',
};
const RENT_FLAT_TYPE_NAME = {
    name: 'rentFlatTypeName',
    title: 'Тип',
    sortName: 'rentFlatType',
    tooltip: 'Тип квартиры',
};
const STOVE_TYPE_NAME = {
    name: 'stoveTypeName',
    title: 'Газ/электро',
    sortName: 'stoveType',
    tooltip: 'Тип плиты',
};
const WALL_MATERIAL_NAME = {
    name: 'wallMaterialName',
    title: 'Материал Стен',
    sortName: 'wallMaterial',
};
const OBJECT_ADDRESS = {
    name: 'objectAddress',
    title: 'Объект',
    sortName: 'object',
};
const DEAL_TYPE_NAME = {
    name: 'dealTypeName',
    title: 'Тип сделки',
    sortName: 'dealType',
    tooltip: 'Тип сделки',
};
const ACTION = {
    name: 'action',
    title: 'Действие',
    sortName: 'action',
};
const SUBURB_OF_CITY = {
    name: 'suburbOfCityName',
    title: 'Пригород',
    sortName: 'suburbOfCity',
};
const DATE = {
    name: 'date',
    title: 'Дата',
    sortName: 'date',
};
const TIME = {
    name: 'time',
    title: 'Время',
    sortName: 'time',
};
const AMOUNT = {
    name: 'amount',
    title: 'Сумма, руб.',
    sortName: 'amount',
};

export default {
    ROW_SELECT,
    CITY_NAME,
    CITY_ALIASES,
    STREET_NAME,
    STREET_NAME_WITH_COMPLEX_NAME,
    DISTRICT_NAME,
    DISTRICT_LANDMARK_NAME,
    SHORT_DISTRICT_NAME,
    HOUSE_NUMBER,
    HOUSE_TYPE_NAME,
    STRUCTURE_NUMBER,
    BUILDING_NUMBER,
    REGION_NAME,
    AREA_NAME,
    AGENCY_NAME,
    OBJECT_STATUS_NAME,
    OBJECT_TYPE,
    OBJECT_TYPE_NAME,
    ROOMS_COUNT,
    FLOOR_COUNT,
    FLOOR_COUNT_WITH_WALL_MATERIAL,
    FLOOR_WITH_FLOOR_COUNT_WITH_WALL_MATERIAL,
    SQUARE,
    SQUARE_TOTAL,
    PRICE,
    UNIT_PRICE,
    PRICE_DELIMITED,
    UNIT_PRICE_DELIMITED,
    SERIES_NAME,
    DATE_OF_COMPLETION,
    DATE_OF_CONSTRUCTION,
    IMAGES,
    CREATED,
    ADDED,
    CREATED_TIME,
    CONTACT_PHONE,
    SOURCE,
    EXCHANGE,
    STEAD_TYPE_NAME,
    COMMERCIAL_TYPE_NAME,
    PASSAGE_NAME,
    BUILDINGS_NAME,
    RENT_FLAT_TYPE_NAME,
    STOVE_TYPE_NAME,
    WALL_MATERIAL_NAME,
    OBJECT_ADDRESS,
    DEAL_TYPE_NAME,
    ACTION,
    SUBURB_OF_CITY,
    DATE,
    TIME,
    AMOUNT,
};
