import { DIRECTORIES } from '../../../constants/shared';

export default {
    type: 'fieldset', // house
    name: 'sourceBoard',
    fields: [{
        name: 'sourceBoardIds',
        type: 'directorySelect',
        directory: DIRECTORIES.PARSER_SOURCE_BOARD,
        label: 'Источник',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: false,
    }],
};
