import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { ContextMenuTrigger } from 'react-contextmenu';
import ObjectListStore from '../../../stores/shared/RefactoredObjectList/ObjectList';
import NotepadList from '../../../stores/shared/NotepadList/NotepadList';
import ExportObjects from '../../shared/ExportObjects';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import { ObjectListPage } from '../../shared/ObjectListPage';
import { Table } from '../Table';
import { TableWithSelected } from '../TableWithSelected';
import useStores from '../../../hooks/client/use-stores';
import useKeyPress from '../../../hooks/shared/use-key-press';
import { LoadMoreBadge } from '../../shared/ObjectPageView';
import { PagePopups } from '../../shared/PagePopups';
import KeyboardHandler from '../../../components/KeyboardHandler';
import { ContextMenu, MenuItem, SubMenu } from '../../../components/ContextMenu';
import { NotepadNestIcon, NotepadPlusIcon, PlusSquareIcon } from '../../../components/Icons/icons';
import NotepadForm from '../../shared/NotepadList/Form';
import MessageDialog from '../../shared/Dialog/MessageDialog';
import PresetForm from '../../shared/PresetList/Form';
import SelectedObject from '../../shared/SelectedObject';
import Dialog from '../Dialog';
import SocialNetworksModal from '../SocialNetworks/SocialNetworksModal';
import SocialNetworksMenu from '../SocialNetworks/SocialNetworksMenu';
import {
    TAB_TYPE,
    SORT_ORDER,
    TABLE_CELL,
} from '../../../constants/client';

const ObjectListContent = ({
    selectable,
    store: {
        list,
        tabs,
        notepads,
        exportObjects,
        createDetailedClass,
        createNotepad,
        createRelated,
    },
    meta,
}) => {
    const listRef = useRef();
    const keyboardRef = useRef();
    const {
        dialogStore,
        directoriesStore,
        headerActions,
        presetListStore,
        objectListPageStore,
    } = useStores();
    const isCtrlPressed = useKeyPress('Control');
    const isShiftPressed = useKeyPress('Shift');
    const [selectedHeight, setSelectedHeight] = useState('266px');
    const { paginationHandler } = list;
    const Detailed = createDetailedClass();
    let keyPressDelay;
    // Interactions
    const handleLoadNewClick = () => {
        list.reload()
            .then(() => {
                listRef.current.scrollTo(0);
            });
    };
    const handleRowClick = (row) => {
        const { id, isExclusive } = row.original;
        if (isShiftPressed()) {
            list.selectRange(list.cursor, id);
            return;
        }
        list.setCursor(id);
        if (isCtrlPressed()) {
            const item = list.getItem(id);
            item.toggle();
            return;
        }
        const instance = new Detailed();
        instance.setData({ id, exclusive: isExclusive });
        if (handleRowClick.callback) {
            instance.load(id, false);
            handleRowClick.callback(instance);
        }
    };
    const handleDoubleRowClick = (data) => {
        const {
            original: { id, isExclusive },
        } = data;
        const item = list.getItem(id);
        if (!tabs.getTab(item.id)) {
            const instance = new Detailed();
            instance.setData({ id: item.id, exclusive: isExclusive });
            const form = instance.createForm();
            form.on('done', (response) => {
                if (!response.filter((res) => Boolean(res)).length) {
                    return;
                }
                tabs.removeTab(item.id);
                item.setData(instance.toItem());
            });
            list.setCursor(item.id);
            instance.load(item.id);
            tabs.addTab(
                instance,
                item.id,
                item.objectName,
                TAB_TYPE.EDIT_OBJECT,
            );
        }
    };
    const handleCreateObjectClick = () => {
        if (!tabs.getTab('new')) {
            const instance = new Detailed();
            instance.setData({ exclusive: true });
            const form = instance.createForm();
            form.on('done', () => {
                setTimeout(() => {
                    tabs.removeTab('new');
                }, 0);
            });
            tabs.addTab(
                instance,
                'new',
                'Новый объект',
                TAB_TYPE.CREATE_OBJECT,
            );
        }
    };
    const handleNewNotepadClick = () => {
        const itemForm = notepads.createItemForm();
        const unsubscribeForm = itemForm.on('done', () => {
            dialogStore.hide('new-notepad-item');
        });
        const unsubscribeDialog = dialogStore.on('new-notepad-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });
        dialogStore.show('new-notepad-item');
    };
    const handleNotepadAdd = (e, { id }) => {
        notepads.addObjectsToNotepad(
            id,
            list.selected && list.selected.length
                ? list.selected.map(({ id: objectId }) => objectId)
                : [list.cursor],
            list.objectType,
        )
            .then(() => {
                const tabStore = tabs.tabs.find(({ id: tabId }) => tabId === `${id}-notepad`);
                list.unselectAll();
                if (tabStore) {
                    const { list: currentNotepadList } = tabStore.store.notepad;
                    currentNotepadList.reload();
                } else {
                    dialogStore.show('notepad-message');
                }
            });
    };
    const handleNotepadClick = (id, name) => {
        const notepadTabId = `${id}-notepad`;
        if (!tabs.getTab(notepadTabId)) {
            const notepad = createNotepad(id);
            tabs.addTab(
                {
                    notepads,
                    notepad,
                    tabs,
                    name,
                },
                notepadTabId,
                name || id,
                TAB_TYPE.NOTEPAD_OBJECTS,
            );
        }
    };
    const handleNewPresetClick = () => {
        const itemForm = presetListStore.createItemForm();
        const unsubscribeFormDone = itemForm.on('done', () => {
            dialogStore.hide('new-preset-item');
        });
        const unsubscribeFormSuccess = itemForm.on('success', (values) => (
            list.getPresetFilterData().then((preset) => {
                presetListStore.create({ ...preset, ...values });
            })
        ));
        const unsubscribeDialog = dialogStore.on('new-preset-item:hidden', () => {
            unsubscribeFormDone();
            unsubscribeDialog();
            unsubscribeFormSuccess();
        });
        dialogStore.show('new-preset-item');
    };
    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = SORT_ORDER.ASC;
                break;
            default:
                nextDirection = (
                    sortName === TABLE_CELL.SHORT_DISTRICT_NAME.sortName
                    || sortName === TABLE_CELL.DISTRICT_NAME.sortName
                )
                    ? SORT_ORDER.DESC
                    : SORT_ORDER.ASC;
        }
        list.setSort(sortName, nextDirection);
        list.reload();
    };
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            list.setPrevCursor();
        } else {
            list.setNextCursor();
        }
        const currentId = list.cursor;
        const index = list.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
        if (selectable) {
            if (keyPressDelay) {
                clearTimeout(keyPressDelay);
            }
            keyPressDelay = setTimeout(() => {
                handleRowClick({ original: { id: currentId } });
            }, 300);
        }
    };
    const handleDownKey = () => {
        handleArrowKey(1);
    };
    const handleUpKey = () => {
        handleArrowKey(-1);
    };
    const handleEnterKey = () => {
        const id = list.cursor;
        handleDoubleRowClick({ original: { id, exclusive: list.getItem(id).isExclusive } });
    };
    const handleSpaceKey = () => {
        list
            ?.getItem(list.cursor)
            ?.toggle();
    };
    const handleObjectClose = () => {
        keyboardRef.current.focus();
    };
    const handleContextMenuShow = () => {
        if (notepads) {
            notepads.load();
        }
    };
    const handlePhoneClick = (id, phone) => {
        const item = list.getItem(id);
        const { objectName } = item;
        const relatedTabId = `${id}-related`;
        if (!tabs.getTab(relatedTabId)) {
            const related = createRelated(id, phone);
            related.load();
            tabs.addTab(
                {
                    related,
                    phone,
                    tabs,
                },
                relatedTabId,
                objectName || id,
                TAB_TYPE.RELATED_OBJECTS,
            );
        }
    };
    // Effects
    useEffect(() => {
        if (list.load) {
            if (objectListPageStore?.selected) {
                const presetFilter = list.convertPresetFilterData(
                    objectListPageStore.selected.filter,
                );
                list.applyFilter(presetFilter.filter);
                list.filterForm.createSnapshot('preset');
            } else {
                list.filterForm.removeSnapshot('preset');
            }
            if (directoriesStore.defaultsLoaded) {
                list.filterForm.submit();
            } else {
                list.preload();
                directoriesStore.on('defaults:loaded', () => {
                    list.filterForm.submit();
                });
            }
            keyboardRef.current.focus();
            headerActions.setActions([
                ...headerActions.actions,
                {
                    actionText: 'Добавить',
                    icon: PlusSquareIcon,
                    actionFunction: handleCreateObjectClick,
                },
            ]);
        }
    }, [list]);
    useEffect(() => {
        headerActions.setActions();
    }, []);
    // Component
    return (
        <>
            <ObjectListPage
                meta={meta}
                store={list}
                tabsStore={tabs}
                notepads={notepads}
                presets={presetListStore}
                onNotepadOpen={notepads && handleNotepadClick}
                onPresetAdd={presetListStore && handleNewPresetClick}
                useStores={useStores}
            >
                <Observer>
                    {() => (
                        <LoadMoreBadge
                            onClick={handleLoadNewClick}
                            isShown={Boolean(paginationHandler.data.newItems)}
                        >
                            Посмотреть
                            &nbsp;
                            {paginationHandler.data.newItems}
                            &nbsp;
                            новых объявления
                        </LoadMoreBadge>
                    )}
                </Observer>
                <ContextMenuTrigger id="objects-context-menu">
                    <KeyboardHandler
                        ref={keyboardRef}
                        onDown={handleDownKey}
                        onUp={handleUpKey}
                        onEnter={handleEnterKey}
                        onSpace={handleSpaceKey}
                    >
                        {
                            selectable
                                ? (
                                    <Observer>
                                        {() => (
                                            <TableWithSelected
                                                ref={listRef}
                                                headers={list.tableHeaders}
                                                store={list}
                                                selectedContentHeight={selectedHeight}
                                                Detailed={Detailed}
                                                onRowClick={handleRowClick}
                                                onDoubleRowClick={handleDoubleRowClick}
                                                onTableHeaderClick={handleSortChange}
                                                withHistory
                                                components={{
                                                    /* eslint-disable react/prop-types */
                                                    SelectedWrapper: (props) => (
                                                        <SelectedObject
                                                            setSelectedHeight={setSelectedHeight}
                                                            onPhoneClick={handlePhoneClick}
                                                            // eslint-disable-next-line max-len
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...props}
                                                        />
                                                    ),
                                                    /* eslint-enable react/prop-types */
                                                }}
                                            />
                                        )}
                                    </Observer>
                                )
                                : (
                                    <Observer>
                                        {() => (
                                            <Table
                                                ref={listRef}
                                                headers={list.tableHeaders}
                                                store={list}
                                                onRowClick={handleDoubleRowClick}
                                                onDoubleRowClick={handleDoubleRowClick}
                                                onTableHeaderClick={handleSortChange}
                                            />
                                        )}
                                    </Observer>
                                )
                        }
                    </KeyboardHandler>
                </ContextMenuTrigger>
                <Observer>
                    {() => (
                        <ContextMenu id="objects-context-menu" onShow={handleContextMenuShow}>
                            <>
                                <MenuItem
                                    icon={NotepadPlusIcon}
                                    onClick={handleNewNotepadClick}
                                >
                                    Создать блокнот
                                </MenuItem>
                                <SubMenu
                                    icon={NotepadNestIcon}
                                    title="Добавить в блокнот"
                                    disabled={
                                        !notepads.list.length
                                        || (!list.selected.length && !list.cursor)
                                    }
                                >
                                    {notepads.list.map((item) => (
                                        <MenuItem
                                            key={`notepad-menu-${item.id}`}
                                            data={{ id: item.id }}
                                            onClick={handleNotepadAdd}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </SubMenu>
                                <SocialNetworksMenu list={list} />
                            </>
                        </ContextMenu>
                    )}
                </Observer>
            </ObjectListPage>
            {
                exportObjects
                    ? (
                        <ExportObjects
                            useStores={useStores}
                            exportObjects={exportObjects}
                        />
                    )
                    : null
            }
            <PagePopups
                useStores={useStores}
                tabsStore={tabs}
                onClose={handleObjectClose}
                createRelated={createRelated}
                createDetailed={() => new Detailed()}
            />
            <Dialog
                name="new-notepad-item"
                size="sm"
                footer={null}
                header={<>Создать блокнот</>}
            >
                <Observer>
                    {() => (
                        <NotepadForm
                            useStores={useStores}
                            button="Добавить"
                            form={notepads.createItemForm()}
                        />
                    )}
                </Observer>
            </Dialog>
            <Dialog
                name="new-preset-item"
                size="sm"
                footer={null}
                header={<>Создать подборку</>}
            >
                <Observer>
                    {() => (
                        <PresetForm
                            useStores={useStores}
                            button="Создать"
                            form={presetListStore?.createItemForm()}
                        />
                    )}
                </Observer>
            </Dialog>

            <Dialog
                useStores={useStores}
                name="preset-item-save"
                size="xs"
                contentHeight="auto"
                footer={null}
                header={null}
            >
                {() => (
                    <p>Подборка сохранена</p>
                )}
            </Dialog>
            <MessageDialog
                useStores={useStores}
                name="notepad-message"
                header={<>Сообщение</>}
            >
                <Observer>
                    {() => (
                        <>
                            {notepads.message}
                        </>
                    )}
                </Observer>
            </MessageDialog>

            <SocialNetworksModal list={list} />
        </>
    );
};

ObjectListContent.propTypes = {
    selectable: PropTypes.bool,
    store: PropTypes.shape({
        list: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(ObjectListStore),
        ]),
        tabs: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(PopupTabsWithApproveStore),
        ]),
        createDetailedClass: PropTypes.func,
        createNotepad: PropTypes.func,
        createRelated: PropTypes.func,
        notepads: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(NotepadList),
        ]),
        exportObjects: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(ExportObjects),
        ]),
    }),
    meta: PropTypes.shape({}),
};

ObjectListContent.defaultProps = {
    selectable: false,
    store: {
        list: {
            tableData: [],
            tableHeaders: [],
            paginationHandler: {
                data: {
                    totalItems: 0,
                },
            },
        },
        tabs: {
            tabs: [],
        },
        notepads: {
            list: [],
            createItemForm: () => null,
        },
        exportObjects: {},
        createDetailedClass: () => null,
        createRelated: () => null,
        createNotepad: () => null,
    },
    meta: {},
};

export default ObjectListContent;
