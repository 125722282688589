import {
    observable,
    computed,
    runInAction,
    toJS,
} from 'mobx';
import DataSource, { STATES } from '../../DataSource';
import List from '../../List';

class DaDataSuggestions {
    @observable $options = [];

    cache = new List();

    constructor(service) {
        this.dataSource = new DataSource(service, STATES.DEFAULT);
    }

    @computed
    get options() {
        return toJS(this.$options);
    }

    @computed
    get loading() {
        return this.dataSource.loading;
    }

    saveCache(key, value) {
        const { cache } = this;
        cache.addItem({
            id: key,
            data: value,
        });
    }

    getCache(key) {
        const { cache } = this;
        const data = cache.getItem(key);
        if (data) return data.data;
        return false;
    }

    loadData(query = '') {
        if (typeof query !== 'string') {
            throw new Error(`Search query must be a string, got: ${typeof query}`);
        }
        const cached = this.getCache(query);
        const mapSuggestions = (sug) => ({
            value: sug.value,
            latitude: sug.data.geo_lat,
            longitude: sug.data.geo_lon,
            data: sug,
        });
        if (cached) {
            runInAction(() => {
                this.$options = cached;
            });
            return Promise.resolve(cached);
        }
        return this.dataSource.getSuggestions(query)
            .then((response) => {
                this.requestId = 0;
                const data = response.map(mapSuggestions);
                this.saveCache(query, data);
                runInAction(() => {
                    this.$options = data;
                });
                return Promise.resolve(data);
            })
            .catch((e) => Promise.reject(e));
    }
}

export default DaDataSuggestions;
