import createQuery from '../../utils/create-query';

const getCursor = (clientCursor) => (clientCursor ? `cursor=${encodeURIComponent(clientCursor)}` : '');

const createObjectService = (request) => ({
    getObjects: (filter = {}, clientCursor, { dealType, objectType }) => {
        const cursor = getCursor(clientCursor);
        return request.post(
            `/objects/${dealType}/${objectType}${cursor ? `?${cursor}` : ''}`,
            filter,
        );
    },
    getObject: (id, { objectType }) => request.get(`/objects/${objectType}/${id}`),
    getNotepadObjects: (id, filter, clientCursor, { objectType, dealType }) => {
        const cursor = getCursor(clientCursor);
        return request.post(
            `objects/notebooks/${id}/${objectType}/${dealType}?${cursor}`,
            filter,
        );
    },
    createObject: (data, { objectType }) => request.post(`objects/${objectType}/`, data),
    updateObject: (id, data, { objectType, requestType }) => (
        request.put(`objects/${objectType}/${id}?${createQuery({ requestType })}`, data)
    ),
    uploadImage: (id, file, { objectType }) => {
        const formData = new FormData();
        formData.append('file', file);
        return request.post(
            `objects/${objectType}/${id}/images`,
            formData,
            {
                headers: {
                    'Content-Type': '',
                },
            },
        );
    },
    removeImage: (id, imageId, { objectType }) => (
        request.delete(`objects/${objectType}/${id}/images/${imageId}`)
    ),
    getExportParams: () => (
        request.get('objects/export-params')
    ),
    getExportFields: (typeId) => (
        request.get(`objects/export-params/${typeId}`)
    ),
    export: (data) => (
        request.post('objects/export', data)
    ),
    getObjectMarkers: ({ dealType, objectType }) => request.get(`/objects/${objectType}/${dealType}/base-list`),
});

export default createObjectService;
export {
    getCursor,
};
