import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ breadcrumbs }) => (
    !breadcrumbs || !breadcrumbs.length
        ? null
        : (
            <div className={styles.breadcrumbs}>
                {breadcrumbs.map(({ link, title }, index) => {
                    const isActive = index === breadcrumbs.length - 1;
                    return (
                        <Link
                            key={link}
                            to={link}
                            disabled={isActive}
                            className={`
                                ${styles.breadcrumbs__item}
                                ${isActive ? styles['breadcrumbs__item--active'] : ''}
                            `}
                        >
                            {title}
                        </Link>
                    );
                })}
            </div>
        )
);

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
};

export default Breadcrumbs;
