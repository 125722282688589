const baseArrayData = Array(4).fill().map((_, i) => i + 1);
const postfixRoom = 'комнатная';
const ROOMS_COUNT = baseArrayData.reduce(
    (acc, value) => [
        ...acc,
        {
            label: `${value}-${postfixRoom}`,
            value,
            extra: {},
        },
    ],
    [{
        label: '0 комнат',
        value: 0,
        extra: {},
    }],
);

ROOMS_COUNT.push({
    label: 'от 5 и более',
    value: 5,
    extra: {},
});

export default {
    ROOMS_COUNT,
};
