import PropTypes from 'prop-types';

import { EMPTY_DATA_STRING } from '../../../constants/shared';

const CellDefault = ({ value }) => {
    if (value instanceof Object) {
        return JSON.stringify(value);
    }
    return value || EMPTY_DATA_STRING;
};

CellDefault.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
        PropTypes.string,
    ]),
};

CellDefault.defaultProps = {
    value: '',
};

export default CellDefault;
