import videoField from './video';
import { DIRECTORIES } from '../../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'basicInfo',
    label: 'Основная информация',
    fields: [{
        name: 'basicInfoLeft',
        type: 'fieldgroup',
        fields: [{
            name: 'steadType',
            type: 'directorySelect',
            directory: DIRECTORIES.OBJECT_STEAD_TYPE,
            label: 'Назначение',
            rules: 'required',
            searchable: true,
            clearable: true,
        }, {
            name: 'passage',
            type: 'directorySelect',
            directory: DIRECTORIES.PASSAGE,
            label: 'Подъезд',
            searchable: true,
            clearable: true,
        }, {
            name: 'buildings',
            type: 'directorySelect',
            directory: DIRECTORIES.BUILDING,
            label: 'Постройки',
            searchable: true,
            clearable: true,
            multiple: true,
        }],
    }, {
        name: 'basicInfoLeft',
        type: 'fieldgroup',
        fields: [{
            name: 'totalArea',
            type: 'numeric',
            size: 'sm',
            label: 'Площадь',
            postfix: 'с',
        }, {
            name: 'pricesForSquareArea',
            type: 'inputList',
            size: 'sm',
            label: 'Цена - Цена, с',
            postfix: 'т.р.',
            fields: [{
                name: 'price',
                type: 'integer',
            }, {
                name: 'unitPrice',
                type: 'numeric',
            }],
        }, {
            type: 'checkbox',
            name: 'isOwned',
            size: 'md',
            label: 'В собственности',
        }, {
            name: 'ownerType',
            type: 'directoryRadioList',
            directory: DIRECTORIES.OWNER_TYPE,
            rules: 'required',
            size: 'md',
            rows: 1,
        }],
    }],
}, {
    type: 'fieldset',
    name: 'additionalInfo',
    label: 'Дополнительная информация',
    fields: [{
        name: 'basicInfoLeft',
        type: 'fieldgroup',
        fields: [{
            type: 'checkbox',
            name: 'hasCommunications',
            size: 'md',
            label: 'Коммуникации',
        }, {
            type: 'checkbox',
            name: 'hasElectricity',
            size: 'md',
            label: 'Электричество',
        }, {
            type: 'checkbox',
            name: 'hasForestNearby',
            size: 'md',
            label: 'Лес рядом',
        }],
    }, {
        name: 'basicInfoRight',
        type: 'fieldgroup',
        fields: [{
            type: 'checkbox',
            name: 'hasVegetation',
            size: 'md',
            label: 'Огород',
        }, {
            type: 'checkbox',
            name: 'hasWaterSupply',
            size: 'md',
            label: 'Водоснабжение',
        }],
    }, {
        name: 'operatorNoteWrapper',
        type: 'fieldgroup',
        fields: [{
            name: 'publicNote',
            type: 'textarea',
            rows: 3,
            label: 'Внешнее примечание',
        }, {
            name: 'privateNote',
            type: 'textarea',
            rows: 3,
            label: 'Внутреннее примечание',
        }],
    }],
}, videoField, {
    type: 'fieldset',
    name: 'note',
    label: 'Текст объявления',
    fields: [{
        name: 'note',
        type: 'textarea',
        rows: 14,
    }],
}, {
    type: 'fieldset',
    name: 'location',
    label: 'Расположение',
    fields: [{
        name: 'area',
        type: 'directorySearch',
        directory: DIRECTORIES.AREA,
        label: 'Район субъекта',
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'city',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        rules: 'required',
        searchable: true,
        clearable: true,
        withDefault: true,
    }, {
        name: 'cityDistrict',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        searchable: true,
        clearable: true,
        defaultCtx: { fromCard: true },
    }, {
        name: 'street',
        type: 'directorySearch',
        directory: DIRECTORIES.STREET,
        label: 'Улица',
        searchable: true,
        clearable: true,
    }, {
        label: 'Ориентир',
        name: 'cityDistrictLandmark',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT_LANDMARK,
        searchable: true,
        clearable: true,
    }, {
        name: 'longitude',
        hidden: true,
    }, {
        name: 'latitude',
        hidden: true,
    }],
}, {
    type: 'fieldset',
    name: 'contacts',
    label: 'Контакты',
    fields: [{
        type: 'fieldgroup',
        name: 'contacts',
        fields: [{
            label: 'Контакты',
            name: 'phones',
            type: 'contact',
            rules: 'contact|required',
            size: 'sm',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'user-note',
    label: '',
    fields: [{
        name: 'userNote',
        rows: 3,
        type: 'textarea',
        label: 'Личные заметки',
        tooltip: 'Эти заметки видны только вам (пользователю)',
        readonly: false,
    }],
}, {
    type: 'fieldset',
    name: 'incorrect',
    label: 'Некорректность',
    fields: [{
        name: 'incorrect',
        type: 'directoryCheckboxList',
        size: 'md',
        columns: 2,
        colored: false,
        directory: DIRECTORIES.INCORRECT_OPTIONS,
        alwaysEditable: true,
    }],
}];
