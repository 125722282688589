import { observable, action } from 'mobx';

class HeaderActions {
    // Actions array from shape:
    // {
    //      icon: IconComponent,
    //      actionFunction: ActionFunction,
    //      actionText: TextAction,
    // }
    @observable
    actions = [];

    @action
    setActions(actions) {
        if (!actions || !actions.length) {
            this.actions = [];
        } else {
            this.actions = actions.sort((a, b) => {
                const { order: aOrder = 0 } = a;
                const { order: bOrder = 0 } = b;
                return aOrder - bOrder;
            });
        }
    }
}

const createHeaderActionsStore = () => new HeaderActions();

export default HeaderActions;
export {
    createHeaderActionsStore,
};
