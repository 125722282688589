import React from 'react';
import PropTypes from 'prop-types';
import DirectorySearch from './DirectorySearch';

import styles from './Directory.module.scss';

const ClientValue = ({
    label,
    value,
    color,
    modificator,
}) => (
    <div
        className={`
            ${styles.client__box}
            ${styles[`client__box--${modificator}`]}
        `}
    >
        <span className={`${styles.client__label} ${styles[`client__label--${color}`]}`}>
            {label}
            :
        </span>
        <span className={styles.client__value}>
            {value}
        </span>
    </div>
);

ClientValue.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string,
    modificator: PropTypes.string,
};

ClientValue.defaultProps = {
    label: '',
    value: '',
    color: 'red',
    modificator: 'default',
};

const Client = ({
    // eslint-disable-next-line react/prop-types
    innerProps,
    isDisabled,
    data: {
        address,
        companyName,
        hardwareName,
        login,
    },
}) => (
    !isDisabled
        ? (
            <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...innerProps}
                className={`${styles['directory-search__client']} ${styles.client}`}
            >
                <ClientValue label="Л" value={login} color="orange" modificator="login" />
                <ClientValue label="К" value={companyName} color="green" modificator="company" />
                <ClientValue label="А" value={address} color="red" modificator="address" />
                <ClientValue label="О" value={hardwareName} color="blue" modificator="hardware" />
            </div>
        ) : null
);

Client.propTypes = {
    isDisabled: PropTypes.bool,
    data: PropTypes.shape({
        address: PropTypes.string,
        companyName: PropTypes.string,
        hardwareName: PropTypes.string,
        label: PropTypes.number,
        login: PropTypes.number,
        value: PropTypes.number,
    }),
};

Client.defaultProps = {
    isDisabled: false,
    data: {
        address: '',
        companyName: '',
        hardwareName: '',
        label: '',
        login: '',
        value: '',
    },
};

const DirectoryClientSearch = ({ ...props }) => (
    <DirectorySearch
        className={styles['directory-search--client']}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        wrappers={{
            OptionWrapper: Client,
        }}
    />
);

export default DirectoryClientSearch;
