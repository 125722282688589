import { endOfDay as getEndOfDay, startOfDay as getStartOfDay } from 'date-fns';
import Field from './DefaultField';

class DatePicker extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw) => {
        const { extra: { endOfDay, startOfDay } } = this;
        const { providedOutput } = this;
        if (!raw) {
            return typeof providedOutput === 'function'
                ? providedOutput(raw)
                : '';
        }
        let value;
        if (endOfDay) {
            value = getEndOfDay(new Date(raw));
        } else if (startOfDay) {
            value = getStartOfDay(raw);
        } else {
            value = raw;
        }
        const output = new Date(value).toISOString();
        if (typeof providedOutput === 'function') {
            return providedOutput(output);
        }
        return output;
    }
}

export default DatePicker;
