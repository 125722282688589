/** Наименования страниц в связке с их путями */
export const PagesRouteInfo = Object.freeze({
    Parser: {
        PageTitle: 'Выдача парсера',
        Root: '/parser',
        RequestType: 'parser',
    },
    Duplicates: {
        PageTitle: 'Поиск дубликатов',
        Root: '/duplicates',
        RequestType: 'duplicates',
    },
    Base: {
        PageTitle: 'Список объектов',
        Root: '/base',
        RequestType: 'base',
    },
});

/** Мапинг для получения названий страниц по типу запроса */
export const PagesRequestTypesNamesMap = new Map(
    Object.keys(PagesRouteInfo)
        .map(((fieldName) => [PagesRouteInfo[fieldName].RequestType,
            PagesRouteInfo[fieldName].PageTitle])),
);

/** Маппинг для получения корневого пути по типу запроса */
export const PagesRootNamesMap = new Map(
    Object.keys(PagesRouteInfo)
        .map(((fieldName) => [PagesRouteInfo[fieldName].RequestType,
            PagesRouteInfo[fieldName].Root])),
);
