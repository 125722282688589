import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './DetailedView.module.scss';

const DetailedViewFieldset = ({ fieldset: { label, id, name }, children }) => (
    <fieldset
        className={`${styles.fieldset} ${styles[`fieldset--${name}`] || ''}`}
        key={`${name}`}
    >
        {
            label
                ? (
                    <Label
                        htmFor={id}
                        className={styles['fieldset-label']}
                    >
                        <span>
                            {label}
                        </span>
                    </Label>
                )
                : null
        }
        {children}
    </fieldset>
);

DetailedViewFieldset.propTypes = {
    fieldset: PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    children: PropTypes.element.isRequired,
};

export default DetailedViewFieldset;
