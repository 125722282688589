import { TABLE_CELL } from '../../../../constants/client';

export default [{
    ...TABLE_CELL.DEAL_TYPE_NAME,
    width: 15,
    textAlign: 'left',
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 25,
    textAlign: 'left',
}, {
    ...TABLE_CELL.DISTRICT_LANDMARK_NAME,
    width: 18,
    textAlign: 'left',
}, {
    ...TABLE_CELL.SQUARE_TOTAL,
    width: 10,
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 18,
}, {
    ...TABLE_CELL.OBJECT_STATUS_NAME,
    textAlign: 'left',
}];
