import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import findClosestRouteWithComponent from './find-closest-route-with-component';
import withMeta from '../../hocs/withMeta';

// eslint-disable-next-line no-shadow
const renderRoutes = (routes, deep = '', parents = [], page404) => {
    const redirect = findClosestRouteWithComponent(routes);
    return (
        <Switch>
            {routes.map(({
                fullpath,
                component: Component,
                exact,
                routes: children,
                ...meta
            }) => {
                if (children && children.length) {
                    const content = renderRoutes(children, fullpath, routes, page404);
                    const render = Component
                        ? () => (
                            <Component routes={children}>
                                {content}
                            </Component>
                        )
                        : () => content;
                    return (
                        <Route
                            exact={exact}
                            key={fullpath}
                            path={fullpath}
                            component={render}
                        />
                    );
                }
                const content = withMeta(
                    Component,
                    {
                        ...meta,
                        sisterRoutes: routes,
                        parentRoutes: parents,
                    },
                );
                return (
                    <Route
                        exact={exact}
                        key={fullpath}
                        path={fullpath}
                        render={content}
                    />
                );
            })}
            <Redirect
                exact
                from={deep || '/'}
                to={
                    redirect
                        ? redirect.link
                        : page404
                }
            />
            <Redirect to={page404} />
        </Switch>
    );
};

export default renderRoutes;
