import { computed } from 'mobx';
import capitalizeFirstLetter from '../../../utils/capitalize-first-letter';
import ObjectList from './ObjectList';
import tables from './tables';

class NotepadObjectList extends ObjectList {
    constructor(strategy, id) {
        super(strategy);
        this.id = id;
    }

    get $tableHeaders() {
        const {
            strategy: { objectType, sortData },
        } = this;
        const headers = tables[`notepad${capitalizeFirstLetter(objectType)}`];
        return headers.map((header) => ({
            ...header,
            sortDirection: sortData.field === header.sortName ? sortData.direction : undefined,
        }));
    }

    @computed
    get tableHeaders() {
        return this.$tableHeaders;
    }

    get storageName() {
        return `notepad${capitalizeFirstLetter(super.storageName)}`;
    }

    createFilterForm() {
        return this.filterForm;
    }

    getList(cursor, ctx) {
        const { strategy: { objectType, dealType }, id } = this;
        return this.dataSource.getNotepadObjects(
            id,
            ctx,
            cursor,
            {
                objectType,
                dealType,
            },
        );
    }

    toItem(data) {
        return super.toItem({ ...data, idModifier: 'notepad' });
    }
}

export default NotepadObjectList;
