import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../../components/Button';
import { NotifyOrangeIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';
import LoginPage from '../../shared/LoginPage';

import clientStyles from './LoginPage.module.scss';

const FingerprintBlock = () => (
    <>
        <LoginPage
            className={clientStyles.fingerprint}
            footer={(
                <ButtonLink
                    Component={Link}
                    size={ButtonLink.SIZE.MEDIUM}
                    color={ButtonLink.COLOR.TRANSPARENT}
                    href="/login"
                    target={null}
                >
                    Назад
                </ButtonLink>
            )}
            title={(
                <div className={`${clientStyles.fingerprint__title} ${clientStyles['fingerprint__title--lg']}`}>
                    Закончились переактивации
                </div>
            )}
        >
            <>
                <div className={`${clientStyles.fingerprint__description} ${clientStyles['fingerprint__description--line']}`}>
                    Для получения информации обратитесь по номеру телефона
                </div>
                <div className={clientStyles.fingerprint__phone}>
                    <a href="tel:+79048906297">
                        +7 (904) 890-62-97
                    </a>
                </div>
                <div className={clientStyles.fingerprint__warning}>
                    <SVGIcon
                        SVGElement={NotifyOrangeIcon}
                        height={16}
                    />
                    Ваш аккаунт заблокирован
                </div>
            </>
        </LoginPage>
    </>
);

export default FingerprintBlock;
