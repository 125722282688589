import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { MessageDialog } from '../../../components/MessageDialog';
import YandexMap from '../../../components/YandexMap';
import Preloader from '../../../components/Preloader';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { AlertIcon } from '../../../components/Icons/icons';

import styles from './ObjectComponent.module.scss';

const MapOptions = ({
    useStores,
    position,
    fullAddress,
    snapshotIsDiffer,
    handleNavigationClick,
    handlePositionSelect,
    handleDefaultPosition,
}) => {
    const { directoriesStore: { dadatasuggestions: store } } = useStores();
    useEffect(() => {
        if (
            !fullAddress
            || !store
            || !store.loadData
            || (
                !snapshotIsDiffer
                && position.length === 2
            )
        ) {
            return;
        }
        store.loadData(fullAddress)
            .then((suggestions) => {
                if (suggestions.length === 1) {
                    handleDefaultPosition(
                        suggestions[0].latitude,
                        suggestions[0].longitude,
                    );
                }
            })
            .catch((e) => {
                // DO NOTHING;
                console.error(e);
            });
    });
    if (!fullAddress && position.length !== 2) {
        return (
            <MessageDialog
                title="Не задан адрес"
                buttonText="Отредактировать"
                onClick={handleNavigationClick}
            >
                Чтобы увидеть объект на карте отредактируйте его расположение
            </MessageDialog>
        );
    }
    if (snapshotIsDiffer || position.length !== 2) {
        return (
            <Observer>
                {() => (
                    <div className={styles.map__select}>
                        <div className={styles.map__warning}>
                            <SVGIcon
                                className={styles.map__icon}
                                SVGElement={AlertIcon}
                                height={14}
                            />
                            Выберите корректный адрес
                        </div>
                        <div
                            className={`
                                ${styles.map__preloader}
                                ${store && store.loading ? styles['map__preloder--loading'] : ''}
                            `}
                        >
                            <Preloader />
                        </div>
                        <div
                            className={`
                                ${styles.map__list}
                                ${store && store.loading ? styles['map__list--loading'] : ''}
                            `}
                        >
                            {
                                store && store.options
                                    ? (
                                        store.options.map((suggestions) => (
                                            <button
                                                key={suggestions.value}
                                                className={styles.map__item}
                                                type="button"
                                                onClick={() => {
                                                    handlePositionSelect(
                                                        suggestions.latitude,
                                                        suggestions.longitude,
                                                    );
                                                }}
                                            >
                                                {suggestions.value}
                                            </button>
                                        )))
                                    : null
                            }
                        </div>
                    </div>
                )}
            </Observer>
        );
    }
    return null;
};

MapOptions.propTypes = {
    useStores: PropTypes.func.isRequired,
    position: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    ),
    fullAddress: PropTypes.string,
    snapshotIsDiffer: PropTypes.bool,
    handleNavigationClick: PropTypes.func,
    handlePositionSelect: PropTypes.func,
    handleDefaultPosition: PropTypes.func,
};

MapOptions.defaultProps = {
    position: [],
    fullAddress: null,
    snapshotIsDiffer: false,
    handleNavigationClick: () => null,
    handlePositionSelect: () => null,
    handleDefaultPosition: () => null,
};

const MapMessages = ({
    position,
}) => {
    if (!position || position.length !== 2) {
        return (
            <MessageDialog
                title="Не задан адрес"
            >
                У объекта указан не полный адрес, или не указаны координаты.
            </MessageDialog>
        );
    }
    return null;
};

MapMessages.propTypes = {
    position: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    ),
};

MapMessages.defaultProps = {
    position: [],
};

const ObjectComponentMap = ({
    useStores,
    form,
    navigateToLocationTab,
    view,
}) => {
    const setNewPosition = (latitude, longitude) => {
        form.$('latitude').set('value', latitude);
        form.$('longitude').set('value', longitude);
        form.makeAddressSnapshot();
    };
    const setDefaultPosition = (latitude, longitude) => {
        setNewPosition(latitude, longitude);
    };
    return (
        <div className={`${styles.map}`}>
            <h2 className={styles['block-title']}>
                <span>Карта</span>
            </h2>
            <Observer>
                {() => {
                    const position = [
                        form.$('latitude').value,
                        form.$('longitude').value,
                    ].filter(Boolean);
                    return (
                        <div className={`${styles.map__container}`}>
                            <YandexMap position={position} />
                            {
                                view
                                    ? (
                                        <MapMessages
                                            position={position}
                                        />
                                    )
                                    : (
                                        <MapOptions
                                            useStores={useStores}
                                            fullAddress={form.fullAddress}
                                            position={position}
                                            snapshotIsDiffer={form.addressSnapshotIsDiffer}
                                            handleNavigationClick={navigateToLocationTab}
                                            handlePositionSelect={setNewPosition}
                                            handleDefaultPosition={setDefaultPosition}
                                        />
                                    )
                            }
                        </div>
                    );
                }}
            </Observer>
        </div>
    );
};

ObjectComponentMap.propTypes = {
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({
        $: PropTypes.func,
        makeSnapshot: PropTypes.func,
        fullAddress: PropTypes.string,
        addressSnapshotIsDiffer: PropTypes.bool,
        makeAddressSnapshot: PropTypes.func,
    }),
    navigateToLocationTab: PropTypes.func,
    view: PropTypes.bool,
};

ObjectComponentMap.defaultProps = {
    form: {
        $: () => null,
        makeSnapshot: () => null,
        fullAddress: null,
        addressSnapshotIsDiffer: false,
        makeAddressSnapshot: () => null,
    },
    navigateToLocationTab: () => null,
    view: false,
};

export default ObjectComponentMap;
