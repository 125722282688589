import React from 'react';
import PropTypes from 'prop-types';

import styles from './ContentWithNav.module.scss';

const ContentWithNavItemInner = ({ children }) => children;

ContentWithNavItemInner.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
};

/**
 * Functional component for display
 * default item for nav
 *
 * @param {object} navItem - params for component
 * @param {string|React.Component} navItem.children - nav items
 * @param {Function} navItem.onClick - handle on click event
 * @param {boolean} navItem.marker - show or not colorize indicator
 * right from text item
 * @param {string} navItem.markerColor - color for unread indicator
 * @param {boolean} navItem.isActive - is active item or not
 * @returns {React.FunctionComponent} (
 *  <ContentWithNavItem
 *      content={content}
 *      onClick={onClick}
 *      marker={marker}
 *      markerColor={markerColor}
 *  >
 *      {children}
 *  </ContentWithNavItem>
 * )
 */
const ContentWithNavItem = ({
    children,
    onClick,
    marker,
    markerColor,
    isActive,
}) => (
    // TODO change to button
    <button
        type="button"
        className={`
            ${styles.sidebar__item}
            ${isActive ? styles['sidebar__item--active'] : ''}
        `}
        onClick={onClick}
    >
        {children}
        {
            marker
                ? (
                    <span
                        className={styles['sidebar__item-circle']}
                        style={{
                            backgroundColor: `#${markerColor}`,
                        }}
                    />
                )
                : null
        }
    </button>
);

ContentWithNavItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    onClick: PropTypes.func.isRequired,
    marker: PropTypes.bool,
    markerColor: PropTypes.string,
    isActive: PropTypes.bool,
};

ContentWithNavItem.defaultProps = {
    marker: false,
    markerColor: 'ff7a00',
    isActive: false,
};

export default ContentWithNavItem;

export {
    ContentWithNavItem,
    ContentWithNavItemInner,
};
