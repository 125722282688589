import { DIRECTORIES } from '../../constants/operator';
import { SIZE } from '../../constants/shared';

export default [{
    name: 'companyId',
    type: 'directorySelect',
    directory: DIRECTORIES.BILLING_COMPANIES,
    searchable: true,
    clearable: true,
    value: { label: 'Не выбрано', value: null },
    size: 'md',
    label: 'Привязать к компании',
}, {
    type: 'integer',
    name: 'maxUserUseCount',
    label: 'MAX кол-во установок для пользователя',
    size: SIZE.MEDIUM,
}, {
    type: 'integer',
    name: 'maxCompanyUseCount',
    label: 'MAX кол-во установок для компании',
    size: SIZE.MEDIUM,
}, {
    type: 'checkbox',
    name: 'manualKey',
    label: 'Ввести ключ вручную',
    size: SIZE.MEDIUM,
}, {
    type: 'text',
    name: 'value',
    value: null,
    label: 'Ключ',
    size: SIZE.MEDIUM,
    hidden: true,
    disabled: true,
    rules: 'required|activationKey|min:8',
}];
