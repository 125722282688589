import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './ExportObjects.module.scss';

const ExportObjectsFieldset = ({
    fieldset: {
        label,
        id,
        name,
        hidden,
    },
    children,
}) => {
    if (hidden) return null;
    return (
        <fieldset className={`${styles['export-objects__fieldset']} ${styles[`export-objects__fieldset--${name}`] || ''}`}>
            {
                label
                    ? (
                        <Label
                            htmFor={id}
                            className={styles['export-objects__label']}
                        >
                            {label}
                        </Label>
                    )
                    : null
            }
            {children}
        </fieldset>
    );
};

ExportObjectsFieldset.propTypes = {
    fieldset: PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        hidden: PropTypes.bool,
    }).isRequired,
    children: PropTypes.element.isRequired,
};

export default ExportObjectsFieldset;
