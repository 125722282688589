export default {
    type: 'fieldset', // milestones
    name: 'milestones',
    fields: [{
        name: 'addedAtFrom',
        type: 'datepicker',
        label: 'Добавлен от',
        placement: 'bottom-end',
        clearable: true,
    }, {
        name: 'addedAtTo',
        type: 'datepicker',
        label: 'Добавлен до',
        placement: 'bottom-end',
        endOfDay: true,
        clearable: true,
    }, {
        name: 'checkedAtFrom',
        type: 'datepicker',
        label: 'Проверка от',
        placement: 'bottom-end',
        clearable: true,
    }, {
        name: 'checkedAtTo',
        type: 'datepicker',
        label: 'Проверка до',
        placement: 'bottom-end',
        endOfDay: true,
        clearable: true,
    }],
};
