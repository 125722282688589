/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import Phone from '../../../components/Phone';
import { POSITION } from '../../../constants/shared';

import styles from './Table.module.scss';

const CellPhone = ({
    value,
    cell: { row: { original: { id, phones } } },
    rowHint,
    onClick,
}) => {
    const handleClick = (e) => {
        e.stopPropagation();
        if (!onClick || typeof onClick !== 'function') {
            return;
        }
        e.preventDefault();
        onClick(id, value);
    };
    return (
        <div
            data-tip={`
                ${(phones
                    ? phones.map((item) => `<div>${item.contact}</div>`)
                    : ''
                )}
                ${value}
                ${(
                    rowHint
                        ? `<div class="${styles['table__row-hint']}">${rowHint}</div>`
                        : ''
                )}
            `}
            data-multiline
            data-html
            data-place={POSITION.RIGHT}
            data-class={`
                ${styles.table__hint}
                ${rowHint ? styles['table__hint--multiline'] : ''}
            `}
        >
            <Phone
                onClick={handleClick}
                className={`
                    ${styles.table__phone}
                    ${styles['table__phone--similar']}
                `}
            >
                {value}
            </Phone>
        </div>
    );
};

CellPhone.propTypes = {
    value: PropTypes.string,
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                phones: PropTypes.oneOfType([
                    PropTypes.arrayOf(
                        PropTypes.number,
                        PropTypes.string,
                    ),
                    PropTypes.number,
                    PropTypes.string,
                ]),
            }),
        }),
    }),
    onClick: PropTypes.func,
    rowHint: PropTypes.string,
};

CellPhone.defaultProps = {
    value: undefined,
    cell: {
        row: {
            original: {
                id: undefined,
            },
        },
    },
    onClick: null,
    rowHint: null,
};

export default CellPhone;
