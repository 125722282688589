import React from 'react';
import PropTypes from 'prop-types';

import styles from './Selected.module.scss';

const SelectedTitle = ({
    children,
}) => (
    <div className={styles.selected__title}>
        {children}
    </div>
);

SelectedTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ]),
        ),
    ]),
};

SelectedTitle.defaultProps = {
    children: null,
};

export default SelectedTitle;
