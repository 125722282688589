import { computed } from 'mobx';

const withRentItem = (Item) => (
    class extends Item {
        @computed
        get rentFlatTypeName() {
            const { rent } = this;
            if (!rent || !rent.rentFlatType) {
                return null;
            }
            return rent.rentFlatType.name;
        }

        @computed
        get exchange() {
            const { $sale } = this;
            return Boolean($sale?.canBeExchanged);
        }
    }
);

export default withRentItem;
