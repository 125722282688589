import React from 'react';
import PropTypes from 'prop-types';
import Error from '../Error';
import { SIZE } from '../../constants/operator';

import styles from './Textarea.module.scss';

const Textarea = ({
    className,
    id,
    name,
    value,
    placeholder,
    disabled,
    rows,
    error,
    size,
    children,
    onChange,
    onInput,
    readOnly,
    maxLength,
}) => (
    <div
        className={`
            ${styles.textarea}
            ${styles[`textarea--${size}`]}
            ${disabled ? styles['textarea--disabled'] : ''}
            ${readOnly ? styles['textarea--readonly'] : ''}
            ${error ? styles['textarea--error'] : ''}
            ${className}
        `}
    >
        <textarea
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            rows={rows}
            onChange={onChange}
            onInput={onInput}
            readOnly={readOnly}
            maxLength={maxLength}
        />
        {
            error
                ? (
                    <Error
                        className={styles.textarea__error}
                        error={error}
                        id={`tooltip-${id}`}
                        size={size}
                    />
                )
                : null
        }
        {children}
    </div>
);

Textarea.SIZE = { ...SIZE };

Textarea.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    size: PropTypes.oneOf(Object.values(Textarea.SIZE)),
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    error: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    readOnly: PropTypes.bool,
};

Textarea.defaultProps = {
    className: '',
    id: undefined,
    placeholder: '',
    value: undefined,
    size: Textarea.SIZE.MEDUIM,
    disabled: false,
    rows: undefined,
    error: '',
    children: null,
    onChange: null,
    onInput: null,
    readOnly: false,
    maxLength: undefined,
};

export default Textarea;
