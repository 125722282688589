import React from 'react';
import Button from './Button';

import buttonStyles from './Button.module.scss';

const ButtonMenu = ({
    children,
    onClick,
    className,
    icon,
    disabled,
}) => (
    <Button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className={`${buttonStyles['button--menu']} ${className}`}
    >
        {icon}
        {children}
    </Button>
);

ButtonMenu.SIZE = {
    ...Button.SIZE,
};

ButtonMenu.TYPE = {
    ...Button.TYPE,
};

ButtonMenu.COLOR = {
    ...Button.COLOR,
};

ButtonMenu.propTypes = {
    ...Button.propTypes,
};

ButtonMenu.defaultProps = {
    ...Button.defaultProps,
};

export default ButtonMenu;
