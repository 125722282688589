import { action, observable } from 'mobx';
import List from '../List';
import listItemWithCursor from './listItemWithCursor';

const listWithCursor = (Store) => {
    if (List !== Store && !Object.prototype.isPrototypeOf.call(List, Store)) {
        throw new Error('A List class must be provided');
    }
    return class extends Store {
        @observable
        cursor = null;

        constructor(...rest) {
            super(...rest);
            const { Item } = this;
            this.Item = listItemWithCursor(Item);
        }

        @action
        setCursor(id) {
            this.cursor = id;
        }

        @action
        setNextCursor() {
            const { list, cursor } = this;
            if (!list.length) {
                return;
            }
            const last = list[list.length - 1].id;
            const first = list[0].id;
            if (cursor === last) {
                return;
            }
            if (cursor === null) {
                this.setCursor(first);
                return;
            }
            const next = list[list.findIndex((item) => item.id === cursor) + 1].id;
            this.setCursor(next);
        }

        @action
        setPrevCursor() {
            const { list, cursor } = this;
            if (!list.length) {
                return;
            }
            const first = list[0].id;
            if (cursor === first) {
                return;
            }
            if (cursor === null) {
                this.setCursor(first);
                return;
            }
            const next = list[list.findIndex((item) => item.id === cursor) - 1].id;
            this.setCursor(next);
        }
    };
};

export default listWithCursor;
