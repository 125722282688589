import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { POSITION } from '../../../constants/shared';
import { CrossIcon } from '../../../components/Icons/icons';
import styles from './PresetList.module.scss';
import ReactTooltip from '../../../lib/react-tooltip';

const PresetListItemConfirmNew = ({
    onCloseNewClick,
    onNewOpenClick,
    item,
}) => {
    const tooltipRef = useRef(null);
    useEffect(() => () => {
        if (tooltipRef.current) {
            ReactTooltip.hide(tooltipRef.current);
        }
    }, [tooltipRef]);
    return (
        <div className={styles.list__row}>
            <button
                type="button"
                className={`${styles.preset__check}`}
                onClick={onCloseNewClick}
                ref={tooltipRef}
                data-tip="С новыми ознакомился"
                data-place={POSITION.RIGHT}
                data-class={styles.preset__hint}
            >
                <SVGIcon
                    SVGElement={CrossIcon}
                    height={8}
                />
            </button>
            <button
                type="button"
                className={`
                    ${styles.preset__trigger}
                    ${styles['preset__trigger--bottom']}
                `}
                onClick={onNewOpenClick}
            >
                <span
                    className={styles.preset__count}
                >
                    {item.new > 0 ? '+' : ''}
                    {item.new}
                </span>
                <span
                    className={styles.preset__title}
                >
                    Новые от
                    {' '}
                    {item.checkedAt ? `${item.checkedAt}` : `${item.compCountedAt}`}
                </span>
            </button>
        </div>
    );
};

PresetListItemConfirmNew.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        count: PropTypes.number,
        countedAt: PropTypes.string,
        checkedAt: PropTypes.string,
        compCountedAt: PropTypes.string,
        new: PropTypes.number,
    }),
    onCloseNewClick: PropTypes.func,
    onNewOpenClick: PropTypes.func,
};

PresetListItemConfirmNew.defaultProps = {
    item: {},
    onCloseNewClick: () => null,
    onNewOpenClick: () => null,
};

export default PresetListItemConfirmNew;
