/* global document */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { DIRECTORIES } from '../../../constants/shared';
import {
    ContentWithNav,
    ContentWithNavList,
    ContentWithNavItemInner,
    ContentWithNavMain,
} from '../../../components/ContentWithNav';
import FormElements from '../Form';
import DetailedViewField from './DetailedViewField';
import DetailedViewFieldset from './DetailedViewFieldset';
import DetailedViewGroup from './DetailedViewGroup';
import DetailedViewInputList from './DetailedViewInputList';
import DetailedViewSidebar from './DetailedViewSidebar';
import DetailedViewWrapper from './DetailedViewWrapper';
import Preloader from '../../../components/Preloader';

import styles from './DetailedView.module.scss';

const DetailedView = ({
    useStores,
    id,
    store,
    wrapper,
    handleChangeActive,
    createContentComponent,
    createTitleComponent,
    formWrappers,
    children,
}) => {
    const { sessionStore, directoriesStore } = useStores();
    const { form, objectContentMap = [] } = store;
    const formComponents = { ...DetailedView.defaultProps.formWrappers, ...formWrappers };
    const getFormElement = (name) => (
        form.render.find(({ name: fieldsetName }) => fieldsetName === name)
    );
    useEffect(() => {
        if (store.isExclusive && !store.id && sessionStore.companyData) {
            store.setCompany(sessionStore.companyData);
        }
    }, []);
    useEffect(() => {
        // eslint-disable-next-line max-len
        directoriesStore[DIRECTORIES.INCORRECT_OPTIONS].options = directoriesStore[DIRECTORIES.INCORRECT_OPTIONS].options.map((option) => {
            const isChecked = store.incorrect?.includes(option.value);
            return ({
                ...option,
                disabled: sessionStore.companyData ? isChecked : !isChecked,
            });
        });
    }, [store.incorrect]);
    // eslint-disable-next-line react/prop-types
    const mapTitle = ({ label, name, hiddenInSideMenu }) => {
        if (getFormElement(name)) {
            return (
                <ContentWithNavItemInner key={`${name}-${id}`} hidden={hiddenInSideMenu}>
                    {label}
                </ContentWithNavItemInner>
            );
        }
        return createTitleComponent(name, { label, key: `${name}-${id}` });
    };

    const contentTitle = objectContentMap.length
        ? objectContentMap.map(mapTitle)
        : form.render.map(mapTitle);

    const contentMain = objectContentMap.length
        ? objectContentMap.map(({ name, label }, index) => {
            const formElement = getFormElement(name);

            // Скрываем поля "Внешнее примечание" и "Внутреннее примечание"
            // при добавлении эксклюзива
            if (name === 'additionalInfo' && store.isExclusive && id === 'new') {
                const fieldSetIndex = formElement.fields.findIndex((set) => set.name === 'operatorNoteWrapper');
                if (fieldSetIndex > -1) {
                    formElement.fields.splice(fieldSetIndex, 1);
                }
            }

            const CustomComponent = createContentComponent(name, label);
            let output;
            if (CustomComponent !== null) {
                output = <CustomComponent />;
            } else if (formElement) {
                output = (
                    FormElements({
                        useStores,
                        form,
                        elements: [formElement],
                        components: formComponents,
                    })
                );
            }
            return (
                <React.Fragment key={`${name}-${id}`}>
                    {
                        index === 0 && store.moderationWarning
                            ? (
                                <div className={`${styles.warning} ${styles.fieldset}`}>
                                    Редактирование объекта станет
                                    возможно после того, как пройдет модерация
                                </div>
                            )
                            : null
                    }
                    {output}
                </React.Fragment>
            );
        })
        : FormElements({
            useStores,
            form,
            elements: form.render,
            components: formComponents,
        });
    const scrollToErrorField = (wrapperNode) => (
        form.on('error', (errors) => {
            const header = wrapperNode.previousElementSibling;
            const headerOffset = header.getBoundingClientRect().top;
            const top = Object.keys(errors).reduce((carry, fieldName) => {
                const { id: currentFieldId } = form.$(fieldName);
                const currentFieldNode = document.querySelector(`#${currentFieldId}`);
                if (!currentFieldNode || errors[fieldName] === null) {
                    return carry;
                }
                const { top: currentTop } = currentFieldNode.getBoundingClientRect();
                if (!carry || currentTop < carry) {
                    return currentTop;
                }
                return carry;
            }, undefined);
            const offset = !top ? 0 : top - header.offsetHeight - headerOffset;
            wrapperNode.scrollTo({
                top: wrapperNode.scrollTop + offset,
            });
        })
    );
    return (
        <Observer>
            {() => (
                <>
                    <Preloader
                        className={`
                            ${styles.preloader}
                            ${store.loading ? styles['preloader--loading'] : ''}
                        `}
                    />
                    <div
                        className={`
                            ${styles.form}
                            ${store.loading ? styles['form--loading'] : ''}
                        `}
                    >
                        <form
                            onSubmit={form.onSubmit}
                            className={styles.form}
                        >
                            <ContentWithNav
                                components={{
                                    ContentWrapper: wrapper,
                                    SidebarWrapper: DetailedViewSidebar,
                                }}
                                memoKey={store.memoKey}
                                topOffset={60}
                                onChangeActive={handleChangeActive}
                                onMount={scrollToErrorField}
                            >
                                {({ setActiveIndex }) => (
                                    <>
                                        <ContentWithNavList>
                                            {contentTitle}
                                        </ContentWithNavList>
                                        <ContentWithNavMain>
                                            {
                                                contentMain.filter(Boolean).map((child) => (
                                                    React.cloneElement(
                                                        child,
                                                        {
                                                            setActiveIndex,
                                                        },
                                                    )
                                                ))
                                            }
                                        </ContentWithNavMain>
                                    </>
                                )}
                            </ContentWithNav>
                            {children}
                        </form>
                    </div>
                </>
            )}
        </Observer>
    );
};

DetailedView.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    store: PropTypes.shape({
        id: PropTypes.string,
        setCompany: PropTypes.func,
        isExclusive: PropTypes.bool,
        incorrect: PropTypes.arrayOf(PropTypes.number),
        form: PropTypes.shape({
            onSubmit: PropTypes.func,
            render: PropTypes.arrayOf(PropTypes.shape({})),
            on: PropTypes.func,
            $: PropTypes.func,
        }),
        memoKey: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        loading: PropTypes.bool,
        moderationWarning: PropTypes.bool,
        objectContentMap: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                label: PropTypes.string,
            }),
        ),
    }).isRequired,
    useStores: PropTypes.func,
    wrapper: PropTypes.func,
    formWrappers: PropTypes.shape({}),
    handleChangeActive: PropTypes.func,
    createContentComponent: PropTypes.func,
    createTitleComponent: PropTypes.func,
    children: PropTypes.element,
};

DetailedView.defaultProps = {
    useStores: () => ({ dialogStore: {} }),
    wrapper: DetailedViewWrapper,
    children: null,
    handleChangeActive: () => null,
    createContentComponent: () => null,
    createTitleComponent: () => null,
    formWrappers: {
        FieldWrapper: DetailedViewField,
        FieldsetWrapper: DetailedViewFieldset,
        FieldGroupWrapper: DetailedViewGroup,
        InputListWrapper: DetailedViewInputList,
    },
};

export default DetailedView;
