import React from 'react';
import { Link } from 'react-router-dom';
import LoginPage from '../../shared/LoginPage';
import { ButtonLink } from '../../../components/Button';

import styles from './Register.module.scss';

const RegisterSelect = () => (
    <LoginPage
        title="Регистрация"
        description="Сначала зарегистрируйте компанию, если она еще не зарегистрирована"
        footer={(
            <ButtonLink
                Component={Link}
                size={ButtonLink.SIZE.MEDIUM}
                color={ButtonLink.COLOR.TRANSPARENT}
                href="/login"
                target="_self"
            >
                Войти
            </ButtonLink>
        )}
    >
        <>
            <ButtonLink
                Component={Link}
                className={styles.register__select}
                size={ButtonLink.SIZE.LARGE}
                color={ButtonLink.COLOR.BLUE}
                href="/register/user"
                target="_self"
                block
            >
                Пользователь
            </ButtonLink>
            <ButtonLink
                Component={Link}
                className={styles.register__select}
                size={ButtonLink.SIZE.LARGE}
                color={ButtonLink.COLOR.WHITE_BLUE}
                href="/register/company/key"
                target="_self"
                block
            >
                Компания
            </ButtonLink>
        </>
    </LoginPage>
);

export default RegisterSelect;
