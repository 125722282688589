import splitParams from './split-params';

/**
 * @description combine routes into more detailed tree
 * @param {import('../operator/index.js').route[]} routes - routes array
 * @param {object} parent - parent route path to combine with current
 * @returns {object} combined routes
 */
const combineRoutes = (routes, parent = {}) => (
    routes.map((route) => {
        const modified = route;
        const {
            routes: children,
            pageTitle,
            icon,
            path,
        } = modified;
        const {
            icon: prevIcon,
            pageTitle: prevPageTitle,
            link = '',
        } = parent;
        const parentFullpath = link === '/' ? '' : link;
        const fullpath = `${parentFullpath}${path}`;
        const params = splitParams(path);
        modified.fullpath = fullpath;
        modified.pageTitle = pageTitle || prevPageTitle;
        modified.icon = icon !== undefined ? icon : prevIcon;
        if (params && params.length) {
            const restPath = params.map((param) => param[0]).join('/');
            modified.link = `${parentFullpath}/${restPath}`;
        } else {
            modified.link = fullpath.replace(/\/:(.*?)\/?$/, '');
        }
        if (children && children.length) {
            modified.routes = combineRoutes(children, modified);
        }
        return modified;
    })
);

export default combineRoutes;
