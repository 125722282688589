import { withAddressField } from '../../../../helpers/Form/mixins';
import BaseStrategy from './BaseStrategy';
import FlatItem from '../item/FlatItem';
import { TABLE_CELL } from '../../../../constants/shared';

class FlatStrategy extends BaseStrategy {
    constructor() {
        super('flats');
        this.Item = FlatItem;
    }

    // eslint-disable-next-line class-methods-use-this
    get sortOrder() {
        return [
            TABLE_CELL.ROOMS_COUNT.sortName,
            TABLE_CELL.DISTRICT_NAME.sortName,
            TABLE_CELL.STREET_NAME.sortName,
            TABLE_CELL.HOUSE_NUMBER.sortName,
            TABLE_CELL.PRICE_DELIMITED.sortName,
            TABLE_CELL.SQUARE.sortName,
            TABLE_CELL.FLOOR_COUNT.sortName,
            TABLE_CELL.SERIES_NAME.sortName,
            TABLE_CELL.DISTRICT_LANDMARK_NAME.sortName,
            TABLE_CELL.DATE_OF_COMPLETION.sortName,
        ];
    }

    createFilterForm(mixins = []) {
        const { objectType } = this;
        const form = super.createFilterForm([
            withAddressField({
                area: 'areaIds',
                city: 'cityIds',
                street: 'streetIds',
                district: 'cityDistrictIds',
                districtLandmark: 'cityDistrictLandmarkIds',
                house: 'houseIds',
                rooms: 'rooms',
            }),
            ...mixins,
        ]);

        const isAreaHidden = () => form.$('groupBy').value !== 'cityDistrictIds';

        Object.defineProperty(form.$('radius'), 'hidden', { get: () => !isAreaHidden() });
        Object.defineProperty(form.$('areaIds'), 'hidden', { get: isAreaHidden });
        Object.defineProperty(form.$('cityIds'), 'hidden', { get: isAreaHidden });
        Object.defineProperty(form.$('houseIds'), 'hidden', { get: isAreaHidden });
        Object.defineProperty(form.$('streetIds'), 'hidden', { get: isAreaHidden });
        Object.defineProperty(form.$('cityDistrictIds'), 'hidden', { get: isAreaHidden });

        if (objectType === 'flats') {
            Object.defineProperty(form.$('complexIds'), 'hidden', { get: isAreaHidden });
        }
        if (objectType === 'flats' || objectType === 'commercial') {
            Object.defineProperty(form.$('cityDistrictLandmarkIds'), 'hidden', { get: isAreaHidden });
        }

        form.$('groupBy').observe(({ change }) => {
            const { newValue } = change;
            if (newValue === 'cityDistrictIds') {
                form.$('radius').set('value', '');
                form.$('address').set('value', '');
                form.$('latitude').set('value', '');
                form.$('longitude').set('value', '');
            } else if (newValue === 'radius') {
                form.$('areaIds').set('value', '');
                form.$('cityIds').set('value', '');
                form.$('cityDistrictIds').set('value', '');
                form.$('streetIds').set('value', '');
                form.$('houseIds').set('value', '');
                form.$('cityDistrictLandmarkIds').set('value', '');
                form.$('complexIds').set('value', '');
            }
        });

        return form;
    }

    /** Создать форму с настройками группировки */
    createGroupingForm() {
        const form = super.createGroupingForm();
        return form;
    }
}

export default FlatStrategy;
