import { action, observable } from 'mobx';
import { EventEmitter } from '../../../helpers/EventEmitter';
import {
    Directory,
    SearchableDirectory,
} from './entities';

class Directories extends EventEmitter {
    @observable
    defaultsLoaded = false;

    constructor(directories = { searchable: [], lists: [], required: [] }, service) {
        super();
        const { lists, searchable, required } = directories;
        lists.forEach((directory) => {
            this[directory] = new Directory(directory, service);
        });
        searchable.forEach((directory) => {
            this[directory] = new SearchableDirectory(directory, service);
        });
        this.service = service;
        this.required = required;
    }

    on(...rest) {
        return this.subscribe(...rest);
    }

    loadDefaults() {
        const { required } = this;
        this.service.getDefaults()
            .then((directoriesWithDefaults) => {
                const promises = directoriesWithDefaults.reduce((carry, directory) => {
                    if (!this[directory] || !required.includes(directory)) {
                        return carry;
                    }
                    carry.push(
                        this.service.getDefault(directory)
                            .then((response) => (
                                Promise.resolve({
                                    name: directory,
                                    data: response,
                                })
                            )),
                    );
                    return carry;
                }, []);
                return Promise.allSettled(promises);
            })
            .then((response) => this.setDefaults(response))
            .catch((e) => {
                console.error(e);
            });
    }

    @action
    setDefaults(list = []) {
        list.forEach(({ value: directory }) => {
            if (!directory) {
                return;
            }
            const { name, data } = directory;
            this[name].setDefault(data);
        });
        this.emitDefaultsLoaded();
    }

    @action
    emitDefaultsLoaded() {
        this.defaultsLoaded = true;
        // HACK: pull it from current event loop tick
        setTimeout(() => {
            this.emit('defaults:loaded');
        }, 0);
    }
}

export default Directories;
