import React from 'react';
import PropTypes from 'prop-types';
import {
    Map,
    YMaps,
    Placemark,
    ZoomControl,
    TypeSelector,
    FullscreenControl,
} from 'react-yandex-maps';
import { YANDEX_MAPS_API_KEY } from '../../config/operator';

const YandexMap = ({ position }) => {
    const marker = position ? position.map((c) => +c) : undefined;

    return (
        <YMaps query={{ apikey: YANDEX_MAPS_API_KEY, lang: 'ru_RU' }}>
            <Map
                state={{
                    zoom: 17,
                    center: marker || [56.0183900, 92.8671700],
                }}
                className="yandex-map"
            >
                <ZoomControl />
                <TypeSelector />
                <FullscreenControl />
                {marker && (
                    <Placemark geometry={marker} />
                )}
            </Map>
        </YMaps>
    );
};

YandexMap.propTypes = {
    position: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    ),
};

YandexMap.defaultProps = {
    position: [],
};

export default YandexMap;
