import createBaseSessionService from '../../shared/SessionService';

const createSessionService = (request) => {
    const baseService = createBaseSessionService(request);
    return {
        ...baseService,
        login: (data) => (
            request.post('/auth/token', data)
                .then(({ token: { accessToken: value, expiresIn }, ...rest }) => (
                    Promise.resolve({
                        token: {
                            value,
                            expiresIn,
                        },
                        ...rest,
                    })
                ))
        ),

        loadData: () => (
            request.get('/billing/info')
                .then((data) => Promise.resolve(data))
        ),

        reactivate: () => (
            request.put('/auth/device')
                .then(({ token, ...rest }) => {
                    const data = {
                        ...rest,
                    };
                    if (token) {
                        const { accessToken: value, expiresIn } = token;
                        data.token = {
                            value,
                            expiresIn,
                        };
                    }
                    return Promise.resolve(data);
                })
        ),
    };
};

export default createSessionService;
