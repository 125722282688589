import { action, computed, observable } from 'mobx';
import ListItem from './entities/ListItem';
import List from './List';
import { STATES } from '../DataSource';

class ListWithDataSource extends List {
    @observable firstLoad = true;

    constructor(Item = ListItem, dataSource, paginationHandler) {
        super(Item);
        this.dataSource = dataSource;
        this.paginationHandler = paginationHandler;
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get error() {
        const { dataSource } = this;
        if (dataSource.state === STATES.ERROR) {
            return dataSource.error;
        }
        return null;
    }

    @computed
    get isEmpty() {
        const { firstLoad, loading, list } = this;
        return !loading && !firstLoad && list.length === 0;
    }

    load(...params) {
        const { paginationHandler, firstLoad } = this;
        let handlePagination;
        if (paginationHandler) {
            handlePagination = paginationHandler.prepareRequest(...params);
        } else {
            handlePagination = Promise.resolve(params);
        }
        return handlePagination
            .then((prepared) => this.getList(...prepared))
            .then((response) => {
                if (firstLoad) {
                    this.disableFirstLoad();
                }
                return Promise.resolve(response);
            })
            .then((response) => {
                if (paginationHandler) {
                    return paginationHandler.handleResponse(response);
                }
                return Promise.resolve(response);
            });
    }

    // eslint-disable-next-line class-methods-use-this
    onLoad() {}

    @action
    disableFirstLoad() {
        this.firstLoad = false;
    }
}

export default ListWithDataSource;
