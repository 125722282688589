import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import NotepadItem from '../../../stores/shared/NotepadList/item';
import Preloader from '../../../components/Preloader';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { EditIcon } from '../../../components/Icons/icons';

import styles from './NotepadList.module.scss';

const List = ({
    notepads,
    loading,
    loaded,
    onEditNotepadClick,
    onNotepadOpen,
}) => {
    const handleEditClick = (item) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        onEditNotepadClick(item);
    };
    const handleOpenClick = (item) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        onNotepadOpen(item.id, item.name);
    };
    return (
        <div className={styles.list__wrapper}>
            <div className={`${styles.list__preloader} ${loading ? styles['list__preloader--loading'] : ''}`}>
                <Preloader width={75} />
            </div>
            <div className={`${styles.list__content} ${loading ? styles['list__content--loading'] : ''} ${loaded ? styles['list__content--loaded'] : ''}`}>
                {
                    notepads && notepads.length
                        ? (
                            notepads.map((item) => (
                                <Observer key={`notepad-${item.id}`}>
                                    {() => (
                                        <div className={styles.list__item}>
                                            <button
                                                type="button"
                                                data-tip={item.name.length > 35 ? item.name : null}
                                                data-class={styles.hint}
                                                className={styles.list__trigger}
                                                onClick={handleOpenClick(item)}
                                            >
                                                <span
                                                    className={styles.list__count}
                                                >
                                                    {item.count}
                                                </span>
                                                <span
                                                    className={styles.list__title}
                                                >
                                                    {item.name}
                                                </span>
                                                <span
                                                    aria-hidden="true"
                                                    className={styles.list__edit}
                                                    onClick={handleEditClick(item)}
                                                >
                                                    <SVGIcon
                                                        SVGElement={EditIcon}
                                                        height={14}
                                                    />
                                                </span>
                                            </button>
                                        </div>
                                    )}
                                </Observer>
                            ))
                        ) : (
                            <div className={styles.list__empty}>
                                Вы пока не создали ни одного блокнота
                            </div>
                        )
                }
            </div>
        </div>
    );
};

List.propTypes = {
    notepads: PropTypes.arrayOf(
        PropTypes.instanceOf(NotepadItem),
    ),
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    onEditNotepadClick: PropTypes.func,
    onNotepadOpen: PropTypes.func,
};

List.defaultProps = {
    notepads: null,
    loading: true,
    loaded: false,
    onEditNotepadClick: () => null,
    onNotepadOpen: () => null,
};

export default List;
