export default [{
    name: 'password',
    type: 'password',
    label: 'Пароль',
    rules: 'required|string|min:8|regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d){8,}/',
    size: 'lg',
    icon: 'lock',
}, {
    name: 'passwordConfirm',
    type: 'password',
    label: 'Повторите пароль',
    rules: 'required|string|same:password',
    size: 'lg',
    icon: 'lock',
}];
