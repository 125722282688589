import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { SearchIcon, ClearIcon } from '../../../components/Icons/icons';

import styles from './Filter.module.scss';

const FilterFooter = ({
    form,
}) => (
    <div className={styles.filter__footer}>
        <IconButton
            type="submit"
            className={styles.filter__submit}
            size={IconButton.SIZE.LARGE}
            color={IconButton.COLOR.BLUE}
            icon={(<SVGIcon SVGElement={SearchIcon} />)}
            block
        >
            Искать
        </IconButton>
        <IconButton
            type="reset"
            size={IconButton.SIZE.MEDIUM}
            color={IconButton.COLOR.WHITE}
            icon={(<SVGIcon SVGElement={ClearIcon} />)}
            block
            onClick={form.onReset}
        >
            Очистить
        </IconButton>
    </div>
);

FilterFooter.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func,
        onReset: PropTypes.func,
        render: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
};

export default FilterFooter;

export {
    FilterFooter,
};
