const {
    REACT_APP_API_PROTOCOL,
    REACT_APP_API_HOST,
    REACT_APP_API_PREFIX,
    REACT_APP_DADATA_API_KEY,
    REACT_APP_ROLLBAR_TOKEN,
    REACT_APP_ROLLBAR_ENVIRONMENT,
    REACT_APP_ROLLBAR_CODE_VERSION,
    REACT_APP_YANDEX_MAPS_API_KEY,
    REACT_APP_OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT,
    REACT_APP_CITY_NAME_CASES,
} = process.env;

const APP_NAME = 'operator';
const API_PROTOCOL = REACT_APP_API_PROTOCOL || 'http';
const API_HOST = REACT_APP_API_HOST || 'localhost:3000';
const API_PREFIX = REACT_APP_API_PREFIX ? `/${REACT_APP_API_PREFIX}` : '';
const DADATA_API_KEY = REACT_APP_DADATA_API_KEY || '';
const ROLLBAR_TOKEN = REACT_APP_ROLLBAR_TOKEN;
const ROLLBAR_ENVIRONMENT = REACT_APP_ROLLBAR_ENVIRONMENT || 'production';
const ROLLBAR_CODE_VERSION = REACT_APP_ROLLBAR_CODE_VERSION || '1.0.0';
const YANDEX_MAPS_API_KEY = REACT_APP_YANDEX_MAPS_API_KEY;
// eslint-disable-next-line max-len
const OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT = REACT_APP_OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT
    === true.toString();
const CITY_NAME_CASES = REACT_APP_CITY_NAME_CASES ? JSON.parse(REACT_APP_CITY_NAME_CASES) : '';

export {
    APP_NAME,
    API_PROTOCOL,
    API_HOST,
    API_PREFIX,
    DADATA_API_KEY,
    ROLLBAR_TOKEN,
    ROLLBAR_ENVIRONMENT,
    ROLLBAR_CODE_VERSION,
    YANDEX_MAPS_API_KEY,
    OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT,
    CITY_NAME_CASES,
};
