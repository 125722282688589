export default [{
    type: 'fieldset', // ad
    name: 'notepad',
    fields: [{
        name: 'name',
        type: 'text',
        label: 'Название блокнота',
        size: 'lg',
        rules: 'required|min:3',
    }],
}];
