import BaseStrategy from './BaseStrategy';
import FlatStrategy from './FlatStrategy';
import HouseStrategy from './HouseStrategy';
import SteadStrategy from './SteadStrategy';
import CommercialStrategy from './CommercialStrategy';

export default BaseStrategy;
export {
    FlatStrategy,
    HouseStrategy,
    SteadStrategy,
    CommercialStrategy,
};
