import { computed } from 'mobx';
import capitalizeFirstLetter from '../../../utils/capitalize-first-letter';
import ObjectList from './ObjectList';
import tables from './tables';

class RelatedObjectList extends ObjectList {
    constructor(strategy, id, phone, requestType) {
        super(strategy);
        this.id = id;
        this.phone = phone;
        this.requestType = requestType;
    }

    get $tableHeaders() {
        const { strategy: { objectType } } = this;
        return tables[`related${capitalizeFirstLetter(objectType)}`];
    }

    @computed
    get tableHeaders() {
        return this.$tableHeaders;
    }

    @computed
    get tableData() {
        const { filterId } = this;

        const filtredList = this.list.filter(({ id }) => id !== filterId);
        const sortedGroups = {};

        filtredList.forEach((item) => {
            if (sortedGroups[item.status.id]) {
                sortedGroups[item.status.id].push(item);
            } else {
                sortedGroups[item.status.id] = [item];
            }
        });

        const sortedList = [
            ...(sortedGroups[2] || []),
            ...(sortedGroups[3] || []),
            ...(sortedGroups[30] || []),
            ...(sortedGroups[20] || []),
            ...(sortedGroups[1] || []),
            ...(sortedGroups[10] || []),
        ];

        return sortedList;
    }

    get storageName() {
        return `related${capitalizeFirstLetter(super.storageName)}`;
    }

    createFilterForm() {
        return this.filterForm;
    }

    getList(cursor) {
        const {
            strategy: { objectType },
            id,
            phone,
            requestType,
        } = this;
        return this.dataSource.getRelatedObjects(
            id,
            phone,
            cursor,
            {
                objectType,
                requestType,
            },
        );
    }
}

export default RelatedObjectList;
