import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';
import { POSITION } from '../../../constants/shared';

import styles from './DetailedView.module.scss';

const DetailedViewField = ({
    name,
    label,
    id,
    hidden,
    type,
    children,
    tooltip,
}) => {
    if (hidden) return null;
    return (
        <div className={`${styles.field} ${styles[`field--${name}`]} ${hidden ? 'hidden' : ''}`}>
            {
                label && type !== 'contact'
                    ? (
                        <Label
                            className={styles['field-label']}
                            htmlFor={id}
                        >
                            {
                                tooltip
                                    ? (
                                        <div
                                            className={styles.tooltip}
                                            data-tip={tooltip}
                                            data-place={POSITION.RIGHT}
                                            data-class={styles.tooltip__content}
                                            data-multiline
                                        >
                                            ?
                                        </div>
                                    )
                                    : null
                            }
                            {label}
                        </Label>
                    )
                    : null
            }
            {children}
        </div>
    );
};

DetailedViewField.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    hidden: PropTypes.bool,
    children: PropTypes.element.isRequired,
    tooltip: PropTypes.string,
};

DetailedViewField.defaultProps = {
    name: '',
    type: '',
    label: '',
    hidden: false,
    tooltip: '',
};

export default DetailedViewField;
