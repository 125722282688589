import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'flats',
    fields: [{
        type: 'fieldset',
        name: 'location',
        fields: [{
            name: 'cityIds',
            type: 'directorySearch',
            directory: DIRECTORIES.CITY,
            label: 'Населенный пункт',
            multiple: true,
            clearable: true,
            searchable: true,
            withDefault: true,
            isDropdown: true,
        }, {
            name: 'cityDistrictIds',
            type: 'directorySearch',
            directory: DIRECTORIES.CITY_DISTRICT,
            label: 'Район',
            multiple: true,
            clearable: true,
            searchable: true,
            isDropdown: true,
        }],
    }, {
        type: 'fieldset',
        name: 'house',
        fields: [{
            name: 'rentFlatTypeIds',
            type: 'directorySelect',
            directory: DIRECTORIES.RENT_FLAT_TYPE,
            label: 'Тип жилья',
            searchable: true,
            multiple: true,
            clearable: true,
        }, {
            name: 'rooms',
            type: 'fieldgroup',
            label: 'Комнат',
            fields: [{
                name: 'roomsFrom',
                type: 'integer',
                prefix: 'от',
            }, {
                name: 'roomsTo',
                type: 'integer',
                prefix: 'до',
            }],
        }, {
            name: 'seriesIds',
            type: 'directorySelect',
            directory: DIRECTORIES.SERIES,
            label: 'Серия (тип)',
            multiple: true,
            searchable: true,
            clearable: true,
            isDropdown: true,
        }, {
            name: 'square',
            type: 'fieldgroup',
            label: 'Площадь, м²',
            fields: [{
                name: 'totalAreaFrom',
                type: 'numeric',
                prefix: 'от',
            }, {
                name: 'totalAreaTo',
                type: 'numeric',
                prefix: 'до',
            }],
        }, {
            name: 'furnitureIds',
            type: 'directorySelect',
            directory: DIRECTORIES.FURNISHING,
            label: 'Мебель',
            searchable: true,
            multiple: true,
            clearable: true,
        }],
    }],
}];
