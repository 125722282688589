import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { RadioList } from '../../../components/Input';
import Preloader from '../../../components/Preloader';

import styles from './Directory.module.scss';

const DirectoryRadioList = ({ useStores, directory, ...props }) => {
    const { directoriesStore } = useStores();
    const store = directoriesStore[directory];
    useEffect(() => { store.loadData(); });
    return (
        <Observer>
            {() => (
                store.loaded
                    ? (
                        <RadioList
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                            options={store.options || []}
                        />
                    )
                    : (
                        <div className={styles['tag-list-preloader']}>
                            <Preloader
                                width={21}
                            />
                        </div>
                    )
            )}
        </Observer>
    );
};

DirectoryRadioList.propTypes = {
    useStores: PropTypes.func.isRequired,
    directory: PropTypes.string.isRequired,
};

export default DirectoryRadioList;
