import { computed } from 'mobx';

const withSaleItem = (Item) => (
    class extends Item {
        @computed
        get dateOfCompletion() {
            const { sale, yearOfConstruction } = this;
            if (!sale || !sale.yearOfCompletion) {
                return yearOfConstruction;
            }
            const { yearOfCompletion, quarterOfCompletion } = sale;
            let output = yearOfCompletion;
            if (quarterOfCompletion) {
                output += `.${quarterOfCompletion}`;
            }
            return output;
        }

        @computed
        get exchange() {
            const { $sale } = this;
            return Boolean($sale?.canBeExchanged);
        }
    }
);

export default withSaleItem;
