import { observable, runInAction } from 'mobx';
import createForm from '../../../helpers/Form';
import clearObject from '../../../utils/clear-object';
import { priceStatsForms } from '../../../forms/client';
import { withAddressField } from '../../../helpers/Form/mixins';
import { PriceStatsService } from '../../../services/client';
import {
    DEAL_TYPE,
    DEAL_TYPE_NAME,
    OBJECT_TYPE,
    OBJECT_TYPE_NAME,
    PRICE_ANALYSIS_STATUS,
    REQUEST_REPEAT_INTERVAL,
} from '../../../constants/shared';

class PriceStats {
    form;

    forms = {
        STEAD: null,
        HOUSE: null,
        FLAT_RENT: null,
        FLAT_SALE: null,
        COMMERTIAL: null,
    };

    @observable stats = []

    @observable loading = false

    @observable formToShow;

    constructor() {
        const withAddress = [withAddressField({
            city: 'cityIds',
            district: 'cityDistrictIds',
        })];
        this.form = createForm(priceStatsForms.general);
        this.forms = {
            STEAD: createForm(priceStatsForms.stead, withAddress),
            HOUSE: createForm(priceStatsForms.house, withAddress),
            FLAT_RENT: createForm(priceStatsForms.rentFlat, withAddress),
            FLAT_SALE: createForm(priceStatsForms.saleFlat, withAddress),
            COMMERCIAL: createForm(priceStatsForms.commercial, withAddress),
        };

        this.initFormListeners();

        this.init = this.init.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    init() {
        if (!this.stats.length) {
            this.getData();
        }
    }

    getData() {
        const { form } = this;
        const values = form.values();
        const formToShowValues = this.formToShow.values();

        this.loading = true;

        return this.preparePayload({ ...values, ...formToShowValues })
            .then((requestData) => {
                runInAction(() => {
                    this.loading = true;
                    this.stats = [];
                });
                return this.makeExportRequest(requestData);
            })
            .then((points) => {
                runInAction(() => {
                    this.stats = points;
                });
                return Promise.resolve(points);
            })
            .catch(() => {
                runInAction(() => {
                    this.stats = [];
                });
                return Promise.resolve();
            })
            .finally(() => {
                setTimeout(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                }, 300);
            });
    }

    makeExportRequest(payload) {
        return PriceStatsService.getStats(payload)
            .then(({ status, points }) => (
                new Promise((resolve, reject) => {
                    if (status === PRICE_ANALYSIS_STATUS.IN_PROGRESS) {
                        setTimeout(() => {
                            resolve(this.makeExportRequest(payload));
                        }, REQUEST_REPEAT_INTERVAL);
                    } else if (status === PRICE_ANALYSIS_STATUS.FAILED) {
                        reject(new Error('failed'));
                    } else {
                        resolve(points.map((item) => ({
                            date: new Date(item.date).toLocaleDateString('ru-RU'),
                            price: item.price,
                        })));
                    }
                })
            ));
    }

    resetFilters() {
        const { formToShow } = this;
        formToShow.fields.forEach((field) => {
            formToShow.$(field.name).set('value', '');
        });
        this.getData();
    }

    initFormListeners() {
        const { form } = this;

        const dateTo = new Date();
        const dateFrom = new Date(new Date().setDate(dateTo.getDate() - 7));
        const dealTypeField = form.$('dealType');
        const objectTypeField = form.$('objectType');

        dealTypeField.set('value', { id: 2, value: DEAL_TYPE.SALE, label: DEAL_TYPE_NAME.SALE });
        objectTypeField.set('value', { id: 1, value: OBJECT_TYPE.FLAT, label: OBJECT_TYPE_NAME.FLAT });

        form.$('dateTo').set('value', dateTo);
        form.$('dateFrom').set('value', dateFrom);

        this.formToShow = this.forms.FLAT_SALE;

        const handleDealTypeChange = ({ change: { newValue }, form: currentForm }) => {
            const objectType = currentForm.$('objectType').value?.value;
            if (newValue?.value && objectType === OBJECT_TYPE.FLAT) {
                const formName = `${objectType}_${newValue.value}`;
                this.formToShow = this.forms[formName];
            }
        };

        const handleObjectTypeChange = ({ change: { newValue }, form: currentForm }) => {
            const dealType = currentForm.$('dealType').value?.value;
            if (dealType && newValue?.value) {
                let formName = newValue.value;
                if (newValue.value === OBJECT_TYPE.FLAT) {
                    formName += `_${dealType}`;
                }
                this.formToShow = this.forms[formName];
            }
        };

        dealTypeField.observe(handleDealTypeChange);
        objectTypeField.observe(handleObjectTypeChange);

        form.on('success', () => this.getData());
    }

    // eslint-disable-next-line class-methods-use-this
    preparePayload(payload) {
        const filterData = clearObject(payload);
        const nestedFields = ['streetIds', 'houseIds'];
        nestedFields.forEach((key) => {
            if (filterData[key]?.length) {
                filterData[key] = filterData[key].flat(Infinity);
            }
        });
        filterData.dealType = filterData.dealType.toLowerCase();
        return Promise.resolve(filterData);
    }
}

export default PriceStats;
