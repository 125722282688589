import { runInAction } from 'mobx';
import Directory from '../../../shared/Directories/entities/Directory';

class HighlightColors extends Directory {
    loadData() {
        const { directory } = this;
        return this.dataSource.getList(directory)
            .then((list = []) => {
                runInAction(() => {
                    this.loaded = true;
                });
                const options = list.map((item) => ({
                    ...item,
                    label: '',
                }));
                options.unshift({
                    value: '',
                    label: 'Все',
                });
                this.items.setList(options);
                return Promise.resolve(this.options);
            });
    }
}

export default HighlightColors;
