import { action, computed } from 'mobx';
import List from '../List';
import listItemWithSelect from './listItemWithSelect';

const listWithSelect = (Store) => {
    if (List !== Store && !Object.prototype.isPrototypeOf.call(List, Store)) {
        throw new Error('A List class must be provided');
    }
    return class extends Store {
        constructor(...rest) {
            super(...rest);
            const { Item } = this;
            this.Item = listItemWithSelect(Item);
        }

        @computed
        get selected() {
            const { list } = this;
            return list.filter((item) => item.selected === true);
        }

        @action
        unselectAll() {
            this.selected.forEach((item) => item.unselect());
        }

        @action
        selectRange(startId, endId) {
            const { list } = this;
            const start = list.findIndex(({ id }) => id === (startId || list[0].id));
            const end = list.findIndex(({ id }) => id === endId);
            const startIndex = Math.min(start, end);
            const endIndex = Math.max(start, end);
            for (let i = startIndex; i <= endIndex; i += 1) {
                list[i].select();
            }
        }
    };
};

export default listWithSelect;
