/* global document */
import {
    observable,
    computed,
    action,
} from 'mobx';
import { EventEmitter } from '../../../helpers/EventEmitter';
import DialogItem from './DialogItemModel';

/**
 * Class for manage all dialogs in app
 */
class DialogStore {
    /** actual open state */
    @observable
    open = false;

    /** closing in progress state */
    @observable
    closing = false;

    /** current active name dialog */
    @observable
    dialog = null;

    /** history dialogs */
    @observable
    history = [];

    /** list of all dialogs */
    @observable
    list = {};

    /** event emitter */
    #eventEmitter = new EventEmitter()

    /**
     * Actual open state
     *
     * @returns {string|boolean} name dialog or boolean state
     */
    @computed
    get isOpen() {
        if (this.open && this.dialog) return this.dialog;
        return false;
    }

    /**
     * Method for getting dialog by name in list
     *
     * @param {string} dialogName name dialog
     * @returns {object} dialog item model
     */
    getDialog(dialogName) {
        const { list } = this;
        return list[dialogName];
    }

    /**
     * Method for checking the existence of a dialog
     *
     * @param {string} dialog dialog name
     * @returns {boolean} dialog exists or does not exist
     */
    doesExist(dialog) {
        return this.getDialog(dialog) !== undefined;
    }

    /**
     * Method for adding new dialog in list
     *
     * @param {string} name name new dialog
     * @param {object} options options for new dialog
     * @param {boolean} options.history add to history or not
     * @param {Function} options.onOpen method that is called
     * on open dialog
     * @param {Function} options.onClose method that is called
     * on close dialog
     */
    add(name, options) {
        this.list[name] = new DialogItem(name, options);
    }

    /**
     * Method for removing dialog from list dialogs
     *
     * @param {string} name name dialog
     */
    remove(name) {
        delete this.list[name];
    }

    /**
     * Method for showing dialog by name
     *
     * @param {string} dialogName dialog name
     * @param {object} customProps custom props
     * @returns {Promise<void>} show promise
     */
    @action
    show(dialogName, customProps) {
        const { open, dialog: currentDialogName } = this;
        const dialog = this.getDialog(dialogName);
        if (customProps) dialog.customProps = customProps;
        // not found
        if (!dialog) return this.hide();
        // this dialog is opened next already
        if (dialog.name === currentDialogName) return Promise.resolve();
        // save provided dialog as next to open
        if (open) {
            this.next = dialogName;
            return this.hide(true);
        }
        document.body.classList.add('dialog-overlay');
        this.open = true;
        this.dialog = dialog.name;
        dialog.open();
        this.addToHistory(dialog);
        this.#eventEmitter.emit(`${dialog.name}:open`);
        return Promise.resolve();
    }

    /**
     * Method for hiding dialog
     *
     * @returns {Promise<void>} hidden promise
     */
    @action
    hide() {
        const { dialog: dialogName } = this;
        const dialog = this.getDialog(dialogName);
        // closing right now
        if (this.closing || !dialog) {
            return Promise.resolve();
        }
        return new Promise((resolve) => {
            this.closing = true;
            setTimeout(() => {
                const { next } = this;
                this.open = false;
                this.closing = false;
                this.dialog = false;
                if (dialog) dialog.close();
                if (!next || typeof next !== 'boolean') {
                    document.body.classList.remove('dialog-overlay');
                }
                if (next) {
                    this.next = false;
                    this.show(next)
                        .then(resolve);
                }
                this.#eventEmitter.emit(`${dialog.name}:hidden`);
                return resolve();
            }, 400);
        });
    }

    /**
     * Method for adding dialog in history dialogs
     *
     * @param {object} dialog dialog item model
     * @returns {number} length history
     */
    addToHistory(dialog) {
        if (dialog.history === false) return false;
        return this.history.push(dialog.name);
    }

    /**
     * Method for toggle dialogs
     *
     * @param {string} dialogName dialog name for opening
     */
    toggle(dialogName) {
        const { open } = this;
        if (!open || this.dialog !== dialogName) {
            this.hide().then(() => { this.show(dialogName); });
        } else {
            this.hide();
        }
    }

    /**
     * Method for toggle dialog back
     * by history
     *
     * @param {number} length count for
     * return back by history
     */
    back(length) {
        const { history } = this;
        const offset = length || 1;
        if (history.length - offset < 0) return;
        this.show(history[history.length - offset]);
    }

    /**
     * @param  {...any} params eventEmitter.subscribe params
     * @returns {Function} unsubscribe function
     */
    on(...params) {
        return this.#eventEmitter.subscribe(...params);
    }
}

const createDialogStore = () => new DialogStore();

export default DialogStore;
export {
    createDialogStore,
};
