import {
    observable,
    action,
    computed,
    toJS,
} from 'mobx';

class PopupTabsStore {
    @observable tabs = [];

    @observable selectedIndex = 0;

    @computed
    get tabIds() {
        return toJS(this.tabs.map(({ id }) => id));
    }

    addTab(store, id, title, type, options) {
        if (this.getTab(id)) {
            return false;
        }
        this.$addTab(
            store,
            id,
            title,
            type,
            options,
        );
        this.setSelectedIndex(this.tabs.length - 1);
        return true;
    }

    @action
    $addTab(store, id, title, type, options) {
        this.tabs.push({
            store,
            id,
            title,
            type,
            options,
        });
    }

    changeTabTitle(id, title) {
        this.$changeTabTitle(id, title);
        return true;
    }

    @action
    $changeTabTitle(id, title) {
        const tabIndex = this.getTabIndex(id);
        this.tabs[tabIndex].title = title;
    }

    removeTab(id) {
        const tabIndex = this.getTabIndex(id);
        this.$removeTab(tabIndex);
    }

    removeAllTabs() {
        this.$removeAllTabs();
    }

    @action
    $removeTab(index) {
        this.tabs.splice(index, 1);
        this.setSelectedIndex(index);
    }

    @action
    $removeAllTabs() {
        this.tabs.splice(0, this.tabs.length);
        this.setSelectedIndex(0);
    }

    getTab(id) {
        const tabIndex = this.getTabIndex(id);
        if (tabIndex === -1) return null;
        return this.tabs[tabIndex];
    }

    getTabIndex(id) {
        return this.tabs.findIndex((tab) => tab.id === id);
    }

    @action
    setSelectedIndex(index) {
        const nextIndex = index >= this.tabs.length
            ? this.tabs.length - 1
            : index;
        this.selectedIndex = Math.max(nextIndex, 0);
    }
}

export default PopupTabsStore;
