import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../../../components/PageTitle';

import styles from './PageView.module.scss';

const PageView = ({
    children,
    disabled,
    meta,
    classList,
}) => {
    const [collapsed, setCollapsed] = useState(false);
    let aside;
    let content;
    if (children.length > 0 && children[0]) {
        [aside, ...content] = children;
    } else {
        content = children;
    }
    return (
        <div className={styles['page-view']}>
            {
                aside
                    ? (
                        <div
                            className={`
                                ${styles['page-view__aside']}
                                ${disabled ? styles['page-view__aside--disabled'] : ''}
                                ${collapsed ? styles['page-view__aside--collapsed'] : ''}
                            `}
                        >
                            {
                                typeof aside === 'function'
                                    ? aside({ setCollapsed, collapsed })
                                    : React.cloneElement(aside, { setCollapsed })
                            }
                        </div>
                    )
                    : null
            }
            <div
                className={`
                    ${styles['page-view__main']}
                    ${classList.main ? classList.main : ''}
                    ${collapsed ? styles['page-view__main--collapsed'] : ''}
                    ${classList.mainColllapsed && collapsed ? classList.mainColllapsed : ''}
                `}
            >
                {
                    meta.withPageTitle
                        ? (
                            <PageTitle icon={meta.icon}>
                                {meta.pageTitle}
                            </PageTitle>
                        )
                        : null
                }
                {content}
            </div>
        </div>
    );
};

PageView.propTypes = {
    meta: PropTypes.shape({
        pageTitle: PropTypes.string,
        icon: PropTypes.func,
        sisterRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        parentRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        withPageTitle: PropTypes.bool,
    }),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.func,
        ])),
    ]).isRequired,
    disabled: PropTypes.bool,
    classList: PropTypes.shape({
        main: PropTypes.string,
        mainColllapsed: PropTypes.string,
    }),
};

PageView.defaultProps = {
    meta: {
        pageTitle: null,
        icon: null,
        sisterRoutes: [],
        parentRoutes: [],
        withPageTitle: false,
    },
    disabled: false,
    classList: {
        main: '',
        mainColllapsed: '',
    },
};

export default PageView;
