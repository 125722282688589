import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import useStores from '../../../hooks/client/use-stores';
import LoginPage, { LoginPageField } from '../../shared/LoginPage';
import { Button, ButtonLink } from '../../../components/Button';
import FormElements from '../Form';

import styles from '../../shared/LoginPage/LoginPage.module.scss';

const RegisterUserKey = () => {
    const history = useHistory();
    const { userStore = {} } = useStores();
    const { keyForm = { $: () => null } } = userStore;
    useEffect(() => {
        if (keyForm && keyForm.on) {
            return keyForm.on('done', () => {
                history.push('credentials');
            });
        }
        return undefined;
    }, [keyForm]);
    return (
        <LoginPage
            title="Регистрация"
            description="Введите ключ программы, полученный при ее покупке"
            footer={(
                <ButtonLink
                    Component={Link}
                    size={ButtonLink.SIZE.MEDIUM}
                    color={ButtonLink.COLOR.TRANSPARENT}
                    href="/register"
                    target="_self"
                >
                    Назад
                </ButtonLink>
            )}
        >
            <Observer>
                {() => (
                    <form onSubmit={keyForm.onSubmit}>
                        <FormElements
                            form={keyForm}
                            elements={keyForm.render || []}
                            components={{
                                FieldWrapper: LoginPageField,
                            }}
                        />
                        <div className={styles.login__submit}>
                            <Button
                                type="submit"
                                color={Button.COLOR.GREEN}
                                size={Button.SIZE.LARGE}
                                block
                                disabled={keyForm.loading}
                            >
                                Продолжить регистрацию
                            </Button>
                        </div>
                    </form>
                )}
            </Observer>
        </LoginPage>
    );
};

export default RegisterUserKey;
