const createUserService = (request) => ({
    checkKey: (key) => (
        request.get('activation-key', {
            headers: {
                'X-Activation-Key': key,
            },
        })
    ),
    getOrganizations: (key) => (
        request.get('activation-key/companies', {
            headers: {
                'X-Activation-Key': key,
            },
        })
    ),
    createUser: ({ organizationId, ...user }) => (
        request.post(`/companies/${organizationId}/users`, user)
    ),

    createOrganization: (organization) => (
        request.post('/companies', organization)
    ),

    requestPasswordChange: ({ login, email }) => (
        request.post('/auth/password/code', { login, email })
    ),

    setNewPassword: ({ code, password }) => (
        request.post('/auth/password', { code, password })
    ),

    getChatBotCode: () => (
        request.get('/bot/telegram/code')
    ),
});

export default createUserService;
