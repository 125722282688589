import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CLIENT_STATUS } from '../constants/client';

const requiresAuthorization = (WrappedComponent, useStores, redirect = '/login') => observer((props) => {
    const { sessionStore } = useStores();

    if (sessionStore.companyData && sessionStore.companyData.status === CLIENT_STATUS.UNPAID) {
        return <Redirect to="/payment-form" />;
    }

    if (!sessionStore.isAuthorized) {
        return <Redirect to={redirect} />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
});

export default requiresAuthorization;
