/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { SIZE } from '../../constants/operator';
import CustomClearIndicator from './CustomClearIndicator';
import CustomDropDownIndicator from './CustomDropDownIndicator';
import CustomLoadingIndicator from './CustomLoadingIndicator';
import CustomMultiValueLabel from './CustomMultiValueLabel';
import CustomMultiValueRemove from './CustomMultiValueRemove';
import CustomValueContainer from './CustomValueContainer';
import OptionWithTooltip from './OptionWithTitle';

import styles from './Select.module.scss';

const BaseSelect = (props) => {
    const {
        Component,
        id,
        className,
        placeholder,
        size,
        maxMultiValues,
        options,
        value,
        name,
        error,
        disabled,
        loading,
        clearable,
        searchable,
        multiple,
        onChange,
        onMenuClose,
        onMenuOpen,
        noOptionsMessage,
        loadingMessage,
        wrappers,
        menuIsOpen,
        loadOptions,
        cacheOptions,
        defaultOptions,
        formatCreateLabel,
        isValidNewOption,
        getNewOptionData,
        onInputChange,
        inputValue,
        readOnly,
        autoFocus,
        backspaceRemovesValue,
        controlShouldRenderValue,
        hideSelectedOptions,
        menuPlacement,
        extra,
    } = props;
    const componentWrappers = { ...BaseSelect.defaultProps.wrappers, ...wrappers };

    const selectedValues = (extra.optionValuesIsArray && Array.isArray(value))
        ? value.map((item) => ({
            ...item,
            value: Array.isArray(item.value) ? item.value.join() : item.value,
        }))
        : value;

    const getOptionValue = (item) => (extra.optionValuesIsArray
        ? Array.isArray(item.value)
            ? item.value.join()
            : String(item.value)
        : item.value
    );

    const handleChange = (selected, selectOptions) => {
        const values = extra.optionValuesIsArray ? selected.map((item) => ({
            ...item,
            value: Array.isArray(item.value) ? item.value : String(item.value).split(',').map((i) => +i),
        })) : selected;
        onChange(values, selectOptions);
    };
    return (
        <>
            <Component
                id={id}
                className={`
                    ${styles.select}
                    ${styles[`select--${size}`]}
                    ${error ? styles['select--error'] : ''}
                    ${readOnly ? styles['select--readonly'] : ''}
                    ${className}
                `}
                getOptionValue={getOptionValue}
                menuPlacement={menuPlacement}
                classNamePrefix="select"
                components={{
                    DropdownIndicator: componentWrappers.DropdownIndicatorWrapper,
                    Option: componentWrappers.OptionWrapper,
                    ClearIndicator: CustomClearIndicator,
                    LoadingIndicator: CustomLoadingIndicator,
                    MultiValueRemove: CustomMultiValueRemove,
                    MultiValueLabel: componentWrappers.MultiValueLabel,
                    ValueContainer: componentWrappers.ValueContainer,
                    MenuList: componentWrappers.MenuList,
                }}
                maxMultiValues={maxMultiValues}
                isDisabled={disabled || readOnly}
                isLoading={loading}
                isClearable={clearable && !readOnly}
                isSearchable={searchable && !readOnly}
                isMulti={multiple}
                closeMenuOnSelect={!multiple}
                hideSelectedOptions={hideSelectedOptions}
                placeholder={placeholder}
                name={name}
                options={options}
                value={selectedValues}
                onChange={handleChange}
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
                noOptionsMessage={noOptionsMessage}
                loadingMessage={loadingMessage}
                menuIsOpen={menuIsOpen}
                isOptionDisabled={(option) => option.disabled}
                // async
                cacheOptions={cacheOptions}
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                // creatable
                formatCreateLabel={formatCreateLabel}
                isValidNewOption={isValidNewOption}
                getNewOptionData={getNewOptionData}
                // search input props
                onInputChange={onInputChange}
                inputValue={inputValue}
                autoFocus={autoFocus}
                // dropdown
                backspaceRemovesValue={backspaceRemovesValue}
                controlShouldRenderValue={controlShouldRenderValue}
            />
        </>
    );
};

BaseSelect.SIZE = { ...SIZE };

BaseSelect.propTypes = {
    Component: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(Object.values(BaseSelect.SIZE)),
    maxMultiValues: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            label: PropTypes.string,
        }),
        PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            label: PropTypes.string,
        })),
    ]),
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    loading: PropTypes.bool,
    clearable: PropTypes.bool,
    searchable: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onMenuClose: PropTypes.func,
    onMenuOpen: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    loadingMessage: PropTypes.func,
    wrappers: PropTypes.shape({
        OptionWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        DropdownIndicatorWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        MultiValueLabel: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        ValueContainer: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        MenuList: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
    }),
    menuIsOpen: PropTypes.bool,
    loadOptions: PropTypes.func,
    cacheOptions: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    formatCreateLabel: PropTypes.func,
    isValidNewOption: PropTypes.func,
    getNewOptionData: PropTypes.func,
    onInputChange: PropTypes.func,
    inputValue: PropTypes.string,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    backspaceRemovesValue: PropTypes.bool,
    controlShouldRenderValue: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
    menuPlacement: PropTypes.string,
    extra: PropTypes.shape({
        optionValuesIsArray: PropTypes.bool,
    }),
};

BaseSelect.defaultProps = {
    Component: Select,
    id: undefined,
    className: '',
    size: BaseSelect.SIZE.MEDIUM,
    maxMultiValues: 1,
    placeholder: '',
    value: undefined,
    options: undefined,
    disabled: false,
    error: false,
    loading: false,
    clearable: false,
    searchable: false,
    multiple: false,
    onChange: null,
    onMenuClose: null,
    onMenuOpen: null,
    noOptionsMessage: () => null,
    loadingMessage: () => null,
    wrappers: {
        OptionWrapper: OptionWithTooltip,
        DropdownIndicatorWrapper: CustomDropDownIndicator,
        MultiValueLabel: CustomMultiValueLabel,
        ValueContainer: CustomValueContainer,
        MenuList: components.MenuList,
    },
    menuIsOpen: undefined,
    loadOptions: undefined,
    defaultOptions: false,
    cacheOptions: undefined,
    formatCreateLabel: undefined,
    isValidNewOption: undefined,
    getNewOptionData: undefined,
    onInputChange: undefined,
    inputValue: undefined,
    readOnly: false,
    autoFocus: false,
    backspaceRemovesValue: true,
    controlShouldRenderValue: true,
    hideSelectedOptions: false,
    menuPlacement: 'auto',
    extra: {
        optionValuesIsArray: false,
    },
};

export default BaseSelect;
