import { computed } from 'mobx';
import BaseItem from './BaseItem';
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter';

class FlatItem extends BaseItem {
    @computed
    get objectName() {
        const {
            streetName,
            houseNumber,
            phone,
            id,
        } = this;
        const fullName = [streetName, houseNumber]
            .filter((address) => Boolean(address))
            .join(', ');
        return fullName || phone || id;
    }

    @computed
    get squareTotal() {
        const { totalArea, kitchenArea, livingArea } = this;
        return [totalArea, livingArea, kitchenArea]
            .map((area) => area || '-')
            .join('/');
    }

    @computed
    get seriesName() {
        const { series } = this;
        if (!series) {
            return null;
        }
        return series.name;
    }

    @computed
    get dateOfCompletion() {
        const { yearOfConstruction } = this;
        return yearOfConstruction;
    }

    @computed
    get dateOfConstruction() {
        const { yearOfConstruction } = this;
        return yearOfConstruction;
    }

    @computed
    get stoveTypeName() {
        const { stoveType } = this;
        if (!stoveType) {
            return null;
        }
        const output = stoveType.shortname
            ? capitalizeFirstLetter(stoveType.shortname)
            : stoveType.name;
        return output.slice(0, 1);
    }

    @computed
    get wallMaterialName() {
        const { wallMaterial } = this;
        if (!wallMaterial) {
            return null;
        }
        return wallMaterial.shortname
            ? capitalizeFirstLetter(wallMaterial.shortname)
            : wallMaterial.name;
    }

    @computed
    get floorCountWithWallMaterial() {
        const { wallMaterialName } = this;
        return `${this.floorCount || '-/-'} ${wallMaterialName?.slice(0, 3).toLowerCase() || ''}`.trim();
    }

    @computed
    get floorWithFloorCountWithWallMaterial() {
        const { wallMaterialName, floor, floorCount } = this;
        return `${floor || '-'}/${floorCount || '-'} ${wallMaterialName?.slice(0, 3).toLowerCase() || ''}`.trim();
    }

    @computed
    get roomCount() {
        const { $roomCount } = this;
        if ($roomCount === null || $roomCount === undefined) {
            return null;
        }
        return `${$roomCount}`;
    }
}

export default FlatItem;
