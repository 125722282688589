/* global alert */
import {
    action,
    computed,
    observable,
    runInAction,
} from 'mobx';
import DataSource from '../DataSource';
import { ListWithDataSource } from '../List';
import PresetItem from './item';
import { preset as form } from '../../../forms/shared';
import createForm from '../../../helpers/Form';

class PresetList extends ListWithDataSource {
    @observable loaded = false;

    @observable itemForm;

    @observable editItem;

    constructor(service) {
        super(
            PresetItem,
            new DataSource(service),
        );
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    getList() {
        return this.dataSource.getList();
    }

    load(force = false) {
        if (this.loaded && !force) {
            return Promise.resolve();
        }
        return super.load()
            .then((list) => {
                this.setList(list);
                runInAction(() => {
                    this.loaded = true;
                });
                return Promise.resolve();
            })
            .catch((e) => alert(e.message));
    }

    reload() {
        return this.load(true);
    }

    preload() {
        this.dataSource.setPreload();
    }

    remove(id) {
        const {
            dataSource,
        } = this;
        return dataSource.remove(id)
            .then(() => this.reload())
            .catch((e) => alert(e?.response?.data?.error?.message || e.message));
    }

    create(preset) {
        const { dataSource, itemForm } = this;

        return dataSource.create(preset)
            .then(() => {
                itemForm.fields.forEach((field) => field.set('value', ''));
            })
            .catch((e) => alert(e?.response?.data?.error?.message || e.message));
    }

    updateName(id, name) {
        const { dataSource } = this;
        return dataSource.updateName(id, name)
            .catch((e) => alert(e?.response?.data?.error?.message || e.message));
    }

    updateViewed(id) {
        const { dataSource } = this;
        return dataSource.updateViewed(id)
            .catch((e) => alert(e?.response?.data?.error?.message || e.message));
    }

    updateFilter(id, filter) {
        const { dataSource } = this;
        return dataSource.updateFilter(id, filter)
            .catch((e) => alert(e?.response?.data?.error?.message || e.message));
    }

    loadFilter(id) {
        const { dataSource } = this;
        return dataSource.loadFilter(id)
            .catch((e) => alert(e?.response?.data?.error?.message || e.message));
    }

    @action
    createItemForm() {
        const { itemForm } = this;
        if (itemForm) {
            return itemForm;
        }
        this.itemForm = createForm(form);
        return this.itemForm;
    }

    @action
    setEditItem({ id }) {
        this.editItem = this.getItem(id);
    }
}

export default PresetList;
