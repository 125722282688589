import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import { SIZE } from '../../constants/operator';

import styles from './Button.module.scss';

const A = ({ children, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a {...props}>
        {children}
    </a>
);

A.propTypes = {
    children: Button.propTypes.children,
};

A.defaultProps = {
    children: Button.defaultProps.children,
};

const ButtonLink = ({
    Component,
    children,
    className,
    color,
    size,
    disabled,
    block,
    onClick,
    href,
    rel,
    target,
    download,
}) => {
    const specificProps = {};
    let Wrapper;
    if (!Component) {
        Wrapper = A;
        specificProps.href = href;
        specificProps.download = download;
    } else {
        Wrapper = Component;
        specificProps.to = href;
    }
    return (
        <Wrapper
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...specificProps}
            className={`
                ${styles.button}
                ${styles[`button--${color}`]}
                ${styles[`button--${size}`]}
                ${block ? styles['button--block'] : ''}
                ${className}
            `}
            disabled={disabled}
            onClick={onClick}
            rel={rel}
            target={target}
        >
            <span className={styles.button__content}>
                {children}
            </span>
        </Wrapper>
    );
};

ButtonLink.SIZE = { ...SIZE };

ButtonLink.COLOR = {
    ...Button.COLOR,
};

ButtonLink.propTypes = {
    ...Button.propTypes,
    Component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.node,
        PropTypes.func,
    ]),
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    rel: PropTypes.string,
    download: PropTypes.bool,
};

ButtonLink.defaultProps = {
    ...Button.defaultProps,
    Component: undefined,
    target: '_blank',
    rel: undefined,
    download: false,
};

export default ButtonLink;
