import Field from './DefaultField';

class CheckBox extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw) => {
        const { providedOutput } = this;
        if (typeof providedOutput === 'function') {
            return providedOutput(raw || '');
        }
        return Number(raw) || 0;
    }
}

export default CheckBox;
