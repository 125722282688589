import { action, computed, observable } from 'mobx';
import { format } from 'date-fns';
import { ListItem } from '../../List';
import { preset as form } from '../../../../forms/shared';
import createForm from '../../../../helpers/Form';
import forEachField from '../../../../forms/utils/for-each-field';
import { DEAL_TYPE_MAP, DEFAULT_DATE_FORMAT, OBJECT_TYPE_MAP } from '../../../../constants/shared';

class PresetItem extends ListItem {
    @observable itemForm;

    @observable $name;

    @observable $dealType;

    @observable $objectType;

    @observable $viewedAt;

    @observable $newAt;

    @computed
    get name() {
        return this.$name;
    }

    @computed
    get count() {
        return this.$viewedCount;
    }

    @computed
    get new() {
        return this.$newCount;
    }

    @computed
    get dealType() {
        const typeMap = Object.keys(DEAL_TYPE_MAP).reduce((carry, type) => ({
            ...carry,
            [DEAL_TYPE_MAP[type]]: type,
        }), {});
        return typeMap[this.$dealType];
    }

    @computed
    get objectType() {
        const typeMap = Object.keys(OBJECT_TYPE_MAP).reduce((carry, type) => ({
            ...carry,
            [OBJECT_TYPE_MAP[type]]: type,
        }), {});
        return typeMap[this.$objectType];
    }

    @computed
    get compCountedAt() {
        const { countedAt } = this;
        return countedAt
            ? format(new Date(countedAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get checkedAt() {
        const { $viewedAt, $newAt } = this;
        if ($newAt) {
            return format(new Date($newAt), DEFAULT_DATE_FORMAT);
        }
        return $viewedAt
            ? format(new Date($viewedAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get checkedAtAsIs() {
        const { $viewedAt } = this;
        return $viewedAt
            ? new Date($viewedAt)
            : undefined;
    }

    @action
    createItemForm() {
        const { itemForm } = this;
        if (itemForm) {
            return itemForm;
        }
        this.itemForm = createForm(form);
        this.initItemFormListeners();
        this.setItemFormValues();
        return this.itemForm;
    }

    @action
    setItemFormValues() {
        const { itemForm } = this;
        const editFields = form;
        forEachField(editFields, (field) => {
            if (Object.getOwnPropertyDescriptor(this, field.name)) {
                // eslint-disable-next-line no-param-reassign
                if (this[field.name]) {
                    itemForm.$(field.name).set('value', this[field.name]);
                }
            }
        });
    }

    initItemFormListeners() {
        const {
            parent,
            itemForm,
            id,
        } = this;
        itemForm.on('success', ({ name }) => (
            parent.updateName(id, name)
                .then((response) => {
                    this.setData(response);
                    return Promise.resolve(response);
                })
        ));
    }

    checkNew() {
        const {
            parent,
            id,
        } = this;
        parent.updateViewed(id)
            .then((response) => {
                this.setData(response);
                return Promise.resolve(response);
            });
    }

    updateFilter(filter) {
        const {
            parent,
            id,
        } = this;
        const payload = { ...filter };
        return parent.updateFilter(id, payload);
    }

    remove() {
        const {
            parent,
            id,
        } = this;
        return parent.remove(id);
    }

    loadFilter() {
        const {
            parent,
            id,
        } = this;
        return parent.loadFilter(id)
            .then((response) => {
                const filter = { ...response };
                if (filter.radius) {
                    filter.groupBy = 'radius';
                }
                this.setData({ filter });
                return Promise.resolve(this.filter);
            });
    }
}

export default PresetItem;
