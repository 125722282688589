import React from 'react';
import { createDeviceStore } from '../../stores/client/Device';
import { createDialogStore } from '../../stores/shared/DialogStore';
import { createSessionStore } from '../../stores/client/Session';
import { createDirectoriesStore } from '../../stores/client/Directories';
import { createAppStore } from '../../stores/client/App';
import { createHeaderActionsStore } from '../../stores/shared/HeaderActions';
import { createObjectsMarkersStore } from '../../stores/shared/ObjectsMarkers';
import { ObjectService } from '../../services/client';

const context = {
    deviceStore: createDeviceStore(),
    dialogStore: createDialogStore(),
    sessionStore: createSessionStore(),
    directoriesStore: createDirectoriesStore(),
    appStore: createAppStore(),
    headerActions: createHeaderActionsStore(),
    objectMarkers: createObjectsMarkersStore(ObjectService),
};

const storesContext = React.createContext(context);

export default storesContext;
