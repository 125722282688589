const createNotepadService = (request) => ({
    getNotepads: () => request.get('/objects/notebooks'),
    create: (name) => request.post('/objects/notebooks', { name }),
    update: (id, name) => request.patch(`/objects/notebooks/${id}`, { name }),
    remove: (id) => request.delete(`/objects/notebooks/${id}`),
    addObjects: (id, objects, { objectType }) => (
        request.put(
            `/objects/notebooks/${id}/${objectType}`,
            { action: 'add', objectIds: objects },
        )
    ),
    removeObjects: (id, objects, { objectType }) => (
        request.put(
            `/objects/notebooks/${id}/${objectType}`,
            { action: 'delete', objectIds: objects },
        )
    ),
});

export default createNotepadService;
