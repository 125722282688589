import validatorjs from 'validatorjs';

validatorjs.prototype.setAttributeNames = function setAttributeNames(attributes) {
    if (!attributes) {
        return;
    }
    const modified = { ...attributes };
    const keys = Object.keys(modified);
    for (let i = 0; i < keys.length; i += 1) {
        const attribute = keys[i];
        modified[attribute] = modified[attribute].toLowerCase();
    }
    // eslint-disable-next-line no-underscore-dangle
    this.messages._setAttributeNames(modified);
};

const rules = {
    email_or_phone: {
        function: (value) => {
            const { fn: validateEmail } = validatorjs.prototype.getRule('email');
            const phoneRe = /^[0-9]{11,12}/;
            return validateEmail(value) || phoneRe.test(value);
        },
        // TODO: l18n
        message: 'Поле :attribute должно содержать корректный номер телефона или E-mail.',
    },
    phone: {
        function: (value) => {
            const phoneRe = /^\+7[0-9]{10,11}/;
            return phoneRe.test(value);
        },
        // TODO: l18n
        message: 'Поле :attribute должно содержать корректный номер телефона.',
    },
    preset: {
        function: (value) => {
            const trimmed = value.trim(' ');
            const { length } = trimmed;
            return length > 1 && length < 33;
        },
        // TODO: l18n
        message: 'Поле :attribute должно быть от 2-х до 32-х символов.',
    },
    contact: {
        function: (value = []) => {
            let error;
            const phoneRe = /^\+7\d{10}$/;
            value.forEach(({ phone, contact }) => {
                if (!contact || !phone || !phoneRe.test(phone)) {
                    error = true;
                }
            });
            return !error;
        },
        // TODO: l18n
        message: 'Поле ":attribute" должно содержать название контакта и корректный номер телефона.',
    },
    password: {
        function: (value) => {
            const passwordRe = /^(?=.*[a-zA-Z])(?=.*[\d])(?=.*[!@#$%^&*()[\]{},.;:_-]).*$/;
            return passwordRe.test(value);
        },
        // TODO: l18n
        message: 'Пароль должен содержать 1 цифру, 1 большую или 1 маленькую латинскую букву и 1 символ.',
    },
    activationKey: {
        function: (value) => {
            const keyRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]).*$/;
            return keyRe.test(value);
        },
        // TODO: l18n
        message: 'Ключ должен содержать 1 цифру, 1 большую и 1 маленькую латинскую букву.',
    },
    lte: {
        function: function lte(value, required) {
            // eslint-disable-next-line no-underscore-dangle
            const max = this.validator._flattenObject(this.validator.input)[required];
            this.ruleValue = this.validator.messages.attributeNames[this.name];
            return max
                ? Number(value) <= Number(max)
                : true;
        },
        message: 'Поле ":attribute" не может быть больше поля ":lte"',
    },
    lt: {
        function: function lte(value, required) {
            // eslint-disable-next-line no-underscore-dangle
            const max = this.validator._flattenObject(this.validator.input)[required];
            this.ruleValue = this.validator.messages.attributeNames[this.name];
            return max
                ? Number(value) < Number(max)
                : true;
        },
        message: 'Поле ":attribute" должно быть меньше поля ":lt"',
    },
    gte: {
        function: function lte(value, required) {
            // eslint-disable-next-line no-underscore-dangle
            const min = this.validator._flattenObject(this.validator.input)[required];
            this.ruleValue = this.validator.messages.attributeNames[this.name];
            return min
                ? Number(value) >= Number(min)
                : true;
        },
        message: 'Поле ":attribute" не может быть меньше поля ":gte"',
    },
    gt: {
        function: function lte(value, required) {
            // eslint-disable-next-line no-underscore-dangle
            const min = this.validator._flattenObject(this.validator.input)[required];
            this.ruleValue = this.validator.messages.attributeNames[this.name];
            return min
                ? Number(value) > Number(min)
                : true;
        },
        message: 'Поле ":attribute" должно быть больше поля ":gt"',
    },
};

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ({ validator }) => {
            validator.useLang('ru');
            Object.keys(rules).forEach(
                (key) => (
                    validator.register(key, rules[key].function, rules[key].message)
                ),
            );
            validator.stopOnError(true);
        },
    },
};

export default plugins;
