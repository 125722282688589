/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
    Map,
    YMaps,
    Circle,
    Placemark,
    ZoomControl,
    ObjectManager,
} from 'react-yandex-maps';
import { Input } from '../../../components/Input';
import { TAB_TYPE } from '../../../constants/shared';
import { AsyncSelect } from '../../../components/Select';
import { Button, ButtonClose } from '../../../components/Button';
import { YANDEX_MAPS_API_KEY } from '../../../config/operator';
import PopupTabsStore from '../../../stores/shared/PopupTabsStore';

import styles from './SearchByRadius.module.scss';

const CITY_CENTER = [56.0183900, 92.8671700];
const PRESET_RADIUS_VALUES = [100, 500, 1000, 5000, 10000];

const SearchByRadius = (props) => {
    const {
        store,
        useStores,
        tabsStore,
        onObjectClose,
        createDetailed,
    } = props;
    const { objectMarkers } = useStores();
    const { pathname } = useLocation();
    const { form } = store;

    const [api, setApi] = useState(null);
    const [center, setCenter] = useState(CITY_CENTER);
    const [marker, setMarker] = useState(null);
    const [radius, setRadius] = useState(PRESET_RADIUS_VALUES[1]);
    const [search, setSearch] = useState('');
    const [current, setCurrent] = useState({ value: '', label: '', data: {} });

    useEffect(() => {
        const pathnameParts = pathname.split('/');
        const objectType = pathnameParts.pop();
        const dealType = pathnameParts.pop();

        objectMarkers.load(dealType, objectType).then(() => setMarker(
            () => {
                let coords = CITY_CENTER;

                const lat = form.$('latitude').get('value');
                const lon = form.$('longitude').get('value');

                if (lat && lon) {
                    coords = [lat, lon];
                    setCenter(coords);
                }

                return coords;
            },
        ));
    }, [pathname]);

    const getAddressByCoordinates = useCallback(async (coords) => {
        if (api) {
            const res = await api.geocode(coords, { results: 1 });
            const firstObject = res.geoObjects.get(0);
            const addressLine = firstObject.getAddressLine();
            return ({
                label: addressLine,
                value: addressLine,
                data: {
                    // eslint-disable-next-line no-underscore-dangle
                    coords: firstObject.geometry._coordinates,
                    address: addressLine,
                },
            });
        }
        return { value: '', label: '', data: {} };
    }, [api]);

    useEffect(() => {
        const lat = form.$('latitude').get('value');
        const lon = form.$('longitude').get('value');

        setRadius(form.$('radius').get('value') || PRESET_RADIUS_VALUES[1]);

        if (lat && lon) {
            getAddressByCoordinates([lat, lon]).then(setCurrent);
        }
    }, [getAddressByCoordinates]);

    const handleLoad = useCallback(
        async (searchValue, callback) => {
            if (api && searchValue) {
                try {
                    const res = await api.geocode(searchValue, {
                        boundedBy: [[51.895064, 80.019981], [77.768785, 114.822285]],
                        strictBounds: true,
                    });
                    const addresses = [];

                    res.geoObjects.each((object) => {
                        const addressLine = object.getAddressLine();
                        addresses.push({
                            label: addressLine,
                            value: addressLine,
                            data: {
                                // eslint-disable-next-line no-underscore-dangle
                                coords: object.geometry._coordinates,
                                address: addressLine,
                            },
                        });
                    });
                    callback(addresses);
                } catch {
                /* empty */
                }
            }
        },
        [api],
    );

    const handleApply = () => {
        form.$('radius').set('value', radius);
        form.$('address').set('value', current.value);
        form.$('latitude').set('value', marker[0]);
        form.$('longitude').set('value', marker[1]);
        onObjectClose();
    };

    const handleDragEnd = async (event) => {
        setCurrent(await getAddressByCoordinates(event.get('target').geometry.getCoordinates()));
    };

    const handleMapClick = async (event) => {
        const coords = event.get('coords');
        setMarker(coords);
        setCenter(coords);
        setCurrent(await getAddressByCoordinates(coords));
    };

    const handleRadiusChange = (event) => {
        setRadius(+event.target.value);
    };

    const handleChangeAddress = (value) => {
        setMarker(value.data.coords);
        setCenter(value.data.coords);
        setCurrent(value);
    };

    const handlePlacemarkClick = (event) => {
        const objectIds = event.get('objectId').split(',').slice(0, 10);

        objectIds.forEach((objectId) => {
            if (!tabsStore.getTab(objectId)) {
                const instance = createDetailed();
                instance.setData({ id: objectId });
                const instanceForm = instance.createForm();
                instanceForm.on('done', (response) => {
                    if (!response.filter((res) => Boolean(res)).length) {
                        return;
                    }
                    tabsStore.removeTab(objectId);
                });
                instance.load(objectId).then(() => {
                    tabsStore.changeTabTitle(objectId, instance.objectName);
                });
                tabsStore.addTab(
                    instance,
                    objectId,
                    objectId,
                    TAB_TYPE.EDIT_OBJECT,
                );
            }
        });
    };

    const handlePositionChange = (event) => {
        setMarker(event.get('target').geometry.getCoordinates());
    };

    const handleRadiusToggleChange = (value) => () => {
        setRadius(value);
    };

    return (
        <div className={styles['radius-search__container']}>
            <div className={`handle ${styles['radius-search__handle']}`}>
                <ButtonClose onClick={onObjectClose} />
            </div>

            <label className={styles['radius-search__label']}>
                Поиск
            </label>
            <AsyncSelect
                onChange={handleChangeAddress}
                value={current.value ? current : null}
                inputValue={search}
                loadOptions={handleLoad}
                onInputChange={setSearch}
                cacheOptions
            />

            <div className={styles['radius-search__map']}>
                <YMaps query={{ apikey: YANDEX_MAPS_API_KEY, lang: 'ru_RU' }}>
                    <Map
                        state={{ zoom: 15, center }}
                        onLoad={setApi}
                        onClick={handleMapClick}
                        modules={['geocode']}
                        className="yandex-map"
                    >
                        <ZoomControl />
                        <Placemark
                            onDrag={handlePositionChange}
                            options={{ draggable: true, iconColor: '#15a1ef' }}
                            geometry={marker}
                            onDragEnd={handleDragEnd}
                        />
                        <Circle
                            onDrag={handlePositionChange}
                            onClick={handleMapClick}
                            geometry={[marker, radius]}
                            onDragEnd={handleDragEnd}
                            options={{
                                draggable: true,
                                fillColor: '#15a1ef',
                                fillOpacity: '0.4',
                                strokeColor: '#15a1ef',
                                strokeWidth: '2',
                            }}
                        />
                        <ObjectManager
                            onClick={handlePlacemarkClick}
                            features={objectMarkers.markers.map((objectMarker) => ({
                                id: objectMarker.objectIds.join(),
                                type: 'Feature',
                                options: { iconColor: '#10bc6a' },
                                properties: { iconContent: objectMarker.numberOfObjects },
                                geometry: {
                                    type: 'Point',
                                    coordinates: objectMarker.coords,
                                },
                            }))}
                        />
                    </Map>
                </YMaps>
            </div>

            <div className={styles['radius-search__footer']}>
                <div>
                    <label className={styles['radius-search__label']}>
                        Радиус, м.
                    </label>
                    <div className={styles['radius-search__input']}>
                        {PRESET_RADIUS_VALUES.map((value) => (
                            <Button
                                key={value}
                                size={Button.SIZE.MEDIUM}
                                color={value === radius ? Button.COLOR.BLUE : Button.COLOR.WHITE}
                                onClick={handleRadiusToggleChange(value)}
                            >
                                {value}
                            </Button>
                        ))}
                        <Input
                            type="number"
                            value={radius === 0 ? '' : radius}
                            onChange={handleRadiusChange}
                            placeholder="Радиус"
                        />
                    </div>
                </div>

                <Button
                    size={Button.SIZE.LARGE}
                    color={Button.COLOR.BLUE}
                    onClick={handleApply}
                    disabled={!radius || !current.value}
                >
                    Применить
                </Button>
            </div>
        </div>
    );
};

SearchByRadius.propTypes = {
    store: PropTypes.instanceOf({
        form: PropTypes.shape({
            onSubmit: PropTypes.func,
            render: PropTypes.arrayOf(
                PropTypes.shape({}),
            ),
        }).isRequired,
    }).isRequired,
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    useStores: PropTypes.func.isRequired,
    onObjectClose: PropTypes.func.isRequired,
    createDetailed: PropTypes.func.isRequired,
};

export default SearchByRadius;
