export default {
    type: 'fieldset',
    name: 'exclusive',
    fields: [{
        name: 'exclusive',
        type: 'fieldgroup',
        label: 'Эксклюзивы',
        fields: [{
            type: 'checkboxList',
            name: 'exclusive',
            size: 'md',
            rows: 1,
            options: [{
                value: 'ours',
                label: 'Наши',
            }, {
                value: 'others',
                label: 'Прочие',
            }],
        }],
    }],
};
