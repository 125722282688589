import React from 'react';
import PropTypes from 'prop-types';
import { EMPTY_DATA_STRING } from '../../../constants/shared';

import styles from './ObjectComponent.module.scss';

const ObjectComponentCompany = ({
    company,
}) => (
    <div className={`${styles.company}`}>
        <h2 className={styles['block-title']}>
            <span>Компания</span>
        </h2>
        <div className={styles.company__container}>
            {company || EMPTY_DATA_STRING}
        </div>
    </div>
);

ObjectComponentCompany.propTypes = {
    company: PropTypes.string,
};

ObjectComponentCompany.defaultProps = {
    company: '',
};

export default ObjectComponentCompany;
