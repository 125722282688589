import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import FormElements from '../Form';
import { Button } from '../../../components/Button';
import EditField from './EditField';
import { Form } from '../../../helpers/Form';

import styles from './PresetList.module.scss';

const PresetForm = ({ useStores, form, button }) => (
    form
        ? (
            <form
                className={styles['preset-edit']}
                onSubmit={form.onSubmit}
            >
                <FormElements
                    useStores={useStores}
                    form={form}
                    elements={form.render}
                    components={{
                        FieldWrapper: EditField,
                    }}
                />
                <div className={styles['preset-edit__submit']}>
                    <Observer>
                        {() => (
                            <Button
                                type="submit"
                                disabled={form.loading}
                                color={Button.COLOR.GREEN}
                                size={Button.SIZE.LARGE}
                                block
                            >
                                {button}
                            </Button>
                        )}
                    </Observer>
                </div>
            </form>
        )
        : null
);

PresetForm.propTypes = {
    useStores: PropTypes.func.isRequired,
    form: PropTypes.instanceOf(Form),
    button: PropTypes.string,
};

PresetForm.defaultProps = {
    form: undefined,
    button: 'Сохранить',
};

export default PresetForm;
