/* global window */
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { ContextMenu } from '../../../components/ContextMenu';
import KeyboardHandler from '../../../components/KeyboardHandler';
import { SORT_ORDER } from '../../../constants/shared';
import ReactTooltip from '../../../lib/react-tooltip';
import ObjectList from '../../../stores/shared/RefactoredObjectList/ObjectList';
import SelectedObject from '../SelectedObject';
import { CellSimilarity } from '../Table';
import { TableWithSelected } from '../TableWithSelected';
import MergeContextMenuItem from '../../operator/ContextMenu/mergeContextMenuItem';
import { ItemStatusModes } from '../ItemStatus/constants';
import ItemStatusWithContactsModal from '../Modals/itemStatusWithContactsModal';
import styles from './SimilarObjects.module.scss';
import './similarContent.scss';

const SimilarContent = ({
    useStores,
    list,
    onDoubleRowClick,
    onRowClick,
}) => {
    const listRef = useRef();
    const keyboardRef = useRef();
    const contextMenuTriggerRef = useRef();
    const [selectedHeight, setSelectedHeight] = useState('140px');
    const [contextMenuOffset, setContextMenuOffset] = useState({ x: 0, y: 0 });
    const [selectedMergeItemInfo, setSelectedMergeItemInfo] = useState({
        item: null,
        key: 0,
    });

    let keyPressDelay;

    // interactions
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            list.setPrevCursor();
        } else {
            list.setNextCursor();
        }
        const currentId = list.cursor;
        const index = list.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
        if (keyPressDelay) {
            clearTimeout(keyPressDelay);
        }
        keyPressDelay = setTimeout(() => {
            onRowClick({ original: { id: currentId } });
        }, 300);
    };

    const handleDownKey = () => {
        handleArrowKey(1);
    };

    const handleUpKey = () => {
        handleArrowKey(-1);
    };

    const handleEnterKey = () => {
        const id = list.cursor;
        onDoubleRowClick({ original: { id, exclusive: list.getItem(id).isExclusive } });
    };

    // effects
    useEffect(() => {
        if (keyboardRef.current) {
            keyboardRef.current.focus();
        }
    }, [list]);
    if (!list) {
        return null;
    }

    // Table headers
    const modifyHeaders = (headers) => headers.map((header) => {
        switch (header.name) {
            case 'similarityValue':
                return { ...header, Cell: CellSimilarity };
            default:
                return header;
        }
    });

    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = SORT_ORDER.ASC;
                break;
            default:
                nextDirection = SORT_ORDER.ASC;
        }
        list.setSort(sortName, nextDirection);
        list.reload();
    };

    const onMergeContextMenuClick = () => {
        const item = list.getItem(list.cursor);
        setSelectedMergeItemInfo((prevInfo) => ({
            item,
            key: prevInfo.key + 1,
        }));
    };

    /**
     * Пересчитать смещение контекстного меню относительно ContextMenuTrigger
     * @param {MouseEvent} e
     */
    const recalculateContextMenuOffset = (e) => {
        // Если не ПКМ или не выделенны объекты
        // - не пересчитываем оффсет контекстного меню
        if (e.button !== 2 || !list.selected?.length) {
            return;
        }

        const rect = contextMenuTriggerRef.current.elem.getBoundingClientRect();
        const x = rect.left;
        const y = rect.top;
        setContextMenuOffset({ x, y });
    };

    return (
        <div className="similar-content">
            <ContextMenuTrigger
                id="similar-objects-context-menu"
                ref={contextMenuTriggerRef}
                posX={contextMenuOffset.x}
                posY={contextMenuOffset.y}
                attributes={{
                    onMouseDown: recalculateContextMenuOffset,
                }}
            >
                <KeyboardHandler
                    ref={keyboardRef}
                    onDown={handleDownKey}
                    onUp={handleUpKey}
                    onEnter={handleEnterKey}
                >
                    <Observer>
                        {() => (
                            <TableWithSelected
                                useStores={useStores}
                                headers={modifyHeaders(list.tableHeaders)}
                                store={list}
                                ref={listRef}
                                onRowClick={onRowClick}
                                onDoubleRowClick={onDoubleRowClick}
                                selectedContentHeight={selectedHeight}
                                onTableHeaderClick={handleSortChange}
                                fullHeight={`${Math.min((window.innerHeight / 100) * 80, 600) - 133}px`}
                                components={{
                                    SelectedWrapper: (props) => (
                                        <SelectedObject
                                            setSelectedHeight={setSelectedHeight}
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...props}
                                        />
                                    ),
                                }}
                            />
                        )}
                    </Observer>
                </KeyboardHandler>
            </ContextMenuTrigger>
            <Observer>
                {
                    () => (
                        <ContextMenu id="similar-objects-context-menu">
                            <MergeContextMenuItem
                                primaryObjectId={list.cursor}
                                selected={list.selected}
                                onClick={onMergeContextMenuClick}
                            />
                        </ContextMenu>
                    )
                }
            </Observer>

            <ItemStatusWithContactsModal
                item={selectedMergeItemInfo.item}
                mode={ItemStatusModes.Merge}
                openKey={selectedMergeItemInfo.key}
                useStores={useStores}
            />
            <ReactTooltip
                id="similarity"
                delayHide={100}
                bodyMode
                getContent={(id) => {
                    return null; // отключен контент для tooltip(хинта), по просьбе клиента.
                    // eslint-disable-next-line no-unreachable
                    if (!id) return null;
                    const preparedId = id.replace(/\s/g, '');
                    if (!list.getItem(preparedId)) return null;
                    const item = list.getItem(preparedId);
                    return (
                        <div className={styles['similar-list']}>
                            <div className={styles['similar-list__title']}>
                                Список совпадающих параметров
                            </div>
                            <ul className={styles['similar-list__list']}>
                                {
                                    item.similarityProperties.map(({ property, value }) => (
                                        <li className={styles['similar-list__item']} key={`similar-prop-${id}-${property}`}>
                                            {property}
                                            &nbsp;
                                            {
                                                value
                                                    ? `(${value})`
                                                    : null
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    );
                }}
            />
        </div>
    );
};

SimilarContent.propTypes = {
    useStores: PropTypes.func.isRequired,
    list: PropTypes.instanceOf(ObjectList),
    onDoubleRowClick: PropTypes.func,
    onRowClick: PropTypes.func,
};

SimilarContent.defaultProps = {
    list: undefined,
    onDoubleRowClick: () => null,
    onRowClick: () => null,
};

export default SimilarContent;
