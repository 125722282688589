import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { Button, IconButton } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { SearchIcon, ClearIcon, SaveIcon } from '../../../components/Icons/icons';

import styles from './ObjectList.module.scss';
import filterStyles from '../Filter/Filter.module.scss';

const FilterFooter = ({
    form,
    useStores,
    onPresetAdd,
    onPresetSave,
    onPresetCancel,
}) => {
    const { objectListPageStore } = useStores();
    return (
        <div className={filterStyles.filter__footer}>
            <IconButton
                type="submit"
                className={filterStyles.filter__submit}
                size={IconButton.SIZE.LARGE}
                color={IconButton.COLOR.BLUE}
                icon={(<SVGIcon SVGElement={SearchIcon} />)}
                block
            >
                Искать
            </IconButton>
            <Observer>
                {() => (
                    objectListPageStore?.selected
                        ? (
                            <div className={`${styles.filter__footer} ${styles['filter__footer--with-preset-selected']}`}>
                                <Button
                                    type={Button.TYPE.BUTTON}
                                    size={Button.SIZE.MEDIUM}
                                    color={Button.COLOR.WHITE_BLACK}
                                    onClick={onPresetCancel}
                                    block
                                >
                                    Выйти из подборки
                                </Button>
                                <Button
                                    type="reset"
                                    size={IconButton.SIZE.MEDIUM}
                                    color={IconButton.COLOR.WHITE_GREEN}
                                    onClick={onPresetSave}
                                    block
                                >
                                    Сохранить
                                </Button>
                            </div>
                        )
                        : (
                            <div className={styles.filter__footer}>
                                <IconButton
                                    type="reset"
                                    size={IconButton.SIZE.MEDIUM}
                                    color={IconButton.COLOR.WHITE}
                                    icon={(<SVGIcon SVGElement={ClearIcon} />)}
                                    onClick={form.onReset}
                                >
                                    Очистить
                                </IconButton>
                                <IconButton
                                    type="button"
                                    size={IconButton.SIZE.MEDIUM}
                                    color={IconButton.COLOR.WHITE_GREEN}
                                    icon={(<SVGIcon SVGElement={SaveIcon} />)}
                                    onClick={onPresetAdd}
                                >
                                    Сохранить подборку
                                </IconButton>
                            </div>
                        )
                )}
            </Observer>
        </div>
    );
};

FilterFooter.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func,
        onReset: PropTypes.func,
        render: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
    useStores: PropTypes.func,
    onPresetAdd: PropTypes.func,
    onPresetSave: PropTypes.func,
    onPresetCancel: PropTypes.func,
};

FilterFooter.defaultProps = {
    useStores: () => null,
    onPresetAdd: () => null,
    onPresetSave: () => null,
    onPresetCancel: () => null,
};

export default FilterFooter;

export {
    FilterFooter,
};
