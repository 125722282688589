import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

const requiresNotAuthorized = (WrappedComponent, useStores, redirect = '/') => observer((props) => {
    const { sessionStore } = useStores();
    if (sessionStore.isAuthorized) {
        return <Redirect to={redirect} />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
});

export default requiresNotAuthorized;
