import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStores from '../../../hooks/client/use-stores';
import Organization from '../../../stores/client/User/Organization';
import storesContext from '../../../contexts/client';

const RegisterOrganization = ({ children }) => {
    const [stores, setStores] = useState(useStores());
    useEffect(() => {
        const organizationStore = new Organization();
        setStores({
            ...stores,
            organizationStore,
        });
    }, []);
    return (
        <storesContext.Provider value={stores}>
            {children}
        </storesContext.Provider>
    );
};

RegisterOrganization.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]).isRequired,
};

export default RegisterOrganization;
