import React from 'react';
import PropTypes from 'prop-types';

/**
 * Functional component for display
 * navigation list
 *
 * @param {object} navItem - params for component
 * @param {string|React.Component} navItem.children - nav items
 * @param {string} navItem.role - role component
 * @returns {React.FunctionComponent} (
 *  <ContentWithNavList>
 *      {children}
 *  </ContentWithNavList>
 * )
 */
const ContentWithNavList = ({
    children,
    role,
}) => (
    <div role={role}>
        {children}
    </div>
);

ContentWithNavList.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    role: PropTypes.string,
};

ContentWithNavList.defaultProps = {
    role: 'navigation',
};

export default ContentWithNavList;
