/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { HouseFilledIcon, HouseFilledWithBorderIcon } from '../../../components/Icons/icons';
import { POSITION } from '../../../constants/shared';

import styles from './Table.module.scss';

const CellType = ({
    value: { value: type, text: hint },
    rowHint,
}) => {
    const hintProps = {};

    if (hint || rowHint) {
        hintProps['data-tip'] = `
            ${hint}
            ${(
                rowHint
                    ? `<div class="${styles['table__row-hint']}">${rowHint}</div>`
                    : ''
            )}
        `;
        hintProps['data-multiline'] = true;
        hintProps['data-html'] = true;
        hintProps['data-plac'] = POSITION.RIGHT;
        hintProps['data-class'] = `
            ${styles.table__hint}
            ${rowHint ? styles['table__hint--multiline'] : ''}
        `;
    }

    return (
        <div
            className={`
                ${styles.table__type}
                ${styles[`table__type--${CellType.TYPES[type]}`]}
            `}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...hintProps}
        >
            <SVGIcon
                SVGElement={
                    CellType.TYPES[type] === CellType.TYPES[2]
                        ? HouseFilledWithBorderIcon
                        : HouseFilledIcon
                    }
                height={10}
            />
        </div>
    );
};

// TODO: maybe move it to constants, also move hints
CellType.TYPES = {
    1: 'yellow',
    2: 'black',
    3: 'blue',
    4: 'orange',
    100: 'red',
};

CellType.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        text: PropTypes.string,
    }),
    rowHint: PropTypes.string,
};

CellType.defaultProps = {
    value: {
        value: 1,
        text: '',
    },
    rowHint: null,
};

export default CellType;
