/* eslint react/prop-types: 0 */
/* eslint linebreak-style: 0 */
/* eslint-disable no-unused-expressions */
import React, { useMemo } from 'react';
import { SIZE } from '../../../constants/shared';
import styles from './Table.module.scss';
import { Checkbox } from '../../../components/Input';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { ArrowRightIcon } from '../../../components/Icons/icons';

/**
 * Компонент группировочной строки
 * @param {Object} param0
 * @param {import('react-table').Row} param0.row строка для группировки
 * @param {Object} param0.style стиль строки
 * @param {Object} param0.isCheckboxLeftAlign
 *  признак необходимости ориентации чекбоксов группировки по левому краю
 */
const GroupingRow = ({
    row,
    style,
    isCheckboxLeftAlign = true,
}) => {
    if (!row.isGrouped) {
        throw new Error('Строка не является группировочной');
    }

    /** Признак выбранности  */
    const isAllInGroupSelected = row.subRows.every((subRow) => subRow.original.selected);

    const onGroupToggleSelection = () => {
        isAllInGroupSelected
            ? row.subRows.forEach((subRow) => subRow.original.unselect())
            : row.subRows.forEach((subRow) => subRow.original.select());

        const lastSubRow = row.subRows.at(row.subRows.length - 1);
        lastSubRow.original.parent.setCursor(lastSubRow.original.id);
    };

    const expandSymbolClassName = useMemo(() => (
        row.isExpanded ? styles['grouping-label--expander--rotate-down'] : styles['grouping-label--expander--rotate-right']
    ), [row.isExpanded]);

    const groupDisplayValue = useMemo(
        () => row.subRows.at(0).original.groupDisplayValue,
        [row.subRows],
    );
    const rowProps = row.getRowProps({ style });

    const containerAlignmentClassName = !isCheckboxLeftAlign ? styles['container-reverse'] : '';

    const toggleRowExpandedProps = useMemo(() => row.getToggleRowExpandedProps(), [row]);

    return (
        <div
            className={`${styles.table__row} ${styles['table__row--grouping']}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rowProps}
        >
            <div className={`${styles['grouping-row-container']} ${containerAlignmentClassName}`}>
                <div>
                    <Checkbox
                        id={`grouping-checkbox-${row.id}`}
                        name={`grouping-checkbox-${row.id}`}
                        className={!isCheckboxLeftAlign ? styles['checkbox-right-align'] : ''}
                        checked={isAllInGroupSelected}
                        onChange={onGroupToggleSelection}
                        size={SIZE.SMALL}
                    />
                </div>
                <div className={styles['grouping-label-container']}>
                    <div
                        className={`${styles['grouping-label']}`}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...toggleRowExpandedProps}
                        title={undefined}
                    >
                        <SVGIcon
                            SVGElement={ArrowRightIcon}
                            height={12}
                            className={`${expandSymbolClassName}`}
                        />
                        <span className={`${styles['grouping-label--title']}`}>{groupDisplayValue}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupingRow;
