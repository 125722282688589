import { action, observable } from 'mobx';
import request, { SessionService, StorageService } from '../../../services/operator';
import Session from '../../shared/Session';
import { loginFields } from '../../../forms/operator';
import { TOKEN_UPDATE_LAG } from '../../../constants/operator';

class SessionStore extends Session {
    @observable operatorInfo = null;

    constructor() {
        super(SessionService, StorageService, {
            loginFields,
            tokenUpdateLag: TOKEN_UPDATE_LAG,
            tokenName: 'operatorAccessToken',
        });
        this.tryAuth();
        // TODO: think about a better place for this
        // api request/response handlers
        request.interceptors.request.use(
            this.addTokenToRequest.bind(this),
            (error) => Promise.reject(error),
        );
        request.interceptors.response.use(
            (response) => response.data,
            this.checkResponseStatus.bind(this),
        );
    }

    loadOperatorInfo() {
        const { sessionService } = this;
        return sessionService.operatorInfo()
            .then((response) => {
                this.setOperatorInfo(response);
            })
            .catch((e) => { console.error(e); });
    }

    authorize() {
        this.authorizeEvent();
        this.loadOperatorInfo();
    }

    @action
    setOperatorInfo(info) {
        this.operatorInfo = info;
    }

    @action
    logout() {
        super.logout();
        this.operatorInfo = null;
    }
}

const createSessionStore = () => new SessionStore();

export default SessionStore;
export {
    createSessionStore,
};
