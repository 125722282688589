import React from 'react';
import PropTypes from 'prop-types';
import BaseStrategy from '../../../stores/shared/RefactoredObjectList/strategy';
import NotepadStore from '../../../stores/client/RefactoredObjectList/Notepad';
import NotepadList from '../../../stores/client/NotepadList/NotepadList';
import {
    FlatStrategy,
    HouseStrategy,
    SteadStrategy,
    CommercialStrategy,
} from '../../../stores/client/RefactoredObjectList/strategy';
import { withSaleStrategy, withRentStrategy } from '../../../stores/shared/RefactoredObjectList/strategy/mixins';
import {
    FlatDetailed,
    HouseDetailed,
    SteadDetailed,
    CommercialDetailed,
} from '../../../stores/client/RefactoredObjectDetailed';
import RelatedStore from '../../../stores/client/RefactoredObjectList/Related';
import withClientStrategy from '../../../stores/client/RefactoredObjectList/strategy/mixins';
import { withSaleDetailed, withRentDetailed } from '../../../stores/shared/RefactoredObjectDetailed/mixins';
import ObjectListStore from '../../../stores/client/RefactoredObjectList/ObjectList';
import ClientExportObjects from '../../../stores/client/ExportObjects/ExportObjects';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import ObjectListContent from './ObjectListContent';
import withStore from '../../../hocs/withStore';

const storesFactory = (options) => {
    const { dealType, objectType } = options;
    let Strategy;
    let Detailed;
    let withDealTypeStrategy;
    let withDealTypeDetailed;
    switch (objectType) {
        case 'flats':
            Detailed = FlatDetailed;
            Strategy = FlatStrategy;
            break;
        case 'houses':
            Detailed = HouseDetailed;
            Strategy = HouseStrategy;
            break;
        case 'steads':
            Detailed = SteadDetailed;
            Strategy = SteadStrategy;
            break;
        case 'commercial':
            Detailed = CommercialDetailed;
            Strategy = CommercialStrategy;
            break;
        default:
            Strategy = BaseStrategy;
    }
    switch (dealType) {
        case 'sale':
            withDealTypeStrategy = withSaleStrategy;
            withDealTypeDetailed = withSaleDetailed;
            break;
        case 'rent':
        default:
            withDealTypeStrategy = withRentStrategy;
            withDealTypeDetailed = withRentDetailed;
    }
    Strategy = withClientStrategy(withDealTypeStrategy(Strategy));
    const list = new ObjectListStore(new Strategy());
    const notepads = new NotepadList();
    const exportObjects = new ClientExportObjects(list, notepads);
    const tabs = new PopupTabsWithApproveStore();
    return () => ({
        tabs,
        list,
        notepads,
        exportObjects,
        createDetailedClass: () => withDealTypeDetailed(Detailed),
        createRelated: (id, phone) => new RelatedStore(id, phone),
        createNotepad: (id) => new NotepadStore(id),
    });
};

const ObjectList = (props) => {
    const {
        match: {
            params: {
                dealType,
                objectType,
            },
        },
        meta,
    } = props;
    const createStores = storesFactory({
        dealType,
        objectType,
    });
    const Component = withStore(
        ObjectListContent,
        createStores,
    );
    return <Component meta={meta} selectable />;
};

ObjectList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dealType: PropTypes.string,
            objectType: PropTypes.string,
        }),
    }),
    meta: PropTypes.shape({}),
};

ObjectList.defaultProps = {
    match: {},
    meta: {},
};

export default ObjectList;
