import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'house',
    fields: [{
        name: 'cityId',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Нас. пункт',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required_without:streetId',
    }, {
        name: 'streetId',
        type: 'directorySearch',
        directory: DIRECTORIES.STREET,
        label: 'Улица',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required_without:cityId',
    }, {
        name: 'houseTypeId',
        type: 'directorySelect',
        directory: DIRECTORIES.HOUSE_TYPE,
        label: 'Тип дома',
        clearable: true,
        searchable: true,
        size: 'md',
        rules: 'required',
    }, {
        name: 'number',
        type: 'text',
        label: 'Номер дома',
        size: 'md',
        rules: 'required|max:20',
    }, {
        name: 'structureNumber',
        type: 'text',
        label: 'Строение',
        size: 'md',
        rules: 'max:50',
    }, {
        name: 'buildingNumber',
        type: 'text',
        label: 'Корпус',
        size: 'md',
        rules: 'max:50',
    }],
}];
