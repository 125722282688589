import { computed, observable } from 'mobx';
import { Field } from 'mobx-react-form';

class DefaultField extends Field {
    @observable $readOnly = false;

    constructor(props) {
        super(props);
        this.$readOnly = props.data.readOnly || false;
    }

    @computed
    get readOnly() {
        return this.$readOnly;
    }
}

export default DefaultField;
