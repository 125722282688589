import cloneDeep from 'lodash.clonedeep';
import { ObjectService } from '../../../services/client';
import { CommercialDetailed } from '../../shared/RefactoredObjectDetailed';
import { withBaseDetailed, withClient } from './mixins';
import forms from '../../../forms/client/objects-detailed';

class ClientCommercialDetailed extends CommercialDetailed {
    constructor() {
        super(ObjectService);
        const { formName } = this;
        this.fields = cloneDeep(forms[formName]);
    }
}

export default withBaseDetailed(withClient(ClientCommercialDetailed));
