/* global alert, window */
import React, { useState } from 'react';
import { Observer } from 'mobx-react';

import Dialog from '../../shared/Dialog';
import SVGIcon from '../../../components/Icons/SVGIcon';
import Dropdown from '../../../components/Dropdown';
import useStores from '../../../hooks/client/use-stores';
import VkService from '../../../utils/vk-service';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import ApproveDialog from '../../shared/Dialog/ApproveDialog';
import { SOCIAL_NETWORK_NAME } from '../../../constants/client';
import { SocialNetworkService } from '../../../services/client';
import { OKIcon, SocialIcon, VKIcon } from '../../../components/Icons/icons';
import { OK_CLIENT_ID, OK_REDIRECT_URL, VK_CLIENT_ID } from '../../../config/client';

import styles from './SocialNetworks.module.scss';

const SocialNetworksDropdown = () => {
    const { sessionStore: { userId, socialNetworkInfo }, dialogStore } = useStores();

    const [socialToUnbind, setSocialToUnbind] = useState('');

    const handleBindSocial = async (social) => {
        if (social === 'vk' && VK_CLIENT_ID) {
            if (!window.VK) {
                dialogStore.show('content-block-warning');
                return;
            }
            const userName = await VkService.login();
            if (userName) {
                socialNetworkInfo.vkUserName = userName;
            } else {
                // eslint-disable-next-line no-alert
                alert('Ошибка при авторизации ВКонтакте');
            }
        } else if (social === 'ok' && userId && OK_CLIENT_ID && OK_REDIRECT_URL) {
            window.location.href = `https://connect.ok.ru/oauth/authorize?client_id=${OK_CLIENT_ID}&scope=VALUABLE_ACCESS;LONG_ACCESS_TOKEN;PUBLISH_TO_STREAM;PHOTO_CONTENT;GROUP_CONTENT&response_type=code&redirect_uri=${OK_REDIRECT_URL}&layout=w&state=${userId}`;
        }
    };

    const handleUnbindSocial = (social) => {
        setSocialToUnbind(social);
        dialogStore.show('approve-unbind-account');
    };

    const handleApproveUnbindSocial = () => {
        if (socialToUnbind === 'vk') {
            VkService.logout();
            socialNetworkInfo.vkUserName = '';
        } else if (socialToUnbind === 'ok') {
            SocialNetworkService.unbindOkAccount().then(() => {
                socialNetworkInfo.okUserName = '';
            });
        }
    };

    const getActions = () => {
        const actionsArray = [];
        if (socialNetworkInfo.vkUserName) {
            actionsArray.push({
                id: 'vk-binded',
                icon: <SVGIcon SVGElement={VKIcon} />,
                text: `Вы авторизованы как ${socialNetworkInfo.vkUserName}`,
                onClick: () => handleUnbindSocial('vk'),
            });
        } else {
            actionsArray.push({
                id: 'vk-unbinded',
                icon: <SVGIcon SVGElement={VKIcon} />,
                text: 'Привязать аккаунт',
                onClick: () => handleBindSocial('vk'),
            });
        }
        if (socialNetworkInfo.okUserName) {
            actionsArray.push({
                id: 'ok-binded',
                icon: <SVGIcon SVGElement={OKIcon} />,
                text: `Вы авторизованы как ${socialNetworkInfo.okUserName}`,
                onClick: () => handleUnbindSocial('ok'),
            });
        } else {
            actionsArray.push({
                id: 'ok-unbinded',
                icon: <SVGIcon SVGElement={OKIcon} />,
                text: 'Привязать аккаунт',
                onClick: () => handleBindSocial('ok'),
            });
        }
        return actionsArray;
    };

    return (
        <Observer>
            {() => (
                <>
                    <Dropdown
                        name={(
                            <>
                                <SVGIcon
                                    height={14}
                                    className={styles['social-network__icon']}
                                    SVGElement={SocialIcon}
                                />
                                Соцсети
                            </>
                        )}
                        align={Dropdown.ALIGN.RIGHT}
                        className={styles['social-network__toggle']}
                    >
                        <div className={styles['social-network__menu']}>
                            {getActions().map((action) => (
                                <ButtonMenu
                                    icon={action.icon}
                                    onClick={action.onClick}
                                    className={styles['social-network__action']}
                                >
                                    {action.text}
                                </ButtonMenu>
                            ))}
                        </div>
                    </Dropdown>

                    <ApproveDialog
                        name="approve-unbind-account"
                        header="Отвязать аккаунт"
                        onClose={() => setSocialToUnbind('')}
                        useStores={useStores}
                        onApprove={handleApproveUnbindSocial}
                    >
                        Вы уверены, что хотите отвязать аккаунт
                        &nbsp;
                        <b>{SOCIAL_NETWORK_NAME[socialToUnbind]}</b>
                        ?
                    </ApproveDialog>

                    <Dialog
                        name="content-block-warning"
                        header="Внимание"
                        useStores={useStores}
                    >
                        <div>
                            Пожалуйста, отключите блокировщик рекламы для работы с ВКонтакте.
                        </div>
                    </Dialog>
                </>
            )}
        </Observer>
    );
};

export default SocialNetworksDropdown;
