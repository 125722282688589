import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './DetailedView.module.scss';

const DetailedViewInputList = ({ fieldList: { label, name }, children }) => (
    <div className={`${styles.inputlist} ${styles[`inputlist--${name}`] || ''}`}>
        {
            label
                ? (
                    <Label className={styles['field-label']}>
                        {label}
                    </Label>
                )
                : null
        }
        <div className={styles['inputlist-children']}>
            {children}
        </div>
    </div>
);

DetailedViewInputList.propTypes = {
    fieldList: PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    children: PropTypes.element.isRequired,
};

export default DetailedViewInputList;
