/* eslint-disable */
import cloneDeep from 'lodash.clonedeep';
import { computed, observable } from 'mobx';
import reduceFields from '../../../../forms/utils/reduce-fields';
import { EXCLUSIVE_OBJECT_CONTENT_MAP, OBJECT_CONTENT_MAP } from '../../../../constants/client';

const withClient = (Store) => (
    class extends Store {
        @observable $userNote;

        $objectContentMap = OBJECT_CONTENT_MAP;

        $exclusiveObjectContentMap = EXCLUSIVE_OBJECT_CONTENT_MAP;

        disabledFields = {
            flats: [
                'area',
                'city',
                'cityDistrict',
                'street',
                'house',
                'roomCount',
                'series',
                'wallMaterial',
                'floor',
                'floorCount',
                'totalArea',
                'kitchenArea',
                'livingArea',
            ],
            houses: [
                'area',
                'city',
                'cityDistrict',
                'street',
                'house',
                'wallMaterial',
                'totalArea',
                'landArea',
            ],
            steads: [
                'area',
                'city',
                'cityDistrict',
                'street',
                'steadType',
                'totalArea',
            ],
            commercial: [
                'area',
                'city',
                'cityDistrict',
                'street',
                'house',
                'commercialType',
                'totalArea',
            ],
        };

        @computed
        get canEdit() {
            const { $canEdit, $moderationInProgress } = this;
            return $canEdit && !$moderationInProgress;
        }

        @computed
        get moderationWarning() {
            const { $moderationInProgress } = this;
            return $moderationInProgress;
        }

        @computed
        get canDelete() {
            const { canEdit } = this;
            return canEdit;
        }

        @computed
        get objectContentMap() {
            const { isExclusive, isNew } = this;
            let contentMap = isExclusive
                ? this.$exclusiveObjectContentMap
                : this.$objectContentMap;
            if (isNew) {
                contentMap = contentMap.filter((item) => item.name !== 'incorrect');
            }
            return contentMap;
        }

        @computed
        get userNote() {
            const { $userNote } = this;
            return $userNote
                ? $userNote.note
                : '';
        }

        deleteObject() {
            const { id, objectType } = this;
            return this.dataSource.deleteObject(id, { objectType });
        }

        checkObject() {
            const { id, objectType } = this;
            return this.dataSource.checkObject(id, { objectType });
        }

        updateUserNote() {
            const { id, objectType, form } = this;
            const { userNote } = this.prepareRequest(form.values());
            return this.dataSource.updateUserNote(id, { userNote }, { objectType });
        }

        updateIcorrectOptions() {
            const { id, objectType, form } = this;
            const { incorrect } = form.values();
            return this.dataSource.updateIncorrectOptions(
                id, { incorrect: 1, options: incorrect }, { objectType },
            );
        }

        createForm(mixins = []) {
            const {
                form,
                isNew,
                objectType,
                disabledFields,
            } = this;
            if (form) {
                const output = super.createForm(mixins);
                const userNoteField = output.$('userNote');
                userNoteField.resetValidation();
                userNoteField.set('extra', { ...userNoteField.get('extra'), readOnly: false });
                userNoteField.set('disabled', false);
                return output;
            }
            if (!isNew) {
                this.fields = reduceFields(cloneDeep(this.fields), (field) => {
                    
                    if (disabledFields[objectType].includes(field.name)) {
                        // eslint-disable-next-line no-param-reassign
                        field.readOnly = true;
                    }
                    return field;
                });
            }
            const output = super.createForm(mixins);
            const userNoteField = output.$('userNote');
            userNoteField.resetValidation();
            userNoteField.set('extra', { ...userNoteField.get('extra'), readOnly: false });
            userNoteField.set('disabled', false);
            return output;
        }

        prepareRequest({ ...values }) {
            const {
                isNew,
                objectType,
                isExclusive,
                canEdit,
            } = this;
            const prepared = super.prepareRequest(values);
            let updated;
            if (!isNew && (!isExclusive || !canEdit)) {
                return {
                    userNote: values.userNote,
                };
            }
            if (!isNew) {
                switch (objectType) {
                    case 'flats': {
                        const {
                            areaId,
                            cityId,
                            cityDistrictId,
                            streetId,
                            houseId,
                            roomCount,
                            seriesId,
                            wallMaterialId,
                            floor,
                            totalArea,
                            kitchenArea,
                            livingArea,
                            ...rest
                        } = prepared;
                        updated = rest;
                        break;
                    }
                    case 'houses': {
                        const {
                            areaId,
                            cityId,
                            cityDistrictId,
                            streetId,
                            houseId,
                            wallMaterialId,
                            totalArea,
                            landArea,
                            ...rest
                        } = prepared;
                        updated = rest;
                        break;
                    }
                    case 'steads': {
                        const {
                            areaId,
                            cityId,
                            cityDistrictId,
                            streetId,
                            objectSteadTypeId,
                            totalArea,
                            ...rest
                        } = prepared;
                        updated = rest;
                        break;
                    }
                    case 'commercial': {
                        const {
                            areaId,
                            cityId,
                            cityDistrictId,
                            streetId,
                            houseId,
                            objectCommercialTypeId,
                            totalArea,
                            ...rest
                        } = prepared;
                        updated = rest;
                        break;
                    }
                    default:
                        updated = prepared;
                }
                return {
                    ...updated,
                    userNote: values.userNote,
                };
            }
            return {
                ...prepared,
                userNote: values.userNote,
            };
        }
    }
);

export default withClient;
