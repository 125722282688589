const createDaDataService = (request) => ({
    getSuggestions: (query) => (
        request.get(`/objects/geolocation?query=${query}`)
            .then(({ suggestions = [] } = {}) => {
                if (!suggestions || !suggestions.length) {
                    return Promise.reject(new Error('No suggestions'));
                }
                return Promise.resolve(suggestions);
            })
            .catch((e) => Promise.reject(e))
    ),
});

export default createDaDataService;
