import { computed } from 'mobx';
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter';
import { PRICE_DELIMITER, PRICE_FLOAT_CHARS } from '../../../../constants/shared';

const withSaleDetailed = (Store) => (
    class extends Store {
        dealType = 'sale';

        @computed
        get formName() {
            const { objectType } = this;
            return `sale${capitalizeFirstLetter(objectType)}`;
        }

        @computed
        get flatType() {
            const { $sale } = this;
            if (!$sale || !$sale.flatType) {
                return null;
            }
            return {
                value: $sale.flatType.id,
                label: $sale.flatType.name,
            };
        }

        @computed
        get furnishing() {
            const { $sale, $furnishing } = this;
            if (!$sale || !$sale.furnishing) {
                return $furnishing
                    ? {
                        value: $furnishing.id,
                        label: $furnishing.name,
                    }
                    : null;
            }
            return {
                value: $sale.furnishing.id,
                label: $sale.furnishing.name,
            };
        }

        @computed
        get windowType() {
            const { $sale } = this;
            if (!$sale || !$sale.windowType) {
                return null;
            }
            return {
                value: $sale.windowType.id,
                label: $sale.windowType.name,
            };
        }

        @computed
        get surcharge() {
            const { $sale } = this;
            if (!$sale || !$sale.surcharge) {
                return null;
            }
            return $sale.surcharge;
        }

        @computed
        get exchange() {
            const { $sale } = this;
            if (!$sale || !$sale.canBeExchanged) {
                return false;
            }
            return Boolean($sale.canBeExchanged);
        }

        @computed
        get requiresDeposit() {
            const { $sale } = this;
            if (!$sale) {
                return false;
            }
            return Boolean($sale.requiresDeposit);
        }

        @computed
        get yearOfCompletion() {
            const { $sale } = this;
            if (!$sale || !$sale.yearOfCompletion) {
                return null;
            }
            return $sale.yearOfCompletion;
        }

        @computed
        get quarterOfCompletion() {
            const { $sale } = this;
            if (!$sale || !$sale.quarterOfCompletion) {
                return {
                    value: null,
                    label: '-',
                };
            }
            return {
                value: `${$sale.quarterOfCompletion}`,
                label: `${$sale.quarterOfCompletion} кв`,
            };
        }

        @computed
        get isLandOwned() {
            const { $sale } = this;
            return Boolean($sale?.isLandOwned);
        }

        @computed
        get hasDeposit() {
            const { $sale } = this;
            return Boolean($sale?.hasDeposit);
        }

        @computed
        get unitPrice() {
            const { $unitPrice } = this;
            const priceFloatDelimiter = 10 ** PRICE_FLOAT_CHARS;
            const priceDelimiter = PRICE_DELIMITER / priceFloatDelimiter;
            return $unitPrice
                ? Math.round($unitPrice / priceDelimiter) / priceFloatDelimiter
                : 0;
        }

        @computed
        get price() {
            const { $price } = this;
            const priceFloatDelimiter = 10 ** PRICE_FLOAT_CHARS;
            const priceDelimiter = PRICE_DELIMITER / priceFloatDelimiter;
            return $price
                ? Math.round($price / priceDelimiter) / priceFloatDelimiter
                : 0;
        }

        @computed
        get mortgage() {
            const { $sale } = this;
            return Boolean($sale?.mortgage);
        }

        prepareRequest(values) {
            const prepared = super.prepareRequest(values);
            const updated = {
                ...prepared,
                price: Math.round(prepared.price * PRICE_DELIMITER),
                unitPrice: Math.round(prepared.unitPrice * PRICE_DELIMITER),
            };
            const { objectType } = this;
            switch (objectType) {
                case 'flats':
                    return {
                        ...updated,
                        dealType: 'sale',
                        sale: {
                            objectFlatTypeId: values.flatType,
                            canBeExchanged: values.exchange,
                            surcharge: values.surcharge,
                            furnishingId: values.furnishing,
                            windowTypeId: values.windowType,
                            yearOfCompletion: values.yearOfCompletion,
                            quarterOfCompletion: values.quarterOfCompletion,
                            mortgage: values.mortgage,
                        },
                    };
                case 'houses':
                    return {
                        ...updated,
                        dealType: 'sale',
                        sale: {
                            canBeExchanged: values.exchange,
                            hasDeposit: values.hasDeposit,
                            isLandOwned: values.isLandOwned,
                        },
                    };
                case 'commercial':
                    return {
                        ...updated,
                        dealType: 'sale',
                        sale: {
                            canBeExchanged: values.exchange,
                            surcharge: values.surcharge,
                            requiresDeposit: values.requiresDeposit,
                        },
                    };
                default:
                    return {
                        ...updated,
                        dealType: 'sale',
                    };
            }
        }

        toItem() {
            const item = super.toItem();
            return {
                ...item,
                sale: this.$sale,
            };
        }
    }
);

export default withSaleDetailed;
