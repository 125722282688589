import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EMPTY_DATA_STRING, POSITION } from '../../constants/shared';

import styles from './Selected.module.scss';
import isTextOverflowActive from '../../utils/is-text-overflow-active';

const SelectedRowWithHint = ({
    classNames,
    name,
    children,
}) => {
    const str = children || EMPTY_DATA_STRING;
    const [ellipsed, setEllipsed] = useState(false);

    const toggleQuestionMark = (node) => {
        if (node) setEllipsed(isTextOverflowActive(node));
    };

    return (
        <div className={`${styles.selected__row} ${classNames?.row}`}>
            {
                name
                    ? (
                        <div className={`${styles.selected__name} ${classNames?.name}`}>
                            {name}
                        </div>
                    )
                    : null
            }
            <div
                ref={toggleQuestionMark}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(ellipsed ? {
                    'data-for': 'selected-object-tooltip',
                    'data-tip': str,
                    'data-effect': 'float',
                    'data-place': POSITION.RIGHT,
                    'data-class': styles.selected__hint,
                } : {})}
                className={`${styles['selected__value--ellipsis']} ${styles.selected__value}  ${classNames?.value}`}
            >
                {str}
            </div>
            {ellipsed && (
                <div
                    className={`${styles.selected__tooltip} ${classNames?.value}`}
                    data-for="selected-object-tooltip"
                    data-tip={ellipsed ? str : null}
                    data-effect="float"
                    data-place={POSITION.RIGHT}
                    data-class={styles.selected__hint}
                >
                    ?
                </div>
            )}
        </div>
    );
};

SelectedRowWithHint.propTypes = {
    classNames: PropTypes.shape({
        row: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
    }),
    name: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ]),
        ),
    ]),
};

SelectedRowWithHint.defaultProps = {
    classNames: {},
    name: '',
    children: null,
};

export default SelectedRowWithHint;
