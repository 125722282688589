import { OPERATOR_STATUS } from '../../../constants/operator';
import createQuery from '../../utils/create-query';

const createOperatorService = (request) => ({
    getAll: (filter = {}, page) => (
        request.post(`/operators/all${page ? `?page=${page}` : ''}`, filter)
    ),
    getRightsScheme: () => request.get('/operators/rights'),
    getFreeCompanies: () => request.get('/operators/free-companies'),
    getOperator: (id) => (
        request.get(`/operators/${id}`)
    ),
    updateOperator: (id, operatorData) => (
        request.put(`/operators/${id}`, operatorData)
    ),
    createOperator: (operatorData) => (
        request.post('/operators/', operatorData)
    ),
    banOperator: (id) => (
        request.put(`/operators/${id}/status`, {
            status: OPERATOR_STATUS.BLOCKED.toLowerCase(),
        })
    ),
    getReport: ({ query = '', ...filter } = {}, page) => {
        const ctxQuery = createQuery(filter);
        return request.get(`/operators/report${page ? `?page=${page}` : ''}${query ? `&query=${query}&` : '&'}${ctxQuery}`);
    },
    getLog: ({ query = '', ...filter } = {}, page) => {
        const ctxQuery = createQuery(filter);
        return request.get(`/operators/actions${page ? `?page=${page}` : ''}${query ? `&query=${query}&` : '&'}${ctxQuery}`);
    },
});

export default createOperatorService;
