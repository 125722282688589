import {
    DIRECTORIES,
    DEFAULT_DATE_FORMAT,
    DATE_TIME_FORMAT,
    SCROLL_WIDTH_LAG,
    THUMBNAIL_SLIDER,
    STATUS_FORM,
    EMPTY_DATA_STRING,
    PRICE_DELIMITER,
    PRICE_FLOAT_CHARS,
    DEFAULT_CENTER_POSITION,
    MAP_TILE_LAYER_URL,
    DADATA_GEODATA_URL,
    SIZE,
    POSITION,
    TAB_TYPE,
    SORT_ORDER,
    OBJECT_TYPE_NAME,
    DEAL_TYPE_NAME,
    OBJECT_TYPE,
    DEAL_TYPE,
    NOTEPAD_MESSAGES,
    TOKEN_UPDATE_LAG,
    DEAL_TYPE_MAP,
    OBJECT_TYPE_MAP,
    EXPORT_TYPE,
    EXPORT_STATUS,
    REQUEST_REPEAT_INTERVAL,
} from '../shared';
import TABLE_CELL from './table-cell';

const PAGE_404 = '/404';

const TEXTS = {
    PARSER_NOT_CONFIGURED: 'На текущий момент парсер не сконфигурирован',
    RESULT_IS_EMPTY: 'По данному запросу ничего не найдено',
};

const OPERATOR_REPORT = 'operator-report-tooltip';

const OPERATOR_STATUS = {
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED',
    ARCHIVED: 'ARCHIVED',
};

const ACTIVATION_KEY_STATUS = {
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED',
};

const ACTIVATION_KEY_STATUS_NAME = {
    ACTIVE: 'Активен',
    BLOCKED: 'Заблокирован',
};

const OPERATOR_DIRECTORIES = {
    ...DIRECTORIES,
    OPERATOR_ACTION_TYPE: 'operatoractiontype',
    COMPANIES: 'companies',
    USERS: 'users',
    BILLING_COMPANIES: 'billingcompanies',
    BILLING_PAYMENT_TYPES: 'billingpayments',
    BASE_HIGHLIGHT_COLOR: 'base/highlights',
    PARSER_HIGHLIGHT_COLOR: 'parser/highlights',
};

const OBJECT_CONTENT_MAP = [
    { name: 'location', label: 'Расположение' },
    { name: 'basicInfo', label: 'Основная информация' },
    { name: 'additionalInfo', label: 'Доп. информация' },
    { name: 'photos', label: 'Фото' },
    { name: 'video', label: 'Ссылка на видео', hiddenInSideMenu: true },
    { name: 'note', label: 'Текст объявления' },
    { name: 'map', label: 'Карта' },
    { name: 'sources', label: 'Источники' },
    { name: 'history', label: 'История' },
    { name: 'contacts', label: 'Контакты' },
    { name: 'manage', label: 'Управление' },
    { name: 'similar', label: 'Похожие объекты' },
    { name: 'incorrect', label: 'Некорректность' },
];

const EXCLUSIVE_OBJECT_CONTENT_MAP = [
    { name: 'location', label: 'Расположение' },
    { name: 'basicInfo', label: 'Основная информация' },
    { name: 'additionalInfo', label: 'Доп. информация' },
    { name: 'photos', label: 'Фото' },
    { name: 'video', label: 'Ссылка на видео', hiddenInSideMenu: true },
    { name: 'note', label: 'Текст объявления' },
    { name: 'map', label: 'Карта' },
    { name: 'history', label: 'История' },
    { name: 'company', label: 'Компания' },
    { name: 'contacts', label: 'Контакты' },
    { name: 'manage', label: 'Управление' },
    { name: 'similar', label: 'Похожие объекты' },
    { name: 'incorrect', label: 'Некорректность' },
];

export default SIZE;
export {
    OPERATOR_DIRECTORIES as DIRECTORIES,
    TABLE_CELL,
    PAGE_404,
    DEFAULT_DATE_FORMAT,
    DATE_TIME_FORMAT,
    SCROLL_WIDTH_LAG,
    THUMBNAIL_SLIDER,
    STATUS_FORM,
    EMPTY_DATA_STRING,
    PRICE_DELIMITER,
    PRICE_FLOAT_CHARS,
    DEFAULT_CENTER_POSITION,
    MAP_TILE_LAYER_URL,
    DADATA_GEODATA_URL,
    SIZE,
    POSITION,
    TAB_TYPE,
    TEXTS,
    SORT_ORDER,
    OBJECT_TYPE_NAME,
    DEAL_TYPE_NAME,
    OBJECT_TYPE,
    DEAL_TYPE,
    OPERATOR_STATUS,
    ACTIVATION_KEY_STATUS,
    ACTIVATION_KEY_STATUS_NAME,
    OPERATOR_REPORT,
    OBJECT_CONTENT_MAP,
    EXCLUSIVE_OBJECT_CONTENT_MAP,
    NOTEPAD_MESSAGES,
    TOKEN_UPDATE_LAG,
    DEAL_TYPE_MAP,
    OBJECT_TYPE_MAP,
    EXPORT_TYPE,
    EXPORT_STATUS,
    REQUEST_REPEAT_INTERVAL,
};
