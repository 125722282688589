/* eslint-disable no-redeclare */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './FilterGroups.module.scss';

const FilterGroupsWrapper = ({
    title,
    className,
    children,
}) => (
    <div className={`${styles.groups} ${className || ''}`}>
        <div className={styles.groups__title}>
            {title}
        </div>
        <div className={styles.groups__list}>
            {children}
        </div>
    </div>
);

FilterGroupsWrapper.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.element,
};

FilterGroupsWrapper.defaultProps = {
    className: '',
    title: '',
    children: null,
};

export default FilterGroupsWrapper;
