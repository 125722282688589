import { DIRECTORIES } from '../../../constants/operator';
import {
    ObjectStatusReasonDirectory,
    Contact,
    OperatorCompanies,
    Users,
    BillingCompanies,
    UserActionsDirectory,
    HighlightColors,
} from './entities';
import {
    Directory,
    HouseDirectory,
    AgencyDirectory,
    AreaDirectory,
    CityDistrictDirectory,
    DaDataSuggestions,
    StreetDirectory,
    StreetsFilterDirectory,
    CityDirectory,
} from '../../shared/Directories/entities';
import Directories from '../../shared/Directories';
import { DaDataService, DirectoryService } from '../../../services/operator';
import CityDistrictLandmarkDirectory from '../../shared/Directories/entities/CityDistrictLandmarkDirectory';
import { STATES } from '../../shared/DataSource';

const directories = [
    DIRECTORIES.ROOMS_COUNT,
    DIRECTORIES.CITY_TYPE,
    DIRECTORIES.STREET_TYPE,
    DIRECTORIES.SERIES,
    DIRECTORIES.PARSER_SOURCE_BOARD,
    DIRECTORIES.WALL_MATERIAL,
    DIRECTORIES.OBJECT_CONDITION,
    DIRECTORIES.OBJECT_FLAT_TYPE,
    DIRECTORIES.OBJECT_STATUS,
    DIRECTORIES.OBJECT_ROOM_TYPE,
    DIRECTORIES.LAVATORY,
    DIRECTORIES.BALCONY,
    DIRECTORIES.OBJECT_ROOM_TYPE,
    DIRECTORIES.OWNER_TYPE,
    DIRECTORIES.FURNITURE,
    DIRECTORIES.FURNISHING,
    DIRECTORIES.WINDOW_TYPE,
    DIRECTORIES.OBJECT_HOUSE_TYPE,
    DIRECTORIES.HOUSE_TYPE,
    DIRECTORIES.HEATING,
    DIRECTORIES.RENT_CONDITION,
    DIRECTORIES.RENT_FLAT_TYPE,
    DIRECTORIES.RENT_SERVICE,
    DIRECTORIES.RENT_PERIOD,
    DIRECTORIES.EFFLUENT,
    DIRECTORIES.WATER_SUPPLY,
    DIRECTORIES.OBJECT_STEAD_TYPE,
    DIRECTORIES.PASSAGE,
    DIRECTORIES.BUILDING,
    DIRECTORIES.OBJECT_COMMERCIAL_TYPE,
    DIRECTORIES.STOVE_TYPE,
    DIRECTORIES.OPERATOR_ACTION_TYPE,
    DIRECTORIES.BILLING_COMPANIES,
    DIRECTORIES.BILLING_PAYMENT_TYPES,
    DIRECTORIES.INCORRECT_OPTIONS,
    DIRECTORIES.USER_ACTION_TYPE,
];

const searchableDirectories = [
    DIRECTORIES.REGION,
    DIRECTORIES.AREA,
    DIRECTORIES.CITY,
    DIRECTORIES.COMPLEX,
    DIRECTORIES.DEVELOPER,
    DIRECTORIES.ORGANIZATION,
    DIRECTORIES.AGENCY,
    DIRECTORIES.CITY_DISTRICT_LANDMARK,
];

const requiredDefaults = [
    DIRECTORIES.CITY,
];

class OperatorDirectories extends Directories {
    constructor() {
        super(
            {
                lists: directories,
                searchable: searchableDirectories,
                required: requiredDefaults,
            },
            DirectoryService,
        );
        this.house = new HouseDirectory(DIRECTORIES.HOUSE, DirectoryService);
        this.street = new StreetDirectory(DIRECTORIES.STREET, DirectoryService);
        this.streets = new StreetsFilterDirectory(DIRECTORIES.STREETS, DirectoryService);
        this.objectflattype = new Directory(
            DIRECTORIES.OBJECT_FLAT_TYPE,
            DirectoryService,
            STATES.DEFAULT,
            { applySort: true, sortFieldName: 'value' },
        );
        this.citydistrict = new CityDistrictDirectory(DIRECTORIES.CITY_DISTRICT, DirectoryService);
        this.contact = new Contact();
        this.area = new AreaDirectory(DIRECTORIES.AREA, DirectoryService);
        this.city = new CityDirectory(DIRECTORIES.CITY, DirectoryService);
        this.agency = new AgencyDirectory(DIRECTORIES.AGENCY, DirectoryService);
        this.citydistrictlandmark = new CityDistrictLandmarkDirectory(
            DIRECTORIES.CITY_DISTRICT_LANDMARK,
            DirectoryService,
        );
        this.dadatasuggestions = new DaDataSuggestions(DaDataService);
        this.companies = new OperatorCompanies(DIRECTORIES.COMPANIES, DirectoryService);
        this.billingcompanies = new BillingCompanies(
            DIRECTORIES.BILLING_COMPANIES,
            DirectoryService,
        );
        this.users = new Users(DIRECTORIES.USERS, DirectoryService);
        this.actiontypes = new UserActionsDirectory(DIRECTORIES.USER_ACTION_TYPE, DirectoryService);
        this.objectstatus = new Directory(
            DIRECTORIES.OBJECT_STATUS,
            DirectoryService,
            STATES.DEFAULT,
            { applySort: true, sortFieldName: 'value' },
        );
        this.objectstatusreason = new ObjectStatusReasonDirectory(
            DIRECTORIES.OBJECT_STATUS_REASON,
            this[DIRECTORIES.OBJECT_STATUS],
            DirectoryService,
        );
        this[DIRECTORIES.BASE_HIGHLIGHT_COLOR] = new HighlightColors(
            DIRECTORIES.BASE_HIGHLIGHT_COLOR,
            DirectoryService,
        );
        this[DIRECTORIES.PARSER_HIGHLIGHT_COLOR] = new HighlightColors(
            DIRECTORIES.PARSER_HIGHLIGHT_COLOR,
            DirectoryService,
        );
    }
}

const createDirectoriesStore = () => new OperatorDirectories();

export default OperatorDirectories;
export {
    createDirectoriesStore,
};
