import React from 'react';
import { SelectedRow, SelectedRowWithHint } from '../../../components/TableSelected';
import { defaultProps, propTypes } from './propTypes';

import styles from './SelectedObject.module.scss';

const SelectedRentFlat = ({
    store,
}) => (
    <>
        <SelectedRowWithHint name="Мебель" classNames={{ row: styles.selected__row }}>
            {store.furniture?.map(({ label }) => label).join(', ')}
        </SelectedRowWithHint>
        <SelectedRowWithHint name="Ком. услуги" classNames={{ row: styles.selected__row }}>
            {store.rentService?.label}
        </SelectedRowWithHint>
        <SelectedRow name="Холодильник" classNames={{ row: styles.selected__row }}>
            {store.hasRefrigerator ? 'Да' : 'Нет'}
        </SelectedRow>
        <SelectedRow name="Телевизор" classNames={{ row: styles.selected__row }}>
            {store.hasTv ? 'Да' : 'Нет'}
        </SelectedRow>
        <SelectedRow name="Ст. машина" classNames={{ row: styles.selected__row }}>
            {store.hasWasher ? 'Да' : 'Нет'}
        </SelectedRow>
    </>
);

SelectedRentFlat.propTypes = { store: propTypes.store };
SelectedRentFlat.defaultProps = { store: defaultProps.store };

export default SelectedRentFlat;
