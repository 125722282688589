import { SIZE } from '../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'payment-type',
    hidden: true,
    fields: [{
        type: 'select',
        name: 'payment-type',
        label: 'Платежи',
        size: SIZE.MEDIUM,
        value: {
            id: 1,
            value: 'annuity',
            label: 'Аннуитетные',
        },
        options: [{
            id: 1,
            value: 'annuity',
            label: 'Аннуитетные',
        }, {
            id: 2,
            value: 'differentiated',
            label: 'Дифференцированные',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'payment-range',
    fields: [{
        type: 'range',
        name: 'price',
        label: 'Стоимость недвижимости',
        size: SIZE.MEDIUM,
        value: 3000000,
        min: 0,
        max: 30000000,
        measure: 'руб',
        showLabels: false,
    }, {
        type: 'range',
        name: 'initial-payment',
        label: 'Первоначальный взнос (руб.)',
        size: SIZE.MEDIUM,
        value: 1500000,
        min: 0,
        max: 3000000,
        measure: 'руб',
        showLabels: false,
    }, {
        type: 'range',
        name: 'initial-payment-percent',
        label: 'Первоначальный взнос (%)',
        size: SIZE.MEDIUM,
        value: 50,
        min: 0,
        max: 100,
        step: 0.01,
        measure: '%',
        showLabels: false,
    }, {
        type: 'range',
        name: 'percentage',
        label: 'Процентная ставка',
        size: SIZE.MEDIUM,
        value: 4.9,
        min: 1,
        max: 17,
        step: 0.1,
        measure: '%',
        showLabels: true,
    }, {
        type: 'range',
        name: 'period',
        label: 'Срок кредита',
        size: SIZE.MEDIUM,
        value: 5,
        min: 1,
        max: 25,
        step: 1,
        measure: 'лет',
        measureStart: 'год',
        measureEnd: 'лет',
        showLabels: true,
    }],
}];
