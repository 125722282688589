const withObjectDetailedField = (detailedFields, alsoInReverse) => (Store) => (
    class extends Store {
        detailedFields;

        constructor(fields) {
            super(fields);
            this.detailedFields = detailedFields;
            this.observeAreaToPriceFields();
        }

        observeAreaToPriceFields() {
            const form = this;
            const { detailedFields: { totalArea, price, unitPrice } } = this;
            if (!form.has(totalArea) || !form.has(price) || !form.has(unitPrice)) {
                throw new Error('Dependent area fields not fully presented');
            }
            const totalAreaField = form.$(totalArea);
            const priceField = form.$(price);
            const unitPriceField = form.$(unitPrice);
            const handleChange = (({ change: { newValue }, field }) => {
                if (!field.focused) {
                    return;
                }
                const { name: fieldName } = field;
                let changeFieldName;
                let priceValue;
                let totalAreaValue;
                let newPriceValue;
                let changeField;
                switch (fieldName) {
                    case price:
                        priceValue = newValue;
                        totalAreaValue = totalAreaField.value;
                        changeField = unitPriceField;
                        changeFieldName = unitPrice;
                        break;
                    case unitPrice:
                        priceValue = newValue;
                        totalAreaValue = totalAreaField.value;
                        changeField = priceField;
                        changeFieldName = price;
                        break;
                    default:
                        priceValue = priceField.value;
                        totalAreaValue = newValue;
                        changeField = unitPriceField;
                        changeFieldName = unitPrice;
                        break;
                }
                if (totalAreaValue && priceValue) {
                    newPriceValue = fieldName === unitPrice
                        ? Math.round(priceValue * totalAreaValue)
                        : (priceValue / totalAreaValue).toFixed(1);
                } else {
                    newPriceValue = '';
                }
                const withObserver = changeFieldName !== unitPrice || alsoInReverse;
                if (withObserver) {
                    changeField.dispose({ type: 'observer' });
                }
                changeField.set(
                    'value',
                    newPriceValue,
                );
                if (withObserver) {
                    changeField.observe(handleChange);
                }
            });
            totalAreaField.observe(handleChange);
            priceField.observe(handleChange);
            if (alsoInReverse) {
                unitPriceField.observe(handleChange);
            }
        }
    }
);

export default withObjectDetailedField;
