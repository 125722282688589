/* global document */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Observer } from 'mobx-react';
import { ButtonClose } from '../../../components/Button';
import { SIZE, CONTENT_INNER_HEIGHT } from '../../../constants/shared';

import styles from './Dialog.module.scss';

const DefaultContentInner = ({ children, className }) => (
    <div className={`${styles.content__inner} ${className}`}>
        {children}
    </div>
);

DefaultContentInner.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    className: PropTypes.string,
};

DefaultContentInner.defaultProps = {
    className: '',
};

const DefaultHeader = ({ children }) => (
    <div className={`${styles.dialog__header}`}>
        <div className={styles.dialog__title}>{children}</div>
    </div>
);

DefaultHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

const DefaultBody = ({ children, footer }) => (
    <div className={`${styles.dialog__body} ${footer ? styles['dialog__body--with-footer'] : ''}`}>
        {children}
    </div>
);

DefaultBody.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    footer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

DefaultBody.defaultProps = {
    footer: null,
};

const Dialog = ({
    useStores,
    size,
    contentHeight,
    name,
    header,
    footer,
    className,
    closeable,
    children,
    history,
    onOpen,
    onClose,
    onCancel,
    components,
    shouldBeClosed,
}) => {
    const wrappers = { ...Dialog.defaultProps.components, ...components };
    const { dialogStore: store } = useStores();
    const wrapperRef = useRef(null);
    const dialogSize = `dialog--${size}`;
    const contentInnerHeight = `content__inner--${contentHeight}`;

    const cancel = () => {
        if (onCancel && typeof onCancel === 'function') onCancel();
    };

    const handleClickOutside = (e) => {
        e.stopPropagation();
        if (store.closing) return;
        if (closeable === false || !store.open || (store.dialog !== name)) return;
        if (
            wrapperRef
            && wrapperRef.current
            && (
                !wrapperRef.current.contains(e.target)
                && e.target.isConnected
            )
        ) {
            if (shouldBeClosed(e)) {
                store.hide();
                cancel();
            }
        }
    };

    const hideDialog = (e) => {
        if (e) e.preventDefault();
        if (shouldBeClosed()) {
            store.hide();
            cancel();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        store.add(name, {
            history,
            onOpen,
            onClose,
        });
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            store.remove(name);
        };
    });

    return (
        <Observer>
            {() => {
                const current = store.getDialog(store.dialog);
                const customProps = current && current.customProps;
                const renderChildren = children && typeof children === 'function'
                    ? children({ ...customProps })
                    : children;
                return (
                    <Portal>
                        <div
                            className={`
                                ${styles.dialog}
                                ${store.open && store.dialog === name ? styles['dialog--open'] : ''}
                                ${store.closing && store.dialog === name ? styles['dialog--close'] : ''}
                                ${size ? styles[dialogSize] : ''}
                            `}
                            id={`dialog-${name}`}
                        >
                            <div className={`${styles.content}`} ref={wrapperRef}>
                                <wrappers.ContentInnerWrapper className={`${className} ${contentInnerHeight ? styles[contentInnerHeight] : ''}`}>
                                    {
                                        closeable !== false
                                            ? (
                                                <div className={styles.close__container}>
                                                    <div className={styles.close__inner}>
                                                        <ButtonClose
                                                            className={styles.close}
                                                            onClick={hideDialog}
                                                            size={ButtonClose.SIZE.MEDIUM}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                    {
                                        header
                                            ? (
                                                <wrappers.HeaderWrapper>
                                                    {header}
                                                </wrappers.HeaderWrapper>
                                            )
                                            : null
                                    }
                                    {
                                        renderChildren
                                            ? (
                                                <wrappers.BodyWrapper footer={footer}>
                                                    {renderChildren}
                                                </wrappers.BodyWrapper>
                                            )
                                            : null
                                    }
                                    {footer}
                                </wrappers.ContentInnerWrapper>
                            </div>
                        </div>
                    </Portal>
                );
            }}
        </Observer>
    );
};

Dialog.propTypes = {
    useStores: PropTypes.func.isRequired,
    size: PropTypes.oneOf(Object.values(SIZE)),
    contentHeight: PropTypes.oneOf(Object.values(CONTENT_INNER_HEIGHT)),
    name: PropTypes.string.isRequired,
    header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    footer: PropTypes.element,
    className: PropTypes.string,
    history: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    shouldBeClosed: PropTypes.func,
    closeable: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]),
    components: PropTypes.shape({
        HeaderWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        BodyWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        ContentInnerWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
    }),
};

Dialog.defaultProps = {
    size: 'sm',
    contentHeight: 'sm',
    className: '',
    header: null,
    footer: null,
    history: true,
    onOpen: null,
    onClose: null,
    onCancel: null,
    shouldBeClosed: () => true,
    children: null,
    closeable: true,
    components: {
        HeaderWrapper: DefaultHeader,
        BodyWrapper: DefaultBody,
        ContentInnerWrapper: DefaultContentInner,
    },
};

export default Dialog;
