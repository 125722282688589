import { computed, observable } from 'mobx';
import { withAddressField, withObjectDetailedField } from '../../../helpers/Form/mixins';
import HouseDirectory from '../Directories/entities/HouseDirectory';
import ObjectDetailed from './ObjectDetailed';

class CommercialDetailed extends ObjectDetailed {
    @observable $commercialType

    @observable $heating

    @observable $hasInspectionPit

    @observable $hasParking

    @observable $hasSecurity

    constructor(service) {
        super(service, 'commercial');
    }

    @computed
    get objectName() {
        const {
            $street = {},
            $house = {},
            id,
        } = this;
        if ($street) {
            return [$street.name, $house?.number]
                .filter((address) => Boolean(address))
                .join(', ');
        }
        return id;
    }

    @computed
    get house() {
        const { $house } = this;
        if (!$house) {
            return null;
        }
        return {
            value: $house.id,
            label: HouseDirectory.getFullHouseName($house),
            extra: {
                number: $house.number,
                buildingNumber: $house.buildingNumber,
                structureNumber: $house.structureNumber,
            },
        };
    }

    @computed
    get commercialType() {
        const { $commercialType } = this;
        if (!$commercialType) {
            return null;
        }
        return {
            value: $commercialType.id,
            label: $commercialType.name,
        };
    }

    @computed
    get heating() {
        const { $heating } = this;
        if (!$heating) {
            return null;
        }
        return {
            value: $heating.id,
            label: $heating.name,
        };
    }

    @computed
    get wallMaterial() {
        const { $wallMaterial } = this;
        if (!$wallMaterial) {
            return null;
        }
        return {
            value: $wallMaterial.id,
            label: $wallMaterial.name,
        };
    }

    @computed
    get hasBasement() {
        return Boolean(this.$hasBasement);
    }

    @computed
    get hasInspectionPit() {
        return Boolean(this.$hasInspectionPit);
    }

    @computed
    get hasParking() {
        return Boolean(this.$hasParking);
    }

    @computed
    get hasSecurity() {
        return Boolean(this.$hasSecurity);
    }

    @computed
    get fullAddress() {
        const { $city, $street, $house } = this;
        if (!$city || !$street || !$house) {
            return null;
        }
        return `${$city.name}, ${$street.name}, ${$house.number}`;
    }

    createForm(mixins = []) {
        return super.createForm([
            withObjectDetailedField({
                totalArea: 'totalArea',
                price: 'price',
                unitPrice: 'unitPrice',
            }, true),
            withAddressField({
                area: 'area',
                city: 'city',
                district: 'cityDistrict',
                districtLandmark: 'cityDistrictLandmark',
                street: 'street',
                house: 'house',
            }),
            ...mixins,
        ]);
    }

    // eslint-disable-next-line class-methods-use-this
    prepareRequest(values) {
        const prepared = super.prepareRequest(values);
        return {
            ...prepared,
            objectCommercialTypeId: values.commercialType,
            cityId: values.city,
            cityDistrictId: values.cityDistrict,
            cityDistrictLandmarkId: values.cityDistrictLandmark,
            floor: values.floor,
            floorCount: values.floorCount,
            totalArea: values.totalArea,
            streetId: values.street,
            houseId: Array.isArray(values.house) ? values.house[0] : values.house,
            heatingId: values.heating,
            hasBasement: values.hasBasement,
            hasInspectionPit: values.hasInspectionPit,
            hasParking: values.hasParking,
            hasSecurity: values.hasSecurity,
            wallMaterialId: values.wallMaterial,
            roomCount: values.roomCount,
            yearOfConstruction: values.yearOfConstruction,
        };
    }

    toItem() {
        const item = super.toItem();
        return {
            ...item,
            commercialType: this.$commercialType ? this.$commercialType : null,
            totalArea: this.$totalArea,
            unitPrice: this.$unitPrice,
            roomCount: this.$roomCount,
            yearOfConstruction: this.$yearOfConstruction,
        };
    }
}

export default CommercialDetailed;
