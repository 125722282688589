import tables from '../../tables';

const withBaseStrategy = (Store) => (
    class extends Store {
        constructor(...rest) {
            super(...rest);
            this.tables = tables;
        }

        setFilterData({ statusIds, ...data }) {
            super.setFilterData({
                ...data,
                statusIds: null,
                [statusIds]: 1,
            });
        }

        // конвертирует сохраненный фильтр в подходящий
        convertPresetFilterData(filterData) {
            const { sort, filter } = super.convertPresetFilterData(filterData);
            const { filterForm } = this;
            const statusFormField = filterForm.$('statusIds');
            const extra = statusFormField.get('extra');
            const {
                mainGroup,
                archiveGroup,
                ...modifiedFilter
            } = filter;
            let value;
            extra.options.forEach((option) => {
                if (filter[option.value]) {
                    value = option.value;
                }
            });
            return {
                sort,
                filter: { ...modifiedFilter, statusIds: value },
            };
        }
    }
);

export default withBaseStrategy;
