import TABLE_CELL from '../shared/table-cell';

const OBJECT_STATUS_UPDATE = {
    name: 'objectStatusUpdate',
    title: 'Статус объекта',
    sortName: 'status',
};

const OBJECT_STATUS_UPDATE_WITH_CONTACT = {
    name: 'objectStatusUpdateWithContact',
    title: 'Статус объекта',
    sortName: 'status',
    tooltip: 'Статус объекта',
};

const OPERATOR_NOTE = {
    name: 'operatorNote',
    title: 'Примечание',
    sortName: 'operatorName',
};

const OPERATOR_NAME = {
    name: 'operatorName',
    title: 'Оператор',
    sortName: 'operator',
};

const OPERATOR_PLAN = {
    name: 'plan',
    title: 'План, о/д',
    sortName: 'plan',
};

const OPERATOR_DONE = {
    name: 'done',
    title: 'Выполнено',
    sortName: 'done',
};

const EMAIL = {
    name: 'email',
    title: 'Почта',
    sortName: 'email',
};

const ACCESS = {
    name: 'access',
    title: 'Доступ',
    sortName: 'accessLevel',
};

const ACTIVATION_KEY_VALUE = {
    name: 'keyValue',
    title: 'Ключ',
};

const ACTIVATION_KEY_MAX_USER_COUNT = {
    name: 'totalUserUseCount',
    title: 'Установок у пользователей/MAX',
    sortName: 'userUseCount',
};

const ACTIVATION_KEY_MAX_COMPANY_COUNT = {
    name: 'totalCompanyUseCount',
    title: 'Установок у компаний/MAX',
    sortName: 'companyUseCount',
};

const ACTIVATION_KEY_STATUS_NAME = {
    name: 'statusName',
    title: 'Статус',
    sortName: 'status',
};

const ACTIVATION_KEY_STATUS_VALUE = {
    name: 'statusValue',
    title: 'Статус',
    sortName: 'status',
};

const SIMILARITY = {
    name: 'similarityValue',
    title: 'Похожесть',
    sortName: 'similarity',
};

const BILLING_COMPANY_NAME = {
    name: 'companyName',
    title: 'Компания',
    sortName: 'name',
};

const BILLING_COMPANY_ADDRESS = {
    name: 'companyAddress',
    title: 'Адрес',
    sortName: 'address',
};

const BILLING_STATUS = {
    name: 'status',
    title: 'Статус',
    sortName: 'status',
};

const BILLING_INSTANCES = {
    name: 'instances',
    title: 'Копий',
    sortName: 'instances',
};

const BILLING_PRICE = {
    name: 'price',
    title: 'Стоимость',
    sortName: 'price',
};

const BILLING_EXPIRES_AT = {
    name: 'activeUntil',
    title: 'Истекает',
    sortName: 'activeUntil',
};

const BILLING_USERS = {
    name: 'users',
    title: 'К сотрудникам',
};
const BILLING_PAID_UNTIL = {
    name: 'paidUntil',
    title: 'Продлено до',
};
const HARDWARE_NAME = {
    name: 'hardwareName',
    title: 'Идентификатор',
};

const USER_REGISTERED = {
    name: 'registeredAt',
    title: 'Зарегистрирован',
};

const USER_LAST_LOGIN = {
    name: 'lastLoginAt',
    title: 'Дата последнего входа',
    sortName: 'lastLoginAt',
};

const INCORRECT_OBJECT = {
    name: 'incorrect',
    title: '',
};

export default {
    ...TABLE_CELL,
    OBJECT_STATUS_UPDATE_WITH_CONTACT,
    OBJECT_STATUS_UPDATE,
    OPERATOR_NOTE,
    OPERATOR_NAME,
    OPERATOR_PLAN,
    OPERATOR_DONE,
    EMAIL,
    ACCESS,
    ACTIVATION_KEY_VALUE,
    ACTIVATION_KEY_MAX_USER_COUNT,
    ACTIVATION_KEY_MAX_COMPANY_COUNT,
    ACTIVATION_KEY_STATUS_NAME,
    ACTIVATION_KEY_STATUS_VALUE,
    SIMILARITY,
    BILLING_COMPANY_NAME,
    BILLING_COMPANY_ADDRESS,
    BILLING_STATUS,
    BILLING_INSTANCES,
    BILLING_PRICE,
    BILLING_EXPIRES_AT,
    BILLING_USERS,
    HARDWARE_NAME,
    USER_REGISTERED,
    USER_LAST_LOGIN,
    BILLING_PAID_UNTIL,
    INCORRECT_OBJECT,
};
