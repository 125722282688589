import { withSaleItem } from '../../item/mixins';
import reduceFields, { reduceFieldGroups } from '../../../../../forms/utils/reduce-fields';
import { PRICE_DELIMITER } from '../../../../../constants/shared';

const withSaleStrategy = (Store) => (
    class extends Store {
        constructor(...rest) {
            super(...rest);
            const { Item } = this;
            this.dealType = 'sale';
            this.Item = withSaleItem(Item);
        }

        get formFields() {
            const formFields = super.formFields;
            const pricesModified = reduceFieldGroups(
                formFields,
                ({ name, label, ...fieldGroup }) => ({
                    ...fieldGroup,
                    name,
                    label: name === 'price' || name === 'unitPrice'
                        ? `${label}, т.р.`
                        : label,
                }),
            );
            return reduceFields(pricesModified, (field) => {
                const { name } = field;
                switch (name) {
                    case 'yearOfConstructionFrom':
                        return { ...field, name: 'yearOfCompletionFrom' };
                    case 'yearOfConstructionTo':
                        return { ...field, name: 'yearOfCompletionTo' };
                    default:
                        return field;
                }
            });
        }

        prepareFilterData(options) {
            const { filterForm } = this;
            const modifyPrice = (price) => (
                price
                    ? price * PRICE_DELIMITER
                    : undefined
            );
            return super.prepareFilterData(options)
                .then(({
                    priceFrom,
                    priceTo,
                    unitPriceFrom,
                    unitPriceTo,
                    ...filter
                }) => (
                    Promise.resolve({
                        ...filter,
                        ...(filterForm
                            ? filterForm.clearValues({
                                priceFrom: modifyPrice(priceFrom),
                                priceTo: modifyPrice(priceTo),
                                unitPriceFrom: modifyPrice(unitPriceFrom),
                                unitPriceTo: modifyPrice(unitPriceTo),
                            })
                            : {}
                        ),
                    })
                ));
        }

        // конвертирует сохраненный фильтр в подходящий
        convertPresetFilterData(filterData) {
            const { sort, filter } = super.convertPresetFilterData(filterData);
            const { filterForm } = this;
            const {
                priceFrom,
                priceTo,
                unitPriceFrom,
                unitPriceTo,
                ...modifiedFilter
            } = filter;
            const modifyPrice = (price) => (
                price
                    ? price / PRICE_DELIMITER
                    : undefined
            );
            return {
                sort,
                filter: {
                    ...modifiedFilter,
                    ...(filterForm
                        ? filterForm.clearValues({
                            priceFrom: modifyPrice(priceFrom),
                            priceTo: modifyPrice(priceTo),
                            unitPriceFrom: modifyPrice(unitPriceFrom),
                            unitPriceTo: modifyPrice(unitPriceTo),
                        })
                        : {}
                    ),
                },
            };
        }
    }
);

export default withSaleStrategy;
