import isEmpty from './is-empty';

const clearRecursively = (data, reducer) => Object.keys(data).reduce(reducer, {});
const createReducer = (data) => (carry, key) => {
    const value = data[key];
    if (value instanceof Object && !isEmpty(value)) {
        let nested;
        if (value instanceof Array) {
            nested = Object.values(clearRecursively(value, createReducer(value)));
        } else {
            nested = clearRecursively(value, createReducer(value));
        }
        if (!isEmpty(nested)) {
            // eslint-disable-next-line no-param-reassign
            carry[key] = nested;
        }
        return carry;
    }
    if (key === 'suburbOfCityId' && isEmpty(value)) {
        // eslint-disable-next-line no-param-reassign
        carry[key] = null;
    }
    if (isEmpty(value)) {
        return carry;
    }
    // eslint-disable-next-line no-param-reassign
    carry[key] = value;
    return carry;
};

const clearObjectExceptSuburb = (obj) => clearRecursively(obj, createReducer(obj));

export default clearObjectExceptSuburb;
