import React, { useEffect, useState } from 'react';

const withStore = (WrappedComponent, createStores) => (props) => {
    const [store, setStore] = useState();
    useEffect(() => {
        setStore(createStores());
    }, []);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} store={store} />;
};

export default withStore;
