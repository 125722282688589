import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useStores from '../../../hooks/client/use-stores';
import LoginPage from '../../shared/LoginPage';
import { Button } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { EyeClosedIcon, EyeOpenedIcon, NotifyOrangeIcon } from '../../../components/Icons/icons';

import styles from './Register.module.scss';

const RegisterUserFinish = () => {
    const { userStore = {}, sessionStore } = useStores();
    const { login, password } = userStore;
    const [viewPassword, setViewPassword] = useState(false);
    const [counter, setCounter] = useState(15);
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState({
        second: '15',
        minute: '00',
    });
    const handleFinishClick = () => {
        setLoading(true);
        sessionStore.login({ login, password })
            .catch(() => {
                setLoading(false);
            });
    };
    if (!login || !password) {
        return <Redirect to="key" />;
    }
    useEffect(() => {
        let intervalId;
        if (counter >= 0) {
            intervalId = setInterval(() => {
                const secondCounter = counter % 60;
                const minuteCounter = Math.floor(counter / 60);

                const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
                const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

                setTime({ second: computedSecond, minute: computedMinute });
                setCounter((currentCounter) => currentCounter - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [counter]);

    const toggleViewPassword = () => {
        setViewPassword(!viewPassword);
    };
    return (
        <LoginPage
            title="Регистрация"
            footer={null}
        >
            <>
                <div className={styles.register__finish}>
                    <div className={`${styles.register__credentials} ${styles.credentials}`}>
                        <div className={styles.credentials__label}>
                            Логин
                        </div>
                        <div className={styles.credentials__value}>
                            {login}
                        </div>
                    </div>
                    <div className={`${styles.register__credentials} ${styles.credentials}`}>
                        <div className={styles.credentials__label}>
                            Пароль
                            <Button
                                className={styles.credentials__view}
                                onClick={toggleViewPassword}
                                type="button"
                                color={Button.COLOR.WHITE_BLUE}
                                size={Button.SIZE.LARGE}
                            >
                                <SVGIcon
                                    SVGElement={viewPassword ? EyeClosedIcon : EyeOpenedIcon}
                                    height={14}
                                />
                            </Button>
                        </div>
                        <div className={styles.credentials__value}>
                            {viewPassword ? password : '*****'}
                        </div>
                    </div>
                </div>
                <div className={styles.register__warning}>
                    <SVGIcon
                        SVGElement={NotifyOrangeIcon}
                        height={16}
                    />
                    Обязательно запишите эти данные!
                </div>
                <Button
                    type="submit"
                    color={Button.COLOR.GREEN}
                    size={Button.SIZE.LARGE}
                    disabled={counter >= 0 || loading}
                    onClick={handleFinishClick}
                    block
                >
                    Завершить регистрацию
                </Button>
                <div className={`${styles.register__counter} ${counter >= 0 ? styles['register__counter--active'] : ''}`}>
                    {`${time.minute}:${time.second}`}
                </div>
            </>
        </LoginPage>
    );
};

export default RegisterUserFinish;
