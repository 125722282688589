import React from 'react';
import PropTypes from 'prop-types';

import styles from './Preloader.module.scss';

const Preloader = ({ width, color, className }) => (
    <div
        className={`${styles.preloader} ${className}`}
        style={{
            width: `${width}px`,
            height: `${width}px`,
        }}
    >
        <div className={styles.showbox}>
            <div className={styles.loader}>
                <svg className={styles.circular} viewBox="25 25 50 50">
                    <circle
                        className={styles.path}
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke={color}
                        strokeWidth={(4 / 100) * (100 - Math.min(100, width)) + 2}
                        strokeMiterlimit="10"
                    />
                </svg>
            </div>
        </div>
    </div>
);

Preloader.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    color: PropTypes.string,
};

Preloader.defaultProps = {
    className: '',
    width: 100,
    color: '',
};

export default Preloader;
