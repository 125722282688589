import React from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';

import styles from './RadioList.module.scss';

const RadioList = ({
    id,
    className,
    name,
    options,
    value,
    rows,
    disabled,
    size,
    onChange,
    readOnly,
    error,
    inline,
}) => {
    const style = {};
    if (rows) {
        const { ceil } = Math;
        const count = options.length;
        const itemsPerRow = ceil(count / rows);
        style.width = `${100 / itemsPerRow}%`;
    }
    return (
        <div
            id={id}
            className={`
                ${styles['radio-list']}
                ${styles[`radio-list--${size}`]}
                ${error ? styles['radio-list--error'] : ''}
                ${inline ? styles['radio-list--inline'] : ''}
                ${className}
            `}
        >
            {
                options.map((item) => {
                    const {
                        value: itemValue,
                        label,
                    } = item;
                    const backgroundColor = item.extra?.bgColor;
                    return (
                        <div
                            key={`radio-${itemValue}`}
                            className={styles['radio-list__item']}
                            style={{ ...style, backgroundColor }}
                        >
                            <Radio
                                id={`radio-${itemValue}`}
                                name={name}
                                value={itemValue}
                                size={size}
                                checked={(
                                    value !== undefined
                                        ? String(value) === String(itemValue)
                                        : undefined
                                )}
                                disabled={disabled}
                                onChange={onChange}
                                readOnly={readOnly}
                            >
                                {label}
                            </Radio>
                        </div>
                    );
                })
            }
            {
                error
                    ? (
                        <div className={styles['radio-list__error']}>
                            {error}
                        </div>
                    )
                    : null
            }
        </div>
    );
};

RadioList.SIZE = { ...Radio.SIZE };

RadioList.TYPE = { ...Radio.TYPE };

RadioList.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    size: PropTypes.oneOf(Object.values(RadioList.SIZE)),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        label: PropTypes.string,
    })).isRequired,
    rows: PropTypes.number,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    error: PropTypes.string,
};

RadioList.defaultProps = {
    id: '',
    className: '',
    size: RadioList.SIZE.MEDUIM,
    value: undefined,
    rows: undefined,
    disabled: false,
    onChange: null,
    readOnly: false,
    inline: false,
    error: '',
};

export default RadioList;
