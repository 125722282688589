import { reduceFieldGroups } from '../../../../../forms/utils/reduce-fields';
import { withRentItem } from '../../item/mixins';
import { TABLE_CELL } from '../../../../../constants/shared';

const withRentStrategy = (Store) => (
    class extends Store {
        constructor(...rest) {
            super(...rest);
            const { Item } = this;
            this.dealType = 'rent';
            this.Item = withRentItem(Item);
        }

        get $tableHeaders() {
            const tableHeaders = super.$tableHeaders;
            return tableHeaders.map(({ name, ...header }) => {
                switch (name) {
                    case TABLE_CELL.PRICE_DELIMITED.name:
                        return {
                            ...header,
                            name: TABLE_CELL.PRICE.name,
                        };
                    case TABLE_CELL.UNIT_PRICE_DELIMITED.name:
                        return {
                            ...header,
                            name: TABLE_CELL.UNIT_PRICE.name,
                        };
                    case TABLE_CELL.DATE_OF_COMPLETION.name:
                        return {
                            ...header,
                            name,
                            fields: [{
                                name: 'yearOfConstructionFrom',
                            }, {
                                name: 'yearOfConstructionTo',
                            }],
                        };
                    default:
                        return {
                            ...header,
                            name,
                        };
                }
            });
        }

        get formFields() {
            const formFields = super.formFields;
            const pricesModified = reduceFieldGroups(
                formFields,
                ({ name, label, ...fieldGroup }) => ({
                    ...fieldGroup,
                    name,
                    label: name === 'price' || name === 'unitPrice'
                        ? `${label}, р.`
                        : label,
                }),
            );
            return reduceFieldGroups(pricesModified, (fieldGroup) => {
                const { name } = fieldGroup;
                switch (name) {
                    case 'year':
                        return { ...fieldGroup, label: 'Год постройки' };
                    default:
                        return fieldGroup;
                }
            });
        }
    }
);

export default withRentStrategy;
