import React from 'react';
import PropTypes from 'prop-types';

import styles from './Selected.module.scss';

const SelectedHighlight = ({
    children,
    color,
}) => (
    <span className={`${styles.selected__text} ${color ? styles[`selected__text--${color}`] : ''}`}>
        {children}
    </span>
);

SelectedHighlight.COLOR = {
    BLUE: 'blue',
    ORANGE: 'orange',
    GREEN: 'green',
    RED: 'red',
};

SelectedHighlight.propTypes = {
    color: PropTypes.oneOf(Object.values(SelectedHighlight.COLOR)),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ]),
        ),
    ]),
};

SelectedHighlight.defaultProps = {
    color: null,
    children: null,
};

export default SelectedHighlight;
