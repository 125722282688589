export default [{
    type: 'fieldset',
    name: 'baseStatusGroups',
    fields: [{
        type: 'directorySelect',
        directory: 'ownertype',
        name: 'ownerTypeIds',
        size: 'sm',
        label: 'Собственник',
        isDropdown: true,
        multiple: true,
        preload: true,
    }, {
        type: 'dropdownSelect',
        name: 'objectType',
        size: 'sm',
        label: 'Тип',
        multiple: true,
        value: [{
            id: 1,
            value: 'FLAT',
            label: 'Квартиры',
        }, {
            id: 2,
            value: 'HOUSE',
            label: 'Дома',
        }, {
            id: 3,
            value: 'STEAD',
            label: 'Земля',
        }, {
            id: 4,
            value: 'COMMERCIAL',
            label: 'Нежилое',
        }],
        options: [{
            id: 1,
            value: 'FLAT',
            label: 'Квартиры',
        }, {
            id: 2,
            value: 'HOUSE',
            label: 'Дома',
        }, {
            id: 3,
            value: 'STEAD',
            label: 'Земля',
        }, {
            id: 4,
            value: 'COMMERCIAL',
            label: 'Коммерческая недвижмость',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'search',
    fields: [{
        name: 'query',
        type: 'text',
        label: 'Поиск',
        placeholder: 'Имя, почта, телефон',
        size: 'sm',
        icon: 'search',
        rules: 'min:3',
    }, {
        type: 'dropdownSelect',
        name: 'status',
        size: 'sm',
        label: 'Статус',
        multiple: true,
        options: [{
            id: 1,
            value: 'active',
            label: 'Активен',
        }, {
            id: 3,
            value: 'blocked',
            label: 'Забанен',
        }, {
            id: 3,
            value: 'archived',
            label: 'В архиве',
        }],
    }, {
        name: 'createdAtFrom',
        type: 'datepicker',
        label: 'Дата от',
        value: new Date(),
        startOfDay: true,
        placement: 'bottom-end',
        clearable: true,
    }, {
        name: 'createdAtTo',
        type: 'datepicker',
        label: 'Дата до',
        value: new Date(),
        placement: 'bottom-end',
        endOfDay: true,
        clearable: true,
    }],
}];
