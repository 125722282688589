import React from 'react';
import PropTypes from 'prop-types';
import { SIZE } from '../../constants/operator';

import styles from './Button.module.scss';

const Button = ({
    children,
    className,
    type,
    color,
    size,
    disabled,
    block,
    onClick,
}) => (
    <button
        className={`
            ${styles.button}
            ${styles[`button--${color}`]}
            ${styles[`button--${size}`]}
            ${block ? styles['button--block'] : ''}
            ${className}
        `}
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
        onClick={onClick}
    >
        <span className={styles.button__content}>
            {children}
        </span>
    </button>
);

Button.SIZE = { ...SIZE };

Button.TYPE = {
    SUBMIT: 'submit',
    BUTTON: 'button',
    RESET: 'reset',
};

Button.COLOR = {
    TRANSPARENT: 'transparent',
    BLUE: 'blue',
    DARK_BLUE: 'dark-blue',
    ORANGE: 'orange',
    GRAY: 'gray',
    WHITE: 'white',
    GREEN: 'green',
    WHITE_RED: 'white-red',
    WHITE_BLACK: 'white-black',
    WHITE_GREEN: 'white-green',
    WHITE_BLUE: 'white-blue',
    WHITE_ORANGE: 'white-orange',
    WHITE_GRAY: 'white-gray',
};

Button.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(Button.SIZE)),
    type: PropTypes.oneOf(Object.values(Button.TYPE)),
    color: PropTypes.oneOf(Object.values(Button.COLOR)),
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
};

Button.defaultProps = {
    className: '',
    size: Button.SIZE.MEDUIM,
    type: Button.TYPE.BUTTON,
    color: Button.COLOR.WHITE,
    disabled: false,
    block: false,
    onClick: null,
    children: null,
};

export default Button;
