/* global alert, window */
import createForm from '../../../../helpers/Form';
import withPayment from '../../../../helpers/Form/mixins/withPayment';
import { paymentFields } from '../../../../forms/client';

class Payment {
    paymentDebt = 0;

    paymentForm;

    isLoading = false;

    isTimeToPay = false;

    paymentAmoutPerMonth = 0;

    paymentExpirationDate = 0;

    afterSubmit = () => {};

    constructor({
        paymentDebt,
        paymentService,
        paymentAmoutPerMonth = 0,
        paymentExpirationDate = 0,
    }) {
        this.paymentService = paymentService;
        this.paymentDebt = paymentDebt;
        this.paymentAmoutPerMonth = paymentAmoutPerMonth;
        this.paymentForm = createForm(
            paymentFields,
            [withPayment({ paymentService })],
        );
        this.paymentExpirationDate = paymentExpirationDate;

        const expDate = new Date(paymentExpirationDate);
        const curDate = new Date();
        this.isTimeToPay = Math.floor(Math.abs(expDate - curDate) / (1000 * 60 * 60 * 24)) <= 3;

        this.paymentForm.on('success', this.makePayment.bind(this));
        this.paymentForm.$('monthCount').set(1);
        this.paymentForm.$('cost').set(paymentAmoutPerMonth);

        if (paymentDebt) {
            this.paymentForm.$('monthCount').set('rules', 'required|numeric|min:0');
        }
    }

    makePayment({ monthCount }) {
        const { afterSubmit, paymentService } = this;
        return paymentService.initPayment({ months: monthCount })
            .then((redirectUrl) => {
                afterSubmit();
                window.open(redirectUrl, '_blank');
            })
            .catch((e) => {
                alert(e.response?.data?.error?.message ?? e);
                console.error(e);
            });
    }
}

export default Payment;
