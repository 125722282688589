import { NotepadService } from '../../../services/client';
import NotepadList from '../../shared/NotepadList/NotepadList';

class ClientNotepadList extends NotepadList {
    constructor() {
        super(NotepadService);
    }
}

export default ClientNotepadList;
