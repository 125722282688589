import house from './houses';
import stead from './steads';
import general from './general';
import rentFlat from './rent-flats';
import saleFlat from './sale-flats';
import commercial from './commercial';

export default {
    house,
    stead,
    general,
    rentFlat,
    saleFlat,
    commercial,
};
