import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import useStores from '../../../hooks/client/use-stores';
import LoginPage, { LoginPageField } from '../../shared/LoginPage';
import { Button } from '../../../components/Button';
import FormElements from '../Form';

import styles from '../../shared/LoginPage/LoginPage.module.scss';
import registrationStyles from './Register.module.scss';

const ResetPasswordSet = () => {
    const history = useHistory();
    const [code] = useQueryParam('code', StringParam);
    const { resetPasswordStore = {} } = useStores();
    const { setForm = { $: () => null } } = resetPasswordStore;
    useEffect(() => {
        if (setForm && setForm.on) {
            setForm.$('code').set('value', code || '');
            return setForm.on('done', ([response]) => {
                if (response) {
                    history.push('/login');
                }
            });
        }
        return undefined;
    }, [setForm]);
    return (
        <LoginPage
            title={(
                <div className={registrationStyles.reset__header}>
                    Восстановление пароля
                </div>
            )}
            className={registrationStyles.reset}
        >
            <Observer>
                {() => (
                    <form
                        onSubmit={setForm.onSubmit}
                    >
                        <FormElements
                            form={setForm}
                            elements={setForm.render || []}
                            components={{
                                FieldWrapper: LoginPageField,
                            }}
                        />
                        <div className={styles.login__submit}>
                            <Button
                                type="submit"
                                color={Button.COLOR.GREEN}
                                size={Button.SIZE.LARGE}
                                block
                                disabled={setForm.loading}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </form>
                )}
            </Observer>
        </LoginPage>
    );
};

export default ResetPasswordSet;
