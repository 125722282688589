import React, {
    useState,
    useCallback,
    forwardRef,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Table } from '../Table';

import styles from './TableWithSelected.module.scss';

const DefaultContent = ({ children }) => (
    <div className={styles.content}>
        {children}
    </div>
);

DefaultContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

const DefaultTableWrapper = ({ children }) => (
    <>
        {children}
    </>
);

DefaultTableWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

const DefaultSelectedWrapper = ({ children }) => (
    <>
        {children}
    </>
);

DefaultSelectedWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

/**
 * Компонент таблицы с возможностью выбора одной строки
 */
const TableWithSelected = forwardRef(({
    useStores,
    headers,
    store,
    onRowClick,
    onRightClick,
    onDoubleRowClick,
    onTableHeaderClick,
    onCellClick,
    fullHeight,
    selectedContentHeight,
    components,
    withHistory,
    textAlign,
    onStatusTooltipToggle,
}, ref) => {
    const wrappers = { ...TableWithSelected.defaultProps.components, ...components };
    const [selectedData, setSelectedData] = useState(null);
    const [selectedHistory, setSelectedHistory] = useState([]);
    const addToHistory = useCallback((item) => {
        if (!item || !item.id) return;
        const duplicateItem = selectedHistory.find(
            (historyItem) => historyItem.id === item.id,
        );
        if (duplicateItem) return;
        setSelectedHistory([...selectedHistory, item]);
    }, []);
    const handleRowClick = useCallback((row) => {
        if (onRowClick && typeof onRowClick === 'function') {
            onRowClick(row);
        }
    }, [store]);
    const handleRightClick = useCallback((row) => {
        if (typeof onRightClick === 'function') {
            onRightClick(row);
        }
    }, [store]);
    const handleDoubleRowClick = useCallback((row) => {
        if (onDoubleRowClick && typeof onDoubleRowClick === 'function') {
            onDoubleRowClick(row);
        }
    }, [store]);
    const wrapperStyles = useCallback(({
        height: selectedData !== null
            ? `calc(${fullHeight} - ${selectedContentHeight})`
            : fullHeight,
        minHeight: '100px',
    }), [selectedData !== null, selectedContentHeight]);
    // effects
    useEffect(() => {
        const setData = (instance) => {
            const handleData = (data) => {
                setSelectedData(data);
                if (withHistory) {
                    addToHistory(data);
                }
            };
            if (instance instanceof Promise) {
                instance.then(handleData);
            } else {
                handleData(instance);
            }
        };

        if (!onRowClick.callback) {
            // eslint-disable-next-line no-param-reassign
            onRowClick.callback = setData;
        }

        if (!onCellClick.callback) {
            // eslint-disable-next-line no-param-reassign
            onCellClick.callback = setData;
        }
    }, [onRowClick, onCellClick]);
    return (
        <wrappers.ContentWrapper
            style={{
                height: fullHeight,
            }}
        >
            <wrappers.TableWrapper>
                <Table
                    ref={ref}
                    useStores={useStores}
                    headers={headers}
                    store={store}
                    onRowClick={handleRowClick}
                    onRightClick={handleRightClick}
                    onDoubleRowClick={handleDoubleRowClick}
                    onCellClick={onCellClick}
                    onTableHeaderClick={onTableHeaderClick}
                    wrapperStyles={wrapperStyles}
                    textAlign={textAlign}
                    onStatusTooltipToggle={onStatusTooltipToggle}
                />
            </wrappers.TableWrapper>
            {
                selectedData !== null
                    ? (
                        <wrappers.SelectedWrapper
                            style={{
                                height: selectedContentHeight,
                            }}
                            store={selectedData}
                            history={selectedHistory}
                            setSelectedData={setSelectedData}
                            withHistory={withHistory}
                        />
                    )
                    : null
            }
        </wrappers.ContentWrapper>
    );
});

TableWithSelected.propTypes = {
    useStores: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            width: PropTypes.number,
        }),
    ),
    store: PropTypes.shape({
        loading: PropTypes.bool,
        tableData: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        load: PropTypes.func,
        getItem: PropTypes.func,
    }).isRequired,
    onRowClick: PropTypes.func,
    onCellClick: PropTypes.func,
    onRightClick: PropTypes.func,
    onDoubleRowClick: PropTypes.func,
    onTableHeaderClick: PropTypes.func,
    fullHeight: PropTypes.string,
    selectedContentHeight: PropTypes.string,
    components: PropTypes.shape({
        ContentWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        TableWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        SelectedWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
    }),
    withHistory: PropTypes.bool,
    textAlign: PropTypes.oneOf([
        'left',
        'center',
        'right',
    ]),
    onStatusTooltipToggle: PropTypes.func,
};

TableWithSelected.defaultProps = {
    headers: [],
    onRowClick: () => {},
    onCellClick: () => {},
    onRightClick: () => {},
    onDoubleRowClick: () => {},
    onStatusTooltipToggle: () => {},
    onTableHeaderClick: () => null,
    fullHeight: 'calc(100vh - 73px)',
    selectedContentHeight: '170px',
    components: {
        ContentWrapper: DefaultContent,
        TableWrapper: DefaultTableWrapper,
        SelectedWrapper: DefaultSelectedWrapper,
    },
    withHistory: false,
    textAlign: 'center',
};

export default TableWithSelected;
