import forms from '../../shared/objects-detailed';
import reduceFields, { reduceFieldGroups } from '../../utils/reduce-fields';

const flatRequiredFields = ['cityDistrict', 'street', 'house', 'roomCount', 'roomType', 'floorCount', 'floor', 'totalArea', 'price'];
const houseRequiredFields = ['cityDistrict', 'street', 'house', 'roomCount', 'houseType', 'wallMaterial', 'totalArea', 'price'];
const steadRequiredFields = ['cityDistrict', 'steadType', 'passage', 'totalArea', 'price'];
const commercialRequiredFields = ['cityDistrict', 'street', 'commercialType', 'wallMaterial', 'floorCount', 'totalArea', 'price'];

const createRequiredReducer = (requiredFields) => (field) => (
    requiredFields.includes(field.name)
        ? {
            ...field,
            rules: field.rules ? `${field.rules}|required` : 'required',
        } : field
);

const userNoteReducer = (fieldgroup) => {
    if (fieldgroup.name === 'operatorNoteWrapper') {
        // fieldgroup.fields.push({
        //     name: 'userNote',
        //     type: 'textarea',
        //     rows: 3,
        //     label: 'Личные заметки',
        //     tooltip: 'Эти заметки видны только вам (пользователю)',
        //     readonly: false,
        // });
    }
    return fieldgroup;
};

const readOnlyNoteReducer = (field) => {
    if (field.name === 'publicNote' || field.name === 'privateNote') {
        // eslint-disable-next-line no-param-reassign
        field.readOnly = true;
    }
    return field;
};

const simpleContactReducer = (field) => {
    if (field.name === 'phones') {
        // eslint-disable-next-line no-param-reassign
        field.simple = true;
    }
    return field;
};

const clientizeForm = (fields, required) => (
    reduceFieldGroups(
        reduceFields(
            reduceFields(
                reduceFields(fields, simpleContactReducer),
                readOnlyNoteReducer,
            ),
            createRequiredReducer(required),
        ),
        userNoteReducer,
    )
);

const clientSaleFlats = clientizeForm(forms.saleFlats, flatRequiredFields);
const clientRentFlats = clientizeForm(forms.rentFlats, flatRequiredFields);
const clientSaleHouses = clientizeForm(forms.saleHouses, houseRequiredFields);
const clientRentHouses = clientizeForm(forms.rentHouses, houseRequiredFields);
const clientSaleSteads = clientizeForm(forms.saleSteads, steadRequiredFields);
const clientRentSteads = clientizeForm(forms.rentSteads, steadRequiredFields);
const clientSaleCommercial = clientizeForm(forms.saleCommercial, commercialRequiredFields);
const clientRentCommercial = clientizeForm(forms.rentCommercial, commercialRequiredFields);

export default {
    saleFlats: clientSaleFlats,
    rentFlats: clientRentFlats,
    saleHouses: clientSaleHouses,
    rentHouses: clientRentHouses,
    saleSteads: clientSaleSteads,
    rentSteads: clientRentSteads,
    saleCommercial: clientSaleCommercial,
    rentCommercial: clientRentCommercial,
};
