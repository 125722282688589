import MortgageCalculator from '../../shared/MortgageCalculator';
import PriceStats from '../PriceStats';

class App {
    constructor() {
        this.priceStats = new PriceStats();
        this.mortgageCalculator = new MortgageCalculator();
    }
}

const createAppStore = () => new App();

export default App;
export {
    createAppStore,
};
