import { runInAction } from 'mobx';
import Directory from '../../../shared/Directories/entities/Directory';

class OperatorCompanies extends Directory {
    loadData() {
        const { directory } = this;
        return this.dataSource.getOperatorCompanies(directory)
            .then((list = []) => {
                runInAction(() => {
                    this.loaded = true;
                });
                this.items.setList(list);
                return Promise.resolve(this.options);
            });
    }
}

export default OperatorCompanies;
