import { action, observable } from 'mobx';
import ListItem from '../entities/ListItem';

const listItemWithSelect = (Store) => {
    if (ListItem !== Store && !Object.prototype.isPrototypeOf.call(ListItem, Store)) {
        throw new Error('A ListItem class must be provided');
    }
    return class extends Store {
        @observable selected = false;

        @action
        select() {
            if (!this.selected) {
                this.selected = true;
            }
        }

        @action
        unselect() {
            if (this.selected) {
                this.selected = false;
            }
        }

        @action
        toggle() {
            this.selected = !this.selected;
        }
    };
};

export default listItemWithSelect;
