import isEqual from 'lodash.isequal';
import {
    toJS,
    computed,
    action,
    observable,
} from 'mobx';
import clearObject from '../../../utils/clear-object';

const withAddressField = (addressFields) => (Store) => (
    class extends Store {
        @observable addressSnapshot;

        addressFields;

        /**
         * Observers should be disabled when form values are loaded from external source
         *
         * @type {boolean}
         */
        disableObservers = false;

        constructor(fields) {
            super(fields);
            const {
                city,
            } = addressFields;
            if (!city) {
                throw new Error('City field must be presented');
            }
            if (!this.$(city)) {
                throw new Error('City field must be defined in form');
            }
            this.addressFields = addressFields;
            this.observeAddressFields();
        }

        observeAddressFields() {
            const form = this;
            const {
                addressFields: {
                    area,
                    city,
                    street,
                    district,
                    districtLandmark,
                    house,
                    rooms,
                },
            } = this;
            const createObserver = (key, dependents = []) => ({ change }) => {
                if (this.disableObservers) {
                    return;
                }
                const { oldValue, newValue } = change;
                const newValueJS = toJS(newValue);
                const oldValueJS = toJS(oldValue);
                const isFieldMultiple = newValueJS instanceof Array;
                let ids = [];
                if (isFieldMultiple) {
                    ids = newValueJS.map(({ value }) => value);
                } else if (newValueJS) {
                    if (newValueJS instanceof Object) {
                        ids = [newValueJS.value];
                    } else {
                        ids = [newValueJS];
                    }
                }
                dependents.forEach((dependentField) => {
                    if (form.has(dependentField)) {
                        const field = form.$(dependentField);
                        const { optionValuesIsArray } = field.extra
                            || { optionValuesIsArray: false };
                        const { extra = {} } = field;
                        const { ctx = {} } = extra;
                        // Reset value of dependent field if something has changed
                        if (!isEqual(oldValueJS, newValueJS)) {
                            field.set('value', optionValuesIsArray ? [] : 0);
                        }
                        field.set('extra', {
                            ...extra,
                            ctx: {
                                ...ctx,
                                [key]: ids.flat(Infinity),
                            },
                        });
                    }
                });
            };
            const getField = (field) => (
                form.has(field) ? form.$(field) : null
            );
            const areaField = getField(area);
            const cityField = getField(city);
            const districtField = getField(district);
            const streetField = getField(street);
            const houseField = getField(house);
            const roomsField = getField(rooms);

            if (areaField) {
                areaField.observe(createObserver(
                    'areaIds',
                    [city],
                ));
            }
            cityField.observe(createObserver(
                'cityIds',
                [district, districtLandmark, street, house],
            ));
            if (districtField) {
                districtField.observe(createObserver(
                    'cityDistrictIds',
                    [districtLandmark],
                ));
            }
            if (streetField) {
                streetField.observe(createObserver(
                    'streetIds',
                    [house],
                ));
            }
            if (roomsField) {
                roomsField.observe(createObserver(
                    'rooms',
                    [roomsField],
                ));
            }
            if (streetField && houseField) {
                streetField.observe(({ change }) => {
                    const { newValue } = change;
                    const disabled = !newValue
                        || (Array.isArray(newValue) && newValue.length === 0);
                    houseField.set('disabled', disabled);
                });
            }
        }

        @action
        makeAddressSnapshot() {
            const { stringify: str } = JSON;
            this.addressSnapshot = str(this.addressValues);
        }

        @computed
        get addressValues() {
            const {
                addressFields: {
                    area,
                    city,
                    street,
                    house,
                },
            } = this;
            const result = {};
            [area, city, street, house].forEach((field) => {
                if (this.has(field)) {
                    const $field = this.$(field);
                    result[$field.name] = $field.value;
                }
            });
            return result;
        }

        @computed
        get addressSnapshotIsDiffer() {
            const { addressSnapshot, addressValues } = this;
            const { stringify: str } = JSON;
            return !addressSnapshot
                ? false
                : str(addressValues) !== addressSnapshot;
        }

        @computed
        get fullAddress() {
            const { addressValues: values } = this;
            const cleaned = clearObject(values);
            if (Object.keys(cleaned).length < 3) return null;
            return Object.values(cleaned).map(({ label }) => label).join(', ');
        }
    }
);

export default withAddressField;
