const createSessionService = (request) => ({
    login: (data) => (
        request.post('/auth/token', data)
            .then(({ accessToken: value, expiresIn }) => (
                Promise.resolve({
                    value,
                    expiresIn,
                })
            ))
    ),

    refresh: () => (
        request.put('/auth/token')
    ),
});

export default createSessionService;
