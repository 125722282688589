import Field from './DefaultField';

class Select extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw = {}) => {
        const { providedOutput } = this;
        let value;
        if (raw instanceof Array) {
            value = raw.map((val) => val.value);
        } else {
            value = raw.value;
        }
        if (typeof providedOutput === 'function') {
            return providedOutput(value || '');
        }
        return value || '';
    }
}

export default Select;
