/* eslint-disable no-redeclare */
/* global Event */
import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { Checkbox } from '../../../components/Input';

import styles from './FilterGroups.module.scss';

/**
 * @param {object} param0
 * @param {object} param0.itemsAutoWidth отображать ли ширину пункта по ширине её контента
 */
const FilterGroups = ({
    title,
    filterForm,
    fields,
    itemsAutoWidth,
}) => {
    const handleChange = (name) => (e) => {
        const { target } = e;
        const names = fields.map(({ name: fieldName }) => fieldName);
        const initial = fields.filter(({ value }) => Boolean(value));
        filterForm
            .$(name)
            .set('value', target.checked);
        const current = names.filter((fieldName) => filterForm.$(fieldName).checked);
        const initialInCurrent = current.filter(
            (fieldName) => initial.find(
                ({ name: initialName }) => fieldName === initialName,
            ),
        );
        const isDefault = (
            current.length === 0
            || (current.length === initial.length && initialInCurrent.length === initial.length)
        );
        if (isDefault) {
            initial.forEach(({ name: fieldName, disabled }) => {
                const field = filterForm.$(fieldName);
                field.set('value', true);
                field.set('disabled', disabled);
            });
        } else {
            initial.forEach(({ name: fieldName, disabled }) => {
                const field = filterForm.$(fieldName);
                field.set('disabled', disabled === true ? false : undefined);
            });
        }
        filterForm.onSubmit(new Event('submit'));
    };
    return (
        <div className={styles.groups}>
            <div className={styles.groups__title}>
                {title}
            </div>
            <div className={styles.groups__list}>
                {
                    fields.map(({ name, label }) => (
                        <Observer key={`extra-${name}`}>
                            {() => (
                                <Checkbox
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...filterForm.$(name).bind()}
                                    disabled={filterForm.$(name).disabled || filterForm.loading}
                                    id={`extra-${name}`}
                                    className={`
                                        ${styles.groups__item}
                                        ${itemsAutoWidth ? styles['groups__item--auto'] : ''}
                                    `}
                                    name={`extra-${name}`}
                                    value
                                    onChange={handleChange(name)}
                                >
                                    {label}
                                </Checkbox>
                            )}
                        </Observer>
                    ))
                }
            </div>
        </div>
    );
};

FilterGroups.propTypes = {
    title: PropTypes.string,
    filterForm: PropTypes.shape({
        $: PropTypes.func,
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,
    }),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    itemsAutoWidth: PropTypes.bool,
};

FilterGroups.defaultProps = {
    title: '',
    filterForm: {
        $: () => null,
        onSubmit: () => null,
    },
    fields: [],
    itemsAutoWidth: false,
};

export default FilterGroups;
