/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from '@brainhubeu/react-carousel';
import SVGIcon from '../Icons/SVGIcon';
import { SIZE } from '../../constants/operator';
import { Button } from '../Button';
import { DownloadIcon } from '../Icons/icons';

import styles from './ImageSlider.module.scss';

const LEFT = 'left';
const RIGHT = 'right';

const ImageSliderArrow = ({ direction, disabled }) => (
    <button
        type="button"
        className={`
            ${styles.arrow}
            ${styles[`arrow--${direction}`]}
            ${disabled ? styles['arrow--disabled'] : ''}
        `}
    >
        {'\u0000'}
    </button>
);

const ImageSlider = ({
    arrows,
    dots,
    infinite,
    maxHeight,
    slides,
    size,
    onImageClick,
    className,
    relatedId,
    initSlide,
    autoPlay,
    onImageDownload,
    stopAutoPlayOnHover,
}) => {
    const defaultInitSlide = 0;
    const [current, setCurrent] = useState(initSlide || defaultInitSlide);
    const defaultAnimationSpeed = 500;
    const [animationSpeed, setAnimationSpeed] = useState(defaultAnimationSpeed);
    const autoPlaySpeed = autoPlay && slides.length > 1
        ? autoPlay
        : undefined;
    if (!slides.length) return null;
    let arrowsProps = {};
    if (arrows) {
        arrowsProps = {
            addArrowClickHandler: true,
            arrowLeft: <ImageSliderArrow direction={LEFT} />,
            arrowLeftDisabled: <ImageSliderArrow direction={LEFT} disabled />,
            arrowRight: <ImageSliderArrow direction={RIGHT} />,
            arrowRightDisabled: <ImageSliderArrow direction={RIGHT} disabled />,
        };
    }
    useEffect(() => {
        if (initSlide !== undefined) {
            setAnimationSpeed(0);
            setCurrent(initSlide);
            setTimeout(() => {
                setAnimationSpeed(defaultAnimationSpeed);
            });
        }
    }, [initSlide]);
    useEffect(() => {
        setAnimationSpeed(0);
        setCurrent(0);
        setTimeout(() => {
            setAnimationSpeed(defaultAnimationSpeed);
        });
    }, [relatedId]);
    return (
        <div
            className={`
                ${styles.slider}
                ${size ? styles[`slider--${size}`] : ''}
                ${arrows ? styles['slider--arrows'] : ''}
                ${className}
            `}
        >
            {size === 'sm' ? (
                <div className={`${styles.slider__download} ${styles['slider__download--sm']}`}>
                    <Button
                        type={Button.TYPE.BUTTON}
                        onClick={() => onImageDownload(current)}
                    >
                        <SVGIcon SVGElement={DownloadIcon} />
                    </Button>
                </div>
            ) : (
                <div className={styles.slider__download}>
                    <Button
                        type={Button.TYPE.BUTTON}
                        size="md"
                        color={Button.COLOR.WHITE_BLUE}
                        onClick={() => onImageDownload(current)}
                    >
                        Скачать фото
                    </Button>
                </div>
            )}
            <Carousel
                infinite={infinite}
                dots={dots}
                lazyLoad
                value={current}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...arrowsProps}
                onChange={(targetIndex) => {
                    setCurrent(targetIndex);
                }}
                animationSpeed={animationSpeed}
                autoPlay={autoPlaySpeed}
                stopAutoPlayOnHover={stopAutoPlayOnHover}
            >
                {
                    slides.map((item, index) => (
                        <div
                            key={item.src}
                            className={styles.slider__slide}
                            onClick={(event) => onImageClick(event, index)}
                        >
                            <img src={item.src} alt={item.name} style={{ maxHeight }} />
                        </div>
                    ))
                }
            </Carousel>
        </div>
    );
};

ImageSliderArrow.propTypes = {
    direction: PropTypes.oneOf([
        LEFT,
        RIGHT,
    ]),
    disabled: PropTypes.bool,
};

ImageSliderArrow.defaultProps = {
    direction: LEFT,
    disabled: false,
};

ImageSlider.SIZE = { ...SIZE };

ImageSlider.propTypes = {
    arrows: PropTypes.bool,
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    maxHeight: PropTypes.number,
    slides: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
        }),
    ),
    size: PropTypes.string,
    onImageClick: PropTypes.func,
    onImageDownload: PropTypes.func,
    className: PropTypes.string,
    relatedId: PropTypes.string,
    initSlide: PropTypes.number,
    autoPlay: PropTypes.number,
    stopAutoPlayOnHover: PropTypes.bool,
};

ImageSlider.defaultProps = {
    arrows: true,
    dots: true,
    infinite: true,
    maxHeight: undefined,
    slides: [],
    size: SIZE.MEDIUM,
    onImageClick: () => {},
    onImageDownload: () => {},
    className: '',
    relatedId: null,
    initSlide: undefined,
    autoPlay: undefined,
    stopAutoPlayOnHover: false,
};

export default ImageSlider;
