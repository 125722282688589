import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import ObjectList from '../../../stores/shared/RefactoredObjectList/ObjectList';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import { ButtonClose } from '../../../components/Button';
import SimilarContent from './SimilarContent';
import { TAB_TYPE } from '../../../constants/shared';
import { ObjectListState } from '../ObjectListPage';

import styles from './SimilarObjects.module.scss';

const SimilarObjects = ({
    useStores,
    id,
    onClose,
    store: {
        similar,
        tabs,
    },
}) => {
    const handleRowClick = (row) => {
        const { id: itemId, isExclusive } = row.original;
        const instance = similar.createDetailed();
        instance.setData({ exclusive: isExclusive });
        similar.setCursor(itemId);
        instance.setData({ id: itemId });
        if (handleRowClick.callback) {
            instance.load(itemId, false);
            handleRowClick.callback(instance);
        }
    };
    const handleDoubleRowClick = (data) => {
        const {
            original: { id: itemId },
        } = data;
        const item = similar.getItem(itemId);
        if (!tabs.getTab(item.id)) {
            const instance = similar.createDetailed();
            instance.setData({ id: item.id, exclusive: item.isExclusive });
            const form = instance.createForm();
            form.on('done', (response) => {
                if (!response.filter((res) => Boolean(res)).length) {
                    return;
                }
                tabs.removeTab(item.id);
                item.setData(instance.toItem());
            });
            similar.setCursor(item.id);
            instance.load(item.id);
            tabs.addTab(
                instance,
                item.id,
                item.objectName,
                TAB_TYPE.EDIT_OBJECT,
            );
        }
    };
    return (
        <div className={`${styles.container} ${styles.related}`}>
            <h2 className={`handle ${styles.title}`}>
                Объекты похожие на
                &nbsp;
                <Observer>
                    {() => similar.parentObjectName}
                </Observer>
            </h2>
            <div className={styles.close}>
                <ButtonClose
                    onClick={() => onClose(id)}
                />
            </div>
            <div className={styles.similar__content}>
                <div className={styles.content}>
                    <Observer>
                        {() => (
                            <ObjectListState
                                isEmpty={similar.isEmpty}
                                error={similar.error}
                            />
                        )}
                    </Observer>
                    <Observer>
                        {() => (
                            <SimilarContent
                                useStores={useStores}
                                list={similar}
                                onDoubleRowClick={handleDoubleRowClick}
                                onRowClick={handleRowClick}
                            />
                        )}
                    </Observer>
                </div>
            </div>
        </div>
    );
};

SimilarObjects.propTypes = {
    useStores: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    store: PropTypes.shape({
        similar: PropTypes.instanceOf(ObjectList),
        tabs: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(PopupTabsWithApproveStore),
        ]),
    }).isRequired,
};

export default SimilarObjects;
