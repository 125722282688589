import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { BrickIcon } from '../../../components/Icons/icons';

import styles from './Table.module.scss';

const CellBrick = ({ value }) => (
    value ? <div className={styles['table__cell-brick']}><SVGIcon height={14} SVGElement={BrickIcon} /></div> : ''
);

CellBrick.propTypes = {
    value: PropTypes.bool,
};

CellBrick.defaultProps = {
    value: false,
};

export default CellBrick;
