import React from 'react';
import SVGIcon from '../Icons/SVGIcon';
import { CrossIcon } from '../Icons/icons';
import Button from './Button';

import buttonStyles from './Button.module.scss';

const size2height = {
    [Button.SIZE.SMALL]: 11,
    [Button.SIZE.MEDIUM]: 14,
    [Button.SIZE.LARGE]: 16,
};

const ButtonClose = ({
    children,
    onClick,
    size,
    className,
}) => (
    <button
        type="button"
        onClick={onClick}
        className={`${buttonStyles['button--close']} ${className}`}
    >
        {children}
        <SVGIcon
            SVGElement={CrossIcon}
            height={size2height[size]}
        />
    </button>
);

ButtonClose.SIZE = {
    ...Button.SIZE,
};

ButtonClose.TYPE = {
    ...Button.TYPE,
};

ButtonClose.COLOR = {
    ...Button.COLOR,
};

ButtonClose.propTypes = {
    ...Button.propTypes,
};

ButtonClose.defaultProps = {
    ...Button.defaultProps,
};

export default ButtonClose;
