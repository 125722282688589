import saleFlats from './sale-flats';
import rentFlats from './rent-flats';
import saleHouses from './sale-houses';
import rentHouses from './rent-houses';
import saleSteads from './sale-steads';
import rentSteads from './rent-steads';
import saleCommercial from './sale-commercial';
import rentCommercial from './rent-commercial';

export default {
    saleFlats,
    rentFlats,
    saleHouses,
    rentHouses,
    saleSteads,
    rentSteads,
    saleCommercial,
    rentCommercial,
};
