import { EMPTY_DATA_STRING } from '../constants/shared';

const extractBoardNameFromUrl = (urlStr) => {
    let hostname;
    if (URL.canParse(urlStr)) {
        hostname = new URL(urlStr).hostname;
    } else if (URL.canParse(`https://${urlStr}`)) {
        hostname = new URL(`https://${urlStr}`).hostname;
    }

    if (hostname) {
        return hostname
            .replace('www.', '')
            .replace('novosibirsk.', '')
            .replace('nsk.', '')
            .replace('krasnoyarsk.', '')
            .replace('krsk.', '');
    }

    return EMPTY_DATA_STRING;
};

export default extractBoardNameFromUrl;
