/** Конфигурация формы фильтра (группировки) для страницы поиска дубликатов */
const grouping = Object.freeze([{
    type: 'fieldgroup',
    name: 'cityId',
    fields: [{
        name: 'cityId',
        label: 'Населённый пункт',
        type: 'checkbox',
        size: 'md',
    }],
}, {
    type: 'fieldgroup',
    name: 'cityDistrictId',
    fields: [{
        name: 'cityDistrictId',
        label: 'Район',
        type: 'checkbox',
        size: 'md',
    }],
}, {
    type: 'fieldgroup',
    name: 'streetId',
    fields: [{
        name: 'streetId',
        label: 'Улица',
        type: 'checkbox',
        size: 'md',
    }],
}, {
    type: 'fieldgroup',
    name: 'houseId',
    fields: [{
        name: 'houseId',
        label: 'Дом',
        type: 'checkbox',
        size: 'md',
    }],
}, {
    type: 'fieldgroup',
    name: 'floor',
    fields: [{
        name: 'floor',
        label: 'Этаж',
        type: 'checkbox',
        size: 'md',
    }],
}, {
    type: 'fieldgroup',
    name: 'floorCount',
    fields: [{
        name: 'floorCount',
        label: 'Этажей всего',
        type: 'checkbox',
        size: 'md',
    }],
}, {
    type: 'fieldgroup',
    name: 'roomCount',
    fields: [{
        name: 'roomCount',
        label: 'Кол-во комнат',
        type: 'checkbox',
        size: 'md',
    }],
}, {
    type: 'fieldgroup',
    name: 'totalArea',
    fields: [{
        name: 'totalArea',
        label: 'Общая площадь',
        type: 'checkbox',
        size: 'md',
        postfix: 'т.р.',
    }, {
        name: 'totalAreaDelta',
        type: 'numeric',
        placeholder: 'Дельта',
    }],
}, {
    type: 'fieldgroup',
    name: 'price',
    fields: [{
        name: 'price',
        label: 'Цена',
        type: 'checkbox',
        size: 'md',
        postfix: 'т.р.',
    }, {
        name: 'priceDelta',
        type: 'numeric',
        placeholder: 'Дельта',
    }],
}, {
    type: 'fieldgroup',
    name: 'showSingleGroups',
    fields: [{
        name: 'showSingleGroups',
        label: 'Отображать одиночные',
        type: 'checkbox',
        size: 'md',
    }],
}]);

export default grouping;
