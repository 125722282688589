import FlatStrategy from './FlatStrategy';
import CommercialItem from '../item/CommercialItem';
import { TABLE_CELL } from '../../../../constants/shared';

class CommercialStrategy extends FlatStrategy {
    constructor() {
        super();
        this.objectType = 'commercial';
        this.Item = CommercialItem;
    }

    // eslint-disable-next-line class-methods-use-this
    get sortOrder() {
        return [
            TABLE_CELL.COMMERCIAL_TYPE_NAME.sortName,
            TABLE_CELL.DISTRICT_NAME.sortName,
            TABLE_CELL.STREET_NAME.sortName,
            TABLE_CELL.ROOMS_COUNT.sortName,
            TABLE_CELL.FLOOR_COUNT.sortName,
            TABLE_CELL.PRICE_DELIMITED.sortName,
            TABLE_CELL.DISTRICT_LANDMARK_NAME.sortName,
            TABLE_CELL.HOUSE_NUMBER.sortName,
        ];
    }
}

export default CommercialStrategy;
