class EventEmitter {
    constructor() {
        this.events = {};
    }

    emit(eventName, data) {
        const event = this.events[eventName];
        const promises = [];
        if (event) {
            event.forEach((fn) => {
                const result = fn.call(null, data);
                if (result instanceof Promise) {
                    promises.push(result);
                }
            });
        }
        return promises;
    }

    subscribe(eventName, fn) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(fn);
        return () => {
            this.events[eventName] = this.events[eventName].filter((eventFn) => fn !== eventFn);
        };
    }
}

const eventEmitter = new EventEmitter();

export default eventEmitter;
export {
    EventEmitter,
};
