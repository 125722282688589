import { computed } from 'mobx';
import Directory from '../../../shared/Directories/entities/Directory';
import isEmpty from '../../../../utils/is-empty';
import { arrayNaturalSort } from '../../../../utils/sortUtils/naturalSorting';

class ObjectStatusReasonDirectory extends Directory {
    localSortOptions;

    constructor(directory, objectStatusDirectory, service, sortOptions = { applySort: true, sortFieldName: 'label' }) {
        super(directory, service);
        this.objectStatusDirectory = objectStatusDirectory;
        this.localSortOptions = sortOptions;
    }

    @computed
    get loading() {
        const { objectStatusDirectory: { loading } } = this;
        return loading;
    }

    @computed
    get loaded() {
        const { objectStatusDirectory: { loaded } } = this;
        return loaded;
    }

    @computed
    get options() {
        const { objectStatusDirectory: { options } } = this;
        let result = options.reduce((carry, { value, extra: { reasons } = {} }) => {
            if (!reasons) return carry;
            return carry.concat(
                reasons
                    .map((reason) => ({
                        value: reason.id,
                        label: reason.name.trim(),
                        statusId: value,
                    })),
            );
        }, []);

        if (this.localSortOptions.applySort) {
            result = arrayNaturalSort(result, this.localSortOptions.sortFieldName);
        }

        return result;
    }

    getOptions({ statusIds = [] } = {}) {
        const { options } = this;
        return isEmpty(statusIds)
            ? options
            : options.filter(({ statusId: reasonStatusId }) => (
                statusIds.includes(reasonStatusId)
            ));
    }

    loadData() {
        const { objectStatusDirectory } = this;
        return objectStatusDirectory.loadData();
    }
}

export default ObjectStatusReasonDirectory;
