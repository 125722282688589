import { computed, observable } from 'mobx';
import { OBJECT_CREATED_AT_IS_FOR_ADDED_AT } from '../../../../config/client';

const withBaseDetailed = (Store) => (
    class extends Store {
        @observable $checkedAt;

        @observable $createdAt;

        @observable $addedAt;

        @computed
        get createdAt() {
            if (OBJECT_CREATED_AT_IS_FOR_ADDED_AT) {
                const { $addedAt } = this;
                return $addedAt;
            }

            const { $createdAt } = this;
            return $createdAt;
        }

        @computed
        get checkedAt() {
            const { $checkedAt } = this;
            return $checkedAt;
        }
    }
);

export default withBaseDetailed;
