import createQuery from '../../utils/create-query';

const createBillingService = (request) => ({
    getStats: () => request.get('/billing/stats/'),
    getCompanies: (filter = {}, page) => (
        request.post(
            `/billing/companies/${page ? `?page=${page}` : ''}`,
            filter,
        )
    ),
    getUsers: (filter = {}, page) => (
        request.post(
            `/billing/users/${page ? `?page=${page}` : ''}`,
            filter,
        )
    ),
    getCompany: (id) => (
        request.get(`/billing/companies/${id}/`)
    ),
    updateCompany: (id, values) => (
        request.put(`/billing/companies/${id}/`, values)
    ),
    toggleCompanyBlock: (id, status) => (
        request.patch(`/billing/companies/${id}/`, { status })
    ),
    archiveCompany: (id) => (
        request.delete(`/billing/companies/${id}/`)
    ),
    getUser: (id) => (
        request.get(`/billing/users/${id}/`)
    ),
    updateUser: (id, values) => (
        request.put(`/billing/users/${id}/`, values)
    ),
    toggleUserBlock: (id, status) => (
        request.patch(`/billing/users/${id}/`, { status })
    ),
    archiveUser: (id) => (
        request.delete(`/billing/users/${id}/`)
    ),
    deleteTelegramAccount: (id) => (
        request.delete(`/billing/users/telegram/chat-id/${id}/`)
    ),
    getSubscriptionDays: (id, sum) => (
        request.get(`/billing/payment?companyId=${id}&amount=${sum}`)
    ),
    getMinPayment: () => (
        request.get('/billing/payment/minimum')
            .then(({ minimum }) => minimum)
    ),
    setPayment: (companyId, { amount }) => (
        request.post('/billing/payment', { companyId, amount })
    ),
    setDebt: (companyId, { days }) => (
        request.post('/billing/debt', { companyId, days })
    ),
    deleteDebt: (companyId) => (
        request.delete(`/billing/debt?companyId=${companyId}`)
    ),
    getPaymentsLog: (filter, page) => {
        const ctxQuery = createQuery({ page, ...filter });
        return request.get(`/billing/payments?${ctxQuery}`);
    },
    getUsersLog: (filter, page) => {
        const ctxQuery = createQuery({ page, ...filter });
        return request.get(`/billing/users/actions?${ctxQuery}`);
    },
});

export default createBillingService;
