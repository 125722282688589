/**
 * This function normalizes empty arrays as empty string
 *
 * @param {object} values Values to normalize
 * @returns {object} Returns normalized object
 */
const normalizeValues = (values) => {
    if (!values) {
        return values;
    }
    Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
            // eslint-disable-next-line no-param-reassign
            if (values[key].length === 0) values[key] = '';
        } else if (typeof values[key] === 'object' && values[key] !== null) {
            // It is passed by reference
            normalizeValues(values[key]);
        }
    });
    // Return the root object
    return values;
};

const str = (values) => JSON.stringify(normalizeValues(values));

const withNamedSnapshots = () => (Store) => (
    class extends Store {
        mapSnapshots = new Map();

        // eslint-disable-next-line no-useless-constructor
        constructor(fields) {
            super(fields);
        }

        createSnapshot(name) {
            this.mapSnapshots.set(name, str(this.values()));
        }

        getSnapshot(name) {
            return this.mapSnapshots.get(name);
        }

        removeSnapshot(name) {
            return this.mapSnapshots.delete(name);
        }

        hasSnapshot(name) {
            return this.mapSnapshots.has(name);
        }

        differsFromSnapshot(name) {
            return this.mapSnapshots.has(name)
            && this.mapSnapshots.get(name) !== str(this.values());
        }
    }
);

export default withNamedSnapshots;
