import videoField from './video';
import { DIRECTORIES } from '../../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'basicInfo',
    label: 'Основная информация',
    fields: [{
        name: 'basicInfoLeft',
        type: 'fieldgroup',
        fields: [{
            name: 'roomCount',
            type: 'integer',
            negative: true,
            label: 'Комнат',
        }, {
            name: 'commercialType',
            type: 'directorySelect',
            directory: DIRECTORIES.OBJECT_COMMERCIAL_TYPE,
            label: 'Тип',
            rules: 'required',
            searchable: true,
            clearable: true,
        }, {
            name: 'wallMaterial',
            type: 'directorySelect',
            directory: DIRECTORIES.WALL_MATERIAL,
            label: 'Материал',
            searchable: true,
            clearable: true,
        }, {
            name: 'objectCondition',
            type: 'directorySelect',
            directory: DIRECTORIES.OBJECT_CONDITION,
            label: 'Состояние',
            searchable: true,
            clearable: true,
        }, {
            name: 'yearOfConstruction',
            type: 'integer',
            label: 'Год',
        }],
    }, {
        name: 'basicInfoRight',
        type: 'fieldgroup',
        fields: [{
            name: 'floors',
            type: 'inputList',
            size: 'sm',
            label: 'Этаж - Этажность',
            fields: [{
                name: 'floor',
                type: 'integer',
                attributeName: 'Этаж',
                rules: 'lte:floorCount',
            }, {
                name: 'floorCount',
                type: 'integer',
                attributeName: 'Этажность',
            }],
        }, {
            name: 'totalArea',
            type: 'numeric',
            size: 'sm',
            label: 'Площадь',
            postfix: 'м²',
        }, {
            name: 'pricesForSquareArea',
            type: 'inputList',
            size: 'sm',
            label: 'Цена - Цена м²',
            postfix: 'т.р.',
            fields: [{
                name: 'price',
                type: 'integer',
                attributeName: 'Цена',
                rules: 'gt:unitPrice',
            }, {
                name: 'unitPrice',
                type: 'numeric',
                attributeName: 'Цена м²',
            }],
        }, {
            name: 'surcharge',
            type: 'integer',
            label: 'Доплата',
        }, {
            name: 'exchange',
            type: 'checkbox',
            size: 'md',
            label: 'Обмен',
        }, {
            name: 'requiresDeposit',
            type: 'checkbox',
            size: 'md',
            label: 'Депозит',
        }, {
            name: 'ownerType',
            type: 'directoryRadioList',
            directory: DIRECTORIES.OWNER_TYPE,
            rules: 'required',
            size: 'md',
            rows: 1,
        }],
    }],
}, {
    type: 'fieldset',
    name: 'additionalInfo',
    label: 'Дополнительная информация',
    fields: [{
        name: 'additionalInfoLeft',
        type: 'fieldgroup',
        fields: [{
            name: 'heating',
            type: 'directorySelect',
            directory: DIRECTORIES.HEATING,
            label: 'Отопление',
            clearable: true,
            searchable: true,
        }],
    }, {
        name: 'additionalInfoRight',
        type: 'fieldgroup',
        fields: [{
            type: 'checkbox',
            name: 'hasInspectionPit',
            size: 'md',
            label: 'Смотровая яма',
        }, {
            type: 'checkbox',
            name: 'hasParking',
            size: 'md',
            label: 'Паркинг',
        }, {
            type: 'checkbox',
            name: 'hasSecurity',
            size: 'md',
            label: 'Охрана',
        }, {
            type: 'checkbox',
            name: 'hasBasement',
            size: 'md',
            label: 'Подвал',
        }],
    }, {
        name: 'operatorNoteWrapper',
        type: 'fieldgroup',
        fields: [{
            name: 'publicNote',
            type: 'textarea',
            rows: 3,
            label: 'Внешнее примечание',
        }, {
            name: 'privateNote',
            type: 'textarea',
            rows: 3,
            label: 'Внутреннее примечание',
        }],
    }],
}, videoField, {
    type: 'fieldset',
    name: 'note',
    label: 'Текст объявления',
    fields: [{
        name: 'note',
        type: 'textarea',
        rows: 14,
    }],
}, {
    type: 'fieldset',
    name: 'location',
    label: 'Расположение',
    fields: [{
        name: 'area',
        type: 'directorySearch',
        directory: DIRECTORIES.AREA,
        label: 'Район субъекта',
        searchable: true,
        clearable: true,
        isDropdown: true,
    }, {
        name: 'city',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        rules: 'required',
        searchable: true,
        clearable: true,
        withDefault: true,
    }, {
        name: 'cityDistrict',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        searchable: true,
        clearable: true,
        defaultCtx: { fromCard: true },
    }, {
        name: 'street',
        type: 'directorySearch',
        directory: DIRECTORIES.STREET,
        label: 'Улица',
        searchable: true,
        clearable: true,
    }, {
        name: 'house',
        type: 'directorySearch',
        label: '№ дома',
        directory: DIRECTORIES.HOUSE,
        searchable: true,
        clearable: true,
    }, {
        label: 'Ориентир',
        name: 'cityDistrictLandmark',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT_LANDMARK,
        searchable: true,
        clearable: true,
    }, {
        name: 'longitude',
        hidden: true,
    }, {
        name: 'latitude',
        hidden: true,
    }],
}, {
    type: 'fieldset',
    name: 'contacts',
    label: 'Контакты',
    fields: [{
        type: 'fieldgroup',
        name: 'contacts',
        fields: [{
            label: 'Контакты',
            name: 'phones',
            type: 'contact',
            rules: 'contact|required',
            size: 'sm',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'user-note',
    label: '',
    fields: [{
        name: 'userNote',
        rows: 3,
        type: 'textarea',
        label: 'Личные заметки',
        tooltip: 'Эти заметки видны только вам (пользователю)',
        readonly: false,
    }],
}, {
    type: 'fieldset',
    name: 'incorrect',
    label: 'Некорректность',
    fields: [{
        name: 'incorrect',
        type: 'directoryCheckboxList',
        size: 'md',
        columns: 2,
        colored: false,
        directory: DIRECTORIES.INCORRECT_OPTIONS,
        alwaysEditable: true,
    }],
}];
