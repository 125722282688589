import React from 'react';
import PropTypes from 'prop-types';

import styles from './DetailedView.module.scss';

const DetailedViewSidebar = ({
    children,
}) => (
    <div className={`${styles.sidebar}`}>
        {children}
    </div>
);

DetailedViewSidebar.propTypes = {
    children: PropTypes.element.isRequired,
};

DetailedViewSidebar.defaultProps = {};

export default DetailedViewSidebar;
