/* global alert */
import { observable } from 'mobx';
import request, {
    PaymentService,
    SessionService,
    StorageService,
    SocialNetworkService,
} from '../../../services/client';
import Payment from '../../shared/Session/entities/Payment';
import Session from '../../shared/Session';
import VkService from '../../../utils/vk-service';
import { loginFields } from '../../../forms/client';
import { TOKEN_UPDATE_LAG } from '../../../constants/client';

class SessionStore extends Session {
    @observable
    userId = '';

    paymentInfo = {};

    companyData = {};

    @observable
    socialNetworkInfo = {
        vkUserName: '',
        okUserName: '',
    };

    constructor() {
        super(SessionService, StorageService, {
            loginFields,
            tokenUpdateLag: TOKEN_UPDATE_LAG,
            tokenName: 'clientAccessToken',
        });
        // TODO: think about a better place for this
        // api request/response handlers
        request.interceptors.request.use(
            this.addTokenToRequest.bind(this),
            (error) => Promise.reject(error),
        );
        request.interceptors.response.use(
            (response) => response.data,
            this.checkResponseStatus.bind(this),
        );
    }

    login({ login, password }) {
        const { sessionService } = this;
        return sessionService.login({ login, password })
            .then(({ token, reactivation }) => {
                this.token = token;
                if (!reactivation) {
                    this.authorize();
                }
                return Promise.resolve(reactivation);
            })
            .catch((e) => {
                alert(e);
            });
    }

    logout() {
        super.logout();
        this.logoutVk();
    }

    logoutVk() {
        this.socialNetworkInfo.vkUserName = '';
        VkService.logout();
    }

    reactivateDevice() {
        const { sessionService } = this;
        return sessionService.reactivate()
            .then(({ token, reactivation }) => {
                if (token) {
                    this.token = token;
                }
                return Promise.resolve(reactivation);
            })
            .catch((e) => {
                alert(e);
                return Promise.reject(e);
            });
    }

    async loadSocialNetworkInfo() {
        this.socialNetworkInfo.vkUserName = await VkService.getUserName();

        SocialNetworkService.getOkAccountInfo()
            .then((data) => {
                this.socialNetworkInfo.okUserName = data.name;
            })
            .catch(() => {});
    }

    authorize() {
        const { sessionService } = this;
        sessionService.loadData()
            .then((data) => {
                this.userId = data.userId;
                this.companyData = data.company;
                this.paymentInfo = new Payment({
                    paymentDebt: data.debt,
                    paymentService: PaymentService,
                    paymentAmoutPerMonth: data.pricePerMonth,
                    paymentExpirationDate: new Date(data.active_until),
                });
                this.authorizeEvent();
                this.loadSocialNetworkInfo();
            })
            .catch((e) => { alert(e); console.error(e); });
    }
}

const createSessionStore = () => new SessionStore();

export default SessionStore;
export {
    createSessionStore,
};
