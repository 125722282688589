const isPhone = (phone = '') => (
    (/^[7,8][0-9]{10}$/).test(phone.replace(/[^0-9]*/g, ''))
);

const toE164 = (phone) => {
    const e164 = phone
        .replace(/[^0-9]*/g, '')
        .replace(/^8/, '7');
    return `+${e164}`;
};

export default isPhone;
export {
    isPhone,
    toE164,
};
