import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { Button } from '../../../components/Button';
import { ObjectPageStats } from '../ObjectPageView';
import { PageViewExtra } from '../PageView';
import ObjectList from '../../../stores/shared/RefactoredObjectList/ObjectList';

import styles from './NotepadObjects.module.scss';

const NotepadStats = ({
    notepad,
}) => {
    const handleSelectCancelClick = () => {
        notepad.list.unselectAll();
    };
    return (
        <PageViewExtra>
            <Observer>
                {() => (
                    <>
                        <ObjectPageStats>
                            Показано
                            &nbsp;
                            {notepad.list.tableData.length}
                            &nbsp;
                            из
                            &nbsp;
                            {notepad.list.paginationHandler.data.totalItems}
                        </ObjectPageStats>
                        {
                            notepad.list.selected && notepad.list.selected.length
                                ? (
                                    <>
                                        <ObjectPageStats>
                                            Выбрано
                                            &nbsp;
                                            {notepad.list.selected.length}
                                        </ObjectPageStats>
                                        <ObjectPageStats>
                                            <Button
                                                className={styles['page-view__unselect']}
                                                color={Button.COLOR.WHITE_BLACK}
                                                size={Button.SIZE.SMALL}
                                                onClick={handleSelectCancelClick}
                                            >
                                                Отменить
                                            </Button>
                                        </ObjectPageStats>
                                    </>
                                ) : null
                        }
                    </>
                )}
            </Observer>
        </PageViewExtra>
    );
};

NotepadStats.propTypes = {
    notepad: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        activeObjectType: PropTypes.string,
        activeDealType: PropTypes.string,
        list: PropTypes.instanceOf(ObjectList),
        setActiveObjectTypeTab: PropTypes.func,
        setActiveDealTypeTab: PropTypes.func,
        createDetailed: PropTypes.func,
        loading: PropTypes.bool,
    }).isRequired,
};

export default NotepadStats;
