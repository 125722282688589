import { action } from 'mobx';
import PopupTabsStore from './PopupTabsStore';

class PopupTabsWithApproveStore extends PopupTabsStore {
    $addTab(store, id, title, type, options) {
        this.tabs.push({
            store,
            id,
            title,
            type,
            options,
            approveIsActive: false,
        });
    }

    @action
    toggleApproveTab(id, actionType) {
        const tabIndex = this.getTabIndex(id);
        if (tabIndex === -1) {
            return;
        }
        const prevState = Boolean(this.tabs[tabIndex].approveIsActive);
        this.tabs[tabIndex].approveIsActive = !prevState;
        this.tabs[tabIndex].action = actionType;
    }
}

export default PopupTabsWithApproveStore;
