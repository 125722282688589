import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import ExportObjectsStore from '../../../stores/shared/ExportObjects';
import { ErrorMessageIcon, ExternalLinkIcon, SuccessMessageIcon } from '../../../components/Icons/icons';
import Dialog from '../Dialog';
import SVGIcon from '../../../components/Icons/SVGIcon';
import FormElements from '../Form';
import ExportObjectsFieldset from './ExportObjectsFieldset';
import ExportObjectsFieldGroup from './ExportObjectsFieldGroup';
import ExportObjectsField from './ExportObjectsField';
import { Button, ButtonLink } from '../../../components/Button';
import Preloader from '../../../components/Preloader';
import { Input } from '../../../components/Input';
import spawnNotification from '../../../utils/spawn-notification';

import styles from './ExportObjects.module.scss';

const ExportObjects = ({
    useStores,
    exportObjects,
}) => {
    const {
        headerActionsStore,
        headerActions,
        dialogStore,
    } = useStores();
    const handleExportClick = () => {
        exportObjects.load()
            .then((form) => {
                if (!form) {
                    return;
                }
                form.on('success', (values) => {
                    exportObjects.export(values)
                        .then(() => {
                            dialogStore.show('export-objects-message');
                        });
                });
            })
            .catch((e) => console.error(e));
        dialogStore.show('export-objects');
    };
    useEffect(() => {
        const actionsStore = headerActionsStore || headerActions;
        actionsStore.setActions([
            ...actionsStore.actions,
            {
                actionText: 'Экспорт',
                icon: ExternalLinkIcon,
                actionFunction: handleExportClick,
                order: 1,
            },
        ]);
    }, [exportObjects]);
    const notify = () => {
        spawnNotification('Ссылка скопирована');
    };
    const handleOneMoreTryClick = () => {
        dialogStore.show('export-objects');
    };
    const handleCloseClick = () => {
        dialogStore.hide();
    };
    const formComponents = {
        FieldsetWrapper: ExportObjectsFieldset,
        FieldGroupWrapper: ExportObjectsFieldGroup,
        FieldWrapper: ExportObjectsField,
    };
    const header = (
        <div className={styles['export-objects__header']}>
            Экспорт
            <SVGIcon
                SVGElement={ExternalLinkIcon}
                height={24}
                className={styles['export-objects__icon']}
            />
        </div>
    );
    return (
        <Observer>
            {() => (
                <>
                    <Dialog
                        name="export-objects"
                        size="lg"
                        className={styles['export-objects']}
                        footer={null}
                        closeable={!exportObjects.exporting}
                        header={header}
                        useStores={useStores}
                    >
                        <Observer>
                            {() => {
                                const { form, baseLoading, exporting } = exportObjects;
                                return form
                                    ? (
                                        <form onSubmit={form.onSubmit} className={styles['export-objects__form']}>
                                            <div className={`${styles['export-objects__field-list']}`}>
                                                <FormElements
                                                    useStores={useStores}
                                                    form={form}
                                                    elements={form.render}
                                                    components={formComponents}
                                                />
                                            </div>
                                            <div className={styles['export-objects__footer']}>
                                                <Button
                                                    className={styles['export-objects__submit']}
                                                    type={Button.TYPE.SUBMIT}
                                                    size={Button.SIZE.LARGE}
                                                    color={Button.COLOR.BLUE}
                                                    disabled={exportObjects.loading}
                                                    block
                                                >
                                                    Экспортировать
                                                </Button>
                                            </div>
                                            <div
                                                className={`
                                                    ${styles['export-objects__preloader']}
                                                    ${baseLoading || exporting ? styles['export-objects__preloader--loading'] : ''}
                                                `}
                                            >
                                                <div className={styles['export-objects__circle-wrapper']}>
                                                    <Preloader className={styles['export-objects__circle']} />
                                                    {
                                                        exporting
                                                            ? 'Объекты выгружаются...'
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    )
                                    : null;
                            }}
                        </Observer>
                    </Dialog>
                    <Dialog
                        name="export-objects-message"
                        size="md"
                        className={styles['export-objects']}
                        footer={null}
                        header={header}
                        useStores={useStores}
                    >
                        <div className={styles['export-objects__message']}>
                            {
                                exportObjects.error
                                    ? (
                                        <>
                                            <SVGIcon
                                                height={124}
                                                SVGElement={ErrorMessageIcon}
                                                className={`${styles['export-objects__message-icon']}`}
                                            />
                                            <div className={`${styles['export-objects__text']}`}>
                                                Произошла ошибка!
                                            </div>
                                            <div className={`${styles['export-objects__actions']}`}>
                                                <Button
                                                    color={Button.COLOR.BLUE}
                                                    size={Button.SIZE.LARGE}
                                                    block
                                                    onClick={handleOneMoreTryClick}
                                                >
                                                    Попробовать еще раз
                                                </Button>
                                                <Button
                                                    color={Button.COLOR.GRAY}
                                                    size={Button.SIZE.LARGE}
                                                    block
                                                    onClick={handleCloseClick}
                                                >
                                                    Отмена
                                                </Button>
                                            </div>
                                        </>
                                    )
                                    : (
                                        <>
                                            <SVGIcon
                                                height={124}
                                                SVGElement={SuccessMessageIcon}
                                                className={`${styles['export-objects__message-icon']}`}
                                            />
                                            <div className={`${styles['export-objects__text']}`}>
                                                Успешно экспортировано!
                                            </div>
                                            <div className={`${styles['export-objects__link']}`}>
                                                <div className={`${styles['export-objects__link-label']}`}>
                                                    Ссылка экспорта
                                                </div>
                                                <Input
                                                    size="md"
                                                    name="export-objects-link"
                                                    copy
                                                    onCopy={notify}
                                                    readOnly
                                                    value={exportObjects.link}
                                                />
                                            </div>
                                            <div className={`${styles['export-objects__actions']}`}>
                                                <ButtonLink
                                                    color={Button.COLOR.BLUE}
                                                    size={Button.SIZE.LARGE}
                                                    href={exportObjects.link}
                                                    block
                                                    download
                                                >
                                                    Скачать
                                                </ButtonLink>
                                                <Button
                                                    color={Button.COLOR.GRAY}
                                                    size={Button.SIZE.LARGE}
                                                    block
                                                    onClick={handleCloseClick}
                                                >
                                                    Закрыть окно
                                                </Button>
                                            </div>
                                        </>
                                    )

                            }
                        </div>
                    </Dialog>
                </>
            )}
        </Observer>
    );
};

ExportObjects.propTypes = {
    useStores: PropTypes.func.isRequired,
    exportObjects: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(ExportObjectsStore),
    ]),
};

ExportObjects.defaultProps = {
    exportObjects: null,
};

export default ExportObjects;
