import isNumber from '../../../utils/is-number';
import Field from './DefaultField';

class TagList extends Field {
    constructor(props) {
        super(props);
        const { data: { output } } = props;
        this.providedOutput = output;
    }

    $output = (raw) => {
        const { providedOutput } = this;
        let value = raw;
        if (value instanceof Object) {
            value = value.value;
        }
        if (value === 'value') {
            value = undefined;
        }
        if (typeof providedOutput === 'function') {
            return providedOutput(value || '');
        }
        return isNumber(value) ? Number(value) : value;
    }
}

export default TagList;
