import React from 'react';
import { SelectedRow, SelectedRowWithHint } from '../../../components/TableSelected';
import { defaultProps, propTypes } from './propTypes';

import styles from './SelectedObject.module.scss';

const SelectedCommercial = ({
    store,
}) => (
    <>
        <SelectedRowWithHint name="Состояние" classNames={{ row: styles.selected__row }}>
            {store.objectCondition?.label}
        </SelectedRowWithHint>
        <SelectedRowWithHint name="Отопление" classNames={{ row: styles.selected__row }}>
            {store.heating?.label}
        </SelectedRowWithHint>
        <SelectedRow name="Охрана" classNames={{ row: styles.selected__row }}>
            {store.hasSecurity ? 'Да' : 'Нет'}
        </SelectedRow>
        <SelectedRow name="Паркинг" classNames={{ row: styles.selected__row }}>
            {store.hasParking ? 'Да' : 'Нет'}
        </SelectedRow>
    </>
);

SelectedCommercial.propTypes = { store: propTypes.store };
SelectedCommercial.defaultProps = { store: defaultProps.store };

export default SelectedCommercial;
