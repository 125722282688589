import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContentWithNavItem } from './ContentWithNavItem';

import styles from './ContentWithNav.module.scss';

const scrollTargetIntoView = ({
    contentMap,
    id,
    contentRef,
    topOffset,
}) => {
    const targetContent = contentMap.find(
        (contentItem) => contentItem.key === id,
    );
    if (
        targetContent
        && targetContent.ref
        && targetContent.ref.current
    ) {
        const element = targetContent.ref.current;
        const { offsetTop } = element;
        // eslint-disable-next-line no-param-reassign
        contentRef.current.scrollTop = offsetTop - topOffset;
    }
};

const handleScroll = ({
    event,
    contentMap,
    setActive,
    topOffset,
}) => {
    const { target } = event;
    const { scrollTop } = target;
    contentMap.forEach((content, index) => {
        const element = content.ref.current;
        const { offsetTop } = element;
        const isNearby = scrollTop - offsetTop <= 0 && scrollTop - offsetTop > (-topOffset);
        if (isNearby) {
            setActive(content.key, index);
        }
    });
};

const contentStateEquality = (prevProps, nextProps) => {
    if (prevProps.memoKey !== undefined || nextProps.memoKey !== undefined) {
        return prevProps.memoKey === nextProps.memoKey;
    }
    return prevProps.contentMap.length === nextProps.contentMap.length;
};

const DefaultSidebar = ({ children }) => (
    <div className={styles.sidebar}>
        {children}
    </div>
);

DefaultSidebar.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

const DefaultContent = ({ children }) => (
    <div className={styles.content}>
        {children}
    </div>
);

DefaultContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

// Navigation list in sidebar
const SidebarList = ({
    navElements,
    handleNavClick,
    getCorrectKey,
    activeId,
}) => (
    <>
        {
            navElements.map((navItem, index) => {
                const { onClick, children, hidden } = navItem.props;
                if (hidden) {
                    return null;
                }
                const key = getCorrectKey(index);
                return (
                    <ContentWithNavItem
                        key={key}
                        onClick={(event) => {
                            if (onClick && typeof onClick === 'function') {
                                onClick(event, { id: key, index });
                                return;
                            }
                            handleNavClick({ id: key, index })();
                        }}
                        marker={navItem.props.marker}
                        markerColor={navItem.props.markerColor}
                        isActive={key === activeId}
                    >
                        {children}
                    </ContentWithNavItem>
                );
            })
        }
    </>
);

SidebarList.propTypes = {
    navElements: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    handleNavClick: PropTypes.func.isRequired,
    getCorrectKey: PropTypes.func.isRequired,
    activeId: PropTypes.string,
};

SidebarList.defaultProps = {
    navElements: [],
    activeId: null,
};

const ContentList = React.memo(({
    contentMap,
    getCorrectKey,
}) => (
    <>
        {
            contentMap && contentMap.length
                ? (
                    contentMap.map((contentItem, index) => (
                        <div
                            ref={contentItem.ref}
                            key={getCorrectKey(index)}
                        >
                            {contentItem.children}
                        </div>
                    ))
                )
                : null
        }
    </>
), contentStateEquality);

ContentList.propTypes = {
    contentMap: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    getCorrectKey: PropTypes.func.isRequired,
};

ContentList.defaultProps = {
    contentMap: [],
};

/**
 * Functional component for display content
 * with left bar navigation by scroll position
 * content
 *
 * @param {object} param0 - params for component
 * @param {Array} param0.children - childs
 * @param {object} param0.components - custom components
 * wrapper for sidebar & content
 * @param {Array} param0.topOffset - position from
 * top for correct scroll working
 * @param {Function} param0.onChangeActive handle setting
 * active id
 * @returns {React.FunctionComponent} (
 *  <ContentWithNav
 *      components={components}
 *      topOffset={topOffset}
 *  >
 *      {children}
 *  </ContentWithNav>
 * )
 */
const ContentWithNav = ({
    children: renderContent,
    components,
    topOffset,
    onChangeActive,
    memoKey,
    onMount,
}) => {
    const qtyWrappers = Object.keys(ContentWithNav.defaultProps.components).length;
    let wrappers = components;
    // Fix problem if not all wrappers added in component props
    if (Object.keys(wrappers).length < qtyWrappers) {
        wrappers = { ...ContentWithNav.defaultProps.components, ...wrappers };
    }
    const getCorrectKey = (index) => (`ContentWithNav__ref--${index}`);
    const [activeId, setActiveId] = useState(getCorrectKey(0));
    const setActive = (key, index) => {
        onChangeActive(key, index);
        setActiveId(key);
    };
    const setActiveIndex = (index) => {
        const key = getCorrectKey(index);
        setActive(key, index);
    };
    const children = renderContent({ setActiveIndex });
    const contentRef = useRef(null);
    const childArray = React.Children.toArray(children.props.children);
    const navigation = childArray.find(
        (childItem) => (childItem.props.role === 'navigation'),
    );
    let { children: navigationChildren } = navigation.props;
    navigationChildren = React.Children.toArray(navigationChildren);
    const content = childArray.find(
        (childItem) => (childItem.props.role === 'content'),
    );
    let { children: contentChildren } = content.props;
    contentChildren = React.Children.toArray(contentChildren);
    if (!navigation || !navigationChildren.length || !content || !contentChildren.length) {
        throw Error('Provided incorrect input data for ContentWithNav!');
    }
    if (navigationChildren.length !== contentChildren.length) {
        throw Error(`Qty children in ContentWithNavList[${navigationChildren.length}] should be equal qty children in ContentWithNavMain[${contentChildren.length}]!`);
    }
    const contentMap = contentChildren.map((childrenItem, index) => ({
        key: getCorrectKey(index),
        children: React.cloneElement(childrenItem),
        ref: useRef(null),
    }));
    useEffect(() => {
        const onScroll = (event) => {
            handleScroll({
                event,
                contentMap,
                setActive,
                topOffset,
            });
        };
        contentRef.current.addEventListener('scroll', onScroll);
        return () => { contentRef.current.removeEventListener('scroll', onScroll); };
    });
    useEffect(() => {
        scrollTargetIntoView({
            contentMap,
            id: activeId,
            contentRef,
            topOffset,
        });
    }, [activeId]);
    const handleNavClick = ({ id, index }) => () => {
        setActive(id, index);
    };
    useEffect(() => onMount(contentRef.current), []);
    return (
        <>
            <wrappers.SidebarWrapper>
                <SidebarList
                    navElements={navigationChildren}
                    handleNavClick={handleNavClick}
                    getCorrectKey={getCorrectKey}
                    activeId={activeId}
                />
            </wrappers.SidebarWrapper>
            <wrappers.ContentWrapper>
                <div
                    ref={contentRef}
                    className={styles.inner}
                >
                    <ContentList
                        memoKey={memoKey}
                        contentMap={contentMap}
                        getCorrectKey={getCorrectKey}
                    />
                </div>
            </wrappers.ContentWrapper>
        </>
    );
};

ContentWithNav.propTypes = {
    children: PropTypes.func.isRequired,
    components: PropTypes.shape({
        ContentWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        SidebarWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
    }),
    topOffset: PropTypes.number,
    onChangeActive: PropTypes.func,
    memoKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onMount: PropTypes.func,
};

ContentWithNav.defaultProps = {
    components: {
        ContentWrapper: DefaultContent,
        SidebarWrapper: DefaultSidebar,
    },
    topOffset: 0,
    onChangeActive: () => {},
    memoKey: '',
    onMount: () => undefined,
};

export default ContentWithNav;
