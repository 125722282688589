import React from 'react';
import PropTypes from 'prop-types';

import styles from './Selected.module.scss';
import { EMPTY_DATA_STRING } from '../../constants/shared';

const SelectedRow = ({
    classNames,
    name,
    children,
}) => (
    <div className={`${styles.selected__row} ${classNames?.row}`}>
        {
            name
                ? (
                    <div className={`${styles.selected__name} ${classNames?.name}`}>
                        {name}
                    </div>
                )
                : null
        }
        <div className={`${styles.selected__value}  ${classNames?.value}`}>
            {children || EMPTY_DATA_STRING}
        </div>
    </div>
);

SelectedRow.propTypes = {
    classNames: PropTypes.shape({
        row: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
    }),
    name: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ]),
        ),
    ]),
};

SelectedRow.defaultProps = {
    classNames: {},
    name: '',
    children: null,
};

export default SelectedRow;
