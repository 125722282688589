import React from 'react';
import useStores from '../../hooks/client/use-stores';
import requiresAuthorization from '../../hocs/requiresAuthorization';
import requiresNotAuthorized from '../../hocs/requiresNotAuthorized';
import { LoginPageContainer } from '../../containers/shared/LoginPage';
import Login, { FingerprintBlock, FingerprintReactivate } from '../../containers/client/Login';
import {
    RegisterSelect,
    RegisterOrganization,
    RegisterUser,
    RegisterOrganizationKey,
    RegisterOrganizationContacts,
    RegisterOrganizationCredentials,
    RegisterUserKey,
    RegisterUserCredentials,
    RegisterUserPassword,
    RegisterUserFinish,
    ResetPassword,
    ResetPasswordRequest,
    ResetPasswordAttention,
    ResetPasswordSet,
} from '../../containers/client/Register';
import Root from '../../containers/client/Root';
import ObjectList from '../../containers/client/ObjectListPage';
import ObjectListWrapper from '../../containers/client/ObjectListPage/ObjectListWrapper';
import PaymentStatus from '../../containers/client/PaymentStatus';
import PaymentFormPage from '../../containers/client/PaymentFormPage';
import { HomeBlueIcon } from '../../components/Icons/icons';

/**
 * @typedef {object} route
 * @property {string} title - route title
 * @property {string} [pageTitle] - page title
 * @property {React.Component|React.FunctionComponent} [icon] - route icon
 * @property {string} path - relative path
 * @property {boolean} exact - strict check
 * @property {React.Component|React.FunctionComponent} component - react component
 * @property {boolean} [addToBreadcrumbs] - flag to add title to breadcrumbs
 */

const routes = [{
    title: 'Авторизация',
    path: '/login',
    component: requiresNotAuthorized(LoginPageContainer, useStores),
    routes: [{
        title: 'Авторизация',
        path: '/auth',
        component: Login,
    }, {
        title: 'Восстановление пароля',
        path: '/forgot',
        component: ResetPassword,
        routes: [{
            title: 'Восстановление пароля',
            path: '/request',
            component: ResetPasswordRequest,
        }, {
            title: 'Восстановление пароля',
            path: '/attention',
            component: ResetPasswordAttention,
        }, {
            title: 'Восстановление пароля',
            path: '/password',
            component: ResetPasswordSet,
        }],
    }],
}, {
    title: 'Регистрация',
    path: '/register',
    component: requiresNotAuthorized(LoginPageContainer, useStores),
    routes: [{
        title: 'Регистрация',
        path: '/company',
        component: RegisterOrganization,
        routes: [{
            title: 'Регистрация',
            path: '/key',
            component: RegisterOrganizationKey,
        }, {
            title: 'Регистрация',
            path: '/contacts',
            component: RegisterOrganizationContacts,
        }, {
            title: 'Регистрация',
            path: '/credentials',
            component: RegisterOrganizationCredentials,
        }],
    }, {
        title: 'Регистрация',
        path: '/user',
        component: RegisterUser,
        routes: [{
            title: 'Регистрация',
            path: '/key',
            component: RegisterUserKey,
        }, {
            title: 'Регистрация',
            path: '/credentials',
            component: RegisterUserCredentials,
        }, {
            title: 'Регистрация',
            path: '/password',
            component: RegisterUserPassword,
        }, {
            title: 'Регистрация',
            path: '/finish',
            component: RegisterUserFinish,
        }],
    }, {
        title: 'Регистрация',
        path: '/',
        component: RegisterSelect,
    }],
}, {
    path: '/activate',
    component: LoginPageContainer,
    routes: [{
        path: '/re-activate',
        component: FingerprintReactivate,
    }, {
        path: '/block',
        component: FingerprintBlock,
    }],
}, {
    path: '/payment',
    component: PaymentStatus,
},
{
    path: '/payment-form',
    component: PaymentFormPage,
},
{
    path: '/',
    component: requiresAuthorization(Root, useStores),
    routes: [{
        title: 'Список объектов',
        pageTitle: 'Список объектов',
        path: '/list',
        icon: HomeBlueIcon,
        component: ObjectListWrapper,
        extra: {
            requestType: 'base',
        },
        routes: [{
            path: '/:dealType(sale|rent)/:objectType(flats|houses|steads|commercial)',
            component: ObjectList,
            extra: {
                requestType: 'base',
            },
        }],
    }],
}];

export default routes;
