import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { Button } from '../../../components/Button';
import { Input, Checkbox } from '../../../components/Input';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { CrossIcon, PhoneIcon } from '../../../components/Icons/icons';
import spawnNotification from '../../../utils/spawn-notification';
import { POSITION, SIZE } from '../../../constants/shared';

import styles from './Directory.module.scss';

const PhoneInput = ({
    size,
    name,
    value,
    readOnly,
    error,
    postfix,
    onInputChange,
    onSuggestionChange,
    onFetchSuggestions,
}) => {
    const [suggestions, setSuggestions] = useState([]);
    const getSuggestionValue = ({ phone }) => phone;
    const renderSuggestion = (suggestion) => (
        <div>
            {suggestion.phone}
        </div>
    );
    const shouldRenderSuggestions = (searchQuery, reason) => {
        switch (reason) {
            case 'input-focused':
                return false;
            default:
                return true;
        }
    };
    const onSuggestionsFetchRequested = ({ value: searchQuery }) => {
        onFetchSuggestions(searchQuery)
            .then((response) => {
                if (!response || !response.length) {
                    setSuggestions([]);
                }
                setSuggestions(response);
            })
            .catch(() => null);
    };
    const onSuggestionSelected = (e, { suggestion }) => {
        onSuggestionChange(suggestion);
    };
    const onSuggestionsClearRequested = () => {
        if (suggestions.length === 1) {
            onSuggestionChange(suggestions[0]);
        }
        setSuggestions([]);
    };
    const notify = () => {
        spawnNotification('Номер телефона скопирован');
    };
    return (
        <Autosuggest
            suggestions={suggestions}
            getSuggestionValue={getSuggestionValue}
            shouldRenderSuggestions={shouldRenderSuggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            renderSuggestion={renderSuggestion}
            inputProps={{
                placeholder: '',
                value,
                postfix,
                onChange: onInputChange,
            }}
            renderInputComponent={(inputProps) => (
                <Input
                    name={name}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...inputProps}
                    copy
                    onCopy={notify}
                    error={error}
                    size={size}
                    readOnly={readOnly}
                    autoComplete="none"
                />
            )}
            focusInputOnSuggestionClick={false}
        />
    );
};

PhoneInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    size: PropTypes.string,
    error: PropTypes.string,
    postfix: PropTypes.string,
    readOnly: PropTypes.bool,
    onInputChange: PropTypes.func,
    onSuggestionChange: PropTypes.func,
    onFetchSuggestions: PropTypes.func,
};

PhoneInput.defaultProps = {
    name: '',
    size: 'md',
    error: '',
    value: '',
    postfix: '',
    readOnly: false,
    onInputChange: () => null,
    onSuggestionChange: () => null,
    onFetchSuggestions: () => null,
};

const Contacts = ({
    useStores,
    value,
    id,
    size,
    onChange,
    error,
    readOnly,
    simple,
}) => {
    const { directoriesStore: { contact } } = useStores();
    const output = value;
    const emptyContactRow = {
        phone: '',
        contact: '',
        isAgent: 0,
        hide: 0,
    };
    const createNameInputHandler = (index) => (e) => {
        let val = e.target.value || '';

        const nameParts = val.trimStart().replace(/ +(?= )/g, '').split(' ');
        if (nameParts[0]) {
            nameParts[0] = nameParts[0].charAt(0).toUpperCase() + nameParts[0].substring(1);
        }
        if (nameParts[1]) {
            nameParts[1] = nameParts[1].charAt(0).toUpperCase() + nameParts[1].substring(1);
        }
        val = nameParts.join(' ');

        output[index].contact = val;
        onChange(output);
    };
    const createPhoneInputHandler = (index) => (e) => {
        const { target: { value: val } } = e;
        if (val === undefined || !val.replace) {
            return;
        }
        let phone = val.replace(/[^+0-9]*/g, '');
        if (phone !== '' && phone !== '+') {
            if (phone[0] !== '8' && phone[0] !== '7' && phone[0] !== '+') {
                phone = `+7${phone}`;
            } else {
                phone = phone.replace(/^8|(\+?7?)/, '+7');
            }
        }
        output[index].phone = phone;
        delete output[index].id;
        onChange(output);
    };
    const createSuggestionSelectHandler = (index) => (suggestion) => {
        output[index] = {
            ...output[index],
            ...suggestion,
        };
        onChange(output);
    };
    const createCheckboxChangeHandler = (index, key) => (e) => {
        output[index] = {
            ...output[index],
            [key]: Number(e.target.checked),
        };
        onChange(output);
    };
    const createDeleteHandler = (index) => () => {
        output.splice(index, 1);
        onChange(output);
    };
    const handleAddContactClick = () => onChange([...output, emptyContactRow]);
    const handleAutocompleteRequest = (searchQuery) => contact.searchByPhone(searchQuery);

    const renderContactButtons = (phone) => (
        <>
            <a
                href={`tel:${phone}`}
                data-tip="Позвонить"
                data-place={POSITION.RIGHT}
                data-class={styles['contacts__call-hint']}
                className={styles.contacts__call}
            >
                <SVGIcon SVGElement={PhoneIcon} height={16} />
            </a>
        </>
    );

    return (
        <div
            id={id}
            className={`
                ${styles.contacts}
                ${readOnly ? styles['contacts--readonly'] : ''}
            `}
        >
            <div className={styles.contacts__head}>
                <div className={`${styles.contacts__row} ${styles['contacts__row--head']}`}>
                    <div
                        className={`
                            ${styles.contacts__td}
                            ${styles['contacts__td--head']}
                            ${styles['contacts__td--name']}
                        `}
                    >
                        Контактное лицо
                    </div>
                    <div
                        className={`
                            ${styles.contacts__td}
                            ${styles['contacts__td--head']}
                            ${styles['contacts__td--phone']}
                        `}
                    >
                        Телефон
                    </div>
                    {
                        readOnly
                            ? null
                            : (
                                <>
                                    {
                                        simple
                                            ? null
                                            : (
                                                <>
                                                    <div
                                                        className={`
                                                            ${styles.contacts__td}
                                                            ${styles['contacts__td--head']}
                                                            ${styles['contacts__td--is-agent']}
                                                        `}
                                                    >
                                                        Агент
                                                    </div>
                                                    <div
                                                        className={`
                                                            ${styles.contacts__td}
                                                            ${styles['contacts__td--head']}
                                                            ${styles['contacts__td--hide']}
                                                        `}
                                                        data-tip="Скрыть от клиента"
                                                        data-place={POSITION.TOP}
                                                        data-class={styles['directory-contact__hint']}
                                                    >
                                                        Скрыть
                                                        <div className={styles.contacts__hint}>
                                                            ?
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                    }
                                    <div
                                        className={`
                                            ${styles.contacts__td}
                                            ${styles['contacts__td--head']}
                                            ${styles['contacts__td--empty']}
                                            ${styles['contacts__td--delete']}
                                        `}
                                    />
                                </>
                            )
                    }
                </div>
            </div>
            <div className="contracts-body">
                {value && value.map((
                    {
                        contact: name,
                        phone,
                        isAgent,
                        hide,
                    },
                    i,
                ) => (
                    <div
                        key={`contact-row-${id}-${i + 1}`}
                        className={`
                            ${styles.contacts__row}
                            ${styles['contacts__row--body']}
                        `}
                    >
                        <div
                            className={`
                                ${styles.contacts__td}
                                ${styles['contacts__td--body']}
                                ${styles['contacts__td--name']}
                            `}
                        >
                            <Input
                                name={`contact-name-${id}-${i}`}
                                value={name}
                                size={size}
                                error={error && !name ? error : ''}
                                readOnly={readOnly}
                                onChange={createNameInputHandler(i)}
                                autoComplete="none"
                            />
                        </div>
                        <div
                            className={`
                                ${styles.contacts__td}
                                ${styles['contacts__td--body']}
                                ${styles['contacts__td--phone']}
                            `}
                        >
                            {
                                simple
                                    ? (
                                        <Input
                                            copy
                                            name={`contact-phone-${id}-${i}`}
                                            value={phone}
                                            size={size}
                                            error={error && !(/^\+7\d{10}$/).test(phone) ? error : ''}
                                            postfix={renderContactButtons(phone)}
                                            readOnly={readOnly}
                                            onChange={createPhoneInputHandler(i)}
                                            autoComplete="none"
                                        />
                                    )
                                    : (
                                        <PhoneInput
                                            size={size}
                                            name={`contact-phone-${id}-${i}`}
                                            value={phone}
                                            readOnly={readOnly}
                                            postfix={renderContactButtons(phone)}
                                            error={error && !(/^\+7\d{10}$/).test(phone) ? error : ''}
                                            onInputChange={createPhoneInputHandler(i)}
                                            onSuggestionChange={createSuggestionSelectHandler(i)}
                                            onFetchSuggestions={handleAutocompleteRequest}
                                        />
                                    )
                            }
                        </div>
                        {
                            readOnly
                                ? null
                                : (
                                    <>
                                        {
                                            simple
                                                ? null
                                                : (
                                                    <>
                                                        <div
                                                            className={`
                                                                ${styles.contacts__td}
                                                                ${styles['contacts__td--body']}
                                                                ${styles['contacts__td--is-agent']}
                                                            `}
                                                        >
                                                            <Checkbox
                                                                size={SIZE.MEDIUM}
                                                                id={`contact-is-agent-${id}-${i}`}
                                                                name={`contact-is-agent-${id}-${i}`}
                                                                value={1}
                                                                checked={Boolean(isAgent)}
                                                                readOnly={readOnly}
                                                                onChange={createCheckboxChangeHandler(i, 'isAgent')}
                                                            />
                                                        </div>
                                                        <div
                                                            className={`
                                                                ${styles.contacts__td}
                                                                ${styles['contacts__td--body']}
                                                                ${styles['contacts__td--hide']}
                                                            `}
                                                        >
                                                            <Checkbox
                                                                size={SIZE.MEDIUM}
                                                                id={`contact-hide-${id}-${i}`}
                                                                name={`contact-hide-${id}-${i}`}
                                                                value={1}
                                                                checked={Boolean(hide)}
                                                                readOnly={readOnly}
                                                                onChange={createCheckboxChangeHandler(i, 'hide')}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                        }
                                        <div
                                            className={`
                                                ${styles.contacts__td}
                                                ${styles['contacts__td--head']}
                                                ${styles['contacts__td--empty']}
                                                ${styles['contacts__td--delete']}
                                            `}
                                        >
                                            <button
                                                type="button"
                                                className={styles.contacts__delete}
                                                onClick={createDeleteHandler(i)}
                                            >
                                                <SVGIcon
                                                    SVGElement={CrossIcon}
                                                    width={11}
                                                    height={11}
                                                />
                                            </button>
                                        </div>
                                    </>
                                )
                        }
                    </div>
                ))}
            </div>
            {
                readOnly
                    ? null
                    : (
                        <div className={styles.contacts__footer}>
                            <Button
                                size={Button.SIZE.MEDIUM}
                                color={Button.COLOR.WHITE_BLUE}
                                onClick={handleAddContactClick}
                            >
                                Добавить новый номер
                            </Button>
                            {
                                error && value.length === 0
                                    ? (
                                        <div className={styles.contacts__error}>
                                            {error}
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                    )
            }
        </div>
    );
};

Contacts.propTypes = {
    useStores: PropTypes.func.isRequired,
    id: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                contact: PropTypes.string,
                phone: PropTypes.string,
                isAgent: PropTypes.number,
                hide: PropTypes.number,
            }),
        ),
        PropTypes.string,
    ]),
    size: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    simple: PropTypes.bool,
};

Contacts.defaultProps = {
    size: SIZE.MEDIUM,
    id: '',
    error: '',
    value: [],
    readOnly: false,
    simple: false,
};

export default Contacts;
