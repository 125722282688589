import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { action, observable } from 'mobx';
import { EventEmitter } from '../../../helpers/EventEmitter';
import request, { StorageService } from '../../../services/client';
import { ACTIVATE_DEVICE_STATE, UPDATE_FINGERPRINT_INTERVAL } from '../../../constants/client';

class DeviceStore {
    fingerprintPromise;

    fingerprintStorageName = 'previousClientFingerprint';

    @observable fingerprint;

    @observable remaining;

    @observable success;

    @observable code;

    previousFingerprint;

    eventEmitter = new EventEmitter();

    constructor() {
        const { fingerprintStorageName } = this;
        this.fingerprintPromise = FingerprintJS.load();
        this.update()
            .then(() => this.eventEmitter.emit('device:ready', this.fingerprint));
        const previousFingerprint = StorageService(fingerprintStorageName);

        if (previousFingerprint) {
            this.previousFingerprint = previousFingerprint.fingerprint;
        }

        setInterval(this.update.bind(this), UPDATE_FINGERPRINT_INTERVAL);

        request.interceptors.request.use(
            this.modifyFingerprintRequest.bind(this),
            (error) => Promise.reject(error),
        );

        request.interceptors.response.use(
            (response) => Promise.resolve(response),
            this.handleFingerpintError.bind(this),
        );
    }

    on(...rest) {
        return this.eventEmitter.subscribe(...rest);
    }

    update() {
        const { fingerprintPromise } = this;
        return fingerprintPromise
            .then((instance) => instance.get())
            .then((result) => {
                this.fingerprint = result.visitorId;
                return Promise.resolve();
            });
    }

    save() {
        const { fingerprint, fingerprintStorageName } = this;
        StorageService(fingerprintStorageName, {
            fingerprint,
            timestamp: Date.now(),
        });
    }

    @action
    setReactivation({ remaining, code, success }) {
        this.remaining = remaining;
        this.code = code;
        this.success = success;
    }

    modifyFingerprintRequest(config) {
        const modifiedConfig = config;
        const { data, url } = modifiedConfig;
        const { previousFingerprint } = this;
        if (url.match(/companies\/[0-9]*\/users/)) {
            modifiedConfig.data = {
                ...data,
                fingerprint: this.fingerprint,
            };
        } else if (url === '/auth/token' || url === '/auth/device') {
            modifiedConfig.data = {
                ...data,
                fingerprint: this.fingerprint,
            };
            if (previousFingerprint) {
                modifiedConfig.data.previousFingerprint = previousFingerprint;
            }
        } else {
            modifiedConfig.headers['X-Device-ID'] = this.fingerprint;
        }
        return modifiedConfig;
    }

    handleFingerpintError(error) {
        const { response: { status, data } } = error;
        if (
            status === 403
            && data
            && data.error
            && data.error.code
            && (
                data.error.code === ACTIVATE_DEVICE_STATE.DEVICE_PARAMS_CHANGED
                || data.error.code === ACTIVATE_DEVICE_STATE.UNREGISTERED_DEVICE
                || data.error.code === ACTIVATE_DEVICE_STATE.NO_REACTIVATION_AVAILABLE
            )
        ) {
            const {
                error: { code, details: { remainingActivations = undefined } = {} },
            } = data;
            this.setReactivation({
                remaining: remainingActivations,
                code,
            });
            this.eventEmitter.emit('device:error', {
                remaining: this.remaining,
                code,
            });
        }
        return Promise.reject(error);
    }
}

const createDeviceStore = () => new DeviceStore();

export default DeviceStore;
export {
    createDeviceStore,
};
