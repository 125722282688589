/**
 * Конфигурация для полей, название которых есть и у client, и у operator.
 * Сами значения полей зависят от текущей конфигурации
 */

const {
    REACT_APP_EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT,
} = process.env;

// eslint-disable-next-line max-len
const EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT = typeof REACT_APP_EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT === 'string'
    && REACT_APP_EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT.length > 0
    ? REACT_APP_EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT
        .split(',')
        .map((str) => Number(str))
    : [];

export {
    // eslint-disable-next-line import/prefer-default-export
    EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT,
};
