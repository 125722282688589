export default [{
    type: 'fieldset',
    name: 'preset',
    fields: [{
        name: 'name',
        type: 'text',
        label: 'Название подборки',
        size: 'lg',
        rules: 'preset|required',
    }],
}];
