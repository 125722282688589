import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

import iconStyles from './IconInput.module.scss';
import inputStyles from './Input.module.scss';

const size2height = {
    [Input.SIZE.SMALL]: 12,
    [Input.SIZE.MEDIUM]: 14,
    [Input.SIZE.LARGE]: 16,
};

const IconInput = React.forwardRef(({
    icon,
    children,
    size,
    className,
    ...props
}, ref) => (
    <Input
        ref={ref}
        className={`
            ${iconStyles['input--with-icon']}
            ${inputStyles['input--with-icon']}
            ${className}
        `}
        size={size}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <div className={`${iconStyles.input__icon} ${iconStyles[`input__icon--${size}`]}`}>
            {
                React.cloneElement(
                    icon,
                    { height: size2height[size] },
                )
            }
        </div>
        {children}
    </Input>
));

IconInput.SIZE = { ...Input.SIZE };

IconInput.TYPE = { ...Input.TYPE };

IconInput.propTypes = {
    icon: PropTypes.element.isRequired,
    ...Input.propTypes,
};

IconInput.defaultProps = {
    ...Input.defaultProps,
};

export default IconInput;
