/* eslint linebreak-style: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import ReactTooltip from '../../../lib/react-tooltip';
import './draggableTooltip.scss';

/**
 * Тултип с возможностью перетаскивания
 * @param {Object} props
 * @param {string} props.id
 *  идентификатор тултипа (для отслеживания клика)
 * @param {(number: id) => React.Component} props.getContect функция для рендера контента тултипа
 * @param {Function} props.afterShow функция, которая выполнится после того, как тултип появится
 * @param {Function} props.afterHide функция, которая выполнится после того, как тултип скроется
 */
const DraggableTooltip = ({
    id, getContent, afterShow, afterHide,
}) => (
    <div className="draggable-tooltip-container">
        <Draggable handle="#drag-handler">
            <div className="dragable-tooltip">
                <ReactTooltip
                    id={id}
                    className="tooltip-content"
                    clickable
                    type="light"
                    delayHide={100}
                    event="click"
                    globalEventOff="click"
                    afterShow={afterShow}
                    afterHide={afterHide}
                    bodyMode
                    getContent={getContent}
                />
            </div>
        </Draggable>
    </div>
);

export default DraggableTooltip;

DraggableTooltip.propTypes = {
    id: PropTypes.string.isRequired,
    getContent: PropTypes.func.isRequired,
    afterShow: PropTypes.func,
    afterHide: PropTypes.func,
};

DraggableTooltip.defaultProps = {
    afterShow: () => {},
    afterHide: () => {},
};
