import React from 'react';
import PropTypes from 'prop-types';
import ObjectComponent from '../ObjectComponent';
import ObjectDetailed from '../../../stores/shared/RefactoredObjectDetailed/ObjectDetailed';

const CreateObject = ({
    useStores,
    id,
    onObjectClose,
    onClose,
    store,
    tabsStore,
}) => (
    <ObjectComponent
        useStores={useStores}
        id={id}
        onObjectClose={onObjectClose}
        onClose={onClose}
        store={store}
        tabsStore={tabsStore}
        title="Создание объекта"
    />
);

CreateObject.propTypes = {
    useStores: PropTypes.func,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onObjectClose: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(ObjectDetailed).isRequired,
    tabsStore: PropTypes.shape({}).isRequired,
};

CreateObject.defaultProps = {
    useStores: () => null,
};

export default CreateObject;
