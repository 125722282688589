/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import parsePrice from '../../../utils/parse-price';

import styles from './Table.module.scss';

const PriceTooltip = ({ priceDynamic }) => {
    if (!priceDynamic?.length) {
        return null;
    }

    return (
        <div className={styles.table__dynamic}>
            <table>
                <thead>
                    <tr>
                        <td>Дата</td>
                        <td>Цена</td>
                        <td>Разница</td>
                    </tr>
                </thead>
                <tbody>
                    {priceDynamic.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                            <td>{new Date(item.date).toLocaleDateString('ru-RU')}</td>
                            <td>{parsePrice(item.price)}</td>
                            <td
                                className={item.diff > 0}
                                // eslint-disable-next-line no-nested-ternary
                                direction={item.diff > 0 ? 'up' : item.diff < 0 ? 'down' : ''}
                            >
                                {item.diff > 0 && '+'}
                                {parsePrice(item.diff)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

PriceTooltip.propTypes = {
    priceDynamic: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        diff: PropTypes.number,
        price: PropTypes.number,
    })),
};

PriceTooltip.defaultProps = {
    priceDynamic: [],
};

export default PriceTooltip;
