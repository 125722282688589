import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'location',
    fields: [{
        name: 'cityIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        multiple: true,
        clearable: true,
        searchable: true,
        withDefault: true,
        isDropdown: true,
    }, {
        name: 'cityDistrictIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset',
    name: 'steadType',
    fields: [{
        name: 'objectHouseTypeIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_HOUSE_TYPE,
        label: 'Тип дома',
        rules: 'required',
        searchable: true,
        multiple: true,
        clearable: true,
    }, {
        name: 'rooms',
        type: 'fieldgroup',
        label: 'Комнат',
        fields: [{
            name: 'roomsFrom',
            type: 'integer',
            prefix: 'от',
        }, {
            name: 'roomsTo',
            type: 'integer',
            prefix: 'до',
        }],
    }, {
        name: 'square',
        type: 'fieldgroup',
        label: 'Площадь, м²',
        fields: [{
            name: 'totalAreaFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'totalAreaTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }, {
        name: 'wallMaterialIds',
        type: 'directorySelect',
        directory: DIRECTORIES.WALL_MATERIAL,
        label: 'Материал',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}];
