import SearchableDirectory from '../../../shared/Directories/entities/SearchableDirectory';

class Users extends SearchableDirectory {
    loadData(query = '', ctx = {}) {
        const {
            minQueryLength,
            debounce,
            requestId,
        } = this;
        this.setError('');
        if (minQueryLength && typeof query !== 'string') {
            throw new Error(`Search query must be a string, got: ${typeof query}`);
        }
        if (query.length < minQueryLength) {
            return Promise.reject();
        }
        if (requestId) {
            clearTimeout(requestId);
        }
        this.dataSource.setPreload();
        return new Promise((resolve, reject) => {
            this.requestId = setTimeout(() => {
                this.dataSource.getUsers(query, ctx)
                    .then((response) => {
                        this.setError('');
                        this.requestId = 0;
                        resolve(response);
                    })
                    .catch((e) => {
                        this.setError(e.message);
                        reject(e);
                    });
            }, debounce);
        });
    }
}

export default Users;
