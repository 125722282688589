import { computed, observable } from 'mobx';
import { withAddressField, withObjectDetailedField } from '../../../helpers/Form/mixins';
import HouseDirectory from '../Directories/entities/HouseDirectory';
import ObjectDetailed from './ObjectDetailed';

class FlatDetailed extends ObjectDetailed {
    @observable $sale;

    @observable $rent;

    @observable $roomType;

    @observable $developer;

    @observable $balcony;

    @observable $personsPerLavatory;

    @observable $lavatory;

    constructor(service) {
        super(service, 'flats');
    }

    @computed
    get objectName() {
        const {
            $street = {},
            $house = {},
            $complex = {},
            id,
        } = this;
        if ($street) {
            return [$street.name, $house?.number, $complex?.name && `ЖК ${$complex.name}`]
                .filter((address) => Boolean(address))
                .join(', ');
        }
        return id;
    }

    @computed
    get house() {
        const { $house } = this;
        if (!$house) {
            return null;
        }
        return {
            value: $house.id,
            label: HouseDirectory.getFullHouseName($house),
            extra: {
                number: $house.number,
                buildingNumber: $house.buildingNumber,
                structureNumber: $house.structureNumber,
            },
        };
    }

    @computed
    get series() {
        const { $series } = this;
        if (!$series) {
            return null;
        }
        return {
            value: $series.id,
            label: $series.name,
        };
    }

    @computed
    get personsPerLavatory() {
        return this.$personsPerLavatory;
    }

    @computed
    get wallMaterial() {
        const { $wallMaterial } = this;
        if (!$wallMaterial) {
            return null;
        }
        return {
            value: $wallMaterial.id,
            label: $wallMaterial.name,
        };
    }

    @computed
    get flatType() {
        const { $sale } = this;
        if (!$sale || !$sale.flatType) {
            return null;
        }
        return {
            value: $sale.flatType.id,
            label: $sale.flatType.name,
        };
    }

    @computed
    get requiresDeposit() {
        const { $sale } = this;
        if (!$sale || !$sale.requiresDeposit) {
            return null;
        }
        return $sale.requiresDeposit;
    }

    @computed
    get balcony() {
        const { $balcony } = this;
        if (!$balcony) {
            return null;
        }
        return {
            value: $balcony.id,
            label: $balcony.name,
        };
    }

    @computed
    get lavatory() {
        const { $lavatory } = this;
        if (!$lavatory) {
            return null;
        }
        return {
            value: $lavatory.id,
            label: $lavatory.name,
        };
    }

    @computed
    get developer() {
        const { $developer } = this;
        if (!$developer) {
            return null;
        }
        return {
            value: $developer.id,
            label: $developer.name,
        };
    }

    @computed
    get complex() {
        const { $complex } = this;
        if (!$complex) {
            return null;
        }
        return {
            value: $complex.id,
            label: $complex.name,
        };
    }

    @computed
    get stoveType() {
        const { $stoveType } = this;
        if (!$stoveType) {
            return null;
        }
        return {
            value: $stoveType.id,
            label: $stoveType.name,
        };
    }

    @computed
    get roomType() {
        const { $roomType } = this;
        if (!$roomType) {
            return null;
        }
        return {
            value: $roomType.id,
            label: $roomType.name,
        };
    }

    @computed
    get fullAddress() {
        const { $city, $street, $house } = this;
        if (!$city || !$street || !$house) {
            return null;
        }
        return `${$city.name}, ${$street.name}, ${$house.number}`;
    }

    createForm(mixins = []) {
        return super.createForm([
            withObjectDetailedField({
                totalArea: 'totalArea',
                price: 'price',
                unitPrice: 'unitPrice',
            }),
            withAddressField({
                area: 'area',
                city: 'city',
                district: 'cityDistrict',
                districtLandmark: 'cityDistrictLandmark',
                street: 'street',
                house: 'house',
            }),
            ...mixins,
        ]);
    }

    // eslint-disable-next-line class-methods-use-this
    prepareRequest(values) {
        const prepared = super.prepareRequest(values);
        return {
            ...prepared,
            roomCount: values.roomCount,
            seriesId: values.series,
            wallMaterialId: values.wallMaterial,
            developerId: values.developer,
            complexId: values.complex,
            floor: values.floor,
            floorCount: values.floorCount,
            totalArea: values.totalArea,
            kitchenArea: values.kitchenArea,
            livingArea: values.livingArea,
            lavatoryId: values.lavatory,
            personsPerLavatory: values.personsPerLavatory,
            balconyId: values.balcony,
            cityId: values.city,
            cityDistrictId: values.cityDistrict,
            cityDistrictLandmarkId: values.cityDistrictLandmark,
            streetId: values.street,
            houseId: Array.isArray(values.house) ? values.house[0] : values.house,
            room: values.room,
            stoveTypeId: values.stoveType,
            objectRoomTypeId: values.roomType,
            yearOfConstruction: values.yearOfConstruction,
        };
    }

    toItem() {
        const item = super.toItem();
        return {
            ...item,
            house: this.$house ? this.$house.number : null,
            floor: this.$floor,
            floorCount: this.$floorCount,
            totalArea: this.$totalArea,
            kitchenArea: this.$kitchenArea,
            livingArea: this.$livingArea,
            roomCount: this.$roomCount,
            series: this.$series,
            unitPrice: this.$unitPrice,
            stoveType: this.$stoveType,
            yearOfConstruction: this.$yearOfConstruction,
        };
    }
}

export default FlatDetailed;
