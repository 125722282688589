import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormElements from '../Form';
import FilterField from './FilterField';
import PopupTabsStore from '../../../stores/shared/PopupTabsStore';
import FilterFieldset from './FilterFieldset';
import FilterFieldGroup from './FilterFieldGroup';
import { FilterFooter as FilterFooterDefault } from './FilterFooter';
import { CollapseButton } from '../../../components/Button';

import styles from './Filter.module.scss';

const Filter = ({
    tabsStore,
    useStores,
    form,
    title,
    withCollapse,
    initOpened,
    components,
    fullHeight,
    customClassNames,
    onCollapse,
}) => {
    const wrappers = { ...Filter.defaultProps.components, ...components };
    // Avoid case when not have collapse button & initOpened = false
    const initOpenedState = !withCollapse ? true : initOpened;
    const [opened, setOpened] = useState(initOpenedState);
    const toggleOpened = () => {
        const next = !opened;
        setOpened(next);
        onCollapse(!next);
    };
    useEffect(() => {
        onCollapse(!initOpenedState);
    }, []);

    return (
        <div
            className={`
                ${styles.filter}
                ${fullHeight ? styles['filter--full'] : ''}
                ${opened ? styles['filter--opened'] : ''}
            `}
        >
            {
                opened && title
                    ? (
                        <div className={styles.filter__title}>
                            {title}
                        </div>
                    )
                    : null
            }
            {
                withCollapse
                    ? (
                        <div className={styles.filter__collapse}>
                            <CollapseButton
                                isOpen={opened}
                                size="sm"
                                color="white-gray"
                                onClick={toggleOpened}
                            />
                        </div>
                    )
                    : null
            }
            <div className={`${styles.filter__content} ${fullHeight ? styles['filter__content--full'] : ''}`}>
                <wrappers.FilterHeader />
                <form onSubmit={form.onSubmit} className={styles.filter__form}>
                    <div className={`${styles['filter__field-list']} ${customClassNames.fieldList || ''}`}>
                        <FormElements
                            useStores={useStores}
                            form={form}
                            tabsStore={tabsStore}
                            elements={form.render}
                            components={{
                                FieldWrapper: wrappers.FilterField || FilterField,
                                FieldsetWrapper: wrappers.FilterFieldset || FilterFieldset,
                                FieldGroupWrapper: wrappers.FilterFieldGroup || FilterFieldGroup,
                            }}
                        />
                    </div>
                    <wrappers.FilterFooter form={form} />
                </form>
            </div>
        </div>
    );
};

Filter.propTypes = {
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    useStores: PropTypes.func.isRequired,
    title: PropTypes.string,
    form: PropTypes.shape({
        onSubmit: PropTypes.func,
        render: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
    withCollapse: PropTypes.bool,
    initOpened: PropTypes.bool,
    components: PropTypes.shape({
        FilterHeader: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        FilterFooter: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        FilterField: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        FilterFieldset: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        FilterFieldGroup: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
    }),
    fullHeight: PropTypes.bool,
    customClassNames: PropTypes.shape({
        fieldList: PropTypes.string,
    }),
    onCollapse: PropTypes.func,
};

Filter.defaultProps = {
    title: '',
    withCollapse: true,
    initOpened: true,
    components: {
        FilterFooter: FilterFooterDefault,
        FilterHeader: () => null,
    },
    fullHeight: true,
    customClassNames: {},
    onCollapse: () => null,
};

export default Filter;
