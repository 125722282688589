import { computed, observable, runInAction } from 'mobx';
import cloneDeep from 'lodash.clonedeep';
import createForm from '../../../helpers/Form';
import DataSource, { STATES } from '../DataSource';
import { exportObjects as exportObjectsFormFields } from '../../../forms/shared';
import forEachField from '../../../forms/utils/for-each-field';
import findField from '../../../forms/utils/find-field';
import {
    EXPORT_TYPE,
    EXPORT_STATUS,
    EXPORT_PRESET_MAP,
    OBJECT_TYPE_MAP,
    DEAL_TYPE_MAP,
    REQUEST_REPEAT_INTERVAL,
} from '../../../constants/shared';
import { EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT } from '../../../config/shared';

class ExportObjects {
    exportPresets = undefined;

    exportTypes = undefined;

    attributes = undefined;

    attributesForExport = [];

    canSelectAttributes = undefined;

    storagePrefix = 'default';

    @observable form = undefined;

    @observable attributesDisabled = false;

    @observable atrributesLoading = true;

    @observable baseLoading = true;

    @observable exporting = false;

    @observable error = false;

    @observable link = '';

    constructor(service, storageService, list, notepads) {
        this.dataSource = new DataSource(service, STATES.LOADING);
        this.storage = storageService;
        this.list = list;
        this.notepads = notepads;
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    getExportParams() {
        const { dataSource } = this;
        runInAction(() => {
            this.baseLoading = true;
        });
        return dataSource.getExportParams()
            .finally(() => {
                runInAction(() => {
                    this.baseLoading = false;
                });
            });
    }

    getExportFields(exportTypeId) {
        const { dataSource } = this;
        runInAction(() => {
            this.atrributesLoading = true;
        });
        return dataSource.getExportFields(exportTypeId)
            .finally(() => {
                runInAction(() => {
                    this.atrributesLoading = false;
                });
            });
    }

    saveFormToStorage() {
        const { form, storage, storagePrefix } = this;
        const company = form.$('company').get('value');
        const dealType = form.$('dealType').get('value');
        const exportType = form.$('exportTypeId').get('value');
        const fields = form.$('fields').get('value');
        const savedExportValues = storage(`${storagePrefix}Export${exportType}`);
        const exportValues = {
            ...savedExportValues,
            company,
            [dealType]: fields,
        };
        storage(`${storagePrefix}Export${exportType}`, exportValues);
    }

    getSavedFormFromStorage() {
        const { form, storage, storagePrefix } = this;
        const dealType = form.$('dealType').get('value');
        const exportType = form.$('exportTypeId').get('value');
        const { company, ...exportValues } = storage(`${storagePrefix}Export${exportType}`) || {};
        return {
            company,
            [dealType]: exportValues?.[dealType] || undefined,
        };
    }

    load() {
        const { baseLoading } = this;
        if (baseLoading) {
            return Promise.all([
                this.getExportParams(),
                this.notepads.load(),
            ])
                .then(([{ exportTypes, exportPresets }]) => {
                    const { notepads: { list } } = this;
                    this.setBaseParams({ exportTypes, exportPresets });
                    this.createForm();
                    this.setNotepads(list);
                    this.restoreCompanyFromStorage();
                    this.initFormListeners();
                    return Promise.resolve();
                })
                .then(() => {
                    const { exportTypes: [{ id: exportTypeId }] } = this;
                    return this.getExportFields(exportTypeId);
                })
                .then(({ attributesForExport, canSelectAttributes }) => {
                    this.setExtraParams({ attributesForExport, canSelectAttributes });
                    this.updateForm();
                    this.updateExportTargetCounts();
                    return Promise.resolve(this.form);
                });
        }
        return this.notepads.load(true)
            .then(() => {
                const { form } = this;
                const exportTypeIdField = form.$('exportTypeId');
                const exportTypeId = exportTypeIdField.get('value');
                const { notepads: { list } } = this;
                this.setNotepads(list);
                return this.getExportFields(exportTypeId);
            })
            .then(({ attributesForExport, canSelectAttributes }) => {
                this.setExtraParams({ attributesForExport, canSelectAttributes });
                this.updateForm();
                this.updateExportTargetCounts();
                return Promise.resolve();
            });
    }

    setNotepads(list) {
        const { form } = this;
        const notepadsField = form.$('notebookId');
        const exportTargetField = form.$('exportTarget');
        const extra = notepadsField.get('extra');
        const selectedNotepad = notepadsField.get('value');
        const selectedExportTarget = exportTargetField.get('value');
        const defaultNotepad = list && list.length && list[0];
        notepadsField.set('extra', {
            ...extra,
            options: list.map(({ name, id, objectCount }) => ({
                id,
                value: id,
                label: `${name} (${objectCount})`,
            })),
        });
        notepadsField.set('disabled', selectedExportTarget !== 'notebook');
        if (!selectedNotepad && defaultNotepad) {
            notepadsField.set('value', {
                id: defaultNotepad.id,
                value: defaultNotepad.id,
                label: `${defaultNotepad.name} (${defaultNotepad.objectCount})`,
            });
        }
    }

    setBaseParams({ exportTypes, exportPresets }) {
        this.exportPresets = exportPresets;
        this.exportTypes = exportTypes;
    }

    setExtraParams({ attributesForExport, canSelectAttributes }) {
        this.attributesForExport = attributesForExport;
        this.canSelectAttributes = canSelectAttributes;
    }

    restoreCompanyFromStorage() {
        const { form } = this;
        const { company } = this.getSavedFormFromStorage();
        form.$('company').set('value', company || '');
    }

    createForm() {
        const {
            exportTypes,
            exportPresets,
        } = this;
        const fieldsClone = cloneDeep(exportObjectsFormFields);
        forEachField(fieldsClone, (field) => {
            switch (field.name) {
                /* eslint-disable no-param-reassign */
                case 'exportTypeId':
                    field.options = exportTypes.map(({ id, name }) => ({
                        value: id,
                        label: name,
                    }));
                    field.rows = exportTypes.length;
                    field.value = exportTypes[0].id;
                    break;
                case 'exportAttributes':
                    field.options = exportPresets.map(({ name }, index) => ({
                        id: index,
                        value: index,
                        label: name,
                    }));
                    field.value = {
                        id: 0,
                        value: 0,
                        label: exportPresets[0].name,
                    };
                    break;
                default:
                    break;
                /* eslint-enable no-param-reassign */
            }
        });
        this.form = createForm(fieldsClone);
        return this.form;
    }

    updateForm() {
        const { form, attributesForExport } = this;
        const dealTypeField = form.$('dealType');
        const fieldsField = form.$('fields');
        const dealTypeExtra = dealTypeField.get('extra');
        const dealTypeValue = dealTypeField.get('value');
        let [defaultAttributte] = attributesForExport;
        dealTypeField.set('extra', {
            ...dealTypeExtra,
            options: attributesForExport.map(({ name, key }) => {
                const option = {
                    label: name,
                    value: key,
                    id: key,
                };
                if (key === dealTypeValue) {
                    defaultAttributte = { name, key };
                }
                return option;
            }),
        });
        if (defaultAttributte) {
            dealTypeField.set('disabled', false);
            fieldsField.set('disabled', false);
            dealTypeField.set('value', {
                label: defaultAttributte.name,
                value: defaultAttributte.key,
                id: defaultAttributte.key,
            });
        } else {
            dealTypeField.set('disabled', true);
            fieldsField.set('disabled', true);
        }
    }

    updateExportTargetCounts() {
        const { list, form, notepads: { list: notepadsList } } = this;
        const exportTargetField = form.$('exportTarget');
        const exportTargetExtra = exportTargetField.get('extra');
        const notebookIdField = form.$('notebookId');
        const selectedNotebookId = notebookIdField.get('value');
        exportTargetField.set('extra', {
            ...exportTargetExtra,
            options: exportTargetExtra.options.map((option, index) => {
                const { label, value } = findField(exportObjectsFormFields, 'exportTarget').options[index];
                let count = 0;
                switch (value) {
                    case 'all':
                        count = list.paginationHandler.totalItems;
                        break;
                    case 'selected':
                        count = list.selected.length;
                        break;
                    case 'notebook':
                        count = notepadsList
                            .find((notepad) => notepad.id === selectedNotebookId)?.objectCount;
                        break;
                    default:
                        break;
                }
                return {
                    ...option,
                    label: `${label} (${count})`,
                };
            }),
        });
    }

    updateFields() {
        const { form, attributesForExport, exportPresets } = this;
        const presetField = form.$('exportAttributes');
        const noContactNamesField = form.$('noContactNames');
        const dealTypeField = form.$('dealType');
        const fieldsField = form.$('fields');

        const preset = presetField.get('value');
        const dealType = dealTypeField.get('value');

        const noContactNamesFieldDisabled = presetField.value.value !== 0;
        noContactNamesField.set('disabled', noContactNamesFieldDisabled);
        if (noContactNamesFieldDisabled) {
            noContactNamesField.set(false);
        }

        const { disabledAttributes } = exportPresets[preset || 0];
        const { attributes = [] } = attributesForExport.find(({ key }) => key === dealType);
        const savedValues = this.getSavedFormFromStorage();
        const savedDealTypeValues = savedValues[dealType];
        fieldsField.set('extra', {
            ...fieldsField.get('extra'),
            rows: attributes.length,
            options: attributes.map(({ key, value }) => ({
                label: value,
                value: key,
                disabled: disabledAttributes.find((item) => item === key),
            })),
        });
        fieldsField.set('value', attributes.reduce((carry, { key }) => {
            if (!savedDealTypeValues || savedDealTypeValues.find((savedKey) => savedKey === key)) {
                carry.push(key);
            }
            return carry;
        }, []));
    }

    initFormListeners() {
        const { form } = this;
        const exportTypeIdField = form.$('exportTypeId');
        const presetField = form.$('exportAttributes');
        const passwordField = form.$('password');
        const companyField = form.$('company');
        const nameField = form.$('name');
        const phoneField = form.$('phone');
        const dealTypeField = form.$('dealType');
        const exportTargetField = form.$('exportTarget');
        const notebookField = form.$('notebookId');
        const fieldsField = form.$('fields');
        const ignoreIncorrectField = form.$('ignoreIncorrect');
        const addSourceField = form.$('addSource');
        const addNotesField = form.$('addNotes');
        const addTextField = form.$('addText');
        const noPhotoField = form.$('no-photo');

        const handleExportTypeIdChange = ({ change: { newValue, oldValue } }) => {
            const nextValue = Number(newValue);
            const prevValue = Number(oldValue);
            const passwordDisabled = nextValue >= EXPORT_TYPE.YANDEX
                && nextValue !== EXPORT_TYPE.PDF;
            const credentialsDisabled = nextValue !== EXPORT_TYPE.YANDEX;
            const extraFieldsHidden = ![
                EXPORT_TYPE.DOC,
                EXPORT_TYPE.OPENOFFICE_CALC,
                EXPORT_TYPE.OPENOFFICE_DOC,
            ].includes(nextValue);
            passwordField.resetValidation();
            passwordField.set('extra', {
                ...passwordField.get('extra'),
                hidden: passwordDisabled,
            });
            passwordField.set('disabled', passwordDisabled);
            passwordField.set('options', {
                validateDisabledFields: false,
            });
            [companyField, nameField, phoneField].forEach((field) => {
                field.resetValidation();
                field.set('extra', {
                    ...field.get('extra'),
                    hidden: credentialsDisabled,
                });
                field.set('disabled', credentialsDisabled);
                field.set('options', {
                    validateDisabledFields: false,
                });
            });
            [addTextField, addNotesField, addSourceField, ignoreIncorrectField].forEach((field) => {
                let isHidden = extraFieldsHidden;
                if (field.name === 'addNotes' && this.storagePrefix === 'operator') {
                    isHidden = true;
                }
                field.resetValidation();
                field.set('extra', {
                    ...field.get('extra'),
                    hidden: isHidden,
                });
                field.set('disabled', isHidden);
                field.set('options', {
                    validateDisabledFields: false,
                });
            });
            if (prevValue && prevValue !== nextValue) {
                this.getExportFields(nextValue)
                    .then(({ attributesForExport, canSelectAttributes }) => {
                        this.setExtraParams({ attributesForExport, canSelectAttributes });
                        this.updateForm();
                    });
            }

            const isNoPhotoDefaultSet = EXPORT_OBJECTS_FORMATS_TO_SET_NO_PHOTO_AS_DEFAULT
                .includes(nextValue);
            noPhotoField.set(isNoPhotoDefaultSet);
        };
        const handleExportTargetChange = ({ change: { newValue } }) => {
            notebookField.set('disabled', newValue !== 'notebook');
        };
        exportTypeIdField.observe(handleExportTypeIdChange);
        dealTypeField.observe(() => this.updateFields());
        presetField.observe(() => this.updateFields());
        exportTargetField.observe(handleExportTargetChange);
        notebookField.observe(() => this.updateExportTargetCounts());
        fieldsField.observe(() => this.saveFormToStorage());
        companyField.observe(() => this.saveFormToStorage());
        handleExportTypeIdChange({ change: { newValue: exportTypeIdField.get('value') } });
    }

    prepareExportRequest(values) {
        const {
            list,
            list: { strategy },
            attributesForExport,
            exportPresets,
        } = this;
        const {
            exportTarget,
            exportTypeId,
            exportAttributes,
            password,
            addSource,
            addNotes,
            addText,
            ignoreIncorrect,
            'no-photo': noImages,
            noContactNames,
        } = values;
        const { objectType, dealType } = strategy;
        const meta = {
            objectType: OBJECT_TYPE_MAP[objectType],
            dealType: DEAL_TYPE_MAP[dealType],
        };
        const savedValues = this.getSavedFormFromStorage();
        let promise;
        if (exportTarget === 'all') {
            promise = strategy.prepareFilterData()
                .then((filterData) => Promise.resolve({ ...meta, filterData }));
        } else if (exportTarget === 'notebook') {
            const { notebookId } = values;
            const sortKey = 'sortData';
            let sortData;
            if (this.storagePrefix === 'client') {
                sortData = this.storage.session(sortKey);
            }

            if (this.storagePrefix === 'operator') {
                sortData = this.storage.get(sortKey);
            }

            let notebookSort = [];

            if (sortData) {
                const prefix = 'notepad';
                const filteredNotepadKeys = Object.keys(sortData)
                    .filter((key) => key.includes(prefix));

                notebookSort = filteredNotepadKeys
                    .map((key) => sortData[key])
                    .map(({
                        field,
                        direction,
                        objectType: notepadObjectType,
                        dealType: notepadDealType,
                    }) => ({
                        objectType: OBJECT_TYPE_MAP[notepadObjectType],
                        dealType: DEAL_TYPE_MAP[notepadDealType],
                        sort: [{
                            name: field,
                            order: direction,
                        }],
                    }));
            }

            promise = Promise.resolve({
                notebookId,
                notebookSort,
            });
        } else {
            promise = strategy.prepareFilterData()
                .then(({ sort }) => Promise.resolve({
                    ...meta,
                    sort,
                    ids: list.selected.map(({ id }) => id),
                }));
        }
        return promise
            .then((output) => ({
                ...output,
                exportTypeId,
                password,
                exportFieldPreset: EXPORT_PRESET_MAP[exportAttributes || 0],
                additional: {
                    ads: addSource,
                    note: addText,
                    userNote: addNotes,
                    noImages,
                    noContactNames,
                    ignoreIncorrect,
                },
                exportTypeParams: attributesForExport.reduce((carry, { key, attributes }) => {
                    /* eslint-disable no-param-reassign */
                    const { disabledAttributes } = exportPresets[exportAttributes || 0];
                    const saved = savedValues[key];
                    if (saved) {
                        carry[key] = saved.filter((field) => !disabledAttributes.includes(field));
                    } else {
                        carry[key] = attributes
                            .map(({ key: attrKey }) => attrKey)
                            .filter((field) => !disabledAttributes.includes(field));
                    }
                    /* eslint-enable no-param-reassign */
                    return carry;
                }, {}),
            }));
    }

    export(values) {
        return this.prepareExportRequest(values)
            .then((requestData) => {
                runInAction(() => {
                    this.exporting = true;
                    this.error = false;
                    this.link = '';
                });
                return this.makeExportRequest(requestData);
            })
            .then((link) => {
                runInAction(() => {
                    this.error = false;
                    this.link = link;
                });
                return Promise.resolve(link);
            })
            .catch(() => {
                runInAction(() => {
                    this.error = true;
                    this.link = '';
                });
                return Promise.resolve();
            })
            .finally(() => {
                setTimeout(() => {
                    runInAction(() => {
                        this.exporting = false;
                    });
                }, 300);
            });
    }

    makeExportRequest(data) {
        return this.dataSource.export(data)
            .then(({ status, link }) => (
                new Promise((resolve, reject) => {
                    if (status === EXPORT_STATUS.IN_PROGRESS) {
                        setTimeout(() => {
                            resolve(this.makeExportRequest(data));
                        }, REQUEST_REPEAT_INTERVAL);
                    } else if (status === EXPORT_STATUS.FAILED) {
                        reject(new Error('failed'));
                    } else {
                        resolve(link);
                    }
                })
            ));
    }
}

const createExportObjectsStore = () => new ExportObjects();

export default ExportObjects;
export {
    createExportObjectsStore,
};
