import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'filter',
    fields: [{
        hidden: true,
        type: 'checkboxList',
        name: 'status',
        size: 'md',
        rows: 1,
        options: [{
            value: 'active',
            label: 'Активен',
        }, {
            value: 'blocked',
            label: 'Отключен',
        }, {
            value: 'archived',
            label: 'В архиве',
        }],
    }, {
        name: 'companyIds',
        type: 'directorySelect',
        directory: DIRECTORIES.BILLING_COMPANIES,
        label: 'Компания',
        size: 'md',
        clearable: true,
        searchable: true,
    }, {
        name: 'query',
        type: 'text',
        label: 'Поиск',
        placeholder: 'Логин или ID компьютера',
        size: 'md',
        icon: 'search',
        rules: 'min:3',
    }],
}];
