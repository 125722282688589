import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Selected.module.scss';
import { EMPTY_DATA_STRING, POSITION } from '../../constants/shared';
import isTextOverflowActive from '../../utils/is-text-overflow-active';

/**
 * @param {string} str Tooltip content
 * @returns {object} Tooltip attributes
 */
function getAttributesForTooltip(str) {
    return {
        'data-for': 'selected-object-tooltip',
        'data-tip': str,
        'data-effect': 'float',
        'data-place': POSITION.RIGHT,
        'data-class': styles.selected__hint,
    };
}

/**
 * @param {Function} setState Ellipsed setter
 * @returns {Function} Ref callback
 */
function createQuestionMarkToggler(setState) {
    return (node) => {
        if (node) setState(isTextOverflowActive(node));
    };
}

const SelectedRowWithContent = ({
    classNames,
    name,
    children,
    value,
}) => {
    const nameStr = name || EMPTY_DATA_STRING;
    const [ellipsedName, setEllipsedName] = useState();
    const [ellipsedValue, setEllipsedValue] = useState();
    return (
        <div className={`${styles.selected__content} ${classNames?.row}`}>
            <div
                ref={createQuestionMarkToggler(setEllipsedName)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...ellipsedName ? getAttributesForTooltip(name) : {}}
                className={`${ellipsedName ? styles['selected__name--ellipsis'] : null} ${styles['selected__name--text']} ${classNames?.name}`}
            >
                {nameStr}
                {ellipsedName && (
                    <div
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getAttributesForTooltip(name)}
                        className={`${styles['selected__tooltip--name']} ${styles.selected__tooltip} ${classNames?.value}`}
                    >
                        ?
                    </div>
                )}
            </div>
            <div
                ref={createQuestionMarkToggler(setEllipsedValue)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...ellipsedValue ? getAttributesForTooltip(value) : {}}
                className={`${styles['selected__name--text']}  ${classNames?.value}`}
            >
                {value || EMPTY_DATA_STRING}
            </div>
            <div className={`${styles['selected__name--postfix']}  ${classNames?.value}`}>
                {children}
            </div>
        </div>
    );
};

SelectedRowWithContent.propTypes = {
    classNames: PropTypes.shape({
        row: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
    }),
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ]),
        ),
    ]),
};

SelectedRowWithContent.defaultProps = {
    classNames: {},
    name: '',
    value: '',
    children: null,
};

export default SelectedRowWithContent;
