import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { CrossBorderedIcon, CirclePlusIcon } from '../../../components/Icons/icons';
import placeholder from '../../../images/house-placeholder.png';

import styles from './ObjectComponent.module.scss';

export const Photos = ({
    photos,
    createImageClickHandler,
    createImageDeleteHandler,
    onImageDelete,
}) => (
    photos.map((photo, index) => (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
            className={`${styles['photo-item']}`}
            style={{
                backgroundImage: `url(${photo.src || placeholder})`,
            }}
            onClick={createImageClickHandler(index)}
            key={photo.id}
        >
            {
                photo.removeable && typeof onImageDelete === 'function'
                    ? (
                        <button
                            type="button"
                            onClick={createImageDeleteHandler(photo.id)}
                            className={`${styles['photo-item__button-remove']}`}
                        >
                            <SVGIcon
                                SVGElement={CrossBorderedIcon}
                            />
                        </button>
                    )
                    : null
            }
            {
                photo.description
                    ? (
                        <div
                            className={`${styles['photo-item__preview']}`}
                        >
                            <div
                                className={`${styles['photo-item__preview-text']}`}
                            >
                                {photo.description}
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
    ))
);

Photos.propTypes = {
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            removeable: PropTypes.bool,
            id: PropTypes.string,
            description: PropTypes.string,
        }),
    ),
    placeholder: PropTypes.string,
    createImageClickHandler: PropTypes.func,
    createImageDeleteHandler: PropTypes.func,
    onImageDelete: PropTypes.func,
};

Photos.defaultProps = {
    photos: [],
    createImageClickHandler: () => () => null,
    createDeleteClickHandler: () => () => null,
};

const ObjectComponentPhotos = ({
    photos,
    onImageUpload,
    onImageDelete,
    onImageClick,
}) => {
    const onDrop = useCallback((acceptedFiles = []) => {
        acceptedFiles.forEach((file) => onImageUpload(file));
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const handleImageClick = (index) => (e) => {
        e.preventDefault();
        if (!photos[index].src) return;
        onImageClick(index);
    };
    const deleteImage = (id) => (e) => {
        e.stopPropagation();
        onImageDelete(id);
    };
    return (
        <div className={`${styles.photos}`}>
            <h2 className={styles['block-title']}>
                <span>Фото</span>
            </h2>
            <div className={`${styles['photos-container']}`}>
                <Photos
                    photos={photos || []}
                    createImageClickHandler={handleImageClick}
                    createImageDeleteHandler={deleteImage}
                    onImageDelete={onImageDelete}
                />
                {
                    typeof onImageUpload !== 'function' && (!photos || !photos.length)
                        ? (
                            <div className={styles['photos-container__no-photo']}>
                                К сожалению, к данному объекту не прикреплено ни одной фотографии
                            </div>
                        )
                        : null
                }
                {
                    typeof onImageUpload === 'function'
                        ? (
                            <div
                                className={`
                                    ${styles['photo-item']}
                                    ${styles['photo-item--dropzone']}
                                `}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...getRootProps()}
                            >
                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <input {...getInputProps()} />
                                <div
                                    className={styles['photo-item--dropzone-inner']}
                                >
                                    <SVGIcon
                                        SVGElement={CirclePlusIcon}
                                        height={22}
                                    />
                                    <div>Добавить</div>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        </div>
    );
};

ObjectComponentPhotos.propTypes = {
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            description: PropTypes.string,
        }),
    ),
    onImageClick: PropTypes.func,
    onImageUpload: PropTypes.func,
    onImageDelete: PropTypes.func,
};

ObjectComponentPhotos.defaultProps = {
    photos: [],
    onImageClick: () => null,
    onImageUpload: () => null,
    onImageDelete: () => null,
};

export default ObjectComponentPhotos;
