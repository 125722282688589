import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { Button } from '../../../components/Button';

import styles from './ObjectList.module.scss';

const FilterHeader = ({ useStores }) => {
    const { objectListPageStore, presetListStore } = useStores();

    const handleBackClick = () => {
        presetListStore.preload();
        objectListPageStore.reset();
        objectListPageStore.setTab(1);
        presetListStore.reload();
    };

    return (
        <Observer>
            {() => (
                (objectListPageStore?.selected && (
                    <div className={`${styles.filter__header} ${styles['preset-header']}`}>
                        <div className={styles['preset-header__text']}>
                            Редактирование подборки
                            {' '}
                            <span className={styles['preset-header__name']}>
                                {objectListPageStore.selected.item.name}
                            </span>
                        </div>
                        <div className={styles['preset-header__back']}>
                            <Button
                                type={Button.TYPE.BUTTON}
                                color={Button.COLOR.WHITE_BLACK}
                                size={Button.SIZE.MEDIUM}
                                onClick={handleBackClick}
                                block
                            >
                                К подборкам
                            </Button>
                        </div>
                    </div>
                )) || null
            )}
        </Observer>
    );
};

FilterHeader.propTypes = {
    useStores: PropTypes.func,
};

FilterHeader.defaultProps = {
    useStores: () => null,
};

export default FilterHeader;
