import createQuery from '../../utils/create-query';
import { DIRECTORIES } from '../../../constants/operator';
import LOCAL_DATA from '../../../stores/shared/Directories/helpers/localDirectories';

const createDirectoryService = (request) => ({
    getList: (directory, ctx) => {
        if (directory === DIRECTORIES.LOCAL_DIRECTORIES.ROOMS_COUNT) {
            return Promise.resolve(LOCAL_DATA.ROOMS_COUNT);
        }
        const ctxQuery = createQuery(ctx);
        return request.get(`/directory/${directory}${ctxQuery ? `?${ctxQuery}` : ''}`)
            .then((response) => (
                response.map(({ id, name, ...rest }) => ({
                    value: id,
                    label: name || '\u0000',
                    extra: rest,
                }))
            ));
    },
    search: (directory, query, ctx = {}) => {
        const ctxQuery = createQuery(ctx);
        return request.get(`/directory/${directory}/search?${query ? `query=${query}&` : '&'}${ctxQuery}`)
            .then((response) => (
                response.map(({ id, name, ...rest }) => ({
                    value: id,
                    label: name || '\u0000',
                    extra: rest,
                }))
            ));
    },
    getDefault: (directory) => (
        request.get(`/directory/defaults/${directory}`)
            .then(({ id, name, ...rest }) => ({
                value: id,
                label: name,
                extra: rest,
            }))
    ),
    getDefaults: () => request.get('directory/defaults'),
    getIncorrectOptions: () => request.get('/directory/incorrect/options'),
});

export default createDirectoryService;
