import { ObjectService, StorageService } from '../../../services/client';
import ExportObjects from '../../shared/ExportObjects';

class ClientExportObjects extends ExportObjects {
    constructor(list, notepads) {
        super(ObjectService, StorageService, list, notepads);
        this.storagePrefix = 'client';
    }
}

export default ClientExportObjects;
