import forEachField, { forEachFieldGroup, forEachFieldset } from './for-each-field';

const find = (fieldType, fields, nameToFind) => {
    let output;
    const fn = (field) => {
        if (field.name && field.name === nameToFind) {
            output = field;
        }
    };
    switch (fieldType) {
        case 'fieldgroup':
            forEachFieldGroup(fields, fn);
            break;
        case 'fieldset':
            forEachFieldset(fields, fn);
            break;
        default:
            forEachField(fields, fn);
    }
    return output;
};

const findField = (fields, nameToFind) => find('field', fields, nameToFind);

const findFieldset = (fields, nameToFind) => find('fieldset', fields, nameToFind);

const findFieldGroup = (fields, nameToFind) => find('fieldgroup', fields, nameToFind);

export default findField;
export {
    findFieldset,
    findFieldGroup,
};
