import cloneDeep from 'lodash.clonedeep';
import { ObjectService } from '../../../services/client';
import { HouseDetailed } from '../../shared/RefactoredObjectDetailed';
import forms from '../../../forms/client/objects-detailed';
import { withBaseDetailed, withClient } from './mixins';

class ClientHouseDetailed extends HouseDetailed {
    constructor() {
        super(ObjectService);
        const { formName } = this;
        this.fields = cloneDeep(forms[formName]);
    }
}

export default withBaseDetailed(withClient(ClientHouseDetailed));
