import createBaseSessionService from '../../shared/SessionService';

const createSessionService = (request) => {
    const baseService = createBaseSessionService(request);
    return {
        ...baseService,
        login: ({ login, password }) => {
            const data = {};
            if (login.includes('@')) {
                data.email = login;
            } else {
                data.phone = login.replace(/[^\d+]*/g, '');
            }
            data.password = password;
            return baseService.login(data);
        },

        operatorInfo: () => request.get('/auth/me'),
    };
};

export default createSessionService;
