import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ACTIVATE_DEVICE_SCREEN_SHOW_LENGTH, ACTIVATE_DEVICE_STATE } from '../../../constants/client';
import useStores from '../../../hooks/client/use-stores';
import LoginPage from '../../shared/LoginPage';

import clientStyles from './LoginPage.module.scss';

const FingerprintReactivate = () => {
    const history = useHistory();
    const { deviceStore, sessionStore } = useStores();
    useEffect(() => {
        if (deviceStore.success !== undefined) {
            setTimeout(() => {
                sessionStore.authorize();
                history.push('/');
            }, ACTIVATE_DEVICE_SCREEN_SHOW_LENGTH);
        } else {
            const startTimestamp = Date.now();
            sessionStore.reactivateDevice()
                .then((reactivation) => {
                    const endTimestamp = Date.now();
                    const diff = endTimestamp - startTimestamp;
                    deviceStore.save();
                    setTimeout(
                        () => {
                            // TODO Проверка на частую переактивацию
                            // if (!reactivation || !reactivation.success) {
                            if (reactivation && !reactivation.success) {
                                sessionStore.logout();
                                history.push('/activate/block');
                                return;
                            }
                            sessionStore.authorize();
                            history.push('/');
                        },
                        diff > ACTIVATE_DEVICE_SCREEN_SHOW_LENGTH
                            ? 0
                            : ACTIVATE_DEVICE_SCREEN_SHOW_LENGTH - diff,
                    );
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        history.push('/');
                    }
                });
        }
    }, []);
    return (
        <>
            <LoginPage
                className={clientStyles.fingerprint}
                footer={null}
                title={(
                    <div className={clientStyles.fingerprint__title}>
                        <Observer>
                            {() => (
                                deviceStore.code === ACTIVATE_DEVICE_STATE.UNREGISTERED_DEVICE
                                    ? 'Попытка входа с незарегистрированного компьютера'
                                    : 'Идентификатор вашего компьютера изменился'
                            )}
                        </Observer>
                    </div>
                )}
            >
                <>
                    <div className={`${clientStyles.preloader} ${clientStyles['preloader--top']}`}>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className={clientStyles.fingerprint__description}>
                        <Observer>
                            {() => (
                                deviceStore.code === ACTIVATE_DEVICE_STATE.UNREGISTERED_DEVICE
                                    ? 'Выполняется активация приложения на новом устройстве...'
                                    : 'Производим повторную активацию...'
                            )}
                        </Observer>
                    </div>
                    <Observer>
                        {() => (
                            deviceStore.remaining !== undefined
                                ? (
                                    <div className={clientStyles.fingerprint__left}>
                                        Осталось
                                        &nbsp;
                                        <b>{deviceStore.remaining}</b>
                                        &nbsp;
                                        переактивации в этом месяце
                                    </div>
                                ) : null
                        )}
                    </Observer>
                </>
            </LoginPage>
        </>
    );
};

export default FingerprintReactivate;
