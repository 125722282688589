import React from 'react';
import { SelectedRow, SelectedRowWithHint } from '../../../components/TableSelected';
import { defaultProps, propTypes } from './propTypes';

import styles from './SelectedObject.module.scss';

const SelectedSaleHouse = ({
    store,
}) => {
    const landArea = store.landArea ? String(store.landArea).replace(/$/, ' сот.') : store.landArea;
    return (
        <>
            <SelectedRow name="Пл.участка" classNames={{ row: styles.selected__row }}>
                {landArea}
            </SelectedRow>
            <SelectedRowWithHint name="Состояние" classNames={{ row: styles.selected__row }}>
                {store.objectCondition?.label}
            </SelectedRowWithHint>
            <SelectedRowWithHint name="Отопление" classNames={{ row: styles.selected__row }}>
                {store.heating?.label}
            </SelectedRowWithHint>
            <SelectedRowWithHint name="Слив" classNames={{ row: styles.selected__row }}>
                {store.effluent?.label}
            </SelectedRowWithHint>
            <SelectedRowWithHint name="Вода" classNames={{ row: styles.selected__row }}>
                {store.waterSupply?.label}
            </SelectedRowWithHint>
        </>
    );
};

SelectedSaleHouse.propTypes = { store: propTypes.store };
SelectedSaleHouse.defaultProps = { store: defaultProps.store };

export default SelectedSaleHouse;
