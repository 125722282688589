import SearchableDirectory from './SearchableDirectory';
import clearObject from '../../../../utils/clear-object';

class HouseDirectory extends SearchableDirectory {
    minQueryLength = 1;

    loadData(query = '', ctx = {}) {
        const { number, ...predicate } = HouseDirectory.parseQueryString(query);
        return super.loadData(number, { ...ctx, ...clearObject(predicate) })
            .then((response) => {
                const list = response.map(({
                    value,
                    extra,
                }) => ({
                    value,
                    extra,
                    label: HouseDirectory.getFullHouseName(extra),
                }));
                const map = {};
                const output = [];
                list.forEach((house) => {
                    const { label, value } = house;
                    if (!map[label]) {
                        map[label] = output.push(house);
                        return;
                    }
                    const index = map[label] - 1;
                    const stored = output[index].value;
                    output[index].value = Array.isArray(stored)
                        ? [...stored, value]
                        : [stored, value];
                });
                return Promise.resolve(output);
            })
            .catch(() => Promise.reject());
    }

    static getFullHouseName({ number, structureNumber, buildingNumber } = {}) {
        const output = [];
        if (number) output.push(`д. ${number}`);
        if (buildingNumber) output.push(`к. ${buildingNumber}`);
        if (structureNumber) output.push(`стр. ${structureNumber}`);
        return output.join(', ');
    }

    static parseQueryString(query) {
        const reg = /\s?([а-яА-Я.]+)?\s?([\d/.-]+([а-яА-Я]*)?)/g;
        const structure = ['с', 'ст', 'стр', 'строение'];
        const building = ['к', 'кор', 'корп', 'корпус'];
        const house = ['д', 'дом'];
        const [...matches] = query.matchAll(reg);
        const result = {
            number: '',
            buildingNumber: '',
            structureNumber: '',
        };
        for (let i = 0; i < matches.length; i += 1) {
            const type = matches[i][1];
            const number = matches[i][2];
            if ((type && house.includes(type)) || i === 0) {
                result.number = number;
            }
            if (type && structure.includes(type)) {
                result.structureNumber = number;
            }
            if (type && building.includes(type)) {
                result.buildingNumber = number;
            }
        }
        return result;
    }
}

export default HouseDirectory;
