import { observable, action } from 'mobx';
import MortgageCalculator from '../../shared/MortgageCalculator';

class HeaderToolsStore {
    // Target object
    @observable
    target = null;

    constructor() {
        this.mortgageCalculator = new MortgageCalculator();
    }

    @action
    setTarget(target) {
        const { mortgageCalculator } = this;
        this.target = target;
        if (!target) {
            return;
        }
        mortgageCalculator.setPrice(target.price);
    }
}

const createHeaderToolsStore = () => new HeaderToolsStore();

export default HeaderToolsStore;
export {
    createHeaderToolsStore,
};
