import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CheckboxList } from '../../../components/Input';
import Preloader from '../../../components/Preloader';

import styles from './Directory.module.scss';

const DirectoryCheckboxList = ({ useStores, directory, ...props }) => {
    const { directoriesStore } = useStores();
    const store = directoriesStore[directory];
    useEffect(() => { store.loadData(); });
    return (
        <Observer>
            {() => (
                store.loaded
                    ? (
                        <CheckboxList
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props.extra}
                            options={store.options}
                        />
                    )
                    : (
                        <div className={styles['tag-list-preloader']}>
                            <Preloader
                                width={21}
                            />
                        </div>
                    )
            )}
        </Observer>
    );
};

DirectoryCheckboxList.propTypes = {
    extra: PropTypes.shape(),
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
    useStores: PropTypes.func.isRequired,
    directory: PropTypes.string.isRequired,
};

DirectoryCheckboxList.defaultProps = {
    extra: {
        disableSelected: false,
    },
};

export default DirectoryCheckboxList;
