import React from 'react';
import Dialog from '../Dialog';
import PaymentForm from './PaymentForm';
import { SIZE } from '../../../constants/shared';
import styles from './PaymentForm.module.scss';

const PaymentFormModal = () => (
    <Dialog
        name="payment-dialog"
        size={SIZE.MEDIUM}
        className={styles['payment-dialog']}
    >
        <PaymentForm title="Оплата" />
    </Dialog>
);

export default PaymentFormModal;
