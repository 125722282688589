import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PAYMENT_STATUS } from '../../../constants/client';
import { PaymentService } from '../../../services/client';
import { ErrorMessageIcon, SuccessMessageIcon } from '../../../components/Icons/icons';
import Preloader from '../../../components/Preloader';
import SVGIcon from '../../../components/Icons/SVGIcon';
import styles from './PaymentStatus.module.scss';

const PaymentStatus = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [lastPaymentData, setLastPaymentData] = useState({});

    useEffect(() => {
        setIsLoading(true);
        PaymentService.getPaymentStatus()
            .then(setLastPaymentData)
            .catch(() => setLastPaymentData(null))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return (
            <div className={styles.root}>
                <Preloader />
            </div>
        );
    }

    if (!lastPaymentData) {
        return (
            <div className={styles.root}>
                <SVGIcon height={124} SVGElement={ErrorMessageIcon} />
                <h1>Данные не найдены</h1>
                <Link className="link" to="/list">Вернуться в личный кабинет</Link>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            {(lastPaymentData.code === PAYMENT_STATUS.FAIL
                || lastPaymentData.code === PAYMENT_STATUS.PENDING) && (
                <SVGIcon height={124} SVGElement={ErrorMessageIcon} />
            )}
            {lastPaymentData.code === PAYMENT_STATUS.SUCCESS && (
                <SVGIcon height={124} SVGElement={SuccessMessageIcon} />
            )}

            <h1>{lastPaymentData.status}</h1>

            <Link className="link" to="/list">Вернуться в личный кабинет</Link>
        </div>
    );
};

export default PaymentStatus;
