import React from 'react';
import PropTypes from 'prop-types';

import styles from './Label.module.scss';

const Label = ({
    className,
    htmlFor,
    children,
}) => (
    <label
        className={`
            ${styles.label}
            ${className}
        `}
        htmlFor={htmlFor}
    >
        {children}
    </label>
);

Label.propTypes = {
    className: PropTypes.string,
    htmlFor: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
};

Label.defaultProps = {
    htmlFor: '',
    className: '',
    children: null,
};

export default Label;
