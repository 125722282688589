import React from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MultipleTabsPopup from '../../../components/MultipleTabsPopup';
import { Button, ButtonClose } from '../../../components/Button';
import EditObject from '../EditObject';
import { RelatedObjects } from '../RelatedObjects';
import { SimilarObjects } from '../SimilarObjects';
import { NotepadObjects } from '../NotepadObjects';
import CreateObject from '../CreateObject';
import SearchByRadius from '../SearchByRadius';
import PopupTabsStore from '../../../stores/shared/PopupTabsStore';
import { TAB_TYPE } from '../../../constants/shared';

import styles from './PagePopups.module.scss';

const PagePopups = ({
    createDetailed,
    createRelated,
    tabsStore,
    useStores,
    onClose,
}) => {
    // Метод для закрытия попапа (по умолчанию)
    const handleClose = (id) => {
        tabsStore.removeTab(id);
        if (onClose && typeof onClose === 'function') {
            onClose();
        }
    };
    // Метод для закрытия попапа объекта
    // (редактирование или создание)
    const onObjectClose = (id, store) => {
        if (id === 'radius-tab') {
            tabsStore.removeAllTabs();
            return;
        }
        const { form } = store;
        const targetTab = tabsStore.getTab(id);
        switch (true) {
            case (!targetTab.approveIsActive && form.snapshotIsDiffer):
                tabsStore.toggleApproveTab(id);
                break;
            case (targetTab.approveIsActive && form.snapshotIsDiffer):
                break;
            default:
                tabsStore.removeTab(id);
                if (onClose && typeof onClose === 'function') {
                    onClose();
                }
                break;
        }
    };
    const handleTabClick = (index) => {
        tabsStore.setSelectedIndex(index);
    };
    const { tabs } = tabsStore;
    return (
        <Observer>
            {() => (
                <MultipleTabsPopup
                    selectedIndex={tabsStore.selectedIndex}
                    onTabClick={handleTabClick}
                    tabs={
                        tabs.map(({
                            id, title, store, type, options = {},
                        }) => {
                            switch (type) {
                                case TAB_TYPE.EDIT_OBJECT:
                                case TAB_TYPE.VIEW_OBJECT:
                                    return ({
                                        id,
                                        title: (
                                            <span>
                                                {title}
                                                <ButtonClose
                                                    className={styles['page-popups__close']}
                                                    onClick={() => onObjectClose(id, store)}
                                                    size={Button.SIZE.SMALL}
                                                />
                                            </span>
                                        ),
                                        content: (
                                            <EditObject
                                                useStores={useStores}
                                                id={id}
                                                onObjectClose={() => onObjectClose(id, store)}
                                                onClose={() => handleClose(id)}
                                                store={store}
                                                tabsStore={tabsStore}
                                                view={!store.canEdit}
                                                contentMap={options.contentMap}
                                            />
                                        ),
                                    });
                                case TAB_TYPE.RELATED_OBJECTS:
                                    return ({
                                        id,
                                        title: (
                                            <span>
                                                Связанные объекты
                                                <ButtonClose
                                                    onClick={() => handleClose(id)}
                                                    size={Button.SIZE.SMALL}
                                                />
                                            </span>
                                        ),
                                        content: (
                                            <RelatedObjects
                                                useStores={useStores}
                                                id={id}
                                                onClose={() => handleClose(id)}
                                                store={store}
                                                contentMaps={options.contentMaps}
                                            />
                                        ),
                                    });
                                case TAB_TYPE.SIMILAR_OBJECTS:
                                    return ({
                                        id,
                                        title: (
                                            <span>
                                                Похожие объекты
                                                <ButtonClose
                                                    onClick={() => handleClose(id)}
                                                    size={Button.SIZE.SMALL}
                                                />
                                            </span>
                                        ),
                                        content: (
                                            <SimilarObjects
                                                useStores={useStores}
                                                id={id}
                                                onClose={() => handleClose(id)}
                                                store={store}
                                                contentMaps={options.contentMaps}
                                            />
                                        ),
                                    });
                                case TAB_TYPE.NOTEPAD_OBJECTS:
                                    return ({
                                        id,
                                        title: (
                                            <span>
                                                Блокнот
                                                <ButtonClose
                                                    onClick={() => handleClose(id)}
                                                    size={Button.SIZE.SMALL}
                                                />
                                            </span>
                                        ),
                                        content: (
                                            <NotepadObjects
                                                useStores={useStores}
                                                id={id}
                                                onClose={() => handleClose(id)}
                                                store={store}
                                                contentMaps={options.contentMaps}
                                                createRelated={createRelated}
                                            />
                                        ),
                                    });
                                case TAB_TYPE.CREATE_OBJECT:
                                    return ({
                                        id,
                                        title: (
                                            <span>
                                                {title}
                                                <ButtonClose
                                                    onClick={() => onObjectClose(id, store)}
                                                    size={Button.SIZE.SMALL}
                                                />
                                            </span>
                                        ),
                                        content: (
                                            <CreateObject
                                                useStores={useStores}
                                                id={id}
                                                onObjectClose={() => onObjectClose(id, store)}
                                                onClose={() => handleClose(id)}
                                                store={store}
                                                tabsStore={tabsStore}
                                                contentMap={options.contentMap}
                                            />
                                        ),
                                    });
                                case TAB_TYPE.SEARCH_BY_RADIUS:
                                    return ({
                                        id,
                                        forceRender: true,
                                        title: (
                                            <span>
                                                {title}
                                                <ButtonClose
                                                    onClick={() => onObjectClose(id, store)}
                                                    size={Button.SIZE.SMALL}
                                                />
                                            </span>
                                        ),
                                        content: (
                                            <SearchByRadius
                                                store={store}
                                                useStores={useStores}
                                                tabsStore={tabsStore}
                                                onObjectClose={() => onObjectClose(id, store)}
                                                createDetailed={createDetailed}
                                            />
                                        ),
                                    });
                                default:
                                    return null;
                            }
                        })
                    }
                />
            )}
        </Observer>
    );
};

PagePopups.propTypes = {
    createDetailed: PropTypes.func,
    createRelated: PropTypes.func,
    useStores: PropTypes.func,
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    onClose: PropTypes.func,
};

PagePopups.defaultProps = {
    createDetailed: () => null,
    createRelated: () => null,
    useStores: () => {},
    onClose: undefined,
};

export default PagePopups;
