import flats from './flats';
import grouping from './grouping';
import houses from './houses';
import steads from './steads';
import commercial from './commercial';
import dateAdded from './date-added';
import dateCreated from './date-created';
import sourceBoard from './source-board';

export default {
    flats,
    grouping,
    houses,
    steads,
    commercial,
    dateAdded,
    dateCreated,
    sourceBoard,
};
