import { computed } from 'mobx';
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter';

const withRentDetailed = (Store) => (
    class extends Store {
        dealType = 'rent';

        @computed
        get formName() {
            const { objectType } = this;
            return `rent${capitalizeFirstLetter(objectType)}`;
        }

        @computed
        get numberOfBeds() {
            const { $rent } = this;
            if (!$rent || !$rent.numberOfBeds) {
                return null;
            }
            return $rent.numberOfBeds;
        }

        @computed
        get furniture() {
            const { $rent } = this;
            if (!$rent || !$rent.furniture) {
                return [];
            }
            return $rent.furniture.map(({ id, name }) => ({
                value: id,
                label: name,
            }));
        }

        @computed
        get conditions() {
            const { $rent } = this;
            if (!$rent || !$rent.conditions) {
                return [];
            }
            return $rent.conditions.map(({ id, name }) => ({
                value: id,
                label: name,
            }));
        }

        @computed
        get hasRefrigerator() {
            const { $rent } = this;
            if (!$rent || !$rent.hasRefrigerator) {
                return false;
            }
            return Boolean($rent.hasRefrigerator);
        }

        @computed
        get hasTv() {
            const { $rent } = this;
            if (!$rent || !$rent.hasTv) {
                return false;
            }
            return Boolean($rent.hasTv);
        }

        @computed
        get hasWasher() {
            const { $rent } = this;
            if (!$rent || !$rent.hasWasher) {
                return false;
            }
            return Boolean($rent.hasWasher);
        }

        @computed
        get rentFlatType() {
            const { $rent } = this;
            if (!$rent || !$rent.rentFlatType) {
                return null;
            }
            return {
                value: $rent.rentFlatType.id,
                label: $rent.rentFlatType.name,
            };
        }

        @computed
        get rentService() {
            const { $rent } = this;
            if (!$rent || !$rent.rentService) {
                return null;
            }
            return {
                value: $rent.rentService.id,
                label: $rent.rentService.name,
            };
        }

        @computed
        get rentPeriod() {
            const { $rent } = this;
            if (!$rent || !$rent.rentPeriod) {
                return null;
            }
            return {
                value: $rent.rentPeriod.id,
                label: $rent.rentPeriod.name,
            };
        }

        @computed
        get requiresDeposit() {
            const { $rent } = this;
            if (!$rent || !$rent.requiresDeposit) {
                return false;
            }
            return Boolean($rent.requiresDeposit);
        }

        @computed
        get furnishing() {
            const { $rent, $furnishing } = this;
            if (!$rent || !$rent.furnishing) {
                return $furnishing
                    ? {
                        value: $furnishing.id,
                        label: $furnishing.name,
                    }
                    : null;
            }
            return {
                value: $rent.furnishing.id,
                label: $rent.furnishing.name,
            };
        }

        prepareRequest(values) {
            const prepared = super.prepareRequest(values);
            const updated = {
                ...prepared,
                price: Math.round(prepared.price),
                unitPrice: Math.round(prepared.unitPrice),
            };
            const { objectType } = this;
            switch (objectType) {
                case 'flats':
                    return {
                        ...updated,
                        dealType: 'rent',
                        rent: {
                            rentFlatTypeId: values.rentFlatType,
                            rentPeriodId: values.rentPeriod,
                            rentServiceId: values.rentService,
                            requiresDeposit: values.requiresDeposit,
                            numberOfBeds: values.numberOfBeds,
                            hasWasher: values.hasWasher,
                            hasTv: values.hasTv,
                            hasRefrigerator: values.hasRefrigerator,
                            rentConditionIds: values.conditions || [],
                            furnitureIds: values.furniture || [],
                        },
                    };
                case 'houses':
                    return {
                        ...updated,
                        dealType: 'rent',
                        rent: {
                            rentConditionIds: values.conditions || [],
                            rentPeriodId: values.rentPeriod,
                            rentServiceId: values.rentService,
                            requiresDeposit: values.requiresDeposit,
                        },
                    };
                case 'commercial':
                    return {
                        ...updated,
                        dealType: 'rent',
                        rent: {
                            furnishingId: values.furnishing,
                            rentServiceId: values.rentService,
                            requiresDeposit: values.requiresDeposit,
                        },
                    };
                default:
                    return {
                        ...updated,
                        dealType: 'rent',
                    };
            }
        }

        toItem() {
            const item = super.toItem();
            return {
                ...item,
                rent: this.$rent,
            };
        }
    }
);

export default withRentDetailed;
