import React from 'react';
import PropTypes from 'prop-types';

import styles from './LoginPage.module.scss';

const LoginPage = ({
    title,
    description,
    children,
    footer,
    className,
}) => (
    <>
        <div className={styles.login__wrapper}>
            <div className={`${styles.login__decorate} ${styles['login__decorate--roof']}`} />
            <div className={`${styles.login__decorate} ${styles['login__decorate--main']} ${className}`}>
                <div className={styles.login__header}>
                    {title}
                    {
                        description
                            ? (
                                <div className={styles.login__description}>
                                    {description}
                                </div>
                            )
                            : null
                    }
                </div>
                <div className={styles.login__body}>
                    {children}
                </div>
            </div>
            <div className={`${styles.login__decorate} ${styles['login__decorate--base']}`} />
        </div>
        {
            footer
                ? (
                    <div className={styles.login__footer}>
                        {footer}
                    </div>
                )
                : null
        }
    </>
);

LoginPage.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
    ]),
    description: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]),
    footer: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]),
    className: PropTypes.string,
};

LoginPage.defaultProps = {
    title: '',
    description: '',
    children: null,
    footer: null,
    className: '',
};

export default LoginPage;
