import { DIRECTORIES } from '../../../constants/operator';

export default {
    label: 'Газ/электро',
    name: 'stoveTypeIds',
    type: 'directorySelect',
    directory: DIRECTORIES.STOVE_TYPE,
    multiple: true,
    clearable: true,
    isDropdown: true,
};
