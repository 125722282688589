import DynamicStructure from '../../DynamicStructure';

let count = 0;

class ListItem extends DynamicStructure {
    keys = [];

    constructor(data, parent) {
        super();
        if (!data.id) {
            this.set('id', count);
            count += 1;
        }
        this.parent = parent;
        this.setData(data);
    }

    get data() {
        const data = {};
        this.keys.forEach((key) => {
            data[key] = this[key];
        });
        return data;
    }
}

export default ListItem;
