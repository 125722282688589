import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PopupTabsStore from '../../stores/shared/PopupTabsStore';

import { Button } from '../Button';
import { EMPTY_DATA_STRING, TAB_TYPE } from '../../constants/shared';

import styles from './Radius.module.scss';

const Radius = (props) => {
    const {
        id,
        form,
        value,
        tabsStore,
        className,
    } = props;

    const [address, setAddress] = useState('');

    useEffect(() => {
        form.$('address').observe(({ change }) => {
            const { newValue } = change;
            setAddress(newValue);
        });
    }, []);

    const handleClick = () => {
        const tabId = 'radius-tab';
        if (!tabsStore.getTab(tabId)) {
            tabsStore.addTab(
                { form },
                tabId,
                'Поиск по радиусу',
                TAB_TYPE.SEARCH_BY_RADIUS,
            );
        }
    };

    const handleClear = () => {
        form.$('radius').set('value', 0);
        form.$('address').set('value', '');
        form.$('latitude').set('value', 0);
        form.$('longitude').set('value', 0);
    };

    return (
        <div
            id={id}
            className={`${styles.radius} ${className}`}
        >
            {value ? (
                <div className={styles.radius__value}>
                    <span>Радиус:</span>
                    &nbsp;
                    {value}
                    <br />
                    <span>Центральная точка:</span>
                    &nbsp;
                    {address || EMPTY_DATA_STRING}
                    <br />
                </div>
            ) : (
                <div className={styles.radius__placeholder}>
                    Ничего не выбрано
                </div>
            )}
            <div className={styles.radius__controls}>
                <Button
                    size={Button.SIZE.MEDIUM}
                    block={!value}
                    color={Button.COLOR.BLUE}
                    onClick={handleClick}
                >
                    {value ? 'Изменить выбор' : 'Выбрать на карте'}
                </Button>
                {!!value && (
                    <Button
                        size={Button.SIZE.MEDIUM}
                        color={Button.COLOR.WHITE}
                        onClick={handleClear}
                    >
                        Сбросить
                    </Button>
                )}
            </div>
        </div>
    );
};

Radius.propTypes = {
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    form: PropTypes.shape({
        $: PropTypes.func,
        onSubmit: PropTypes.func,
        render: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
};

Radius.defaultProps = {
    id: '',
    className: '',
    value: undefined,
};

export default Radius;
