/* global window */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import useStores from '../../../hooks/client/use-stores';
import Preloader from '../../../components/Preloader';
import FormElements from '../../shared/Form';
import styles from './PaymentForm.module.scss';
import { SIZE } from '../../../constants/shared';
import { Button } from '../../../components/Button';
import { PAYMENT_STATUS } from '../../../constants/client';
import { LoginPageField } from '../../shared/LoginPage';
import { ErrorMessageIcon, SuccessMessageIcon } from '../../../components/Icons/icons';

const PaymentForm = (props) => {
    const { title, onLogout } = props;
    const { sessionStore } = useStores();
    const { paymentService, paymentForm, paymentDebt } = sessionStore.paymentInfo;

    const [paymentData, setPaymentData] = useState(null);

    const getPaymentStatus = () => {
        paymentService.getPaymentStatus()
            .then((data) => {
                setPaymentData(data);
                if (data.code === PAYMENT_STATUS.PENDING) {
                    setTimeout(() => getPaymentStatus(), 1000);
                }
                return data;
            })
            .catch(() => setPaymentData({
                code: PAYMENT_STATUS.FAIL,
                status: 'Ошибка при попытке получить данные об оплате',
            }));
    };

    useEffect(() => {
        sessionStore.paymentInfo.afterSubmit = () => {
            getPaymentStatus();
        };
    }, []);

    if (!paymentForm) {
        window.location.href = '/';
    }

    if (paymentData) {
        return (
            <PaymentData
                title={paymentData.status}
                status={paymentData.code}
            />
        );
    }

    return (
        <form onSubmit={paymentForm.onSubmit} className={styles.form}>
            <h1>{title}</h1>

            <div className={styles.form__fields}>
                <FormElements
                    form={paymentForm}
                    elements={paymentForm.render}
                    components={{
                        FieldWrapper: LoginPageField,
                    }}
                />
            </div>

            {!!paymentDebt && (
                <div className={styles.form__debt}>
                    * У вас есть долг в размере
                    <b>
                        &nbsp;
                        {paymentDebt}
                        &nbsp;
                        ₽
                    </b>
                </div>
            )}

            <div className={styles.form__submit}>
                <Button type="submit" size={SIZE.LARGE} disabled={paymentForm.loading}>
                    <div className={styles.form__preload}>
                        <span>Оплатить</span>
                        {paymentForm.loading && <Preloader width={20} />}
                    </div>
                </Button>
                {!!onLogout && (
                    <div className={styles.form__forgot}>
                        <button className="link" type="button" onClick={onLogout}>
                            Выйти
                        </button>
                    </div>
                )}
            </div>
        </form>
    );
};

PaymentForm.propTypes = {
    title: PropTypes.string.isRequired,
    onLogout: PropTypes.func,
};

PaymentForm.defaultProps = {
    onLogout: undefined,
};

const PaymentData = (props) => {
    const { title, status } = props;

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <form className={styles.form}>
            {status === PAYMENT_STATUS.FAIL && (
                <SVGIcon height={62} SVGElement={ErrorMessageIcon} className={styles['payment-dialog__icon']} />
            )}
            {status === PAYMENT_STATUS.SUCCESS && (
                <SVGIcon height={62} SVGElement={SuccessMessageIcon} className={styles['payment-dialog__icon']} />
            )}
            {status === PAYMENT_STATUS.PENDING && (
                <Preloader className={styles['payment-dialog__loader']} />
            )}

            <p className={styles['payment-dialog__status']}>
                {title}
            </p>

            <div className={styles.form__submit}>
                <Button size={SIZE.LARGE} onClick={reloadPage}>
                    <span>Завершить</span>
                </Button>
            </div>
        </form>
    );
};

PaymentData.propTypes = {
    title: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(PAYMENT_STATUS)).isRequired,
};

export default PaymentForm;
