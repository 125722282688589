import {
    DIRECTORIES,
    TABLE_CELL,
    DEFAULT_DATE_FORMAT,
    SCROLL_WIDTH_LAG,
    THUMBNAIL_SLIDER,
    STATUS_FORM,
    EMPTY_DATA_STRING,
    PRICE_DELIMITER,
    PRICE_FLOAT_CHARS,
    DEFAULT_CENTER_POSITION,
    MAP_TILE_LAYER_URL,
    DADATA_GEODATA_URL,
    SIZE,
    POSITION,
    TAB_TYPE,
    SORT_ORDER,
    OBJECT_TYPE_NAME,
    DEAL_TYPE_NAME,
    OBJECT_TYPE,
    DEAL_TYPE,
    NOTEPAD_MESSAGES,
    TOKEN_UPDATE_LAG,
    DEAL_TYPE_MAP,
    OBJECT_TYPE_MAP,
    EXPORT_TYPE,
    EXPORT_STATUS,
    REQUEST_REPEAT_INTERVAL,
} from '../shared';

const PAGE_404 = '/404';

const CLIENT_STATUS = {
    ACTIVE: 'ACTIVE',
    UNPAID: 'UNPAID',
    BLOCKED: 'BLOCKED',
    ARCHIVED: 'ARCHIVED',
};

const PAYMENT_STATUS = {
    FAIL: 'fail',
    PENDING: 'inProcess',
    SUCCESS: 'success',
};

const OBJECT_CONTENT_MAP = [
    { name: 'user-note', label: '', hiddenInSideMenu: true },
    { name: 'location', label: 'Расположение' },
    { name: 'basicInfo', label: 'Основная информация' },
    { name: 'additionalInfo', label: 'Доп. информация' },
    { name: 'photos', label: 'Фото' },
    { name: 'video', label: 'Ссылка на видео', hiddenInSideMenu: true },
    { name: 'note', label: 'Текст объявления' },
    { name: 'map', label: 'Карта' },
    { name: 'sources', label: 'Источники' },
    { name: 'contacts', label: 'Контакты' },
    { name: 'incorrect', label: 'Некорректность' },
];

const EXCLUSIVE_OBJECT_CONTENT_MAP = [
    { name: 'user-note', label: '', hiddenInSideMenu: true },
    { name: 'location', label: 'Расположение' },
    { name: 'basicInfo', label: 'Основная информация' },
    { name: 'additionalInfo', label: 'Доп. информация' },
    { name: 'photos', label: 'Фото' },
    { name: 'video', label: 'Ссылка на видео', hiddenInSideMenu: true },
    { name: 'note', label: 'Текст объявления' },
    { name: 'map', label: 'Карта' },
    { name: 'company', label: 'Компания' },
    { name: 'contacts', label: 'Контакты' },
    { name: 'incorrect', label: 'Некорректность' },
];

const ACTIVATE_DEVICE_SCREEN_SHOW_LENGTH = 5000;
const UPDATE_FINGERPRINT_INTERVAL = 60 * 60 * 10 ** 3;
const ACTIVATE_DEVICE_STATE = {
    DEVICE_PARAMS_CHANGED: 'DEVICE_PARAMS_CHANGED',
    UNREGISTERED_DEVICE: 'UNREGISTERED_DEVICE',
    NO_REACTIVATION_AVAILABLE: 'NO_REACTIVATION_AVAILABLE',
};

const SOCIAL_NETWORK_NAME = {
    vk: 'ВКонтакте',
    ok: 'Одноклассники',
};

export {
    DIRECTORIES,
    TABLE_CELL,
    PAGE_404,
    DEFAULT_DATE_FORMAT,
    SCROLL_WIDTH_LAG,
    THUMBNAIL_SLIDER,
    STATUS_FORM,
    EMPTY_DATA_STRING,
    PRICE_DELIMITER,
    PRICE_FLOAT_CHARS,
    DEFAULT_CENTER_POSITION,
    MAP_TILE_LAYER_URL,
    DADATA_GEODATA_URL,
    SIZE,
    POSITION,
    TAB_TYPE,
    SORT_ORDER,
    OBJECT_TYPE_NAME,
    DEAL_TYPE_NAME,
    OBJECT_TYPE,
    DEAL_TYPE,
    OBJECT_CONTENT_MAP,
    EXCLUSIVE_OBJECT_CONTENT_MAP,
    NOTEPAD_MESSAGES,
    TOKEN_UPDATE_LAG,
    ACTIVATE_DEVICE_SCREEN_SHOW_LENGTH,
    ACTIVATE_DEVICE_STATE,
    UPDATE_FINGERPRINT_INTERVAL,
    DEAL_TYPE_MAP,
    OBJECT_TYPE_MAP,
    EXPORT_TYPE,
    EXPORT_STATUS,
    REQUEST_REPEAT_INTERVAL,
    CLIENT_STATUS,
    PAYMENT_STATUS,
    SOCIAL_NETWORK_NAME,
};
