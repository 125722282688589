/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { POSITION } from '../../../constants/shared';

import styles from './Table.module.scss';

const CellSimilarity = ({
    value: { value, text },
    cell: { row: { original: { id } } },
    rowHint,
}) => (
    <div
        data-tip={`
            ${id}
            ${(
                rowHint
                    ? `<div class="${styles['table__row-hint']}">${rowHint}</div>`
                    : ''
            )}
        `}
        data-effect="solid"
        data-for="similarity"
        data-multiline
        data-place={POSITION.RIGHT}
        data-class={`
            ${styles.table__hint}
            ${rowHint ? styles['table__hint--multiline'] : ''}
        `}
        className={`
            ${styles.table__similarity}
            ${styles[`table__similarity--${value}`]}
        `}
    >
        {text}
    </div>
);

CellSimilarity.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        text: PropTypes.string,
    }).isRequired,
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                images: PropTypes.arrayOf(
                    PropTypes.shape({
                        src: PropTypes.string,
                    }),
                ),
            }),
        }),
    }).isRequired,
    rowHint: PropTypes.string,
};

CellSimilarity.defaultProps = {
    rowHint: null,
};

export default CellSimilarity;
