import React from 'react';
import PropTypes from 'prop-types';

import styles from './DetailedView.module.scss';

const DetailedViewWrapper = ({
    children,
    title,
}) => (
    <div className={`${styles.content}`}>
        <h2 className={`handle ${styles['content-title']}`}>
            {title}
        </h2>
        {children}
    </div>
);

DetailedViewWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
};

DetailedViewWrapper.defaultProps = {};

export default DetailedViewWrapper;
