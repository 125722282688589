/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { Select, DropdownSelect } from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';
import {
    Checkbox,
    CheckboxList,
    RadioList,
    Input,
    Textarea,
    InputList,
    TagList,
    Tag,
    Range,
    Rights,
    Companies,
    Radius,
} from '../../../components/Input';
import {
    DirectorySelect,
    DirectorySearch,
    DirectoryRadioList,
    DirectoryTagList,
    DirectoryClientSearch,
    DirectoryCheckboxList,
} from '../Directory';
import Contact from '../Directory/Contact';
import IconInput from './IconInput';
import PopupTabsStore from '../../../stores/shared/PopupTabsStore';

const renderField = (field) => {
    const { type, extra: { icon } } = field;
    switch (type) {
        case 'select':
            return Select;
        case 'radioList':
            return RadioList;
        case 'checkbox':
            return Checkbox;
        case 'checkboxList':
            return CheckboxList;
        case 'datepicker':
            return DatePicker;
        case 'textarea':
            return Textarea;
        case 'tagList':
            return TagList;
        case 'tag':
            return Tag;
        case 'directorySelect':
            return DirectorySelect;
        case 'directorySearch':
            return DirectorySearch;
        case 'directoryClientSearch':
            return DirectoryClientSearch;
        case 'directoryRadioList':
            return DirectoryRadioList;
        case 'directoryCheckboxList':
            return DirectoryCheckboxList;
        case 'directoryTagList':
            return DirectoryTagList;
        case 'contact':
            return Contact;
        case 'range':
            return Range;
        case 'dropdownSelect':
            return DropdownSelect;
        case 'rights':
            return Rights;
        case 'companies':
            return Companies;
        case 'radius':
            return Radius;
        default:
            if (icon) {
                return IconInput;
            }
            return Input;
    }
};

const DefaultFieldset = ({ children }) => (
    <fieldset>
        {children}
    </fieldset>
);

DefaultFieldset.propTypes = {
    children: PropTypes.element.isRequired,
};

const DefaultFieldGroup = ({ children }) => (
    <>
        {children}
    </>
);

DefaultFieldGroup.propTypes = {
    children: PropTypes.element.isRequired,
};

const DefaultField = ({ children }) => (
    <>
        {children}
    </>
);

DefaultField.propTypes = {
    children: PropTypes.element.isRequired,
};

const DefaultInputList = ({ children }) => (
    <>
        {children}
    </>
);

DefaultInputList.propTypes = {
    children: PropTypes.element.isRequired,
};

const Fieldset = ({
    useStores,
    form,
    fieldset,
    components,
    tabsStore,
}) => (
    <components.FieldsetWrapper fieldset={fieldset}>
        <FormElements
            useStores={useStores}
            form={form}
            elements={fieldset.fields}
            components={components}
            tabsStore={tabsStore}
        />
    </components.FieldsetWrapper>
);

Fieldset.propTypes = {
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({}).isRequired,
    fieldset: PropTypes.shape({
        fields: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
    components: PropTypes.shape({}).isRequired,
};

const FieldGroup = ({
    useStores,
    form,
    fieldgroup,
    components,
    tabsStore,
}) => (
    <components.FieldGroupWrapper fieldgroup={fieldgroup}>
        <FormElements
            useStores={useStores}
            form={form}
            elements={fieldgroup.fields}
            components={components}
            tabsStore={tabsStore}
        />
    </components.FieldGroupWrapper>
);

FieldGroup.propTypes = {
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({}).isRequired,
    fieldgroup: PropTypes.shape({
        fields: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
    components: PropTypes.shape({}).isRequired,
};

const FormElements = ({
    useStores,
    form,
    elements,
    components,
    tabsStore,
}) => {
    let wrappers = components;
    const qtyWrappers = 4;
    if (Object.keys(components).length < qtyWrappers) {
        wrappers = { ...FormElements.defaultProps.components, ...wrappers };
    }
    return elements.map((element, index) => {
        const {
            type,
            name = '',
        } = element;
        const key = `${type}-${name}-${index}`;
        let field;
        let FieldComponent;
        switch (type) {
            case 'fieldset':
                return (
                    <Fieldset
                        key={key}
                        form={form}
                        fieldset={element}
                        components={wrappers}
                        useStores={useStores}
                        tabsStore={tabsStore}
                    />
                );
            case 'fieldgroup':
                return (
                    <FieldGroup
                        key={key}
                        form={form}
                        fieldgroup={element}
                        components={wrappers}
                        useStores={useStores}
                        tabsStore={tabsStore}
                    />
                );
            case 'inputList':
                return (
                    <InputListComponent
                        key={key}
                        form={form}
                        fieldList={element}
                        components={components}
                        useStores={useStores}
                        tabsStore={tabsStore}
                    />
                );
            default:
                field = form.$(name);
                FieldComponent = renderField(field);
                return (
                    <Observer key={`${field.id}`}>
                        {() => {
                            const fieldProps = field.bind();
                            return (
                                <wrappers.FieldWrapper {...fieldProps} form={form}>
                                    <FieldComponent
                                        {...fieldProps}
                                        extra={field.extra}
                                        error={field.error}
                                        components={components}
                                        useStores={useStores}
                                        form={form}
                                        tabsStore={tabsStore}
                                    />
                                </wrappers.FieldWrapper>
                            );
                        }}
                    </Observer>
                );
        }
    });
};

FormElements.propTypes = {
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({
        $: PropTypes.func.isRequired,
    }).isRequired,
    elements: PropTypes.arrayOf(
        PropTypes.shape({}),
    ).isRequired,
    components: PropTypes.shape({
        FieldsetWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        FieldGroupWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        FieldWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        InputListWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
    }),
};

FormElements.defaultProps = {
    components: {
        FieldsetWrapper: DefaultFieldset,
        FieldGroupWrapper: DefaultFieldGroup,
        FieldWrapper: DefaultField,
        InputListWrapper: DefaultInputList,
    },
};

const InputListComponent = ({
    useStores,
    form,
    fieldList,
    components,
    tabsStore,
}) => (
    <components.InputListWrapper fieldList={fieldList}>
        <InputList
            size={fieldList.size}
            prefix={fieldList.prefix}
            postfix={fieldList.postfix}
        >
            {FormElements({
                useStores,
                form,
                elements: fieldList.fields,
                components,
                tabsStore,
            })}
        </InputList>
    </components.InputListWrapper>
);

InputListComponent.propTypes = {
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({}).isRequired,
    fieldList: PropTypes.shape({
        fields: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        size: PropTypes.string,
        prefix: PropTypes.string,
        postfix: PropTypes.string,
    }).isRequired,
    components: PropTypes.shape({}).isRequired,
};

export default FormElements;

export {
    renderField,
};
