import React from 'react';
import PropTypes from 'prop-types';

import styles from './PageView.module.scss';

const PageViewExtra = ({ children }) => (
    <div className={styles['page-view__extra']}>
        {children}
    </div>
);

PageViewExtra.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.func,
        PropTypes.element,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.func,
                PropTypes.element,
            ]),
        ),
    ]),
};

PageViewExtra.defaultProps = {
    children: 0,
};

export default PageViewExtra;
