import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';

import Dialog from '../Dialog';
import useStores from '../../../hooks/client/use-stores';
import Preloader from '../../../components/Preloader';
import ObjectListStore from '../../../stores/shared/RefactoredObjectList/ObjectList';
import { Button } from '../../../components/Button';
import { Photos } from '../../shared/ObjectComponent/ObjectComponentPhotos';
import { Textarea } from '../../../components/Input';
import { SOCIAL_NETWORK_NAME } from '../../../constants/client';

import styles from './SocialNetworks.module.scss';

const MAX_IMAGES_COUNT = 5;
const MAX_MESSAGE_LENGTH = 1000;

const SocialNetworksModal = (props) => {
    const { list } = props;
    const { dialogStore } = useStores();

    const closeDialog = () => {
        dialogStore.hide('social-network-dialog');
    };

    const handleClose = () => {
        list.socialNetworkLoading = false;
        list.socialNetworkPostData = null;
    };

    const handleSendPost = () => {
        list.publishSocialNetworkPost(list.socialNetworkPostData, closeDialog);
    };

    const handleChangePostText = (event) => {
        list.socialNetworkPostData.postData.info = event.target.value;
    };

    const handleDeletePostImage = (id) => () => {
        // eslint-disable-next-line operator-linebreak
        list.socialNetworkPostData.postData.images =
            list.socialNetworkPostData.postData.images.filter((image) => (
                image.id !== id
            ));
    };

    return (
        <Observer>
            {() => {
                const postData = list.socialNetworkPostData?.postData;
                const isImagesCountOverflow = postData?.images.length
                    > MAX_IMAGES_COUNT;
                const isMessageLengthOverflow = postData?.info.length
                    > MAX_MESSAGE_LENGTH;

                return (
                    <Dialog
                        name="social-network-dialog"
                        size="xlg"
                        onClose={handleClose}
                        className={styles['social-network-dialog']}
                        header={(
                            <div className={styles['social-network-dialog__header']}>
                                Создать пост
                                {' '}
                                {SOCIAL_NETWORK_NAME[list.socialNetworkPostData?.socialNetwork]}
                            </div>
                        )}
                        footer={(
                            <div className={styles['social-network-dialog__footer']}>
                                <Button
                                    type="reset"
                                    size={Button.SIZE.MEDIUM}
                                    color={Button.COLOR.WHITE}
                                    onClick={closeDialog}
                                    disabled={list.socialNetworkLoading}
                                >
                                    Отмена
                                </Button>
                                <Button
                                    size={Button.SIZE.LARGE}
                                    color={Button.COLOR.BLUE}
                                    onClick={handleSendPost}
                                    disabled={(
                                        list.socialNetworkLoading
                                        || isImagesCountOverflow
                                        || isMessageLengthOverflow
                                    )}
                                >
                                    {list.socialNetworkLoading
                                        ? (<Preloader width={16} />)
                                        : (<span>Отправить</span>)}
                                </Button>
                            </div>
                        )}
                    >
                        <div className={styles['social-network-dialog__container']}>
                            {!!postData && (
                                <>
                                    <div className={styles['social-network-dialog__warning']}>
                                        Не более 1000 символов и 5 фотографий
                                    </div>

                                    <div className={styles['social-network-dialog__input']}>
                                        <div
                                            className={`
                                                ${styles['social-network-dialog__info']}
                                                ${isMessageLengthOverflow ? styles['social-network-dialog__info--error'] : ''}
                                            `}
                                        >
                                            {postData.info.length}
                                            &nbsp;из&nbsp;
                                            {MAX_MESSAGE_LENGTH}
                                            &nbsp;символов
                                        </div>
                                        <Textarea
                                            rows={15}
                                            value={postData.info}
                                            onChange={handleChangePostText}
                                            maxLength={MAX_MESSAGE_LENGTH}
                                        />
                                    </div>

                                    {!!postData.images?.length && (
                                        <>
                                            <div
                                                className={`
                                                    ${styles['social-network-dialog__info']}
                                                    ${isImagesCountOverflow ? styles['social-network-dialog__info--error'] : ''}
                                                `}
                                            >
                                                {postData.images.length}
                                                &nbsp;из&nbsp;
                                                {MAX_IMAGES_COUNT}
                                                &nbsp;фото
                                            </div>
                                            <div className={styles['social-network-dialog__images']}>
                                                <Photos
                                                    onImageDelete={handleDeletePostImage}
                                                    createImageDeleteHandler={handleDeletePostImage}
                                                    photos={(
                                                        postData.images.map(
                                                            (photo) => ({
                                                                id: photo.id,
                                                                src: photo.location,
                                                                removeable: true,
                                                            }),
                                                        )
                                                    )}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </Dialog>
                );
            }}
        </Observer>
    );
};

SocialNetworksModal.propTypes = {
    list: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(ObjectListStore),
    ]).isRequired,
};

export default SocialNetworksModal;
