import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { TagList } from '../../../components/Input';
import Preloader from '../../../components/Preloader';

import styles from './Directory.module.scss';

const DirectoryTagList = ({ useStores, directory, ...props }) => {
    const { directoriesStore } = useStores();
    const store = directoriesStore[directory];
    // eslint-disable-next-line react/prop-types
    const { ctx } = props;
    useEffect(() => {
        store.loadData();
    }, []);
    return (
        <Observer>
            {() => {
                const options = store.getOptions
                    ? store.getOptions(ctx)
                    : store.options;
                return (
                    store.loaded
                        ? (
                            <TagList
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...props}
                                options={options}
                            />
                        )
                        : (
                            <div className={styles['tag-list-preloader']}>
                                <Preloader
                                    width={21}
                                />
                            </div>
                        )
                );
            }}
        </Observer>
    );
};

DirectoryTagList.propTypes = {
    useStores: PropTypes.func.isRequired,
    directory: PropTypes.string.isRequired,
};

export default DirectoryTagList;
