import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList as VariableList } from 'react-window';

const VariableSizeList = forwardRef(({
    className, height, width, handleScroll, rows, children,
}, ref) => {
    const rowHeights = useMemo(() => rows.map((row, i) => {
        ref.current?.resetAfterIndex(i);
        // Строки с длинным названием улицы
        if (!row.isGrouped && row.values.streetName && row.values.streetName.includes('\n')) {
            return 32;
        }

        return 17;
    }), [rows]);

    const scrollHeight = useMemo(
        () => rowHeights.reduce((prev, current) => prev + current, 0),
        [rowHeights],
    );

    const getItemSize = (index) => rowHeights[index];

    return (
        <VariableList
            ref={ref}
            className={className}
            height={height}
            width={width}
            itemCount={rows.length}
            itemSize={getItemSize}
            overscanCount={5}
            onScroll={handleScroll(height, scrollHeight)}
            resetAfterIndex={0}
        >
            {children}
        </VariableList>
    );
});

export default VariableSizeList;

VariableSizeList.propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    handleScroll: PropTypes.func,
    rows: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    children: PropTypes.node,
};

VariableSizeList.defaultProps = {
    className: '',
    height: 0,
    width: 0,
    handleScroll: () => null,
    rows: [],
    children: <></>,
};
