import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import ObjectListStore from '../../../stores/shared/RefactoredObjectList/ObjectList';
import { ObjectPageStats } from '../ObjectPageView';
import ObjectListTabs from './ObjectListTabs';
import ObjectListState from './ObjectListState';
import PageView, { PageViewExtra } from '../PageView';
import ObjectListFilter from './ObjectListFilter';
import { FilterGroups } from '../FilterGroups';
import NotepadList from '../../../stores/shared/NotepadList/NotepadList';
import PresetList from '../../../stores/shared/PresetList';
import { Button } from '../../../components/Button';
import { DIALOG_DURATION_DELAY } from '../../../constants/shared';
import styles from '../ObjectPageView/ObjectPageView.module.scss';
import PopupTabsStore from '../../../stores/shared/PopupTabsStore';
import { PagesRouteInfo, PagesRequestTypesNamesMap } from '../../../routes/utils/pagesRouteInfo';

const ObjectListPage = ({
    tabsStore,
    store,
    children,
    meta,
    useStores,
    notepads,
    presets,
    onNotepadOpen,
    onPresetAdd,
}) => {
    const { objectListPageStore, dialogStore, presetListStore } = useStores();
    const { filterForm, groupingForm } = store;
    const groupFieldset = filterForm && filterForm.render.find((field) => field.name === 'groups');
    const handleSelectCancelClick = () => {
        store.unselectAll();
    };
    const handlePresetSaveClick = () => {
        store.getPresetFilterData().then((preset) => {
            objectListPageStore.selected.item.updateFilter(preset);
        });
        dialogStore.show('preset-item-save');
        setTimeout(() => dialogStore.hide('preset-item-save'), DIALOG_DURATION_DELAY);
    };
    const handlePresetCancelClick = (e) => {
        filterForm.onReset(e);
        if (filterForm.hasSnapshot('preset')) {
            filterForm.removeSnapshot('preset');
        }
        presetListStore.preload();
        objectListPageStore.reset();
        presetListStore.reload();
    };
    /** Признак необходимости отображения таба с настройками группировки */
    const showGroupingTab = useMemo(
        () => [PagesRouteInfo.Duplicates.PageTitle]
            .includes(PagesRequestTypesNamesMap.get(meta.extra.requestType)),
        [meta.extra.requestType],
    );
    return (
        <PageView meta={meta}>
            {({ setCollapsed }) => (
                <>
                    <Observer>
                        {() => (
                            filterForm
                                ? (
                                    <ObjectListFilter
                                        filterForm={filterForm}
                                        groupingForm={groupingForm}
                                        showGroupingTab={showGroupingTab}
                                        tabsStore={tabsStore}
                                        useStores={useStores}
                                        notepads={notepads}
                                        presets={presets}
                                        onNotepadOpen={notepads && onNotepadOpen}
                                        onPresetAdd={presets && onPresetAdd}
                                        onPresetSave={presets && handlePresetSaveClick}
                                        onPresetCancel={presets && handlePresetCancelClick}
                                        onCollapse={setCollapsed}
                                    />
                                )
                                : null
                        )}
                    </Observer>
                </>
            )}
            <>
                <ObjectListTabs meta={meta} useStores={useStores} />
                <div className={styles['page-view__wrapper']}>
                    <Observer>
                        {() => (
                            <ObjectListState
                                isEmpty={store.isEmpty}
                                error={store.error}
                            />
                        )}
                    </Observer>
                    <PageViewExtra>
                        <Observer>
                            {() => (
                                <>
                                    <ObjectPageStats>
                                        Показано
                                        &nbsp;
                                        {store.tableData.length}
                                        &nbsp;
                                        из
                                        &nbsp;
                                        {store.paginationHandler.data.totalItems}
                                    </ObjectPageStats>
                                    {
                                        store.selected && store.selected.length
                                            ? (
                                                <>
                                                    <ObjectPageStats>
                                                        Выбрано
                                                        &nbsp;
                                                        {store.selected.length}
                                                    </ObjectPageStats>
                                                    <ObjectPageStats>
                                                        <Button
                                                            className={styles['page-view__unselect']}
                                                            color={Button.COLOR.WHITE_BLACK}
                                                            size={Button.SIZE.SMALL}
                                                            onClick={handleSelectCancelClick}
                                                        >
                                                            Отменить
                                                        </Button>
                                                    </ObjectPageStats>
                                                </>
                                            ) : null
                                    }
                                </>
                            )}
                        </Observer>
                        {
                            groupFieldset
                                ? (
                                    <FilterGroups
                                        title="Группы объектов"
                                        filterForm={filterForm}
                                        fields={groupFieldset.fields}
                                    />
                                )
                                : null
                        }
                    </PageViewExtra>
                    {children}
                </div>
            </>
        </PageView>
    );
};

ObjectListPage.propTypes = {
    tabsStore: PropTypes.oneOfType([
        PropTypes.instanceOf(PopupTabsStore),
        PropTypes.shape({}),
    ]).isRequired,
    store: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(ObjectListStore),
    ]).isRequired,
    notepads: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(NotepadList),
    ]),
    presets: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(PresetList),
    ]),
    onNotepadOpen: PropTypes.func,
    onPresetAdd: PropTypes.func,
    useStores: PropTypes.func.isRequired,
    meta: PropTypes.shape({
        pageTitle: PropTypes.string,
        icon: PropTypes.func,
        sisterRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        parentRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        extra: PropTypes.shape({
            requestType: PropTypes.string,
        }),
    }),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]).isRequired,
};

ObjectListPage.defaultProps = {
    meta: {
        title: null,
        icon: null,
        sisterRoutes: [],
        parentRoutes: [],
    },
    notepads: null,
    presets: null,
    onNotepadOpen: () => null,
    onPresetAdd: () => null,
};

export default ObjectListPage;
