/* global window */
import { SocialNetworkService } from '../services/client';

const API_VERSION = '5.131';
const PERMISSIONS_CODE = '73732';

const parseUserName = (user) => `${user.first_name || ''} ${user.last_name || ''}`.trim();

const VkService = {
    async login() {
        return new Promise((resolve) => {
            window.VK.Auth.login((data) => {
                console.info('VK Login', { data });
                if (data?.status === 'connected') {
                    const { user } = data.session;
                    resolve(parseUserName(user));
                } else {
                    resolve('');
                }
            }, PERMISSIONS_CODE);
        });
    },

    logout() {
        window.VK.Auth.logout();
    },

    async getUserName() {
        return new Promise((resolve) => {
            try {
                const vkSessionData = window.VK.Auth.getSession();
                if (!vkSessionData) {
                    throw new Error('No active session found');
                }
                window.VK.Api.call('users.get', {
                    v: API_VERSION,
                    user_ids: vkSessionData.mid,
                }, (data) => {
                    if (!data.response.length) {
                        throw new Error('No users found');
                    }
                    const user = data.response[0];
                    resolve(parseUserName(user));
                });
            } catch {
                resolve('');
            }
        });
    },

    async savePhotos(savedImagesData) {
        return new Promise((resolve) => {
            window.VK.Api.call('photos.saveWallPhoto', {
                v: API_VERSION,
                ...savedImagesData,
            }, (data) => {
                if (data.response?.length) {
                    resolve(data.response.map((item) => `photo${item.owner_id}_${item.id}`).join(','));
                }
                resolve('');
            });
        });
    },

    async uploadPhotos(url, images) {
        const photos = [];
        const uploaded = await SocialNetworkService.uploadImages({ url, images });
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= uploaded.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            const photo = await this.savePhotos(uploaded[i]);
            photos.push(photo);
        }
        return photos.join(',');
    },

    async getUploadServer() {
        return new Promise((resolve) => {
            const vkSessionData = window.VK.Auth.getSession();
            if (!vkSessionData) {
                resolve(false);
            }
            window.VK.Api.call('photos.getWallUploadServer', {
                v: API_VERSION,
                owner_id: vkSessionData.mid,
            }, (data) => {
                resolve(data?.response?.upload_url || '');
            });
        });
    },

    async prepareAttachments(images) {
        if (!images?.length) {
            return '';
        }
        const uploadServer = await this.getUploadServer();
        return uploadServer ? this.uploadPhotos(uploadServer, images) : '';
    },

    async postDataOnWall(text, attachments) {
        return new Promise((resolve) => {
            const vkSessionData = window.VK.Auth.getSession();
            if (!vkSessionData) {
                resolve(false);
            }
            window.VK.Api.call('wall.post', {
                v: API_VERSION,
                owner_id: vkSessionData.mid,
                message: text,
                attachments: attachments || undefined,
            }, (data) => {
                resolve(!data.error);
            });
        });
    },
};

export default VkService;
