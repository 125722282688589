import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import useStores from '../../../hooks/client/use-stores';
import { Button, ButtonLink } from '../../../components/Button';
import LoginPage, { LoginPageField } from '../../shared/LoginPage';
import FormElements from '../Form';

import styles from '../../shared/LoginPage/LoginPage.module.scss';
import clientStyles from './LoginPage.module.scss';

const Login = () => {
    const history = useHistory();
    const { sessionStore, deviceStore } = useStores();
    const { loginForm } = sessionStore;
    useEffect(() => {
        if (loginForm && loginForm.on) {
            return loginForm.on('done', ([reactivation]) => {
                deviceStore.save();
                if (reactivation) {
                    const { success } = reactivation;
                    const route = success ? 're-activate' : 'block';
                    deviceStore.setReactivation(reactivation);
                    history.push(`/activate/${route}`, reactivation);
                }
            });
        }
        return undefined;
    }, [loginForm]);
    return (
        <>
            <Observer>
                {() => (
                    <Portal>
                        <div
                            className={`
                                ${clientStyles.wrapper}
                                ${sessionStore.trying ? clientStyles['wrapper--loading'] : ''}
                            `}
                        />
                    </Portal>
                )}
            </Observer>
            <LoginPage
                title="Вход"
                footer={(
                    <ButtonLink
                        Component={Link}
                        size={ButtonLink.SIZE.MEDIUM}
                        color={ButtonLink.COLOR.TRANSPARENT}
                        href="/register"
                        target="_self"
                    >
                        Регистрация
                    </ButtonLink>
                )}
            >
                <Observer>
                    {() => (
                        <form onSubmit={loginForm.onSubmit}>
                            <FormElements
                                form={loginForm}
                                elements={loginForm.render}
                                components={{
                                    FieldWrapper: LoginPageField,
                                }}
                            />
                            <div className={styles.login__submit}>
                                <Button
                                    type="submit"
                                    color={Button.COLOR.GREEN}
                                    size={Button.SIZE.LARGE}
                                    block
                                    disabled={loginForm.loading}
                                >
                                    Войти
                                </Button>
                            </div>
                            <div className={styles.login__forgot}>
                                <Link className="link" to="/login/forgot">Забыли пароль?</Link>
                            </div>
                        </form>
                    )}
                </Observer>
            </LoginPage>
        </>
    );
};

export default Login;
