import React from 'react';
import { SelectedRowWithHint } from '../../../components/TableSelected';
import { defaultProps, propTypes } from './propTypes';

import styles from './SelectedObject.module.scss';

const SelectedSaleFlat = ({
    store,
}) => (
    <>
        <SelectedRowWithHint name="Класс жилья" classNames={{ row: styles.selected__row }}>
            {store.flatType?.label}
        </SelectedRowWithHint>
        <SelectedRowWithHint name="Тип комнат" classNames={{ row: styles.selected__row }}>
            {store.roomType?.label}
        </SelectedRowWithHint>
        <SelectedRowWithHint name="Состояние" classNames={{ row: styles.selected__row }}>
            {store.objectCondition?.label}
        </SelectedRowWithHint>
        <SelectedRowWithHint name="Мебель" classNames={{ row: styles.selected__row }}>
            {store.furnishing?.label}
        </SelectedRowWithHint>
        <SelectedRowWithHint name="Балкон" classNames={{ row: styles.selected__row }}>
            {store.balcony?.label}
        </SelectedRowWithHint>
    </>
);

SelectedSaleFlat.propTypes = { store: propTypes.store };
SelectedSaleFlat.defaultProps = { store: defaultProps.store };

export default SelectedSaleFlat;
