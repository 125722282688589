/* global alert */
import { runInAction, observable } from 'mobx';
import createForm from '../../../helpers/Form';
import {
    resetPasswordRequest,
    resetPasswordSet,
} from '../../../forms/client';
import DataSource, { STATES } from '../../shared/DataSource';
import { UserService } from '../../../services/client';

class ResetPassword {
    requestForm = createForm(resetPasswordRequest);

    setForm = createForm(resetPasswordSet);

    @observable login = undefined;

    @observable email = undefined;

    constructor() {
        const {
            requestForm,
            setForm,
        } = this;
        requestForm.on('success', this.requestPasswordChange.bind(this));
        setForm.on('success', this.setNewPassword.bind(this));
        this.dataSource = new DataSource(UserService, STATES.DEFAULT);
    }

    requestPasswordChange({ login, email }) {
        const { dataSource } = this;
        return dataSource.requestPasswordChange({ login, email })
            .then((response) => {
                runInAction(() => {
                    this.login = login;
                    this.email = email;
                });
                return Promise.resolve(response);
            })
            .catch((e) => {
                alert(e);
            });
    }

    setNewPassword({ code, password }) {
        const { dataSource } = this;
        return dataSource.setNewPassword({ code, password })
            .then((response) => {
                runInAction(() => {
                    this.login = undefined;
                    this.email = undefined;
                });
                return Promise.resolve(response);
            })
            .catch((e) => {
                alert(e);
            });
    }
}

const createResetPasswordStore = () => new ResetPassword();

export default ResetPassword;
export {
    createResetPasswordStore,
};
