/* eslint linebreak-style: 0 */

import SearchableDirectory from './SearchableDirectory';

/** Справочник ориентиров */
class CityDistrictLandmarkDirectory extends SearchableDirectory {
    minQueryLength = 0;
}

export default CityDistrictLandmarkDirectory;
