/* eslint linebreak-style: 0 */

/**
 * Функция сравнения строк, либо объектов, натуральным (а-я, a-z, 0-9) сравнением
 * Позволяет сравнивать как строки, так и объекты, используя для сравнения строковое поле объекта
 * Дефолтная сортировка сравнивает строки, содержащие числа,
    следующим образом: '21' > '2' true, '21' > '3' false
 * Натуральная сортировка будет сравнивать так: '21' > '2' true, '21' > '3' true
 * @param {string|object} obj1 строка, либо объект, либо null/undefined
 * @param {string|object} obj2 строка, либо объект, либо null/undefined
 * @param {string} objectField название поля, по которому требуется сравнить объекты,
    если переданы объекты
 * @param {string|Array<string>} locales строка с названием локали,
    либо массив строк с названиями локалей, которые будут учитываться
    при сортировке (по умолчанию `[ru, en]`)
 * @returns `1` если первый объект больше, `-1` если второй объект больше, `0` если оба равны
 */
export const naturalComparer = (obj1, obj2, objectField, locales = ['ru', 'en']) => {
    if (!obj1 && obj2) return -1;
    if (!obj1 && !obj2) return 0;
    if (obj1 && !obj2) return 1;

    let value1ToCompare = obj1;
    let value2ToCompare = obj2;
    if (objectField) {
        value1ToCompare = value1ToCompare[objectField];
        value2ToCompare = value2ToCompare[objectField];
    }

    if (typeof (value1ToCompare.localeCompare) === 'function') {
        return value1ToCompare.localeCompare(value2ToCompare, locales, { numeric: true });
    }

    return value1ToCompare > value2ToCompare;
};

/**
 * Функция натуральной сортировки (а-я, a-z, 0-9)
 * Позволяет сортировать как массив строк,
 * так и массив объектов, используя для сравнения строковое поле объекта
 * Дефолтная сортировка сортирует строки, содержащие числа,
 * следующим образом: '1', '10', '11', '2', '21', '3'...
 * Натуральная сортировка отсортирует такой массив так: '1', '2', '3', '10', '11', '21'...
 * @param {Array} array массив строк, либо массив объектов
 * @param {string} objectField название строкового поля,
    по которому требуется отсортировать объекты,если передан массив объектов
 * @param {string|Array<string>} locales строка, либо массив строк, с названиями локалей,
    которые будут учитываться при сортировке (по умолчанию `[ru, en]`)
 * @returns новый отсортированный массив
 */
export const arrayNaturalSort = (array, objectField, locales = ['ru', 'en']) => (
    array.sort((item1, item2) => naturalComparer(item1, item2, objectField, locales))
);
