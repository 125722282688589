import React, { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

import styles from './ChatBotQR.module.scss';
import Preloader from '../../../components/Preloader';
import Dialog from '../../client/Dialog';
import { UserService } from '../../../services/client';
import { CHAT_BOT_NAME } from '../../../config/client';

const ChatBotQR = () => {
    const [code, setCode] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (isOpen) {
                try {
                    setIsLoading(true);
                    const res = await UserService.getChatBotCode();
                    setCode(res);
                    setIsLoading(false);
                } catch {
                    /* empty */
                }
            } else {
                setCode('');
                setIsLoading(false);
            }
        })();
    }, [isOpen]);

    return (
        <Dialog
            name="telegram-bot"
            size="md"
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            footer={null}
            header={(
                <div className={styles['chat-bot__header']}>
                    <span>Чат-бот в Telegram</span>
                </div>
            )}
        >
            <div className={styles['chat-bot__container']}>
                <span>
                    Отсканируйте код или перейдите по ссылке
                </span>
                <div className={styles['chat-bot__qr']}>
                    {
                        isLoading
                            ? <Preloader width={40} />
                            : <QRCodeCanvas size={200} value={`https://t.me/${CHAT_BOT_NAME}/?start=${code}`} />
                    }
                </div>
                <a href={`https://t.me/${CHAT_BOT_NAME}/?start=${code}`} target="_blank" rel="noreferrer" className="link">
                    @
                    {CHAT_BOT_NAME}
                </a>
            </div>
        </Dialog>
    );
};

export default ChatBotQR;
