import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { DropdownAsyncSelect, AsyncSelect } from '../../../components/Select';

import styles from './Directory.module.scss';

const DirectorySearch = ({
    useStores,
    form,
    name,
    multiple,
    directory, // rename to directoryStore?
    className,
    withDefault,
    isDropdown,
    ctx,
    ...props
}) => {
    const { directoriesStore } = useStores();
    const store = directoriesStore[directory];
    const [inputValue, setInputValue] = useState('');
    const [defaultOptions, setDefaultOptions] = useState(false);
    const handleSearch = (query) => store.loadData(query, ctx);
    const handleOpen = () => {
        setDefaultOptions(false);
        store.loadData('', ctx)
            .then((response) => {
                setDefaultOptions(response);
            })
            .catch(() => {});
    };
    const onInputChange = (query, { action }) => {
        // Prevents resetting our input after option has been selected
        if (action !== 'set-value') {
            setInputValue(query);
            return query;
        }
        return inputValue;
    };

    const Component = isDropdown && multiple
        ? DropdownAsyncSelect
        : AsyncSelect;

    if (!store) {
        return null;
    }
    return (
        <Observer>
            {() => {
                useEffect(() => {
                    const field = form.$(name);
                    const defaultValue = multiple
                        ? [store.default]
                        : store.default;
                    if (!field.value && store.default && withDefault) {
                        field.set('value', defaultValue);
                        field.set('default', defaultValue);
                        form.makeSnapshot();
                    }
                }, [store.default]);
                const key = `${name}-${JSON.stringify(ctx)}`;
                return (
                    <Component
                        key={key}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        name={name}
                        multiple={multiple}
                        className={`${styles['directory-search']} ${className}`}
                        loading={store.loading}
                        loadingMessage={() => 'Поиск...'}
                        // TODO: handle with right message on filter dropdown select
                        noOptionsMessage={({ inputValue: query }) => {
                            switch (store.message) {
                                case 'CITY_ID_IS_MISSING':
                                    return 'Необходимо указать город либо ввести первые символы названия района';
                                case 'AREA_QUERY_IS_EMPTY':
                                    return 'Например, Емельяновский';
                                default:
                                    return query.length >= store.minQueryLength
                                        ? 'Список пуст'
                                        : `Введите еще ${store.minQueryLength - query.length} символ(а)`;
                            }
                        }}
                        onMenuOpen={
                            store.minQueryLength === 0
                                ? handleOpen
                                : undefined
                        }
                        defaultOptions={defaultOptions}
                        loadOptions={handleSearch}
                        inputValue={multiple ? inputValue : undefined}
                        onInputChange={multiple ? onInputChange : undefined}
                    />
                );
            }}
        </Observer>
    );
};

DirectorySearch.propTypes = {
    useStores: PropTypes.func.isRequired,
    form: PropTypes.shape({
        $: PropTypes.func,
        makeSnapshot: PropTypes.func,
    }),
    name: PropTypes.string,
    multiple: PropTypes.bool,
    isDropdown: PropTypes.bool,
    directory: PropTypes.string.isRequired,
    className: PropTypes.string,
    withDefault: PropTypes.bool,
    ctx: PropTypes.shape({}),
};

DirectorySearch.defaultProps = {
    form: {
        $: () => null,
        makeSnapshot: () => null,
    },
    name: '',
    multiple: false,
    isDropdown: false,
    className: '',
    withDefault: false,
    ctx: {},
};

export default DirectorySearch;
