import CellSelect from '../CellSelect';
import CellDefault from '../CellDefault';
import CellType from '../CellType';
import CellImages from '../CellImages';
import CellPhone from '../CellPhone';
import CellBool from '../CellBool';
import CellStatusUpdate from '../CellStatusUpdate';
import CellWithNewLine from '../CellWithNewLine';
import CellPrice from '../CellPrice';
import CellBrick from '../CellBrick';
import CellSource from '../CellSource';

const modifyHeaders = (headers) => (
    headers.map((header) => {
        let Cell;
        if (header.Cell) {
            return header;
        }
        switch (header.name) {
            case 'rowSelect':
                Cell = CellSelect;
                break;
            case 'objectType':
                Cell = CellType;
                break;
            case 'images':
                Cell = CellImages;
                break;
            case 'contactPhone':
                Cell = CellPhone;
                break;
            case 'objectStatusUpdate':
            case 'objectStatusUpdateWithContact':
                Cell = CellStatusUpdate;
                break;
            case 'exchange':
            case 'suburbOfCityName':
                Cell = CellBool;
                break;
            case 'streetName':
                Cell = CellWithNewLine;
                break;
            case 'price':
            case 'priceDelimited':
                Cell = CellPrice;
                break;
            case 'incorrect':
                Cell = CellBrick;
                break;
            case 'source':
                Cell = CellSource;
                break;
            default:
                Cell = CellDefault;
        }
        return {
            ...header,
            Cell,
        };
    })
);

export default modifyHeaders;
