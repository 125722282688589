import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PaymentForm.module.scss';
import useStores from '../../../hooks/client/use-stores';
import PaymentForm from './PaymentForm';

const PaymentFormPage = () => {
    const { sessionStore } = useStores();
    const history = useHistory();

    return (
        <div className={styles.root}>
            <PaymentForm
                title="У вас закончился оплаченный период. Оплатите, чтобы продолжить использование личного кабинета"
                onLogout={() => {
                    sessionStore.logout();
                    history.replace('/login');
                }}
            />
        </div>
    );
};

export default PaymentFormPage;
