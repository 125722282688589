import React from 'react';
import PropTypes from 'prop-types';
import { ErrorIcon } from '../Icons/icons';
import SVGIcon from '../Icons/SVGIcon';
import { SIZE, POSITION } from '../../constants/operator';

import styles from './Error.module.scss';

const size2height = {
    [SIZE.SMALL]: 14,
    [SIZE.MEDIUM]: 16,
    [SIZE.LARGE]: 18,
};

const Error = ({
    error,
    className,
    size,
}) => (
    <div
        className={`
            ${styles.error}
            ${styles[`error--${size}`]}
            ${className}
        `}
        data-tip={error}
        data-place={POSITION.RIGHT}
        data-class={styles.error__message}
    >
        <SVGIcon
            SVGElement={ErrorIcon}
            height={size2height[size]}
            className={styles.error__icon}
        />
    </div>
);

Error.SIZE = { ...SIZE };

Error.propTypes = {
    error: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(Error.SIZE)),
};

Error.defaultProps = {
    error: '',
    className: '',
    size: Error.SIZE.MEDIUM,
};

export default Error;
