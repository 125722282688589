import React from 'react';
import PropTypes from 'prop-types';
import ImageSlider from '../../../components/ImageSlider/ImageSlider';

import styles from './Table.module.scss';

const TableImageSlider = ({
    onSliderClick,
    sources,
    relatedId,
    onImageDownload,
}) => (
    <ImageSlider
        size={ImageSlider.SIZE.SMALL}
        arrows
        dots
        slides={sources}
        maxHeight={116}
        onImageClick={onSliderClick}
        className={styles.slider}
        relatedId={relatedId}
        autoPlay={2000}
        stopAutoPlayOnHover
        onImageDownload={onImageDownload}
    />
);

TableImageSlider.propTypes = {
    onImageDownload: PropTypes.func,
    onSliderClick: PropTypes.func.isRequired,
    sources: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
        }),
    ),
    relatedId: PropTypes.string.isRequired,
};

TableImageSlider.defaultProps = {
    sources: [],
    onImageDownload: () => {},
};

export default TableImageSlider;
