/* global window, alert */
import { computed, observable } from 'mobx';
import { format } from 'date-fns';
import DynamicStructure from '../DynamicStructure';
import DataSource from '../DataSource';
import createForm from '../../../helpers/Form';
import forEachField from '../../../forms/utils/for-each-field';
import isEmpty from '../../../utils/is-empty';
import downloadFile from '../../../utils/download-file';
import { DEFAULT_DATE_FORMAT, PRICE_FLOAT_CHARS } from '../../../constants/shared';

class ObjectDetailed extends DynamicStructure {
    form;

    fields = [];

    @observable $images = [];

    @observable $history = [];

    @observable $ads = [];

    @observable $loaded = false;

    @observable $canEdit = true;

    @observable $moderationInProgress = false;

    @observable $company;

    @observable $street;

    @observable $house;

    @observable $objectCondition;

    @observable $status;

    @observable $statusReason;

    @observable $publicNote;

    @observable $privateNote;

    @observable $objectStatusComment;

    @observable $phones;

    @observable $stats;

    @observable $incorrectList;

    @observable $video;

    constructor(service, objectType) {
        super();
        this.objectType = objectType;
        this.dataSource = new DataSource(service);
    }

    @computed
    get formName() {
        const { objectType } = this;
        return objectType;
    }

    @computed
    get loaded() {
        const { $loaded } = this;
        return $loaded;
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get status() {
        const { $status } = this;
        if (!$status) {
            return null;
        }
        return {
            value: $status.id,
            label: $status.name,
        };
    }

    @computed
    get reason() {
        const { $statusReason } = this;
        if (!$statusReason) {
            return null;
        }
        return {
            value: $statusReason.id,
            label: $statusReason.name,
        };
    }

    @computed
    get comment() {
        const { $objectStatusComment } = this;
        return $objectStatusComment || '';
    }

    @computed
    get isNew() {
        return Boolean(!this.$id);
    }

    @computed
    get ownerType() {
        const { $ownerType } = this;
        if (!$ownerType) {
            return null;
        }
        return String($ownerType.id);
    }

    @computed
    get area() {
        const { $city } = this;
        if (!$city) {
            return null;
        }
        const { area } = $city;
        if (!area) {
            return null;
        }
        return {
            value: area.id,
            label: area.name,
        };
    }

    @computed
    get city() {
        const { $city } = this;
        if (!$city) {
            return null;
        }
        return {
            value: $city.id,
            label: $city.name,
        };
    }

    @computed
    get cityDistrict() {
        const { $cityDistrict } = this;
        if (!$cityDistrict) {
            return null;
        }
        return {
            value: $cityDistrict.id,
            label: $cityDistrict.name,
        };
    }

    @computed
    get cityDistrictLandmark() {
        const { $cityDistrictLandmark } = this;
        if (!$cityDistrictLandmark) {
            return null;
        }
        return {
            value: $cityDistrictLandmark.id,
            label: $cityDistrictLandmark.name,
        };
    }

    @computed
    get street() {
        const { $street } = this;
        if (!$street) {
            return null;
        }
        return {
            value: $street.id,
            label: $street.name,
        };
    }

    @computed
    get objectCondition() {
        const { $objectCondition } = this;
        if (!$objectCondition) {
            return null;
        }
        return {
            value: $objectCondition.id,
            label: $objectCondition.name,
        };
    }

    @computed
    get images() {
        const { $images } = this;
        if (!$images || !$images.length) {
            return [];
        }
        return $images.map(({
            location,
            id,
            description,
        }) => ({
            id,
            description,
            src: location,
            removeable: Boolean(location),
        }));
    }

    @computed
    get waitingImages() {
        const { $images } = this;
        if (!$images || !$images.length) {
            return [];
        }
        return $images
            .filter(({ raw }) => Boolean(raw))
            .map(({ raw }) => ({ raw }));
    }

    @computed
    get sources() {
        const { $ads } = this;
        if (!$ads || !$ads.length) {
            return [];
        }
        return $ads.filter((e) => e.id).map(({
            board,
            url,
            createdAt,
            updatedAt,
        }) => ({
            src: board && board.icon,
            name: (board && board.name) || new URL(url).hostname,
            url,
            posted: createdAt ? format(new Date(createdAt), DEFAULT_DATE_FORMAT) : '-/-',
            updated: updatedAt ? format(new Date(updatedAt), DEFAULT_DATE_FORMAT) : '-/-',
        }));
    }

    @computed
    get history() {
        const { $history } = this;
        if (!$history || !$history.length) {
            return [];
        }
        const mapActions = (action) => ({
            field, prev, next, value,
        }) => ({
            name: field,
            action,
            newValue: next || (typeof value === 'object' ? value?.next : value),
            prevValue: prev || (typeof value === 'object' ? value?.prev : value),
        });
        const history = $history.map(({
            user,
            operator,
            id,
            createdAt,
            data = {},
        } = {}) => {
            const {
                comment,
                reason,
                updated = [],
                deleted = [],
                added = [],
            } = data || {};
            return {
                user,
                operator,
                id,
                comment,
                reason,
                datetime: createdAt
                    ? format(new Date(createdAt), DEFAULT_DATE_FORMAT)
                    : undefined,
                changed: [
                    ...added.map(mapActions('ADD')),
                    ...updated.map(mapActions('UPDATE')),
                    ...deleted.map(mapActions('DELETE')),
                ],
            };
        });
        return history || [];
    }

    @computed
    get phones() {
        const { $phones } = this;
        return $phones || [];
    }

    @computed
    get phoneMobile() {
        const { phones } = this;
        if (!phones || phones.length) {
            return null;
        }
        return phones[0].phone;
    }

    @computed
    get isExclusive() {
        const { $company, $exclusive } = this;
        return $exclusive || Boolean($company) || false;
    }

    @computed
    get company() {
        const { $company } = this;
        return $company
            ? $company.name
            : null;
    }

    @computed
    get privateNote() {
        const { $privateNote } = this;
        return $privateNote || '';
    }

    @computed
    get publicNote() {
        const { $publicNote } = this;
        return $publicNote || '';
    }

    @computed
    get canEdit() {
        const { $canEdit } = this;
        return $canEdit !== false;
    }

    @computed
    get moderationInProgress() {
        const { $moderationInProgress } = this;
        return $moderationInProgress;
    }

    @computed
    get views() {
        const { $stats } = this;
        return $stats?.views || null;
    }

    @computed
    get incorrect() {
        const { $incorrectList } = this;
        return $incorrectList?.map((i) => i.option.id) || [];
    }

    @computed
    get video() {
        const { $video } = this;
        return $video;
    }

    load(id) {
        this.setData({ id });
        const { objectType } = this;
        return this.dataSource.getObject(id, { objectType })
            .then((response) => {
                this.setData(response);
                this.createForm();
                this.setFormValues();
                this.setData({ loaded: true });
                return Promise.resolve();
            })
            .catch((e) => alert(e.message));
    }

    createForm(mixins = []) {
        const { fields, form, canEdit } = this;
        const editable = canEdit !== false;
        if (form) {
            if (editable !== form.editable) {
                this.form.editable = editable;
            }
            return form;
        }
        this.form = createForm(
            fields,
            mixins,
        );
        this.form.editable = editable;
        this.initFormListeners();
        return this.form;
    }

    setFormValues() {
        const { form, fields: formFields } = this;
        forEachField(formFields, (field) => {
            if (Object.getOwnPropertyDescriptor(this, field.name)) {
                if (!isEmpty(this[field.name])) {
                    form.$(field.name).set('value', this[field.name]);
                }
            }
        });
        form.makeSnapshot();
        form.makeAddressSnapshot();
    }

    initFormListeners() {
        const {
            form,
            objectType,
            requestType,
        } = this;
        form.on('success', (values) => {
            const { id, isNew, $images } = this;
            const request = form.nullValues(this.prepareRequest(values));
            let promise;
            if (!isNew) {
                promise = this.dataSource.updateObject(id, request, { objectType, requestType });
            } else {
                promise = this.dataSource.createObject(request, { objectType });
            }
            return promise
                .then((response) => {
                    this.setData(response);
                    form.makeSnapshot();
                    form.makeAddressSnapshot();
                    if (isNew) {
                        const promises = $images
                            .filter(({ local }) => local === true)
                            .map(({ raw }) => this.uploadNewPhoto(raw));
                        if (promises.length) {
                            return Promise.all(promises)
                                .then(() => Promise.resolve(response));
                        }
                    }
                    return Promise.resolve(response);
                })
                .catch((e) => {
                    alert(e);
                    return Promise.reject();
                });
        });
    }

    downloadImage(index, addIndexToName) {
        const { $images, objectName } = this;
        downloadFile($images[index].location, objectName + (addIndexToName ? ` (${index + 1})` : ''));
    }

    downloadAllImages() {
        const { $images } = this;
        $images.forEach((_, index) => {
            this.downloadImage(index, true);
        });
    }

    uploadNewPhoto(file) {
        const { objectType } = this;
        return this.dataSource.uploadImage(this.id, file, { objectType })
            .then((image) => {
                const { $images } = this;
                this.setData({
                    images: $images.concat(image),
                });
            });
    }

    removePhoto(id) {
        const { $images, objectType } = this;
        const image = $images.find(({ id: imageId }) => imageId === id);
        if (image && image.local) {
            this.setData({
                images: $images.filter((stored) => stored.id !== image.id),
            });
            return Promise.resolve();
        }
        return this.dataSource.removeImage(this.id, id, { objectType })
            .then((file) => {
                this.setData({
                    images: $images.filter((stored) => stored.id !== file.id),
                });
            });
    }

    addNewPhoto(file) {
        const reader = new window.FileReader();
        reader.onloadend = () => {
            const { $images: images } = this;
            const result = {
                raw: file,
                removable: true,
                local: true,
                id: `local-image-${images.length}`,
            };
            result.location = reader.result;
            this.setData({
                images: images.concat(result),
            });
        };
        reader.readAsDataURL(file);
    }

    setCompany(data) {
        this.$company = data;
    }

    // eslint-disable-next-line class-methods-use-this
    prepareRequest(values) {
        const { totalArea, price, unitPrice: passedUnitPrice } = values;
        return {
            objectConditionId: values.objectCondition,
            price: values.price || 0,
            unitPrice: (price / totalArea || passedUnitPrice || 0).toFixed(PRICE_FLOAT_CHARS),
            ownerTypeId: values.ownerType,
            privateNote: values.privateNote,
            publicNote: values.publicNote,
            note: values.note,
            phones: values.phones,
            longitude: values.longitude,
            latitude: values.latitude,
            incorrect: values.incorrect,
            video: values.video,
        };
    }

    toItem() {
        return {
            highlight: this.highlight,
            status: this.$status,
            price: this.$price,
            phones: this.$phones,
            area: this.$area ? this.$area.name : null,
            city: this.$city ? this.$city.name : null,
            cityDistrict: this.$cityDistrict
                ? this.$cityDistrict.name
                : null,
            cityDistrictLandmark: this.$cityDistrictLandmark
                ? this.$cityDistrictLandmark.name
                : null,
            street: this.$street ? this.$street.name : null,
        };
    }
}

export default ObjectDetailed;
