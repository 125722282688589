import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.ROW_SELECT,
    width: 3,
    sortName: null,
}, {
    ...TABLE_CELL.OBJECT_TYPE,
    width: 3,
    sortName: null,
    fields: [{
        name: 'ownerTypeIds',
    }],
}, {
    ...TABLE_CELL.HOUSE_TYPE_NAME,
    width: 13,
    fields: [{
        name: 'objectHouseTypeIds',
    }],
}, {
    ...TABLE_CELL.ROOMS_COUNT,
    width: 3,
    fields: [{
        name: 'rooms',
    }],
}, {
    ...TABLE_CELL.SHORT_DISTRICT_NAME,
    width: 5,
    fields: [{
        name: 'cityDistrictIds',
    }],
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 20,
    textAlign: 'left',
    fields: [{
        name: 'streetIds',
    }],
}, {
    ...TABLE_CELL.HOUSE_NUMBER,
    title: '№',
    width: 6,
    fields: [{
        name: 'houseIds',
    }],
}, {
    ...TABLE_CELL.DISTRICT_LANDMARK_NAME,
    textAlign: 'left',
    width: 12,
    sortName: null,
    fields: [{
        name: 'cityDistrictLandmarkIds',
    }],
}, {
    ...TABLE_CELL.FLOOR_COUNT_WITH_WALL_MATERIAL,
    width: 6,
    fields: [{
        name: 'floorFrom',
    }, {
        name: 'floorTo',
    }],
    sortName: null,
}, {
    ...TABLE_CELL.SQUARE,
    width: 5,
    fields: [{
        name: 'totalAreaFrom',
    }, {
        name: 'totalAreaTo',
    }],
}, {
    ...TABLE_CELL.PRICE_DELIMITED,
    width: 6,
}, {
    ...TABLE_CELL.UNIT_PRICE_DELIMITED,
    width: 5,
}, {
    ...TABLE_CELL.DATE_OF_CONSTRUCTION,
    width: 6,
    sortName: null,
}, {
    ...TABLE_CELL.IMAGES,
    sortName: null,
    width: 5.7,
}, TABLE_CELL.INCORRECT_OBJECT];
