import SearchableDirectory from './SearchableDirectory';

class CityDistrictDirectory extends SearchableDirectory {
    minQueryLength = 0;

    loadData(query = '', ctx = {}) {
        if (!query && (!ctx.cityIds || !ctx.cityIds.length)) {
            this.setError('CITY_ID_IS_MISSING');
            return Promise.reject();
        }
        return super.loadData(query, ctx);
    }
}

export default CityDistrictDirectory;
