import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Observer } from 'mobx-react';
import { ContextMenuTrigger } from 'react-contextmenu';
import PresetItem from '../../../stores/shared/PresetList/item';
import Preloader from '../../../components/Preloader';
import PresetListItem from './PresetIListtem';
import { ContextMenu, MenuItem } from '../../../components/ContextMenu';

import styles from './PresetList.module.scss';

const List = ({
    presets,
    loading,
    loaded,
    active,
    baseLink,
    onEditPresetClick,
    onOpenPresetClick,
    onDeletePresetClick,
}) => {
    const history = useHistory();

    const handleEditClick = (item) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        onEditPresetClick(item);
    };
    const handleCloseNewClick = (item) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        item.checkNew();
    };
    const handleOpenClick = (item, onlyNew = false) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        item.loadFilter().then(() => {
            onOpenPresetClick(item, onlyNew);
            history.push(`${baseLink}/${item.dealType}/${item.objectType}`);
        });
    };
    const handlePresetDeleteClick = (item) => () => {
        onDeletePresetClick(item);
    };
    return (
        <div className={styles.list__wrapper}>
            <div className={`${styles.list__preloader} ${loading ? styles['list__preloader--loading'] : ''}`}>
                <Preloader width={75} />
            </div>
            <div className={`${styles.list__content} ${loading ? styles['list__content--loading'] : ''} ${loaded ? styles['list__content--loaded'] : ''}`}>
                {
                    presets && presets.length
                        ? (
                            presets.map((item) => (
                                <Observer key={`preset-${item.id}`}>
                                    {() => (
                                        <>
                                            <ContextMenuTrigger id={`preset-list-item-${item.id}`}>
                                                <PresetListItem
                                                    item={item}
                                                    isActive={active === item.id}
                                                    onOpenClick={handleOpenClick(item)}
                                                    onEditClick={handleEditClick(item)}
                                                    onCloseNewClick={handleCloseNewClick(item)}
                                                    onNewOpenClick={handleOpenClick(item, true)}
                                                />
                                            </ContextMenuTrigger>
                                            <ContextMenu id={`preset-list-item-${item.id}`}>
                                                <MenuItem
                                                    className={styles.list__remove}
                                                    onClick={handlePresetDeleteClick(item)}
                                                >
                                                    Удалить
                                                </MenuItem>
                                            </ContextMenu>
                                        </>
                                    )}
                                </Observer>
                            ))
                        ) : (
                            <div className={styles.list__empty}>
                                Вы пока не создали ни одной подборки
                            </div>
                        )
                }
            </div>
        </div>
    );
};

List.propTypes = {
    presets: PropTypes.arrayOf(
        PropTypes.instanceOf(PresetItem),
    ),
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    active: PropTypes.number,
    baseLink: PropTypes.string,
    onEditPresetClick: PropTypes.func,
    onOpenPresetClick: PropTypes.func,
    onDeletePresetClick: PropTypes.func,
};

List.defaultProps = {
    presets: null,
    loading: true,
    loaded: false,
    active: undefined,
    onEditPresetClick: () => null,
    onOpenPresetClick: () => null,
    onDeletePresetClick: () => null,
    baseLink: '/list',
};

export default List;
