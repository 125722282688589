/* eslint-disable no-param-reassign */
const createReducer = (fieldType, fn) => (carry, current, index) => {
    const deeper = Boolean(current.fields);
    let updated = current;
    if (current.type === fieldType || (fieldType === 'field' && !deeper)) {
        updated = fn(current);
    }
    if (deeper) {
        carry[index] = {
            ...updated,
            fields: current.fields.reduce(createReducer(fieldType, fn), []),
        };
    } else {
        carry[index] = updated;
    }
    return carry;
};

const reduceFields = (fields = [], fn) => fields.reduce(createReducer('field', fn), []);
const reduceFieldsets = (fields = [], fn) => fields.reduce(createReducer('fieldset', fn), []);
const reduceFieldGroups = (fields = [], fn) => fields.reduce(createReducer('fieldgroup', fn), []);

export default reduceFields;
export {
    reduceFieldsets,
    reduceFieldGroups,
};
