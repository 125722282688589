import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '../Button';
import SVGIcon from '../Icons/SVGIcon';
import { SettingsWhiteIcon } from '../Icons/icons';

import styles from './MessageDialog.module.scss';

const DefaultButton = ({
    onClick,
    children,
}) => (
    <IconButton
        color="blue"
        icon={(<SVGIcon SVGElement={SettingsWhiteIcon} height={14} />)}
        size="lg"
        block
        className={styles.message__button}
        onClick={onClick}
    >
        {children}
    </IconButton>
);

DefaultButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
};

const MessageDialog = ({
    children,
    title,
    buttonText,
    onClick,
    components,
    className,
}) => (
    <div className={`${styles.message__container} ${className}`}>
        <h2 className={styles.message__title}>
            {title}
        </h2>
        <div className={styles.message__text}>
            {children}
        </div>
        {
            buttonText
                ? (
                    <components.ButtonWrapper onClick={onClick}>
                        {buttonText}
                    </components.ButtonWrapper>
                )
                : null
        }
    </div>
);

MessageDialog.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
    ]),
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    components: PropTypes.shape({
        ButtonWrapper: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
    }),
    className: PropTypes.string,
};

MessageDialog.defaultProps = {
    components: {
        ButtonWrapper: DefaultButton,
    },
    className: '',
    onClick: () => {},
    buttonText: null,
    children: null,
};

export default MessageDialog;
