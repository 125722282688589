/* global window */
import { observable, toJS } from 'mobx';
import isEqual from 'lodash.isequal';
import { formatISO } from 'date-fns';
import { ObjectService, StorageService, SocialNetworkService } from '../../../services/client';
import forms from '../../../forms/client/objects-filter';
import VkService from '../../../utils/vk-service';
import ObjectList from '../../shared/RefactoredObjectList/ObjectList';
import { DEAL_TYPE_MAP, OBJECT_TYPE_MAP } from '../../../constants/shared';

class ClientObjectList extends ObjectList {
    @observable
    socialNetworkPostData = null;

    @observable
    socialNetworkLoading = false;

    constructor(strategy) {
        super(ObjectService, strategy, forms);
        this.restoreSort();
    }

    // eslint-disable-next-line class-methods-use-this
    onLoad(newListItems) {
        const { list, strategy } = this;
        const { dealType, objectType } = strategy;
        const objectIds = newListItems.map((item) => item.id);
        this.dataSource.getPriceStats({
            dealType: dealType.toLowerCase(),
            objectIds,
            objectType: OBJECT_TYPE_MAP[objectType],
        })
            .then((response) => {
                response.forEach((item, dIndex) => {
                    const index = list.findIndex((listItem) => listItem.id === item.id);
                    if (index > -1) {
                        if (dIndex === response.length - 1) {
                            this.list[index].setData({ priceDynamic: item.dynamic });
                        } else {
                            this.list[index].set('priceDynamic', item.dynamic);
                        }
                    }
                });
            })
            .catch(() => {});
    }

    storeSort() {
        const { strategy: { sortData, objectType, dealType }, storageName } = this;
        const sort = {
            ...toJS(sortData),
            objectType,
            dealType,
        };
        StorageService.session.add('sortData', { [storageName]: sort });
    }

    restoreSort() {
        const { storageName } = this;
        const savedSortData = StorageService.session('sortData');
        if (savedSortData && savedSortData[storageName]) {
            const { field, direction } = savedSortData[storageName];
            this.setSort(field, direction);
        }
    }

    setSort(field, direction) {
        super.setSort(field, direction);
        this.storeSort();
    }

    getPresetFilterData() {
        const { strategy: { objectType, dealType } } = this;
        return super.getPresetFilterData()
            .then(({ filter: appliedFilter, currentFilter }) => {
                const filter = { ...appliedFilter };
                // Prepare applied form filter statusIds to filter flag
                if (appliedFilter.allGroup) {
                    delete filter.mainGroup;
                    filter.statusIds = 'allGroup';
                } else if (appliedFilter.mainGroup) {
                    delete filter.mainGroup;
                    filter.statusIds = 'mainGroup';
                } else if (appliedFilter.archiveGroup) {
                    delete filter.archiveGroup;
                    filter.statusIds = 'archiveGroup';
                }
                const { statusIds, ...currentFilterWithoutStatusIds } = currentFilter;
                return {
                    filter: { ...currentFilterWithoutStatusIds, [statusIds]: 1 },
                    objectType: OBJECT_TYPE_MAP[objectType],
                    dealType: DEAL_TYPE_MAP[dealType],
                    // compare if applied filter is equal to current values in filter form
                    viewedAt: isEqual(filter, currentFilter)
                        ? formatISO(this.viewedAt)
                        : null,
                };
            });
    }

    // eslint-disable-next-line class-methods-use-this
    async publishSocialNetworkPost(socialNetworkPostData, closeDialog) {
        this.socialNetworkLoading = true;
        if (socialNetworkPostData.socialNetwork === 'vk') {
            const attachments = await VkService.prepareAttachments(
                socialNetworkPostData.postData.images,
            );
            VkService.postDataOnWall(socialNetworkPostData.postData.info, attachments);
            closeDialog();
        } else {
            SocialNetworkService.postOkData(socialNetworkPostData.postData)
                .then((data) => {
                    closeDialog();
                    window.open(data.url).focus();
                })
                .catch(() => {})
                .finally(() => {
                    this.socialNetworkLoading = false;
                });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    prepareSocialNetworkPostData(socialNetwork, objectId, objectType, openDialog) {
        SocialNetworkService.prepareData(objectId, objectType)
            .then((data) => {
                this.socialNetworkPostData = {
                    socialNetwork,
                    postData: data,
                };
                openDialog();
            })
            .catch(() => {});
    }
}

export default ClientObjectList;
