import { ObjectService } from '../../../services/client';
import { SteadDetailed } from '../../shared/RefactoredObjectDetailed';
import forms from '../../../forms/client/objects-detailed';
import { withBaseDetailed, withClient } from './mixins';

class ClientSteadDetailed extends SteadDetailed {
    constructor() {
        super(ObjectService);
        const { formName } = this;
        this.fields = forms[formName];
    }
}

export default withBaseDetailed(withClient(ClientSteadDetailed));
