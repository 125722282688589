/* global alert */
import { runInAction } from 'mobx';
import createForm from '../../../helpers/Form';
import {
    registerKeyFields,
    registerOrganizationContactsFields,
    registerOrganizationCredentialsFields,
} from '../../../forms/client';
import DataSource, { STATES } from '../../shared/DataSource';
import { UserService } from '../../../services/client';

class Organization {
    keyForm = createForm(registerKeyFields);

    contactsForm = createForm(registerOrganizationContactsFields);

    credentialsForm = createForm(registerOrganizationCredentialsFields);

    key = undefined;

    contacts = {};

    credentials = {};

    constructor() {
        const {
            keyForm,
            contactsForm,
            credentialsForm,
        } = this;
        keyForm.on('success', this.saveKey.bind(this));
        contactsForm.on('success', this.saveContacts.bind(this));
        credentialsForm.on('success', this.saveCredentials.bind(this));
        this.service = new DataSource(UserService, STATES.DEFAULT);
    }

    saveKey({ key }) {
        const { service } = this;
        return service.checkKey(key)
            .then((response) => {
                const { canBeUsedForCompanyCreation } = response;
                if (!canBeUsedForCompanyCreation) {
                    throw new Error('Provided key cant be used for new company registration');
                }
                runInAction(() => {
                    this.key = key;
                });
                return Promise.resolve();
            })
            .catch((e) => {
                alert(e.message);
                return Promise.reject(new Error(e.message));
            });
    }

    saveContacts(contacts) {
        this.contacts = contacts;
        return Promise.resolve();
    }

    saveCredentials(credentials) {
        const { key, contacts, service } = this;
        return service.createOrganization({
            activationKey: key,
            name: credentials.name,
            inn: credentials.tin,
            phone: contacts.phone,
            email: contacts.email,
            note: credentials.comment,
            addresses: [{ address: credentials.address }],
        })
            .then(() => {
                runInAction(() => {
                    this.key = undefined;
                    this.credentials = undefined;
                });
                return Promise.resolve();
            })
            .catch((e) => {
                alert(e.message);
                return Promise.reject(new Error(e.message));
            });
    }
}

const createOrganizationStore = () => new Organization();

export default Organization;
export {
    createOrganizationStore,
};
