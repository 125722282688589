import SearchableDirectory from './SearchableDirectory';

class CityDirectory extends SearchableDirectory {
    loadData(query = '', ctx = {}) {
        return super.loadData(query, ctx)
            .then((response) => {
                const list = response.map(({
                    value,
                    label,
                    extra: { cityType: { shortname }, ...extra },
                }) => ({
                    value,
                    extra,
                    label: `${shortname ? `${shortname} ` : ''}${label}`,
                }));
                return Promise.resolve(list);
            })
            .catch(() => Promise.reject());
    }
}
export default CityDirectory;
