const createPriceStatsService = (request) => ({
    getStats: (filter = {}) => (
        request.post(
            '/price-analysis/points',
            filter,
        )
    ),
});

export default createPriceStatsService;
