export default [{
    name: 'hardwareName',
    type: 'text',
    label: 'Имя компьютера',
    rules: 'required|string|min:10|max:1024',
    size: 'lg',
    tooltip: 'Дайте имя компьютеру пользователя для дальнейшей идентификации в системе.<br /><br />Максимальная длина 1024 символа',
}, {
    name: 'address',
    type: 'text',
    label: 'Адрес',
    rules: 'required|string',
    size: 'lg',
}, {
    name: 'organizationId',
    type: 'select',
    label: 'Компания',
    rules: 'required',
    readonly: true,
    options: [],
    size: 'lg',
}];
