import FlatStrategy from './FlatStrategy';
import HouseItem from '../item/HouseItem';
import { TABLE_CELL } from '../../../../constants/shared';

class HouseStrategy extends FlatStrategy {
    constructor() {
        super();
        this.objectType = 'houses';
        this.Item = HouseItem;
    }

    // eslint-disable-next-line class-methods-use-this
    get sortOrder() {
        const { dealType } = this;
        return dealType === 'rent'
            ? [
                TABLE_CELL.DISTRICT_NAME.sortName,
                TABLE_CELL.ROOMS_COUNT.sortName,
                TABLE_CELL.STREET_NAME.sortName,
                TABLE_CELL.SQUARE.sortName,
                TABLE_CELL.PRICE_DELIMITED.sortName,
                TABLE_CELL.HOUSE_TYPE_NAME.sortName,
            ]
            : [
                TABLE_CELL.ROOMS_COUNT.sortName,
                TABLE_CELL.DISTRICT_NAME.sortName,
                TABLE_CELL.PRICE_DELIMITED.sortName,
                TABLE_CELL.STREET_NAME.sortName,
                TABLE_CELL.HOUSE_NUMBER.sortName,
                TABLE_CELL.SQUARE.sortName,
                TABLE_CELL.FLOOR_COUNT.sortName,
                TABLE_CELL.HOUSE_TYPE_NAME.sortName,
                TABLE_CELL.DISTRICT_LANDMARK_NAME.sortName,
                TABLE_CELL.DATE_OF_CONSTRUCTION.sortName,
            ];
    }
}

export default HouseStrategy;
