import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.ROW_SELECT,
    width: 4,
    sortName: null,
}, {
    ...TABLE_CELL.OBJECT_TYPE,
    width: 3,
    sortName: null,
}, {
    ...TABLE_CELL.ROOMS_COUNT,
    width: 5,
    textAlign: 'center',
}, {
    ...TABLE_CELL.SHORT_DISTRICT_NAME,
    width: 6,
    fields: [{
        name: 'cityDistrictIds',
    }],
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 22,
    textAlign: 'left',
}, {
    ...TABLE_CELL.HOUSE_NUMBER,
    title: '№',
    width: 9,
}, {
    ...TABLE_CELL.FLOOR_WITH_FLOOR_COUNT_WITH_WALL_MATERIAL,
    width: 13,
}, {
    ...TABLE_CELL.SQUARE_TOTAL,
    width: 12,
}, {
    ...TABLE_CELL.PRICE_DELIMITED,
    width: 7,
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 15,
    sortName: null,
}, {
    ...TABLE_CELL.IMAGES,
    width: 5,
    sortName: null,
}];
