import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'filter',
    fields: [{
        name: 'query',
        type: 'text',
        label: 'Поиск',
        placeholder: 'ID, Компания, почта, телефон',
        size: 'md',
        icon: 'search',
        rules: 'min:3',
    }, {
        name: 'createdAtFrom',
        type: 'datepicker',
        label: 'Дата от',
        startOfDay: true,
        placement: 'bottom-end',
        clearable: true,
        size: 'md',
    }, {
        name: 'createdAtTo',
        type: 'datepicker',
        label: 'Дата до',
        placement: 'bottom-end',
        endOfDay: true,
        clearable: true,
        size: 'md',
    }, {
        name: 'actionType',
        type: 'directorySelect',
        size: 'md',
        label: 'Действие',
        multiple: true,
        directory: DIRECTORIES.USER_ACTION_TYPE,
        clearable: true,
        isDropdown: true,
    }],
}];
