import { runInAction } from 'mobx';
import Directory from '../../../shared/Directories/entities/Directory';

class UserActionsDirectory extends Directory {
    loadData() {
        if (this.loaded || this.loading) {
            return Promise.resolve(this.options);
        }
        const { directory } = this;
        return this.dataSource.getUsersActionTypes(directory)
            .then((list = []) => {
                runInAction(() => {
                    this.loaded = true;
                });
                this.items.addList(list.map(({ description, id }) => ({
                    value: id,
                    label: description,
                })));
                return Promise.resolve(this.options);
            })
            .catch(() => {});
    }
}

export default UserActionsDirectory;
