import React from 'react';
import PropTypes from 'prop-types';
import { IconInput as Input } from '../../../components/Input';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { inputIcons } from '../../../components/Icons/icons';

const IconInput = ({ icon, ...props }) => {
    const iconElement = `${icon.charAt(0).toUpperCase() + icon.slice(1)}Icon`;
    return (
        <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            icon={<SVGIcon SVGElement={inputIcons[iconElement]} />}
        />
    );
};

IconInput.propTypes = {
    icon: PropTypes.string,
};

IconInput.defaultProps = {
    icon: '',
};

export default IconInput;
