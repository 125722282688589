import DIRECTORIES from './directories';
import TABLE_CELL from './table-cell';

const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy'; // https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
const DEFAULT_TIME_FORMAT = 'HH:mm'; // https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
const DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT}, ${DEFAULT_TIME_FORMAT}`;
const SCROLL_WIDTH_LAG = 30;
const THUMBNAIL_SLIDER = 'thumbnail-slider';
const PRICE_STATS = 'price-stats';
const SOURCE_LIST = 'source-list';
const STATUS_FORM = 'status-form';
const EMPTY_DATA_STRING = '-/-';
// Задержка между кликами при двойном нажатии
const DOUBLE_CLICK_DELAY = 200;

// Constants for table
const PRICE_DELIMITER = 1000;
const PRICE_FLOAT_CHARS = 1;

// Constants for map
const DEFAULT_CENTER_POSITION = [56.012980, 92.865710];
const MAP_TILE_LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const DADATA_GEODATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

const TOKEN_UPDATE_LAG = 10000; // ms
const REQUEST_REPEAT_INTERVAL = 10000; // ms

const SIZE = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    EXTRA_LARGE: 'xlg',
};

const CONTENT_INNER_HEIGHT = {
    AUTO: 'auto',
    SMALL: 'sm',
};

const POSITION = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
};

// Constants for multiple tabs popup
const TAB_TYPE = {
    EDIT_OBJECT: 'editObject',
    VIEW_OBJECT: 'viewObject',
    RELATED_OBJECTS: 'relatedObjects',
    SIMILAR_OBJECTS: 'similarObjects',
    NOTEPAD_OBJECTS: 'notepadObjects',
    CREATE_OBJECT: 'createObject',
    SEARCH_BY_RADIUS: 'searchByRadius',
};

const SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC',
};

const DEAL_TYPE = {
    SALE: 'SALE',
    RENT: 'RENT',
};

const DEAL_TYPE_NAME = {
    SALE: 'Продажа',
    RENT: 'Аренда',
};

const OBJECT_TYPE = {
    FLAT: 'FLAT',
    HOUSE: 'HOUSE',
    STEAD: 'STEAD',
    COMMERCIAL: 'COMMERCIAL',
};

const OBJECT_TYPE_NAME = {
    FLAT: 'Квартира',
    HOUSE: 'Дом',
    STEAD: 'Земля',
    COMMERCIAL: 'Нежилое',
};

const OBJECT_TYPE_MAP = {
    flats: OBJECT_TYPE.FLAT,
    steads: OBJECT_TYPE.STEAD,
    houses: OBJECT_TYPE.HOUSE,
    commercial: OBJECT_TYPE.COMMERCIAL,
};

const DEAL_TYPE_MAP = {
    rent: DEAL_TYPE.RENT,
    sale: DEAL_TYPE.SALE,
};

const SIMILARITY_TEXT = {
    low: 'Небольшая схожесть',
    medium: 'Средне похож',
    high: 'Сильно похож',
};

const NOTEPAD_MESSAGES = {
    objectAddedToNotebook: 'Объект успешно добавлен в блокнот',
    objectsAddedToNotebook: 'Объекты успешно добавлены в блокнот',
};

const OBJECT_HIGHLIHT_TEXT = {
    'must-check': '',
    'updated-by-parser': 'Объект обновился',
    'status-reason-deposit': 'Задаток',
    'restored-by-parser': 'Восстановлен парсером из архива, т.к. поступил объект-дубль',
};

const DIALOG_DURATION_DELAY = 1000;
const MAX_DELAY = 2 ** 31 - 1;

const EXPORT_TYPE = {
    XLS: 1,
    DOC: 2,
    OPENOFFICE_CALC: 3,
    OPENOFFICE_DOC: 4,
    YANDEX: 5,
    DOMCLICK: 6,
    CIAN: 7,
    PDF: 8,
};

const EXPORT_STATUS = {
    FAILED: 'failed',
    IN_PROGRESS: 'running',
    DONW: 'done',
};

const PRICE_ANALYSIS_STATUS = {
    DONE: 'done',
    FAILED: 'failed',
    IN_PROGRESS: 'running',
};

const EXPORT_PRESET_MAP = {
    0: 'ALL',
    1: 'CLIENT',
    2: 'CLIENT_WITHOUT_HOUSE',
};

export {
    DIRECTORIES,
    TABLE_CELL,
    DEFAULT_DATE_FORMAT,
    DEFAULT_TIME_FORMAT,
    DATE_TIME_FORMAT,
    SCROLL_WIDTH_LAG,
    THUMBNAIL_SLIDER,
    STATUS_FORM,
    EMPTY_DATA_STRING,
    PRICE_DELIMITER,
    PRICE_FLOAT_CHARS,
    DEFAULT_CENTER_POSITION,
    MAP_TILE_LAYER_URL,
    DADATA_GEODATA_URL,
    SIZE,
    CONTENT_INNER_HEIGHT,
    POSITION,
    TAB_TYPE,
    DOUBLE_CLICK_DELAY,
    SORT_ORDER,
    OBJECT_TYPE,
    DEAL_TYPE,
    DEAL_TYPE_NAME,
    DEAL_TYPE_MAP,
    DIALOG_DURATION_DELAY,
    OBJECT_TYPE_NAME,
    OBJECT_TYPE_MAP,
    SIMILARITY_TEXT,
    NOTEPAD_MESSAGES,
    OBJECT_HIGHLIHT_TEXT,
    TOKEN_UPDATE_LAG,
    MAX_DELAY,
    EXPORT_TYPE,
    EXPORT_STATUS,
    EXPORT_PRESET_MAP,
    REQUEST_REPEAT_INTERVAL,
    PRICE_STATS,
    SOURCE_LIST,
    PRICE_ANALYSIS_STATUS,
};
