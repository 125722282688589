import { DIRECTORIES } from '../../../constants/operator';
import { OBJECT_SHOW_ALL_GROUP } from '../../../config/client';

const groups = [{
    value: 'mainGroup',
    label: 'В базе',
}, {
    value: 'archiveGroup',
    label: 'Архив',
}];

if (OBJECT_SHOW_ALL_GROUP) {
    groups.unshift({
        value: 'allGroup',
        label: 'Все',
    });
}

export default {
    type: 'fieldset',
    name: 'baseStatusGroups',
    fields: [{
        name: 'baseStatusGroups',
        type: 'fieldgroup',
        label: 'Группы',
        fields: [{
            type: 'radioList',
            name: 'statusIds',
            size: 'md',
            rows: 1,
            value: 'mainGroup',
            options: groups,
        }],
    }, {
        name: 'objectStatusReasonIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_STATUS_REASON,
        label: 'Причина',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }],
};
