export default [{
    name: 'monthCount',
    type: 'text',
    label: 'Количество месяцев',
    rules: 'required|numeric|min:1',
    size: 'lg',
}, {
    name: 'cost',
    type: 'text',
    label: 'Сумма',
    readOnly: true,
    size: 'lg',
}];
