import React from 'react';
import PropTypes from 'prop-types';

import styles from './ObjectPageView.module.scss';

const RelatedTypeTab = ({
    onClick,
    type,
    active,
    className,
    activeClassName,
    children,
}) => {
    const handleClick = (e) => {
        if (e) {
            e.preventDefault();
        }
        onClick(e, type);
    };
    return (
        <button
            type="button"
            onClick={handleClick}
            className={`
                ${styles['page-view__menu-item']}
                ${active ? `${styles['page-view__menu-item--selected']} ${activeClassName}` : ''}
                ${className}
            `}
        >
            <div className={styles['page-view__menu-title']}>
                {children}
            </div>
        </button>
    );
};

RelatedTypeTab.propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
};

RelatedTypeTab.defaultProps = {
    onClick: () => null,
    active: false,
    type: null,
    className: '',
    activeClassName: '',
};

export default RelatedTypeTab;
