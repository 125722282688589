import React from 'react';
import { SelectedRow, SelectedRowWithHint } from '../../../components/TableSelected';
import { defaultProps, propTypes } from './propTypes';

import styles from './SelectedObject.module.scss';

const SelectedStead = ({
    store,
}) => (
    <>
        <SelectedRow name="В собственности" classNames={{ row: styles.selected__row }}>
            {store.isOwned ? 'Да' : 'Нет'}
        </SelectedRow>
        <SelectedRowWithHint name="Подъезд" classNames={{ row: styles.selected__row }}>
            {store.passage?.label}
        </SelectedRowWithHint>
        <SelectedRow name="Коммуникации" classNames={{ row: styles.selected__row }}>
            {store.hasCommunications ? 'Да' : 'Нет'}
        </SelectedRow>
        <SelectedRow name="Электричество" classNames={{ row: styles.selected__row }}>
            {store.hasElectricity ? 'Да' : 'Нет'}
        </SelectedRow>
        <SelectedRow name="Лес рядом" classNames={{ row: styles.selected__row }}>
            {store.hasForestNearby ? 'Да' : 'Нет'}
        </SelectedRow>
    </>
);

SelectedStead.propTypes = { store: propTypes.store };
SelectedStead.defaultProps = { store: defaultProps.store };

export default SelectedStead;
