import React from 'react';
import PropTypes from 'prop-types';
import useClickPreventionOnDoubleClick from '../../hooks/shared/double-click';

const PropsWrapper = ({
    children,
    onClick,
    onDoubleClick,
    onRightClick,
}) => {
    const childrenWithProps = React.Children.map(
        (children),
        (child) => React.cloneElement(child, {
            onClick,
            onDoubleClick,
            onContextMenu: onRightClick,
        }),
    );
    return childrenWithProps;
};

/**
 * Компонент заточен под разделение одинарного и
 * двойного клика по компоненту
 *
 * @param {object} param0 -
 * @param {Function} param0.onClick - метод на одиночный клик
 * @param {Function} param0.onDoubleClick - метод на двойной клик
 * @param {Function} param0.onRightClick - метод на правый клик мыши
 * @param {Function} param0.children - содержимое элемента
 * @returns {React.FunctionComponent} - элемент
 */
const ClickableBox = ({
    onClick,
    onDoubleClick,
    onRightClick,
    children,
}) => {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
        onClick,
        onDoubleClick,
    );
    return (
        <PropsWrapper
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            onRightClick={onRightClick}
        >
            {children}
        </PropsWrapper>
    );
};

ClickableBox.propTypes = {
    onClick: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onRightClick: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
};

ClickableBox.defaultProps = {
    children: null,
    onRightClick: () => null,
};

export default ClickableBox;
