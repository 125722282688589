import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';
import { POSITION } from '../../../constants/shared';

import styles from './LoginPage.module.scss';

const LoginField = ({
    label,
    id,
    children,
    tooltip,
}) => (
    <div className={styles.login__field}>
        {
            label
                ? (
                    <Label htmlFor={id}>
                        {
                            tooltip
                                ? (
                                    <div
                                        className={styles.tooltip}
                                        data-tip={tooltip}
                                        data-place={POSITION.RIGHT}
                                        data-class={styles.tooltip__content}
                                        data-multiline
                                    >
                                        ?
                                    </div>
                                )
                                : null
                        }
                        {label}
                    </Label>
                )
                : null
        }
        {children}
    </div>
);

LoginField.propTypes = {
    tooltip: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    children: PropTypes.element.isRequired,
};

LoginField.defaultProps = {
    tooltip: '',
    label: '',
};

export default LoginField;
