export default [{
    name: 'login',
    type: 'text',
    label: 'Логин',
    rules: 'required|numeric|min:1',
    size: 'lg',
}, {
    name: 'email',
    type: 'text',
    label: 'Почта',
    rules: 'required|email',
    size: 'lg',
    tooltip: 'Это почта, которая была указана при регистрации компании',
}];
