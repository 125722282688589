import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-tabs';

import styles from './CustomTabs.module.scss';

const CustomTab = ({
    children,
    className,
    activeClassName,
    onClick,
    ...props
}) => (
    <Tab
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onClick={onClick}
        className={`${className} ${styles.tab}`}
        selectedClassName={`${activeClassName} ${styles['tab--selected']}`}
    >
        <div className={`${styles['tab-child']}`}>
            {children}
        </div>
    </Tab>
);

CustomTab.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    onClick: PropTypes.func,
};

CustomTab.defaultProps = {
    className: '',
    activeClassName: '',
    onClick: () => null,
};

CustomTab.tabsRole = 'Tab';

export default CustomTab;
