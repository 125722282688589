import { DIRECTORIES } from '../../../constants/shared';

export default [{
    type: 'fieldset',
    name: 'hidden',
    hidden: true,
    fields: [{
        name: 'passageIds',
        type: 'directorySelect',
        hidden: true,
        directory: DIRECTORIES.PASSAGE,
        multiple: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset', // stead type
    name: 'steadType',
    fields: [{
        name: 'objectSteadTypeIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_STEAD_TYPE,
        label: 'Назначение',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset', // location
    name: 'location',
    fields: [{
        name: 'areaIds',
        type: 'directorySearch',
        directory: DIRECTORIES.AREA,
        label: 'Район субъекта',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'cityIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY,
        label: 'Населенный пункт',
        multiple: true,
        clearable: true,
        searchable: true,
        withDefault: true,
        isDropdown: true,
    }, {
        name: 'cityDistrictIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT,
        label: 'Район',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'streetIds',
        type: 'directorySearch',
        directory: DIRECTORIES.STREETS,
        label: 'Улица',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
        optionValuesIsArray: true,
    }, {
        name: 'cityDistrictLandmarkIds',
        type: 'directorySearch',
        directory: DIRECTORIES.CITY_DISTRICT_LANDMARK,
        label: 'Ориентир',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset', // stead
    name: 'stead',
    fields: [{
        name: 'square',
        type: 'fieldgroup',
        label: 'Площадь, сот',
        fields: [{
            name: 'totalAreaFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'totalAreaTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }, {
        name: 'price',
        type: 'fieldgroup',
        label: 'Цена',
        fields: [{
            name: 'priceFrom',
            type: 'numeric',
            prefix: 'от',
        }, {
            name: 'priceTo',
            type: 'numeric',
            prefix: 'до',
        }],
    }, {
        name: 'passageIds',
        type: 'directorySelect',
        directory: DIRECTORIES.PASSAGE,
        label: 'Подъезд',
        multiple: true,
        searchable: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset', // contacts
    name: 'contacts',
    fields: [{
        name: 'contact',
        type: 'text',
        label: 'Контакт',
    }, {
        name: 'phone',
        type: 'phone',
        label: 'Телефон',
        size: 'sm',
        rules: 'regex:/^[+]?[0-9)(-]{11,15}/',
    }],
}, {
    type: 'fieldset', // contacts
    name: 'hidden',
    hidden: true,
    fields: [{
        name: 'objectSelectionId',
        type: 'integer',
        clearable: true,
        hidden: true,
    }, {
        name: 'updatedAtFrom',
        type: 'datepicker',
        placement: 'bottom-end',
        clearable: true,
        hidden: true,
    }, {
        name: 'updatedAtTo',
        type: 'datepicker',
        placement: 'bottom-end',
        clearable: true,
        hidden: true,
    }],
}];
