const createSocialNetworkService = (request) => ({
    // Общие запросы
    prepareData: (objectId, objectType) => (
        request.get(`/snw/${objectType}/${objectId}/prepare-post`)
            .then((data) => Promise.resolve(data))
    ),

    // ВКонтакте
    uploadImages: (payload) => (
        request.post('/snw/vk/photos', payload)
            .then((data) => Promise.resolve(data))
            .catch(() => Promise.resolve(null))
    ),

    // Одноклассники
    unbindOkAccount: () => (
        request.get('/snw/ok/logout')
            .then((data) => Promise.resolve(data))
    ),

    getOkAccountInfo: () => (
        request.get('/snw/ok/user-info')
            .then((data) => Promise.resolve(data))
    ),

    postOkData: (payload) => (
        request.post('/snw/ok/post', payload)
            .then((data) => Promise.resolve(data))
    ),
});

export default createSocialNetworkService;
