import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { useHistory, Redirect } from 'react-router-dom';
import useStores from '../../../hooks/client/use-stores';
import LoginPage, { LoginPageField } from '../../shared/LoginPage';
import { Button } from '../../../components/Button';
import FormElements from '../Form';

import styles from '../../shared/LoginPage/LoginPage.module.scss';
import registrationStyles from './Register.module.scss';

const RegisterUserCredentials = () => {
    const history = useHistory();
    const { userStore = {} } = useStores();
    const { credentialsForm = { $: () => null }, key } = userStore;
    useEffect(() => {
        if (credentialsForm && credentialsForm.on) {
            return credentialsForm.on('done', () => {
                history.push('password');
            });
        }
        return undefined;
    }, [credentialsForm]);
    if (!key) return <Redirect to="key" />;
    return (
        <LoginPage
            title="Регистрация"
            className={registrationStyles.register__user}
            footer={(
                <Button
                    size={Button.SIZE.MEDIUM}
                    color={Button.COLOR.TRANSPARENT}
                    onClick={() => history.goBack()}
                >
                    Назад
                </Button>
            )}
        >
            <Observer>
                {() => (
                    <form
                        onSubmit={credentialsForm.onSubmit}
                    >
                        <FormElements
                            form={credentialsForm}
                            elements={credentialsForm.render || []}
                            components={{
                                FieldWrapper: LoginPageField,
                            }}
                        />
                        <div className={styles.login__submit}>
                            <Button
                                type="submit"
                                color={Button.COLOR.BLUE}
                                size={Button.SIZE.LARGE}
                                block
                                disabled={credentialsForm.loading}
                            >
                                Дальше
                            </Button>
                        </div>
                    </form>
                )}
            </Observer>
        </LoginPage>
    );
};

export default RegisterUserCredentials;
