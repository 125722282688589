import requestFactory from '../../utils/request';
import createSessionService from './SessionService';
import createDirectoryService from './DirectoryService';
import createContactService from './ContactService';
import createObjectService from './ObjectService';
import createStorageService from './StorageService';
import createDaDataService from '../shared/DaDataService';
import createOperatorService from './OperatorService';
import createBillingService from './BillingService';
import createActivationKeyService from './ActivationKeyService';
import createNotepadService from '../shared/NotepadService';
import createPresetService from '../shared/PresetService';
import {
    API_PROTOCOL,
    API_HOST,
    API_PREFIX,
} from '../../config/operator';

const apiRequest = requestFactory(API_PROTOCOL, API_HOST, API_PREFIX);

const SessionService = createSessionService(apiRequest);
const ObjectService = createObjectService(apiRequest);
const DirectoryService = createDirectoryService(apiRequest);
const ContactService = createContactService(apiRequest);
const StorageService = createStorageService();
const DaDataService = createDaDataService(apiRequest);
const OperatorService = createOperatorService(apiRequest);
const ActivationKeyService = createActivationKeyService(apiRequest);
const NotepadService = createNotepadService(apiRequest);
const BillingService = createBillingService(apiRequest);
const PresetService = createPresetService(apiRequest);

export default apiRequest;
export {
    SessionService,
    ObjectService,
    DirectoryService,
    ContactService,
    StorageService,
    DaDataService,
    OperatorService,
    BillingService,
    ActivationKeyService,
    NotepadService,
    PresetService,
};
