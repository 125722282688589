/* global alert */

const withPayment = (options) => (Store) => (
    class extends Store {
        paymentService = () => {};

        constructor(fields) {
            super(fields);
            const { paymentService } = options;
            this.paymentService = paymentService;
            this.observeMonthField();
        }

        observeMonthField() {
            const form = this;
            const { paymentService } = this;
            const costField = form.$('cost');
            const monthCountField = form.$('monthCount');
            monthCountField.observe(({ change }) => {
                paymentService.getAmount({ months: change.newValue })
                    .then((data) => {
                        costField.set(data.amount);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-alert
                        alert(e.message);
                    });
            });
        }
    }
);

export default withPayment;
