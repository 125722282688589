import { computed } from 'mobx';
import DataSource, { STATES } from '../../../shared/DataSource';
import { ContactService } from '../../../../services/operator';

class Contact {
    minQueryLength = 3;

    constructor() {
        this.dataSource = new DataSource(ContactService, STATES.DEFAULT);
    }

    @computed
    get loading() {
        return this.dataSource.loading;
    }

    searchByPhone(query, phoneType) {
        const { minQueryLength } = this;
        if (query.length < minQueryLength) return Promise.reject();
        return this.dataSource.findContactByPhone(query, phoneType)
            .then((response) => (
                Promise.resolve(response.map((contact) => ({
                    value: contact.id,
                    label: contact.phones.find(({ phoneType: { id } }) => id === 1).phone,
                    data: contact,
                })))
            ));
    }

    searchByName(query) {
        const { minQueryLength } = this;
        if (query.length < minQueryLength) return Promise.reject();
        return this.dataSource.findContactByName(query)
            .then((response) => (
                Promise.resolve(response.map((contact) => ({
                    value: contact.id,
                    label: `${contact.name} - ${contact.phones.find(({ phoneType: { id } }) => id === 1).phone}`,
                    data: contact,
                })))
            ));
    }
}

export default Contact;
