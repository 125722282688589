import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu } from 'react-contextmenu';

import styles from './ContextMenu.module.scss';

const ModifiedContextMenu = ({ className, ...props }) => (
    <ContextMenu
        className={`${styles['context-menu']} ${className}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    />
);

ModifiedContextMenu.propTypes = {
    className: PropTypes.string,
};

ModifiedContextMenu.defaultProps = {
    className: '',
};

export default ModifiedContextMenu;
