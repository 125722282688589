const createReducer = (pathname) => {
    const createBreadcrumbs = (
        carry,
        {
            link,
            title,
            addToBreadcrumbs,
            routes: nested,
        },
    ) => {
        const re = new RegExp(`${link}($|/)`);
        if (!re.test(pathname) || !addToBreadcrumbs || !title) {
            return carry;
        }
        carry.push({ title, link });
        if (nested && nested.length) {
            carry.concat(nested.reduce(createBreadcrumbs, carry));
        }
        return carry;
    };
    return createBreadcrumbs;
};

export default (pathname, routes) => routes.reduce(createReducer(pathname), []);
