const createPresetService = (request) => ({
    getList: () => request.get('/objects/selections'),
    create: (preset) => request.post('/objects/selections', preset),
    updateName: (id, name) => request.put(`/objects/selections/${id}/name`, { name }),
    updateFilter: (id, filter) => request.put(`/objects/selections/${id}/filter`, { ...filter }),
    updateViewed: (id) => request.patch(`/objects/selections/${id}`),
    remove: (id) => request.delete(`/objects/selections/${id}`),
    loadFilter: (id) => request.get(`/objects/selections/${id}/filter`),
});

export default createPresetService;
