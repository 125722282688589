import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import FormElements from '../Form';
import { Button } from '../../../components/Button';
import DirectoryEditField from './DirectoryEditField';
import { Form } from '../../../helpers/Form';
import styles from './DirectoryPage.module.scss';
import useStores from '../../../hooks/operator/use-stores';

const DirectoryForm = ({ form, button }) => {
    const { directoriesStore } = useStores();
    React.useEffect(() => {
        if (form && form.fields && directoriesStore.city && directoriesStore.city.default) {
            if (form.has('suburbOfCityId')) {
                form.$('suburbOfCityId').set('value', directoriesStore.city.default.value);
            }
        }
    }, [form]);
    return (
        form
            ? (
                <form
                    className={styles['directory-edit']}
                    onSubmit={form.onSubmit}
                >
                    <FormElements
                        form={form}
                        elements={form.render}
                        components={{
                            FieldWrapper: DirectoryEditField,
                        }}
                    />
                    <div className={styles['directory-edit__submit']}>
                        <Observer>
                            {() => (
                                <Button
                                    type="submit"
                                    disabled={form.loading}
                                    color={Button.COLOR.GREEN}
                                    size={Button.SIZE.LARGE}
                                    block
                                >
                                    {button}
                                </Button>
                            )}
                        </Observer>
                    </div>
                </form>
            )
            : null
    );
};

DirectoryForm.propTypes = {
    form: PropTypes.instanceOf(Form),
    button: PropTypes.string,
};

DirectoryForm.defaultProps = {
    form: undefined,
    button: 'Сохранить',
};

export default DirectoryForm;
