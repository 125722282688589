import { computed, observable } from 'mobx';
import { withAddressField, withObjectDetailedField } from '../../../helpers/Form/mixins';
import HouseDirectory from '../Directories/entities/HouseDirectory';
import ObjectDetailed from './ObjectDetailed';

class HouseDetailed extends ObjectDetailed {
    @observable $heating;

    @observable $heating;

    @observable $landArea

    @observable $hasGas

    @observable $houseType

    @observable $effluent

    @observable $hasSummerKitchen

    @observable $waterSupply

    @observable $hasGarage

    constructor(service) {
        super(service, 'houses');
    }

    @computed
    get objectName() {
        const {
            $street = {},
            $house = {},
            id,
        } = this;
        if ($street) {
            return [$street.name, $house?.number]
                .filter((address) => Boolean(address))
                .join(', ');
        }
        return id;
    }

    @computed
    get house() {
        const { $house } = this;
        if (!$house) {
            return null;
        }
        return {
            value: $house.id,
            label: HouseDirectory.getFullHouseName($house),
            extra: {
                number: $house.number,
                buildingNumber: $house.buildingNumber,
                structureNumber: $house.structureNumber,
            },
        };
    }

    @computed
    get wallMaterial() {
        const { $wallMaterial } = this;
        if (!$wallMaterial) {
            return null;
        }
        return {
            value: $wallMaterial.id,
            label: $wallMaterial.name,
        };
    }

    @computed
    get houseType() {
        const { $houseType } = this;
        if (!$houseType) {
            return null;
        }
        return {
            value: $houseType.id,
            label: $houseType.name,
        };
    }

    @computed
    get effluent() {
        const { $effluent } = this;
        if (!$effluent) {
            return null;
        }
        return {
            value: $effluent.id,
            label: $effluent.name,
        };
    }

    @computed
    get furnishing() {
        const { $furnishing } = this;
        if (!$furnishing) {
            return null;
        }
        return {
            value: $furnishing.id,
            label: $furnishing.name,
        };
    }

    @computed
    get heating() {
        const { $heating } = this;
        if (!$heating) {
            return null;
        }
        return {
            value: $heating.id,
            label: $heating.name,
        };
    }

    @computed
    get hasCellar() {
        return Boolean(this.$hasCellar);
    }

    @computed
    get hasGarage() {
        return Boolean(this.$hasGarage);
    }

    @computed
    get hasGas() {
        return Boolean(this.$hasGas);
    }

    @computed
    get hasSauna() {
        return Boolean(this.$hasSauna);
    }

    @computed
    get hasSummerKitchen() {
        return Boolean(this.$hasSummerKitchen);
    }

    @computed
    get landArea() {
        const { $landArea } = this;
        return $landArea || null;
    }

    @computed
    get waterSupply() {
        const { $waterSupply } = this;
        if (!$waterSupply) {
            return null;
        }
        return {
            value: $waterSupply.id,
            label: $waterSupply.name,
        };
    }

    @computed
    get fullAddress() {
        const { $city, $street, $house } = this;
        if (!$city || !$street || !$house) {
            return null;
        }
        return `${$city.name}, ${$street.name}, ${$house.number}`;
    }

    createForm(mixins = []) {
        return super.createForm([
            withObjectDetailedField({
                totalArea: 'totalArea',
                price: 'price',
                unitPrice: 'unitPrice',
            }),
            withAddressField({
                area: 'area',
                city: 'city',
                district: 'cityDistrict',
                districtLandmark: 'cityDistrictLandmark',
                street: 'street',
                house: 'house',
            }),
            ...mixins,
        ]);
    }

    // eslint-disable-next-line class-methods-use-this
    prepareRequest(values) {
        const prepared = super.prepareRequest(values);
        return {
            ...prepared,
            cityId: values.city,
            cityDistrictId: values.cityDistrict,
            cityDistrictLandmarkId: values.cityDistrictLandmark,
            effluentId: values.effluent,
            floorCount: values.floorCount,
            furnishingId: values.furnishing,
            hasCellar: values.hasCellar,
            hasGarage: values.hasGarage,
            hasGas: values.hasGas,
            hasSauna: values.hasSauna,
            hasSummerKitchen: values.hasSummerKitchen,
            heatingId: values.heating,
            houseId: Array.isArray(values.house) ? values.house[0] : values.house,
            objectHouseTypeId: values.houseType,
            landArea: values.landArea,
            roomCount: values.roomCount,
            streetId: values.street,
            totalArea: values.totalArea,
            wallMaterialId: values.wallMaterial,
            waterSupplyId: values.waterSupply,
            yearOfConstruction: values.yearOfConstruction,
        };
    }

    toItem() {
        const item = super.toItem();
        return {
            ...item,
            house: this.$house ? this.$house.number : null,
            houseType: this.$houseType ? this.$houseType : null,
            floorCount: this.$floorCount,
            totalArea: this.$totalArea,
            roomCount: this.$roomCount,
            unitPrice: this.$unitPrice,
            yearOfConstruction: this.$yearOfConstruction,
        };
    }
}

export default HouseDetailed;
