/* global alert */
import { runInAction, observable, action } from 'mobx';
import createForm from '../../../helpers/Form';
import {
    registerKeyFields,
    registerUserCredentialsFields,
    registerUserPasswordFields,
} from '../../../forms/client';
import DataSource, { STATES } from '../../shared/DataSource';
import { UserService } from '../../../services/client';

class User {
    keyForm = createForm(registerKeyFields);

    credentialsForm = createForm(registerUserCredentialsFields);

    passwordForm = createForm(registerUserPasswordFields);

    @observable key = undefined;

    @observable credentials = undefined;

    @observable login;

    @observable password;

    password = {};

    constructor() {
        const {
            keyForm,
            credentialsForm,
            passwordForm,
        } = this;
        keyForm.on('success', this.saveKey.bind(this));
        credentialsForm.on('success', this.saveCredentials.bind(this));
        passwordForm.on('success', this.savePassword.bind(this));
        this.dataSource = new DataSource(UserService, STATES.DEFAULT);
    }

    saveKey({ key }) {
        const { credentialsForm, dataSource } = this;
        return dataSource.checkKey(key)
            .then((response) => {
                const { canBeUsedForUserCreation } = response;
                if (!canBeUsedForUserCreation) {
                    throw new Error('Provided key cant be used for new user registration');
                }
                runInAction(() => {
                    this.key = key;
                });
                return Promise.resolve();
            })
            .then(() => dataSource.getOrganizations(key))
            .then((companies) => {
                if (!companies || !companies.length) {
                    throw new Error('No company was registered with this key yet');
                }
                const field = credentialsForm.$('organizationId');
                runInAction(() => {
                    field.set('extra', {
                        ...field.extra,
                        options: companies.map(({ id, name }) => ({ value: id, label: name })),
                    });
                });
                if (companies.length === 1) {
                    const { id: value, name: label } = companies[0];
                    field.set('value', { value, label });
                }
                return Promise.resolve();
            })
            .catch((e) => {
                alert(e.message);
                return Promise.reject(new Error(e.message));
            });
    }

    @action
    saveCredentials(credentials) {
        this.credentials = credentials;
        return Promise.resolve();
    }

    savePassword({ password }) {
        const { key, credentials, dataSource } = this;
        return dataSource.createUser({
            activationKey: key,
            address: credentials.address,
            hardwareName: credentials.hardwareName,
            lastName: null,
            firstName: null,
            patronymic: null,
            password,
            organizationId: credentials.organizationId,
        })
            .then(({ login }) => {
                runInAction(() => {
                    this.key = undefined;
                    this.credentials = undefined;
                    this.login = login;
                    this.password = password;
                });
                return Promise.resolve();
            })
            .catch((e) => {
                alert(e.message);
                return Promise.reject(new Error(e.message));
            });
    }

    getChatBotCode() {
        const { dataSource } = this;
        return dataSource.getChatBotCode()
            .then((response) => Promise.resolve(response))
            .catch((e) => {
                alert(e);
            });
    }
}

const createUserStore = () => new User();

export default User;
export {
    createUserStore,
};
