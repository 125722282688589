import { computed, observable, toJS } from 'mobx';
import createForm from '../../../../helpers/Form';
import { withNamedSnapshots } from '../../../../helpers/Form/mixins';
import BaseItem from '../item/BaseItem';
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter';
import LOCAL_DATA from '../../Directories/helpers/localDirectories';
import groupingFormFields from '../../../../forms/operator/objects-filter/groupingFormFields';

class BaseStrategy {
    filterForm;

    groupingForm;

    filterData = {};

    /** Данные для агрегации */
    @observable.ref
    aggregationData = { };

    @observable
    sortData = {};

    constructor(objectType) {
        this.Item = BaseItem;
        this.objectType = objectType;
    }

    get strategyName() {
        const { objectType } = this;
        return objectType;
    }

    get $tableHeaders() {
        const {
            strategyName,
            tables,
            dealType,
            sortData,
        } = this;
        const strategyNameWithDealType = `${dealType}${capitalizeFirstLetter(strategyName)}`;
        const headers = tables[strategyNameWithDealType] || tables[strategyName];
        return headers.map((header) => ({
            ...header,
            sortDirection: sortData.field === header.sortName ? sortData.direction : undefined,
        }));
    }

    @computed
    get tableHeaders() {
        return this.$tableHeaders;
    }

    get filterFormName() {
        const { strategyName, forms } = this;
        return forms[strategyName]
            ? strategyName
            : 'base';
    }

    get forms() {
        const { parent } = this;
        return parent.forms;
    }

    get formFields() {
        const { filterFormName, forms } = this;
        return forms[filterFormName];
    }

    setParent(parent) {
        this.parent = parent;
    }

    setFilterData(filterData) {
        this.filterData = filterData;
    }

    setAggregationData(aggregationData) {
        this.aggregationData = aggregationData;
    }

    setSortData(sortData) {
        this.sortData = sortData;
    }

    createFilterForm(mixins = []) {
        const { filterForm, formFields } = this;
        if (filterForm) {
            return filterForm;
        }
        this.filterForm = createForm(formFields, [
            withNamedSnapshots(),
            ...mixins,
        ]);
        return this.filterForm;
    }

    /** Создать форму с настройками группировки */
    createGroupingForm(mixins = []) {
        const { groupingForm } = this;
        if (groupingForm) {
            return groupingForm;
        }

        this.groupingForm = createForm(groupingFormFields, [...mixins]);
        return this.groupingForm;
    }

    /**
     * Подготовить объект фильтра
     * @param {Object} options
     * @param {Object} options.includeAggregationData
     *  признак необходимости добавить в объект данные для агрегации
     * @returns {Promise<Object>} подготовленный объект фильтра
     */
    prepareFilterData(options = { }) {
        const { filterData: filter, sortData, defaultSortData = [] } = this;
        // FIXME: сбрасываем groupBy - это временное решение,
        // необходимо обрабатывать поля с omit = true в Form.js
        const { groupBy, address, ...filterData } = filter;
        const nestedFields = ['streetIds', 'houseIds'];
        const mergedSortData = [toJS(sortData), ...defaultSortData];
        const uniqueSortData = [];
        mergedSortData.forEach(({ field, direction }) => {
            const alreadyIn = uniqueSortData.find(
                ({ name: uniqueField }) => uniqueField === field,
            );
            if (field && direction && !alreadyIn) {
                uniqueSortData.push({ name: field, order: direction });
            }
        });
        nestedFields.forEach((key) => {
            if (filterData[key]?.length) {
                filterData[key] = filterData[key].flat(Infinity);
            }
        });
        if (this.filterForm && this.filterForm.differsFromSnapshot('preset')) {
            const presetOmitFields = ['updatedAtTo', 'updatedAtFrom', 'objectSelectionId'];
            // eslint-disable-next-line no-param-reassign
            presetOmitFields.forEach((field) => delete filterData[field]);
        }

        const preparedFilterData = { ...filterData, sort: uniqueSortData };

        if (options.includeAggregationData) {
            preparedFilterData.aggregationData = this.aggregationData;
        }

        return Promise.resolve(preparedFilterData);
    }

    // конвертирует сохраненный фильтр в подходящий
    // eslint-disable-next-line class-methods-use-this
    convertPresetFilterData(filterData) {
        const {
            sort, rooms, floorExcludeFirst, floorExcludeLast, ...rest
        } = filterData;
        const convertedSort = (sort || [])
            .map(({ name, order }) => ({ field: name, direction: order }));
        const filter = { ...rest };
        // NOTE: rooms - это локальный справочник, потому преобразовываем его на фронте
        // если он будет перенесен в бэкэнд, то необходимо преобразование выполнять там
        if (rooms) {
            filter.rooms = rooms.map(
                (room) => LOCAL_DATA.ROOMS_COUNT.find(({ value }) => value === room),
            );
        }
        if (floorExcludeFirst) {
            filter.floorExcludeFirst = true;
        }
        if (floorExcludeLast) {
            filter.floorExcludeLast = true;
        }
        return {
            sort: convertedSort,
            filter,
        };
    }
}

export default BaseStrategy;
