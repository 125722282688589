import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import spawnNotification from '../../utils/spawn-notification';
import { POSITION } from '../../constants/shared';
import SVGIcon from '../Icons/SVGIcon';
import { CopyIcon } from '../Icons/icons';

import styles from './CopyToClipboard.module.scss';

const CopyToClipboardWrapped = ({ text, onCopy, children }) => (
    <CopyToClipboard
        text={text}
        onCopy={onCopy}
    >
        {children}
    </CopyToClipboard>
);

CopyToClipboardWrapped.propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    onCopy: PropTypes.func,
};

CopyToClipboardWrapped.defaultProps = {
    onCopy: spawnNotification.bind(null, 'Скопировано в буфер обмена'),
};

export default CopyToClipboardWrapped;

export const CopyToClipboardButton = ({ text, onCopy, iconHeight }) => (
    <CopyToClipboard
        text={text}
        onCopy={onCopy}
    >
        <button
            className={styles['copy-to-clipboard__toggle']}
            type="button"
            data-tip="Скопировать"
            data-place={POSITION.RIGHT}
            data-class={styles['copy-to-clipboard__hint']}
        >
            <SVGIcon
                SVGElement={CopyIcon}
                height={iconHeight}
            />
        </button>
    </CopyToClipboard>
);

CopyToClipboardButton.propTypes = {
    text: PropTypes.string.isRequired,
    onCopy: PropTypes.func,
    iconHeight: PropTypes.number,
};

CopyToClipboardButton.defaultProps = {
    onCopy: spawnNotification.bind(null, 'Скопировано в буфер обмена'),
    iconHeight: 10,
};
