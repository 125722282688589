const KEYS = {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    ENTER: 13,
    SPACE: 32,
};

export {
    // eslint-disable-next-line import/prefer-default-export
    KEYS,
};
