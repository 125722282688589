import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import getMonth from 'date-fns/getMonth';
import ru from 'date-fns/locale/ru';
import SVGIcon from '../Icons/SVGIcon';
import { ChevronRightIcon, CalendarIcon, CrossIcon } from '../Icons/icons';
import { SIZE } from '../../constants/operator';

import styles from './DatePicker.module.scss';

const size2height = {
    [SIZE.SMALL]: 12,
    [SIZE.MEDIUM]: 14,
    [SIZE.LARGE]: 16,
};

const DatePicker = ({
    className,
    id,
    name,
    value,
    placeholder,
    size,
    disabled,
    onChange,
    popperPlacement,
    closeOnScroll,
    portalId,
    withPortalId,
    shouldCloseOnSelect,
    onSelect,
    clearable,
}) => {
    const modifiedProps = {};
    if (withPortalId) {
        modifiedProps.portalId = portalId;
    }
    const [isOpened, setOpened] = useState(false);
    const handleCalendarClose = () => setOpened(false);
    const handleCalendarOpen = () => setOpened(true);
    const handleReset = () => {
        onChange('');
    };
    const pickerRef = useRef();
    const preparedDateValue = value ? new Date(value) : '';
    return (
        <div
            className={`
                ${styles['datepicker-wrapper']}
                ${styles[`datepicker-wrapper--${size}`]}
            `}
        >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
                className={`
                    ${styles.datepicker}
                    ${styles[`datepicker--${size}`]}
                    ${isOpened ? styles['datepicker--opened'] : ''}
                    ${className}
                `}
            >
                <ReactDatePicker
                    ref={pickerRef}
                    locale={ru}
                    dateFormat="dd.MM.yyyy"
                    showPopperArrow={false}
                    selected={preparedDateValue}
                    id={id}
                    name={name}
                    placeholderText={placeholder}
                    disabled={disabled}
                    autoComplete="off"
                    onChange={onChange}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                    popperPlacement={popperPlacement}
                    closeOnScroll={closeOnScroll}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className={styles['calendar-header']}>
                            <button
                                className={`
                                    ${styles['calendar-header__nav']}
                                    ${styles['calendar-header__nav--prev']}
                                `}
                                type="button"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                            >
                                <SVGIcon
                                    className={styles['calendar-header__icon']}
                                    SVGElement={ChevronRightIcon}
                                    height={8}
                                />
                            </button>
                            <button
                                className={`
                                    ${styles['calendar-header__nav']}
                                    ${styles['calendar-header__nav--next']}
                                `}
                                type="button"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                            >
                                <SVGIcon
                                    className={styles['calendar-header__icon']}
                                    SVGElement={ChevronRightIcon}
                                    height={8}
                                />
                            </button>
                            <div className={styles['calendar-header__month']}>
                                {ru.localize.month(getMonth(date))}
                            </div>
                            <div className={styles['calendar-header__year']}>
                                {date.getFullYear()}
                            </div>
                        </div>
                    )}
                    shouldCloseOnSelect={shouldCloseOnSelect}
                    onSelect={(date, event) => onSelect(date, event, pickerRef)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...modifiedProps}
                />
                <div className={styles.datepicker__icon}>
                    <SVGIcon
                        SVGElement={CalendarIcon}
                        height={size2height[size]}
                    />
                </div>
            </label>
            {
                clearable
                    ? (
                        <div
                            aria-hidden
                            className={`
                                ${styles.datepicker__clear}
                                ${value ? '' : styles['datepicker__clear--hidden']}
                            `}
                            onClick={handleReset}
                        >
                            <SVGIcon
                                SVGElement={CrossIcon}
                                height={size2height[size] - 2}
                            />
                        </div>
                    )
                    : null
            }
        </div>
    );
};

DatePicker.SIZE = { ...SIZE };

DatePicker.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]).isRequired,
    size: PropTypes.oneOf(Object.values(DatePicker.SIZE)),
    disabled: PropTypes.bool,
    popperPlacement: PropTypes.string,
    closeOnScroll: PropTypes.bool,
    onChange: PropTypes.func,
    portalId: PropTypes.string,
    withPortalId: PropTypes.bool,
    shouldCloseOnSelect: PropTypes.bool,
    onSelect: PropTypes.func,
    clearable: PropTypes.bool,
};

DatePicker.defaultProps = {
    className: '',
    id: undefined,
    placeholder: '',
    size: DatePicker.SIZE.MEDUIM,
    disabled: false,
    onChange: null,
    popperPlacement: 'bottom-left',
    closeOnScroll: true,
    portalId: 'root',
    withPortalId: true,
    shouldCloseOnSelect: true,
    onSelect: () => {},
    clearable: false,
};

export default DatePicker;
