/* global window */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ObjectList from '../../../stores/shared/RefactoredObjectList/ObjectList';
import { TableWithSelected } from '../TableWithSelected';
import SelectedObject from '../SelectedObject';
import KeyboardHandler from '../../../components/KeyboardHandler';
import { SORT_ORDER, TABLE_CELL } from '../../../constants/shared';

const NotepadContent = ({
    useStores,
    list,
    onPhoneClick,
    onDoubleRowClick,
    onRowClick,
}) => {
    const listRef = useRef();
    const keyboardRef = useRef();
    const [selectedHeight, setSelectedHeight] = useState('140px');
    let keyPressDelay;
    // interactions
    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = SORT_ORDER.ASC;
                break;
            default:
                nextDirection = (
                    sortName === TABLE_CELL.SHORT_DISTRICT_NAME.sortName
                    || sortName === TABLE_CELL.DISTRICT_NAME.sortName
                )
                    ? SORT_ORDER.DESC
                    : SORT_ORDER.ASC;
        }
        list.setSort(sortName, nextDirection);
        list.reload();
    };
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            list.setPrevCursor();
        } else {
            list.setNextCursor();
        }
        const currentId = list.cursor;
        const index = list.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
        if (keyPressDelay) {
            clearTimeout(keyPressDelay);
        }
        keyPressDelay = setTimeout(() => {
            onRowClick({ original: { id: currentId } });
        }, 300);
    };
    const handleDownKey = () => {
        handleArrowKey(1);
    };
    const handleUpKey = () => {
        handleArrowKey(-1);
    };
    const handleEnterKey = () => {
        const id = list.cursor;
        onDoubleRowClick({ original: { id, exclusive: list.getItem(id).isExclusive } });
    };
    const handleSpaceKey = () => {
        list
            .getItem(list.cursor)
            .toggle();
    };
    // effects
    useEffect(() => {
        if (keyboardRef.current) {
            keyboardRef.current.focus();
        }
    }, [list]);
    if (!list) {
        return null;
    }
    return (
        <KeyboardHandler
            ref={keyboardRef}
            onDown={handleDownKey}
            onUp={handleUpKey}
            onEnter={handleEnterKey}
            onSpace={handleSpaceKey}
        >
            <TableWithSelected
                useStores={useStores}
                headers={list.tableHeaders}
                store={list}
                ref={listRef}
                onRowClick={onRowClick}
                onDoubleRowClick={onDoubleRowClick}
                onTableHeaderClick={handleSortChange}
                selectedContentHeight={selectedHeight}
                fullHeight={`${Math.min((window.innerHeight / 100) * 80, 600) - 183}px`}
                components={{
                    SelectedWrapper: (props) => (
                        <SelectedObject
                            setSelectedHeight={setSelectedHeight}
                            onPhoneClick={onPhoneClick}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                        />
                    ),
                }}
            />
        </KeyboardHandler>
    );
};

NotepadContent.propTypes = {
    useStores: PropTypes.func.isRequired,
    list: PropTypes.instanceOf(ObjectList),
    onPhoneClick: PropTypes.func,
    onDoubleRowClick: PropTypes.func,
    onRowClick: PropTypes.func,
};

NotepadContent.defaultProps = {
    list: undefined,
    onPhoneClick: () => null,
    onDoubleRowClick: () => null,
    onRowClick: () => null,
};

export default NotepadContent;
