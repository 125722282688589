export default (field) => (value, { action }) => {
    if (value) {
        field.set(value);
        return;
    }
    switch (action) {
        case 'clear':
            field.reset();
            break;
        default:
    }
};
