import React from 'react';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { FingerIcon } from '../../../components/Icons/icons';
import { POSITION } from '../../../constants/shared';

const renderVideoLinkButton = ({ value, readOnly, disabled }) => (
    (!!value && (readOnly || disabled))
        ? (
            <a
                href={value}
                data-tip="Перейти по ссылке"
                data-place={POSITION.RIGHT}
                data-class="link-hint__tooltip"
                className="link-hint"
                target="_blank"
                rel="noreferrer"
            >
                <SVGIcon SVGElement={FingerIcon} style={{ height: 20 }} />
            </a>
        )
        : null
);

export default {
    type: 'fieldset',
    name: 'video',
    label: 'Ссылка на видео',
    fields: [{
        type: 'text',
        name: 'video',
        copy: true,
        postfix: renderVideoLinkButton,
    }],
};
