/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { PhotoIcon } from '../../../components/Icons/icons';
import { THUMBNAIL_SLIDER, POSITION } from '../../../constants/shared';

import styles from './Table.module.scss';

const CellImages = ({
    cell: { row: { original: { id, images } } },
    listId,
    onClick,
}) => (
    images && images.length
        ? (
            <div
                className={styles.table__images}
                data-tip={id}
                data-effect="solid"
                data-for={`${THUMBNAIL_SLIDER}-${listId}`}
                data-place={POSITION.TOP}
                data-class={`${styles.table__hint} ${styles.table__slider}`}
                onClick={onClick}
            >
                <SVGIcon
                    className={styles.table__photo}
                    SVGElement={PhotoIcon}
                    height={14}
                />
            </div>
        ) : null
);

CellImages.propTypes = {
    onClick: PropTypes.func,
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                images: PropTypes.arrayOf(
                    PropTypes.shape({
                        src: PropTypes.string,
                    }),
                ),
            }),
        }),
    }).isRequired,
    listId: PropTypes.number,
};

CellImages.defaultProps = {
    onClick: () => {},
    listId: 0,
};

export default CellImages;
